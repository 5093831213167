/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";

import "ol/ol.css";
import proj4 from "proj4";
import Feature from "ol/Feature.js";
import Map from "ol/Map.js";
import View from "ol/View.js";
import MVT from "ol/format/MVT.js";
import LineString from "ol/geom/LineString.js";
import MultiPolygon from "ol/geom/MultiPolygon.js";
import Point from "ol/geom/Point.js";
import VectorLayer from "ol/layer/Vector.js";
import VectorTile from "ol/layer/VectorTile.js";
import TileLayer from "ol/layer/Tile.js";
import XYZ from "ol/source/XYZ.js";
import { fromLonLat } from "ol/proj";
import VectorSource from "ol/source/Vector.js";
import TileGrid from "ol/tilegrid/TileGrid.js";
import TileWMS from "ol/source/TileWMS.js";
import VectorTileSource from "ol/source/VectorTile.js";
import Stroke from "ol/style/Stroke.js";
import Fill from "ol/style/Fill.js";
import Style from "ol/style/Style.js";
import { getWidth } from "ol/extent.js";
import { get as getProjection } from "ol/proj.js";
import { register } from "ol/proj/proj4.js";
import OSM from "ol/source/OSM.js";

import StylesApi from "../../Controllers/style.js";
import { convertRegionToUrl } from "../../utils.js";
import config from "../../settings.js";
import configData from "../../settings_data.js";

proj4.defs(
    "EPSG:2154",
    "+proj=lcc +lat_1=49 +lat_2=44 +lat_0=46.5 +lon_0=3 +x_0=700000 +y_0=6600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
);
register(proj4);

/**
 * This component is a map that can display indicators (analysis) and equipments (POI).
 *
 * Workflow:
 * - componentDidMount:
 *   - init some objects
 *   - load data into this.state, creating timeouts if not available
 * - componentDidUpdate:
 *   - reload data if props changed
 *   - display data or refresh already displayed data if state or props changed
 *
 * @prop {String} id unique identifier for the map container (required)
 * @prop {Object} parentApi data and callbacks from index.js (required)
 * @prop {Object} zone type of the zone as { zone: str, maille: str } (required)
 * @prop {String} currentZone id of the zone (required)
 * @prop {number} analysisId id of the analysis to show
 * @prop {Array} poiLayers layers of POI to show
 * @prop {string} filterString unique id for a filter, only used for updating
 * @prop {string} classMethod method for choropleth colorization. Either "quantile" (default), "equidistant" or "logarithmic"
 * @prop {Object} metaStyle styles info used for representation
 * @prop {Object} mapOptions additionnal options passed to new Map()
 * @prop {Object} viewOptions additionnal options passed to new View()
 * @prop {function} didMountCallback called at the end of componentDidMount to add interactions in the map
 * @prop {function} updateAnalysisMeta callback called when analysis' metadata is updated
 * @prop {function} getDataMap getter for map data. Default to analysisManager.getDataMap
 */
class OlMap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            highlightFeature: undefined,
            data: {},
        };

        // Objects that will be defined later
        this.highlightLayer = undefined;
        this.vtLayer = undefined;
        this.analysisLayer = undefined;
        this.stationLayer = undefined;
        this.poiLayers = {};
        this.pixelLayer = undefined;
        this.view = undefined;
        this.WMSLayer = undefined;
    }

    componentDidMount() {
        this.view = new View({
            enableRotation: false,
            ...(this.props.viewOptions ?? {}),
        });

        this.map = new Map({
            target: this.props.id,
            layers: [],
            view: this.view,
            ...(this.props.mapOptions ?? {}),
        });

        this.addOSM();

        // Initializate layer objects and add them to the map
        this.highlightLayer = new VectorLayer({
            source: new VectorSource(),
            style: StylesApi.styleHighlight(),
            name: "highlight",
        });
        this.highlightLayer.setZIndex(configData.highlight_layer_index);
        this.map.addLayer(this.highlightLayer);
        this.analysisLayer = new VectorLayer({
            source: new VectorSource(),
            name: "analysis",
        });
        this.analysisLayer.setZIndex(configData.analysis_layer_index);
        this.map.addLayer(this.analysisLayer);
        this.stationLayer = new VectorLayer({
            source: new VectorSource(),
            projection: "EPSG:3857",
            name: "stations",
        });
        this.stationLayer.setZIndex(configData.station_layer_index);
        this.map.addLayer(this.stationLayer);

        if (this.props.didMountCallback) this.props.didMountCallback(this);

        // Retrieve data and create timeouts if data has not been loaded yet
        this.loadHighlightFeature();
        this.loadAnalysis();

        this.displayVTZones();
        this.displayPoiLayer(this.props.poiLayers);
    }

    componentWillUnmount() {
        if (this.hlFeaturePromise) this.hlFeaturePromise.abort();
    }

    componentDidUpdate(prevProps, prevState) {
        const updated = {
            zone:
                this.isZoneDefined() !==
                    this.isZoneDefined(prevProps.zone, prevProps.currentZone) ||
                this.props.currentZone !== prevProps.currentZone ||
                this.props.zone.zone !== prevProps.zone.zone ||
                this.props.zone.maille !== prevProps.zone.maille,
            analysis: this.props.analysisId !== prevProps.analysisId,
            filter: this.props.filterString !== prevProps.filterString,
            poi:
                JSON.stringify(this.props.poiLayers) !==
                JSON.stringify(prevProps.poiLayers),
            analysisStyle:
                JSON.stringify(this.props.metaStyle) !==
                    JSON.stringify(prevProps.metaStyle) ||
                this.props.classMethod !== prevProps.classMethod,

            data: this.state.data !== prevState.data,
            highlight: this.state.highlightFeature !== prevState.highlightFeature,
        };

        //console.log("OlMap update:", Object.keys(updated).filter((k) => updated[k]).join(", ")); // prettier-ignore

        // Reload data in case of an update in props
        if (updated.zone) {
            this.loadHighlightFeature();
        }
        if (updated.zone || updated.analysis || updated.filter) {
            this.loadAnalysis();
        }

        // Reload view in case of an update in props
        if (updated.zone) {
            this.displayVTZones();
        }
        if (updated.zone || updated.poi) {
            this.displayPoiLayer(this.props.poiLayers);
        }

        // Reload view in case of an update in state
        if (updated.highlight) {
            this.displayHighlightFeature();
        }
        if (updated.data || updated.analysisStyle) {
            this.displayAnalysis();
            this.displayStationMesures();
        }
    }

    addOSM() {
        if (config.OSM_SOURCE === "agaric" && config.osm_raster_tiles_url) {
            // cf. https://gis.stackexchange.com/questions/349543/how-to-build-xyz-layer-source-for-custom-scales
            const attributions =
                '<a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>';
            const yMaxExtents = {};
            config.rasterResolutions.forEach((resolution, i) => {
                yMaxExtents[i] = Math.floor(
                    (config.rasterMaxExtent[3] - config.rasterMaxExtent[1]) /
                        resolution /
                        256
                );
            });

            const aerial = new XYZ({
                attributions: attributions,
                maxZoom: 20,
                crossOrigin: "Anonymous",
                tileGrid: new TileGrid({
                    origin: config.rasterOrigin, // left upper corner
                    resolutions: config.rasterResolutions,
                    tileSize: [256, 256],
                }),
                tileUrlFunction: function (coord, ratio, projection) {
                    var zoom = coord[0];
                    var x = coord[1];
                    var y = coord[2];

                    return (
                        config.osm_raster_tiles_url +
                        zoom +
                        "/" +
                        x +
                        "/" +
                        (yMaxExtents[zoom] - y) +
                        ".png"
                    );
                },
            });

            this.map.addLayer(
                new TileLayer({
                    source: aerial,
                })
            );
        } else {
            // Add OSM vector tile layer
            this.osmMap = new TileLayer({
                source: new OSM(),
            });
            this.map.addLayer(this.osmMap);
        }
    }

    /**
     * Loads the feature for zone highlighting
     */
    async loadHighlightFeature() {
        if (!this.isZoneDefined()) {
            this.setState({
                highlightFeature: undefined,
            });
            return;
        }

        if (this.hlFeaturePromise) this.hlFeaturePromise.abort();
        this.hlFeaturePromise = this.props.parentApi.controller.zonesManager.getFeature(
            this.props.zone.zone,
            this.props.currentZone
        );
        this.hlFeaturePromise
            .then((feature) => {
                let geom = new MultiPolygon(feature.geometry.coordinates);
                geom.transform(configData.data_epsg, configData.map_epsg);
                let zoneFeature = new Feature({ geometry: geom });

                this.setState({
                    highlightFeature: zoneFeature,
                });
            })
            .catch((error) => {
                if (error.name === "AbortError") return;
                console.error(error);
            });
    }

    /**
     * Load data for analysis
     */
    loadAnalysis() {
        if (!this.isZoneDefined() || !this.props.analysisId) {
            this.setState({ data: {} });
            if (this.props.updateAnalysisMeta) this.props.updateAnalysisMeta({});
            return;
        }
        const getDataMap =
            this.props.getDataMap ??
            this.props.parentApi.controller.analysisManager.getDataMap.bind(
                this.props.parentApi.controller.analysisManager
            );
        let data = getDataMap(this.props.analysisId);

        if (this.props.updateAnalysisMeta) {
            this.props.updateAnalysisMeta(data.meta);
        }
        this.setState({ data: data });
    }

    /**
     * Returns true if zone, zone.zone, zone.maille and currentZone are all
     * considered as defined, i.e are neither undefined, "" nor "undefined".
     */
    isZoneDefined(zone, currentZone) {
        // Values considered as not defined
        const undefinedValues = [undefined, "", "undefined"];

        zone = zone ?? this.props.zone;
        currentZone = currentZone ?? this.props.currentZone;
        if (
            undefinedValues.includes(zone) ||
            undefinedValues.includes(zone.zone) ||
            undefinedValues.includes(zone.maille) ||
            undefinedValues.includes(currentZone)
        )
            return false;
        return true;
    }

    /**
     * Display the highlight effect around selected zone
     * Then, recenter the view around this zone
     */
    displayHighlightFeature() {
        this.clearLayer(this.highlightLayer);

        if (this.state.highlightFeature) {
            // Display contour of the zone
            this.highlightLayer.getSource().addFeature(this.state.highlightFeature);

            // Recenter map on it
            let geom = this.state.highlightFeature.getGeometry();
            let extent = geom.getExtent();
            this.map.getView().fit(extent, this.map.getSize());
        }
    }

    /** Display the vector tiles for mailles. Should be called before displayAnalysis */
    displayVTZones() {
        this.removeLayer(this.vtLayer);
        if (!this.isZoneDefined()) {
            return;
        }

        let layerName =
            convertRegionToUrl(this.props.parentApi.data.region) +
            "." +
            this.props.zone.maille;

        let vectorSource = new VectorTileSource({
            attributions: configData.mapattributions,
            format: new MVT({
                featureClass: Feature,
            }),
            url:
                config.zone_vector_tiles_url.replace("#layer#", layerName) +
                "fields=code,nom",
            projection: "EPSG:3857",
        });
        this.vtLayer = new VectorTile({
            source: vectorSource,
            style: StylesApi.styleZone(),
            name: "zone",
        });
        this.vtLayer.setZIndex(configData.zone_layer_index);
        this.map.addLayer(this.vtLayer);
    }

    /**
     * Main function used to display analyses. Clear existing layers and display
     * the layer selected. Handle Pixels layers separately.
     */
    displayAnalysis() {
        this.clearLayer(this.analysisLayer);
        this.resetVTLayerStyle();
        this.removeLayer(this.pixelLayer);
        this.removeLayer(this.WMSLayer);

        const data = this.state.data;
        const meta = data.meta;

        if (!this.props.analysisId || !data || !meta || !data.data) {
            return;
        }
        if (!this.isZoneDefined()) {
            return;
        }

        if (meta.type === "pixels" || meta.type === "pixels_cat") {
            this.displayPixelAnalysis(data, meta);
            return;
        }

        if (meta.type === "wms_feed") {
            this.displayWMSLayer(data, meta);
            return;
        }

        let data_carte = data.data;
        let deuxiemeRepresentation = false;
        let indicateurFlux = meta.type === "flow";
        if (meta.dataDeuxiemeRepresentation || meta.afficherVersionSimple) {
            deuxiemeRepresentation = true;
        }

        let data_map, classMethod, nbClasses;
        if (meta.type === "stars") {
            let minStar = 0,
                maxStar = 5;
            if (meta.representationDetails) {
                // we parse as integers
                const _minStar = parseInt(meta.representationDetails.minValue ?? 0, 10);
                const _maxStar = parseInt(meta.representationDetails.maxValue ?? 5, 10);
                // just in case we don't have correct order here
                minStar = Math.min(_minStar, _maxStar);
                maxStar = Math.max(_minStar, _maxStar);
            }

            data_map = Array(maxStar - minStar + 1)
                .fill()
                .map((x, i) => ({
                    val: i + minStar,
                }));

            // we rebuild color scale
            classMethod = "equidistant";
            nbClasses = maxStar - minStar + 1;
        } else if (meta.type === "choropleth_cat") {
            data_map = data_carte;
            nbClasses = meta.carto_category?.length ?? configData.nbClassChoropleth;
            classMethod = "equidistant";
        } else {
            data_map = data_carte;
            nbClasses =
                meta.nb_classes_color_representation &&
                Number.isInteger(meta.nb_classes_color_representation)
                    ? meta.nb_classes_color_representation
                    : configData.nbClassChoropleth;
            classMethod = this.props.classMethod ?? "quantile";
        }

        // Build color palette from color_s / color_e in meta
        meta.colorScale = StylesApi.getColorScale(
            data_map,
            data_map.length,
            meta.color_start,
            meta.color_end,
            classMethod,
            deuxiemeRepresentation,
            indicateurFlux,
            meta.data_type,
            nbClasses
        );

        // Choose the style for the zone layer. Can be empty, confidential, chloropleth...
        this.addVTLayerStyle(data);
        const forcedMinScale =
            (meta &&
                meta.representationDetails &&
                meta.representationDetails.scale_forced_min) ??
            undefined;
        // Process analysis
        for (let d in data_carte) {
            let newFeature = undefined;
            if (meta.type === "circle") {
                newFeature = this.displayCircleAnalysis(data_carte[d], meta, nbClasses);
            } else if (meta.type === "flow") {
                newFeature = this.displayFlowAnalysis(data_carte[d]);
            }

            if (!newFeature) {
                continue;
            }
            // Add feature to analysis layer and style it
            let valTaille = data_carte[d].val;

            // We handle the case of a single circle (e.g., level region/div region)
            let forceAverageRadius = data_carte.length === 1;

            let valCouleur = false;
            if (data.mapDataDeuxiemeRepresentation || meta.afficherVersionSimple) {
                valCouleur = data_carte[d].val_applats;
                if (meta.data_type === "accessibilite_emploi") {
                    valCouleur = data_carte[d].rapport;
                }
            }

            newFeature.setStyle(
                StylesApi.styleAnalysis(
                    newFeature,
                    meta.type,
                    meta.colorScale.scale,
                    meta,
                    this.props.metaStyle,
                    valTaille,
                    valCouleur,
                    forceAverageRadius,
                    forcedMinScale
                )
            );
            this.analysisLayer.getSource().addFeature(newFeature);
        }
    }

    /**
     * Display a pixel type layer using VectorTileSource. Will restrict the
     * data (if possible) to current zone through props.zone and props.currentZone.
     *
     * @param {Object} data contains layer data
     * @param {Object} meta whole layer metadata (layer name, layer type - raw pixel
     * or categorical pixels, nb of classes, color start, color end, etc.)
     */
    displayPixelAnalysis(data, meta) {
        let layerName =
            convertRegionToUrl(this.props.parentApi.data.region) + "." + meta.data;

        let vectorSource = new VectorTileSource({
            attributions: configData.mapattributions,
            format: new MVT({
                layerName: "content", // HACK ? Sinon l'attribut layer n'est pas correct dans les features
            }),
            url:
                config.zone_vector_tiles_url.replace("#layer#", layerName) +
                "fields=id,valeur&restrict=" +
                this.props.zone.zone +
                "&restrictId=" +
                this.props.currentZone,
            projection: "EPSG:3857",
        });
        this.pixelLayer = new VectorTile({
            source: vectorSource,
            name: layerName,
        });

        if (meta.type === "pixels") {
            let colorScaleDifferent = StylesApi.getColorScale(
                data.data,
                data.data.length,
                meta.color_start,
                meta.color_end,
                this.props.classMethod ?? "quantile",
                false,
                false,
                meta.data_type,
                meta.nb_classes_color_representation
            );

            this.pixelLayer.setStyle((feature) => {
                let couleur = colorScaleDifferent.scale(feature.get("valeur"));
                return StylesApi.stylePixelsLayer(couleur);
            });

            // Build color palette from color_s / color_e in meta
            // Update parent state with meta
            meta.colorScale = colorScaleDifferent;
        } else if (meta.type === "pixels_cat") {
            let colorsCode = {};
            data.distinctValues.forEach((cat) => {
                colorsCode[cat.valeur] = cat;
            });
            this.pixelLayer.setStyle((feature) => {
                let couleur = colorsCode[feature.get("valeur")]?.couleur;
                return StylesApi.stylePixelsLayer(couleur);
            });
            meta.colorsCode = colorsCode;
        }

        this.pixelLayer.setZIndex(configData.analysis_layer_index);
        this.map.addLayer(this.pixelLayer);
    }

    /**
     * Display a WMS feed.
     *
     * @param {Object} data contains layer data
     * @param {Object} meta whole layer metadata (layer name, layer type - raw pixel
     * or categorical pixels, nb of classes, color start, color end, etc.)
     */
    displayWMSLayer(data, meta) {
        if (
            !meta?.representationDetails?.wmsUrl ||
            !meta?.representationDetails?.wmsLayer
        ) {
            return;
        }

        const serverType = meta?.representationDetails?.wmsServerType ?? "geoserver";
        const projection =
            "EPSG:" + (meta?.representationDetails?.wmsProjection ?? "3857");
        let url = meta.representationDetails.wmsUrl;
        let opacity = Math.min(
            Math.max(parseFloat(meta.representationDetails?.wmsOpacity ?? 1.0), 0.0),
            1.0
        );
        let layer = meta.representationDetails.wmsLayer;

        let years =
            this.props.parentApi.data && this.props.parentApi.controller.analysisManager
                ? this.props.parentApi.controller.analysisManager.getAnalysisYears(
                      this.props.analysisId
                  )
                : [];
        // if we need to replace the year value in layer parameters
        if (url.includes("#annee#")) {
            url = url.replace(
                "#annee#",
                this.props.parentApi.data?.analysisSelectedYear ?? Math.max(...years)
            );
        }
        if (layer.includes("#annee#")) {
            layer = layer.replace(
                "#annee#",
                this.props.parentApi.data?.analysisSelectedYear ?? Math.max(...years)
            );
        }

        let rasterOrigin = config.rasterOrigin;
        let rasterMaxExtent = [...config.rasterMaxExtent];
        //I'm not going to redefine those two in latter examples.
        if (projection === "EPSG:2154") {
            rasterOrigin = proj4("EPSG:3857", projection, rasterOrigin);
            rasterMaxExtent = proj4("EPSG:3857", projection, rasterMaxExtent);
            getProjection(projection).setExtent(rasterMaxExtent);
        }
        const projExtent = getProjection(projection);
        const newExtent = projExtent.getExtent();
        const startResolution = getWidth(newExtent) / 256;
        const resolutions = new Array(22);
        for (let i = 0, ii = resolutions.length; i < ii; ++i) {
            resolutions[i] = startResolution / Math.pow(2, i);
        }
        const tileGrid = new TileGrid({
            origin: rasterOrigin, // left upper corner
            resolutions: config.rasterResolutions,
            tileSize: [256, 256],
        });

        this.WMSSource = new TileWMS({
            params: {
                LAYERS: layer,
            },
            ratio: 1,
            url,
            projection,
            serverType,
            tileGrid,
            crossOrigin: "Anonymous",
        });

        this.WMSLayer = new TileLayer({
            source: this.WMSSource,
            opacity,
            projection,
        });
        this.WMSSource.on("imageloadstart", (e) => {
            this.props.parentApi.callbacks.updateDataLoaded(false);
        });
        this.WMSSource.on("imageloadend", (e) => {
            this.props.parentApi.callbacks.updateDataLoaded(true);
        });

        this.WMSLayer.setZIndex(configData.wms_layer_index);
        this.map.addLayer(this.WMSLayer);

        const resolution = this.map.getView().getResolution();
        const graphicUrl = this.WMSSource.getLegendUrl(resolution);
        const imgs = document.getElementsByClassName(
            "legend-wms-" + this.props.analysisId
        );
        if (imgs) {
            for (let img of imgs) {
                img.src = graphicUrl;
            }
        }
    }

    /**
     * Display one circle analysis feature.
     *
     * @param {Object} current_data contains feature data
     * @param {Object} meta whole layer metadata (colorScale, afficherVersionSimple)
     *    dataDeuxiemeRepresentation, etc.)
     * @param {integer} nbClasses how many classes are displayed (useful only for
     * specific accessibilite_emploi analysis)
     */
    displayCircleAnalysis(current_data, meta, nbClasses) {
        if (current_data.confidentiel) {
            return;
        }
        // Create circles
        let geomFeature = new Point([current_data.x, current_data.y]);

        let newFeature = new Feature({
            geometry: geomFeature,
        });
        newFeature.set("val", current_data.val);
        newFeature.set("code", current_data.code);
        newFeature.set("nom", current_data.nom);
        if (meta.dataDeuxiemeRepresentation || meta.afficherVersionSimple) {
            newFeature.set("val_applats", current_data.val_applats);
        }
        if (meta.data_type === "accessibilite_emploi") {
            // UGLY
            let classesLegende;
            for (let i = 0; i < nbClasses; i++) {
                if (
                    (current_data.rapport >= meta.colorScale.breaks[i] &&
                        current_data.rapport < meta.colorScale.breaks[i + 1]) ||
                    (i === nbClasses - 1 &&
                        current_data.rapport >= meta.colorScale.breaks[i] &&
                        current_data.rapport <= meta.colorScale.breaks[i + 1])
                ) {
                    classesLegende = meta.colorScale.classesLegende[i];
                }
            }
            newFeature.set("rapport", current_data.rapport);
            newFeature.set("classesLegende", classesLegende);
        }
        return newFeature;
    }

    /**
     * Display a single flow feature. Will require a few data in current_data
     * object:
     *    - orig
     *    - dest
     *    - country_dest
     *    - lab_o
     *    - lab_d
     *    - val
     * @param {Object} current_data contains feature data
     */
    displayFlowAnalysis(current_data) {
        if (current_data.orig[0] === null) {
            current_data.orig = configData.outsideFluxLocation;
        }
        if (current_data.dest[0] === null) {
            current_data.dest = configData.outsideFluxLocation;
        }
        if (current_data.country_dest !== null) {
            for (let co of configData.countryFluxLocation) {
                if (co.country === current_data.country_dest) {
                    current_data.dest = co.location;
                    // Update label
                    if (current_data.lab_d == null) {
                        current_data.lab_d = co.label;
                    }
                }
            }
        }

        let points = [current_data.orig, current_data.dest];
        let geomFeature = new LineString(points);
        let newFeature = new Feature({
            geometry: geomFeature,
        });

        newFeature.set("origin", current_data.lab_o);
        newFeature.set("destination", current_data.lab_d);
        newFeature.set("val", current_data.val);
        newFeature.set("id", current_data.lab_o + "_" + current_data.lab_d);
        return newFeature;
    }

    /**
     * Display measure stations for climate data
     */
    displayStationMesures() {
        this.clearLayer(this.stationLayer);
        const data = this.state.data;
        if (
            !this.isZoneDefined() ||
            !this.props.analysisId ||
            !data ||
            !data.stations_mesures
        ) {
            return;
        }

        let stationsMesures = data.stations_mesures;
        let dataCarte = data.data;
        this.stationLayer.setStyle(StylesApi.styleStationMesure(data.meta.data));
        for (const d in stationsMesures) {
            let v = dataCarte.find((v) => v.nom_station === stationsMesures[d].nom);
            if (v === undefined) continue;

            const stationGeometry = new Point(
                fromLonLat([stationsMesures[d].x, stationsMesures[d].y])
            );
            const newFeature = new Feature({ geometry: stationGeometry });
            newFeature.set("station-name", stationsMesures[d].nom);
            newFeature.set("station-altitude", stationsMesures[d].altitude);
            newFeature.set("station_val", v.val);
            newFeature.set("min_periode_ancienne", v.min_periode_ancienne);
            newFeature.set("max_periode_ancienne", v.max_periode_ancienne);
            newFeature.set("min_periode_recente", v.min_periode_recente);
            newFeature.set("max_periode_recente", v.max_periode_recente);

            this.stationLayer.getSource().addFeature(newFeature);
        }
    }

    /**
     * Display POI layers, using VectorTileSource for Point layers and
     * VectorSource for other layers.
     * @param {Array} activatedPoi list of POI layers activated
     */
    displayPoiLayer(activatedPoi) {
        if (activatedPoi === undefined) return;
        for (let poiLayer of this.props.parentApi.data.poiLayers) {
            let layer = this.poiLayers[poiLayer.nom];
            this.removeLayer(layer);
        }
        if (activatedPoi.length === 0) {
            return;
        }
        for (let poiLayerName of activatedPoi) {
            let poiLayer = this.props.parentApi.data.poiLayers.find(
                (p) => p.nom === poiLayerName
            );
            if (poiLayer === undefined) {
                delete this.poiLayers[poiLayerName];
                continue;
            }
            let couleur = poiLayer.couleur;
            let typeGeom = poiLayer.typeGeom;
            let ancrageIcone = poiLayer.ancrageIcone;
            let layerName =
                convertRegionToUrl(this.props.parentApi.data.region) +
                "_poi." +
                poiLayerName;

            // if (typeGeom === "Point") {
            let vectorSource = new VectorTileSource({
                attributions: configData.mapattributions,
                format: new MVT({
                    layerName: "content", // HACK ? Sinon l'attribut layer n'est pas correct dans les features
                }),
                url:
                    config.zone_vector_tiles_url.replace("#layer#", layerName) +
                    "fields=id,properties",
                projection: "EPSG:3857",
            });

            let layerStyle = {};
            if (typeGeom?.endsWith("LineString")) {
                layerStyle = {
                    ...layerStyle,
                    stroke: new Stroke({
                        color: poiLayer.couleur,
                        width: 3,
                    }),
                };
            } else if (typeGeom?.endsWith("Polygon")) {
                layerStyle = {
                    ...layerStyle,
                    stroke: new Stroke({
                        color: poiLayer.couleur,
                        width: 1,
                    }),
                    fill: new Fill({
                        color: poiLayer.couleur,
                    }),
                };
            }
            let vtLayerPoi = new VectorTile({
                source: vectorSource,
                name: layerName,
                style: new Style({
                    ...layerStyle,
                }),
            });
            if (typeGeom === "Point") {
                vtLayerPoi.setStyle((feature) => {
                    let currLayer = feature.get("content");
                    currLayer = currLayer.replace(
                        convertRegionToUrl(this.props.parentApi.data.region) + "_poi.",
                        ""
                    );

                    let style = StylesApi.stylePoiLayer(
                        JSON.parse(feature.get("properties")),
                        this.props.parentApi.data.region,
                        currLayer,
                        couleur,
                        typeGeom,
                        ancrageIcone
                    );
                    return style;
                });
            }

            vtLayerPoi.setZIndex(configData.poi_layer_index);
            this.map.addLayer(vtLayerPoi);
            this.poiLayers[poiLayerName] = vtLayerPoi;
        }
    }

    /**
     * Resets a feature and returns the default zone style
     * @param{Feature} feature
     */
    resetFeatureStyle(feature) {
        // Reset potentialy old values.
        feature.unset("val");
        feature.unset("val_applats");
        feature.unset("rapport");
        feature.unset("indisponible");
        feature.unset("confidentiel");
        // Set to default style
        return StylesApi.styleZone(feature);
    }

    /**
     * Reset the vectorTile layer style
     */
    resetVTLayerStyle() {
        if (this.vtLayer) {
            this.vtLayer.setStyle(this.resetFeatureStyle);
        }
    }

    /**
     * Add the vectorTile layer style
     * Handle chloropleth colors and confidentiality for all types and scales
     * @param{{data:any[],meta:any}} data
     */
    addVTLayerStyle(data) {
        const data_carte = data.data;
        const meta = data.meta;

        let confidentialStyle = StylesApi.styleConfidentialZone();

        this.vtLayer.setStyle((feature) => {
            const properties = feature.getProperties();
            let style = this.resetFeatureStyle(feature); // default style

            // If we can find it, get the territory's data
            let d = data_carte.find((d) => d.code === properties.code);
            if (!d) {
                if (meta.data_type === "climat") {
                    feature.set("val", null);
                    feature.set("indisponible", true);
                    return StylesApi.styleUnavailableData();
                }
                return style;
            }

            feature.set("confidentiel", d.confidentiel);
            // Check if there is confidentiality
            if (d.confidentiel) {
                feature.set("val", null);
                style = confidentialStyle;
            }

            if (!["choropleth", "choropleth_cat", "stars"].includes(meta.type)) {
                return style;
            }

            if (isNaN(d.val)) {
                return style;
            }
            if (meta.min === 0 && meta.max === 0) {
                // No data. The legend will also show "Pas de données".
                return StylesApi.styleUnavailableData();
            }

            // Valid chloropleth or star case
            feature.set("val", d.val);
            style = StylesApi.styleAnalysis(
                feature,
                meta.type,
                meta.colorScale.scale,
                meta,
                this.props.metaStyle,
                d.val
            );
            if (meta.data_type === "climat") {
                feature.set("min_periode_ancienne", d.min_periode_ancienne);
                feature.set("max_periode_ancienne", d.max_periode_ancienne);
                feature.set("min_periode_recente", d.min_periode_recente);
                feature.set("max_periode_recente", d.max_periode_recente);
            }
            if (meta.representationDetails?.popup_columns) {
                const columns = JSON.parse(meta.representationDetails.popup_columns);
                for (let column in columns) {
                    feature.set(column, d[column]);
                }
            }

            // we add zindex based on feature area to allow small shapes to
            // be visible on top on bigger shapes (in case both a city and
            // its arrondissement are displayed)
            style.setZIndex(-parseInt(properties.geometry.getArea()));

            return style;
        });
    }

    /** Clear the specified layer if it exists */
    clearLayer(layer) {
        if (layer && layer.getSource().clear) {
            layer.getSource().clear(true); // True is for fast deletion
        }
    }

    /** Clear and remove the specified layer if it exists */
    removeLayer(layer) {
        if (layer) {
            if (layer.getSource().clear) {
                layer.getSource().clear(true); // True is for fast deletion
            }
            this.map.removeLayer(layer);
        }
    }

    render() {
        return <div className="map" id={this.props.id}></div>;
    }
}

export default OlMap;
