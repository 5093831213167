/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React, { useState } from "react";

import reactCSS from "reactcss";
import Select from "react-select";
import Creatable from "react-select/creatable";
import { SketchPicker } from "react-color";

import Api from "../../Controllers/Api";
import config from "../../settings.js";
import configData from "../../settings_data.js";
import { buildRegionUrl, slugify } from "../../utils.js";

import "bootstrap/dist/css/bootstrap.min.css";
import "../../style/admin/poi.css";

/**
 * This component is used to add equipment to the database
 */
class POIEdition extends React.Component {
    constructor(props) {
        super(props);

        let equip = {
            label: "",
            couleur: configData.defaultColor,
            theme: "",
            nom: "",
            afficherStatut: false,
            ancrageIcone: "",
            exportable: false,
            geom: { value: "geometrie", label: "Choisissez une géométrie" },
            creditsDataSources: [],
            creditsDataProducers: [],
            isMultiIcon: false,
            categoriesElements: [],
        };
        if (this.props.mode === "modifier" && this.props.equipementCourant) {
            equip = this.props.equipementCourant;
            equip.geom = { value: equip.typeGeom, label: equip.typeGeom };
            equip.isMultiIcon =
                equip.type_installation && equip.type_installation !== "";
            if (equip.isMultiIcon) {
                equip.categoriesElements = equip.type_installation
                    .split(",")
                    .map((cat, i) => {
                        return { label: cat, icon: undefined, tmpId: i };
                    });
            } else {
                equip.categoriesElements = [];
            }
        }

        this.state = {
            couleurEquipements: equip.couleur,
            displayCouleurPicker: false,
            defaultNom: "Ex. Bornes hydrogène",
            currentNom: equip.label,
            defaultNomTable: "Ex. bornes_hydrogene",
            currentNomTable: equip.nom,
            currentRubrique: equip.theme,
            uiRubriques: this.props.parentApi.controller.equipementsManager.themes,
            afficherStatut: equip.afficherStatut,
            iconAnchor: equip.ancrageIcone,
            exportable: equip.exportable,
            listExistingTables:
                this.props.parentApi.controller.equipementsManager.poiLayers,
            typeGeom: equip.geom,
            creditsDataSources: equip.creditsDataSources,
            creditsDataProducers: equip.creditsDataProducers,
            fichierDonnees: undefined,
            isMultiIcon: equip.isMultiIcon,
            categoriesElements: equip.categoriesElements,
            fichierIcone: undefined,
            fichierIconeProjet: undefined,
            fichierIconeLegende: undefined,
            fichierPdfMethodo: undefined,
            statusMessage: undefined,
            status: undefined,
        };
    }

    /**
     * Validate the form
     * @return {status} true or false whether data is valid
     */
    formIsValid() {
        if (this.props.mode !== "modifier" && this.state.currentNom === "") {
            this.setState({
                status: "warning",
                statusMessage: "Vous devez renseigner un nom",
            });
            return false;
        }
        if (this.props.mode !== "modifier" && this.state.currentNomTable === "") {
            this.setState({
                status: "warning",
                statusMessage: "Vous devez renseigner un nom de table",
            });
            return false;
        }
        if (this.props.mode !== "modifier" && this.state.currentRubrique === "") {
            this.setState({
                status: "warning",
                statusMessage: "Vous devez renseigner une catégorie",
            });
            return false;
        }
        if (this.props.mode !== "modifier" && !this.state.fichierDonnees) {
            this.setState({
                status: "warning",
                statusMessage: "Vous devez sélectionner un fichier de données",
            });
            return false;
        }
        if (this.state.isMultiIcon) {
            if (this.state.categoriesElements.length === 0) {
                this.setState({
                    status: "warning",
                    statusMessage: "Vous devez sélectionner au moins une icône",
                });
                return false;
            }
        } else if (this.state.typeGeom.value === "Point") {
            if (this.props.mode !== "modifier" && !this.state.fichierIcone) {
                this.setState({
                    status: "warning",
                    statusMessage: "Vous devez sélectionner un fichier icône",
                });
                return false;
            }
            if (this.props.mode !== "modifier" && !this.state.fichierIconeProjet) {
                this.setState({
                    status: "warning",
                    statusMessage: "Vous devez sélectionner un fichier icône projet",
                });
                return false;
            }
            if (this.props.mode !== "modifier" && !this.state.fichierIconeLegende) {
                this.setState({
                    status: "warning",
                    statusMessage: "Vous devez sélectionner un fichier icône légende",
                });
                return false;
            }
        }

        if (this.state.typeGeom.label === "geometrie") {
            this.setState({
                status: "warning",
                statusMessage: "Vous devez sélectionner un type de géométrie",
            });
            return false;
        }
        if (this.props.mode !== "modifier" && !this.state.fichierPdfMethodo) {
            this.setState({
                status: "warning",
                statusMessage: "Le fichier méthodologique est obligatoire",
            });
            return false;
        }

        if (this.props.mode !== "modifier" && this.state.isMultiIcon) {
            for (const icon of this.state.categoriesElements) {
                if (!icon.icon) {
                    this.setState({
                        status: "warning",
                        statusMessage:
                            "Une icône manque pour la modalité " + icon.label,
                    });
                    return false;
                }
            }
        }
        return true;
    }

    /**
     * Data file to import
     * @return None
     */
    onDataFileSelection = (event) => {
        this.setState({ fichierDonnees: event.target.files[0] });
    };

    /**
     * Icon file to import
     * @return None
     */
    onIconPlainFileSelection = (event) => {
        this.setState({ fichierIcone: event.target.files[0] });
    };

    /**
     * Icon "project" file to import (i.e. when the POI is not working yet)
     * @return None
     */
    onIconProjectFileSelection = (event) => {
        this.setState({ fichierIconeProjet: event.target.files[0] });
    };

    /**
     * Icon legend file to import
     * @return None
     */
    onIconLegendFileSelection = (event) => {
        this.setState({ fichierIconeLegende: event.target.files[0] });
    };

    /**
     * Methodological PDF
     * @return None
     */
    onMethodoPdfSelection = (event) => {
        this.setState({ fichierPdfMethodo: event.target.files[0] });
    };

    /**
     * POI name
     * @return None
     */
    onChangeName = (event) => {
        this.setState({ currentNom: event.target.value });
    };

    /**
     * Handles changing geometry type
     * @param {event} e
     */
    updateSelectedGeom(e) {
        this.setState({
            typeGeom: e,
        });
    }

    /**
     * update the component state with new value for credits data Sources
     */
    updateCreditsDataSources = (newVal) => {
        this.setState({ creditsDataSources: newVal });
    };

    /**
     * update the component state with new value for credits data Producers
     */
    updateCreditsDataProducers = (newVal) => {
        this.setState({ creditsDataProducers: newVal });
    };

    /**
     * Adds a new POI layer
     * @return {status} false if the form is invalid
     */
    addPOI() {
        let formData = new FormData();
        // data is invalid => exits
        if (!this.formIsValid()) {
            return false;
        }

        // Updates the formdata before sending it to the API
        formData.append("fichier", this.state.fichierDonnees);
        formData.append("nomFichier", this.state.fichierDonnees.name);

        formData.append("fichierPdf", this.state.fichierPdfMethodo);
        formData.append("nomFichierPdf", this.state.fichierPdfMethodo.name);

        if (this.state.isMultiIcon) {
            this.state.categoriesElements.forEach((categoryElements, i) => {
                formData.append("iconsLabel" + i, categoryElements.label);
                formData.append("iconsFile" + i, categoryElements.icon);
                formData.append("iconsFilename" + i, categoryElements.icon.name);
            });
        } else if (this.state.typeGeom.value === "Point") {
            if (this.state.fichierIcone) {
                formData.append("fichierIcone", this.state.fichierIcone);
                formData.append("nomFichierIcone", this.state.fichierIcone.name);
            }
            // in the case of point geometry => more icons

            if (this.state.fichierIconeProjet) {
                formData.append("fichierIconeProjet", this.state.fichierIconeProjet);
                formData.append(
                    "nomFichierIconeProjet",
                    this.state.fichierIconeProjet.name
                );
            }

            if (this.state.fichierIconeLegende) {
                formData.append("fichierIconeLegende", this.state.fichierIconeLegende);
                formData.append(
                    "nomFichierIconeLegende",
                    this.state.fichierIconeLegende.name
                );
            }
        }
        if (this.state.typeGeom.value === "Point")
            formData.append("ancrageIcone", this.state.iconAnchor);

        formData.append("nomTable", this.state.currentNomTable);
        formData.append("nomEquipement", this.state.currentNom);
        formData.append("nomRubrique", this.state.currentRubrique);

        formData.append("couleur", this.state.couleurEquipements);
        formData.append("afficherStatut", this.state.afficherStatut);
        formData.append("donneesExportables", this.state.exportable);
        formData.append("typeGeom", this.state.typeGeom.value);
        formData.append(
            "creditsDataSources",
            JSON.stringify(this.state.creditsDataSources)
        );
        formData.append(
            "creditsDataProducers",
            JSON.stringify(this.state.creditsDataProducers)
        );
        // Calls the API with files and metadata
        Api.callApi(
            buildRegionUrl(
                config.api_poi_add_layer_url,
                this.props.parentApi.data.region
            ),
            formData,
            "POST",
            "default"
        )
            .then((response) => {
                this.setState({
                    status: "success",
                    statusMessage: response.message,
                });

                // reload the POI list
                this.props.parentApi.callbacks.updatePoiRights();
            })
            .catch((e) => {
                this.setState({ status: "error", statusMessage: e.message });
            });
    }

    /**
     * Creates a select object to choose between different geometry types
     * @returns the select tool
     */
    formSelectionTypeGeom() {
        let listeGeom = [
            { value: "Point", label: "Point" },
            { value: "MultiLineString", label: "Ligne" },
            { value: "MultiPolygon", label: "Polygone" },
        ];
        return (
            <div className="form-horizontal">
                <label htmlFor="geometry_type" className="col-sm-2 control-label">
                    Type de géométrie
                </label>
                <Select
                    defaultValue={this.state.typeGeom}
                    value={this.state.typeGeom}
                    name="annee_ref"
                    inputId="geometry_type"
                    options={listeGeom}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(e) => this.updateSelectedGeom(e)}
                />
            </div>
        );
    }

    /**
     * Update an existing POI
     * @return {status} returns false if the update fails (form invalid)
     */
    updatePOI() {
        let formData = new FormData();

        // data is invalid => exits
        if (!this.formIsValid()) {
            return false;
        }

        // Updates the formdata before sending it to the API
        if (this.state.fichierDonnees) {
            formData.append("fichier", this.state.fichierDonnees);
            formData.append("nomFichier", this.state.fichierDonnees.name);
        }

        if (this.state.fichierPdfMethodo) {
            formData.append("fichierPdf", this.state.fichierPdfMethodo);
            formData.append("nomFichierPdf", this.state.fichierPdfMethodo.name);
        }

        if (this.state.isMultiIcon) {
            this.state.categoriesElements.forEach((categoryElements, i) => {
                formData.append("iconsLabel" + i, categoryElements.label);
                if (categoryElements.icon) {
                    formData.append("iconsFile" + i, categoryElements.icon);
                    formData.append("iconsFilename" + i, categoryElements.icon.name);
                }
            });
        } else if (this.state.typeGeom.value === "Point") {
            if (this.state.fichierIcone) {
                formData.append("fichierIcone", this.state.fichierIcone);
                formData.append("nomFichierIcone", this.state.fichierIcone.name);
            }
            if (this.state.fichierIconeProjet) {
                formData.append("fichierIconeProjet", this.state.fichierIconeProjet);
                formData.append(
                    "nomFichierIconeProjet",
                    this.state.fichierIconeProjet.name
                );
            }

            if (this.state.fichierIconeLegende) {
                formData.append("fichierIconeLegende", this.state.fichierIconeLegende);
                formData.append(
                    "nomFichierIconeLegende",
                    this.state.fichierIconeLegende.name
                );
            }
        }
        if (this.state.typeGeom.value === "Point")
            formData.append("ancrageIcone", this.state.iconAnchor);

        formData.append("nomEquipement", this.state.currentNom);
        formData.append("nomRubrique", this.state.currentRubrique);
        formData.append("couleur", this.state.couleurEquipements);
        formData.append("typeGeom", this.state.typeGeom.value);
        formData.append("afficherStatut", this.state.afficherStatut);
        formData.append("donneesExportables", this.state.exportable);
        formData.append(
            "creditsDataSources",
            JSON.stringify(this.state.creditsDataSources)
        );
        formData.append(
            "creditsDataProducers",
            JSON.stringify(this.state.creditsDataProducers)
        );
        // Calls the API
        Api.callApi(
            buildRegionUrl(
                config.api_poi_layer_url,
                this.props.parentApi.data.region
            ).replace("#layer#", this.props.equipementCourant.nom),
            formData,
            "PUT",
            "default"
        )
            .then((response) => {
                this.setState({
                    status: "success",
                    statusMessage: response.message,
                });

                // Reloads list of POI
                this.props.parentApi.callbacks.updatePoiRights();
                // Reloads the pictures
                this.reloadPictures();
            })
            .catch((e) => {
                this.setState({ status: "error", statusMessage: e.message });
            });
    }

    /**
     * Render a generic form field
     * @param {str} id : field id
     * @param {str} label : field label
     * @param {input} field : input (form)
     */
    renderField(id, label, field, className = undefined) {
        className = typeof className !== "undefined" ? className : "";
        let ref = id + label;
        return (
            <div className={className} ref={ref}>
                <label htmlFor={id} className="col-sm-2 control-label">
                    {label}
                </label>
                {field}
            </div>
        );
    }

    /**
     * Render an input form field
     * @param {str} id : field id
     * @param {str} label : field label
     */
    renderTextInput(id, label, placeHolder, value, callback) {
        return this.renderField(
            id,
            label,
            <input
                type="text"
                className="form-control form-inline equipement-name"
                id={id}
                placeholder={placeHolder}
                value={value}
                onChange={callback}
            />
        );
    }

    /**
     * Render a checkbox form field
     * @param {str} id : field id
     * @param {str} label : field label
     */
    renderCheckbox(id, label) {
        return this.renderField(
            id,
            "",
            <label>
                <input
                    type="checkbox"
                    className="form-control form-inline col-sm-4 form-checkbox"
                    id={id}
                    ref={id}
                />
                <span className="checkbox-label">{label}</span>
            </label>
        );
    }

    /**
     * Build a selection field with Creatable for table name creation
     * @return the table field
     */
    buildTableName = () => {
        return (
            <Creatable
                name="table-name"
                inputId="table-name"
                className="basic-multi-select"
                classNamePrefix="select"
                options={this.state.listExistingTables.map(({ nom }) => ({
                    value: nom,
                    label: nom,
                    isDisabled: true,
                }))}
                placeholder={this.state.defaultNomTable}
                value={{
                    value: this.state.currentNomTable,
                    label: this.state.currentNomTable,
                }}
                onChange={(e) => this.checkTableNameValidity(e.value)}
                formatCreateLabel={(value) => `Créer la table "${value}"`}
                createOptionPosition="first"
            />
        );
    };

    /**
     * checks the table name entered by the user
     */
    checkTableNameValidity(value) {
        // The input value should only contain lowecase letters, numbers and underscores
        if (!value.match(/^[a-z0-9_]+$/)) {
            // changing the erreurEntreeTexteUtilisateur state to true, which displays an error message to the user
            this.setState({
                erreurEntreeTexteUtilisateur: true,
            });
        } else {
            // if none of the characters of value are present in the regular expression, we update the input
            this.setState({
                currentNomTable: value,
                erreurEntreeTexteUtilisateur: false,
            });
        }
    }

    /**
     * Build the themes selection field (with Creatable)
     * @return the selection field
     */
    buildThemesList = () => {
        return (
            <Creatable
                name="rubrique"
                inputId="rubrique"
                className="basic-multi-select"
                classNamePrefix="select"
                options={this.state.uiRubriques.map((value) => ({
                    value,
                    label: value,
                }))}
                value={{
                    value: this.state.currentRubrique,
                    label: this.state.currentRubrique,
                }}
                onChange={(e) => this.setState({ currentRubrique: e.value })}
                formatCreateLabel={(value) => `Créer la rubrique "${value}"`}
            />
        );
    };

    /** Selector for the type of icon (determines the relative anchor point) */
    buildIconAnchorSelect() {
        const iconAnchors = [
            { value: "centre", label: "Ancrage au centre de l'icône" },
            {
                value: "milieu_bas",
                label: "Ancrage en bas au milieu de l'icône",
            },
        ];
        let currentIconAnchor =
            iconAnchors.filter((type) => type.value === this.state.iconAnchor)[0] ??
            iconAnchors[0];
        return (
            <div>
                <label
                    htmlFor="icon-anchor"
                    className="col-sm-2 control-label"
                    title="L'ancrage de l'icône lorsqu'affichée sur la carte, c'est-à-dire le point auquel elle est attachée"
                >
                    Ancrage de l'icône
                </label>
                <Select
                    id="icon-anchor"
                    value={currentIconAnchor}
                    options={iconAnchors}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(anchor) => this.setState({ iconAnchor: anchor.value })}
                    isDisabled={this.state.typeGeom.value !== "Point"}
                />
            </div>
        );
    }

    /**
     * Handle color picker click
     */
    handleColorPickerClick = () => {
        this.setState({
            displayCouleurPicker: !this.state.displayCouleurPicker,
        });
    };

    /**
     * Handle color picker choice
     */
    handleColorPickerChoice = (newColor, event) => {
        let color = newColor.hex + Math.round(newColor.rgb.a * 255).toString(16);

        this.setState({ couleurEquipements: color });
    };

    /**
     * Handle color picker closing
     */
    handleColorPickerClose = () => {
        this.setState({ displayCouleurPicker: false });
    };

    /**
     * Update En projet or En service display in legend.
     */
    updateStatus = () => {
        this.setState({ afficherStatut: !this.state.afficherStatut });
    };

    /**
     * Update the ability to export data of the POI into a csv file.
     */
    updateExportable = () => {
        this.setState({ exportable: !this.state.exportable });
    };

    /**
     * Retrieve the existing SVG icon depending on the type of icon we want
     *
     * @param {string} nom the name of the icon
     * @param {string} typeIcone the type ("", "legend", "en_projet")
     * @returns the span displaying the icon
     */
    displayExistingSVGIcon = (nom, typeIcone, suffix = "") => {
        let full_suffix =
            (typeIcone === "legende"
                ? "_legende"
                : typeIcone === "en_projet"
                ? "_en_projet"
                : "") + suffix;
        return (
            <span className="poi-legend">
                <img
                    id={"existing_svg_icon" + full_suffix}
                    src={
                        "svg/" +
                        this.props.parentApi.data.region +
                        "/" +
                        nom +
                        full_suffix +
                        ".svg"
                    }
                    alt={nom}
                    className="poi-legend"
                />
                <br />
                <em>Icône actuelle</em>
            </span>
        );
    };

    /**
     * Retrieve methodological PDF
     */
    retrieveMethodologicalPdf = (nom) => {
        let pdfMethodo =
            config.methodo_url.replace("#region#", this.props.parentApi.data.region) +
            nom +
            ".pdf";
        return (
            <span className="poi-legend">
                <a
                    href={pdfMethodo}
                    onClick={() => this.suiviConsultation("POI ponctuel", nom + ".pdf")}
                    target="_blank"
                    rel="noreferrer"
                >
                    {nom}.pdf
                </a>
                <br />
                <em>PDF méthodologique actuel</em>
            </span>
        );
    };

    /**
     * Reloads the svg icons
     */
    reloadPictures = () => {
        if (this.state.isMultiIcon) {
            this.state.categoriesElements.forEach((cat) => {
                let img = document.getElementById(
                    "existing_svg_icon_" + slugify(cat.label)
                );
                let timestamp = new Date().getTime();
                let currentSrc = img.src + "?_=" + timestamp;
                img.src = currentSrc;
            });
        } else {
            let suffixes = ["", "_legende", "_en_projet"];
            suffixes.forEach((suffixe) => {
                let img = document.getElementById("existing_svg_icon" + suffixe);
                let timestamp = new Date().getTime();
                let currentSrc = img.src + "?_=" + timestamp;
                img.src = currentSrc;
            });
        }
    };

    /**
     * Render component
     * @return {dom} the DOM of the component
     */
    render() {
        if (
            !this.props.connected ||
            !this.props.userInfos ||
            this.props.userInfos?.profil !== "admin"
        ) {
            return <div>Non accessible.</div>;
        }

        // Styles for color picker
        const styles = reactCSS({
            default: {
                color: {
                    background: this.state.couleurEquipements,
                },
            },
        });

        let errors = "";
        if (this.state.status && this.state.statusMessage) {
            errors = (
                <div role="alert" className={"alert alert-" + this.state.status}>
                    {this.state.statusMessage}
                </div>
            );
        }

        let themes = this.state.uiRubriques ? this.buildThemesList() : "";
        let tableName = this.state.listExistingTables ? this.buildTableName() : "";

        let validationButton = (
            <button className="btn btn-success" onClick={() => this.addPOI()}>
                Ajouter la couche équipement
            </button>
        );

        let blockLayerName = (
            <div className="form-horizontal">
                {this.renderTextInput(
                    "nom",
                    "Nom de la couche",
                    this.state.defaultNom,
                    this.state.currentNom,
                    (e) => this.onChangeName(e)
                )}
            </div>
        );

        let blockTableName = (
            <div className="form-horizontal">
                {/* if the user input is not correct, a message is displayed */}
                {this.state.erreurEntreeTexteUtilisateur && (
                    <p style={{ color: "red" }}>
                        Le nom de la table n'accepte pas les caractères spéciaux, les
                        majuscules, les tirets et les espaces.
                    </p>
                )}
                <label className="col-sm-2 control-label" htmlFor="table-name">
                    Nom de la table
                </label>
                {tableName}
            </div>
        );

        let blockThemes = (
            <div className="form-horizontal">
                <label className="col-sm-2 control-label" htmlFor="rubrique">
                    Rubrique
                </label>
                {themes}
            </div>
        );

        let blockStatus = (
            <div className="afficher-statut">
                <input
                    type="checkbox"
                    id="check-statut"
                    checked={this.state.afficherStatut}
                    onChange={() => this.updateStatus()}
                ></input>
                <label className="titre-statut" htmlFor="check-statut">
                    Afficher le statut du projet dans la légende{" "}
                </label>
            </div>
        );

        let blockExportable = (
            <div className="donnees-exportables">
                <input
                    type="checkbox"
                    id="check-exportable"
                    checked={this.state.exportable}
                    onChange={() => this.updateExportable()}
                ></input>
                <label className="titre-exportable" htmlFor="check-exportable">
                    Autoriser l'export des données de l'équipement
                </label>
            </div>
        );

        let blockMultiIcon = (
            <div>
                <div className="actions">
                    <label
                        htmlFor="is-multi-icon"
                        className="col-sm-2 control-label"
                        title="Lorsqu'une couche a plusieurs icônes différentes en fonction des valeurs de la propriété type, permet d'ajouter les différentes icônes."
                    >
                        Icônes multiples en fonction du champ <em>type</em>
                    </label>
                    <input
                        type="checkbox"
                        id="is-multi-icon"
                        className="col-sm-2 taille-formulaire-admin"
                        defaultChecked={this.state.isMultiIcon}
                        onChange={(e) =>
                            this.setState({ isMultiIcon: !this.state.isMultiIcon })
                        }
                    />
                </div>
            </div>
        );

        let blockSvg = this.state.isMultiIcon ? (
            <div>
                <MultiIconBlock
                    displayExistingIcon={(suffix) => {
                        return this.props.mode === "modifier"
                            ? this.displayExistingSVGIcon(
                                  this.state.currentNomTable,
                                  "",
                                  suffix
                              )
                            : "";
                    }}
                    categoriesElements={this.state.categoriesElements}
                    setCategoriesElements={(e) =>
                        this.setState({ categoriesElements: e })
                    }
                />
                {this.buildIconAnchorSelect()}
                <hr />
            </div>
        ) : (
            <div>
                <div className="actions">
                    <label htmlFor="file_upload_icon">
                        Icône pour l'affichage (format SVG)
                    </label>
                    <input
                        type="file"
                        id="file_upload_icon"
                        onChange={this.onIconPlainFileSelection}
                    />
                </div>
                {this.props.mode === "modifier"
                    ? this.displayExistingSVGIcon(this.state.currentNomTable, "")
                    : ""}
                <div className="actions">
                    <label htmlFor="file_upload_en_projet">
                        Icône pour l'affichage en projet (format SVG)
                    </label>
                    <input
                        type="file"
                        id="file_upload_en_projet"
                        onChange={this.onIconProjectFileSelection}
                    />
                </div>
                {this.props.mode === "modifier"
                    ? this.displayExistingSVGIcon(
                          this.state.currentNomTable,
                          "en_projet"
                      )
                    : ""}
                {this.buildIconAnchorSelect()}
                <hr />
                <div className="actions">
                    <label htmlFor="file_upload_legende">
                        Icône pour la légende (format SVG)
                    </label>
                    <input
                        type="file"
                        id="file_upload_legende"
                        onChange={this.onIconLegendFileSelection}
                    />
                </div>
                {this.props.mode === "modifier"
                    ? this.displayExistingSVGIcon(this.state.currentNomTable, "legende")
                    : ""}
                <hr />
            </div>
        );

        let blockPdfMethodo = (
            <div>
                <div className="actions">
                    <label
                        htmlFor="pdf-file"
                        className="col-sm-2 control-label"
                        title="Fichier PDF méthodologique."
                    >
                        Fichier PDF méthodologique
                    </label>
                    <input
                        type="file"
                        id="pdf-file"
                        className="col-sm-2 taille-formulaire-admin"
                        onChange={this.onMethodoPdfSelection}
                    />
                </div>
                {this.props.mode === "modifier"
                    ? this.retrieveMethodologicalPdf(this.state.currentNomTable)
                    : ""}
            </div>
        );

        let blockData = (
            <div className="block-etape">
                <h4>Sélectionnez les données à importer</h4>
                <div className="actions">
                    <h5>Fichier de données</h5>
                    <div className="admin-upload-info">
                        <strong>Contraintes à respecter :</strong>
                        <ul>
                            <li>
                                les noms des colonnes seront ceux affichés dans les
                                infobulles ;
                            </li>
                            <li>
                                si une de vos colonnes contient une apostrophe, il faut
                                doubler celle-ci (par exemple,{" "}
                                <em>production d''énergie</em> au lieu de{" "}
                                <em>production d'énergie</em>
                            </li>
                            <li>
                                <strong>
                                    aucune de vos colonnes ne doit s'appeler <em>id</em>
                                    ou <em>geom</em>
                                </strong>
                            </li>
                            <li>
                                pour pouvoir géolocaliser les équipements, vous avez le
                                choix selon le type de géométrie :
                                <ul>
                                    <li>
                                        S'il s'agit d'une couche{" "}
                                        <strong>ponctuelle</strong>, il faut bien
                                        spécifier une colonne{" "}
                                        <strong>
                                            <code>x</code>
                                        </strong>{" "}
                                        et une colonne{" "}
                                        <strong>
                                            <code>y</code>
                                        </strong>{" "}
                                        contenant des nombres utilisant un point pour
                                        les décimales (et non une virgule).{" "}
                                        <strong>x</strong> doit correspondre à la
                                        longitude (généralement entre -4 et 9 pour la
                                        France métropolitaine) et <strong>y</strong> à
                                        la latitude (généralement entre 41 et 51 pour la
                                        France métropolitaine).
                                    </li>
                                    <li>
                                        s'il s'agit d'une couche{" "}
                                        <strong>linéaire</strong> ou{" "}
                                        <strong>polygonale</strong>, la géométrie doit
                                        se trouver dans une colonne appelée{" "}
                                        <strong>
                                            <code>geom_origine</code>
                                        </strong>{" "}
                                        au format <strong>WKT</strong> et dans le
                                        système de projection Lambert-93 (EPSG:2154).
                                    </li>
                                </ul>
                            </li>
                            <li>
                                si votre indicateur présente plusieurs typologies (par
                                exemple, <em>déchèteries professionnelles</em>,{" "}
                                <em>déchèteries particuliers</em> et{" "}
                                <em>déchèteries mixtes</em>), il faut utiliser une
                                colonne appelée{" "}
                                <strong>
                                    <code>type</code>
                                </strong>{" "}
                                dans laquelle vous indiquez la catégorie associée. Il
                                faudra également fournir (pour l'instant) des icônes
                                intitulées{" "}
                                <code>nom-de-la-table_nom-de-la-typologie.svg</code> à
                                l'équipe de développement de TerriSTORY (avec{" "}
                                <code>nom-de-la-typologie</code> contenant les valeurs
                                de la colonne{" "}
                                <strong>
                                    <code>type</code>
                                </strong>{" "}
                                de votre fichier de données).
                            </li>
                            <li>
                                si vous souhaitez différencier les installations{" "}
                                <strong>en projet</strong> de celles{" "}
                                <strong>existantes</strong>, c'est possible. Il suffit
                                d'avoir une colonne <strong>statut</strong> qui contient
                                les valeurs <em>En service</em> ou <em>En projet</em>.
                            </li>
                            <li>
                                par conséquent, utiliser les noms de colonne{" "}
                                <code>
                                    <strong>x</strong>
                                </code>
                                ,{" "}
                                <code>
                                    <strong>y</strong>
                                </code>
                                ,{" "}
                                <code>
                                    <strong>geom_origine</strong>
                                </code>
                                ,{" "}
                                <code>
                                    <strong>geom</strong>
                                </code>
                                ,{" "}
                                <code>
                                    <strong>type</strong>
                                </code>
                                ,{" "}
                                <code>
                                    <strong>geom</strong>
                                </code>
                                ,{" "}
                                <code>
                                    <strong>status</strong>
                                </code>{" "}
                                sont vivement déconseillés, à part dans les cas
                                précédemment décrits. Disposer de tels noms de colonnes
                                dans votre fichier de données hors des cas détaillés
                                ci-dessus pourrait faire planter votre couche
                                d'équipements.
                            </li>
                        </ul>
                    </div>
                    <div className="actions">
                        <label
                            htmlFor="poi_data"
                            className="col-sm-2 control-label"
                            title="Fichier de données de la couche POI."
                        >
                            Fichier de données :
                        </label>
                        <input
                            type="file"
                            id="poi_data"
                            className="col-sm-2 taille-formulaire-admin"
                            onChange={this.onDataFileSelection}
                        />
                    </div>
                </div>
                <hr />

                {blockMultiIcon}

                {blockSvg}
            </div>
        );

        let blockColor = (
            <div className="form-horizontal">
                <label className="col-sm-2 control-label">
                    Couleur de représentation
                </label>
                <div className="custom-block">
                    <div
                        className="color-picker-swatch"
                        onClick={this.handleColorPickerClick}
                    >
                        <div className="color-picker-color" style={styles.color} />
                    </div>
                    {this.state.displayCouleurPicker ? (
                        <div className="color-picker-popover">
                            <div
                                className="color-picker-cover"
                                onClick={this.handleColorPickerClose}
                            />
                            <SketchPicker
                                color={this.state.couleurEquipements}
                                onChange={this.handleColorPickerChoice}
                            />
                        </div>
                    ) : null}
                </div>
            </div>
        );

        let title = "Ajout d'une nouvelle couche équipement";
        if (this.props.mode === "modifier") {
            title =
                "Mettre à jour la couche d'équipements \"" +
                this.props.equipementCourant.label +
                '"';
            validationButton = (
                <button className="btn btn-success" onClick={() => this.updatePOI()}>
                    Mettre à jour la couche équipement
                </button>
            );
            // When updating, we only show the table name (and not the editing field)
            blockTableName = (
                <div className="form-horizontal">
                    Nom de la table : <em>{this.state.currentNomTable}</em>
                </div>
            );
        }

        return (
            <div>
                <div className="panel-body panel-ajout-indicateur">
                    <h3 className="panel-title pull-left">{title}</h3>
                    <div className="block-etape">
                        <h4>
                            Renseignez les informations pour cette couche équipement
                        </h4>
                        <div className="actions">
                            {blockLayerName}

                            {blockTableName}

                            {blockColor}

                            {blockThemes}

                            {this.formSelectionTypeGeom()}

                            {blockStatus}

                            {blockExportable}

                            <div className="form-horizontal">
                                <SourcesListInput
                                    name="Sources de la donnée"
                                    id="sources"
                                    sources={this.state.creditsDataSources}
                                    changeCallback={this.updateCreditsDataSources}
                                />
                            </div>
                            <div className="form-horizontal">
                                <SourcesListInput
                                    name="Producteurs de la donnée"
                                    id="producteurs"
                                    sources={this.state.creditsDataProducers}
                                    changeCallback={this.updateCreditsDataProducers}
                                />
                            </div>

                            {blockPdfMethodo}
                        </div>
                    </div>

                    {blockData}

                    {validationButton}

                    {errors}
                </div>
            </div>
        );
    }
}

/**
 * Component to display one source / provider form (with name and url) and the
 * ability to remove current form.
 *
 * Props are given in parameters and are specified as described below.
 *
 * @param {str} name the name of the source / provider
 * @param {str} url the URL of the source / provider
 * @param {str} changeCallback callback when changing value of current parameters
 * @param {str} removeCallback callback when clicking remove button
 */
function SourceInput({ name, url, changeCallback, removeCallback, id }) {
    return (
        <div>
            <div className="analysis-sources-url-form">
                <p>
                    <label htmlFor={"source_input_" + id + "_name"}>Nom :</label>
                </p>
                <p>
                    <input
                        type="text"
                        value={name}
                        id={"source_input_" + id + "_name"}
                        onChange={(e) => changeCallback("name", e.target.value)}
                    />
                </p>
                <p>
                    <label htmlFor={"source_input_" + id + "_url"}>URL :</label>
                </p>
                <p>
                    <input
                        type="text"
                        value={url}
                        id={"source_input_" + id + "_url"}
                        onChange={(e) => changeCallback("url", e.target.value)}
                    />
                </p>
                <p>
                    <button className="btn btn-danger" onClick={() => removeCallback()}>
                        Supprimer
                    </button>
                </p>
            </div>
        </div>
    );
}

/**
 * Component to display, from a list of sources, a form to edit/add/remove the
 * data sources/providers.
 *
 * @param {object} props
 * @param {Array<{name: str, url: str}>} props.sources the list of data sources
 * @param {str} props.changeCallback callback when changing the sources
 * @param {str?} props.name the name of current data sources to be displayed alongside the form (optional)
 */
export function SourcesListInput(props) {
    function changeAnalysis(i, element, val) {
        // we change index value
        props.sources[i] = {
            name: element === "name" ? val : props.sources[i]["name"],
            url: element === "url" ? val : props.sources[i]["url"],
        };
        // and then update parent value
        props.changeCallback([...props.sources]);
    }

    function addSource() {
        // if we add a source, we just append it to the array
        props.changeCallback([...props.sources, { name: "", url: "" }]);
    }

    function removeSource(i) {
        // if we removed a source, we filter the array not to return it
        props.changeCallback(props.sources.filter((_, index) => index !== i));
    }

    let formSources = [];
    if (props.sources && Array.isArray(props.sources)) {
        // we build the final form with smaller form for each source
        props.sources.forEach((source, i) => {
            formSources.push(
                <div key={i}>
                    <SourceInput
                        id={props?.id + "_" + i}
                        name={source.name ? source.name : ""}
                        url={source.url ? source.url : ""}
                        changeCallback={(element, val) =>
                            changeAnalysis(i, element, val)
                        }
                        removeCallback={() => removeSource(i)}
                    />
                </div>
            );
        });
    }

    return (
        <div>
            <div>
                <label className="col-sm-2 control-label">
                    <strong>{props.name ? props.name : "Sources"}</strong>
                </label>
                <div
                    className="taille-formulaire-admin analysis-sources-form-empty"
                    style={{ display: "inline-block" }}
                ></div>
            </div>
            <div>
                <label className="col-sm-2 control-label"></label>
                <div
                    className="taille-formulaire-admin analysis-sources-form"
                    style={{ display: "inline-block" }}
                >
                    {formSources}
                    <p>
                        <button className="btn btn-primary" onClick={() => addSource()}>
                            Ajouter une source
                        </button>
                    </p>
                </div>
            </div>
        </div>
    );
}

function MultiIconBlock({
    categoriesElements,
    setCategoriesElements,
    displayExistingIcon,
}) {
    const addCategoryElements = () => {
        const tmpId = Math.max(...categoriesElements.map((o) => o.tmpId), 0) + 1;
        setCategoriesElements(
            categoriesElements.concat({
                isNew: true,
                icon: undefined,
                label: "",
                tmpId: tmpId,
            })
        );
    };
    const updateCategoryElement = (categoryId, newData) => {
        setCategoriesElements(
            categoriesElements.map((cat, catId) => {
                if (catId !== categoryId) {
                    return cat;
                }
                return {
                    ...cat,
                    ...newData,
                };
            })
        );
    };
    const removeCategoryElements = (categoryId) => {
        categoriesElements.splice(categoryId, 1);
        setCategoriesElements(categoriesElements);
    };

    return (
        <div className="multi-icons-block">
            <h2>Icônes</h2>
            <p>
                <button
                    className="btn btn-success"
                    onClick={() => addCategoryElements()}
                >
                    Ajouter une icône
                </button>
            </p>
            <div className="icons-blocks">
                {categoriesElements.map((categoryElements, categoryId) => {
                    return (
                        <div className="icon-block" key={"block-icon-" + categoryId}>
                            <p>
                                <button
                                    className="btn btn-danger"
                                    id={"remove-icon-" + categoryId}
                                    onClick={() => removeCategoryElements(categoryId)}
                                >
                                    Retirer cette icône
                                </button>
                            </p>
                            <IconBlock
                                key={
                                    "block-icon-" +
                                    categoryId +
                                    "-" +
                                    categoryElements?.tmpId
                                }
                                id={categoryId}
                                displayExistingIcon={displayExistingIcon}
                                data={categoryElements}
                                callback={(e) => updateCategoryElement(categoryId, e)}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

function IconBlock({ data, callback, displayExistingIcon, id }) {
    const [isIconShown, setIconShown] = useState(!data.isNew);

    return (
        <div>
            <p>
                <label htmlFor={"icon_block_label_" + id}>
                    Valeur de la catégorie correspondant à cette icône
                </label>
                <input
                    type="text"
                    id={"icon_block_label_" + id}
                    key={"icon_block_label_" + id}
                    placeholder="Modalité de la typologie"
                    defaultValue={data.label}
                    onChange={(e) => {
                        setIconShown(false);
                        callback({ label: e.target.value });
                    }}
                />
            </p>
            <p>
                <label htmlFor={"icon_block_file_" + id}>Modifier l'icône</label>
                <input
                    type="file"
                    id={"icon_block_file_" + id}
                    key={"icon_block_file_" + id}
                    defaultValue={data.icon}
                    onChange={(e) => {
                        setIconShown(false);
                        callback({ icon: e.target.files[0] });
                    }}
                />
            </p>
            {isIconShown && displayExistingIcon("_" + slugify(data.label))}
        </div>
    );
}

export default POIEdition;
