/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import config from "../settings.js";
import { buildRegionUrl } from "../utils.js";
import Api from "./Api";

/**
 * This component is used to get the configurations of all regions
 * form the `regions configuration` table.
 */
class Settings {
    constructor(callback) {
        this.fetchConfiguration(callback);
    }

    /**
     * Calls the API to get the list of available regions.
     * @param {callable} callback function called at the end of the treatment
     * of available regions.
     */
    fetchConfiguration(callback) {
        Api.callApi(buildRegionUrl(config.api_region_list_url)).then((json) => {
            // Save the metadata
            this.regions = json;
            this.regionsList = [];
            this.regionsUrl = [];
            this.regionsSettings = new Map();
            for (let region of this.regions) {
                this.regionsList.push(region.id);
                this.regionsUrl.push(region.url);
                this.regionsSettings.set(region.id, region);
            }
            // Tell the main app that the configuration has been loaded
            callback(this.regionsList, this.regionsUrl, this.regionsSettings);
        });
    }
}

export default Settings;
