/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import { Link } from "react-router-dom";

import configData from "../../settings_data.js";
import config from "../../settings.js";

/**
 * This component manages the display of the application footer
 */
class RegionalFooter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            classFooter: "footer",
        };

        // Initial one shot timer for hiding footer
        this.footerTimerId = setInterval(() => {
            this.setState({ classFooter: "footer footer-small" });
            clearInterval(this.footerTimerId);
        }, configData.timeHideFooter);
    }

    render() {
        let lien_a_propos = this.props.parentApi.data?.settings?.lien_a_propos;
        let lien_faq = this.props.parentApi.data?.settings?.lien_faq;
        let nom_faq = "";
        let lien_faq_final = "";
        // Mouse over on footer
        const handleMouseEnterOnFooter = (e) => {
            this.setState({ classFooter: "footer" });
        };
        const handleMouseLeaveFooter = (e) => {
            this.setState({ classFooter: "footer footer-small" });
        };

        let classFooter = this.state.classFooter;

        let footer = {};
        footer.left = [];
        footer.right = [];
        if (
            this.props.parentApi &&
            this.props.parentApi.data &&
            this.props.parentApi.data?.settings?.footer
        ) {
            // Construction of the footer
            for (let dir of ["left", "right"]) {
                for (let block of JSON.parse(this.props.parentApi.data.settings.footer)[
                    dir
                ]) {
                    let title = block.title;
                    let subtitle = block.subtitle;
                    let key = title + Date.now();
                    let logos = [];
                    for (let logo of block.logos) {
                        logos.push(
                            <img
                                key={logo.title}
                                className="footer-logo"
                                src={"img/" + logo.img}
                                alt={logo.title}
                            />
                        );
                    }
                    footer[dir].push(
                        <div className="footer-block" key={key}>
                            <label>{title}</label>
                            <div>{logos}</div>
                            <label className="subtitle">{subtitle}</label>
                        </div>
                    );
                }
            }
        }
        if (lien_faq === "faq.pdf") {
            nom_faq = "FAQ";
            lien_faq_final = (
                <li>
                    <a
                        href={
                            config.faq_url.replace(
                                "#region#",
                                this.props.parentApi.data.region
                            ) + lien_faq
                        }
                        target="_blank"
                        rel="noreferrer"
                    >
                        {nom_faq}
                    </a>
                </li>
            );
        } else {
            nom_faq = "Aide";
            lien_faq_final = (
                <li>
                    <Link to="/support">
                        <button>{nom_faq}</button>
                    </Link>
                </li>
            );
        }

        let lienAPropos = (
            <a
                href={
                    config.a_propos_url.replace(
                        "#region#",
                        this.props.parentApi.data.region
                    ) + lien_a_propos
                }
                target="_blank"
                rel="noreferrer"
            >
                À propos
            </a>
        );
        if (lien_a_propos === "a_propos") {
            lienAPropos = (
                <Link to={"/" + lien_a_propos}>
                    <button>À propos</button>
                </Link>
            );
        }
        if (lien_a_propos && lien_a_propos.indexOf("http") !== -1) {
            lienAPropos = (
                <a href={lien_a_propos} target="_blank" rel="noreferrer">
                    À propos
                </a>
            );
        }
        return (
            <div
                className={classFooter}
                onMouseEnter={handleMouseEnterOnFooter}
                onMouseLeave={handleMouseLeaveFooter}
            >
                <span className="left-logos">{footer["left"]}</span>

                <ul className="menu-h">
                    <li>{lienAPropos}</li>
                    <li>
                        <Link to="/contact">
                            <button>Contact</button>
                        </Link>
                    </li>
                    <li>
                        <Link to="mentions_legales_cgu">
                            <button>Mentions légales</button>
                        </Link>
                    </li>
                    <li>
                        <Link to="open_source">
                            <button>Open-Source</button>
                        </Link>
                    </li>
                    {(this.props.parentApi.data?.settings?.enable_newsletter_national ||
                        this.props.parentApi.data?.settings
                            ?.enable_newsletter_regional) && (
                        <li>
                            <Link to="newsletter">
                                <button>Newsletter</button>
                            </Link>
                        </li>
                    )}
                    {lien_faq_final}
                </ul>

                <span className="right-logos">{footer["right"]}</span>
            </div>
        );
    }
}

export default RegionalFooter;
