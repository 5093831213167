/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import ReactTable from "react-table-6";

import Api from "../../Controllers/Api";
import { buildRegionUrl, filterCaseInsensitive } from "../../utils.js";

import config from "../../settings.js";

import "bootstrap/dist/css/bootstrap.min.css";

function displayLinkCoordinates(coordinates) {
    if (coordinates === undefined || !coordinates) {
        return "";
    }
    let coords = coordinates.split(" ");
    return (
        <a
            target="_blank"
            rel="noreferrer"
            className="link_coordinates"
            href={
                "https://www.openstreetmap.org/#map=14/" + coords[1] + "/" + coords[0]
            }
        >
            {Math.round(coords[1] * 1000) / 1000}, {Math.round(coords[0] * 1000) / 1000}
        </a>
    );
}

/**
 * This component allows you to manage the history of modifications on data layers
 */
class POIUserContributions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            history: undefined,
        };
    }
    componentDidMount() {
        this.getContributionsList();
    }

    // Get contributions of modifications on layers
    getContributionsList() {
        // Call api to get contributions
        Api.callApi(
            buildRegionUrl(
                config.api_poi_contributions_admin_url,
                this.props.parentApi.data.region
            ),
            undefined,
            "GET"
        )
            .then((response) => {
                response = response.map((r) => ({
                    ...r,
                    mise_a_jour: new Date(r.mise_a_jour),
                }));
                // Store user list
                this.setState({
                    contributions: response,
                });
            })
            .catch((e) => this.setState({ status: e.message }));
    }

    // Handle a single contribution
    handleContribution(edit_id, action) {
        if (action !== "accepted" && action !== "refused") {
            return;
        }
        Api.callApi(
            buildRegionUrl(
                config.api_poi_contribution_manage_url,
                this.props.parentApi.data.region
            )
                .replace("#edit_id#", edit_id)
                .replace("#action#", action),
            undefined,
            "POST"
        )
            .then((response) => {
                this.props.parentApi.callbacks.updateMessages(
                    response.message,
                    "success"
                );
                this.getContributionsList();
            })
            .catch((e) =>
                this.props.parentApi.callbacks.updateMessages(e.message, "danger")
            );
    }

    /**
     * getTrProps is used to color the rows of the table according to criteria
     * @param  {json} state state of the current row
     * @param  {json} rowInfo current row info (columns, values)
     * @param  {json} instance array instance
     * @return {json} the style definition for the current row
     */
    getTrProps(state, rowInfo, instance) {
        if (rowInfo) {
            return {
                style: {
                    background:
                        rowInfo.original.state === "pending"
                            ? "#b2e1ff"
                            : rowInfo.original.state === "accepted"
                            ? "#bcff7d"
                            : "#ff7c7e",
                },
            };
        }
        return {};
    }

    render() {
        if (
            !this.props.connected ||
            !this.props.userInfos ||
            (this.props.userInfos?.profil !== "admin" &&
                !this.props.userInfos?.canValidatePOIContributions)
        ) {
            return <div>Non accessible.</div>;
        }

        const columns = [
            {
                Header: "Id",
                accessor: "edit_id",
                maxWidth: 100,
            },
            {
                Header: "Couche",
                accessor: "poi_table",
                minWidth: 150,
            },
            {
                Header: "Action",
                accessor: "action",
                maxWidth: 100,
            },
            {
                Header: "Utilisateur",
                accessor: "utilisateur",
            },
            {
                Header: "Changement",
                accessor: "changement",
                Cell: (props) => {
                    return props.value ? (
                        <ul>
                            {props.value.map((element, i) => {
                                return <li key={i}>{element}</li>;
                            })}
                        </ul>
                    ) : (
                        "-"
                    );
                },
                minWidth: 250,
            },
            {
                Header: "Geolocalisation",
                accessor: "geolocalisation",
            },
            {
                Header: "Position avant mise à jour",
                accessor: "position_precedente",
                Cell: (props) => {
                    return displayLinkCoordinates(props.original.position_precedente);
                },
            },
            {
                Header: "Nouvelle position",
                accessor: "position_courante",
                Cell: (props) => {
                    return displayLinkCoordinates(props.original.position_courante);
                },
            },
            {
                Header: "Mise à jour",
                accessor: "mise_a_jour",
                Cell: (props) => {
                    return props.value.toLocaleString();
                },
            },
            {
                Header: "Actions",
                accessor: "actions",
                Cell: (props) => {
                    if (props.original.state !== "pending") {
                        return null;
                    }
                    return (
                        <div>
                            <button
                                className="btn btn-success"
                                onClick={() =>
                                    this.handleContribution(
                                        props.original.edit_id,
                                        "accepted"
                                    )
                                }
                            >
                                Accepter
                            </button>
                            <button
                                className="btn btn-danger"
                                onClick={() =>
                                    this.handleContribution(
                                        props.original.edit_id,
                                        "refused"
                                    )
                                }
                            >
                                Refuser
                            </button>
                        </div>
                    );
                },
            },
        ];
        return (
            <div>
                <div className="panel-body">
                    <h3 className="panel-title pull-left">
                        Contributions proposées par les utilisateurs
                    </h3>
                    <ReactTable
                        data={this.state.contributions}
                        columns={columns}
                        className="-striped"
                        defaultPageSize={10}
                        getTrProps={this.getTrProps}
                        filterable={true}
                        defaultFilterMethod={(filter, row) =>
                            filterCaseInsensitive(filter, row)
                        }
                    />
                </div>
            </div>
        );
    }
}

export default POIUserContributions;
