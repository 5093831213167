/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

import Api from "../../Controllers/Api.js";
import config from "../../settings.js";
import { consulteAutrePage } from "../../utils.js";
import Loading from "../../wrappers/Loading.js";

import "bootstrap/dist/css/bootstrap.min.css";

/**
 * This component is used to manage the "contact" page of the application
 */
class Contact extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            mailSent: true,
            captchaVerified: undefined,
            submitted: undefined,
        };
    }

    componentDidMount() {
        let region = this.props.parentApi.data.region;
        if (this.props.parentApi?.controller?.gestionSuiviConsultations) {
            let idUtilisateur =
                this.props.parentApi.controller.gestionSuiviConsultations.idUtilisateur;
            let url = config.api_consultation_autre_page_url;
            consulteAutrePage(url, region, idUtilisateur, "Contact", "");
        }
    }

    verifyCallbackCaptcha(token) {
        this.setState({
            captchaVerified: token,
        });
    }

    resetCaptcha() {
        window.grecaptcha.reset();
        this.setState({
            captchaVerified: undefined,
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        const form = event.target.elements;

        if (!this.state.captchaVerified) {
            this.setState({ submitted: "Vous devez remplir le captcha" });
            return;
        }
        let contactUrl = config.api_contact_url.replace(
            "#region#",
            this.props.parentApi.data.region
        );
        let params = [];
        let fields = [
            "firstname",
            "lastname",
            "organism",
            "email",
            "question",
            "subject",
            "rgpd",
        ];

        fields.forEach((field) => {
            let value = "";

            if (form[field].type === "checkbox") value = form[field].checked;
            else value = trim(form[field].value);

            params.push(field + "=" + value);
        });
        contactUrl += params.join("&");
        contactUrl += "&captcha_token=" + this.state.captchaVerified;

        this.setState({ mailSent: false });
        Api.callApi(contactUrl)
            .then((json) => {
                let msg = "Votre mail a bien été envoyé.";
                if (json.status !== "ok") msg = json.status;

                this.setState({
                    submitted: msg,
                    mailSent: true,
                });
            })
            .catch((e) => {
                this.props.parentApi.callbacks.updateMessages(e.message);
                this.setState({ mailSent: true });
            })
            .finally(() => {
                this.resetCaptcha();
            });
    }

    render() {
        if (!this.props.parentApi.data.settings) {
            return (
                <Loading
                    isLoaded={
                        this.props.parentApi.data.settings &&
                        Object.values(this.props.parentApi.data.settings).length > 0
                    }
                />
            );
        }

        let submitted = null;
        if (this.state.submitted) {
            submitted = (
                <div className="form-group centered-row">
                    <label className="alert alert-success col-sm-4 control-label">
                        {this.state.submitted}
                    </label>
                </div>
            );
        }

        let mailSent = "";
        if (!this.state.mailSent) {
            mailSent = <div className="loader centered-widget centered-row" />;
        }

        return (
            <div className="widgets full-screen-widget">
                {!this.props.hideBackToMap && (
                    <Link
                        className="back-to-map"
                        to={"/" + this.props.parentApi.data.urlPartageable}
                    >
                        <button
                            type="button"
                            className="close close-big"
                            data-dismiss="alert"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </Link>
                )}
                <div className="panel panel-default">
                    <h2 className="tstitle">Contact</h2>
                    <form className="tsform" onSubmit={this.handleSubmit.bind(this)}>
                        {this.renderTextInput("email", "Email", true, "email")}
                        {this.renderTextInput("firstname", "Prénom", true)}
                        {this.renderTextInput("lastname", "Nom", true)}
                        {this.renderTextInput("organism", "Structure", true)}
                        {this.renderTextInput("subject", "Objet", true)}
                        {this.renderTextarea("question", "Message", true)}
                        {this.renderCheckbox(
                            "rgpd",
                            "En soumettant ce formulaire, j'accepte que les informations saisies soient exploitées par " +
                                this.props.parentApi.data.settings.contact_resp_rgpd +
                                " pour me recontacter.",
                            true
                        )}
                        <p style={{ fontSize: 14, marginBottom: 0 }}>
                            <span className="danger">*Mention obligatoire</span>
                        </p>
                        <div className="form-group recaptcha">
                            <ReCAPTCHA
                                sitekey={config.captcha_key}
                                onChange={(t) => this.verifyCallbackCaptcha(t)}
                                hl="fr"
                            />
                        </div>
                        <div>
                            <Link
                                to={"/" + this.props.parentApi.data.urlPartageable}
                                className="tsbtn big"
                            >
                                Fermer
                            </Link>{" "}
                            <input
                                type="submit"
                                className="tsbtn info big"
                                value="Envoyer"
                                disabled={!this.state.mailSent}
                            />
                        </div>
                    </form>
                    <div className="panel-footer">{mailSent}</div>
                </div>
                {submitted}
                <label className="label-alert centered-row">
                    Conformément au RGPD, vous disposez d’un droit d’accès, de
                    modification, de rectification et de suppression des données qui
                    vous concernent. Vous pouvez l’exercer en adressant un e-mail à
                    l’adresse suivante:{" "}
                    {this.props.parentApi.data.settings.contact_email}
                </label>
            </div>
        );
    }

    renderTextInput(id, label, required = false, type = "text") {
        return (
            <div className={"input " + (id in this.state.errors ? "has-error " : "")}>
                <label
                    htmlFor={id}
                    title={id in this.state.errors ? this.state.errors[id] : undefined}
                >
                    {label} {required && <Star />}
                </label>
                <input type={type} id={id} name={id} required={required} />
            </div>
        );
    }

    renderTextarea(id, label, required = false) {
        return (
            <div className={"input " + (id in this.state.errors ? "has-error " : "")}>
                <label
                    htmlFor={id}
                    title={id in this.state.errors ? this.state.errors[id] : undefined}
                >
                    {label} {required && <Star />}
                </label>
                <textarea id={id} name={id} required={required} />
            </div>
        );
    }

    renderCheckbox(id, label, required = false) {
        return (
            <div
                key={id}
                className={
                    "check-input " + (id in this.state.errors ? "has-error " : "")
                }
            >
                <input type="checkbox" id={id} name={id} required={required} />
                <label
                    htmlFor={id}
                    title={id in this.state.errors ? this.state.errors[id] : undefined}
                >
                    {label} {required && <Star />}
                </label>
            </div>
        );
    }
}

function Star() {
    return <span className="danger">*</span>;
}

export default Contact;

// Utils
var trim = (function () {
    var TRIM_RE = /^\s+|\s+$/g;
    return function trim(string) {
        return string.replace(TRIM_RE, "");
    };
})();
