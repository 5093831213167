/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";

import "bootstrap-icons/font/bootstrap-icons.css";
import { SketchPicker } from "react-color";
import reactCSS from "reactcss";

import { buildRegionUrl } from "../../utils.js";
import config from "../../settings.js";
import api from "../../Controllers/Api.js";

/**
 * This component is used to create an Analysis Launcher
 */
class AnalysisLauncher extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id_analyse: String(props.id_analysis),
            representation: "analysis-launcher",
            widthDOM: window.innerWidth,
            color: props.color,
            displayColorStartPicker: false,
            displayColorEndPicker: false,
            disabled: false,
        };
        // Get data from API
        this.fetchConfiguration();
    }

    componentDidMount() {
        // we parse ID to remove any eventual prefix due to territorial choice
        let id = this.props.id;
        if (typeof id === "string" && ["-"].includes(id)) {
            id = id.split("-")[-1];
        }

        this.props.parentApi.callbacks.updateAnalysisNameColor({
            id: parseInt(this.props.id, 10),
            color: this.props.color,
            id_analysis: this.props.id_analysis,
        });
        let chartsLegendsDiv = document.getElementsByClassName("charts-legende");
        if (chartsLegendsDiv.length > 0) {
            let widthDiv =
                document.getElementsByClassName("charts-legende")[0].offsetWidth;
            if (
                this.props.parentApi.data.tailleDiv !== widthDiv &&
                this.props.provenance === "tableau_de_bord_restitue"
            ) {
                this.props.parentApi.callbacks.tailleDiv(widthDiv);
            }
        }
    }

    /**
     * This function updates the data from which we build the SVG marker when changing territory
     * @param {object key => value} prevProps : main component properties before territory change via selection tool
     */
    componentDidUpdate(prevProps) {
        let chartsLegendsDiv = document.getElementsByClassName("charts-legende");
        if (chartsLegendsDiv.length > 0) {
            let widthDiv =
                document.getElementsByClassName("charts-legende")[0].offsetWidth;
            if (
                this.props.parentApi.data.tailleDiv !== widthDiv &&
                this.props.provenance === "tableau_de_bord_restitue"
            ) {
                this.props.parentApi.callbacks.tailleDiv(widthDiv);
            }
        }

        let currentZone = this.props.zoneId ?? this.props.parentApi.data.currentZone;
        if (
            (prevProps.zoneId ?? prevProps.parentApi.data.currentZone) !== currentZone
        ) {
            // If the territory has changed
            if (this.state.id_analyse) {
                this.fetchConfiguration(); // We fetch data
            }
        }
    }

    componentWillUnmount() {
        if (this.dataPromise) this.dataPromise.abort();
    }

    fetchConfiguration() {
        let pZone =
            "?zone=" + (this.props.zoneType ?? this.props.parentApi.data.zone.zone);
        let pMaille =
            "&maille=" +
            (this.props.zoneMaille ?? this.props.parentApi.data.zone.maille);
        let pZoneId =
            "&zone_id=" + (this.props.zoneId ?? this.props.parentApi.data.currentZone);
        if ((this.props.zoneType ?? this.props.parentApi.data.zone.zone) === "region") {
            pZoneId = "&zone_id=" + this.props.parentApi.data.regionCode;
        }
        let id_analyse = this.state.id_analyse;
        let representation = this.state.representation;
        let body = JSON.stringify({});
        let dataSource =
            config.api_analysis_meta_url +
            id_analyse +
            "/graphique/" +
            representation +
            pZone +
            pMaille +
            pZoneId;
        if (this.dataPromise) this.dataPromise.abort();
        this.dataPromise = api.callApi(
            buildRegionUrl(dataSource, this.props.parentApi.data.region),
            body,
            "POST"
        );
        this.dataPromise
            .then((json) => {
                if (json["disabled"] && json["disabled"] === true) {
                    this.setState({
                        disabled: true,
                    });
                    return;
                }

                this.setState({
                    data: json,
                });
            })
            .catch((error) => {
                if (error.name === "AbortError") return;
                console.error(error);
            });
    }

    /**
     * Update component state with the color selected by user
     * @param {chaine de caractères} : color encoded in hexadecimal string
     */
    updateColor = (newColor) => {
        this.setState({
            color: newColor.hex,
        });
        // we parse ID to remove any eventual prefix due to territorial choice
        let id = this.props.id;
        if (["-"].includes(id)) {
            id = id.split("-")[-1];
        }
        let id_analyse = parseInt(this.state.id_analyse, 10);
        let color = newColor.hex;
        this.props.parentApi.callbacks.updateAnalysisNameColor({
            id: parseInt(id, 10),
            color,
            id_analyse,
        });
    };

    /**
     * Updates this.state.displayColorStartPicker
     * As long as this.state.displayColorStartPicker is equal to false, the color form is not displayed
     * As soon as it is true, the form appears and the administrator can select the color of his choice
     */
    handleColorStartClick = () => {
        this.setState({
            displayColorStartPicker: !this.state.displayColorStartPicker,
        });
    };

    /**
     * Set this.state.displayColorStartPicker to false to close the form when the color has been selected
     */
    handleColorStartCose = () => {
        this.setState({ displayColorStartPicker: false });
    };

    /**
     * The color of the target trajectory
     */
    colorChoice() {
        const stylesColorDebut = reactCSS({
            default: {
                color: {
                    background: this.state.color,
                },
            },
        });

        return (
            <div className="formulaire-horizontal-alignement-vertical-centre">
                <label className="">Couleur de l'étiquette</label>
                <div className="custom-block">
                    <div
                        className="color-picker-swatch"
                        onClick={this.handleColorStartClick}
                    >
                        <div
                            className="color-picker-color"
                            style={stylesColorDebut.color}
                        />
                    </div>
                    {this.state.displayColorStartPicker ? (
                        <div className="color-picker-popover">
                            <div
                                className="color-picker-cover"
                                onClick={this.handleColorStartCose}
                            />
                            <SketchPicker
                                color={this.state.color}
                                onChange={this.updateColor}
                            />
                        </div>
                    ) : null}
                </div>
            </div>
        );
    }

    /**
     * this function returns the url to an analysis
     */
    buildUrlAnalysisDashboard(idIndicateur) {
        // if we are editing a dashboard, we don't allow the click
        if (this.props.provenance === "tableau_de_bord") {
            return "";
        }
        let typeTerritoire =
            "?zone=" + (this.props.zoneType ?? this.props.parentApi.data.zone.zone);
        let maille =
            "&maille=" +
            (this.props.zoneMaille ?? this.props.parentApi.data.zone.maille);
        let analysis = "&analysis=" + idIndicateur;
        let codeInseeTerritoire =
            "&zone_id=" + (this.props.zoneId ?? this.props.parentApi.data.currentZone);
        let zoneObject =
            this.props.zoneType && this.props.zoneMaille
                ? {
                      zone: this.props.zoneType,
                      maille: this.props.zoneMaille,
                  }
                : this.props.parentApi.data.zone;
        let nomTerritoire =
            "&nom_territoire=" +
            encodeURI(
                this.props.parentApi.controller.zonesManager.getZoneName(
                    this.props.zoneId ?? this.props.parentApi.data.currentZone,
                    zoneObject
                )
            );
        let uiTheme =
            "&theme=" +
            this.props.parentApi.controller.analysisManager.getAnalysisNameTheme(
                parseInt(idIndicateur, 10)
            );
        if (
            !this.props.parentApi.controller.zonesManager.getZoneName(
                this.props.zoneId ?? this.props.parentApi.data.currentZone,
                zoneObject
            )
        ) {
            nomTerritoire =
                "&nom_territoire=" + encodeURI(this.props.parentApi.data.nomTerritoire);
        }
        if ((this.props.zoneType ?? this.props.parentApi.data.zone.zone) === "region") {
            codeInseeTerritoire = "&zone_id=" + this.props.parentApi.data.regionCode;
            nomTerritoire =
                "&nom_territoire=" + this.props.parentApi.data.settings.label;
        }
        let url =
            window.location.origin +
            typeTerritoire +
            maille +
            codeInseeTerritoire +
            analysis +
            uiTheme +
            nomTerritoire;
        return url;
    }

    render() {
        if (this.state.disabled === true) {
            return (
                <div className="charts-legende">
                    <div className="confid-chart">
                        Cet indicateur n'est pas activé actuellement, veuillez contacter
                        l'administrateur régional.
                    </div>
                </div>
            );
        }

        let nom = "";
        let id_analysis;
        let colorChoice = this.colorChoice();
        let colorRegion = this.state.color.toUpperCase() + "66";
        if (this.state.data) {
            id_analysis = this.state.data.id_indicateur;
            nom = this.state.data.nom;
        }

        const getItemStyle = {
            background: colorRegion,
            border: "solid " + this.state.color,
        };
        const url = this.buildUrlAnalysisDashboard(id_analysis);
        return (
            <div>
                <div className="charts-legende">
                    <div
                        className="btn"
                        onClick={() => {
                            this.props.parentApi.callbacks.mettreAJourParametresUrls(
                                url
                            );
                            return window.open(url, "_blank");
                        }}
                    >
                        <div style={getItemStyle} className="analysis-launcher-button">
                            {nom}
                        </div>
                    </div>
                    <span className="only-print">
                        Contient un <a href={url}>lien</a>
                    </span>
                </div>
                {this.props.provenance !== "tableau_de_bord_restitue" && colorChoice}
            </div>
        );
    }
}

export default AnalysisLauncher;
