/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import ReactDOM from "react-dom";

import Analysis from "./Controllers/Analysis";
import Equipements from "./Controllers/Equipements";
import Zones from "./Controllers/Zones";
import AuthManager from "./Controllers/AuthManager";
import DashboardService from "./Controllers/DashboardService.js";
import SuiviConsultations from "./Controllers/SuiviConsultations.js";
import Print from "./Controllers/Print";
import Settings from "./Controllers/Settings";
import ErrorBoundary from "./ErrorBoundary";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter as Router, Route } from "react-router-dom";

import "../node_modules/ol/ol.css";
import "./style/index.css";
import "./style/forms.css";

import configData from "./settings_data.js";
import config from "./settings.js";
import RegionalRouter from "./routes/RegionalRouter.js";
import NationalRouter from "./routes/NationalRouter.js";
import Loading from "./wrappers/Loading.js";
import RegionalHandler from "./wrappers/RegionalHandler.js";

/**
 * Ce fichier regroupe tous les composants et détermine les routes.
 */
class Main extends React.Component {
    constructor(props) {
        super(props);

        this.title = "TerriSTORY";
        this.timeoutInMiliseconds = 1800000; // Vérification toute les X millisecondes de l'activité
        this.timeoutId = undefined;

        const regionMap = new Map();
        // TODO à stocker en base ou settings ?
        regionMap.set("auvergnerhonealpes", {
            name: "auvergne-rhone-alpes",
            code: "84",
        });
        regionMap.set("arec-nouvelleaquitaine", {
            name: "nouvelle-aquitaine",
            code: "75",
        });
        regionMap.set("arec-occitanie", { name: "occitanie", code: "76" });
        regionMap.set("regions", { name: "france", code: "1" });
        regionMap.set("bretagne", { name: "bretagne", code: "53" });
        regionMap.set("teo-paysdelaloire", {
            name: "paysdelaloire",
            code: "52",
        });
        regionMap.set("aue-corsica", { name: "corse", code: "94" });
        regionMap.set("national", { name: "national", code: "" });
        // Tester où se situe l'information de région (en début ou fin d'url)
        let region = this.props.location.pathname.split("/")[1];
        let regionUrlServeur = "";
        this.argsUrl = this.props.location.search;
        for (let value of regionMap) {
            if (region.indexOf(value[1].name) !== -1) {
                region = value[1].name;
                regionUrlServeur = value[1].name;
            }
        }
        if (config.DEFAULT_REGION) {
            region = config.DEFAULT_REGION;
            regionUrlServeur = config.DEFAULT_REGION;
        }

        this.region = region;
        if (regionUrlServeur === "") {
            // On regarde si la region est contenue dans l'url
            let regionUrl = window.location.hostname.substr(
                0,
                window.location.hostname.indexOf(".")
            );
            if (["dev", "test", "prod"].indexOf(regionUrl) === -1) {
                for (const [key, value] of regionMap) {
                    if (regionUrl === key) {
                        this.region = value.name;
                        this.regionCode = value.code;
                    }
                }
            }
        } else {
            for (const value of regionMap.values()) {
                if (this.region === value.name) {
                    this.regionCode = value.code;
                }
            }

            // Ce n'est peut-être pas la région, mais une sous-URL de l'appli
            let isSubUrl = true;
            regionMap.forEach(function (value, key, map) {
                if (region === value.name) {
                    // On test value et non key, car dans le cas de l'appli en local, c'est cette valeur qu'on a
                    isSubUrl = false;
                }
            });
            if (isSubUrl) {
                // On redirige vers la page d'accueil
                window.location = "/";
            }
        }

        this.state = {
            title: "TerriSTORY",
            zone: { zone: undefined, maille: undefined },
            currentZone: undefined,
            chargementListeTableauxDeBordNecessaire: false,
            tableauBordDonnees: {
                donnees: {
                    1: {
                        titre_thematique: "Titre de la thématique",
                        indicateurs: {
                            1: {
                                numero_analyse: "1",
                                categories: {},
                            },
                            2: {
                                numero_analyse: "2",
                                categories: {},
                            },
                            3: {
                                numero_analyse: "3",
                                categories: {},
                            },
                        },
                        description_thematique: "Description de la thématique",
                        ordre: 0,
                    },
                    2: {
                        titre_thematique: "Titre de la thématique",
                        indicateurs: {
                            4: {
                                numero_analyse: "4",
                                categories: {},
                            },
                            5: {
                                numero_analyse: "5",
                                categories: {},
                            },
                            6: {
                                numero_analyse: "6",
                                categories: {},
                            },
                        },
                        description_thematique: "Description de la thématique",
                        ordre: 1,
                    },
                    3: {
                        titre_thematique: "Titre de la thématique",
                        indicateurs: {
                            7: {
                                numero_analyse: "7",
                                categories: {},
                            },
                            8: {
                                numero_analyse: "8",
                                categories: {},
                            },
                            9: {
                                numero_analyse: "9",
                                categories: {},
                            },
                        },
                        description_thematique: "Description de la thématique",
                        ordre: 2,
                    },
                },
                metadonnees: {},
                charger: false,
                affectationTerritoire: false,
            },
            tableauBordCourant: undefined,
            currentDidacticFile: undefined,
            representationCourante: undefined,
            dashboardManager: {},
            confidActuelle: "A",
            tailleDiv: "",
            analysisNameColor: [],
            didacticFileNameColor: [],
            linkNameColor: [],
            displayChart: false,
            stationMeteoName: undefined,
            stationAltitude: undefined,
            displayAnalysis: true,
            idUtilisateur: undefined,
            previousMaille: "",
            analysis: undefined,
            numeroIndicateur: undefined,
            planActions: undefined,
            analysisMeta: undefined, // Meta for current analysis
            infos: "",
            dataAnalysisLoaded: false,
            filtreCategorieCourant: "",
            dataLoaded: true,
            mapFilter: {},
            localMapFilter: {},
            poiLayers: [],
            chartsPanelOpened: true,
            metaStyle: {
                maxRadius: configData.maxRadiusCircle,
            },
            connected: false,
            splashScreen: configData.splashScreen,
            confidFilteredPieId: undefined,
            classMethod: "quantile",
            fluxThreshold: undefined,
            toggleMenu: false,
            currentMenu: "indicateur",
            printManager: new Print(),
            map: undefined,
            messages: undefined,
            profil: undefined,
            provenance: undefined,
            region: this.region,
            regionCode: this.regionCode,
            settingsLoaded: true,
            showReset: false,
            lastZone: undefined,
            utilisateurActif: true,
            regionMap: regionMap,
            urlArgs: this.urlArgs,
            urlPartageable: this.props.location.search,
            uiTheme: undefined,
            idInstallationCourante: undefined,
            nomInstallationCourante: undefined,
            idIndicateurCourant: undefined,
            nomIndicateurCourant: undefined,
            analysisManagerFailed: false,
            analysisSelectedYear: undefined,
            analysisSelectedUnit: undefined,
            sankeySelected: undefined,
            isOtherTerritory: false,
            authComplete: false,
            regions: [],
            isNationalRegion: false,
        };
        this.ol = undefined;
    }

    /**
     * Vérifie que la mise à jour a eu lieu.
     * @param {array} prevProps : les props de l'état précédent
     * @param {array} prevState : les états de l'état précédent
     * @public
     */
    componentDidUpdate(prevProps, prevState) {
        if (
            this.state.settings &&
            this.state.gestionSuiviConsultations &&
            this.state.analysisManager &&
            !this.state.chargementTermine &&
            this.state.dashboardManager
        ) {
            if (
                this.state.settings &&
                this.state.dashboardManager.listMyDashboards !== undefined
            ) {
                this.configurerEtatComposantSelonUrl(
                    this.props.location.pathname,
                    this.props.location.search
                );
                this.setState({
                    chargementTermine: true,
                });
            }
        }
    }

    componentDidMount() {
        let zone = { zone: undefined, maille: undefined };
        let codeInseeTerritoireUrl = undefined;
        let argsFinaux = this.decortiquerArgumentsUrl();
        let depuisUrl = false;
        if (argsFinaux.zone && argsFinaux.maille && argsFinaux.zone_id) {
            zone = { zone: argsFinaux["zone"], maille: argsFinaux["maille"] };
            codeInseeTerritoireUrl = argsFinaux.zone_id;
            depuisUrl = true;
        }

        if (argsFinaux.installation) {
            depuisUrl = true;
        }

        // récupère le ui thème passé en url
        if (argsFinaux.theme) {
            this.setState({
                uiTheme: decodeURI(argsFinaux.theme),
            });
        }

        // récupère l'identifiant de l'installation passé en url
        if (!this.settingsManager) {
            this.settingsManager = new Settings(
                (regions, regionsUrls, regionsSettings) => {
                    this.setState({
                        regions: regions,
                        regionsUrls: regionsUrls,
                        regionsSettings: regionsSettings,
                        currentZone: undefined,
                    });
                    if (this.state.region) {
                        this.setState({
                            depuisUrl: depuisUrl,
                        });
                        let parametresRegion = regionsSettings.get(this.state.region);
                        if (
                            parametresRegion?.valeur_defaut_territoire &&
                            !codeInseeTerritoireUrl
                        ) {
                            this.setState({
                                zone: { zone: "region", maille: "epci" },
                                toggleMenu: true,
                                currentZone: this.regionCode,
                            });
                            if (
                                this.region !== "national" ||
                                !this.state.isNationalRegion ||
                                !this.state.isOtherTerritory
                            ) {
                                this.dashboardManager.refreshDashboardLists(
                                    "region",
                                    this.regionCode
                                );
                            }
                        } else if (codeInseeTerritoireUrl) {
                            this.setState({
                                zone: zone,
                                toggleMenu: true,
                                currentZone: codeInseeTerritoireUrl,
                            });
                        }
                        this.setState({
                            theme: regionsSettings.get(this.state.region)?.theme,
                            regionLabel: regionsSettings.get(this.state.region)?.label,
                            settings: regionsSettings.get(this.state.region),
                        });
                    } else {
                        this.setState({
                            settings: regionsSettings.get("national"),
                        });
                    }
                }
            );
        }
        if (this.region && this.region !== "national") {
            let typeTerritoire = undefined;
            let codeInseeTerritoire = undefined;
            let maille = undefined;
            if (argsFinaux.zone && argsFinaux.maille && argsFinaux.zone_id) {
                typeTerritoire = argsFinaux["zone"];
                codeInseeTerritoire = argsFinaux["zone_id"];
                maille = argsFinaux["maille"];
                this.setState({
                    zone: { zone: typeTerritoire, maille: maille },
                });
            }

            if (argsFinaux.nom_territoire) {
                this.setState({
                    nomTerritoire: decodeURI(argsFinaux["nom_territoire"]),
                });
            }

            if (argsFinaux.installation) {
                this.setState({
                    idInstallationCourante: decodeURI(argsFinaux.installation),
                    depuisUrl: true,
                    splashScreen: false,
                });
            }

            if (argsFinaux.analysis) {
                this.setState({
                    idIndicateurCourant: parseInt(argsFinaux.analysis, 10),
                });
            }

            if (argsFinaux.sankey) {
                this.setState({
                    sankeySelected: argsFinaux.sankey,
                });
            }
            if (argsFinaux.didactic_file) {
                this.setState({
                    currentDidacticFile: parseInt(argsFinaux.didactic_file, 10),
                });
            }

            let url = this.props.location.pathname;

            let listePagesCom = [
                "a_propos",
                "contact",
                "mentions_legales_cgu",
                "open_source",
            ];
            for (let composant of listePagesCom) {
                if (url.indexOf(composant) !== -1) {
                    this.props.history.push("/" + composant);
                    this.setState({
                        splashScreen: false,
                    });
                }
            }

            this.gestionSuiviConsultations = new SuiviConsultations(
                () => {
                    this.setState({
                        gestionSuiviConsultations: this.gestionSuiviConsultations,
                    });
                },
                this.regionCode,
                this.region
            );
            this.analysisManager = new Analysis(
                () => {
                    this.setState({
                        dataAnalysisLoaded: true,
                        analysisManager: this.analysisManager,
                    });
                },
                this.region,
                this.regionCode,
                () => {
                    this.setState({ analysisManagerFailed: true });
                }
            );
            this.zonesManager = new Zones(() => {
                this.setState({
                    dataZonesLoaded: true,
                    zonesManager: this.zonesManager,
                });
            }, this.region);

            this.equipementsManager = new Equipements((poiLayers) => {
                this.setState({
                    dataPoiLoaded: true,
                    poiLayers: poiLayers,
                    equipementsManager: this.equipementsManager,
                });
            }, this.region);

            this.dashboardManager = new DashboardService(
                () => {
                    this.setState({
                        dashboardManager: this.dashboardManager,
                    });
                },
                this.region,
                this.regionCode,
                typeTerritoire,
                codeInseeTerritoire,
                false
            );

            this.authManager = new AuthManager(null, this.region, this.regionCode);
            this.setState({ authManager: this.authManager });
            if (
                argsFinaux.analysis &&
                url.indexOf("restitution_tableaux_bord") !== -1
            ) {
                this.props.location.pathname = this.props.location.pathname.replace(
                    "/restitution_tableaux_bord",
                    "/"
                );
                this.props.history.push("/");
            }
        } else if (this.region === "national") {
            let typeTerritoire = undefined;
            let maille = undefined;
            this.authManager = new AuthManager(null, "national");
            if (argsFinaux.zone && argsFinaux.maille && argsFinaux.zone_id) {
                typeTerritoire = argsFinaux["zone"];
                maille = argsFinaux["maille"];
            }
            this.gestionSuiviConsultations = new SuiviConsultations(
                () => {
                    this.setState({
                        gestionSuiviConsultations: this.gestionSuiviConsultations,
                    });
                },
                "01",
                this.region
            );

            this.setState({
                authManager: this.authManager,
                zone: { zone: typeTerritoire, maille: maille },
            });
        }

        // Détection de l'inactivité de l'utilisateur
        this.setupInactivityTimers();
    }

    startInactivityTimer() {
        this.timeoutId = window.setTimeout(() => {
            this.setState({
                utilisateurActif: false,
            });
        }, this.timeoutInMiliseconds);
    }

    setupInactivityTimers() {
        document.addEventListener(
            "mousemove",
            this.resetInactivityTimer.bind(this),
            false
        );
        document.addEventListener(
            "mousedown",
            this.resetInactivityTimer.bind(this),
            false
        );
        document.addEventListener(
            "keypress",
            this.resetInactivityTimer.bind(this),
            false
        );
        document.addEventListener(
            "touchmove",
            this.resetInactivityTimer.bind(this),
            false
        );
        this.startInactivityTimer();
    }

    resetInactivityTimer() {
        window.clearTimeout(this.timeoutId);
        this.startInactivityTimer();
    }

    decortiquerArgumentsUrl() {
        let args = this.argsUrl.split("&");
        let argsCle = {};
        for (let arg of args) {
            argsCle[arg.split("=")[0].replace("?", "")] = arg.split("=")[1];
        }
        return argsCle;
    }

    configurerEtatComposantSelonUrl(url) {
        let listeComposants = ["restitution_tableaux_bord"];
        if (this.state.settings.ui_show_poi) {
            listeComposants.push("poi");
        }
        if (this.state.settings.ui_show_plan_actions) {
            listeComposants.push(
                "strategies_territoriales",
                "fiche_didactique",
                "simulateur"
            );
        }
        if (this.state.settings.ui_show_analyse) {
            listeComposants.push(
                "suivi_energetique",
                "synthese_territoriale",
                "suivi_emission_ges",
                "suivi_polluants_covnm",
                "suivi_polluants_nh3",
                "suivi_polluants_nox",
                "suivi_polluants_pm10",
                "suivi_polluants_pm25",
                "suivi_polluants_so2",
                "diagramme_sankey"
            );
        }

        let argsFinaux = this.decortiquerArgumentsUrl(this.argsUrl);
        if (argsFinaux.zone) {
            this.setState({
                zone: { zone: argsFinaux.zone, maille: argsFinaux.maille },
                currentZone: argsFinaux.zone_id,
                splashScreen: false,
            });
            // On n'autorise les Sankey que sur les EPCI
            if (
                argsFinaux.zone &&
                (!this.state.settings || !this.state.settings.ui_show_sankey) &&
                url.indexOf("diagramme_sankey") !== -1
            ) {
                url = url.replace("/diagramme_sankey", "/");
            }
        }

        for (let composant of listeComposants) {
            if (
                url.indexOf(composant) !== -1 &&
                composant !== "restitution_tableaux_bord"
            ) {
                this.updateAnalysis(composant, false, "url partagée " + composant);
            } else if (
                url.indexOf(composant) !== -1 &&
                composant === "restitution_tableaux_bord"
            ) {
                if (argsFinaux.id_tableau) {
                    let tableauBordCourant = parseInt(argsFinaux.id_tableau, 10);
                    this.setState({
                        tableauBordCourant: tableauBordCourant,
                        nomTerritoire: decodeURI(argsFinaux["nom_territoire"]),
                    });
                    this.updateAnalysis(composant, false, "url partagée " + composant);
                }
            }
        }

        if (argsFinaux["analysis"] && url.indexOf("restitution_tableaux_bord") === -1) {
            let idIndicateur = parseInt(argsFinaux["analysis"], 10);
            this.updateAnalysis(idIndicateur, false, "url partagée " + idIndicateur);
        }

        // pour lancer les indicateurs depuis les tableaux de bord
        if (argsFinaux.analysis && url.indexOf("restitution_tableaux_bord") !== -1) {
            url = url.replace("/restitution_tableaux_bord", "/");
            this.props.location.pathname = this.props.location.pathname.replace(
                "/restitution_tableaux_bord",
                "/"
            );
            this.props.history.push("/");
        }
    }

    updateAnalysis(analysis, options, provenance, filtre_initial) {
        this.updateDataLoaded(false);
        let idUtilisateur = this.gestionSuiviConsultations?.idUtilisateur;
        // création d'un tableau qui contient l'ensemble des id analysis que l'on ne souhaite pas faire entrer dans la première condition

        let listIdAnalysis = ["creation_tableaux_bord", "restitution_tableaux_bord"];
        if (this.state.settings.ui_show_plan_actions) {
            listIdAnalysis.push(
                "strategies_territoriales",
                "edition_strategie",
                "fiche_didactique",
                "simulateur"
            );
        }
        if (this.state.settings.ui_show_analyse) {
            listIdAnalysis.push(
                "suivi_energetique",
                "synthese_territoriale",
                "suivi_emission_ges",
                "suivi_polluants_covnm",
                "suivi_polluants_nh3",
                "suivi_polluants_nox",
                "suivi_polluants_pm10",
                "suivi_polluants_pm25",
                "suivi_polluants_so2",
                "diagramme_sankey"
            );
        }

        if (
            !listIdAnalysis.includes(analysis) &&
            analysis &&
            (!(analysis instanceof String) || !analysis.startsWith("national"))
        ) {
            // Fetch data
            let filtreInitial = {};
            let filtreInitialValeur = undefined;
            if (filtre_initial) {
                filtreInitial = filtre_initial;
            } else {
                if (analysis) {
                    filtreInitial = this.analysisManager.initFiltersByCategory(
                        parseInt(analysis, 10)
                    );
                    filtreInitialValeur =
                        this.analysisManager.getFilterDefaultValue(analysis);
                }
            }

            // We need to reset filters
            if (filtreInitialValeur) {
                this.setState({
                    fluxThreshold: filtreInitialValeur,
                });
            }
            this.setState({
                planActions: undefined,
                territorialSynthesis: undefined,
                confidFilteredPieId: undefined,
                localMapFilter: filtreInitial,
            });

            this.state.analysisManager.fetchData(
                analysis,
                this.state.zone,
                this.state.currentZone,
                (currentAnalysis) => {
                    // we handle two cases where analysis is disabled at this level
                    let newAnalysis =
                        analysis !== "" ? parseInt(analysis, 10) : undefined;
                    if (currentAnalysis && currentAnalysis.disabled_for_macro_level) {
                        let forbiddenLevels =
                            currentAnalysis.disabled_for_macro_level.split(",");
                        if (forbiddenLevels.includes(this.state.zone.zone)) {
                            newAnalysis = undefined;
                        }
                    }
                    if (currentAnalysis && currentAnalysis.disabled_for_zone) {
                        let forbiddenDivs =
                            currentAnalysis.disabled_for_zone.split(",");
                        if (forbiddenDivs.includes(this.state.zone.maille)) {
                            newAnalysis = undefined;
                        }
                    }
                    this.setState({
                        analysis: newAnalysis,
                        analysisSelectedYear: undefined,
                        analysisSelectedUnit: undefined,
                        dataLoaded: true,
                        filtreCategorieCourant: "",
                        localMapFilter: filtreInitial,
                    });
                },
                true,
                filtreInitial,
                provenance,
                idUtilisateur,
                filtreInitialValeur,
                true
            );
        } else {
            if (this.state.zonesManager) {
                if (!this.state.zonesManager.zoneLists) {
                    // Si les composants sont lancés depuis une URL, il faut attendre que les territoires soient chargés
                    this.state.zonesManager.fetchZones();
                }
            }
            if (analysis === "strategies_territoriales") {
                this.setState({
                    affichagePoiNecessaire: true,
                    analysis: undefined,
                    analysisSelectedYear: undefined,
                    analysisSelectedUnit: undefined,
                    planActions: analysis,
                    dataLoaded: true,
                    fromMenu: options?.fromMenu ?? false,
                });
                if (this.props.location.pathname !== "/strategies_territoriales") {
                    this.props.history.push("/strategies_territoriales");
                }
            }
            if (analysis === "edition_strategie") {
                this.setState({
                    affichagePoiNecessaire: true,
                    analysis: undefined,
                    analysisSelectedYear: undefined,
                    analysisSelectedUnit: undefined,
                    planActions: analysis,
                    dataLoaded: true,
                    fromMenu: options?.fromMenu ?? false,
                });
                if (options.id === undefined) {
                    // Anonymous strategy
                    if (this.props.location.pathname !== "/edition_strategie") {
                        this.props.history.push(
                            "/edition_strategie" +
                                `?zone=${this.state.zone.zone}` +
                                `&zone_id=${this.state.currentZone}`
                        );
                    }
                } else {
                    // Existing strategy
                    if (
                        this.props.location.pathname !==
                        "/edition_strategie/" + options.id
                    ) {
                        this.props.history.push("/edition_strategie/" + options.id);
                    }
                }
            }
            if (analysis === "creation_tableaux_bord") {
                let fromMenu = false; // special case to watch if the plan_actions has been launched from the menu of from the analysis list
                if (options && options.fromMenu) {
                    fromMenu = options.fromMenu;
                }
                // special case
                this.setState({
                    affichagePoiNecessaire: true,
                    analysis: undefined,
                    analysisSelectedYear: undefined,
                    analysisSelectedUnit: undefined,
                    planActions: analysis,
                    dataLoaded: true,
                    fromMenu: fromMenu,
                    currentMenu: "tableaux-bord",
                });

                if (this.props.location.pathname !== "/creation_tableaux_bord") {
                    this.props.history.push("/creation_tableaux_bord");
                }
            }
            if (analysis === "synthese_territoriale") {
                this.setState({
                    affichagePoiNecessaire: true,
                    analysis: undefined,
                    analysisSelectedYear: undefined,
                    analysisSelectedUnit: undefined,
                    territorialSynthesis: analysis,
                    dataLoaded: true,
                });
                if (this.props.location.pathname !== "/synthese_territoriale") {
                    this.props.history.push("/synthese_territoriale");
                }
            }
            if (analysis === "suivi_energetique") {
                this.setState({
                    affichagePoiNecessaire: true,
                    dataLoaded: true,
                    currentMenu: "suivi-energetique",
                    analysis: undefined,
                    analysisSelectedYear: undefined,
                    analysisSelectedUnit: undefined,
                });
                if (this.props.location.pathname !== "/suivi_energetique") {
                    this.props.history.push("/suivi_energetique");
                }
            }
            if (analysis === "suivi_emission_ges") {
                this.setState({
                    affichagePoiNecessaire: true,
                    dataLoaded: true,
                    currentMenu: analysis,
                    analysis: undefined,
                    analysisSelectedYear: undefined,
                    analysisSelectedUnit: undefined,
                });
                if (this.props.location.pathname !== "/suivi_emission_ges") {
                    this.props.history.push("/suivi_emission_ges");
                }
            }
            if (analysis === "diagramme_sankey") {
                let newSankey = this.state.sankeySelected;
                if (options && options["tableName"]) {
                    newSankey = options["tableName"];
                }

                this.setState({
                    affichagePoiNecessaire: true,
                    dataLoaded: true,
                    currentMenu: "diagramme_sankey",
                    sankeySelected: newSankey,
                    analysis: undefined,
                    analysisSelectedYear: undefined,
                    analysisSelectedUnit: undefined,
                });
                this.props.history.push(
                    "/diagramme_sankey" +
                        (options && options["tableName"]
                            ? "?sankey=" + options["tableName"]
                            : "")
                );
            }
            if (
                [
                    "suivi_polluants_covnm",
                    "suivi_polluants_nh3",
                    "suivi_polluants_nox",
                    "suivi_polluants_pm10",
                    "suivi_polluants_pm25",
                    "suivi_polluants_so2",
                    "fiche_didactique",
                ].includes(analysis)
            ) {
                this.setState({
                    affichagePoiNecessaire: true,
                    dataLoaded: true,
                    currentMenu: analysis,
                });
                if (this.props.location.pathname !== "/" + analysis) {
                    this.props.history.push("/" + analysis);
                }
            }
            if (analysis === "simulateur") {
                this.setState({
                    affichagePoiNecessaire: true,
                    dataLoaded: true,
                    currentMenu: "plan-action",
                });
                if (this.props.location.pathname !== "/" + analysis) {
                    this.props.history.push("/" + analysis);
                }
            }
            if (analysis === "restitution_tableaux_bord") {
                this.setState({
                    affichagePoiNecessaire: true,
                    analysis: undefined,
                    analysisSelectedYear: undefined,
                    analysisSelectedUnit: undefined,
                    planActions: analysis,
                    dataLoaded: true,
                    currentMenu: "tableaux-bord",
                });
                if (this.props.location.pathname !== "/restitution_tableaux_bord") {
                    this.props.history.push("/restitution_tableaux_bord");
                }
            }
        }
    }

    updateDataLoaded = (dataloaded) => {
        if (this.state.dataLoaded === dataloaded) return;
        this.setState({ dataLoaded: dataloaded });
    };

    updateZoneType = (zone, zoneId, callback) => {
        if (
            this.state.zone.zone === zone.zone &&
            this.state.zone.maille === zone.maille &&
            this.state.currentZone === zoneId
        )
            return;
        zoneId = typeof zoneId !== "undefined" ? zoneId : "";
        this.setState({ zone: zone, currentZone: zoneId }, callback);
    };

    updateZone = (zoneType, zoneMaille, zoneId, keepLastZone = false) => {
        if (
            this.state.zone.zone === zoneType &&
            this.state.zone.maille === zoneMaille &&
            this.state.currentZone === zoneId
        )
            return;

        const analysis = this.state.analysis;
        this.setState({
            zone: { zone: zoneType, maille: zoneMaille },
            currentZone: zoneId,

            analysis: undefined,
            analysisSelectedYear: undefined,
            analysisSelectedUnit: undefined,
        });
        if (!keepLastZone) {
            this.setState({
                lastZone: undefined,
                showReset: false,
            });
        }

        const filtreInitial = this.state.analysisManager?.initFiltersByCategory(
            parseInt(analysis)
        );
        this.setState({
            mapFilter: filtreInitial,
        });

        this.updateDataLoaded(false);
        if (this.state.settings?.ui_show_tableau_bord) {
            this.updateDashboardsList(zoneType, zoneId);
        }

        // Fetch data
        this.state.analysisManager?.fetchData(
            analysis,
            { zone: zoneType, maille: zoneMaille },
            zoneId,
            (success) => {
                // we try to avoid removing loader when still loading wms_feed
                let dataLoaded = success?.type !== "wms_feed" || this.state.dataLoaded;
                this.setState({
                    analysis: success ? analysis : undefined,
                    dataLoaded: dataLoaded,
                    filtreCategorieCourant: "",
                    localMapFilter: filtreInitial,
                });
            },
            true,
            filtreInitial,
            "Changement zone " + zoneId,
            this.state.gestionSuiviConsultations?.idUtilisateur,
            undefined,
            true
        );
    };

    updateDashboardsList = (zone, codeInseeTerritoire) => {
        // On crée un nouveau DashboardService pour lui affecter un type de territoire
        if (!zone) {
            zone = this.state.zone.zone;
        }

        if (!codeInseeTerritoire) {
            codeInseeTerritoire = this.state.currentZone;
        }

        let wasEditing = false;
        if (this.state.dashboardManager.wasEditing) {
            wasEditing = true;
        }

        this.dashboardManager = new DashboardService(
            () => {
                // lui donner le type de territoire sélectionné
                this.setState({
                    dashboardManager: this.dashboardManager,
                });
            },
            this.region,
            this.regionCode,
            zone,
            codeInseeTerritoire,
            wasEditing
        );
    };

    focusTerritory = (zoneId) => {
        this.setState({
            lastZone: {
                zoneType: this.state.zone.zone,
                zoneMaille: this.state.zone.maille,
                zoneId: this.state.currentZone,
            },
            showReset: true,
        });
        this.updateZone(this.state.zone.maille, this.state.zone.maille, zoneId, true);
    };

    reset = () => {
        if (this.state.lastZone) {
            const { zoneType, zoneMaille, zoneId } = this.state.lastZone;
            this.updateZone(zoneType, zoneMaille, zoneId);
        }
    };

    /**
     * Renders the component.
     * @returns nothin
     */
    render() {
        const mettreAJourParametresUrls = (parametresUrl, title = "TerriSTORY") => {
            if (
                this.state.urlPartageable !== parametresUrl ||
                this.props.location.search !== parametresUrl
            ) {
                this.setState({
                    urlPartageable: parametresUrl,
                });
                window.history.pushState("", title, parametresUrl);
            }
        };

        const goToURL = (url) => {
            this.props.history.push(url);
        };

        const typeConfid = (typeConfid) => {
            if (
                JSON.stringify(this.state.confidActuelle) !== JSON.stringify(typeConfid)
            ) {
                this.setState({
                    confidActuelle: typeConfid,
                });
            }
        };

        const tailleDiv = (taille) => {
            if (this.state.tailleDiv !== taille) {
                this.setState({
                    tailleDiv: taille,
                });
            }
        };

        const updateAnalysisNameColor = (idAnalysisColor) => {
            let temp = this.state.analysisNameColor;
            const foundIndex = temp.findIndex((x) => x.id === idAnalysisColor.id);
            if (foundIndex !== -1) {
                temp[foundIndex] = idAnalysisColor;
            } else {
                temp.push(idAnalysisColor);
            }

            this.setState({
                analysisNameColor: temp,
            });
        };

        const updateDidacticFileNameColor = (idFileColor) => {
            let temp = this.state.didacticFileNameColor;
            const foundIndex = temp.findIndex((x) => x.id === idFileColor.id);
            if (foundIndex !== -1) {
                temp[foundIndex] = idFileColor;
            } else {
                temp.push(idFileColor);
            }
            this.setState({
                didacticFileNameColor: temp,
            });
        };

        const updateLinkNameColor = (idFileColor) => {
            let temp = this.state.linkNameColor;
            const foundIndex = temp.findIndex((x) => x.id === idFileColor.id);
            if (foundIndex !== -1) {
                temp[foundIndex] = idFileColor;
            } else {
                temp.push(idFileColor);
            }
            this.setState({
                linkNameColor: temp,
            });
        };

        const reinitialiserFiltreValeur = (valeur) => {
            if (JSON.stringify(this.state.fluxThreshold) !== JSON.stringify(valeur)) {
                this.setState({
                    fluxThreshold: valeur,
                });
            }
        };

        const displayChart = (display, stationMeteoName, stationAltitude) => {
            if (
                display !== this.state.displayChart ||
                stationMeteoName !== this.state.stationMeteoName ||
                stationAltitude !== this.state.stationAltitude
            ) {
                this.setState({
                    displayChart: display,
                    stationMeteoName: stationMeteoName,
                    stationAltitude: stationAltitude,
                });
            }
        };

        const displayAnalysis = (display) => {
            if (display !== this.state.displayAnalysis) {
                this.setState({
                    displayAnalysis: display,
                });
            }
        };

        const reset = this.reset;

        /**
        Déclenchée sur événement dans le composant DashboardEdition
        Ajoute une nouvelle analyse dans une thématique ciblée
        @param  {entier} numeroThematique : identifiant incrémental de la thématique ou de l'enjeu dans laquelle est située l'analyse
        @param  {entier} id_analyse : numéro de l'analyse (identifiant incrémental)
        @param  {objet clé => valeur} nouvelleAnalyse : Nouvelle analyse avec ses caractéristiques. La strucutre de l'objet est la suivante :
        {
            id_analysis : identifiant en base de données de l'analyse,
            representation: représentation sélectionnée par l'utilisateur.trice,
            catagories: { (objet modifiable si l'utilisateur.trice souhaite désactivé certains graphiques associés à ces catégories.).
                nom_categorie1 {
                    {
                        categorie: nom de la catégorie,
                        titre: titre de la catégorie
                    }
                }
                etc.
            }
        }
        */
        const updateDashboard = (
            numeroThematique,
            titreThematique,
            description,
            code_analyse,
            nouvelleAnalyse
        ) => {
            let tableauBordDonnees = this.state.tableauBordDonnees;
            if (!tableauBordDonnees.donnees[numeroThematique]) {
                // Si l'objet est vide
                // on calcule la valeur maximale de l'ordre
                let maxVal = -1;
                Object.keys(tableauBordDonnees.donnees).forEach((id) => {
                    if (tableauBordDonnees.donnees[id].ordre > maxVal) {
                        maxVal = tableauBordDonnees.donnees[id].ordre;
                    }
                });
                tableauBordDonnees.donnees[numeroThematique] = {
                    indicateurs: {},
                    titre_thematique: "Nouvelle thématique",
                    description: {},
                    ordre: maxVal + 1,
                }; // On l'initialise avec un nouvel objet vide
            }
            if (nouvelleAnalyse) {
                tableauBordDonnees.donnees[numeroThematique]["indicateurs"][
                    code_analyse
                ] = nouvelleAnalyse; // Sinon, on ajoute la nouvelle analyse
            }
            tableauBordDonnees.donnees[numeroThematique]["description_thematique"] =
                description;
            tableauBordDonnees.donnees[numeroThematique]["titre_thematique"] =
                titreThematique;
            this.setState({
                tableauBordDonnees: tableauBordDonnees, // Mise à jour des données du tableau de bord
            });
        };

        /**
         * Echange la place dans l'ordre de deux thématiques.
         * @param {integer} idThematique1 l'ID de la première thématique
         * @param {integer} idThematique2 l'ID de la deuxième thématique
         */
        const updateGroupsOrderInDashboard = (idThematique1, idThematique2) => {
            // on retourne false si on n'a rien à faire
            if (idThematique1 === idThematique2) {
                return false;
            }

            let tableauBordDonnees = this.state.tableauBordDonnees;
            // on vérifie qu'on a des données
            if (
                !tableauBordDonnees.donnees[idThematique1] ||
                !tableauBordDonnees.donnees[idThematique2]
            ) {
                return false;
            }
            // on vérifie que les deux ordres sont valides
            let ordre1 = tableauBordDonnees.donnees[idThematique1].ordre;
            let ordre2 = tableauBordDonnees.donnees[idThematique2].ordre;
            if (
                ordre1 !== undefined &&
                ordre2 !== undefined &&
                ordre1 !== false &&
                ordre2 !== false
            ) {
                tableauBordDonnees.donnees[idThematique1].ordre = ordre2;
                tableauBordDonnees.donnees[idThematique2].ordre = ordre1;
                this.setState({
                    tableauBordDonnees: tableauBordDonnees, // Mise à jour des données du tableau de bord
                });
                return true;
            } else {
                return false;
            }
        };

        const deleteGroupInDashboard = (numeroThematique) => {
            /**
            Déclenchée sur événement dans le composant DashboardEdition.
            Supprime une thématique des données du tableau de bord
            @param  {entier} numeroThematique : identifiant incrémental de la thématique
            */

            // on aurait le choix ici : soit baisser tous les ordres > à celui supprimé
            // de un pour combler le trou, soit tout réordonner pour garantir l'intégrité
            // on préfère garantir l'intégrité
            let tableauBordDonnees = this.state.tableauBordDonnees;
            delete tableauBordDonnees.donnees[numeroThematique];
            // on crée le tableau permettant d'ordonner les thématiques
            let ordreThematiques = {};
            for (let numeroThematique in tableauBordDonnees.donnees) {
                // Pour chaque thématique
                let ordreThematique =
                    tableauBordDonnees.donnees[numeroThematique].ordre;
                // il nous faut gérer le cas où l'ordre n'est pas disponible (pas normal)
                // ou bien s'il est déjà présent (pas normal non plus)
                if (
                    ordreThematique === undefined ||
                    ordreThematique === null ||
                    ordreThematique === false ||
                    isNaN(ordreThematique) ||
                    Object.keys(ordreThematiques).includes(ordreThematique)
                ) {
                    // s'il n'y a pas encore d'objet dans le tableau, on met 0
                    if (Object.keys(ordreThematiques).length === 0) {
                        ordreThematique = 0;
                    } else {
                        // on utilise l'ordre max + 1 (on met à la fin quoi)
                        // cette formule reste valide même si on continue de remplir le tableau
                        ordreThematique = Math.max(Object.keys(ordreThematiques)) + 1;
                    }
                }
                // on sauvegarde l'ID au niveau de l'ordre
                ordreThematiques[ordreThematique] = numeroThematique;
            }
            let ordresThematiquesOrdonnees = Object.keys(ordreThematiques)
                .map(Number)
                .sort(function (a, b) {
                    return a - b;
                });

            let i = 0;
            ordresThematiquesOrdonnees.forEach((ordre) => {
                let numeroThematique = ordreThematiques[ordre];
                tableauBordDonnees.donnees[numeroThematique].ordre = i;
                i++;
            });

            this.setState({
                tableauBordDonnees: tableauBordDonnees,
            });
        };

        const miseAJourNomThematique = (numeroThematique, nouveauNomThematique) => {
            /**
            Déclenchée sur événement dans le composant DashboardEdition.
            À chaque fois qu'on modifie le nom de la ligne, cette
            fonction met à jour this.state.tableauBordDonnees de ce
            composant Main (index.js)
            @param {entier} numeroThematique : Identifiant incrémental initial de la ligne dont on change le nom
            @param {chaine de caractères} nouveauNomLigne : Nouveau nom donné à la ligne
            */
            let tableauBordDonnees = this.state.tableauBordDonnees;
            tableauBordDonnees.donnees[numeroThematique].titre_thematique =
                nouveauNomThematique;
            this.setState({
                tableauBordDonnees: tableauBordDonnees,
            });
        };

        const miseAJourDescriptionThematique = (
            numeroThematique,
            nouvelleDescription
        ) => {
            /**
            Déclenchée sur événement dans le composant DashboardEdition.
            À chaque fois qu'on modifie le nom de la ligne, cette
            fonction met à jour this.state.tableauBordDonnees de ce
            composant Main (index.js)
            @param {chaine de caractères} numeroThematique : Identifiant incrémental initial de la ligne dont on change le nom
            @param {chaine de caractères} nouvelleDescription : Nouvelle description donnée à la thématique
            */
            let tableauBordDonnees = this.state.tableauBordDonnees;

            tableauBordDonnees.donnees[numeroThematique].description_thematique =
                nouvelleDescription;
            this.setState({
                tableauBordDonnees: tableauBordDonnees,
            });
        };

        const removeIndicatorFromDashboard = (thematique, code_analyse) => {
            /**
            Déclenchée sur événement dans le composant DashboardEdition
            Supprime l'analyse dont le numéro est « code_analyse » dans une thématique ciblée.
            @param  {chaine de caractère} thematique : nom de la thématique ou de l'enjeu dans alquelle est située l'analyse
            @param  {entier} cle_analyse : numéro de l'analyse (identifiant incrémental)
            */
            delete this.state.tableauBordDonnees.donnees[thematique].indicateurs[
                code_analyse
            ];
            this.setState({
                tableauBordDonnees: this.state.tableauBordDonnees,
            });
        };

        const toggleCategoriesSelectionForIndicatorInDashboard = (
            numeroThematique,
            cle_analyse,
            categorie,
            cochee
        ) => {
            /**
            Déclenchée sur événement dans le composant DashboardEdition
            Supprimee ou restaure un graphique associé à la catégorie précisée en paramètre de la méthode
            @param  {entier} numeroThematique : Identifiant incrémental de la thématique ou de l'enjeu dans alquelle est située l'analyse
            @param  {entier} cle_analyse : numéro de lo'analyse (identifiant incrémental)
            @param  {chaine de caractère} categorie : nom de la catégorie à supprimer ou restaurer à l'aide d'une case qu'on coche ou décoche.
            */

            let tableauBordDonnees = this.state.tableauBordDonnees;
            if (!cochee) {
                // Si la catégorie existe alors on la supprime
                tableauBordDonnees.donnees[numeroThematique].indicateurs[cle_analyse][
                    "categories"
                ][categorie].visible = false;
            } else {
                // Sinon, le graphique associé a déjà été supprimée (correspond à une case décochée) et auquel cas, on le restaure
                tableauBordDonnees.donnees[numeroThematique].indicateurs[cle_analyse][
                    "categories"
                ][categorie].visible = true;
            }

            this.setState({
                tableauBordDonnees: tableauBordDonnees,
            });
        };

        const ajouterMetaDonneesTableauBord = (titre, description) => {
            /**
            Déclenchée sur événement dans le composant DashboardEdition
            Met à jour la structure des données su tableau de bord en y ajoutant les
            métadonnées (titre et description).
            @param  {chaine de caractère} titre : titre du tableau de bord
            @param  {chaine de caractère} description : description du tableau de bord
            */
            let tableauBordDonnees = this.state.tableauBordDonnees;
            if (titre === undefined) {
                tableauBordDonnees.metadonnees.description = description;
            } else if (description === undefined) {
                tableauBordDonnees.metadonnees.titre = titre;
            } else {
                tableauBordDonnees.metadonnees = {
                    titre: titre,
                    description: description,
                };
            }

            this.setState({
                tableauBordDonnees: tableauBordDonnees,
            });
        };

        const chargerTableauBord = (tableauBordDonnees) => {
            /**
            Met à jour this.state.tableauBordDonnees à partir des donnée
            passées en paramètres de la fonction. Ainsi initialisé, le
            tableau de bord peut être chargé dans le composant DashboardEdition (DashboardEdition.js)
            @param {objet clé => valeur} tableauBordDonnees : dictionnaire au sein duquel sont structurées toutes les données relatives au tableau de bord
            Pour en savoir davantage sur la structuration de cet objet, consultez DashboardEdition.js
            */
            this.setState({
                tableauBordDonnees: tableauBordDonnees,
            });
        };

        const chargementListeTableauxDeBordNecessaire = (chargementNecessaire) => {
            this.setState({
                chargementListeTableauxDeBordNecessaire: chargementNecessaire,
            });
        };

        const definirTableauBordCourant = (id_tableau_bord) => {
            /**
            Met à jour le paramètre this.state.tableauBordCourant avec l'identifiant
            du tableau de bord qu'on sélectionne dans le menu de gauche
            @param {entier} id_tableau_bord : Identifiant du tableau de bord
            */
            this.setState({
                tableauBordCourant: id_tableau_bord,
            });
        };

        const updateCurrentDidacticFile = (didactic_file_id) => {
            this.setState({
                currentDidacticFile: didactic_file_id,
            });
        };

        const representationCourante = (representation) => {
            this.setState({
                representationCourante: representation,
            });
        };

        const updateZone = this.updateZone;
        const updateZoneType = this.updateZoneType;

        /**
         * Update the year selected for an indicator. This function will trigger
         * fetchData from the analysis Manager to retrieve data if the input year
         * is different from existing selected year.
         *
         * @param {integer} newYear year selected
         */
        const updateSelectedYearAnalysis = (newYear) => {
            let filtreInitial = this.state.localMapFilter;
            let filtreInitialValeur = this.state.fluxThreshold;

            let idUtilisateur = this.state.gestionSuiviConsultations?.idUtilisateur;

            this.setState({ dataLoaded: false });
            let { zone, currentZone } = this.state;
            this.state.analysisManager.fetchData(
                this.state.analysis,
                zone,
                currentZone,
                () => {
                    this.setState({
                        analysisSelectedYear: newYear,
                        dataLoaded: true,
                    });
                },
                true,
                filtreInitial,
                "changing-year-analysis",
                idUtilisateur,
                filtreInitialValeur,
                false, // do not reset filters when just changing year
                newYear,
                this.state.analysisSelectedUnit
            );
        };

        /**
         * Update the year selected for an indicator. This function will trigger
         * fetchData from the analysis Manager to retrieve data if the input year
         * is different from existing selected year.
         *
         * @param {integer} newUnit unit selected
         */
        const updateSelectedUnitAnalysis = (newUnit) => {
            let filtreInitial = this.state.localMapFilter;
            let filtreInitialValeur = this.state.fluxThreshold;

            let idUtilisateur = this.state.gestionSuiviConsultations?.idUtilisateur;

            this.setState({ dataLoaded: false });
            let { zone, currentZone } = this.state;
            this.state.analysisManager.fetchData(
                this.state.analysis,
                zone,
                currentZone,
                () => {
                    this.setState({
                        analysisSelectedUnit: newUnit,
                        dataLoaded: true,
                    });
                },
                true,
                filtreInitial,
                "changing-unit-analysis",
                idUtilisateur,
                filtreInitialValeur,
                false, // do not reset filters when just changing year
                this.state.analysisSelectedYear,
                newUnit
            );
        };

        const miseAJourUiTheme = (uiTheme) => {
            this.setState({
                uiTheme: uiTheme,
            });
        };

        const majAffichagePoiNecessaire = (affichagePoiNecessaire) => {
            this.setState({
                affichagePoiNecessaire: affichagePoiNecessaire,
            });
        };

        const miseAjourNomIndicateurCourant = (nomIndicateurCourant) => {
            this.setState({
                nomIndicateurCourant: nomIndicateurCourant,
            });
        };

        const miseAjourNomInstallationCourante = (
            nomInstallationCourante,
            idInstallationCourante,
            uiTheme
        ) => {
            this.setState({
                nomInstallationCourante: nomInstallationCourante,
                idInstallationCourante: idInstallationCourante,
                uiTheme: uiTheme,
            });
        };

        const updateNationalTerritory = (isClicked, region) => {
            if (region) {
                this.zonesManager = new Zones(() => {
                    this.setState({
                        dataZonesLoaded: true,
                        zonesManager: this.zonesManager,
                    });
                }, region);
            }
            this.setState({
                isOtherTerritory: isClicked,
                region: region,
                isNationalRegion: true,
            });
        };

        const handleAuthComplete = (status) => {
            this.setState({
                authComplete: status,
            });
        };
        const updateDataLoaded = this.updateDataLoaded;

        const updateSingleValueMapFilter = (filtre_categorie, id) => {
            this.updateDataLoaded(false);
            let identifiant_indicateur = this.state.analysis;

            // we update by replacing local map filter
            let localMapFilter = this.state.localMapFilter ?? {};
            if (Object.keys(localMapFilter).length === 0) {
                const defaultFilters = this.state.analysisManager.initFiltersByCategory(
                    parseInt(identifiant_indicateur, 10)
                );
                Object.assign(localMapFilter, defaultFilters);
            }
            localMapFilter[id] = [{ filtre_categorie }];

            let provenance = "filtre " + filtre_categorie;
            let filtreCategorieCourant = "";

            if (filtre_categorie) {
                filtreCategorieCourant = this.state.analysis + filtre_categorie;
            }
            let idUtilisateur = this.state.gestionSuiviConsultations?.idUtilisateur;

            this.state.analysisManager.fetchData(
                identifiant_indicateur,
                this.state.zone,
                this.state.currentZone,
                () => {
                    this.setState({
                        filtreCategorieCourant: filtreCategorieCourant,
                        dataLoaded: true,
                        localMapFilter: localMapFilter,
                    });
                },
                true,
                localMapFilter,
                provenance,
                idUtilisateur,
                this.state.fluxThreshold,
                false,
                // keep selected year and unit when filtering
                this.state.analysisSelectedYear,
                this.state.analysisSelectedUnit
            );
        };
        const updateRegionName = (region, callback = false) => {
            const isNationalRegion =
                this.state.regionsSettings?.get(region)?.is_national;
            this.setState({ region: region, isNationalRegion });
            if (callback) callback();
        };
        const updateAuthManager = (authManager) => {
            this.setState({ authManager: authManager });
        };
        const updateMapFilter = (filtre_categorie, filtre, id, activate) => {
            this.updateDataLoaded(false);
            let filtreCategorieCourant = "";
            let identifiant_indicateur = this.state.analysis;

            let localMapFilter = this.state.localMapFilter ?? {};
            if (
                id && // we don't want undefined id
                (Object.keys(localMapFilter).length === 0 || !localMapFilter[id])
            ) {
                const defaultFilters = this.state.analysisManager.initFiltersByCategory(
                    parseInt(identifiant_indicateur, 10)
                );
                if (Object.keys(localMapFilter).length === 0) {
                    Object.assign(localMapFilter, defaultFilters);
                } else {
                    localMapFilter[id] = defaultFilters[id];
                }
            }
            let suppr = false;
            if (id) {
                if (activate === undefined) {
                    if (localMapFilter[id]) {
                        for (let f in localMapFilter[id]) {
                            if (
                                localMapFilter[id][f] &&
                                localMapFilter[id][f].filtre_categorie ===
                                    filtre_categorie
                            ) {
                                suppr = true;
                                delete localMapFilter[id][f];
                                localMapFilter[id] = localMapFilter[id].flat();
                            }
                        }
                    }
                    if (!suppr) {
                        if (!localMapFilter[id]) {
                            localMapFilter[id] = [];
                        }
                        localMapFilter[id].push({
                            filtre_categorie: filtre_categorie,
                        });
                    }
                } else {
                    if (activate) {
                        if (!localMapFilter[id]) {
                            localMapFilter[id] = [];
                        }
                        localMapFilter[id].push({
                            filtre_categorie: filtre_categorie,
                        });
                    } else {
                        for (let f in localMapFilter[id]) {
                            if (
                                localMapFilter[id][f] &&
                                localMapFilter[id][f].filtre_categorie ===
                                    filtre_categorie
                            ) {
                                suppr = true;
                                delete localMapFilter[id][f];
                                localMapFilter[id] = localMapFilter[id].flat();
                            }
                        }
                    }
                }
            }

            let filtre_valeur = 0;

            if (filtre) {
                filtre_valeur = filtre;
            }
            let provenance = "filtre " + filtre_categorie + " " + filtre;
            if (filtre_categorie) {
                filtreCategorieCourant = this.state.analysis + filtre_categorie + suppr;
            }
            let idUtilisateur = this.state.gestionSuiviConsultations?.idUtilisateur;
            // Fetch data with filter
            const savedFilters = JSON.stringify(localMapFilter);
            this.state.analysisManager.fetchData(
                identifiant_indicateur,
                this.state.zone,
                this.state.currentZone,
                () => {
                    if (
                        this.state.localMapFilter &&
                        JSON.stringify(this.state.localMapFilter) !== savedFilters
                    ) {
                        console.log("Will not update or it could rollback!");
                        return;
                    }
                    this.setState({
                        filtreCategorieCourant: filtreCategorieCourant,
                        dataLoaded: true,
                        fluxThreshold: filtre_valeur,
                        localMapFilter: localMapFilter,
                    });
                },
                true,
                localMapFilter,
                provenance,
                idUtilisateur,
                filtre_valeur,
                false,
                // keep selected year and unit when filtering
                this.state.analysisSelectedYear,
                this.state.analysisSelectedUnit
            );
        };
        const updateProvenance = (provenance) => {
            this.setState({ provenance: provenance });
        };

        const updateAnalysis = (analysis, options, provenance) => {
            this.updateAnalysis(analysis, options, provenance);
        };

        const updateDashboardsList = this.updateDashboardsList;

        const initialiserTableauBord = (tableauBordInitial) => {
            this.setState({
                tableauBordDonnees: tableauBordInitial,
            });
        };

        const initialiserTableauBordApresConnexion = (
            reinitialiserTableauBordApresDeconnexion
        ) => {
            this.setState({
                reinitialiserTableauBordApresDeconnexion:
                    reinitialiserTableauBordApresDeconnexion,
            });
        };

        const updateAnalysisMeta = (meta) => {
            // we test we are not entering an infinite loop
            if (JSON.stringify(this.state.analysisMeta) !== JSON.stringify(meta)) {
                this.setState({ analysisMeta: meta });
            }
        };
        /*
         * Mise à jour de la liste des analyses
         */
        const updateAnalysisList = () => {
            this.analysisManager.fetchConfiguration(() => {
                this.setState({
                    analysisManager: this.analysisManager,
                });
            });
        };
        const updateInfos = (infos) => {
            this.setState({ infos: infos });
        };
        const updatePoiLayers = (poiLayers) => {
            this.setState({ poiLayers: poiLayers });
        };
        const updateMetaStyle = (style) => {
            this.setState({ metaStyle: style });
        };

        const updateConnected = (connected) => {
            let tableauBordInitial = {
                donnees: {
                    1: {
                        titre_thematique: "Titre de la thématique",
                        indicateurs: {
                            1: {
                                numero_analyse: "1",
                                categories: {},
                            },
                            2: {
                                numero_analyse: "2",
                                categories: {},
                            },
                            3: {
                                numero_analyse: "3",
                                categories: {},
                            },
                        },
                        description_thematique: "Description de la thématique",
                        ordre: 0,
                    },
                    2: {
                        titre_thematique: "Titre de la thématique",
                        indicateurs: {
                            4: {
                                numero_analyse: "4",
                                categories: {},
                            },
                            5: {
                                numero_analyse: "5",
                                categories: {},
                            },
                            6: {
                                numero_analyse: "6",
                                categories: {},
                            },
                        },
                        description_thematique: "Description de la thématique",
                        ordre: 1,
                    },
                    3: {
                        titre_thematique: "Titre de la thématique",
                        indicateurs: {
                            7: {
                                numero_analyse: "7",
                                categories: {},
                            },
                            8: {
                                numero_analyse: "8",
                                categories: {},
                            },
                            9: {
                                numero_analyse: "9",
                                categories: {},
                            },
                        },
                        description_thematique: "Description de la thématique",
                        ordre: 2,
                    },
                },
                metadonnees: {},
                charger: false,
                affectationTerritoire: false,
                tableauBordReinitialise: true,
            };
            this.setState({
                connected: connected,
                reinitialiserTableauBordApresDeconnexion: true,
                tableauBordDonnees: tableauBordInitial,
            });
        };

        const updateUtilisateurActif = (utilisateurActif) => {
            this.setState({ utilisateurActif: utilisateurActif });
        };
        const updateSplashScreen = (popupOpened) => {
            this.setState({ splashScreen: popupOpened });
        };
        const updateConfidentialityPie = (id) => {
            this.setState({ confidFilteredPieId: id });
        };
        const updateClassMethod = (method) => {
            this.setState({ classMethod: method });
        };
        const updateToggleMenu = (show) => {
            this.setState({ toggleMenu: show });
        };
        const updateCurrentMenu = (menu) => {
            this.setState({ currentMenu: menu });
        };
        const print = () => {
            this.state.printManager.print(
                this.state.map,
                this.state.toggleMenu,
                this.state.zone.zone
            );
        };
        const updateMapPointer = (map) => {
            this.setState({ map: map });
        };
        const newPoi = (layer) => {
            this.ol.newFeature(layer);
        };
        const updateMessages = (messages, type) => {
            type = typeof type !== "undefined" ? type : "warning";
            this.setState({ messages: messages, typeMessages: type });
        };
        const updateProfil = (profil, publication, accesIndicateursDesactives) => {
            this.setState({
                profil: profil,
                publication: publication,
                accesIndicateursDesactives: accesIndicateursDesactives,
            });
        };
        const updatePoiRights = () => {
            this.state.equipementsManager.refreshPoiLayers(() => {
                this.setState({
                    poiLayers: this.state.equipementsManager.getEquipementsLayers(),
                });
            });
        };
        const setZoneFromUserPreferedTerritory = (preferedTerritory, fromLogin) => {
            if (!preferedTerritory) return;
            if (this.state.depuisUrl && this.state.zone && !fromLogin) return; // URL has priority on prefered territory
            if (
                this.state.zone.zone === preferedTerritory.zoneType &&
                this.state.currentZone === preferedTerritory.zoneId
            )
                return;
            if (fromLogin && window.location.pathname === "/edition_strategie") {
                return;
            }
            this.setState({
                zone: {
                    zone: preferedTerritory.zoneType,
                    maille: preferedTerritory.zoneMaille,
                },
                currentZone:
                    preferedTerritory.zoneType === "region"
                        ? this.state.regionCode
                        : preferedTerritory.zoneId,
            });
        };

        let territoireSelectionne = false;
        if (
            (this.state.zone.zone && this.state.currentZone !== "") ||
            this.state.zone.zone === "region"
        ) {
            territoireSelectionne = true;
        }
        const api = {
            data: {
                zone: this.state.zone,
                currentZone: this.state.currentZone,
                depuisUrl: this.state.depuisUrl,
                dataLoaded: this.state.dataLoaded,
                representation: this.state.representation,
                reinitialiserTableauBordApresDeconnexion:
                    this.state.reinitialiserTableauBordApresDeconnexion,
                filtreCategorieCourant: this.state.filtreCategorieCourant,
                chargementListeTableauxDeBordNecessaire:
                    this.state.chargementListeTableauxDeBordNecessaire,
                analysis: this.state.analysis,
                fromMenu: this.state.fromMenu,
                accesIndicateursDesactives: this.state.accesIndicateursDesactives,
                confidActuelle: this.state.confidActuelle,
                tailleDiv: this.state.tailleDiv,
                analysisNameColor: this.state.analysisNameColor,
                didacticFileNameColor: this.state.didacticFileNameColor,
                linkNameColor: this.state.linkNameColor,
                displayChart: this.state.displayChart,
                stationMeteoName: this.state.stationMeteoName,
                stationAltitude: this.state.stationAltitude,
                displayAnalysis: this.state.displayAnalysis,
                nomTerritoire: this.state.nomTerritoire,
                analysisMeta: this.state.analysisMeta,
                infos: this.state.infos,
                mapFilter: this.state.mapFilter,
                numeroIndicateur: this.state.numeroIndicateur,
                localMapFilter: this.state.localMapFilter,
                poiLayers: this.state.poiLayers,
                metaStyle: this.state.metaStyle,
                connected: this.state.connected,
                profil: this.state.profil,
                confidFilteredPieId: this.state.confidFilteredPieId,
                classMethod: this.state.classMethod,
                fluxThreshold: this.state.fluxThreshold,
                toggleMenu: this.state.toggleMenu,
                currentMenu: this.state.currentMenu,
                affichagePoiNecessaire: this.state.affichagePoiNecessaire,
                representationCourante: this.state.representationCourante,
                messages: this.state.messages,
                typeMessages: this.state.typeMessages,
                provenance: this.state.provenance,
                publication: this.state.publication,
                region: this.state.region,
                regionCode: this.state.regionCode,
                theme: this.state.theme,
                regionLabel: this.state.regionLabel,
                settings: this.state.settings,
                showReset: this.state.showReset,
                tableauBordCourant: this.state.tableauBordCourant,
                currentDidacticFile: this.state.currentDidacticFile,
                tableauBordDonnees: this.state.tableauBordDonnees,
                utilisateurActif: this.state.utilisateurActif,
                regionMap: this.state.regionMap,
                uiTheme: this.state.uiTheme,
                idInstallationCourante: this.state.idInstallationCourante,
                nomInstallationCourante: this.state.nomInstallationCourante,
                idIndicateurCourant: this.state.idIndicateurCourant,
                nomIndicateurCourant: this.state.nomIndicateurCourant,
                urlPartageable: this.state.urlPartageable,
                analysisSelectedYear: this.state.analysisSelectedYear,
                analysisSelectedUnit: this.state.analysisSelectedUnit,
                sankeySelected: this.state.sankeySelected,
                isNationalRegion: this.state.isNationalRegion,
            },
            controller: {
                analysisManager: this.state.analysisManager,
                equipementsManager: this.state.equipementsManager,
                zonesManager: this.state.zonesManager,
                dashboardManager: this.state.dashboardManager,
                gestionSuiviConsultations: this.state.gestionSuiviConsultations,
                authManager: this.state.authManager,
            },
            callbacks: {
                ajouterMetaDonneesTableauBord,
                majAffichagePoiNecessaire,
                chargerTableauBord,
                chargementListeTableauxDeBordNecessaire,
                typeConfid,
                tailleDiv,
                updateProvenance,
                updateAnalysisNameColor,
                updateDidacticFileNameColor,
                updateLinkNameColor,
                displayChart,
                displayAnalysis,
                definirTableauBordCourant,
                updateCurrentDidacticFile,
                initialiserTableauBord,
                initialiserTableauBordApresConnexion,
                reinitialiserFiltreValeur,
                updateZoneType,
                updateZone,
                focusTerritory: this.focusTerritory,
                updateAnalysis,
                updateInfos,
                updateRegionName,
                updateAuthManager,
                updateAnalysisMeta,
                updateAnalysisList,
                updateMapFilter,
                updateSingleValueMapFilter,
                updatePoiLayers,
                updateMetaStyle,
                updateConnected,
                updateUtilisateurActif,
                updateSplashScreen,
                updateConfidentialityPie,
                updateClassMethod,
                updateToggleMenu,
                updateCurrentMenu,
                updateSelectedYearAnalysis,
                updateSelectedUnitAnalysis,
                updateDataLoaded,
                miseAJourDescriptionThematique,
                updateGroupsOrderInDashboard,
                updateDashboard,
                miseAJourNomThematique,
                updateDashboardsList,
                handleAuthComplete,
                mettreAJourParametresUrls,
                goToURL,
                print,
                updateMapPointer,
                newPoi,
                updateMessages,
                updateProfil,
                updatePoiRights,
                representationCourante,
                reset,
                deleteGroupInDashboard,
                removeIndicatorFromDashboard,
                toggleCategoriesSelectionForIndicatorInDashboard,
                miseAJourUiTheme,
                miseAjourNomIndicateurCourant,
                miseAjourNomInstallationCourante,
                updateNationalTerritory,
                setZoneFromUserPreferedTerritory,
            },
        };

        const isRegionalRouter =
            this.state.region &&
            this.state.regions &&
            !this.state.isOtherTerritory &&
            this.state.settings &&
            this.state.region !== "national" &&
            !this.state.isNationalRegion;

        return (
            <>
                {isRegionalRouter ? (
                    <RegionalHandler
                        region={this.state.region}
                        regions={this.state.regions}
                    >
                        <Loading
                            isLoaded={
                                this.state.dataAnalysisLoaded &&
                                this.state.dataZonesLoaded &&
                                this.state.dataPoiLoaded
                            }
                        >
                            <RegionalRouter
                                fromMenu={this.state.fromMenu}
                                settings={this.state.settings}
                                parentApi={api}
                                territoireSelectionne={territoireSelectionne}
                                connected={this.state.connected}
                                analysisManagerFailed={this.state.analysisManagerFailed}
                                splashScreen={this.state.splashScreen}
                                analysisMeta={this.state.analysisMeta}
                                dataLoaded={this.state.dataLoaded}
                                currentDidacticFile={this.state.currentDidacticFile}
                                analysisManager={this.state.analysisManager}
                                tableauBordDonnees={this.state.tableauBordDonnees}
                                tableauBordCourant={this.state.tableauBordCourant}
                                analysis={this.state.analysis}
                                olCallback={(ref) => (this.ol = ref)}
                            />
                        </Loading>
                    </RegionalHandler>
                ) : (
                    <Loading
                        isLoaded={this.state.regions && this.state.regions.length > 0}
                    >
                        <NationalRouter
                            regions={this.state.regions}
                            regionsSettings={this.state.regionsSettings}
                            fromMenu={this.state.fromMenu}
                            parentApi={api}
                            history={this.props.history}
                            connected={this.state.connected}
                            authComplete={this.state.authComplete}
                        />
                    </Loading>
                )}
            </>
        );
    }
}

const helmetContext = {};
ReactDOM.render(
    <Router>
        <HelmetProvider context={helmetContext}>
            <ErrorBoundary>
                <Route component={Main} />
            </ErrorBoundary>
        </HelmetProvider>
    </Router>,
    document.getElementById("root")
);
