/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import { Link } from "react-router-dom";

import NationalFooter from "../Components/StaticPages/NationalFooter.js";

export default function NationalPublicPages({ parentApi, children }) {
    return (
        <div className="home-container">
            <div className="navbar">
                <div className="logo">
                    <Link to="/">
                        <img
                            src="/img/logo_TerriSTORY_small.png"
                            alt="Logo TerriSTORY®"
                            width="100%"
                        ></img>
                    </Link>
                </div>
            </div>
            {children}
            <NationalFooter parentApi={parentApi} />
        </div>
    );
}
