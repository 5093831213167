/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";

import Api from "../../Controllers/Api";
import { buildRegionUrl } from "../../utils.js";
import config from "../../settings.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import * as icons from "react-icons/md";

/**
 * This component is used to create an SVG marker
 */
class MarqueurSVG extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id_analyse: String(props.id_analysis),
            representation: "marqueur-svg",
            widthDOM: window.innerWidth,
            tailleSVG: props.tailleSVG,
            tailleData: props.tailleData,
            icon: props.image,
            disabled: false,
            filters: {},
        };
        // Get data from API
        this.fetchConfiguration();
    }

    componentDidMount() {
        // Retrieve initial filters
        let filters =
            this.props.filters ??
            this.props.parentApi.controller.analysisManager.initFiltersByCategory(
                parseInt(this.props.id_analysis)
            );
        this.setState({ filters: filters });

        let chartsLegendsDiv = document.getElementsByClassName("charts-legende");
        if (chartsLegendsDiv.length > 0) {
            let widthDiv =
                document.getElementsByClassName("charts-legende")[0].offsetWidth;
            if (
                this.props.parentApi.data.tailleDiv !== widthDiv &&
                this.props.provenance === "tableau_de_bord_restitue"
            ) {
                this.props.parentApi.callbacks.tailleDiv(widthDiv);
            }
        }
    }

    /**
     * This function updates the data from which we build the SVG marker when changing territory
     * @param {object key => value} prevProps : main component properties before territory change via selection tool
     */
    componentDidUpdate(prevProps, prevState) {
        let chartsLegendsDiv = document.getElementsByClassName("charts-legende");
        if (chartsLegendsDiv.length > 0) {
            let widthDiv =
                document.getElementsByClassName("charts-legende")[0].offsetWidth;
            if (
                this.props.parentApi.data.tailleDiv !== widthDiv &&
                this.props.provenance === "tableau_de_bord_restitue"
            ) {
                this.props.parentApi.callbacks.tailleDiv(widthDiv);
            }
        }
        let currentZone = this.props.zoneId ?? this.props.parentApi.data.currentZone;

        // changes indicators
        const wasZoneUpdated =
            (prevProps.zoneId ?? prevProps.parentApi.data.currentZone) !==
                currentZone && this.state.id_analyse;
        const wereFiltersUpdated =
            JSON.stringify(this.state.filters) !== JSON.stringify(prevState.filters);
        const werePropsFiltersForced =
            this.props.forcedFilters &&
            JSON.stringify(this.props.forcedFilters) !==
                JSON.stringify(prevProps.forcedFilters);

        if (wasZoneUpdated || wereFiltersUpdated) {
            this.fetchConfiguration();
        } else if (werePropsFiltersForced) {
            this.setState({
                filters: {
                    ...this.state.filters,
                    ...this.props.forcedFilters,
                },
            });
        }
    }

    componentWillUnmount() {
        if (this.dataPromise) {
            this.dataPromise.abort();
        }
    }

    fetchConfiguration() {
        let pZone =
            "?zone=" + (this.props.zoneType ?? this.props.parentApi.data.zone.zone);
        let pMaille =
            "&maille=" +
            (this.props.zoneMaille ?? this.props.parentApi.data.zone.maille);
        let pZoneId =
            "&zone_id=" + (this.props.zoneId ?? this.props.parentApi.data.currentZone);
        if ((this.props.zoneType ?? this.props.parentApi.data.zone.zone) === "region") {
            pZoneId = "&zone_id=" + this.props.parentApi.data.regionCode;
        }
        let id_analyse = this.state.id_analyse;
        let representation = this.state.representation;
        let body = JSON.stringify(this.state.filters);
        let dataSource =
            config.api_analysis_meta_url +
            id_analyse +
            "/graphique/" +
            representation +
            pZone +
            pMaille +
            pZoneId;

        if (this.dataPromise) this.dataPromise.abort();
        this.dataPromise = Api.callApi(
            buildRegionUrl(dataSource, this.props.parentApi.data.region),
            body,
            "POST"
        );
        this.dataPromise
            .then((json) => {
                if (json["disabled"] && json["disabled"] === true) {
                    this.setState({
                        disabled: true,
                    });
                    return;
                }

                this.setState({
                    data: json,
                });
            })
            .catch((error) => {
                if (error.name === "AbortError") return;
                console.error(error);
            });
    }

    render() {
        if (this.state.disabled === true) {
            return (
                <div className="charts-legende">
                    <div className={"confid-chart"}>
                        Cet indicateur n'est pas activé actuellement, veuillez contacter
                        l'administrateur régional.
                    </div>
                </div>
            );
        }

        let data;
        let unite = "";
        let iconName = this.props.image;
        let tailleSVG = this.props.tailleSVG + "rem";
        let tailleData = this.props.tailleData + "rem";
        let colorRegion = getComputedStyle(document.body).getPropertyValue(
            "--" + this.props.parentApi.data.settings.theme
        );
        if (this.state.data) {
            data = new Intl.NumberFormat("fr-FR").format(this.state.data.valeur);
            unite = this.state.data.unite;
        }
        const mdIcon = icons[iconName];
        return (
            <div className="charts-legende svg-icon">
                <div>
                    {iconName && (
                        <div style={{ marginTop: "15%" }}>
                            <div ref={this.state.ref}>
                                {" "}
                                <div
                                    style={{
                                        color: colorRegion,
                                        fontSize: tailleSVG,
                                        display: "inline-block",
                                    }}
                                >
                                    {React.createElement(mdIcon)}
                                </div>
                                <div
                                    style={{
                                        fontSize: tailleData,
                                        display: "inline-block",
                                        marginLeft: "3px",
                                    }}
                                >
                                    {data} {unite}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default MarqueurSVG;
