/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";

function LoadingButton({ title, forceDisabled, callback }) {
    const click = () => {
        if (callback) {
            callback();
        }
    };

    return (
        <button
            type="submit"
            className="btn btn-primary"
            disabled={forceDisabled}
            onClick={() => click()}
        >
            {forceDisabled && (
                <>
                    <span
                        className="spinner-border spinner-border-sm me-1"
                        role="status"
                        aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Loading...</span>
                </>
            )}
            {title}
        </button>
    );
}

export default LoadingButton;
