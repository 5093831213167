/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import Api from "../../Controllers/Api";
import config from "../../settings.js";
import { buildRegionUrl } from "../../utils.js";

import "react-tabs/style/react-tabs.css";

class ActionInformation extends React.Component {
    render() {
        let requisitesDisplay = [],
            outputsDisplay = "",
            variablesUsedDisplay = "";

        if (this.props.variablesUsed) {
            variablesUsedDisplay = (
                <ul>
                    {this.props.variablesUsed.map((element, i) => {
                        return <li key={i}>{element}</li>;
                    })}
                </ul>
            );
        }
        if (this.props.outputs) {
            outputsDisplay = (
                <ul>
                    {this.props.outputs.map((element, i) => {
                        return <li key={i}>{element}</li>;
                    })}
                </ul>
            );
        }

        if (this.props.requisites) {
            for (const key in this.props.requisites) {
                if (Object.hasOwnProperty.call(this.props.requisites, key)) {
                    const requisites = this.props.requisites[key];
                    requisitesDisplay.push(
                        <div key={key}>
                            <h5>{key}</h5>

                            <ul>
                                {requisites.map((element, i) => {
                                    return <li key={i}>{element}</li>;
                                })}
                            </ul>
                        </div>
                    );
                }
            }
        }

        return (
            <div>
                <h3>{this.props?.name}</h3>
                <h4>Variables utilisées</h4>
                {variablesUsedDisplay}
                <h4>Sorties</h4>
                {outputsDisplay}
                <h4>Dépendances générales</h4>
                {requisitesDisplay}
            </div>
        );
    }
}

class ActionTest extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            zoneType: props.zoneType,
            zoneId: props.zoneId,
        };
    }

    test() {
        const url =
            this.props.testUrl +
            "?zone=" +
            this.state.zoneType +
            "&zone_id=" +
            this.state.zoneId;
        Api.callApi(url, null, "GET").then((response) => {
            this.setState({
                actionsParams: response,
            });
        });
    }

    render() {
        let tests = "";

        if (this.props.tests) {
            tests = this.props.tests.map((element, i) => {
                return (
                    <div key={i}>
                        <h5>{element.name}</h5>
                        <ul>
                            {Object.keys(element.parameters).map((param, j) => {
                                return (
                                    <li key={j}>
                                        <em>{param}</em>:{" "}
                                        {JSON.stringify(element.parameters[param])}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                );
            });
        }
        return (
            <div>
                <h4>Tests</h4>
                <button className="btn btn-primary" onClick={() => this.test()}>
                    Lancer les tests
                </button>
                {tests}
            </div>
        );
    }
}

/**
 * This component allows to edit the template Excel file used to export the results
 * and parameters of territorial strategies.
 */
class StrategyInformation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            actionsParams: {},
            tabAction: 0,
        };
    }

    componentDidMount() {
        this.getInformation();
    }

    /**
     * Get current template file
     * @returns the file from the API
     */
    getInformation() {
        Api.callApi(
            buildRegionUrl(
                config.api_strategy_requisites,
                this.props.parentApi.data.region
            ),
            null,
            "GET"
        ).then((response) => {
            this.setState({
                actionsParams: response,
            });
        });
    }

    /**
     * Generate markdown file from actions details.
     */
    getSummaryMarkdown() {
        const date = new Date();

        let markdown = this.getSummary();
        markdown =
            `---
title: "Actions du module de stratégie territoriale"
author: TerriSTORY®
date: ` +
            date.toLocaleDateString("fr-FR") +
            `
geometry: "left=2cm,right=2cm,top=2cm,bottom=2cm"
---

` +
            markdown;

        const blob = new Blob([markdown], {
            type: "text/markdown;charset=utf8;",
        }); // Renvoie un objet Blob qui contient une concaténation de toutes les données du tableau passées au constructeur.
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob); // Cette fonction permet de créer un DOMString qui contient l'url de l'objet à exporter
        link.setAttribute("href", url);
        link.setAttribute("download", "strategy_actions.md");
        link.style.visibility = "hidden";
        link.click();
    }

    /**
     * Generate markdown from actions details.
     */
    getSummary() {
        let output = "";
        for (const key in this.state.actionsParams) {
            if (Object.hasOwnProperty.call(this.state.actionsParams, key)) {
                let requisitesDisplay = [],
                    outputsDisplay = [],
                    variablesUsedDisplay = [];
                const action = this.state.actionsParams[key];

                if (action.variables_used) {
                    variablesUsedDisplay = action.variables_used.map((element, i) => {
                        return "* " + element;
                    });
                }
                if (action.outputs) {
                    outputsDisplay = action.outputs.map((element, i) => {
                        return "* " + element;
                    });
                }

                if (action.requisites) {
                    for (const key in action.requisites) {
                        if (Object.hasOwnProperty.call(action.requisites, key)) {
                            const requisites = action.requisites[key];
                            requisitesDisplay.push(
                                "### " +
                                    key +
                                    "\n\n" +
                                    requisites
                                        .map((element, i) => {
                                            return "* " + element;
                                        })
                                        .join("\n") +
                                    "\n"
                            );
                        }
                    }
                }
                output += "# " + action.name.replace("#", "n°") + "\n\n";
                output += "## Variables utilisées\n\n";
                output += variablesUsedDisplay.join("\n");
                output += "\n\n## Sorties\n\n";
                output += outputsDisplay.join("\n");
                output += "\n\n## Dépendances générales\n\n";
                output += requisitesDisplay.join("\n");
                output += "\n\\pagebreak\n\n";
            }
        }

        return output;
    }

    render() {
        let actionsDisplay = [],
            actionsTabs = [];
        for (const key in this.state.actionsParams) {
            if (Object.hasOwnProperty.call(this.state.actionsParams, key)) {
                const action = this.state.actionsParams[key];
                actionsTabs.push(<Tab>{action.name}</Tab>);
                actionsDisplay.push(
                    <TabPanel>
                        <div className="panel-content">
                            <ActionInformation
                                name={action.name}
                                key={key}
                                requisites={action.requisites}
                                variablesUsed={action.variables_used}
                                outputs={action.outputs}
                            />
                            <ActionTest
                                tests={action.tests}
                                testUrl={buildRegionUrl(
                                    config.api_test_action,
                                    this.props.parentApi.data.region
                                ).replace("#action#", key)}
                                zoneType={this.props.parentApi.data.zone.zone}
                                zoneId={this.props.parentApi.data.currentZone}
                            />
                        </div>
                    </TabPanel>
                );
            }
        }

        return (
            <div className="panel-body block-row strategy-information">
                <h3>Informations sur les actions</h3>
                <p>
                    <button
                        className="btn btn-primary"
                        onClick={() => this.getSummaryMarkdown()}
                    >
                        Récupérer un résumé des actions
                    </button>
                </p>
                <Tabs>
                    <TabList>{actionsTabs}</TabList>
                    {actionsDisplay}
                </Tabs>
            </div>
        );
    }
}

export default StrategyInformation;
