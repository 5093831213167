/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import { useState } from "react";
import ReactTable from "react-table-6";

import Api from "../../Controllers/Api";

import config from "../../settings.js";
import configData from "../../settings_data.js";
import { buildRegionUrl, filterCaseInsensitive } from "../../utils.js";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-table-6/react-table.css";

import DetailsPopup from "../DetailsPopup";
import { AdviceOnDataTable } from "./AnalysisAjouter.js";

/**
 * This component is used to edit the perimeter year of a data table ()
 */

function PerimeterYearEdit({
    parentApi,
    onSuccess,
    table,
    initialPerimeterYear,
    allowConversion = true,
}) {
    const [errorMessage, setErrorMessage] = useState(false);
    const [changePerimeterIsLoading, setChangePerimeterIsLoading] = useState(false);
    const [convertPerimeterIsLoading, setConvertPerimeterIsLoading] = useState(false);

    // Generate the list of possible years for the perimeter
    let listOptionsPerimeterYear = [
        <option key={"selection-annee"} value="">
            {"Sélectionnez une année"}
        </option>,
    ];
    let key = 0;
    for (let i = configData.anneeMin; i <= new Date().getFullYear(); i++) {
        listOptionsPerimeterYear.push(
            <option key={key} value={i}>
                {i}
            </option>
        );
        key += 1;
    }

    const listOptionsAggreationFunction = [
        { value: "sum", label: "Somme" },
        { value: "mean", label: "Moyenne" },
        { value: "min", label: "Minimum" },
        { value: "max", label: "Maximum" },
    ].map((e) => (
        <option key={e.value} value={e.value}>
            {e.label}
        </option>
    ));

    /**
     * Calls the API to modify the perimeter year of the table
     * @param {*} e
     */
    const handleChangePerimeterYearSubmit = (e) => {
        e.preventDefault();
        // get value from form input
        let targetYear = document.querySelector("#targetYear").value;
        let url = buildRegionUrl(
            config.api_mise_a_jour_perimetre_origine,
            parentApi.data.region
        );
        setChangePerimeterIsLoading(true);
        setErrorMessage("");
        Api.callApi(
            url,
            JSON.stringify({ annee_perimetre: targetYear, nom: table }),
            "PUT"
        )
            .then((response) => {
                setChangePerimeterIsLoading(false);
                // close modal and refetch tables
                onSuccess();
            })
            .catch((e) => {
                setChangePerimeterIsLoading(false);
                setErrorMessage(e.message);
            });
    };

    /**
     * Calls the API to convert the table to a new perimeter
     * @param {*} e
     */
    const handleConvertToPerimeterSubmit = (e) => {
        e.preventDefault();
        // get value from form input
        let conversionTargetYear = document.querySelector(
            "#conversionTargetYear"
        ).value;
        let aggregationFunction = document.querySelector("#aggregationFunction").value;
        let url = buildRegionUrl(
            config.api_conversion_perimetre,
            parentApi.data.region
        );
        setConvertPerimeterIsLoading(true);
        setErrorMessage("");
        Api.callApi(
            url,
            JSON.stringify({
                initialPerimeterYear: initialPerimeterYear.toString(),
                targetPerimeterYear: conversionTargetYear,
                tableName: table,
                aggregationFunction: aggregationFunction,
            }),
            "PUT"
        )
            .then((response) => {
                setConvertPerimeterIsLoading(false);
                // close modal and refetch tables
                onSuccess();
            })
            .catch((e) => {
                setConvertPerimeterIsLoading(false);
                setErrorMessage(e.message);
            });
    };

    return (
        <div className="mt-3">
            <h4>Modifier l'année du périmètre de la table (sans conversion)</h4>
            <p>
                L'année spécifiée pour le périmètre de la table sera simplement modifiée
                sans entraîner de modification des données.
            </p>
            <form className="mb-3" onSubmit={handleChangePerimeterYearSubmit}>
                <div className="mb-3">
                    <select className="form-select" id="targetYear" required>
                        {listOptionsPerimeterYear}
                    </select>
                </div>
                <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={convertPerimeterIsLoading || changePerimeterIsLoading}
                >
                    {changePerimeterIsLoading && (
                        <>
                            <span
                                className="spinner-border spinner-border-sm me-1"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            <span className="visually-hidden">Loading...</span>
                        </>
                    )}
                    Modifier le périmètre
                </button>
            </form>
            <h4>Convertir la table dans un nouveau périmètre</h4>
            {allowConversion ? (
                <>
                    <p>
                        Les données seront converties dans un nouveau périmètre qui doit
                        être postérieur au périmètre spécifié actuel (
                        {initialPerimeterYear}).
                    </p>
                    <div className="alert alert-warning" role="alert">
                        Attention, la conversion vers un nouveau périmètre n'est pas
                        réversible et les données dans le périmètre initial seront
                        écrasées. Pensez à conserver une copie des données originales.
                    </div>
                    <form className="mb-3" onSubmit={handleConvertToPerimeterSubmit}>
                        <div className="mb-3">
                            <label
                                htmlFor="conversionTargetYear"
                                className="form-label"
                            >
                                Année du nouveau périmètre
                            </label>
                            <select
                                className="form-select"
                                id="conversionTargetYear"
                                required
                            >
                                {listOptionsPerimeterYear.filter(
                                    (e, i) =>
                                        e.props.value > initialPerimeterYear || i === 0
                                )}
                            </select>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="aggregationFunction" className="form-label">
                                Fonction d'agrégation
                            </label>
                            <select
                                className="form-select"
                                id="aggregationFunction"
                                required
                            >
                                {listOptionsAggreationFunction}
                            </select>
                            <div id="aggregationFunctionHelp" className="form-text">
                                Fonction utilisée pour agréger les valeurs dans le cas
                                d'une fusion de communes.
                            </div>
                        </div>
                        <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={
                                convertPerimeterIsLoading || changePerimeterIsLoading
                            }
                        >
                            {convertPerimeterIsLoading && (
                                <>
                                    <span
                                        className="spinner-border spinner-border-sm me-1"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                    <span className="visually-hidden">Loading...</span>
                                </>
                            )}
                            Convertir dans le nouveau périmètre
                        </button>
                    </form>
                </>
            ) : (
                <div className="alert alert-secondary" role="alert">
                    La conversion vers un nouveau périmètre n'est pas possible pour
                    cette table : les données ne sont pas exprimées à la maille
                    communale ou bien le type des données n'est pas encore pris en
                    charge par la conversion (données de pixel).
                </div>
            )}
            {errorMessage && (
                <div className="alert alert-danger" role="alert">
                    {errorMessage}
                </div>
            )}
        </div>
    );
}

/**
 * This component is used to edit the perimeter year of a data table ()
 */

function AddTable({ parentApi, onSuccess }) {
    const [errorMessage, setErrorMessage] = useState(false);
    const [tableDataLoading, setTableDataLoading] = useState(false);
    const currentYear = new Date().getFullYear();

    const handleChange = (e) => {
        e.preventDefault();
        const form = e.target.elements;

        setErrorMessage("");
        setTableDataLoading(true);
        const tableName = form["table_name_field"].value;
        const datePerimeter = form["perimeter_year"].value;
        const onlyForZone = form["only_for_zone"].value;
        const dataFile = form["data_table_field"].files[0];

        let formData = new FormData();
        formData.append("file", dataFile);
        formData.append("fileName", dataFile.name);
        formData.append("tableName", tableName);
        formData.append("datePerimeter", datePerimeter);
        formData.append("onlyForZone", onlyForZone);
        let url = buildRegionUrl(config.api_analysis_data_url, parentApi.data.region);

        Api.callApi(url, formData, "POST", "default")
            .then((response) => {
                setTableDataLoading(false);
                // close modal and refetch tables
                onSuccess();
            })
            .catch((e) => {
                setTableDataLoading(false);
                setErrorMessage(e.message);
            });
    };

    // Generate the list of possible years for the perimeter
    let listOptionsPerimetersOnlyForZone = [
        <option key={"selection-zone"} value="">
            {"Maille communale (par défaut)"}
        </option>,
    ];

    const mailles = parentApi.controller.zonesManager.getMaillesList();
    mailles.forEach((element, key) => {
        if (element.value === "commune") {
            return;
        }
        listOptionsPerimetersOnlyForZone.push(
            <option key={key} value={element.value}>
                {element.label}
            </option>
        );
    });

    return (
        <div className="mt-3">
            <h4>Fichier de données</h4>
            <form className="mb-3" onSubmit={handleChange}>
                <div className="mb-3">
                    <AdviceOnDataTable />
                    <label htmlFor="data_table_field" className="form-label">
                        Table de données
                    </label>
                    <input
                        type="file"
                        className="form-control form-inline col-sm-4"
                        name="data_table_field"
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="table_name_field" className="form-label">
                        Nom de la table de données
                    </label>
                    <input
                        type="text"
                        className="form-control form-inline col-sm-4"
                        name="table_name_field"
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="perimeter_year" className="form-label">
                        Année du périmètre géographique
                    </label>
                    <input
                        type="number"
                        className="form-control form-inline col-sm-4"
                        name="perimeter_year"
                        defaultValue={currentYear}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="only_for_zone" className="form-label">
                        Définie sur une maille géographique différente de la commune
                    </label>
                    <select className="form-select" name="only_for_zone">
                        {listOptionsPerimetersOnlyForZone}
                    </select>
                </div>
                <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={tableDataLoading}
                >
                    {tableDataLoading && (
                        <>
                            <span
                                className="spinner-border spinner-border-sm me-1"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            <span className="visually-hidden">Loading...</span>
                        </>
                    )}
                    Modifier le périmètre
                </button>
            </form>
            {errorMessage && (
                <div className="alert alert-danger" role="alert">
                    {errorMessage}
                </div>
            )}
        </div>
    );
}

/**
 * this component is preview first five rows in a table
 */
export class TablePreview extends React.Component {
    /**
     * Rounds the value attribute to 2 digits after decimal places
     * @param value parameter to round if it is a value attribute
     * @param attribute type of attribute
     * @return the rounded parameter if it is a value, or the parameter otherwise
     */
    simplifyValuesFormat(value, attribute) {
        // we are displaying a value (and not the header)
        if (attribute === "valeur" && value !== "valeur") {
            return <span title={value}>{value}</span>;
        }
        return value;
    }

    /**
     * Gets the table header and renders it
     */
    render() {
        let header = [];
        let headerAndValues = [];
        let i = 0;

        // we store headers and values
        if (this.props.data && Object.keys(this.props.data).length > 0) {
            for (const elem of this.props.data) {
                let values = [];
                for (const object in elem) {
                    // if we haven't stored headers yet (first element)
                    if (i === 0) {
                        header.push(object);
                    }
                    values.push(elem[object]);
                }
                // we store headers first
                if (i === 0) {
                    headerAndValues.push(header);
                }
                headerAndValues.push(values);
                i += 1;
            }
        }
        return (
            <div>
                <table className="table_preview_header">
                    <tbody>
                        {headerAndValues.map((line, j) => (
                            <tr key={j} className="table_preview_header_line">
                                {line.map((col, i) => (
                                    <td key={i}>
                                        {this.simplifyValuesFormat(col, header[i])}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
                {this.props.additionalElements ?? ""}
            </div>
        );
    }
}

/**
 * this component is used to manage indicator data
 */
class DataManage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tables: undefined,
            tableCourante: "",
            anneeCourante: undefined,
            showDetailsTablesElement: false,
            detailsTable: "",
            showPerimeterEditModal: false,
            perimeterYearEdit: "",
            showAddTableModal: false,
            addTable: "",
        };
        this.region = this.props.parentApi.data.region;
        this.getDataTables();
    }

    componentDidUpdate(prevProps, prevState) {
        // If the date of the geographic perimeter has changed for one of the data tables
        if (
            this.state.anneeCourante !== prevState.anneeCourante ||
            this.state.tableCourante !== prevState.tableCourante
        ) {
            this.getDataTables();
            this.setState({
                anneeCourante: this.state.anneeCourante,
            });
        }
    }

    closePerimeterYearEditModal() {
        this.setState({ showPerimeterEditModal: false });
    }

    openPerimeterYearEditModal(props) {
        let modalContent = (
            <PerimeterYearEdit
                parentApi={this.props.parentApi}
                onSuccess={() => {
                    this.closePerimeterYearEditModal();
                    this.getDataTables();
                }}
                table={props.original.nom}
                initialPerimeterYear={props.original.date_perimetre}
                allowConversion={this.isConvertible(props.original.header)}
            />
        );
        this.setState({
            showPerimeterEditModal: true,
            perimeterYearEdit: modalContent,
        });
    }

    closeAddTableModal() {
        this.setState({ showAddTableModal: false });
    }

    openAddTableModal(props) {
        let modalContent = (
            <AddTable
                parentApi={this.props.parentApi}
                onSuccess={() => {
                    this.closeAddTableModal();
                    this.getDataTables();
                }}
            />
        );
        this.setState({
            showAddTableModal: true,
            addTable: modalContent,
        });
    }

    toggleDetailsCell(details) {
        let toggle = this.state.showDetailsTablesElement;
        // we toggle between displaying details or not
        if (!toggle) {
            this.setState({
                showDetailsTablesElement: true,
                detailsTable: details,
            });
        } else {
            this.setState({
                showDetailsTablesElement: false,
                detailsTable: "",
            });
        }
    }

    /**
     * Test if the table is convertible :
     *  - has a "commune" and "valeur" column
     *  - does not have a "commune_dest" column (flow data isn't convertible yet)
     *  - does not have a "geom" column (pixel data isn't convertible yet)
     * @param {} header The header of the table (first 5 lines)
     * @returns {boolean} true if the table is convertible, false otherwise
     */
    isConvertible(header) {
        const requiredColumns = ["commune", "valeur"];
        const forbiddenColumns = ["geom"];
        const columns = Object.keys(header[0]);
        const hasForbiddenColumn = columns.some((column) =>
            forbiddenColumns.includes(column)
        );
        const hasRequiredColumns = requiredColumns.every((column) =>
            columns.includes(column)
        );
        return hasRequiredColumns && !hasForbiddenColumn;
    }

    /**
     * Retrieve list of data tables
     * @return {json} the list of tables
     */
    getDataTables() {
        // Call API to retrieve analysis
        let url = buildRegionUrl(config.api_analysis_data_url, this.region);
        Api.callApi(url, null, "GET")
            .then((response) => {
                this.setState({
                    tables: response,
                });
            })
            .catch((e) => this.setState({ analysis: undefined }));
    }

    /**
     * Data file to import
     * @param {event} event : event object from input[file] (file selection)
     * @param {tableRow} : current row of indicators array
     */
    onFichierDataSelection = (event, tableRow) => {
        // update the state
        let tables = JSON.parse(JSON.stringify(this.state.tables));
        for (let table of tables) {
            if (tableRow.props.row.nom === table.nom) {
                table.miseAJourEnabled = true;
            } else {
                table.miseAJourEnabled = false;
            }
        }
        this.setState({
            fichierDonnees: event.target.files[0],
            tables: tables,
        });
    };

    /**
     * Restores actions to their original state
     * @param {event} event : event object from input[file] (file selection)
     * @param {tableRow} : current row of indicators array
     */
    resetActions = (event, tableRow) => {
        // update the state
        let tables = JSON.parse(JSON.stringify(this.state.tables));
        for (let table of tables) {
            table.miseAJourEnabled = false;
        }
        this.setState({
            tables: tables,
        });
    };

    /**
     * Updates the date of the geographic perimeter that the user has selected
     * @param {e} object : event triggered by the selection of a new year in the dropdown menu
     * @param {table} string : Name of the table concerned by the update of the perimeter date in which the data contained therein is expressed
     */
    anneePerimetreGeographique(e, props, table, anneePerimetreOriginal) {
        let nouvelleAnnee = e.target.value;
        let r = window.confirm(
            "Souhaitez vous vraiment définir que le périmètre géographique de la table " +
                table +
                " date de " +
                nouvelleAnnee +
                " " +
                anneePerimetreOriginal +
                " ? Cette opération n'entrainera pas de conversion des données. Pour convertir les données dans un nouveau périmètre, utiliser le bouton dans la colonne action."
        );
        if (r !== true) {
            e.value = "test";
            return;
        }
        let url = buildRegionUrl(
            config.api_mise_a_jour_perimetre_origine,
            this.props.parentApi.data.region
        );
        Api.callApi(
            url,
            JSON.stringify({ annee_perimetre: nouvelleAnnee, nom: table }),
            "PUT"
        )
            .then((response) => {
                this.setState({
                    message: response.message,
                    anneeCourante: nouvelleAnnee,
                    tableCourante: table,
                });
            })
            .catch((e) => this.setState({ analysis: undefined }));
    }

    downloadButton = (tableName) => {
        let url = buildRegionUrl(config.api_analysis_data_export, this.region).replace(
            "#table_name#",
            tableName
        );
        return (
            <p className="export-data-button-in-popup">
                <a
                    className="btn btn-info normal-size"
                    target="_blank"
                    rel="noreferrer"
                    href={url}
                >
                    Exporter le contenu complet de la table en csv
                </a>
            </p>
        );
    };

    /**
     * @return {dom} the render of the component
     */
    render() {
        if (
            !this.props.connected ||
            !this.props.userInfos ||
            this.props.userInfos?.profil !== "admin"
        ) {
            return <div>Non accessible.</div>;
        }

        /**
         * Callback to update a table
         * @param  {json} table : current row
         */
        const updateTable = (
            tableRow,
            casOnlyForZone,
            forceUpdate = false,
            callback = () => {}
        ) => {
            if (!this.state.fichierDonnees) {
                this.setState({
                    status: "Vous devez choisir un fichier de données",
                });
                return false;
            }
            if (!forceUpdate) {
                let r = window.confirm(
                    "Attention, cette opération n'est pas réversible. Voulez vous continuer ?"
                );
                if (r !== true) {
                    return;
                }
            }
            let formData = new FormData();
            formData.append("fichier", this.state.fichierDonnees);
            formData.append("nomFichier", this.state.fichierDonnees.name);
            let suffix = "";
            if (forceUpdate) {
                suffix = (casOnlyForZone ? "&" : "?") + "force_update=t";
            }
            let url =
                buildRegionUrl(config.api_analysis_data_url, this.region) +
                "/" +
                tableRow.props.row.nom +
                casOnlyForZone +
                suffix;

            Api.callApi(url, formData, "PUT", "default")
                .then((response) => {
                    this.setState({ status: response.message });
                    this.props.parentApi.callbacks.updateMessages(
                        response.message,
                        "success"
                    );
                    this.resetActions();
                    callback();
                })
                .catch((e) => {
                    if (
                        e.message ===
                        "This operation should not be performed but can be forced."
                    ) {
                        let r = window.confirm(
                            "Il est possible de forcer cette mise à jour malgré le fait que cela change la liste des colonnes de la table de données. En faisant cela, vous courrez le risque de briser le fonctionnement de certains tableaux de bord utilisant cette donnée. Acceptez-vous ce risque et choisissez-vous de poursuivre malgré tout ?"
                        );
                        if (r !== true) {
                            return;
                        }
                        updateTable(tableRow, casOnlyForZone, true, () =>
                            this.getDataTables()
                        );
                    } else {
                        this.props.parentApi.callbacks.updateMessages(e.message);
                    }
                });
        };

        /**
         * Callback to delete a table
         * @param  {json} table : current row
         */
        const deleteTable = (tableRow) => {
            let url =
                buildRegionUrl(config.api_analysis_data_url, this.region) +
                "/" +
                tableRow.props.row.nom;

            Api.callApi(url, null, "DELETE", "default")
                .then((response) => {
                    const newLine = "\r\n";
                    let r = window.confirm(
                        "Attention, cette opération n'est pas réversible. Voulez-vous supprimer cette table de données ?" +
                            newLine +
                            "Les indicateurs associés suivants ne fonctionneront plus et seront automatiquement désactivés : " +
                            response.related_tables
                    );
                    if (r !== true) {
                        return;
                    } else {
                        url += "?confirm=true";
                        Api.callApi(url, null, "DELETE", "default").then((response) => {
                            // update the state
                            let tables = JSON.parse(JSON.stringify(this.state.tables));
                            tables = tables.filter(
                                (table) => table.nom !== tableRow.props.row.nom
                            );
                            this.setState({ status: response.message, tables: tables });
                            this.props.parentApi.callbacks.updateMessages(
                                response.message,
                                "success"
                            );
                        });
                    }
                })
                .catch((e) => this.props.parentApi.callbacks.updateMessages(e.message));
        };

        let tablesDashboard = "";

        const columns = [
            {
                Header: "Nom de la table",
                accessor: "nom",
            },
            {
                Header: "Utilisée dans les indicateurs suivants",
                accessor: "indicateurs",
            },
            {
                id: "date_perimetre_geo",
                Header: "Date du périmètre géographique",
                accessor: "date_perimetre",
                Cell: (props) => {
                    let listePerimetresGeo = [];
                    let cle = 0;
                    for (
                        let i = configData.anneeMin;
                        i <= new Date().getFullYear();
                        i++
                    ) {
                        let option = (
                            <option key={cle} value={i}>
                                {i}
                            </option>
                        );
                        listePerimetresGeo.push(option);
                        cle += 1;
                    }
                    return (
                        <div className="actions">
                            {props.original.date_perimetre}

                            <button
                                className={"btn btn-secondary ms-1"}
                                onClick={() => this.openPerimeterYearEditModal(props)}
                            >
                                Modifier
                            </button>
                        </div>
                    );
                },
                filterable: false,
            },
            {
                Header: "Date de mise à jour",
                accessor: "date_maj",
            },
            {
                Header: "Aperçu de la table",
                accessor: "table_preview",
                Cell: (props) => {
                    return (
                        <div>
                            <img
                                className="table_preview_icon"
                                src="img/table_preview.svg"
                                alt="Visualiser une partie des données"
                                title="Visualiser une partie des données"
                                onClick={() =>
                                    this.toggleDetailsCell(
                                        <TablePreview
                                            data={props.original.header}
                                            additionalElements={this.downloadButton(
                                                props.original.nom
                                            )}
                                        />
                                    )
                                }
                            />
                        </div>
                    );
                },
                filterable: false,
            },
            {
                id: "action",
                accessor: "action",
                Header: "Actions",
                Cell: (props) => {
                    let casOnlyForZone = "";
                    if (props.original.only_for_zone) {
                        casOnlyForZone =
                            "?only_for_zone=" + props.original.only_for_zone;
                    }
                    return (
                        <div className="actions">
                            <input
                                type="file"
                                onChange={(e) => {
                                    this.onFichierDataSelection(e, {
                                        props,
                                    });
                                }}
                            />
                            <button
                                className={
                                    "btn btn-warning " +
                                    (props.original.miseAJourEnabled ? "" : "hidden")
                                }
                                onClick={() => updateTable({ props }, casOnlyForZone)}
                            >
                                Mettre à jour
                            </button>
                            <button
                                className={"btn btn-danger"}
                                onClick={() => deleteTable({ props })}
                            >
                                Supprimer
                            </button>
                        </div>
                    );
                },
            },
        ];

        tablesDashboard = (
            <div className="panel-body user-scenarii">
                <h3 className="panel-title pull-left">Gestion des données</h3>
                <p>
                    <button
                        className={"btn btn-primary"}
                        onClick={() => this.openAddTableModal(this.props)}
                    >
                        Ajouter une table de données
                    </button>
                </p>
                <ReactTable
                    data={this.state.tables}
                    columns={columns}
                    className="-striped"
                    defaultPageSize={30}
                    filterable={true}
                    defaultFilterMethod={(filter, row) =>
                        filterCaseInsensitive(filter, row)
                    }
                />
            </div>
        );

        return (
            <div>
                {this.state.showAddTableModal && (
                    <DetailsPopup
                        title="Ajout d'une table de données"
                        content={this.state.addTable}
                        show={this.state.showAddTableModal}
                        emptyMsg="Nothing to show"
                        callbackAfterClosing={() => this.closeAddTableModal()}
                    />
                )}
                {this.state.showPerimeterEditModal && (
                    <DetailsPopup
                        title="Modification du périmètre géographique"
                        content={this.state.perimeterYearEdit}
                        show={this.state.showPerimeterEditModal}
                        emptyMsg="Nothing to show"
                        callbackAfterClosing={() => this.closePerimeterYearEditModal()}
                    />
                )}
                {this.state.showDetailsTablesElement && (
                    <DetailsPopup
                        title="Aperçu de la table"
                        content={this.state.detailsTable}
                        show={this.state.showDetailsTablesElement}
                        emptyMsg="Nothing to show"
                        callbackAfterClosing={() => this.toggleDetailsCell()}
                    />
                )}
                {tablesDashboard}
            </div>
        );
    }
}

export default DataManage;
