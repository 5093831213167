/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import Api from "../../Controllers/Api.js";
import config from "../../settings.js";
import { buildRegionUrl } from "../../utils.js";

import "../../style/national/portal.css";

import "bootstrap/dist/css/bootstrap.min.css";

function Portal(props) {
    const [enableSimulator, setEnableSimulator] = useState(false);
    const [enableTerritorialStrategy, setEnableTerritorialStrategy] = useState(false);
    const { region } = useParams();

    useEffect(() => {
        let url = buildRegionUrl(
            config.api_check_validity_territorial_strategy_simulator,
            "national"
        ).replace("#territory#", region);
        Api.callApi(url, null, "GET")
            .then((response) => {
                setEnableSimulator(response.simulator);
                setEnableTerritorialStrategy(response.territorial_strategy);
            })
            .catch((e) => console.error(e));
    }, [region]);

    const renderWelcomeMessage = () => {
        return <h4 className="header-other-dashboard tstitle">Bienvenue</h4>;
    };

    const renderImportButton = () => {
        return (
            <div className="form-group centered-row">
                <Link
                    to={"/national/" + props.parentApi.data.region + "/import"}
                    className="tsbtn info big import-button"
                >
                    Importer des données
                </Link>
            </div>
        );
    };

    const renderAccordion = () => {
        const disableSimulatorClass = enableSimulator ? "" : "disabled-item";
        const disableTerritorialStrategyClass = enableTerritorialStrategy
            ? ""
            : "disabled-item";
        return (
            <div className="national-results">
                <div className={disableTerritorialStrategyClass + " strategy"}>
                    <Link
                        to={
                            "/national/" +
                            props.parentApi.data.region +
                            "/strategies_territoriales"
                        }
                        className="non-link"
                    >
                        <div className="img">
                            <img
                                src="../../img/national/strategy.svg"
                                title="Stratégies territoriales"
                                alt="Stratégies territoriales"
                            />
                        </div>
                        <p>Stratégies territoriales</p>
                    </Link>
                </div>
                <div className={disableSimulatorClass + " simulator"}>
                    <Link
                        to={"/national/" + props.parentApi.data.region + "/simulateur"}
                        className="non-link"
                    >
                        <div className="img">
                            <img
                                src="../../img/national/simulator_mobility.svg"
                                title="Simulateur de mobilité"
                                alt="Simulateur de mobilité"
                            />
                        </div>
                        <p>Simulateur de mobilité</p>
                    </Link>
                </div>
            </div>
        );
    };

    const renderAlertMsg = () => {
        return (
            <div className="form-group centered-row">
                <label className="missing-data">
                    Données incomplètes !<br />
                    Lorsque toutes les données auront été saisies, les modules seront
                    disponibles ici.
                </label>
            </div>
        );
    };

    return (
        <div className="container">
            {renderWelcomeMessage()}
            {renderAccordion()}
            {(!enableSimulator || !enableTerritorialStrategy) && renderAlertMsg()}
            {renderImportButton()}
        </div>
    );
}

export default Portal;
