/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import moment from "moment";

import Api from "../../Controllers/Api";
import config from "../../settings.js";
import { buildRegionUrl } from "../../utils.js";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";

/**
 * This component is used to manage user authentication
 */
class Auth extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loginPopupOn: false,
            status: "",
            connected: false,
            userInfos: undefined,
        };
    }

    componentDidMount() {
        // Check if there is a token stored
        if (localStorage.getItem("access_token") !== "") {
            this.getUserInfo().then(() => {
                if (!this.state.connected) return;
                if (this.state.userInfos.utiliser_territoire_predilection) {
                    this.props.parentApi.callbacks.setZoneFromUserPreferedTerritory(
                        this.state.userInfos.territoire_predilection
                    );
                }
            });
        }
    }
    /**
     * update zone
     */
    getCodeRegion(region) {
        let url = buildRegionUrl(config.api_code_region_national, "national").replace(
            "#territory#",
            region
        );
        Api.callApi(url, null, "GET")
            .then((code) => {
                this.props.parentApi.callbacks.updateZone("region", "commune", code);
            })
            .catch((e) => console.error(e));
    }

    /**
     * Get infos on current connected user
     */
    async getUserInfo() {
        const authManager = this.props.parentApi.controller.authManager;
        let nationalRegion = false;
        if (this.props.parentApi.data.region === "national") {
            nationalRegion = this.props.match.params[0].split("/", 2)[0];
        } else if (this.props.parentApi.data.isNationalRegion) {
            nationalRegion = this.props.parentApi.data.region;
        }
        const connected = await authManager.getMe(
            this.props.parentApi.callbacks,
            nationalRegion
        );
        if (connected) {
            this.props.parentApi.callbacks.updateNationalTerritory(
                true,
                authManager.userInfos.nationalRegion
            );
            this.getCodeRegion(authManager.userInfos.nationalRegion);
            this.setState({
                userInfos: authManager.userInfos,
                regionUtilisateur: authManager.regionUtilisateur,
                connected: true,
            });
            this.handleTimeOut(authManager.userInfos.exp);
            this.props.parentApi.callbacks.handleAuthComplete(true);
        } else {
            this.setState({
                regionUtilisateur: authManager.regionUtilisateur,
                connected: false,
            });
            this.props.parentApi.callbacks.handleAuthComplete(false);
        }
    }

    /**
     * Disconnect the current user
     */
    disconnect() {
        this.props.parentApi.controller.authManager.disconnect({
            ...this.props.parentApi.callbacks,
            refreshUserID: () =>
                this.props.parentApi.controller.gestionSuiviConsultations.obtenirIdUtilisateur(
                    () => {}
                ),
        });
        this.setState({
            connected: false,
            status: "",
        });
    }

    /**
     * Manage connection time
     */
    handleTimeOut(exp) {
        const warningNotice = 60;
        let expires = moment.unix(exp);
        let now = moment();
        let diff =
            moment(expires, "DD/MM/YYYY HH:mm:ss").diff(
                moment(now, "DD/MM/YYYY HH:mm:ss")
            ) / 1000; // remaining connection time in seconds
        let secondsToAlert = diff - warningNotice;
        setTimeout(() => {
            // Call API to refresh the token, provided the user is still active
            if (this.props.parentApi.data.utilisateurActif) {
                this.refreshConnexion();
            } else {
                let r = window.confirm(
                    "Votre session est arrivée à expiration. Voulez-vous effectuer une reconnexion automatique ?"
                );
                if (r !== true) {
                    // Redirect to home page
                    window.location.reload();
                }
                this.refreshConnexion();
                this.props.parentApi.callbacks.updateUtilisateurActif(true);
            }
        }, secondsToAlert * 1000);
    }

    /**
     * Refresh connexion
     */
    refreshConnexion() {
        Api.callApi(
            buildRegionUrl(config.auth_refresh_url, this.props.parentApi.data.region),
            {},
            "POST"
        )
            .then((response) => {
                // We store the new token
                localStorage.setItem("access_token", response.accessToken);
                this.getUserInfo();
            })
            .catch((e) => this.setState({ status: e.message }));
    }

    render() {
        return <></>;
    }
}

export default Auth;
