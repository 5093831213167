/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import { Helmet } from "react-helmet-async";

/**
 * SEO component that adds a helmet (title + meta tags) when provided data.
 */
function SEO({ settings, page, seoDetails }) {
    function getMetaData(type) {
        let res = settings
            .filter((item) => item.page === page)
            .map((item) => item[type])[0];
        if (
            ["home-page", "dashboard", "sankey"].includes(page) &&
            seoDetails !== undefined
        ) {
            res = seoDetails + " : " + res;
        }
        return res;
    }

    return (
        <Helmet>
            <title>{getMetaData("meta_title")}</title>
            <meta
                name="description"
                content={getMetaData("meta_description")}
                data-react-helmet="true"
                data-rh="true"
            />
        </Helmet>
    );
}

export default SEO;
