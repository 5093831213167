/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";

import FadeInSection from "../Utils/FadeInSection";

import "../../style/homepage.css";

/**
 * This component is used to manage the 'A propos' page of the application
 */
function NationalAbout() {
    return (
        <div className="widgets full-screen-widget">
            <div className="tout about">
                <div className="img larger-logo">
                    <img
                        src="../../img/logo_TerriSTORY_transparent.png"
                        alt="Logo de TerriSTORY®"
                    ></img>
                </div>
                <div className="texte-corps">
                    <div className="text-block">
                        <div className="whole-width">
                            <FadeInSection>
                                <h1>Le projet</h1>
                            </FadeInSection>
                        </div>
                        <div>
                            <FadeInSection>
                                <h2>
                                    Un outil partenarial d’aide au pilotage de la
                                    transition des territoires, co-construit avec les
                                    territoires
                                </h2>
                                <p>
                                    Interface de{" "}
                                    <strong>
                                        visualisation interactive de données
                                    </strong>{" "}
                                    opendata multithématiques (énergie, climat,
                                    mobilité, économie...), TerriSTORY® facilite la
                                    compréhension de son territoire, l'identification de
                                    ses atouts et des leviers d’actions prioritaires.
                                </p>
                                <p>
                                    L’outil permet de{" "}
                                    <strong>simuler des scénarios prospectifs</strong>{" "}
                                    et d'en mesurer les impacts socio-économiques et
                                    environnementaux, pour{" "}
                                    <strong>
                                        construire une trajectoire territoriale
                                    </strong>{" "}
                                    à la hauteur des enjeux et des objectifs.
                                </p>
                                <p>
                                    Conçu comme un <strong>support pédagogique</strong>,
                                    il permet d'engager un dialogue multi acteurs au
                                    sein d'un territoire et entre territoires.
                                </p>
                            </FadeInSection>
                        </div>
                        <div>
                            <FadeInSection>
                                <img
                                    alt="carte"
                                    src="/img/national/carte.png"
                                    className="map"
                                />
                            </FadeInSection>
                        </div>
                        <div>
                            <FadeInSection>
                                <img
                                    alt="carte"
                                    src="/img/national/strategie.png"
                                    className="map"
                                />
                            </FadeInSection>
                        </div>
                        <div>
                            <FadeInSection>
                                <h2>Un site accessible à tous</h2>
                                <ul>
                                    <li>
                                        <span style={{ color: "#3592c1" }}>
                                            <strong>Simple d’utilisation</strong>
                                        </span>
                                        , terristory.fr est accessible à tous.
                                    </li>
                                    <li>
                                        <span style={{ color: "#bf2d64" }}>
                                            <strong>
                                                Support d’animation territoriale
                                            </strong>
                                        </span>
                                        , il permet d’organiser de manière pédagogique
                                        le dialogue entre parties prenantes au sein d’un
                                        territoire dans une logique de projet
                                        territorial partagé.
                                    </li>
                                    <li>
                                        <span style={{ color: "#9db043" }}>
                                            <strong>Évolutif</strong>
                                        </span>
                                        , TerriSTORY® est régulièrement mis à jour avec
                                        de nouveaux jeux de données et de nouvelles
                                        fonctionnalités
                                    </li>
                                </ul>
                            </FadeInSection>
                        </div>
                        <div className="video">
                            <FadeInSection>
                                <h2>
                                    Comprendre les atouts de TerriSTORY® en 2 minutes !
                                </h2>

                                <div
                                    className="youtube_player active-title fade-in mui-enter-active"
                                    videoid="RHSQ_oS7XDA"
                                    width="715"
                                    height="402"
                                    theme="theme dark"
                                    rel="rel 0"
                                    controls="controls 1"
                                    showinfo="showinfo 1"
                                    autoPlay="autoplay 0"
                                >
                                    <iframe
                                        title="Youtube iframe"
                                        type="text/html"
                                        width="715"
                                        height="402"
                                        src="//www.youtube-nocookie.com/embed/RHSQ_oS7XDA?theme=theme dark&amp;rel=rel 0&amp;controls=controls 1&amp;showinfo=showinfo 1&amp;autoplay=autoplay 0"
                                        webkitallowfullscreen=""
                                        mozallowfullscreen=""
                                        allowFullScreen=""
                                    ></iframe>
                                </div>
                            </FadeInSection>
                        </div>
                    </div>
                    <div className="text-block">
                        <div className="whole-width">
                            <FadeInSection>
                                <h1>Le consortium</h1>
                            </FadeInSection>
                        </div>
                        <div>
                            <FadeInSection>
                                <h2>Une dynamique multirégionale</h2>
                                <p>
                                    Outil "made in Auvergne-Rhône-Alpes", TerriSTORY®
                                    est aujourd'hui présent dans{" "}
                                    <strong>6 régions</strong> et devient un outil de
                                    référence au niveau national.
                                </p>
                            </FadeInSection>
                        </div>
                        <div>
                            <FadeInSection>
                                <img
                                    alt="bloc_partenaires_consortium"
                                    src="/img/national/bloc_partenaires_consortium.png"
                                />
                            </FadeInSection>
                        </div>
                        <div>
                            <FadeInSection>
                                <img
                                    alt="bloc_partenaires_regionaux"
                                    src="/img/national/bloc_partenaires_regionaux.png"
                                />
                            </FadeInSection>
                        </div>

                        <div>
                            <FadeInSection>
                                <h2>Une gouvernance multipartenariale</h2>
                                <p>
                                    Fédérant un ensemble de partenaires, le projet est
                                    soutenu par la Région Auvergne-Rhône-Alpes, la
                                    Région Nouvelle-Aquitaine, la Région Occitanie, la
                                    Région Pays de la Loire, la Région Bretagne, la
                                    Région Corse, l’ADEME et la Commission européenne.
                                </p>
                                <p>
                                    La gouvernance multipartenariale de TerriSTORY®
                                    permet d'apporter une réponse concrète aux enjeux
                                    d’harmonisation et d’agrégation des objectifs
                                    multi-niveaux.
                                </p>
                                <p>
                                    Elle facilite le déploiement de TerriSTORY® dans
                                    d’autres régions et favorise la mutualisation des
                                    moyens pour le développement de nouvelles
                                    fonctionnalités ainsi que la mise à disposition de
                                    toujours plus de données.
                                </p>
                            </FadeInSection>
                        </div>
                    </div>
                    <div className="text-block">
                        <div className="whole-width">
                            <FadeInSection>
                                <h1>Pour aller plus loin...</h1>
                            </FadeInSection>
                        </div>
                        <div>
                            <FadeInSection>
                                <h2>Quelques documents</h2>
                                <p>
                                    <a
                                        href="/pdf/auvergne-rhone-alpes/TerriSTORY_Presentation.pdf"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Plaquette de présentation de TerriSTORY®
                                    </a>
                                </p>
                                <p>
                                    <a
                                        href="/pdf/auvergne-rhone-alpes/TerriSTORY_Bilan_activite.pdf"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Bilan d'activité TerriSTORY®
                                    </a>
                                </p>
                                <p>
                                    <a
                                        href="https://www.youtube.com/watch?v=RHSQ_oS7XDA"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Lien vers la vidéo de présentation de
                                        TerriSTORY®
                                    </a>
                                </p>
                            </FadeInSection>
                        </div>
                        <div>
                            <FadeInSection>
                                <h2>Rejoignez-nous !</h2>
                                <p>
                                    Le consortium est ouvert aux territoires et plus
                                    particulièrement aux régions qui souhaiteraient nous
                                    rejoindre pour participer au consortium, à
                                    l'évolution de l'outil voire même{" "}
                                    <em>
                                        déployer une version régionale de TerriSTORY®
                                    </em>{" "}
                                    publique et ouverte à tout le monde ! Vous pouvez
                                    prendre contact avec nous via la page de contact ou
                                    en nous écrivant à <em>contact@terristory.fr</em> si
                                    vous souhaitez en savoir plus sur cette démarche.
                                </p>
                            </FadeInSection>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NationalAbout;
