/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import AddDataSourceLogo from "./Admin/AddDataSourceLogo";
import AddDidacticFile from "./Admin/AddDidacticFile";
import AjoutObjectifs from "./Admin/AjoutObjectifs";
import AnalysisAjouter from "./Admin/AnalysisAjouter";
import AnalysisManage from "./Admin/AnalysisManage";
import AnalysisOrder from "./Admin/AnalysisOrder.js";
import CategoriesManage from "./Admin/CategoriesManage";
import ConfigurerDonneesRegistre from "./Admin/ConfigurerDonneesRegistre";
import DataHistory from "./Admin/DataHistory";
import DataManage from "./Admin/DataManage";
import DataSourceLogoManage from "./Admin/DataSourceLogoManage";
import DidacticFileManage from "./Admin/DidacticFileManage";
import DonneesRegistre from "./Admin/DonneesRegistre";
import EditeurPopUp from "./Admin/EditeurPopUp";
import EditStrategyResultsFile from "./Admin/EditStrategyResultsFile";
import ExternalAPI from "./Admin/ExternalAPI";
import GererDonneesFrance from "./Admin/GererDonneesFrance";
import GestionObjectifs from "./Admin/GestionObjectifs";
import PassageTable from "./Admin/PassageTable";
import POIEdition from "./Admin/POIEdition";
import POIHistory from "./Admin/POIHistory";
import POIManage from "./Admin/POIManage";
import POIOrder from "./Admin/POIOrder";
import POIUserContributions from "./Admin/POIUserContributions";
import Pcaet from "./Admin/Pcaet";
import RegionScenarioTable from "./Admin/RegionScenarioTable";
import Sankeys from "./Admin/Sankeys";
import StaticFilesManage from "./Admin/StaticFilesManage";
import Statistics from "./Admin/Statistics";
import StrategyInformation from "./Admin/StrategyInformation";
import StrategyErrors from "./Admin/StrategyErrors";
import TerritoiresManage from "./Admin/TerritoiresManage";
import UserEdition from "./Admin/UserEdition";
import UsersManage from "./Admin/UsersManage";
import ZonesManage from "./Admin/ZonesManage";

import Api from "../Controllers/Api";

import config from "../settings.js";

import "bootstrap/dist/css/bootstrap.min.css";
import { buildRegionUrl } from "../utils";

/**
 * This component manages the profile of the connected user
 */
class Profile extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            submissionWidgetOn: false,
            users: undefined,
            mainTab: 0,
            tabIndicateur: 0, // the tab to open by default for indicators (list or add/modify)
            tabTerritories: 0, // the tab to open by default for territories
            modeIndicateur: "ajouter", // the mode in which we open the edition tab (addition or modification)
            tabEquipements: 0, // the tab to open by default for equipment (list or add/modify)
            modeEquipements: "ajouter", // the mode in which we open the edition tab for the equipment (addition or modification)
            modeObjectifs: "ajouter",
            tabHistory: 0, // défini l'onglet à ouvrir par défaut pour les historiques
            tabIndexObjectif: 0,
            tabCustomization: 0,
            objectifCourant: undefined,
            tabLogo: 0, // the tab to open by default for the data source logo (list or add/modify)
            modeLogo: "add", // the mode in which we open the edition tab (addition or modification)
            dataSourceLogoCourant: {
                id: undefined,
                name: undefined,
                url: undefined,
                path_logo: undefined,
            },
            tabDidacticFile: 0,
            modeDidacticFile: "add", // the mode in which we open the edition tab (addition or modification)
            dataCurrentDidacticFile: {
                id: undefined,
                title: undefined,
                type: undefined,
            },
            configurer: false,
            donneesRegistre: "",
        };
    }

    componentDidMount() {
        if (localStorage.getItem("access_token") !== "") {
            // Get user infos
            this.getMe();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.state.tabIndicateur !== prevState.tabIndicateur &&
            this.state.tabIndicateur !== 8 // Id of the configuration tab
        ) {
            this.setState({
                configurer: false,
            });
        }
        if (
            this.props.connected !== prevProps.connected &&
            localStorage.getItem("access_token") !== ""
        ) {
            // Get user infos
            this.getMe();
        }
    }

    // Get infos on current connected user
    getMe() {
        const authManager = this.props.parentApi.controller.authManager;
        if (this.props.connected && authManager.userInfos?.id) {
            this.setState({ userInfos: authManager.userInfos });
        } else {
            authManager.getMe(this.props.parentApi.callbacks).then((connected) => {
                if (connected) {
                    this.setState({ userInfos: authManager.userInfos });
                }
            });
        }
    }

    /**
     * Updates index of tab to open for indicators in edition mode
     * @param {indicateur} : metadata of the indicator being edited
     */
    updateTabIndicateur = (indicateur) => {
        this.setState({
            tabIndicateur: this.state.tabIndicateur + 1,
            modeIndicateur: "modifier",
            indicateurCourant: indicateur,
        });
    };

    goToDataTab = () => {
        this.setState({ mainTab: 2, tabIndicateur: 0 });
    };

    /**
     * Updates the state of the component with a parameter that defines whether
     * to launch the AjoutObjectif component to add an objective or to update one.
     * @param {chaine de caractères} mode : Mode for launching the AjoutObjectif component (ajouter or mise_a_jour)
     * @param {int} objectifCourant : the characteristics of the objective to update (in the case of an update)
     */
    modeAjoutMiseAJourObjectifs = (mode, objectifCourant) => {
        this.setState({
            modeObjectifs: mode,
            tabIndexObjectif: this.state.tabIndexObjectif + 1,
            objectifCourant: objectifCourant,
        });
    };

    accesPageConfiguration = (analysis) => {
        // We switch to the creation tab, but in update mode
        this.setState({
            configurer: true,
            donneesRegistre: analysis,
            tabIndicateur: this.state.tabIndicateur + 1,
            annee: analysis.annee,
        });
    };

    /**
     * Mise à jour de l'index de l'onglet à ouvrir pour les équipements (liste ou édition)
     * @param {tab} : index (int) of the add/modify tab of the Equipment management interface
     * @param {mode} : addition or modification
     * @param {indicateur} : metadata of equipment being editd
     */
    updateTabEquipements = (tab, mode, equipement) => {
        // update the state
        this.setState({
            tabEquipements: tab,
            modeEquipements: mode,
            equipementCourant: equipement,
        });
    };

    /**
     * Update of the index of the tab to open for the data sources logo (list or edition)
     * @param {tabLogo} : index (int) of the add/modify tab of the data source logo management interface
     * @param {modeLogo} : addition or modification
     * @param {dataSourceLogoCourant} : metadata of the logo being edited
     */
    updateTabDataSource = (mode, dataSourceLogoCourant) => {
        // update the state
        this.setState({
            modeLogo: mode,
            dataSourceLogoCourant: dataSourceLogoCourant,
            tabLogo: 1,
        });
    };

    /**
     * Update of the index of the tab to open for the data didactic file (list or edition)
     * @param {tabDidacticFile} : index (int) of the add/modify tab of the data didactic file management interface
     * @param {modeDidacticFile} : addition or modification
     * @param {dataDidacticFile} : metadata of the didactic file being edited
     */
    updateTabDataDidacticFile = (
        tabDidacticFile,
        mode = undefined,
        dataCurrentDidacticFile = undefined
    ) => {
        // update the state
        this.setState({
            modeDidacticFile: mode,
            dataCurrentDidacticFile: dataCurrentDidacticFile,
            tabDidacticFile: tabDidacticFile,
        });
    };

    render() {
        if (!this.props.connected) {
            return (
                <div className="account widgets full-screen-widget">
                    <Link
                        className="back-to-map"
                        to={"/" + this.props.parentApi.data.urlPartageable}
                        aria-label="Retour à la carte"
                    >
                        <span className="close close-big">
                            <span aria-hidden="true">&times;</span>
                        </span>
                    </Link>
                </div>
            );
        }
        // My account labels -------------------------------------------------
        let labelMyAccount = "Mon compte";
        let pdfLink = "";
        const isUserAdmin =
            this.state.userInfos && this.state.userInfos.profil === "admin";
        const canEditPOIContributions =
            this.state.userInfos && this.state.userInfos.canValidatePOIContributions;
        if (isUserAdmin) {
            labelMyAccount = "Administration";

            pdfLink = (
                <a
                    href={
                        "/pdf/" +
                        this.props.parentApi.data.region +
                        "/documentation-utilisation-interfaces-admin.pdf"
                    }
                    title="Guide de l'interface d'administration"
                    target="_blank"
                    rel="noreferrer"
                >
                    <span className="pdf-admin"></span>
                </a>
            );
        }

        let titreTabEditionIndicateur = "Ajout d'un indicateur";
        if (this.state.modeIndicateur === "modifier") {
            titreTabEditionIndicateur = "Modifier un indicateur";
        }

        let titreTabEditionObjectif = "Ajout d'un objectif supra-territorial";
        if (this.state.modeObjectifs === "mise_a_jour") {
            titreTabEditionObjectif = "Modifier un objectif supra-territorial";
        }
        // let pageConfigurerDonneesRegistre = "";
        let intitulePageConfigurerDonneesRegistre = "";

        if (this.state.configurer) {
            intitulePageConfigurerDonneesRegistre =
                "Configurer " + this.state.donneesRegistre.nom;
        }

        let titreTabEditionDataSourceLggo = "Ajout d'un logo";
        if (this.state.modeLogo === "update") {
            titreTabEditionDataSourceLggo = "Modifier un logo";
        }

        let titreTabEditionDataDidacticFile = "Ajout d'une fiche didactique";
        if (this.state.modeDidacticFile === "update") {
            titreTabEditionDataDidacticFile = "Modifier une fiche didactique";
        }

        // My account / dashboard ----------------------------------------
        return (
            <div className="account widgets full-screen-widget">
                <Link
                    className="back-to-map"
                    to={"/" + this.props.parentApi.data.urlPartageable}
                >
                    <button
                        type="button"
                        className="close close-big"
                        data-dismiss="alert"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Link>
                <div className="panel panel-default centered-row">
                    <div className="panel-heading clearfix">
                        <h2 className="panel-title pull-left">
                            {labelMyAccount} {pdfLink}
                        </h2>
                    </div>
                    <div className="panel-body user-infos">
                        <Tabs
                            selectedIndex={this.state.mainTab}
                            onSelect={(index, lastIndex) => {
                                this.setState({ mainTab: index });
                            }}
                        >
                            <TabList>
                                <Tab>Infos personnelles</Tab>
                                {!isUserAdmin && canEditPOIContributions && (
                                    <Tab>Contributions aux POIs</Tab>
                                )}
                                {isUserAdmin && (
                                    <>
                                        <Tab>Utilisateurs</Tab>
                                        <Tab>Indicateurs, données</Tab>
                                        <Tab>Equipements</Tab>
                                        <Tab>Territoires</Tab>
                                        <Tab>Stats & Historique</Tab>
                                        <Tab>Stratégie territoriale</Tab>
                                        <Tab>Personnalisation</Tab>
                                        <Tab>Gestion des données communes</Tab>
                                        <Tab>Fiches didactiques</Tab>
                                    </>
                                )}
                            </TabList>
                            <TabPanel>
                                <PersonnalInfo
                                    parentApi={this.props.parentApi}
                                    userInfos={this.state.userInfos}
                                    getMe={() => this.getMe()}
                                />
                            </TabPanel>
                            {!isUserAdmin && canEditPOIContributions && (
                                <TabPanel>
                                    <POIUserContributions
                                        connected={this.props.connected}
                                        userInfos={this.state.userInfos}
                                        parentApi={this.props.parentApi}
                                    />
                                </TabPanel>
                            )}
                            {isUserAdmin && (
                                <>
                                    <TabPanel>
                                        <UsersManage
                                            connected={this.props.connected}
                                            userInfos={this.state.userInfos}
                                            parentApi={this.props.parentApi}
                                        />
                                    </TabPanel>
                                    <TabPanel>
                                        <Tabs
                                            selectedIndex={this.state.tabIndicateur}
                                            onSelect={(index, lastIndex) => {
                                                this.setState({
                                                    tabIndicateur: index,
                                                    modeIndicateur: "ajouter",
                                                });
                                            }}
                                        >
                                            <TabList>
                                                <Tab>Gestion des données</Tab>
                                                <Tab>Gestion des indicateurs</Tab>
                                                <Tab>{titreTabEditionIndicateur}</Tab>
                                                <Tab>Ordre des indicateurs</Tab>
                                                <Tab>Gestion des catégories</Tab>
                                                <Tab>API externes</Tab>
                                                <Tab>Diagrammes de Sankey</Tab>
                                                <Tab>Données du registre</Tab>
                                                {intitulePageConfigurerDonneesRegistre !==
                                                    "" && (
                                                    <Tab>
                                                        {
                                                            intitulePageConfigurerDonneesRegistre
                                                        }
                                                    </Tab>
                                                )}
                                            </TabList>
                                            <TabPanel>
                                                <DataManage
                                                    connected={this.props.connected}
                                                    userInfos={this.state.userInfos}
                                                    parentApi={this.props.parentApi}
                                                />
                                            </TabPanel>
                                            <TabPanel>
                                                <AnalysisManage
                                                    connected={this.props.connected}
                                                    userInfos={this.state.userInfos}
                                                    parentApi={this.props.parentApi}
                                                    updateTabIndicateur={
                                                        this.updateTabIndicateur
                                                    }
                                                />
                                            </TabPanel>
                                            <TabPanel>
                                                <AnalysisAjouter
                                                    connected={this.props.connected}
                                                    userInfos={this.state.userInfos}
                                                    parentApi={this.props.parentApi}
                                                    mode={this.state.modeIndicateur}
                                                    indicateur={
                                                        this.state.indicateurCourant
                                                    }
                                                />
                                            </TabPanel>
                                            <TabPanel>
                                                <AnalysisOrder
                                                    connected={this.props.connected}
                                                    userInfos={this.state.userInfos}
                                                    parentApi={this.props.parentApi}
                                                />
                                            </TabPanel>
                                            <TabPanel>
                                                <CategoriesManage
                                                    connected={this.props.connected}
                                                    userInfos={this.state.userInfos}
                                                    parentApi={this.props.parentApi}
                                                ></CategoriesManage>
                                            </TabPanel>
                                            <TabPanel>
                                                <ExternalAPI
                                                    connected={this.props.connected}
                                                    userInfos={this.state.userInfos}
                                                    parentApi={this.props.parentApi}
                                                />
                                            </TabPanel>
                                            <TabPanel>
                                                <Sankeys
                                                    connected={this.props.connected}
                                                    userInfos={this.state.userInfos}
                                                    parentApi={this.props.parentApi}
                                                />
                                            </TabPanel>
                                            <TabPanel>
                                                <DonneesRegistre
                                                    connected={this.props.connected}
                                                    userInfos={this.state.userInfos}
                                                    parentApi={this.props.parentApi}
                                                    callbackConfiguration={
                                                        this.accesPageConfiguration
                                                    }
                                                />
                                            </TabPanel>
                                            {intitulePageConfigurerDonneesRegistre !==
                                                "" && (
                                                <TabPanel>
                                                    <ConfigurerDonneesRegistre
                                                        connected={this.props.connected}
                                                        userInfos={this.state.userInfos}
                                                        parentApi={this.props.parentApi}
                                                        donneesRegistre={
                                                            this.state.donneesRegistre
                                                        }
                                                        configurer={
                                                            this.state.configurer
                                                        }
                                                        annee={this.state.annee}
                                                    />
                                                </TabPanel>
                                            )}
                                        </Tabs>
                                    </TabPanel>
                                    <TabPanel>
                                        <Tabs
                                            selectedIndex={this.state.tabEquipements}
                                            onSelect={(index, lastIndex) =>
                                                this.setState({
                                                    tabEquipements: index,
                                                    modeEquipements: "ajouter",
                                                })
                                            }
                                        >
                                            <TabList>
                                                <Tab>Gestion des équipements</Tab>
                                                <Tab>Ajout d'une couche équipement</Tab>
                                                <Tab>Ordre des équipements</Tab>
                                                <Tab>
                                                    Contributions des utilisateurs
                                                </Tab>
                                            </TabList>
                                            <TabPanel>
                                                <POIManage
                                                    connected={this.props.connected}
                                                    userInfos={this.state.userInfos}
                                                    parentApi={this.props.parentApi}
                                                    updateTabEquipements={
                                                        this.updateTabEquipements
                                                    }
                                                />
                                            </TabPanel>
                                            <TabPanel>
                                                <POIEdition
                                                    connected={this.props.connected}
                                                    userInfos={this.state.userInfos}
                                                    parentApi={this.props.parentApi}
                                                    mode={this.state.modeEquipements}
                                                    equipementCourant={
                                                        this.state.equipementCourant
                                                    }
                                                />
                                            </TabPanel>
                                            <TabPanel>
                                                <POIOrder
                                                    connected={this.props.connected}
                                                    userInfos={this.state.userInfos}
                                                    parentApi={this.props.parentApi}
                                                />
                                            </TabPanel>
                                            <TabPanel>
                                                <POIUserContributions
                                                    connected={this.props.connected}
                                                    userInfos={this.state.userInfos}
                                                    parentApi={this.props.parentApi}
                                                />
                                            </TabPanel>
                                        </Tabs>
                                    </TabPanel>
                                    <TabPanel>
                                        <Tabs
                                            selectedIndex={this.state.tabTerritories}
                                            onSelect={(index, lastIndex) => {
                                                this.setState({
                                                    tabTerritories: index,
                                                });
                                            }}
                                        >
                                            <TabList>
                                                <Tab>Gestion des périmètres</Tab>
                                                <Tab>Gestion des zones disponibles</Tab>
                                            </TabList>
                                            <TabPanel>
                                                <TerritoiresManage
                                                    connected={this.props.connected}
                                                    userInfos={this.state.userInfos}
                                                    parentApi={this.props.parentApi}
                                                    goToDataTab={this.goToDataTab}
                                                ></TerritoiresManage>
                                            </TabPanel>
                                            <TabPanel>
                                                <ZonesManage
                                                    connected={this.props.connected}
                                                    userInfos={this.state.userInfos}
                                                    parentApi={this.props.parentApi}
                                                ></ZonesManage>
                                            </TabPanel>
                                        </Tabs>
                                    </TabPanel>
                                    <TabPanel>
                                        <Tabs
                                            selectedIndex={this.state.tabHistory}
                                            onSelect={(index, lastIndex) =>
                                                this.setState({
                                                    tabHistory: index,
                                                })
                                            }
                                        >
                                            <TabList>
                                                <Tab>Statistiques</Tab>
                                                <Tab>Indicateurs</Tab>
                                                <Tab>Équipements</Tab>
                                            </TabList>
                                            <TabPanel>
                                                <Statistics
                                                    connected={this.props.connected}
                                                    userInfos={this.state.userInfos}
                                                    parentApi={this.props.parentApi}
                                                />
                                            </TabPanel>
                                            <TabPanel>
                                                <DataHistory
                                                    connected={this.props.connected}
                                                    userInfos={this.state.userInfos}
                                                    parentApi={this.props.parentApi}
                                                />
                                            </TabPanel>
                                            <TabPanel>
                                                <POIHistory
                                                    connected={this.props.connected}
                                                    userInfos={this.state.userInfos}
                                                    parentApi={this.props.parentApi}
                                                />
                                            </TabPanel>
                                        </Tabs>
                                    </TabPanel>
                                    <TabPanel>
                                        <Tabs
                                            selectedIndex={this.state.tabIndexObjectif}
                                            onSelect={(index) =>
                                                this.setState({
                                                    tabIndexObjectif: index,
                                                    modeObjectifs: "ajouter",
                                                })
                                            }
                                        >
                                            <TabList>
                                                <Tab>Informations générales</Tab>
                                                <Tab>PCAET</Tab>
                                                <Tab>Table de passage</Tab>
                                                <Tab>Erreurs répertoriées</Tab>
                                                <Tab>Export des résultats</Tab>
                                                <Tab>
                                                    Gestion des objectifs
                                                    supra-territoriaux
                                                </Tab>
                                                <Tab>{titreTabEditionObjectif}</Tab>
                                                <Tab>
                                                    Stratégies territoriales des
                                                    utilisateurs de la région
                                                </Tab>
                                            </TabList>
                                            <TabPanel>
                                                <StrategyInformation
                                                    parentApi={this.props.parentApi}
                                                    connected={this.props.connected}
                                                />
                                            </TabPanel>
                                            <TabPanel>
                                                <Pcaet
                                                    connected={this.props.connected}
                                                    userInfos={this.state.userInfos}
                                                    parentApi={this.props.parentApi}
                                                />
                                            </TabPanel>
                                            <TabPanel>
                                                <PassageTable
                                                    parentApi={this.props.parentApi}
                                                    connected={this.props.connected}
                                                />
                                            </TabPanel>
                                            <TabPanel>
                                                <StrategyErrors
                                                    parentApi={this.props.parentApi}
                                                    connected={this.props.connected}
                                                />
                                            </TabPanel>
                                            <TabPanel>
                                                <EditStrategyResultsFile
                                                    parentApi={this.props.parentApi}
                                                    connected={this.props.connected}
                                                />
                                            </TabPanel>
                                            <TabPanel>
                                                <GestionObjectifs
                                                    parentApi={this.props.parentApi}
                                                    connected={this.props.connected}
                                                    userInfos={this.state.userInfos}
                                                    modeAjoutMiseAJourObjectifs={
                                                        this.modeAjoutMiseAJourObjectifs
                                                    }
                                                />
                                            </TabPanel>
                                            <TabPanel>
                                                <AjoutObjectifs
                                                    parentApi={this.props.parentApi}
                                                    connected={this.props.connected}
                                                    userInfos={this.state.userInfos}
                                                    objectifCourant={
                                                        this.state.objectifCourant
                                                    }
                                                    mode={this.state.modeObjectifs}
                                                />
                                            </TabPanel>
                                            <TabPanel>
                                                <RegionScenarioTable
                                                    parentApi={this.props.parentApi}
                                                    connected={this.props.connected}
                                                    userInfos={this.state.userInfos}
                                                />
                                            </TabPanel>
                                        </Tabs>
                                    </TabPanel>
                                    <TabPanel>
                                        <Tabs
                                            selectedIndex={this.state.tabCustomization}
                                            onSelect={(index) =>
                                                this.setState({
                                                    tabCustomization: index,
                                                })
                                            }
                                        >
                                            <TabList>
                                                <Tab>Mise à jour des PDFs généraux</Tab>
                                                <Tab>
                                                    Personnalisation pop-up accueil
                                                </Tab>
                                            </TabList>
                                            <TabPanel>
                                                <StaticFilesManage
                                                    parentApi={this.props.parentApi}
                                                />
                                            </TabPanel>
                                            <TabPanel>
                                                <EditeurPopUp
                                                    connected={this.props.connected}
                                                    userInfos={this.state.userInfos}
                                                    parentApi={this.props.parentApi}
                                                />
                                            </TabPanel>
                                        </Tabs>
                                    </TabPanel>
                                    <TabPanel>
                                        <GererDonneesFrance
                                            connected={this.props.connected}
                                            userInfos={this.state.userInfos}
                                            parentApi={this.props.parentApi}
                                            goToDataTab={this.goToDataTab}
                                        />
                                    </TabPanel>
                                    <TabPanel>
                                        <Tabs
                                            selectedIndex={this.state.tabDidacticFile}
                                            onSelect={(index, lastIndex) =>
                                                this.setState({
                                                    tabDidacticFile: index,
                                                    modeDidacticFile: "add",
                                                })
                                            }
                                        >
                                            <TabList>
                                                <Tab>Gestion des fiches didactique</Tab>
                                                <Tab>
                                                    {titreTabEditionDataDidacticFile}
                                                </Tab>
                                                <Tab>Gestion des logos</Tab>
                                            </TabList>

                                            <TabPanel>
                                                <DidacticFileManage
                                                    connected={this.props.connected}
                                                    userInfos={this.state.userInfos}
                                                    parentApi={this.props.parentApi}
                                                    updateTabDataDidacticFile={
                                                        this.updateTabDataDidacticFile
                                                    }
                                                />
                                            </TabPanel>
                                            <TabPanel>
                                                <AddDidacticFile
                                                    connected={this.props.connected}
                                                    userInfos={this.state.userInfos}
                                                    parentApi={this.props.parentApi}
                                                    mode={this.state.modeDidacticFile}
                                                    dataCurrentDidacticFile={
                                                        this.state
                                                            .dataCurrentDidacticFile
                                                    }
                                                    updateTabDataDidacticFile={
                                                        this.updateTabDataDidacticFile
                                                    }
                                                />
                                            </TabPanel>
                                            <TabPanel>
                                                <Tabs
                                                    selectedIndex={this.state.tabLogo}
                                                    onSelect={(index, lastIndex) =>
                                                        this.setState({
                                                            tabLogo: index,
                                                            modeLogo: "add",
                                                        })
                                                    }
                                                >
                                                    <TabList>
                                                        <Tab>Gestion des Logos</Tab>
                                                        <Tab>
                                                            {
                                                                titreTabEditionDataSourceLggo
                                                            }
                                                        </Tab>
                                                    </TabList>
                                                    <TabPanel>
                                                        <DataSourceLogoManage
                                                            connected={
                                                                this.props.connected
                                                            }
                                                            userInfos={
                                                                this.state.userInfos
                                                            }
                                                            parentApi={
                                                                this.props.parentApi
                                                            }
                                                            updateTabDataSource={
                                                                this.updateTabDataSource
                                                            }
                                                        />
                                                    </TabPanel>
                                                    <TabPanel>
                                                        <AddDataSourceLogo
                                                            connected={
                                                                this.props.connected
                                                            }
                                                            userInfos={
                                                                this.state.userInfos
                                                            }
                                                            parentApi={
                                                                this.props.parentApi
                                                            }
                                                            mode={this.state.modeLogo}
                                                            dataSourceLogoCourant={
                                                                this.state
                                                                    .dataSourceLogoCourant
                                                            }
                                                        />
                                                    </TabPanel>
                                                </Tabs>
                                            </TabPanel>
                                        </Tabs>
                                    </TabPanel>
                                </>
                            )}
                        </Tabs>
                    </div>
                </div>
            </div>
        );
    }
}

class PersonnalInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status: "",
            newPasswordFormWidgetOn: false,
            editInfoWidgetOn: false,
        };
    }

    handleNewPasswordForm(e) {
        this.setState({
            newPasswordFormWidgetOn: true,
        });
    }
    handleNewPassword(e) {
        // Check if password1 = password2
        let password1 = ReactDOM.findDOMNode(this.refs["password1"]).value;
        let password2 = ReactDOM.findDOMNode(this.refs["password2"]).value;
        if (password1 !== password2) {
            this.setState({
                status: "Les mots de passe ne sont pas identiques.",
            });
            return;
        }

        // Change the password
        let url = buildRegionUrl(
            config.user_set_new_password,
            this.props.parentApi.data.region
        );
        const body = JSON.stringify({ new_password: password1 });
        Api.callApi(url, body, "POST")
            .then((response) => {
                this.setState({
                    status: "Votre mot de passe a bien été changé.",
                    newPasswordFormWidgetOn: false,
                });
            })
            .catch((e) => this.setState({ status: e.message }));
    }

    closeUserEdition() {
        this.setState({ editInfoWidgetOn: false });
        this.props.getMe();
    }

    render() {
        // New password form ------------------------------------------
        let newPasswordForm = "";
        if (this.state.newPasswordFormWidgetOn) {
            newPasswordForm = (
                <div className="panel-body user-login-form">
                    <div className="user-password-form">
                        <div className="form-group">
                            <label>Nouveau mot de passe</label>
                            <input
                                type="password"
                                className="form-inline input"
                                id="password1"
                                ref="password1"
                            />
                        </div>
                        <div className="form-group">
                            <label>Retapez le nouveau mot de passe</label>
                            <input
                                type="password"
                                className="form-inline input"
                                id="password2"
                                ref="password2"
                            />
                        </div>
                        <div className="form-group">
                            <label></label>
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={(e) => this.handleNewPassword(e)}
                            >
                                Modifier
                            </button>
                        </div>
                    </div>
                </div>
            );
        }

        let editInfoForm = "";
        if (this.state.editInfoWidgetOn) {
            editInfoForm = (
                <UserEdition
                    parentApi={this.props.parentApi}
                    mode="profile"
                    onClose={this.closeUserEdition.bind(this)}
                />
            );
        }

        return (
            <div>
                <h3 className="panel-title pull-left">Mes informations</h3>
                <div className="block-row">
                    {this.props.userInfos && (
                        <ul className="user-meta">
                            <li>
                                <label>Identifiant : </label>
                                <span className="value">{this.props.userInfos.id}</span>
                            </li>
                            <li>
                                <label>Prénom : </label>
                                <span className="value">
                                    {this.props.userInfos.prenom}
                                </span>
                            </li>
                            <li>
                                <label>Nom : </label>
                                <span className="value">
                                    {this.props.userInfos.nom}
                                </span>
                            </li>
                            <li>
                                <label>Organisation : </label>
                                <span className="value">
                                    {this.props.userInfos.organisation}
                                </span>
                            </li>
                            <li>
                                <label>Fonction : </label>
                                <span className="value">
                                    {this.props.userInfos.fonction}
                                </span>
                            </li>
                            <li>
                                <label>Territoire : </label>
                                <span className="value">
                                    {(() => {
                                        const { zoneType, zoneMaille, zoneId } =
                                            this.props.userInfos
                                                .territoire_predilection;
                                        const zoneName =
                                            this.props.parentApi.controller.zonesManager.getZoneName(
                                                zoneId,
                                                { zone: zoneType }
                                            );
                                        return (
                                            `${zoneType} - maille ${zoneMaille}` +
                                            (zoneName ? " : " + zoneName : "")
                                        );
                                    })()}
                                </span>
                            </li>
                            <li>
                                <span>
                                    Territoire sélectionné automatiquement lors de la
                                    connexion :
                                </span>{" "}
                                <span className="value">
                                    {this.props.userInfos
                                        .utiliser_territoire_predilection
                                        ? "oui"
                                        : "non"}
                                </span>
                            </li>
                            <li>
                                <label>Code postal : </label>
                                <span className="value">
                                    {this.props.userInfos.code_postal}
                                </span>
                            </li>
                            <li>
                                <label>Profil : </label>
                                <span className="value">
                                    {this.props.userInfos.profil}
                                </span>
                            </li>
                        </ul>
                    )}
                    <Link to="/contact">
                        <button className="btn btn-primary">
                            Demande de suppression de compte
                        </button>
                    </Link>{" "}
                    <button
                        className="btn btn-primary"
                        onClick={() => this.handleNewPasswordForm()}
                    >
                        Changer mon mot de passe
                    </button>{" "}
                    <button
                        className="btn btn-primary"
                        onClick={() => this.setState({ editInfoWidgetOn: true })}
                    >
                        Modifier mon territoire, mes informations
                    </button>
                    {newPasswordForm}
                </div>
                <div>{this.state.status}</div>
                {editInfoForm}
            </div>
        );
    }
}

export default Profile;
