/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";

import Api from "../../Controllers/Api";
import { buildRegionUrl } from "../../utils.js";
import config from "../../settings.js";

/**
 * This component is used to create a gauge chart.
 */
class Jauge extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id_analyse: String(props.id_analysis),
            representation: "jauge",
            widthDOM: window.innerWidth,
            disabled: false,
        };
        // Get data from API
        this.fetchConfiguration();
    }

    componentDidMount() {
        let chartsLegendsDiv = document.getElementsByClassName("charts-legende");
        if (chartsLegendsDiv.length > 0) {
            let widthDiv =
                document.getElementsByClassName("charts-legende")[0].offsetWidth;
            if (
                this.props.parentApi.data.tailleDiv !== widthDiv &&
                this.props.provenance === "tableau_de_bord_restitue"
            ) {
                this.props.parentApi.callbacks.tailleDiv(widthDiv);
            }
        }
    }

    /**
     * This function updates the data from which we build the gauge charts when changing territory
     * @param {object key => value} prevProps : main component properties before territory change via selection tool
     */
    componentDidUpdate(prevProps) {
        let chartsLegendsDiv = document.getElementsByClassName("charts-legende");
        if (chartsLegendsDiv.length > 0) {
            let widthDiv =
                document.getElementsByClassName("charts-legende")[0].offsetWidth;
            if (
                this.props.parentApi.data.tailleDiv !== widthDiv &&
                this.props.provenance === "tableau_de_bord_restitue"
            ) {
                this.props.parentApi.callbacks.tailleDiv(widthDiv);
            }
        }

        let currentZone = this.props.zoneId ?? this.props.parentApi.data.currentZone;
        if (
            (prevProps.zoneId ?? prevProps.parentApi.data.currentZone) !== currentZone
        ) {
            // If the territory has changed
            if (this.state.id_analyse) {
                this.fetchConfiguration(); // Get data
            }
        }
    }

    componentWillUnmount() {
        if (this.dataPromise) {
            this.dataPromise.abort();
        }
    }

    fetchConfiguration() {
        let pZone =
            "?zone=" + (this.props.zoneType ?? this.props.parentApi.data.zone.zone);
        let pMaille =
            "&maille=" +
            (this.props.zoneMaille ?? this.props.parentApi.data.zone.maille);
        let pZoneId =
            "&zone_id=" + (this.props.zoneId ?? this.props.parentApi.data.currentZone);
        if ((this.props.zoneType ?? this.props.parentApi.data.zone.zone) === "region") {
            pZoneId = "&zone_id=" + this.props.parentApi.data.regionCode;
        }
        let id_analyse = this.state.id_analyse;
        let representation = this.state.representation;
        let body = JSON.stringify({});
        let dataSource =
            config.api_analysis_meta_url +
            id_analyse +
            "/graphique/" +
            representation +
            pZone +
            pMaille +
            pZoneId;

        if (this.dataPromise) this.dataPromise.abort();
        this.dataPromise = Api.callApi(
            buildRegionUrl(dataSource, this.props.parentApi.data.region),
            body,
            "POST"
        );
        this.dataPromise
            .then((json) => {
                if (json["disabled"] && json["disabled"] === true) {
                    this.setState({
                        disabled: true,
                    });
                    return;
                }

                this.setState({
                    picto: json["svg"],
                });
            })
            .catch((error) => {
                if (error.name === "AbortError") return;
                console.error(error);
            });
    }

    render() {
        if (this.state.disabled === true) {
            return (
                <div className="charts-legende">
                    <div className={"confid-chart"}>
                        Cet indicateur n'est pas activé actuellement, veuillez contacter
                        l'administrateur régional.
                    </div>
                </div>
            );
        }

        return (
            <div className="charts-legende">
                <div
                    ref={this.state.ref}
                    dangerouslySetInnerHTML={{ __html: this.state.picto }}
                ></div>
            </div>
        );
    }
}

export default Jauge;
