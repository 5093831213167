/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";

import Charts from "./Charts";

import "bootstrap/dist/css/bootstrap.min.css";
// import 'react-table/react-table.css'

/**
 * This component is used to integrate one or more graphics in a page.
 */
class ChartsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = { display: true };

        this.toggleDisplay = this.toggleDisplay.bind(this);
    }

    toggleDisplay() {
        this.setState({
            display: !this.state.display,
        });
    }

    render() {
        let charts = [];
        let chartsIds = [];
        for (let a of this.props.analysisManager.analysis) {
            if (a.id === this.props.analysis) {
                for (let c = 0; c < a.charts.length; c++) {
                    // we do not want to show anything for switch button or selection categories
                    if (["switch-button", "selection"].includes(a.charts?.[c]?.type)) {
                        continue;
                    }
                    if (
                        (a.charts[c] &&
                            a.charts[c].visible &&
                            a.data_type !== "climat") ||
                        (a.charts[c] &&
                            a.data_type === "climat" &&
                            this.props.parentApi.data.displayChart)
                    ) {
                        let last = false;
                        if (c === a.charts.length - 1) last = true;
                        let unique_id = this.props.analysis + a.charts[c].categorie;
                        charts.push(
                            <Charts
                                key={unique_id}
                                parentApi={this.props.parentApi}
                                id={unique_id}
                                categorie={a.charts[c].categorie}
                                chartsIds={chartsIds}
                                last={last}
                                nbCharts={a.charts.length}
                                type={a.charts[c].categorie}
                                ordre={a.charts[c].ordre}
                            />
                        );
                        chartsIds.push(unique_id);
                        charts.sort((a, b) => a.props.ordre - b.props.ordre);
                        if (a.charts[c].messageNePasAfficherDonneesMailles) {
                            break;
                        }
                    }
                }
                break;
            }
        }

        if (charts.length === 0) {
            return null;
        }
        let classOpenedChartsContainer = " chartsContainer-closed";
        let iconColapse = <span aria-hidden="true">&uArr;</span>;
        if (this.state.display) {
            classOpenedChartsContainer = "";
            iconColapse = <span aria-hidden="true">&dArr;</span>;
        }

        let classChartContainerFullWidth = " chartsContainer-full-width";
        if (this.props.parentApi.data.toggleMenu) {
            classChartContainerFullWidth = "";
        }

        let conteneurGraphiques = "";

        if (
            this.props.parentApi.data.analysisMeta ||
            this.props.parentApi.data.displayChart
        ) {
            conteneurGraphiques = (
                <div
                    className={
                        "chartsContainer" +
                        classOpenedChartsContainer +
                        classChartContainerFullWidth
                    }
                >
                    {charts}
                    <button
                        type="button"
                        className="colapse"
                        aria-label="Masquer/Afficher"
                        onClick={this.toggleDisplay}
                    >
                        {iconColapse}
                    </button>
                </div>
            );
        }

        return conteneurGraphiques;
    }
}

export default ChartsContainer;
