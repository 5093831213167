/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";

import Api from "../../Controllers/Api";
import config from "../../settings.js";
import { buildRegionUrl } from "../../utils.js";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-table-6/react-table.css";
import Select from "react-select";
import { SketchPicker } from "react-color";
import reactCSS from "reactcss";
import configData from "../../settings_data.js";
import PlanActionsTrajectoiresCibles from "../PlanActionsTrajectoiresCibles";
import { MainZoneSelect } from "../SelectionObjet.js";

/**
 * This component is used to add or update an objective
 */
class AjoutObjectifs extends React.Component {
    constructor(props) {
        super(props);
        this.anneeSelectionnee = [{ value: 2018, label: 2018 }];
        this.selectionGraphique = { value: "", label: "" };
        this.state = {
            titre: "",
            description: "",
            anneesReferencePossibles: [],
            anneesDisponiblesPourTrajectoire: [],
            trajectoiresCibles: [],
            listeAffectationsObjectifs: [],
            displayColorStartPicker: false,
            displayColorEndPicker: false,
            couleur: "#aaaaaa",
            classeMessage: "",
            currentSupraGoalId: undefined,
            anneeSelectionnee: { value: 2018, label: 2018 },
            anneeSelectionneePrecedente: 2018,
            selectionGraphique: {
                value: "",
                label: "",
                statut: "pas-de-selection",
            },
            affectationToutTypeTerritoire: false,
            objectifExiste: false,
            derniereAnneeProjection: 2050,
            anneeProjModifiee: false,
            trajectoires: { annees_valeurs: {}, annees_modifiees: {} },
        };
        this.trajectoires = this.obtenirDonneesTrajInitiales(true);
    }

    componentDidMount() {
        let supraGoalId = this.props.objectifCourant;
        if (supraGoalId) {
            this.setState({
                currentSupraGoalId: supraGoalId,
                objectifExiste: true,
            });
            this.obtenirListeAffectations(supraGoalId);
        }
        this.obtenirAssociationTableGraphique();
        if (this.props.mode === "mise_a_jour") {
            // There are two possible modes: "mise_a_jour" and "ajout"
            this.obtenirDonneesObjectifs(supraGoalId);
        } else {
            this.setState({
                obtentionAnneeReferenceNecessaire: true,
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        let majTrajectoire = false;
        let init = false;
        if (this.state.status !== prevState.status && this.state.objectifExiste) {
            this.obtenirListeAffectations(this.state.currentSupraGoalId);
        }
        // IF the last projection year has been modified and if obtaining the data is not necessary because an existing objective to be updated has been loaded
        let majDerniereAnneeProjection =
            this.state.derniereAnneeProjection !== prevState.derniereAnneeProjection &&
            this.state.obtentionAnneeReferenceNecessaire;
        // If the reference year has been modified and if obtaining the data is not necessary because an existing objective to be updated has been loaded
        let majAnneeReference =
            this.state.anneeSelectionnee.value !== prevState.anneeSelectionnee.value &&
            this.state.obtentionAnneeReferenceNecessaire;
        // If the list of years has been modified (following an update of the reference year or last year of projection)
        let anneesDisponiblesMisesAJour =
            JSON.stringify(this.state.anneesDisponiblesPourTrajectoire) !==
            JSON.stringify(prevState.anneesDisponiblesPourTrajectoire);
        // If the graph has been changed
        let majGraphique =
            this.state.selectionGraphique.value !== prevState.selectionGraphique.value;

        if (majGraphique && this.props.mode !== "mise_a_jour") {
            majTrajectoire = true;
            init = true;
        }
        if (majDerniereAnneeProjection || majAnneeReference) {
            this.obtenirAnneesReference(
                this.state.selectionGraphique,
                this.state.anneeSelectionnee
            ); // If the number of years for which the objective can be changed changes
            majTrajectoire = true;
        }

        if (
            this.state.anneeSelectionnee.value !== prevState.anneeSelectionnee.value &&
            this.state.obtentionAnneeReferenceNecessaire
        ) {
            this.setState({
                anneeSelectionneePrecedente: prevState.anneeSelectionnee.value,
            });
        }

        if (anneesDisponiblesMisesAJour) {
            majTrajectoire = true;
        }

        if (majTrajectoire) {
            let nouvelleTraj = this.obtenirDonneesTrajInitiales(
                init,
                prevState.derniereAnneeProjection,
                prevState.anneeSelectionnee.value
            );
            this.trajectoires = nouvelleTraj;
            this.setState({
                trajectoires: nouvelleTraj,
                anneeProjModifiee: true,
            });
        }
    }

    /**
     * Retrieve objective data to update it (so you can change it if needed).
     * This function returns a dictionary which contains the data for each objective.
     * For exemple : [{titre : REPOS, table : conso_energetique, graphique : Consommation d'énergie, annee_reference : 2016, annee : 2026, valeur : 50}]
     * @param {Chaine de caractères} titre : objective title
     * @param {Chaine de caractères} table : name of the table used to build the graph on which we report the objective
     *
     */
    obtenirDonneesObjectifs(supraGoalId) {
        let url = buildRegionUrl(
            config.api_objectifs,
            this.props.parentApi.data.region
        ).replace("#supra_goal_id#", supraGoalId);
        Api.callApi(url, null, "GET") //  call API to get objectives data
            .then((response) => {
                // Objectives data (Cf. objectifs table)
                let couleur = response[0].couleur;
                let titre = response[0].titre;
                let anneeReference = response[0].annee_reference;
                this.anneeSelectionnee = [
                    { value: anneeReference, label: anneeReference },
                ];
                let derniereAnneeProjection = response[0].derniere_annee_projection;
                let graphiqueReq = response[0].graphique;
                let description = response[0].description;
                this.selectionGraphique = { value: graphiqueReq, label: graphiqueReq };
                let obj = {
                    annees_valeurs: {},
                    annees_modifiees: {},
                    "saisie-objectifs": {
                        valeurs: [],
                        annees: [],
                        annees_modifiees: [],
                    },
                };
                for (let a in response) {
                    // Convert data to the format expected by the component (this.state.obj)
                    obj.annees_valeurs[response[a].annee] = response[a].valeur;
                    obj["saisie-objectifs"].valeurs.push(response[a].valeur);
                    obj["saisie-objectifs"].annees.push(response[a].annee);
                    obj["saisie-objectifs"].annees_modifiees.push(
                        response[a].annee_modifiee
                    );
                    obj.annees_modifiees[response[a].annee] =
                        response[a].annee_modifiee;
                }
                this.trajectoires = obj;
                this.obtenirAnneesReference(
                    { value: graphiqueReq, label: graphiqueReq },
                    this.anneeSelectionnee[0]
                ); // get the reference year used to calcule these objectives
                this.setState({
                    nbreLignes: response.length,
                    titre: titre,
                    couleur: couleur,
                    trajectoires: obj,
                    selectionGraphique: { value: graphiqueReq, label: graphiqueReq },
                    graphiqueReq: graphiqueReq,
                    derniereAnneeProjection: derniereAnneeProjection,
                    anneeSelectionnee: this.anneeSelectionnee[0],
                    anneeSelectionneePrecedente: this.anneeSelectionnee[0].value,
                    description: description,
                    obtentionAnneeReferenceNecessaire: false,
                });
            });
    }

    /**
     * To update an objective, retrieve the reference year used to calculate these objectives (so you can change it if necessary).
     * @param {Chaine de caractère} table : name of the table used to build the graph on which we report the objective
     */
    obtenirAnneesReference(table, anneeSelectionneeCourante) {
        let url = buildRegionUrl(
            config.api_liste_annees_url.replace("#table#", table.value),
            this.props.parentApi.data.region
        );
        Api.callApi(url, null, "GET").then((response) => {
            let listeAnnees = [];
            let anneesDisponiblesPourTrajectoire = [];
            for (let a of response) {
                listeAnnees.push(a.value);
            }

            let anneeSelectionnee = [
                {
                    value: Math.max(...listeAnnees),
                    label: Math.max(...listeAnnees),
                },
            ];

            if (anneeSelectionneeCourante) {
                anneeSelectionnee = [anneeSelectionneeCourante];
            }
            this.anneeSelectionnee = anneeSelectionnee;
            for (
                let a = this.anneeSelectionnee[0].value;
                a <=
                configData.planActionsAnnees[configData.planActionsAnnees.length - 1];
                a++
            ) {
                anneesDisponiblesPourTrajectoire.push({ value: a, label: a });
            }
            this.setState({
                anneeSelectionnee: anneeSelectionnee[0],
                anneesReferencePossibles: response,
                anneesDisponiblesPourTrajectoire: anneesDisponiblesPourTrajectoire,
                selectionGraphique: { label: table.label, value: table.value },
            });
        });
    }

    /**
     * Retrieve the association between the names of graphs and the names of the tables from which they are built.
     */
    obtenirAssociationTableGraphique() {
        let url = buildRegionUrl(
            config.api_association_table_graphique_url,
            this.props.parentApi.data.region
        );
        Api.callApi(url, null, "GET").then((response) => {
            this.setState({
                trajectoiresCibles: response,
            });
        });
    }

    /**
     * Formats the data obtained when regional administrators enter their objective according
     * to the requirements of the API transmitted
     */
    formaterDonneesObjectifs(trajectoire) {
        let donneesFinales = [];
        for (let ref in trajectoire.valeurs) {
            let tmpDict = {
                annee: trajectoire.annees[ref],
                valeur: trajectoire.valeurs[ref],
                id: ref + this.state.titre,
                annee_modifiee: trajectoire.annees_modifiees[ref],
            };
            donneesFinales.push(tmpDict);
        }
        return donneesFinales;
    }

    majAnneeSelectionnee(e) {
        this.setState({
            anneeSelectionnee: e,
            obtentionAnneeReferenceNecessaire: true,
        });
    }

    /**
     * Generate the selection form for the reference year used to calculate the objectives
     */
    choixAnneesReference() {
        return (
            <div className="form-control-small">
                <h5>Année de référence</h5>
                <Select
                    defaultValue={this.state.anneeSelectionnee}
                    value={this.state.anneeSelectionnee}
                    name="annee_ref"
                    options={this.state.anneesReferencePossibles}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(e) => this.majAnneeSelectionnee(e)}
                />
            </div>
        );
    }

    miseAJourTrajectoireCible(proj) {
        this.setState({
            derniereAnneeProjection: proj.value,
            obtentionAnneeReferenceNecessaire: true,
        });
    }

    /**
     * Generate the selection form for the last projection year
     */
    choixDerniereAnneeProjection() {
        let listeAnneesProj = [];
        for (let a of configData.planActionsAnnees) {
            listeAnneesProj.push({ value: a, label: a });
        }
        let maxAnnee = Math.max(...configData.planActionsAnnees);
        if (this.state.derniereAnneeProjection) {
            maxAnnee = this.state.derniereAnneeProjection;
        }
        return (
            <div className="form-control-small">
                <h5>Dernière année de projection</h5>
                <Select
                    defaultValue={{ value: maxAnnee, label: maxAnnee }}
                    name="derniere_annee_proj"
                    options={listeAnneesProj}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(e) => this.miseAJourTrajectoireCible(e)}
                    ref={(ref) => {
                        if (ref) {
                            this.derniereAnneeProjection = ref.getValue();
                        }
                    }}
                />
            </div>
        );
    }

    /**
     * Add the form that allows you to enter the description of the objective to display in the tooltip
     */

    ajouterFormulaireDescription() {
        let description = "";
        if (this.props.mode === "mise_a_jour") {
            description = this.state.description;
        }
        return (
            <div>
                <h5>Description</h5>
                <textarea
                    key={"descriptionTableauBord"}
                    ref={"descriptionObjectif"}
                    type="text"
                    defaultValue={description}
                    onChange={() => this.mettreAJourDescription()}
                ></textarea>
            </div>
        );
    }

    mettreAJourDescription() {
        let descriptionObjectif = ReactDOM.findDOMNode(
            this.refs["descriptionObjectif"]
        ).value;
        this.setState({
            description: descriptionObjectif,
            objectifExiste: false,
        });
    }

    /**
     * Update component state with the color selected by user
     * @param {chaine de caractères} : color encoded in hexadecimal string
     */
    mettreAJourLaCouleur = (couleur) => {
        this.setState({
            couleur: couleur.hex,
        });
    };

    /**
     * Generate the selection form of the graph to report the objective
     */
    choixTrajectoireCible() {
        let valeurParDefaut = {
            value: "Veuillez sélectionner un graphique",
            label: "Veuillez sélectionner un graphique",
            status: "pas-de-selection",
        };
        if (this.props.mode === "mise_a_jour") {
            valeurParDefaut = this.selectionGraphique;
        }

        let formulaireChoixReference = (
            <div className="form-control-small">
                <h5>Sélection des trajectoires cibles</h5>
                <Select
                    defaultValue={valeurParDefaut}
                    name="graph"
                    options={this.state.trajectoiresCibles}
                    onChange={(e) => this.obtenirAnneesReference(e)}
                    className="basic-multi-select"
                    classNamePrefix="select"
                />
            </div>
        );
        if (this.props.mode === "mise_a_jour") {
            formulaireChoixReference = (
                <div className="form-control-small">
                    <h5>{"Graphique : " + valeurParDefaut.label}</h5>
                </div>
            );
        }
        return formulaireChoixReference;
    }

    /**
     * Updates this.state.displayColorStartPicker
     * As long as this.state.displayColorStartPicker is equal to false, the color form is not displayed
     * As soon as it is true, the form appears and the administrator can select the color of his choice
     */
    handleCouleurDebutClick = () => {
        this.setState({
            displayColorStartPicker: !this.state.displayColorStartPicker,
        });
    };

    /**
     * Set this.state.displayColorStartPicker to false to close the form when the color has been selected
     */
    handleCouleurDebutClose = () => {
        this.setState({ displayColorStartPicker: false });
    };

    /**
     * The color of the target trajectory
     */
    choixCouleur() {
        const stylesColorDebut = reactCSS({
            default: {
                color: {
                    background: this.state.couleur,
                },
            },
        });

        return (
            <div className="formulaire-horizontal-alignement-vertical-centre">
                <label className="">Couleur</label>
                <div className="custom-block">
                    <div
                        className="color-picker-swatch"
                        onClick={this.handleCouleurDebutClick}
                    >
                        <div
                            className="color-picker-color"
                            style={stylesColorDebut.color}
                        />
                    </div>
                    {this.state.displayColorStartPicker ? (
                        <div className="color-picker-popover">
                            <div
                                className="color-picker-cover"
                                onClick={this.handleCouleurDebutClose}
                            />
                            <SketchPicker
                                color={this.state.couleur}
                                onChange={this.mettreAJourLaCouleur}
                            />
                        </div>
                    ) : null}
                </div>
            </div>
        );
    }

    /**
     * Updates the component state with the latest information that the regional administrator enters in the form.
     * @param {Entier} i : incremental identifier to identify which year is associated with which value identifiant
     * @param {chaine de caractères} param : this is either the projection year or the value associated with that year
     * @param {chaine de caractères} ref : reference of the form from which we obtain the value that the administrator in the region will have entered
     */
    mettreAJourParam(i, param, ref) {
        let val = ReactDOM.findDOMNode(this.refs[ref]).value; // Value entered in the form (balise <input>)
        let paramAMettreAJour;
        if (param === "annee") {
            // If the form whose value is retrieved is the projection year
            paramAMettreAJour = this.state.obj;
            paramAMettreAJour.anneesObj[i] = val; // In the objectives, integrate the years. For exemple : {0 : 2026, 1 : 2033, 2 : 2050}
            this.setState({
                obj: paramAMettreAJour,
            });
        } else if (param === "valeur") {
            // If the form whose value is retrieved is the value associated with the projection year
            paramAMettreAJour = this.state.obj;
            paramAMettreAJour.valeurObj[i] = val;
            this.setState({
                valeurObj: paramAMettreAJour,
            });
        }
    }

    /**
     * Integrate the data the administrator has entered into the database
    @param {objet clé => valeur} graphiques : object of type {value: name of the table from which the target trajectories are calculated, label: name of the graph}
    @param {Entier} anneeRef : reference year used to calculate the objectives.
    @param {chaine de caractères} couleur : color of trajectory curve.
    */
    ajouterObjectifs(graphiques, anneeRef, couleur, derniereAnneeProjection) {
        if (!this.state.titre) {
            this.setState({
                status: "Veuillez saisir le titre de votre objectif",
                classeMessage: "alert alert-warning",
            });
            return;
        }

        if (!graphiques) {
            this.setState({
                status: "Veuillez sélectionner les graphiques auxquels ajouter cet objectif",
                classeMessage: "alert alert-warning",
            });
            return;
        }

        if (!anneeRef) {
            this.setState({
                status: "Veuillez sélectionner une année de référence",
                classeMessage: "alert alert-warning",
            });
            return;
        }

        if (graphiques && anneeRef && this.state.titre) {
            let parametresAnneesGraphiques = {
                graphiques: graphiques.value,
                annee: anneeRef,
                titre: this.state.titre,
                couleur: couleur,
                derniereAnneeProjection: derniereAnneeProjection,
                description: this.state.description,
            };

            if (!this.trajectoires["saisie-objectifs"]) {
                this.setState({
                    status: "Veuillez d'abord saisir votre objectif",
                });
                return;
            }
            let tableauObjectifs = this.formaterDonneesObjectifs(
                this.trajectoires["saisie-objectifs"]
            );
            if (tableauObjectifs.length === 0) {
                this.setState({
                    status: "Veuillez d'abord saisir les valeurs de votre objectif",
                });
                return;
            }

            let donneesFinales = {
                obj: tableauObjectifs,
                params: parametresAnneesGraphiques,
            };
            let url = buildRegionUrl(
                config.api_integration_objectifs,
                this.props.parentApi.data.region
            );
            if (tableauObjectifs) {
                Api.callApi(url, JSON.stringify(donneesFinales), "PUT") // Transmission of data to the API to update data
                    .then((response) => {
                        this.setState({
                            status: response.message,
                            currentSupraGoalId: response.supra_goal_id,
                            objectifExiste: true,
                            classeMessage: "alert alert-success",
                        });
                    })
                    .catch((e) => {
                        this.setState({
                            status: e.message,
                            classeMessage: "alert alert-warning",
                            objectifExiste: false,
                        });
                    });
            }
        }
    }

    /**
     * Form for entering the title of the objective
     * (the one that will be displayed in the legend of the graph where it will be reported).
     */
    formTitre() {
        let titre = (
            <div>
                <h3>Saisissez le nom de cet objectif</h3>
                <input
                    type="text"
                    defaultValue={this.state.titre}
                    onChange={() => this.mettreAJourTitre()}
                    ref="titre-objectif"
                ></input>
            </div>
        );
        if (this.props.mode === "mise_a_jour") {
            titre = (
                <div>
                    <h2>{"Objectif : " + this.state.titre}</h2>
                </div>
            );
        }
        return titre;
    }

    /**
     * Each time the title is modified, it is updated in the component's state (this.state.titre)
     */
    mettreAJourTitre() {
        let titre = ReactDOM.findDOMNode(this.refs["titre-objectif"]).value;
        this.setState({
            titre: titre,
            objectifExiste: false,
        });
    }

    /**
     * Update the data entered by the administrator in the database
    @param {objet clé => valeur} selectionGraphique :object of type {value: name of the table from which the target trajectories are calculated, label: name of the graph}
    @param {chaine de caractères} couleur : color of the trajectory.
    @param {Entier} anneeSelectionnee : reference year used to calculate the objectives.
    */
    miseAJourDonneesObjectifs(selectionGraphique, anneeSelectionnee, couleur) {
        let url = buildRegionUrl(
            config.api_maj_objectif,
            this.props.parentApi.data.region
        ).replace("#supra_goal_id#", this.state.currentSupraGoalId);
        if (selectionGraphique && anneeSelectionnee && this.state.currentSupraGoalId) {
            let trajectoire = this.trajectoires["saisie-objectifs"];
            let parametresAnneesGraphiques = {
                graphiques: selectionGraphique.value,
                annee: anneeSelectionnee,
                titre: this.state.titre,
                couleur: couleur,
                description: this.state.description,
                derniereAnneeProjection: this.state.derniereAnneeProjection,
            };
            let tableauObjectifs = this.formaterDonneesObjectifs(trajectoire);
            let donneesFinales = {
                obj: tableauObjectifs,
                params: parametresAnneesGraphiques,
            };
            if (tableauObjectifs) {
                Api.callApi(url, JSON.stringify(donneesFinales), "PUT") // Call API to update data
                    .then((response) => {
                        this.setState({
                            status: response.message,
                            classeMessage: "alert alert-success",
                        });
                    });
            }
        }
    }

    /**
     * Allow the display in value mode of an objective for a type of territory or specific territory
     * @param {chaine de caractères} titre : Title of the objective
     * @param {objet clé => valeur} selectionGraphique : object of type {value: name of the table from which the target trajectories are calculated, label: name of the graph}
     * @param {chaine de caractères} nomTerritoire : name of the territory concerned by the deactivation of the display of the target trajectory in value mode.
     */
    affectationObjectifs(titre, selectionGraphique, nomTerritoire) {
        let urlCodeNomTerritoire = "?zone=" + this.props.parentApi.data.zone.zone;
        if (!this.state.affectationToutTypeTerritoire) {
            urlCodeNomTerritoire +=
                "&zone_id=" +
                this.props.parentApi.data.currentZone +
                "&nom_territoire=" +
                nomTerritoire;
        }
        let url =
            buildRegionUrl(
                config.api_affectation_objectifs,
                this.props.parentApi.data.region
            ) + urlCodeNomTerritoire;
        if (selectionGraphique && this.state.currentSupraGoalId) {
            if (this.state.objectifExiste) {
                let parametresAnneesGraphiques = {
                    graphiques: selectionGraphique.value,
                    titre: this.state.titre,
                };
                Api.callApi(
                    url.replace("#supra_goal_id#", this.state.currentSupraGoalId),
                    JSON.stringify(parametresAnneesGraphiques),
                    "PUT"
                ) // Call API to update data
                    .then((response) => {
                        this.setState({
                            status: response.message,
                            classeMessage: "alert alert-success",
                        });
                    })
                    .catch((e) => {
                        this.setState({
                            status: e.message,
                            classeMessage: "alert alert-warning",
                        });
                    });
            } else {
                alert(
                    "L'objectif que vous venez de saisir n'est pas enregistré. Ajoutez-le d'abord puis réessayez."
                );
            }
        }
    }

    /**
     * This method modifies the state of the component depending on whether the authorization
     * to display the objective in value mode relates to a specific territory
     * or to all territories of the type of the selected one.
     * If the checkbox (Cf. ajouterBoutonAffectationObjectif) is unchecked,
     * this.state.affectationToutTypeTerritoire is false and we publish on a
     * specific territory. Otherwise, we publish on all territories of the same type.
     */
    modifierEtatAffectation() {
        this.setState({
            affectationToutTypeTerritoire: !this.state.affectationToutTypeTerritoire,
        });
    }

    /**
     * Add a button associated with the objectives display permission event in mode
     * value for a specific territory or type of territory
     * @param {chaine de caractères} typeTerritoire : the type of territory on which we publish
     * @param {chaine de caractères} graphique : title of the graph (ex : Consommation d''énergie)
     * @param {chaine de caractères} nomTerritoire : name of the territory concerned by the deactivation of the display of the target trajectory in value mode.
     */
    ajouterBoutonAffectationObjectif(titre, graphique, nomTerritoire) {
        let ajouterCaseAffectationToutTypeTerritoireOuTerritoireSpecifique = (
            <div key="publication">
                <input
                    type="checkbox"
                    id="publication-territoire"
                    checked={this.state.affectationToutTypeTerritoire}
                    onChange={() => this.modifierEtatAffectation()}
                ></input>
                <label className="titre-categorie" htmlFor="publication-territoire">
                    Autoriser l'affichage pour tous les territoires de ce type
                </label>
            </div>
        );
        let boutonAffectationObjectifs = (
            <button
                className="btn btn-info marge-haute-moyenne"
                onClick={() =>
                    this.affectationObjectifs(titre, graphique, nomTerritoire)
                }
            >
                Autoriser l'affichage de l'objectif pour le territoire sélectionné
            </button>
        );
        return (
            <div key={"publierTableauBord"}>
                {ajouterCaseAffectationToutTypeTerritoireOuTerritoireSpecifique}
                {boutonAffectationObjectifs}
            </div>
        );
    }

    /**
     * Allow to obtain the list of territories for which display is authorized in value mode of the current objective.
     * @param {chaine de caractères} titre : title of the objective
     * @param {Objet clé => valeur} selectionGraphique : graph characteristics : {"value" : "prod_enr", "label" : Production EnR}
     */
    obtenirListeAffectations(supraGoalId) {
        let url = buildRegionUrl(
            config.api_affectation_objectifs,
            this.props.parentApi.data.region
        );
        if (supraGoalId) {
            url = url.replace("#supra_goal_id#", supraGoalId);
        }
        Api.callApi(url, null, "GET").then((response) => {
            this.setState({
                listeAffectationsObjectifs: response.donnees,
            });
        });
    }

    /**
     * Disable the display of objectives for selected territories
     * @param {chaine de caractères} titre : Title of the objective
     * @param {objet clé => valeur} selectionGraphique : object of type {value: name of the table from which the target trajectories are calculated, label: name of the graph}
     * @param {chaine de caractères} typeTerritoire : type of territory concerned by the deactivation of the display of the target trajectory in value mode.
     * @param {chaine de caractères} nomTerritoire : name of territory concerned by the deactivation of the display of the target trajectory in value mode.
     * @param {chaine de caractères} codeTerritoire : code of territory concerned by the deactivation of the display of the target trajectory in value mode.
     */
    supprimerAffectationObjectifs(
        titre,
        selectionGraphique,
        typeTerritoire,
        codeTerritoire,
        nomTerritoire
    ) {
        let urlCodeNomTerritoire =
            "?zone=" +
            typeTerritoire +
            "&zone_id=" +
            codeTerritoire +
            "&nom_territoire=" +
            nomTerritoire;
        let url =
            buildRegionUrl(
                config.api_affectation_objectifs,
                this.props.parentApi.data.region
            ) + urlCodeNomTerritoire;
        if (
            selectionGraphique &&
            this.state.currentSupraGoalId &&
            this.state.titre &&
            this.state.anneeSelectionnee
        ) {
            let parametresAnneesGraphiques = {
                graphiques: selectionGraphique.value,
                titre: this.state.titre,
            };
            Api.callApi(
                url.replace("#supra_goal_id#", this.state.currentSupraGoalId),
                JSON.stringify(parametresAnneesGraphiques),
                "DELETE"
            ) // Call API to update data
                .then((response) => {
                    this.setState({
                        status: response.message,
                        classeMessage: "alert alert-success",
                    });
                });
        }
    }

    /**
     * Generate the form that lists the authorizations and from which you can delete them.
     * @param {chaine de caractères} titre : Title of the objective
     * @param {objet clé => valeur} selectionGraphique : object of type {value: name of the table from which the target trajectories are calculated, label: name of the graph}
     */
    formulaireAffectationObjectifs(titre, selectionGraphique) {
        let formulaireDePublication = [];
        let zone = {};
        let compteur = 1;
        let couleurDeFond = { background: "rgba(220, 220, 220)" };
        if (this.state.listeAffectationsObjectifs) {
            for (let affectation of this.state.listeAffectationsObjectifs) {
                for (let typeTerritoireMaille in this.props.parentApi.controller
                    .zonesManager.zoneLists) {
                    if (
                        typeTerritoireMaille.split("-")[0] ===
                        affectation.type_territoire
                    ) {
                        zone = {
                            zone: typeTerritoireMaille.split("-")[0],
                            maille: typeTerritoireMaille.split("-")[1],
                        };
                        break;
                    }
                }
                if (compteur % 2 !== 0) {
                    couleurDeFond = { background: "rgba(220, 220, 220)" };
                } else {
                    couleurDeFond = { background: "rgba(240, 240, 240)" };
                }
                let nomAffectation = "";

                if (affectation.type_territoire) {
                    let typeTerritoireReel = affectation.type_territoire;
                    if (!affectation.code_territoire) {
                        typeTerritoireReel = affectation.type_territoire.substring(
                            0,
                            affectation.type_territoire.length - 1
                        );
                    }
                    nomAffectation =
                        this.props.parentApi.controller.zonesManager.obtenirLibelleZone(
                            typeTerritoireReel
                        );
                }
                let nomTerritoire = "";
                let codeTerritoire = "";
                if (affectation.type_territoire && affectation.code_territoire) {
                    nomTerritoire =
                        this.props.parentApi.controller.zonesManager.getZoneName(
                            affectation.code_territoire,
                            zone
                        );
                    nomAffectation = nomTerritoire + " (" + nomAffectation + ")";
                    codeTerritoire = affectation.code_territoire;
                }

                let boutonDepublication = (
                    <button
                        className="btn btn-danger"
                        onClick={() =>
                            this.supprimerAffectationObjectifs(
                                titre,
                                selectionGraphique,
                                affectation.type_territoire,
                                codeTerritoire,
                                nomTerritoire
                            )
                        }
                    >
                        Désactiver l'affichage
                    </button>
                );
                let formulaire = (
                    <tr key={nomAffectation}>
                        <th style={couleurDeFond}>{nomAffectation}</th>
                        <th>{boutonDepublication}</th>
                    </tr>
                );
                formulaireDePublication.push(formulaire);
                compteur += 1;
            }
        }

        return (
            <div className="corps-tableau-bord baseline marge-haute-moyenne">
                <h4 style={{ marginBottom: "5px" }}>
                    Cet objectif sera disponible pour les territoires suivants :
                </h4>
                <table>
                    <tbody>{formulaireDePublication}</tbody>
                </table>
            </div>
        );
    }

    obtenirDonneesTrajInitiales(
        init,
        prevDerniereAnneeProjection,
        prevDerniereAnneeReference
    ) {
        // When initializing the target trajectories, as this one is in % mode, all the values must be zero.
        let valeursTrajectoire = { annees_valeurs: {}, annees_modifiees: {} };
        let listeValeurs = [];
        let listeAnnees = [];
        // Reconstitution of the dictionaries of the values by year and the one whose keys are the years whose value has been changed
        if (this.trajectoires) {
            if (this.trajectoires["saisie-objectifs"]) {
                if (
                    this.trajectoires["saisie-objectifs"].annees_modifiees &&
                    this.trajectoires["saisie-objectifs"].annees
                ) {
                    for (let a in this.trajectoires["saisie-objectifs"].annees) {
                        if (
                            parseInt(
                                this.trajectoires["saisie-objectifs"].annees[a],
                                10
                            ) <= this.state.derniereAnneeProjection
                        ) {
                            // If this year is available
                            this.trajectoires.annees_valeurs[
                                this.trajectoires["saisie-objectifs"].annees[a]
                            ] = this.trajectoires["saisie-objectifs"].valeurs[a];
                            this.trajectoires.annees_valeurs[
                                this.trajectoires["saisie-objectifs"].annees_modifiees[
                                    a
                                ]
                            ] =
                                this.trajectoires["saisie-objectifs"].annees_modifiees[
                                    a
                                ];
                        } else {
                            delete this.trajectoires.annees_valeurs[
                                this.trajectoires["saisie-objectifs"].annees[a]
                            ]; // Otherwise, we delete the keys that no longer serve us
                        }
                    }
                }
                valeursTrajectoire.annees_modifiees =
                    this.trajectoires.annees_modifiees;
            }
        }

        for (let a of this.state.anneesDisponiblesPourTrajectoire) {
            // Initialization to reference value
            if (a.value <= this.state.derniereAnneeProjection) {
                // For each year...
                if (init || a.value <= this.state.anneeSelectionneePrecedente) {
                    // If the previous reference year is greater than the year
                    valeursTrajectoire.annees_valeurs[a.value] = 0;
                    valeursTrajectoire.annees_modifiees[a.value] = false;
                } else {
                    let val = 0;
                    if (
                        this.trajectoires.annees_valeurs[a.value] ||
                        this.trajectoires.annees_valeurs[a.value] === 0
                    ) {
                        val = this.trajectoires.annees_valeurs[a.value];
                    } else if (!this.trajectoires.annees_valeurs[a.value]) {
                        if (
                            this.trajectoires.annees_valeurs[
                                prevDerniereAnneeProjection
                            ]
                        ) {
                            val =
                                this.trajectoires.annees_valeurs[
                                    prevDerniereAnneeProjection
                                ];
                        }
                    }

                    valeursTrajectoire.annees_valeurs[a.value] = val;
                    listeValeurs.push(val);
                    listeAnnees.push(a.value);
                }
            }
        }

        if (listeValeurs && listeAnnees && this.trajectoires) {
            valeursTrajectoire["saisie-objectifs"] = {};
            valeursTrajectoire["saisie-objectifs"]["valeurs"] = listeValeurs;
            valeursTrajectoire["saisie-objectifs"]["annees"] = listeAnnees;
            if (this.trajectoires["saisie-objectifs"]) {
                valeursTrajectoire["saisie-objectifs"]["annees_modifiees"] =
                    this.trajectoires["saisie-objectifs"].annees_modifiees;
            }
        }

        return valeursTrajectoire;
    }

    /**
     * This function resets the values of the target trajectory
     */
    resetCoeffs() {
        this.trajectoires = this.obtenirDonneesTrajInitiales(true);
        this.setState({
            trajectoires: this.trajectoires,
        });
    }

    render() {
        const saveTrajectoiresCibles = (trajectoires) => {
            // Saving target trajectory parameters (by type: conso, prod, emission)
            this.trajectoires[Object.keys(trajectoires)[0]] =
                trajectoires[Object.keys(trajectoires)[0]];
            this.setState({
                savedTrajectoiresCibles: true,
                anneeProjModifiee: false,
            });
        };

        let formAnneeSelectionnee = "";
        let formDerniereAnneeProjectionSelectionnee = "";
        if (this.state.anneesReferencePossibles.length !== 0) {
            formAnneeSelectionnee = this.choixAnneesReference();
            formDerniereAnneeProjectionSelectionnee =
                this.choixDerniereAnneeProjection();
        }

        let formChoixGraphique = "";
        if (this.props.mode === "mise_a_jour" && this.selectionGraphique) {
            if (this.selectionGraphique.value && this.selectionGraphique.label) {
                formChoixGraphique = this.choixTrajectoireCible();
            }
        } else if (this.props.mode !== "mise_a_jour") {
            formChoixGraphique = this.choixTrajectoireCible();
        }

        let actionObjectif = "";

        let donneesInitiales = this.trajectoires;

        let titreGraph = "";
        if (
            donneesInitiales["saisie-objectifs"] &&
            donneesInitiales["saisie-objectifs"].valeurs.length !== 0
        ) {
            donneesInitiales = this.trajectoires["saisie-objectifs"];
            if (this.state.anneeProjModifiee) {
                donneesInitiales = this.state.trajectoires;
            }
            if (typeof donneesInitiales.valeurs !== "undefined") {
                for (let i in donneesInitiales.valeurs) {
                    donneesInitiales.valeurs[i] = parseFloat(
                        donneesInitiales.valeurs[i]
                    ).toFixed(2);
                }
            }
        }

        let traj = "";
        let boutonPourReinitialiserTrajectoire = "";
        let choixCouleur = "";
        let boutonAffectationObjectifs = "";
        let outilDeSelectionTerritoire = "";
        let formulaireAffectationObjectifs = "";
        if (
            this.state.titre &&
            this.state.selectionGraphique &&
            this.state.listeAffectationsObjectifs.length !== 0 &&
            this.state.objectifExiste
        ) {
            formulaireAffectationObjectifs = this.formulaireAffectationObjectifs(
                this.state.titre,
                this.state.selectionGraphique
            );
        }
        let formulaireAffectationFinal = "";
        if (!this.state.selectionGraphique["statut"]) {
            titreGraph = this.state.selectionGraphique.label;

            // TODO: use a ZoneSelect instead to prevent changing the main zone
            outilDeSelectionTerritoire = (
                <MainZoneSelect
                    parentApi={this.props.parentApi}
                    className=""
                    containerClassName=""
                />
            );

            traj = (
                <PlanActionsTrajectoiresCibles
                    parentApi={this.props.parentApi}
                    id="saisie-objectifs"
                    title={titreGraph}
                    unite="%"
                    data={donneesInitiales}
                    saveTrajectoiresCibles={saveTrajectoiresCibles}
                    index={0}
                    provenance={"objectifs"}
                    couleur={this.state.couleur}
                    derniereAnneeProjection={this.state.derniereAnneeProjection}
                />
            );

            boutonPourReinitialiserTrajectoire = (
                <button
                    type="button"
                    className="btn btn-warning block-row"
                    onClick={() => this.resetCoeffs()}
                >
                    <span>Réinitialiser les valeurs</span>
                </button>
            );

            actionObjectif = (
                <button
                    className="btn btn-info"
                    onClick={() =>
                        this.ajouterObjectifs(
                            this.state.selectionGraphique,
                            this.anneeSelectionnee[0].value,
                            this.state.couleur,
                            this.derniereAnneeProjection[0].value
                        )
                    }
                >
                    Ajouter l'objectif
                </button>
            );

            let nomTerritoire =
                this.props.parentApi.controller.zonesManager.getZoneName(
                    this.props.parentApi.data.currentZone,
                    this.props.parentApi.data.zone
                );

            if (
                this.props.parentApi.data.currentZone ||
                this.props.parentApi.data.zone.zone === "region"
            ) {
                boutonAffectationObjectifs = this.ajouterBoutonAffectationObjectif(
                    this.state.titre,
                    this.state.selectionGraphique,
                    nomTerritoire
                );
            }

            if (this.props.mode === "mise_a_jour") {
                actionObjectif = (
                    <button
                        className="btn btn-info"
                        onClick={() =>
                            this.miseAJourDonneesObjectifs(
                                this.state.selectionGraphique,
                                this.anneeSelectionnee[0].value,
                                this.state.couleur,
                                this.derniereAnneeProjection[0].value
                            )
                        }
                    >
                        Mettre à jour l'objectif
                    </button>
                );
            }

            choixCouleur = this.choixCouleur();
            formulaireAffectationFinal = (
                <div className="corps-tableau-bord baseline marge-haute-moyenne">
                    <h3>
                        Gestion des autorisations d'affichage des objectifs dans les
                        trajectoires cibles
                    </h3>
                    <div className="corps-tableau-bord baseline marge-haute-moyenne">
                        {outilDeSelectionTerritoire}
                    </div>
                    <div className="corps-tableau-bord baseline marge-haute-moyenne">
                        {boutonAffectationObjectifs}
                        {formulaireAffectationObjectifs}
                    </div>
                </div>
            );
        }

        return (
            <div className="corps-tableau-bord">
                {this.formTitre()}
                {this.ajouterFormulaireDescription()}
                {formChoixGraphique}
                {formAnneeSelectionnee}
                {formDerniereAnneeProjectionSelectionnee}
                {traj}
                {boutonPourReinitialiserTrajectoire}
                {choixCouleur}
                {actionObjectif}
                {formulaireAffectationFinal}
                <div className={this.state.classeMessage}>{this.state.status}</div>
            </div>
        );
    }
}

export default AjoutObjectifs;
