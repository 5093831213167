/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import ReactTable from "react-table-6";

import Api from "../Controllers/Api";
import { Link } from "react-router-dom";
import Select from "react-select";

import config from "../settings.js";
import { buildRegionUrl } from "../utils.js";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-table-6/react-table.css";

/**
 * Ce composant permet de gérer les tableaux de bords existants
 */
class DashboardList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableauxBord: undefined, // Liste de tous les tableaux de bord et leur données
            status: undefined,
            sharePopupOn: false,
            currentDashboard: undefined,
            currentFullShare: undefined,
            currentReadonlyShare: undefined,
            shareTitle: undefined,
            isReadonlyShare: false,
            updateDashboardList: false,
            users: undefined,
        };
        this.region = this.props.parentApi.data.region;
    }

    componentDidMount() {
        this.setState({
            tableauxBord:
                this.props.parentApi.controller.dashboardManager.listMyDashboards,
            updateDashboardList: true,
        });

        // check if the user is an admin
        if (this.props.parentApi.data.profil === "admin") {
            // Call api to get users list.
            Api.callApi(
                buildRegionUrl(config.users_url, this.props.parentApi.data.region),
                undefined,
                "GET"
            ).then((response) => this.setState({ users: response })); // Store user list
        }
    }

    /**
    Lorsqu'un.e utilisateur.rice déclenche un événement et que la fonction render est lancée à nouveau
    on met à jour la liste des tableaux de bord qui doivent figurer dans l'onglet « tableaux de bord ».
    Cette opération est nécessaire en cas de suppression d'un tableau de bord pour que cette modification
    soit bien prise en compte lors du rechargement de la liste des tableaux de bord.
    @param {objet clé => valeur} prevProps : anciens paramètres du composant auquel on accède avec this.props
    @param {objet clé => valeur} prevState : ancien état du composant accessible avec this.state
    */
    componentDidUpdate(prevProps, prevState) {
        if (this.state.updateDashboardList !== prevState.updateDashboardList) {
            // Lorsque les listes des tableaux de bord est disponible,
            // On relance le chargement de la liste des tableaux de bord
            this.props.parentApi.controller.dashboardManager.retrieveMyDashboardsList(
                (myDashboards) => {
                    this.setState({ tableauxBord: myDashboards });
                }
            );
        }
    }

    /**
     * getTrProps sert à colorier les lignes du tableau selon des critères
     * @param  {json} state : état de la ligne courante
     * @param  {json} rowInfo : infos sur la ligne courante (colonnes, valeurs)
     * @param  {json} instance : instance du tableau
     * @return {json} la définition du style pour la ligne courante
     */
    getTrProps(state, rowInfo, instance) {
        if (rowInfo) {
            return {
                style: {
                    background: rowInfo.row.active ? "#f3f9e6" : "#fdf1da",
                },
            };
        }
        return {};
    }

    /**
    formate les données au format attendu par le composant TableauBord (nécessaire à
    l'initialisation au chargement de this.props.parentApi.data.tableauBordDonnees)
    * @param  {tableau clé => valeur} données: données propre à une thématique enregistrée en base de données
    Pour rappel, la structure de ce paramètre est :
    {
        id : identifiant
        titre : titre
        identifiant_tableau : identifiant du tableau de bord
        graphiques : [
                {
                    identifiant_analyse : identifiant de l'analyse
                    representation: représentation
                    categories {
                        première catégories {
                            nom : nom de la catégorie (ex : secteurs, energies, usages, type_prod_enr etc.)
                            titre : titre de la catégorie (ex : Par secteurs, Par filières de production etc.)
                            visible : true si on doit l'afficher false sinon
                        }
                        .
                        .
                        .
                        deuxième catégories{
                            .
                            .
                            .
                        }
                    }
                },
                {
                .
                .
                .
                }
        ]
    }
    */
    formaterDonneesThematiques(donnees) {
        let donneesformatees = {};
        for (let a in donnees) {
            let graphiques = {};
            for (let g in donnees[a].graphiques) {
                graphiques[donnees[a].graphiques[g].numero_analyse] =
                    donnees[a].graphiques[g];
            }
            donneesformatees[a] = {
                indicateurs: graphiques,
                description_thematique: donnees[a].description,
                titre_thematique: donnees[a].titre,
                ordre: donnees[a].ordre,
            };
        }
        return donneesformatees;
    }

    /**
    Déclenchée sur événement.
    Réinitialise la variable this.props.parentApi.data.tableauBordDonnees
    selon les données du tableau de bord chargé par un.e utilisateur.rice
    * @param  {entier} id_tableau_bord: identifiant du tableau de bord sélectionné
    */
    chargerTableauBord(id_tableau_bord) {
        let tableauBordCourant = undefined;
        for (let t of this.state.tableauxBord) {
            if (t.id === id_tableau_bord) {
                tableauBordCourant = t;
            }
        }

        tableauBordCourant["donnees"] = this.formaterDonneesThematiques(
            tableauBordCourant.thematiques
        );
        tableauBordCourant["metadonnees"] = {
            titre: tableauBordCourant.titre,
            description: tableauBordCourant.description,
        };
        delete tableauBordCourant["thematiques"];
        this.props.parentApi.callbacks.chargerTableauBord(tableauBordCourant);
        this.props.parentApi.callbacks.updateAnalysis(
            "creation_tableaux_bord",
            { fromMenu: true },
            tableauBordCourant
        );
    }

    /**
    Déclenchée sur événement
    Supprime un tableau de bord
    @param  {entier} id_tableau_bord : identifiant en base du tableau de bord que l'on veut supprimer
    */
    supprimerTableauBord(id_tableau_bord) {
        let r = window.confirm("Attention, cette action est irréversible");
        if (r !== true) {
            return;
        }
        let region = this.props.parentApi.controller.analysisManager.region;
        let url =
            buildRegionUrl(config.tableau_bord_url, region) + "/" + id_tableau_bord;
        Api.callApi(url, null, "DELETE")
            .then((response) => {
                this.props.parentApi.callbacks.updateMessages(
                    "Tableau de bord supprimé"
                );
                this.setState({
                    updateDashboardList: !this.state.updateDashboardList,
                });
            })
            .catch((e) => {
                this.props.parentApi.callbacks.updateMessages(e.message);
            });
    }

    /**
    Déclenchée sur événement
    Duplicate a dashboard
    @param  {object} dashboard
    */
    duplicateDashboard(dashboard) {
        let newTitle = window.prompt(
            "Quel nom souhaitez-vous donner au tableau ?",
            dashboard.titre
        );
        if (!newTitle) {
            return;
        }
        let region = this.props.parentApi.controller.analysisManager.region;
        let url =
            buildRegionUrl(config.tableau_bord_dupliquer_url, region) +
            "/" +
            dashboard.id;
        Api.callApi(url, JSON.stringify({ title: newTitle }), "POST")
            .then((response) => {
                this.props.parentApi.callbacks.updateMessages(
                    "Tableau de bord dupliqué !"
                );
                this.setState({
                    erreur: undefined,
                    updateDashboardList: !this.state.updateDashboardList,
                });
            })
            .catch((e) => {
                this.props.parentApi.callbacks.updateMessages(e.message);
            });
    }

    removeDashboardFromProfile(dashboard) {
        const region = this.props.parentApi.controller.analysisManager.region;
        const url =
            buildRegionUrl(config.tableau_bord_share_url, region).replace(
                "#dashboard_id#",
                dashboard.id
            ) +
            "/" +
            (dashboard.is_readonly ? "?readonly=true" : "");
        Api.callApi(url, null, "DELETE")
            .then((response) => {
                this.props.parentApi.callbacks.updateMessages(response.message);

                this.setState({
                    updateDashboardList: !this.state.updateDashboardList,
                });
            })
            .catch((e) => {
                this.props.parentApi.callbacks.updateMessages(e.message);
            });
    }

    /** Open a popup to type the email addresses */
    openSharePopup(row, shareTitle, isReadonlyShare = false) {
        this.setState({
            sharePopupOn: true,
            currentDashboard: row.id,
            shareTitle,
            isReadonlyShare,
            currentFullShare: row.full_share,
            currentReadonlyShare: row.readonly_share,
        });
    }

    /** Called from a form onSubmit event */
    handleShare(event, is_readonly_share = false) {
        event.preventDefault();
        const form = event.target.elements;
        let emails = form["emails_partage"].value;
        if (form["emails_partage"].length !== undefined) {
            emails = [...form["emails_partage"]].map((input) => input.value).join("\n");
        }

        // Appel API pour enregistrer le partage
        const body = JSON.stringify({ emails, is_readonly_share });
        let url = buildRegionUrl(
            config.tableau_bord_share_url,
            this.props.parentApi.data.region
        ).replace("#dashboard_id#", this.state.currentDashboard);
        Api.callApi(url, body, "POST").then(() => {
            this.setState({
                sharePopupOn: false,
                updateDashboardList: !this.state.updateDashboardList,
            });
        });
    }

    renderSharePopup() {
        if (!this.state.sharePopupOn) {
            return "";
        }
        let label = "";
        let email_input = undefined;

        const defaultValue = this.state.isReadonlyShare
            ? this.state.currentReadonlyShare
            : this.state.currentFullShare;

        if (this.state.users && this.state.users.length > 0) {
            label = `Sélectionnez les emails des personnes avec qui vous voulez
                partager`;
            const options = this.state.users.map((user) => ({
                value: user.mail,
                label: user.mail,
            }));
            email_input = (
                <Select
                    isMulti
                    name="emails_partage"
                    id="emails_partage"
                    defaultValue={
                        defaultValue
                            ? defaultValue.map((mail) => ({
                                  value: mail,
                                  label: mail,
                              }))
                            : []
                    }
                    options={options}
                    className="basic-multi-select"
                    classNamePrefix="select"
                />
            );
        } else {
            label = `Saisissez les emails des personnes avec qui vous voulez
            partager (un email par ligne)`;
            email_input = (
                <textarea
                    name="emails_partage"
                    id="emails_partage"
                    rows="5"
                    cols="50"
                    defaultValue={defaultValue ? defaultValue.join("\n") : ""}
                ></textarea>
            );
        }
        return (
            <div className="popup partage-strategie">
                <h2>{this.state.shareTitle}</h2>
                <form
                    className="panel-body user-partage-form"
                    onSubmit={(e) => this.handleShare(e, this.state.isReadonlyShare)}
                >
                    <div className="form-group">
                        <label htmlFor="emails_partage">{label}</label>
                    </div>
                    <div className="form-group">{email_input}</div>
                    <div className="form-group">
                        <input
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => {
                                this.setState({
                                    sharePopupOn: false,
                                    currentDashboard: undefined,
                                });
                            }}
                            value="Annuler"
                        />{" "}
                        <input
                            type="submit"
                            className="btn btn-primary"
                            value="Valider"
                        />
                    </div>
                </form>
            </div>
        );
    }

    render() {
        const goCreation = () => {
            this.props.parentApi.callbacks.updateAnalysis(
                "creation_tableaux_bord",
                undefined,
                "carto"
            );
            this.props.parentApi.callbacks.displayChart(false);
        };

        // on kick les personnes non connectées
        if (!this.props.connected) {
            return (
                <div className="plan-actions widgets full-screen-widget">
                    <Link
                        className="back-to-map"
                        to={"/" + this.props.parentApi.data.urlPartageable}
                    >
                        <button
                            type="button"
                            className="close close-big"
                            data-dismiss="alert"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </Link>{" "}
                    {/* Retour à la carte */}
                    <div className="pdf-pages">
                        <div className="creation-tableaux-bord">
                            <div className="title centered-row">
                                <p className="action-title">Action impossible</p>
                            </div>
                            <p>
                                Vous devez vous connecter pour pouvoir effectuer cette
                                action.
                            </p>
                        </div>
                    </div>
                </div>
            );
        }

        const isAdmin = this.props.parentApi.data.profil === "admin";

        let gestionTableauxBord = "";
        const columns = [
            // Colonne du tableau
            {
                Header: "Titre",
                accessor: "titre",
            },
            {
                Header: "Description",
                accessor: "description",
            },
            {
                Header: "Partagé avec",
                accessor: "full_share",
                getProps: (state, rowInfo) => {
                    if (
                        rowInfo &&
                        rowInfo.row &&
                        ((rowInfo.row.full_share &&
                            rowInfo.row.full_share.length > 0) ||
                            (rowInfo.original.readonly_share &&
                                rowInfo.original.readonly_share.length > 0))
                    )
                        return { style: { backgroundColor: "#d1f4f9" } };
                    return {};
                },
                Cell: (props) => {
                    if (!props.row.full_share && !props.original.readonly_share)
                        return "";
                    const items =
                        props.row.full_share &&
                        props.row.full_share.map((partage, i) => (
                            <li className="list-simple" key={i}>
                                {partage}
                            </li>
                        ));
                    const readonlyItems =
                        props.original.readonly_share &&
                        props.original.readonly_share.map((partage, i) => (
                            <li className="list-simple readonly-mail" key={i}>
                                {partage} (<em>lecture seule</em>)
                            </li>
                        ));
                    return (
                        <div className="multi-line">
                            {items}
                            {readonlyItems}
                        </div>
                    );
                },
            },
            {
                Header: "Partagé par",
                accessor: "shared_by",
                getProps: (state, rowInfo) => {
                    if (rowInfo && rowInfo.row && rowInfo.row.shared_by)
                        return { style: { backgroundColor: "#d1f9de" } };
                    return {};
                },
                Cell: (props) => {
                    return (
                        <p>
                            {props.original.shared_by}
                            {props.original.is_readonly && (
                                <>
                                    <br />
                                    <em>(en lecture seule)</em>
                                </>
                            )}
                        </p>
                    );
                },
            },
            {
                Header: "Actions de partage",
                accessor: "id",
                Cell: (props) =>
                    (!props.row.shared_by || isAdmin) && (
                        <div className="btn-group">
                            <button
                                className={"btn btn-primary "}
                                onClick={() =>
                                    this.openSharePopup(
                                        props.original,
                                        "Partage en écriture",
                                        false
                                    )
                                }
                            >
                                en écriture
                            </button>
                            <button
                                className={"btn btn-info "}
                                onClick={() =>
                                    this.openSharePopup(
                                        props.original,
                                        "Partage en lecture seule",
                                        true
                                    )
                                }
                            >
                                en lecture seule
                            </button>
                        </div>
                    ),
            },
            {
                Header: "Autres actions",
                accessor: "id",
                Cell: (props) => (
                    // Cet argument props correspond aux propriétées du tableau et donc props.row permet d'accéder
                    // aux attributs des lignes. Ici on récupère l'identifiant de chaque tableaux de bord (un tableau par ligne).
                    <div className="actions">
                        {!props.original.is_readonly ? (
                            <button
                                className="btn btn-success"
                                onClick={() => this.chargerTableauBord(props.row.id)}
                            >
                                Charger
                            </button>
                        ) : (
                            <button
                                className="btn btn-success"
                                onClick={() => this.duplicateDashboard(props.row)}
                            >
                                Dupliquer
                            </button>
                        )}
                        {(!props.row.shared_by || isAdmin) && (
                            <button
                                className={"btn btn-danger"}
                                onClick={() => this.supprimerTableauBord(props.row.id)}
                            >
                                Supprimer
                            </button>
                        )}
                        {props.row.shared_by && !isAdmin && (
                            <button
                                className={"btn btn-danger "}
                                onClick={() =>
                                    this.removeDashboardFromProfile(props.original)
                                }
                            >
                                Retirer du profil
                            </button>
                        )}
                    </div>
                ),
            },
        ];
        gestionTableauxBord = (
            <div className="panel-body user-scenarii">
                <h3 className="panel-title pull-left">Gestion des tableaux de bord</h3>
                <p>
                    <button className="btn btn-primary" onClick={() => goCreation()}>
                        Créer un tableau de bord
                    </button>
                </p>
                <ReactTable
                    data={this.state.tableauxBord}
                    columns={columns}
                    className="-striped"
                    getTrProps={this.getTrProps}
                    defaultPageSize={30}
                />
            </div>
        );

        // On retourne le tableau en question
        return (
            <>
                <Link
                    className="back-to-map"
                    to={"/" + this.props.parentApi.data.urlPartageable}
                >
                    <button
                        type="button"
                        className="close close-big"
                        data-dismiss="alert"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Link>{" "}
                <div className="centered-row">
                    <div className="gestion-tableau-bord">
                        {/* Retour à la carte */}
                        {gestionTableauxBord}
                        {this.renderSharePopup()}
                    </div>
                </div>
            </>
        );
    }
}

export default DashboardList;
