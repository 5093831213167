/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";

import "bootstrap/dist/css/bootstrap.min.css";

/**
 * This component is used to display an alert message at the top of the application
 */
class Messages extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: "",
        };
    }

    componentDidUpdate(prevProps, prevState) {
        let messages = this.props.parentApi.data.messages;

        if (prevProps.parentApi.data.messages !== messages && messages !== "") {
            setTimeout(() => {
                this.props.parentApi.callbacks.updateMessages("");
            }, 5000);
        }
    }

    render() {
        let msg = this.props.parentApi.data.messages;
        let classNameMessage = "alert-" + this.props.parentApi.data.typeMessages;
        if (!msg || msg === "") return null;
        else
            return (
                <div className={"alert messages-msg " + classNameMessage}>
                    {this.props.parentApi.data.messages}
                </div>
            );
    }
}

export default Messages;
