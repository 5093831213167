/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Api from "../../Controllers/Api";
import config from "../../settings";
import { buildRegionUrl } from "../../utils";
import { NewDetailsPopup as DetailsPopup } from "../DetailsPopup";
import { MainZoneSelect } from "../SelectionObjet";

import "../../style/strategies.css";

export default function Strategies({ parentApi }) {
    const [errorMessage, setErrorMessage] = useState(undefined);
    const [myStrategies, setMyStrategies] = useState([]);
    const [publicStrategies, setPublicStrategies] = useState([]);
    const [showZoneSelect, setShowZoneSelect] = useState(false);
    const [showNameInput, setShowNameInput] = useState(false);
    const [shouldUpdate, forceUpdate] = useState({});
    const [currentSharingStrategy, setSharingStrategy] = useState(undefined);
    const [isLoadingStrategies, setIsLoadingStrategies] = useState(false);

    useEffect(() => {
        if (!parentApi.data.connected) return;
        setErrorMessage(undefined);
        setIsLoadingStrategies(true);
        let url = buildRegionUrl(config.user_scenario_list_url, parentApi.data.region);
        if (parentApi.data?.settings?.["is_national"]) {
            url = buildRegionUrl(
                config.user_scenario_list_national_url.replace(
                    "#territory#",
                    parentApi.data.region
                ),
                "national"
            );
        }
        const promise = Api.callApi(url);
        promise
            .then((strategies) => {
                setIsLoadingStrategies(false);
                setMyStrategies(
                    strategies.filter(
                        (strategy) => !(strategy.publique && strategy.partage_par)
                    )
                );
                setPublicStrategies(
                    strategies.filter(
                        (strategy) => strategy.publique && strategy.partage_par
                    )
                );
            })
            .catch((e) => {
                if (e.name === "AbortError") return;
                console.error(e);
                setIsLoadingStrategies(false);
                setErrorMessage("Impossible de récupérer la liste des stratégies.");
            });
        return () => promise.abort();
    }, [
        parentApi.data.connected,
        parentApi.data.region,
        parentApi.data.settings,
        shouldUpdate,
    ]);

    function createStrategy(name) {
        const url = buildRegionUrl(config.user_scenario_url, parentApi.data.region);
        const strategyData = {
            titre: name,
            zone_type: parentApi.data.zone.zone,
            zone_id: parentApi.data.currentZone,
            nom_territoire: parentApi.controller.zonesManager.getZoneName(
                parentApi.data.currentZone,
                parentApi.data.zone
            ),
            trajectoires: {
                emission_ges: { annees_valeurs: {}, annees_modifiees: {} },
                energie_economisee: { annees_valeurs: {}, annees_modifiees: {} },
                energie_produite: { annees_valeurs: {}, annees_modifiees: {} },
            },
            actions: [],
            advanced: [],
        };
        Api.callApi(url, JSON.stringify(strategyData), "POST").then(
            ({ scenario_id }) => {
                parentApi.callbacks.updateProvenance("strategy_creation");
                if (parentApi.data?.settings?.["is_national"]) {
                    parentApi.callbacks.goToURL(
                        `/national/${parentApi.data.region}/edition_strategie/${scenario_id}`
                    );
                } else {
                    parentApi.callbacks.goToURL(`edition_strategie/${scenario_id}`);
                }
            }
        );
    }
    function deleteStrategy(strategy) {
        if (!window.confirm("Attention, cette action est irréversible")) {
            return;
        }
        let url =
            buildRegionUrl(config.user_scenario_url, parentApi.data.region) +
            "/" +
            strategy.id;
        Api.callApi(url, "{}", "DELETE")
            .then(() => forceUpdate({})) // reload strategy list
            .catch((e) => setErrorMessage(e.message));
    }
    function shareStrategy(emails) {
        const body = JSON.stringify({ emails });
        let url = buildRegionUrl(
            config.user_scenario_partage_url,
            parentApi.data.region
        ).replace("#id#", currentSharingStrategy.id);
        Api.callApi(url, body, "POST")
            .then(() => {
                forceUpdate({}); // reload strategy list
                setSharingStrategy(undefined);
            })
            .catch((e) => setErrorMessage(e.message));
    }
    function initNewStrategy() {
        // test if only one "regional" territory
        const nbLevels = Object.keys(
            parentApi.controller.zonesManager.zonesInNewFormat
        ).length;
        if (
            nbLevels === 1 &&
            "region" in parentApi.controller.zonesManager.zonesInNewFormat
        ) {
            setShowNameInput(true);
        } else {
            setShowZoneSelect(true);
        }
    }

    let returnButton = (
        <Link className="back-to-map" to={"/" + parentApi.data.urlPartageable}>
            <span className="big">&times;</span> <span>Retour à la carte</span>
        </Link>
    );
    if (parentApi.data.settings?.is_national) {
        returnButton = (
            <div>
                <Link
                    className="btn btn-light return-button"
                    to={"/national/" + parentApi.data.region + "/portail"}
                >
                    <i className="bi bi-arrow-left"></i>
                </Link>
            </div>
        );
    }
    return (
        <div className="widgets full-screen-widget">
            {returnButton}
            <div className="ui-main">
                <h1 className="tstitle left">
                    Bienvenue dans le module Stratégies Territoriales
                </h1>
                <p>
                    Ce module vous aidera à piloter votre trajectoire de transition.
                    Vous pourrez élaborer, suivre et évaluer l'impact de vos actions
                    relativement aux objectifs cibles que vous vous êtes fixés.
                </p>
                <button
                    className="tsbtn info big new-strat-button"
                    onClick={() => initNewStrategy()}
                >
                    Nouvelle stratégie
                </button>
                <h3 className="tstitle2">Mes stratégies territoriales</h3>
                <div className="tscards-container">
                    {!parentApi.data.connected ? (
                        "Connectez-vous pour accéder à vos stratégies et les sauvegarder"
                    ) : isLoadingStrategies ? (
                        <span className="loader" />
                    ) : !myStrategies.length ? (
                        "Aucune stratégie"
                    ) : (
                        myStrategies.map((strategy) => (
                            <StrategyCard
                                strategy={strategy}
                                nationalRegion={
                                    parentApi.data?.settings?.["is_national"]
                                        ? parentApi.data.region
                                        : undefined
                                }
                                key={strategy.id}
                                onDelete={() => deleteStrategy(strategy)}
                                onDuplicate={() => console.log("duplicate")}
                                onShare={() => setSharingStrategy(strategy)}
                            />
                        ))
                    )}
                </div>
                {publicStrategies.length > 0 && (
                    <>
                        <h3 className="tstitle2">Stratégies territoriales publiques</h3>
                        <div className="tscards-container">
                            {publicStrategies.map((strategy) => (
                                <StrategyCard
                                    strategy={strategy}
                                    nationalRegion={
                                        parentApi.data?.settings?.["is_national"]
                                            ? parentApi.data.region
                                            : undefined
                                    }
                                    key={strategy.id}
                                    onDuplicate={() => console.log("duplicate")}
                                />
                            ))}
                        </div>
                    </>
                )}
                {errorMessage && (
                    <div className="alert alert-danger" role="alert">
                        {errorMessage}
                    </div>
                )}
                <SelectZonePopup
                    parentApi={parentApi}
                    title="Nouvelle stratégie"
                    isOpen={showZoneSelect}
                    onClose={() => setShowZoneSelect(false)}
                    onValidate={() => {
                        if (parentApi.data.connected) {
                            setShowNameInput(true);
                        } else {
                            if (parentApi.data?.settings?.["is_national"]) {
                                parentApi.callbacks.goToURL(
                                    `/national/${parentApi.data.region}/edition_strategie?zone=${parentApi.data.zone.zone}&zone_id=${parentApi.data.currentZone}`
                                );
                            } else {
                                parentApi.callbacks.goToURL(
                                    `edition_strategie?zone=${parentApi.data.zone.zone}&zone_id=${parentApi.data.currentZone}`
                                );
                            }
                        }
                    }}
                />
                <StrategyNamePopup
                    title="Nouvelle stratégie"
                    isOpen={showNameInput}
                    onClose={() => setShowNameInput(false)}
                    onValidate={createStrategy}
                />
                <ShareStrategyPopup
                    title="Partager une stratégie"
                    isOpen={currentSharingStrategy !== undefined}
                    onClose={() => setSharingStrategy(undefined)}
                    onValidate={shareStrategy}
                    strategy={currentSharingStrategy}
                />
            </div>
        </div>
    );
}

function StrategyCard({
    strategy,
    nationalRegion,
    onShare,
    onPublish,
    onDuplicate,
    onDelete,
}) {
    let actions = [];
    const prefix = nationalRegion ? `/national/${nationalRegion}` : "";

    // The first action in the list is the main action
    if (strategy.partage_par) {
        actions.push({
            label: "Afficher",
            link: `${prefix}/edition_strategie/${strategy.id}`,
        });
        //actions.push({ label: "Dupliquer", callback: onDuplicate });
    } else {
        actions.push({
            label: "Modifier",
            link: `${prefix}/edition_strategie/${strategy.id}`,
        });
        actions.push({ label: "Partager", callback: onShare });
        if (onPublish) {
            actions.push({ label: "Publier", callback: onPublish });
        }
        //actions.push({ label: "Dupliquer", callback: onDuplicate });
        actions.push({ label: "Supprimer", callback: onDelete });
    }
    actions = actions.map(({ label, link, callback }, index) => {
        const className = index === 0 ? "tsbtn info full-width" : null;
        if (link) {
            return (
                <Link key={index} to={link} className={className}>
                    {label}
                </Link>
            );
        }
        if (callback) {
            return (
                <button
                    key={index}
                    onClick={() => callback(strategy)}
                    className={className}
                >
                    {label}
                </button>
            );
        }
        return (
            <button key={index} disabled className={className}>
                {label}
            </button>
        );
    });

    return (
        <div className="tscard strategy-card">
            <div className="first-line">
                <h4>{strategy.titre}</h4>
                {actions.length > 1 && (
                    <button className="bi bi-three-dots-vertical dropdown-button">
                        <div>{actions.slice(1)}</div>
                    </button>
                )}
            </div>
            {strategy.description && (
                <em>
                    {strategy.description}
                    <br />
                </em>
            )}
            <span>
                <label>Territoire :</label> {strategy.nom_territoire}
            </span>
            <br />
            <span>
                <label>Modifié le</label> {strategy.derniere_modif} <label>par</label>{" "}
                {strategy.partage_par ?? <label>vous</label>}
            </span>
            {strategy.partage?.length ? (
                <>
                    <br />
                    <span>
                        <label>Partagé avec :</label> {strategy.partage.join(", ")}
                    </span>
                </>
            ) : null}
            {actions[0]}
        </div>
    );
}

function SelectZonePopup({ parentApi, title, isOpen, onClose, onValidate }) {
    return (
        <DetailsPopup {...{ title, isOpen, onClose }}>
            <form
                onSubmit={(event) => {
                    event.preventDefault();
                    onValidate();
                    onClose();
                }}
                className="full-screen-form"
            >
                <div />
                <div>
                    <h2>Territoire</h2>
                    <MainZoneSelect
                        parentApi={parentApi}
                        containerClassName=""
                        className="strategy-zone-select"
                        title=""
                        disableMaille
                    />
                </div>
                <input
                    type="submit"
                    className="tsbtn info big full-width"
                    value="Valider"
                    disabled={
                        parentApi.data.zone?.zone == null ||
                        parentApi.data.currentZone == null
                    }
                />
            </form>
        </DetailsPopup>
    );
}

function StrategyNamePopup({ title, isOpen, onClose, onValidate }) {
    return (
        <DetailsPopup {...{ title, isOpen, onClose }}>
            <form
                onSubmit={(event) => {
                    event.preventDefault();
                    onValidate(event.target.elements["name"].value || "Sans titre");
                    onClose();
                }}
                className="full-screen-form"
            >
                <div />
                <div>
                    <h2>Nom de la stratégie</h2>
                    <input
                        type="text"
                        name="name"
                        placeholder="Sans titre"
                        className="full-width"
                    />
                </div>
                <input
                    type="submit"
                    className="tsbtn info big full-width"
                    value="Créer"
                />
            </form>
        </DetailsPopup>
    );
}

function ShareStrategyPopup({ title, isOpen, onClose, onValidate, strategy }) {
    return (
        <DetailsPopup {...{ title, isOpen, onClose }}>
            <form
                onSubmit={(event) => {
                    event.preventDefault();
                    onValidate(event.target.elements["emails"].value);
                    onClose();
                }}
                className="full-screen-form"
            >
                <div />
                <div>
                    <label htmlFor="emails">
                        Saisissez les emails des personnes avec qui vous voulez partager
                        (un email par ligne)
                    </label>
                    <textarea
                        id="emails"
                        name="emails"
                        className="full-width"
                        defaultValue={
                            strategy?.partage ? strategy.partage.join("\n") : ""
                        }
                    />
                </div>
                <input
                    type="submit"
                    className="tsbtn info big full-width"
                    value="Partager"
                />
            </form>
        </DetailsPopup>
    );
}
