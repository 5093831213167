/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

/**
 * Component to preview the first five rows in a table.
 */
const TablePreview = ({ data, additionalElements }) => {
    /**
     * Formats the value based on its attribute.
     * @param {string} value - The value to format.
     * @param {string} attribute - The type of attribute.
     * @returns {JSX.Element|string} - The formatted value.
     */
    const simplifyValuesFormat = (value, attribute) => {
        if (attribute === "valeur" && value !== "valeur") {
            return <span title={value}>{value}</span>;
        }
        return value;
    };

    /**
     * Extracts header and values from the data.
     */
    const extractHeaderAndValues = () => {
        const header = [];
        const headerAndValues = [];
        let i = 0;

        if (data && data.length > 0) {
            for (const elem of data) {
                const values = [];
                for (const object in elem) {
                    if (i === 0) {
                        header.push(object);
                    }
                    values.push(elem[object]);
                }
                if (i === 0) {
                    headerAndValues.push(header);
                }
                headerAndValues.push(values);
                i += 1;
            }
        }
        return headerAndValues;
    };

    const headerAndValues = extractHeaderAndValues();

    return (
        <div>
            <table className="table_preview_header">
                <tbody>
                    {headerAndValues.map((line, j) => (
                        <tr key={j} className="table_preview_header_line">
                            {line.map((col, i) => (
                                <td key={i}>
                                    {simplifyValuesFormat(col, headerAndValues[0][i])}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
            {additionalElements ?? ""}
        </div>
    );
};

export default TablePreview;
