/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import ReactTable from "react-table-6";

import Api from "../../Controllers/Api";
import { buildRegionUrl, filterCaseInsensitive, exportToCSV } from "../../utils.js";

import config from "../../settings.js";

import "bootstrap/dist/css/bootstrap.min.css";

/**
 * This component displays a table of the scenarios created by users of the region
 */
class RegionScenarioTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            scenarios: undefined,
            status: "Chargement des stratégies territoriales...",
        };
    }

    componentDidMount() {
        this.getScenarios();
    }

    /**
     * Retrieve from the API the scenarios of the region
     */
    getScenarios() {
        Api.callApi(
            buildRegionUrl(
                config.region_scenarios_url,
                this.props.parentApi.data.region
            ),
            undefined,
            "GET"
        )
            .then((response) => {
                response = response.map((r) => ({
                    ...r,
                    derniere_modif: new Date(r.derniere_modif),
                }));
                this.setState({
                    scenarios: response,
                });
            })
            .catch((e) => this.setState({ status: e.message }));
    }

    /**
     * Export table data to CSV file using exportToCSV function (utils).
     */
    exportScenarios() {
        if (this.state.scenarios) {
            let fileName = `strategies_territoriales_${this.props.parentApi.data.region}`;
            exportToCSV(this.state.scenarios, "csv", fileName);
        }
    }

    render() {
        if (
            !this.props.connected ||
            !this.props.userInfos ||
            this.props.userInfos.profil !== "admin"
        ) {
            return <div>Non accessible.</div>;
        }

        const columns = [
            {
                Header: "Titre",
                accessor: "titre",
            },
            {
                Header: "Description",
                accessor: "description",
            },
            {
                Header: "Territoire",
                accessor: "nom_territoire",
            },
            {
                Header: "Type de territoire",
                accessor: "zone_type",
            },
            {
                Header: "Créée par",
                accessor: "mail",
            },
            {
                Header: "Dernière modification",
                accessor: "derniere_modif",
                Cell: (props) => {
                    return props.value.toLocaleString();
                },
            },
        ];

        return (
            <div className="panel-body">
                <h3 className="panel-title pull-left">
                    Stratégies territoriales des utilisateurs de la région
                    {this.props.userInfos.territoire}
                </h3>
                <div className="d-flex justify-content-end px-2 pb-2">
                    <button
                        type="button"
                        id="exportButton"
                        className="btn btn-primary align-self-end"
                        title="Exporter les stratégies territoriales"
                        onClick={() => this.exportScenarios()}
                        disabled={!this.state.scenarios}
                    >
                        <i className="bi bi-download"></i> Exporter
                    </button>
                </div>

                <ReactTable
                    data={this.state.scenarios}
                    columns={columns}
                    filterable={true}
                    defaultFilterMethod={(filter, row) =>
                        filterCaseInsensitive(filter, row)
                    }
                    className="-striped"
                    defaultPageSize={20}
                    noDataText={this.state.status}
                />
            </div>
        );
    }
}

export default RegionScenarioTable;
