/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import ImageUploading from "react-images-uploading";
import { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

/**
 * This function collects and displays carousel images
 */
function GestionnaireImagesCarousel({ number, allImages, setAllImages }) {
    const [images, setImages] = useState([]);
    // number of images the user can select if the mode is multiple
    const maxNumber = 5;

    const onChange = (imageList, addUpdateIndex) => {
        setImages(imageList);
        // updating the images in the list containing the images of all "GestionnaireImagesCarousel"
        allImages[number] = imageList;
        setAllImages(allImages);
    };

    return (
        <div className="AppImage">
            <h4 className="title-carroussel">Carrousel {number + 1}</h4>
            <ImageUploading
                multiple
                value={images}
                onChange={onChange}
                maxNumber={maxNumber}
                dataURLKey="data_url"
            >
                {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                }) => (
                    // write your building UI
                    <div className="upload__image-wrapper">
                        <button
                            className="button-ajout-supp-img"
                            title="Glisser-déposer votre image"
                            style={isDragging ? { color: "red" } : undefined}
                            onClick={onImageUpload}
                            {...dragProps}
                        >
                            <span style={{ paddingRight: "10px" }}>
                                Ajouter une image
                            </span>
                            <i
                                className="bi bi-plus-circle"
                                style={{ fontSize: "16px" }}
                            ></i>
                        </button>
                        &nbsp;
                        <button
                            onClick={onImageRemoveAll}
                            className="button-ajout-supp-img"
                        >
                            <span style={{ paddingRight: "10px" }}>
                                Supprimer les images
                            </span>
                            <i
                                className="bi bi-dash-circle"
                                style={{ fontSize: "16px" }}
                            ></i>
                        </button>
                        <div id="img-for-carousel">
                            {imageList.map((image, index) => (
                                <div key={index} className="image-item">
                                    <img
                                        className="size-img-editeur-pop-up"
                                        src={image["data_url"]}
                                        alt=""
                                    />
                                    <div className="image-item__btn-wrapper">
                                        <button
                                            title="Changer"
                                            className="bouton-editeur-pop-up"
                                            onClick={() => onImageUpdate(index)}
                                        >
                                            <i
                                                className="bi bi-arrow-repeat"
                                                style={{ fontSize: "32px" }}
                                            ></i>
                                        </button>
                                        <button
                                            className="bouton-editeur-pop-up"
                                            title="Supprimer"
                                            onClick={() => onImageRemove(index)}
                                        >
                                            <i
                                                className="bi bi-x-lg"
                                                style={{ fontSize: "32px" }}
                                            ></i>
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </ImageUploading>
        </div>
    );
}

export default GestionnaireImagesCarousel;
