/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import { useState, useEffect, useCallback } from "react";
import ReactTable from "react-table-6";

import Api from "../../Controllers/Api";
import config from "../../settings.js";
import {
    buildRegionUrl,
    createPdfMethodoLink,
    filterCaseInsensitive,
} from "../../utils.js";

/**
 * This function is used to show and edit PDFs static files.
 *
 * @param {mixed} parentApi the reference to main API object
 */
function StaticFilesManage({ parentApi }) {
    // state containing status regarding loading data process
    const [isLoading, setLoading] = useState(false);
    // state telling whether data were loaded or not
    const [hasLoaded, setLoaded] = useState(false);
    // state containing static files list
    const [staticFiles, setStaticFiles] = useState([]);
    // state containing static file data columns name
    const [columns, setColumns] = useState([]);
    // state containing files binaries set inside the forms
    const [files, setFiles] = useState({});
    // state containing a status message after upload was performed
    const [statusMessage, setStatusMessage] = useState(undefined);
    // state containing whether the upload encountered an error or not
    const [error, setError] = useState(false);

    /**
     * update the component state with the data file put inside the form
     */
    const onChangeFile = useCallback(
        (id, event) => {
            if (files) {
                files[id] = event.target.files[0];
                setFiles(files);
            }
        },
        [files, setFiles]
    );

    /**
     * upload the new file inside the API
     *
     * @param {integer} id the file_id for the file that must be updated
     */
    const uploadFile = useCallback(
        (id) => {
            if (files[id]) {
                setStatusMessage(undefined);
                // Object of type FormData
                let formData = new FormData();
                formData.append("file", files[id]);
                // Call API with the file entered by the user
                Api.callApi(
                    buildRegionUrl(
                        config.api_static_file_update,
                        parentApi.data.region
                    ).replace("#file_id#", id),
                    formData,
                    "PUT",
                    "default"
                )
                    .then((response) => {
                        // no error
                        setError(false);
                        setStatusMessage(response.message);
                        // we refresh the update_date
                        let _staticFiles = staticFiles.map((staticFile) => {
                            if (staticFile.file_id === parseInt(id, 10)) {
                                staticFile["update_date"] = response.new_date;
                            }
                            return staticFile;
                        });
                        setStaticFiles(_staticFiles);
                    })
                    .catch((e) => {
                        // an error was raised
                        setError(true);
                        setStatusMessage(e.message);
                    });
            }
        },
        [parentApi, files, staticFiles]
    );

    useEffect(() => {
        // if we haven't loaded yet and we are not loading
        if (!isLoading && !hasLoaded) {
            setLoading(true);
            // call API to retrieve all static files
            let url = buildRegionUrl(config.api_static_files, parentApi.data.region);

            Api.callApi(url, null, "get").then((response) => {
                setStaticFiles(response.data);

                // Fill columns of ReactTable
                let _columns = [];
                response.headers.forEach((header) => {
                    _columns.push({
                        Header: header,
                        accessor: header,
                        show: header !== "file_id",
                    });
                });

                // link to existing file
                _columns.push({
                    id: "existing_file",
                    Header: "Fichier existant",
                    accessor: "existing_file",
                    filterable: false,
                    Cell: (props) => {
                        const url = createPdfMethodoLink(
                            config.methodo_url,
                            parentApi.data.region,
                            props.original.file_name + ".pdf"
                        );
                        return (
                            <a
                                className="normal-size"
                                href={url}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Ouvrir le fichier
                            </a>
                        );
                    },
                });

                // button to upload and update the file
                _columns.push({
                    id: "update",
                    Header: "Mettre à jour",
                    accessor: "update",
                    filterable: false,
                    Cell: (props) => {
                        return (
                            <div className="actions">
                                <div>
                                    <input
                                        type="file"
                                        onChange={(e) =>
                                            onChangeFile(props.original.file_id, e)
                                        }
                                    />
                                </div>
                                <div>
                                    <button
                                        className="btn btn-info"
                                        onClick={() =>
                                            uploadFile(props.original.file_id)
                                        }
                                    >
                                        Mettre à jour
                                    </button>
                                </div>
                            </div>
                        );
                    },
                });
                setColumns(_columns);
                setLoading(false);
                setLoaded(true);
            });
        }
    }, [parentApi, isLoading, hasLoaded, uploadFile, onChangeFile]);

    return (
        <div className="panel-body panel-ajout-indicateur">
            <h1 style={{ marginTop: "1%" }}>Gestion des fichiers statiques (PDFs)</h1>
            {statusMessage !== undefined ? (
                <p className={error ? "alert alert-danger" : "alert alert-success"}>
                    {statusMessage}
                </p>
            ) : (
                ""
            )}
            <ReactTable
                data={staticFiles}
                columns={columns}
                className="-striped"
                filterable={true}
                defaultFilterMethod={(filter, row) =>
                    filterCaseInsensitive(filter, row)
                }
            />
        </div>
    );
}

export default StaticFilesManage;
