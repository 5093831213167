/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import "bootstrap/dist/css/bootstrap.min.css";
import "../../style/national/welcomedashboard.css";

import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const DefaultTerritory = () => {
    return (
        <div className="submission widgets full-screen-widget">
            <div className="home-page-national">
                <div className="content">
                    <h1 className="tstitle">TerriSTORY® national</h1>
                    <p>
                        <strong>
                            Si votre région n'est pas répertoriée dans la liste de
                            TerriSTORY®, ne vous inquiétez pas !{" "}
                        </strong>{" "}
                        Vous pouvez toujours bénéficier de certaines fonctionnalités du
                        site en créant un profil.
                    </p>
                    <p>Voici comment cela fonctionne :</p>
                    <ol>
                        <li>
                            <strong>création d'un profil :</strong> tout d'abord, vous
                            devez créer votre profil en spécifiant le territoire sur
                            lequel vous souhaitez utiliser TerriSTORY®.
                        </li>
                        <li>
                            <strong>traitement de votre demande :</strong> notre équipe
                            traitera rapidement votre demande et vous fournira les
                            informations de connexion nécessaires pour accéder à votre
                            espace personnel.
                        </li>
                        <li>
                            <strong>importation de vos données :</strong> une fois sur
                            cet espace, vous pourrez importer vos données. Cela vous
                            permettra d'utiliser le{" "}
                            <em>module de stratégie territoriale</em> et le{" "}
                            <em>simulateur mobilité</em> (pour en savoir plus sur ces
                            modules, vous pouvez visiter{" "}
                            <Link to="/national/modules">la page suivante</Link>).
                        </li>
                    </ol>
                    <p>
                        Notre équipe sera disponible pour vous aider tout au long de ce
                        processus, n'hésitez pas à{" "}
                        <Link to="/national/contact">nous contacter</Link>.
                    </p>
                    <div className="submit-container">
                        <Link
                            to={{
                                pathname: "/national/login",
                                state: { previousPage: "/national" },
                            }}
                            className="tsbtn info big"
                        >
                            Connexion
                        </Link>{" "}
                        <Link
                            to={{
                                pathname: "/national/inscription",
                                state: { previousPage: "/national" },
                            }}
                            className="tsbtn success big"
                        >
                            Inscription
                        </Link>
                    </div>
                </div>
                <div className="image-container">
                    <img
                        className="img-home"
                        src="/img/terristory_carte.jpg"
                        alt=""
                    ></img>
                </div>
            </div>
        </div>
    );
};

export default DefaultTerritory;
