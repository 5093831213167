/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import ReactTooltip from "react-tooltip";
import { buildRegionUrl, slugify } from "../utils.js";
import Api from "../Controllers/Api.js";
import { MainZoneSelect } from "./SelectionObjet.js";
import config from "../settings.js";
import {
    Accordion,
    AccordionItem,
    AccordionItemPanel,
    AccordionItemHeading,
    AccordionItemButton,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import "bootstrap/dist/css/bootstrap.min.css";
import SEO from "./SEO.js";

/**
 * This component is used to filter the data sources used to calculate the cartographic data.
 * It manages the home page of the site with the display of the map, graphics, legends and any filters.
 */
class Filters extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            analysis: props.parentApi.data.analysis,
            toggleMenu: props.parentApi.data.toggleMenu,
            searchPattern: "",
            currentPanel: props.parentApi.data.currentMenu,
            dataReferencement: undefined,
            installationsCochées: [],
            nomTerritoire: "",
            uiTheme: props.parentApi.data.uiTheme,
            nomIndicateurCourant: props.parentApi.data.nomIndicateurCourant,
            idInstallationCouranteTermine: false,
            disabled: false,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        let { zone, currentZone, connected, analysis, sankeySelected } =
            this.props.parentApi.data;
        // get the theme passed in url when the user enters a url to an indicator
        let uiTheme = this.props.parentApi.data.uiTheme;
        // get the installation passed in url when the user enters a url to an installation
        let idInstallationCourante = this.props.parentApi.data.idInstallationCourante;
        // current installation name
        let nomInstallationCourante = this.props.parentApi.data.nomInstallationCourante;

        if (
            prevProps.parentApi.data.zone !== zone ||
            currentZone !== prevProps.parentApi.data.currentZone
        ) {
            let nomTerritoire =
                this.props.parentApi.controller.zonesManager.getZoneName(
                    this.props.parentApi.data.currentZone,
                    this.props.parentApi.data.zone
                );
            if (zone.zone === "region") {
                nomTerritoire = this.props.parentApi.data.regionLabel;
            }
            this.setState({
                disabled: this.disableAnalysisList(
                    zone,
                    this.props.parentApi.data.currentZone
                ),
                nomTerritoire: nomTerritoire,
            });
        }

        // updating the state of the current indicator name in the index.js component
        if (this.state.nomIndicateurCourant !== prevState.nomIndicateurCourant) {
            this.props.parentApi.callbacks.miseAjourNomIndicateurCourant(
                this.state.nomIndicateurCourant
            );
        }

        let url = "";
        let nomTerritoire = this.props.parentApi.controller.zonesManager.getZoneName(
            this.props.parentApi.data.currentZone,
            this.props.parentApi.data.zone
        );
        if (!nomTerritoire) {
            // get the name of the territory passed in url when the user enters a url to an indicator/equipment
            nomTerritoire = this.props.parentApi.data.nomTerritoire;
            if (this.state.nomTerritoire) {
                nomTerritoire = this.state.nomTerritoire;
            }
        }

        if (zone) {
            url += "?zone=" + zone.zone + "&maille=" + zone.maille;
            if (zone.zone === "region") {
                url += "&zone_id=" + this.props.parentApi.data.regionCode;
            } else if (currentZone) {
                url += "&zone_id=" + currentZone;
            }
        }

        if (analysis) {
            url += "&analysis=" + analysis;
        }

        if (sankeySelected) {
            url += "&sankey=" + sankeySelected;
        }

        if (this.state.uiTheme || uiTheme) {
            // specify in the URL the theme associated with the indicator
            url += "&theme=" + uiTheme ?? this.state.uiTheme;
        }

        let url_nom_territoire = "";
        if (nomTerritoire) {
            url_nom_territoire = "&nom_territoire=" + encodeURI(nomTerritoire);
        }
        if (zone.zone === "region") {
            url_nom_territoire =
                "&nom_territoire=" + encodeURI(this.props.parentApi.data.regionLabel);
        }

        url += url_nom_territoire;

        if (idInstallationCourante) {
            url += "&installation=" + idInstallationCourante;
            if (
                !this.state.idInstallationCouranteTermine &&
                this.props.parentApi.data.depuisUrl
            ) {
                // specify in the url the installation
                let installations = idInstallationCourante.split("-");
                for (let install of installations) {
                    let inputInstallation = document.getElementById(install);
                    if (!inputInstallation.checked) {
                        setTimeout(function () {
                            inputInstallation.click();
                        }, 2000);
                    }
                }

                this.setState({
                    idInstallationCouranteTermine: true,
                });
            }
        } else if (!this.state.idInstallationCouranteTermine) {
            this.setState({
                idInstallationCouranteTermine: true,
            });
        }

        if (
            prevProps.parentApi.data.zone !== zone ||
            currentZone !== prevProps.parentApi.data.currentZone ||
            analysis !== prevProps.parentApi.data.analysis
        ) {
            this.props.parentApi.callbacks.mettreAJourParametresUrls(url);
        }

        if (prevProps.parentApi.data.zone.maille !== zone.maille) {
            // deactivate the chart if we go from one maille to another (epci, departement, teposcv)
            this.props.parentApi.callbacks.displayChart(false);
        }

        if (
            prevProps.parentApi.data.connected !== connected ||
            this.props.parentApi.data.chargementListeTableauxDeBordNecessaire
        ) {
            // Reload the analysis list if we connect / disconnect
            this.props.parentApi.callbacks.updateAnalysisList();
            this.props.parentApi.callbacks.updateDashboardsList();
            this.props.parentApi.callbacks.chargementListeTableauxDeBordNecessaire(
                false
            );
        }

        let nomIndicateurCourant =
            this.props.parentApi.controller.analysisManager.getAnalysisName(
                this.props.parentApi.data.idIndicateurCourant
            );
        if (!nomIndicateurCourant) {
            nomIndicateurCourant =
                this.props.parentApi.controller.analysisManager.getAnalysisName(
                    parseInt(this.state.analysis, 10)
                );
        }
        // update of the referencing(SEO) if we change territory
        if (
            (prevProps.parentApi.data.zone !== zone ||
                currentZone !== prevProps.parentApi.data.currentZone ||
                prevState.nomTerritoire !== this.state.nomTerritoire) &&
            this.state.uiTheme &&
            nomIndicateurCourant
        ) {
            // update meta title of home page
            const dataReferencement =
                this.state.uiTheme + " - " + nomIndicateurCourant + " " + nomTerritoire;

            this.setState({
                dataReferencement: dataReferencement,
            });
        } else if (
            (prevProps.parentApi.data.zone !== zone ||
                currentZone !== prevProps.parentApi.data.currentZone ||
                prevState.nomTerritoire !== this.state.nomTerritoire) &&
            nomInstallationCourante
        ) {
            // modification of the title key of the home page
            const dataReferencement =
                uiTheme + " - " + nomInstallationCourante + " " + nomTerritoire;
            this.setState({
                dataReferencement: dataReferencement,
            });
        }
    }

    toggleMenu() {
        let show = true;
        if (this.state.toggleMenu) {
            show = false;
        }
        this.setState({
            toggleMenu: show,
        });
        this.props.parentApi.callbacks.updateToggleMenu(show);
    }

    showMenu(menuId) {
        if (!this.state.toggleMenu) {
            this.toggleMenu();
        }
        this.setState({
            currentPanel: menuId,
        });
        this.props.parentApi.callbacks.updateCurrentMenu(menuId);
    }

    parseOtherLink(url, replace_epci_group_names = false) {
        let zoneName =
            this.props.parentApi.controller?.zonesManager?.getZoneName(
                this.props.parentApi.data.currentZone,
                this.props.parentApi.data.zone
            ) ?? "unknown";
        // we make the right replacements
        if (url.includes("{zone-name")) {
            url = url.replaceAll("{zone-name}", zoneName);
        }
        if (url.includes("{zone-name-slug")) {
            // we create the slug
            let slugZoneName = slugify(zoneName);
            // in  some weird cases, we have to replace the groups names by their
            // full length names (Communautés de communes)
            if (replace_epci_group_names) {
                if (slugZoneName.startsWith("cc-")) {
                    slugZoneName = slugZoneName.replace(
                        "cc-",
                        "communaute-de-communes-"
                    );
                } else if (slugZoneName.startsWith("ca-")) {
                    slugZoneName = slugZoneName.replace(
                        "ca-",
                        "communaute-d-agglomeration-"
                    );
                }
            }
            url = url.replaceAll("{zone-name-slug}", slugZoneName);
        }
        const zoneId = this.props.parentApi.data?.currentZone ?? "unknown";
        if (url.includes("{zone-id")) {
            url = url.replaceAll("{zone-id}", zoneId);
        }
        if (url.includes("{zone-id-slug")) {
            url = url.replaceAll("{zone-id-slug}", slugify(zoneId));
        }
        return url;
    }

    disableAnalysisList(zone) {
        let disabled = true;
        if (zone.zone === "region") {
            disabled = false;
            if (this.state) {
                if (!this.state.toggleMenu) {
                    this.toggleMenu();
                }
            }
        } else {
            if (this.props.territoireSelectionne) {
                disabled = false;
                if (!this.state.toggleMenu) {
                    this.toggleMenu();
                }
            }
        }
        return disabled;
    }

    handleSearchChange(event) {
        this.setState({ searchPattern: event.target.value });
    }

    render() {
        const launchAnalysis = (e, id, id_tableau_bord, item) => {
            e.stopPropagation();
            if (id === "simulateur") {
                this.props.parentApi.callbacks.updateProvenance("filters");
            }
            if (id_tableau_bord) {
                // Needed to know which dashboard to render when clicking on the title of one of them in the left menu.
                this.props.parentApi.callbacks.definirTableauBordCourant(
                    id_tableau_bord
                );
                this.props.parentApi.callbacks.updateAnalysis(undefined);
            }
            // update referencement
            const dataReferencement =
                item.ui_theme + " - " + item.name + " " + this.state.nomTerritoire;

            this.setState({
                dataReferencement: dataReferencement,
                analysis: id,
                uiTheme: item.ui_theme,
                nomIndicateurCourant: item.name,
            });

            if (parseInt(id, 10) !== parseInt(this.props.parentApi.data.analysis, 10)) {
                this.props.parentApi.callbacks.updateAnalysis(
                    id,
                    item.options,
                    "carto"
                );
                this.props.parentApi.callbacks.updateProvenance("carto");
            }

            this.props.parentApi.callbacks.displayChart(false);
            this.props.parentApi.callbacks.miseAJourUiTheme(item.ui_theme);
            this.props.parentApi.callbacks.miseAjourNomIndicateurCourant(item.name);
        };

        const handlePOILayerVisibilityChange = (uiTheme, nomInstallationCourante) => {
            return (e) => {
                const target = e.target;
                const value =
                    target.type === "checkbox" ? target.checked : target.value;
                // creation of a temporary array that contains the array returned by the state
                let tempArray = this.state.installationsCochées;
                let installationsCocheesStr = "";
                // update uiTheme
                this.props.parentApi.callbacks.miseAJourUiTheme(uiTheme);
                // if an installation is checked
                if (target.checked) {
                    // add the name of the installation to the table
                    tempArray.push(target.name);
                    for (let eq of tempArray) {
                        if (tempArray.indexOf(eq) !== tempArray.length - 1) {
                            installationsCocheesStr += eq + "-";
                        } else {
                            installationsCocheesStr += eq;
                        }
                    }
                    // update the state with new array
                    this.setState({
                        installationsCochées: tempArray,
                    });

                    this.props.parentApi.callbacks.miseAjourNomInstallationCourante(
                        nomInstallationCourante,
                        installationsCocheesStr,
                        uiTheme
                    );
                } else {
                    // if the installation is unchecked
                    // get the position from the installation name in the array
                    let pos = tempArray.indexOf(target.name);
                    // remove the installation from the array
                    tempArray.splice(pos, 1);
                    // maj du state avec le nouveau tableau
                    this.setState({
                        installationsCochées: tempArray,
                    });
                    // reset current value
                    this.props.parentApi.callbacks.miseAjourNomInstallationCourante(
                        undefined
                    );
                    this.props.parentApi.callbacks.miseAJourUiTheme(undefined);
                }

                const { analysis } = this.props.parentApi.data;
                const analysisUrl = analysis ? "&analysis=" + analysis : "";

                // if the state array is not empty
                if (this.state.installationsCochées.length > 0) {
                    // converting array elements to comma separated string
                    let installationsConcat =
                        this.state.installationsCochées.toString();
                    // replace commas with dashes
                    let installationsConcatAvecTirets = installationsConcat.replaceAll(
                        ",",
                        "-"
                    );
                    // update the url
                    let zoneId = this.props.parentApi.data.currentZone;
                    if (this.props.parentApi.data.zone.zone === "region") {
                        zoneId = this.props.parentApi.data.regionCode;
                    }
                    let nomTerritoire = this.state.nomTerritoire;
                    if (!this.state.nomTerritoire) {
                        nomTerritoire =
                            this.props.parentApi.controller.zonesManager.getZoneName(
                                this.props.parentApi.data.currentZone,
                                this.props.parentApi.data.zone
                            );
                    }
                    this.props.parentApi.callbacks.mettreAJourParametresUrls(
                        "?region=" +
                            this.props.parentApi.data.region +
                            "&zone=" +
                            this.props.parentApi.data.zone.zone +
                            "&maille=" +
                            this.props.parentApi.data.zone.maille +
                            "&zone_id=" +
                            zoneId +
                            "&nom_territoire=" +
                            nomTerritoire +
                            "&theme=" +
                            uiTheme +
                            "&installation=" +
                            installationsConcatAvecTirets +
                            analysisUrl
                    );

                    // update referencement
                    const dataReferencement =
                        uiTheme + " - " + nomInstallationCourante + " " + nomTerritoire;
                    this.setState({
                        dataReferencement: dataReferencement,
                    });
                } else {
                    // if the array is empty
                    // update the url
                    this.props.parentApi.callbacks.mettreAJourParametresUrls(
                        "?zone=" +
                            this.props.parentApi.data.zone.zone +
                            "&maille=" +
                            this.props.parentApi.data.zone.maille +
                            "&zone_id=" +
                            this.props.parentApi.data.currentZone +
                            "&nom_territoire=" +
                            this.state.nomTerritoire +
                            analysisUrl
                    );
                }
                let nom_couche = "";
                let cochee = false;
                for (let poiLayer of this.props.parentApi.data.poiLayers) {
                    if (poiLayer.nom === target.name) {
                        poiLayer.checked = value;
                        nom_couche = poiLayer.label;
                        cochee = value;
                    }
                }
                let region = this.props.parentApi.data.region;
                let urlCodeTerritoire =
                    "?code_territoire=" + this.props.parentApi.data.currentZone;
                if (this.props.parentApi.zone) {
                    if (this.props.parentApi.zone.zone === "region") {
                        urlCodeTerritoire =
                            "?code_territoire=" + this.props.parentApi.data.regionCode;
                    }
                }
                let urlTypeTerritoire =
                    "&type_territoire=" + this.props.parentApi.data.zone.zone;
                let urlIdUtilisateur =
                    "&id_utilisateur=" +
                    this.props.parentApi.controller.gestionSuiviConsultations
                        .idUtilisateur;
                let urlSuiviPoi =
                    config.api_poi_consult_layer_url.replace(
                        "#layer#",
                        nom_couche.replace("/", " et ")
                    ) +
                    "/" +
                    cochee +
                    urlCodeTerritoire +
                    urlTypeTerritoire +
                    urlIdUtilisateur;
                Api.callApi(buildRegionUrl(urlSuiviPoi, region), null, "GET");
                this.props.parentApi.callbacks.updatePoiLayers(
                    this.props.parentApi.data.poiLayers.slice()
                );
            };
        };

        const newPoi = (layer) => {
            this.props.parentApi.callbacks.newPoi(layer);
        };

        // Build UI elements for sub-indicators
        const buildSubIndicatorList = (subIndicators, launchAnalysis) => {
            return subIndicators.map((subIndic) => {
                let [subIndicEnabled, subIndicTitle] = isAnalysisEnabled(subIndic);
                let subIndicClassName = subIndicEnabled ? "" : "disabled";
                const onclickSub = subIndicEnabled
                    ? (e) => launchAnalysis(e, subIndic.id, undefined, subIndic)
                    : undefined;

                if (subIndicTitle) {
                    subIndicTitle +=
                        " Sélectionnez un autre territoire en haut de la page.";
                }

                // Highlight the item if it is the currently analyzed one
                subIndicClassName =
                    parseInt(this.props.parentApi.data.analysis, 10) ===
                        parseInt(subIndic.id, 10) && this.props.parentApi.data.analysis
                        ? "active"
                        : subIndicClassName;

                // Return a list of sub-indicator elements for each indicator
                return (
                    <li
                        className={subIndicClassName}
                        key={subIndic.id}
                        onClick={(e) => onclickSub(e)}
                    >
                        <span title={subIndicTitle}>{subIndic.name}</span>
                    </li>
                );
            });
        };

        let currentAnalysis = this.state.analysis;
        const { analysisManager } = this.props.parentApi.controller;
        // Get label
        for (let ca of analysisManager.analysis) {
            if (ca.id.toString() === currentAnalysis) {
                currentAnalysis = ca.name;
                break;
            }
        }
        if (!currentAnalysis) {
            currentAnalysis = "";
        }

        let poiLayersFinal = [];
        let couchePoiParRubrique = {};
        for (let poiLayer of this.props.parentApi.data.poiLayers) {
            if (!couchePoiParRubrique[poiLayer.theme]) {
                couchePoiParRubrique[poiLayer.theme] = [];
            }
            couchePoiParRubrique[poiLayer.theme].push(poiLayer);
        }

        for (let theme in couchePoiParRubrique) {
            let poiLayersMenu = [];
            for (let poiLayer of couchePoiParRubrique[theme]) {
                let editButton = "";
                if (
                    this.props.parentApi.data.connected &&
                    poiLayer.checked &&
                    poiLayer.modifiable
                ) {
                    editButton = (
                        <button
                            type="button"
                            className="menu-button"
                            onClick={() => newPoi(poiLayer.nom)}
                        >
                            <div className="poi-add"></div>
                        </button>
                    );
                }

                if (this.props.parentApi.data.settings.ui_show_poi) {
                    poiLayersMenu.push(
                        <div className="layer-check" key={poiLayer.nom}>
                            <label>
                                <input
                                    id={poiLayer.nom}
                                    name={poiLayer.nom}
                                    type="checkbox"
                                    checked={poiLayer.checked}
                                    onChange={handlePOILayerVisibilityChange(
                                        theme,
                                        poiLayer.label
                                    )}
                                />

                                {poiLayer.label}
                                <span
                                    data-tip={
                                        "Cliquer ici pour ajouter un(e) " +
                                        poiLayer.label
                                    }
                                >
                                    {editButton}
                                </span>
                            </label>
                        </div>
                    );
                }
            }
            poiLayersFinal.push(
                <Accordion allowMultipleExpanded allowZeroExpanded key={theme}>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton className="accordion-indicateur accordion__button">
                                <b className="retour-ligne-fleche">{theme}</b>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className="liste-indicateurs">
                            {poiLayersMenu}
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
            );
        }

        const isAnalysisEnabled = (item) => {
            let zone = this.props.parentApi.data.zone;
            const analysis = analysisManager.analysis.find(
                (ca) => item.id === ca.id.toString()
            );
            if (analysis !== undefined) {
                if (
                    analysis.disabled_for_macro_level !== null &&
                    analysis.disabled_for_macro_level.split(",").includes(zone.zone)
                ) {
                    const plural = analysis.disabled_for_macro_level.includes(",");
                    const info = `Indicateur non disponible pour ${
                        plural ? "les types de territoire" : "le type de territoire"
                    } : ${analysis.disabled_for_macro_level.split(",").join(", ")}.`;
                    return [false, info];
                }
                if (
                    analysis.disabled_for_zone !== null &&
                    analysis.disabled_for_zone.split(",").includes(zone.maille)
                ) {
                    const plural = analysis.disabled_for_zone.includes(",");
                    const info = `Indicateur non disponible ${
                        plural ? "aux mailles" : "à la maille"
                    } : ${analysis.disabled_for_zone.split(",").join(", ")}.`;
                    return [false, info];
                }
                if (
                    analysis.only_for_zone &&
                    !analysis.only_for_zone.split(",").includes(zone.maille)
                ) {
                    const plural = analysis.only_for_zone.includes(",");
                    const info = `Indicateur uniquement disponible ${
                        plural ? "aux mailles" : "à la maille"
                    } : ${analysis.only_for_zone.split(",").join(", ")}.`;
                    return [false, info];
                }
            }
            // Special case for region & synthese
            if (
                item.id === "synthese_territoriale" &&
                ["region", "commune"].includes(this.props.parentApi.data.zone.zone)
            ) {
                return [
                    false,
                    "Synthèse non disponible à l'échelle régionale ou communale.",
                ];
            }

            return [true];
        };

        let listThemeActions = [];
        let requisiteItem = (
            <label className="alert alert-warning">Sélectionnez un territoire</label>
        );
        listThemeActions.push({ id: "indicateur", items: requisiteItem });
        listThemeActions.push({ id: "plan-action", items: requisiteItem });
        listThemeActions.push({ id: "analyse", items: requisiteItem });
        let disabled = this.state.disabled;
        if (this.props.territoireSelectionne) {
            disabled = false;
        }
        if (analysisManager.analysis && !disabled) {
            const indicateursList = [];
            for (let a of analysisManager.analysis) {
                if (a.concatenation === null || a.concatenation === undefined) {
                    if (this.state.searchPattern !== "") {
                        let found = a.nom
                            .toLowerCase()
                            .indexOf(this.state.searchPattern);
                        if (found === -1) {
                            continue;
                        }
                    }

                    if (
                        this.props.parentApi.data.profil === "admin" ||
                        this.props.parentApi.data.accesIndicateursDesactives ||
                        a.active
                    ) {
                        let subItem;
                        try {
                            subItem = a.sub_indicator
                                ? JSON.parse(a.sub_indicator)?.map(
                                      (item) => item.indicator
                                  )
                                : undefined;
                        } catch (error) {
                            // Handle JSON parsing error
                            console.error("Error parsing JSON:", error);
                        }

                        indicateursList.push({
                            id: a.id.toString(),
                            name: a.nom,
                            ui_theme: a.ui_theme,
                            sub_indicator: subItem,
                            is_sub_indicator: a.is_sub_indicator,
                        });
                    }
                }
            }
            const plansActionsList = [
                {
                    id: "strategies_territoriales",
                    name: "Stratégies territoriales",
                    ui_theme: null,
                    is_sub_indicator: false,
                },
                {
                    id: "simulateur",
                    name: "Mobilité",
                    ui_theme: "Simulateurs",
                    is_sub_indicator: false,
                },
            ];

            const analysesList = [];
            if (
                this.props.parentApi.data.settings &&
                this.props.parentApi.data.settings.ui_show_cesba
            ) {
                analysesList.push({
                    id: "synthese_territoriale",
                    name: "Synthèse des indicateurs territoriaux",
                    ui_theme: "Profil du territoire",
                    is_sub_indicator: false,
                });
            }

            if (this.props.parentApi.data?.settings?.other_links) {
                for (let group in this.props.parentApi.data.settings.other_links) {
                    for (let link of this.props.parentApi.data.settings.other_links[
                        group
                    ]) {
                        if (
                            (link?.zones_types ?? "")
                                .split(",")
                                .includes(this.props.parentApi.data.zone.zone) &&
                            !link?.zones_excluded?.[
                                this.props.parentApi.data.zone.zone
                            ]?.includes(this.props.parentApi.data.currentZone)
                        ) {
                            const url = this.parseOtherLink(
                                link.url,
                                link?.replace_epci_group_names
                            );
                            analysesList.push({
                                id: link.slug,
                                name: link.label,
                                ui_theme: group,
                                url: url,
                                show_external: link.show_external,
                            });
                        }
                    }
                }
            }

            // special case: Energy trajectory tracking, Carbon trajectory tracking, Air pollutant trajectory tracking
            if (
                this.props.parentApi.data.settings &&
                this.props.parentApi.data.settings.analyses_pages
            ) {
                if (
                    this.props.parentApi.data.settings.analyses_pages.includes(
                        "suivi_energetique"
                    )
                ) {
                    analysesList.push({
                        id: "suivi_energetique",
                        name: "Suivi de la trajectoire énergétique",
                        ui_theme: "Suivi de la trajectoire énergétique",
                        is_sub_indicator: false,
                    });
                }
                if (
                    this.props.parentApi.data.settings.analyses_pages.includes(
                        "suivi_emission_ges"
                    )
                ) {
                    analysesList.push({
                        id: "suivi_emission_ges",
                        name: "Suivi des émissions de GES",
                        ui_theme: "Suivi de la trajectoire carbone",
                        is_sub_indicator: false,
                    });
                }
                if (
                    this.props.parentApi.data.settings.analyses_pages.includes(
                        "suivi_polluants_nox"
                    )
                ) {
                    analysesList.push({
                        id: "suivi_polluants_nox",
                        name: "Suivi des émissions de NOx",
                        ui_theme: "Suivi des trajectoires des polluants atmosphériques",
                        is_sub_indicator: false,
                    });
                }
                if (
                    this.props.parentApi.data.settings.analyses_pages.includes(
                        "suivi_polluants_pm10"
                    )
                ) {
                    analysesList.push({
                        id: "suivi_polluants_pm10",
                        name: "Suivi des émissions de PM10 ",
                        ui_theme: "Suivi des trajectoires des polluants atmosphériques",
                        is_sub_indicator: false,
                    });
                }
                if (
                    this.props.parentApi.data.settings.analyses_pages.includes(
                        "suivi_polluants_pm25"
                    )
                ) {
                    analysesList.push({
                        id: "suivi_polluants_pm25",
                        name: "Suivi des émissions de PM2.5 ",
                        ui_theme: "Suivi des trajectoires des polluants atmosphériques",
                        is_sub_indicator: false,
                    });
                }
                if (
                    this.props.parentApi.data.settings.analyses_pages.includes(
                        "suivi_polluants_covnm"
                    )
                ) {
                    analysesList.push({
                        id: "suivi_polluants_covnm",
                        name: "Suivi des émissions de COVNM",
                        ui_theme: "Suivi des trajectoires des polluants atmosphériques",
                        is_sub_indicator: false,
                    });
                }
                if (
                    this.props.parentApi.data.settings.analyses_pages.includes(
                        "suivi_polluants_so2"
                    )
                ) {
                    analysesList.push({
                        id: "suivi_polluants_so2",
                        name: "Suivi des émissions de SO2 ",
                        ui_theme: "Suivi des trajectoires des polluants atmosphériques",
                        is_sub_indicator: false,
                    });
                }
                if (
                    this.props.parentApi.data.settings.analyses_pages.includes(
                        "suivi_polluants_nh3"
                    )
                ) {
                    analysesList.push({
                        id: "suivi_polluants_nh3",
                        name: "Suivi des émissions de NH3",
                        ui_theme: "Suivi des trajectoires des polluants atmosphériques",
                        is_sub_indicator: false,
                    });
                }

                // We limit sankeys to EPCIs (by choice)
                if (
                    this.props.parentApi.data.zone &&
                    this.props.parentApi.data.zone.zone &&
                    this.props.parentApi.data.settings &&
                    this.props.parentApi.data.settings.ui_show_sankey
                ) {
                    this.props.parentApi.data.settings.sankeys.forEach((sankey) => {
                        // do not show sankeys that were not enabled
                        if (
                            !sankey["geographical_levels_enabled"].includes(
                                this.props.parentApi.data.zone.zone
                            )
                        ) {
                            return;
                        }
                        analysesList.push({
                            id: "diagramme_sankey",
                            name:
                                sankey["sankey_name"] !== ""
                                    ? sankey["sankey_name"]
                                    : sankey["data_table"],
                            options: {
                                tableName: sankey["data_table"],
                            },
                            key: "diagramme_sankey_" + sankey["data_table"],
                            ui_theme: "Suivi des flux",
                            is_sub_indicator: false,
                        });
                    });
                }
            }

            for (let theme of listThemeActions) {
                if (theme.id === "indicateur") {
                    theme.list = indicateursList;
                }
                if (theme.id === "plan-action") {
                    theme.list = plansActionsList;
                }
                if (theme.id === "analyse") {
                    theme.list = analysesList;
                }

                let uiThemeList = [];
                for (let item of theme.list) {
                    // Classify indicators by ui_theme
                    let indUiTheme = uiThemeList.find(
                        (ui_theme) => ui_theme.id === item.ui_theme
                    );
                    if (!indUiTheme) {
                        uiThemeList.push({
                            type: item.id,
                            id: item.ui_theme,
                            items: [],
                        });
                        indUiTheme = uiThemeList.find(
                            (ui_theme) => ui_theme.id === item.ui_theme
                        );
                    }

                    // Check if the current item is enabled for analysis
                    let [enabled, title] = isAnalysisEnabled(item);
                    let className = enabled ? "" : "disabled";
                    let onclick = enabled
                        ? (e) => launchAnalysis(e, item.id, undefined, item)
                        : undefined;

                    // Add a message to the title if applicable
                    if (title) {
                        title +=
                            " Sélectionnez un autre territoire en haut de la page.";
                    }

                    // Highlight the item if it is the currently analyzed one
                    className =
                        parseInt(this.props.parentApi.data.analysis, 10) ===
                            parseInt(item.id, 10) && this.props.parentApi.data.analysis
                            ? "active"
                            : className;

                    // if item is not a sub-indicator
                    if (!item.is_sub_indicator) {
                        // If the item is a link
                        if (item.url) {
                            indUiTheme.items.push(
                                <li className={className} key={item.key ?? item.id}>
                                    <a
                                        className={
                                            item.show_external && "navbar-external-link"
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={item.url}
                                    >
                                        {item.name}
                                    </a>
                                </li>
                            );
                        } else {
                            // find sub-indicators metadata
                            const subIndicatorList = (item.sub_indicator || [])
                                .map((id) => {
                                    const foundElement = theme.list.find(
                                        (elem) =>
                                            parseInt(elem.id, 10) === parseInt(id, 10)
                                    );

                                    return foundElement
                                        ? {
                                              id: foundElement.id,
                                              name: foundElement.name,
                                              ui_theme: foundElement.ui_theme,
                                          }
                                        : null;
                                })
                                .filter(Boolean); // Filter out null values

                            // Create a list of UI elements for sub-indicators
                            const subIndicatorUi = buildSubIndicatorList(
                                subIndicatorList,
                                launchAnalysis
                            );

                            // Add the item and its sub-indicators to the UI theme list
                            indUiTheme.items.push(
                                <div key={item.key ?? item.id}>
                                    <li
                                        className={className}
                                        onClick={(e) => onclick(e)}
                                    >
                                        <span title={title}>{item.name}</span>
                                    </li>
                                    <ul>{subIndicatorUi}</ul>
                                </div>
                            );
                        }
                    }
                }

                let items = [];
                for (let ui_theme of uiThemeList) {
                    let themeLabel = "";
                    if (ui_theme.id) {
                        themeLabel = (
                            <Accordion
                                allowMultipleExpanded
                                allowZeroExpanded
                                key={ui_theme.id}
                            >
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton className="accordion-indicateur accordion__button">
                                            <b className="retour-ligne-fleche">
                                                {ui_theme.id}
                                            </b>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="liste-indicateurs">
                                        <ul className="analysis-list">
                                            {ui_theme.items}
                                        </ul>
                                    </AccordionItemPanel>
                                </AccordionItem>
                            </Accordion>
                        );
                    } else {
                        // No theme id means the items should be directly in the menu, without accordion
                        themeLabel = (
                            <ul key="no-accordion" className="analysis-list">
                                {ui_theme.items}
                            </ul>
                        );
                    }

                    items.push(themeLabel);
                }

                theme.items = (
                    <div className="panel-container">
                        <ul className="analysis-themes">{items}</ul>
                    </div>
                );
            }
        }

        let mesTableauxDeBord = (
            <Accordion
                allowMultipleExpanded
                allowZeroExpanded
                key={"tableaux-bord-publics"}
            >
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton className="accordion-indicateur accordion__button">
                            <b className="retour-ligne-fleche">Mes tableaux de bord</b>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="liste-indicateurs">
                        <label className="alert alert-warning">
                            Veuillez vous connecter
                        </label>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
        );
        let creationTableauBord = (
            <Accordion
                allowMultipleExpanded
                allowZeroExpanded
                key={"creation-tableau-bord"}
            >
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton className="accordion-indicateur accordion__button">
                            <b className="retour-ligne-fleche">
                                Création d'un tableau de bord
                            </b>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="liste-indicateurs">
                        <label className="alert alert-warning">
                            Veuillez vous connecter
                        </label>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
        );

        // Menu Tableau de bord.
        // On ne l'ouvre que lorsqu'on est connecté en tant qu'administrateur.trice.
        let itemCreaTionTdB = {
            id: "creation_tableaux_bord",
            name: "Création d'un tableau de bord",
            ui_theme: "Création d'un tableau de bord",
        };
        if (this.props.parentApi.data.connected) {
            mesTableauxDeBord = (
                <Accordion
                    allowMultipleExpanded
                    allowZeroExpanded
                    key={"mes-tableaux-bord"}
                >
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton className="accordion-indicateur accordion__button">
                                <b className="retour-ligne-fleche">
                                    Mes tableaux de bord
                                </b>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className="liste-indicateurs">
                            {requisiteItem}
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
            );
            creationTableauBord = (
                <Accordion
                    allowMultipleExpanded
                    allowZeroExpanded
                    key={"creation-tableau-bord"}
                >
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton className="accordion-indicateur accordion__button">
                                <b className="retour-ligne-fleche">
                                    Création d'un tableau de bord
                                </b>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className="liste-indicateurs">
                            <ul className="analysis-list">
                                <li
                                    onClick={(e) =>
                                        launchAnalysis(
                                            e,
                                            "creation_tableaux_bord",
                                            undefined,
                                            itemCreaTionTdB
                                        )
                                    }
                                >
                                    Créer un tableau de bord
                                </li>
                            </ul>
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
            );
            let listMyDashboards =
                this.props.parentApi.controller.dashboardManager.listMyDashboards;
            if (listMyDashboards) {
                if (
                    !listMyDashboards.statut &&
                    listMyDashboards.length !== 0 &&
                    this.props.territoireSelectionne
                ) {
                    mesTableauxDeBord = []; // to list the dashboards created by the connected person
                    for (let tableau of this.props.parentApi.controller.dashboardManager
                        .listMyDashboards) {
                        let itemRestitutionTdB = {
                            id: "restitution_tableaux_bord_" + tableau.id,
                            name: tableau.titre,
                            ui_theme: tableau.titre,
                        };
                        mesTableauxDeBord.push(
                            <ul
                                className="analysis-list"
                                key={"restitution_tableaux_bord_" + tableau.id}
                            >
                                <li
                                    className={""}
                                    key={"liste_restitution_tableaux_bord"}
                                    onClick={(e) =>
                                        launchAnalysis(
                                            e,
                                            "restitution_tableaux_bord",
                                            tableau.id,
                                            itemRestitutionTdB
                                        )
                                    }
                                >
                                    {tableau.titre}
                                </li>
                            </ul>
                        );
                    }

                    mesTableauxDeBord = (
                        <Accordion
                            allowMultipleExpanded
                            allowZeroExpanded
                            key={"mes-tableaux-bord"}
                        >
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton className="accordion-indicateur accordion__button">
                                        <b className="retour-ligne-fleche">
                                            Mes tableaux de bord
                                        </b>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel className="liste-indicateurs">
                                    {mesTableauxDeBord}
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>
                    );
                } else if (this.props.territoireSelectionne) {
                    mesTableauxDeBord = (
                        <Accordion
                            allowMultipleExpanded
                            allowZeroExpanded
                            key={"mes-tableaux-bord"}
                        >
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton className="accordion-indicateur accordion__button">
                                        <b className="retour-ligne-fleche">
                                            Mes tableaux de bord
                                        </b>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel className="liste-indicateurs">
                                    <label className="alert alert-warning">
                                        Vous n'avez aucun tableau de bord pour ce
                                        territoire
                                    </label>
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>
                    );
                }
            }
        }
        let tableauxDeBordPublics = (
            <Accordion
                allowMultipleExpanded
                allowZeroExpanded
                key={"tableaux-bord-disponibles"}
            >
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton className="accordion-indicateur accordion__button">
                            <b className="retour-ligne-fleche">
                                Tableaux de bord disponibles pour le territoire
                                sélectionné
                            </b>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="liste-indicateurs">
                        {requisiteItem}
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
        );
        if (this.props.territoireSelectionne) {
            if (this.props.parentApi.controller.dashboardManager.listPublicDashboards) {
                // If there are dashboards available for the selected territory
                tableauxDeBordPublics = []; // to access the dashboards available for this territory
                for (let tableau of this.props.parentApi.controller.dashboardManager
                    .listPublicDashboards) {
                    let itemRestitutionTdB = {
                        id: "restitution_tableaux_bord_" + tableau.id,
                        name: tableau.titre,
                        ui_theme: tableau.titre,
                    };
                    tableauxDeBordPublics.push(
                        <ul
                            className="analysis-list"
                            key={"restitution_tableaux_bord_publics_" + tableau.id}
                        >
                            <li
                                key={"liste_restitution_tableaux_bord"}
                                onClick={(e) =>
                                    launchAnalysis(
                                        e,
                                        "restitution_tableaux_bord",
                                        tableau.id,
                                        itemRestitutionTdB
                                    )
                                }
                            >
                                {tableau.titre}
                            </li>
                        </ul>
                    );
                }
                tableauxDeBordPublics = (
                    <Accordion
                        allowMultipleExpanded
                        allowZeroExpanded
                        key={"tableaux-bord-publics-disponibles"}
                    >
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton className="accordion-indicateur accordion__button">
                                    <b className="retour-ligne-fleche">
                                        Tableaux de bord disponibles pour le territoire
                                        sélectionné
                                    </b>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel className="liste-indicateurs">
                                {tableauxDeBordPublics}
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>
                );
                if (
                    this.props.parentApi.controller.dashboardManager
                        .listPublicDashboards.length === 0
                ) {
                    tableauxDeBordPublics = (
                        <Accordion
                            allowMultipleExpanded
                            allowZeroExpanded
                            key={"tableaux-bord-disponibles"}
                        >
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton className="accordion-indicateur accordion__button">
                                        <b className="retour-ligne-fleche">
                                            Tableaux de bord disponibles pour le
                                            territoire sélectionné
                                        </b>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel className="liste-indicateurs">
                                    <label className="alert alert-warning">
                                        Il n' y pas de tableau de bord disponible pour
                                        ce territoire
                                    </label>
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>
                    );
                }
            }
        }
        let tableauBordInterfaceUtilisateur = (
            <div key={"acces_interface_creation_tableaux_bord"}>
                {tableauxDeBordPublics}
                {mesTableauxDeBord}
                {creationTableauBord}
            </div>
        );
        let creerTableauBord = (
            <div className="panel-container">
                <ul className="analysis-themes">{tableauBordInterfaceUtilisateur}</ul>
            </div>
        );

        let classToggleMenu = "hidden";
        if (this.state.toggleMenu) {
            classToggleMenu = "visible";
        }

        let listIndicateurs = undefined;
        let ind = listThemeActions.find((theme) => theme.id === "indicateur");
        if (ind && this.props.territoireSelectionne) {
            listIndicateurs = ind.items;
        } else {
            listIndicateurs = requisiteItem;
        }

        let listAnalyses = undefined;
        ind = listThemeActions.find((theme) => theme.id === "analyse");
        if (ind && this.props.territoireSelectionne) {
            listAnalyses = ind.items;
        } else {
            listAnalyses = requisiteItem;
        }

        let listPlansActions = undefined;
        ind = listThemeActions.find((theme) => theme.id === "plan-action");
        if (ind && this.props.territoireSelectionne) {
            listPlansActions = ind.items;
        } else {
            listPlansActions = requisiteItem;
        }

        ind = listThemeActions.find((theme) => theme.id === "tableaux-bord");

        let classToggleButton = "toggle_on";
        if (this.state.toggleMenu) {
            classToggleButton = "toggle_off";
        }

        let classTheme = "";
        if (this.props.parentApi && this.props.parentApi.data) {
            classTheme += " menu-" + this.props.parentApi.data.theme;
        }

        // Menu POI
        let poiMenu = "";
        if (this.props.parentApi.data.settings.ui_show_poi) {
            poiMenu = (
                <li
                    className={
                        "installation " +
                        (this.state.currentPanel === "installation" ? "active" : "")
                    }
                    onClick={(e) => this.showMenu("installation")}
                    data-tip="Équipements"
                >
                    <div className="menu-icon" />
                </li>
            );
        }
        // Menu cesba
        let analyseMenu = "";
        if (this.props.parentApi.data.settings.ui_show_analyse) {
            analyseMenu = (
                <li
                    className={
                        "analyse " +
                        (this.state.currentPanel === "analyse" ? "active" : "")
                    }
                    onClick={(e) => this.showMenu("analyse")}
                    data-tip="Analyses territoriales"
                >
                    <div className="menu-icon" />
                </li>
            );
        }
        // Menu plan actions
        let planActionsMenu = "";
        if (this.props.parentApi.data.settings.ui_show_plan_actions) {
            planActionsMenu = (
                <li
                    className={
                        "plan-action " +
                        (this.state.currentPanel === "plan-action" ? "active" : "")
                    }
                    onClick={(e) => this.showMenu("plan-action")}
                    data-tip="Stratégies territoriales"
                >
                    <div className="menu-icon" />
                </li>
            );
        }

        let tableauBordMenu = "";
        if (this.props.parentApi.data.settings.ui_show_tableau_bord) {
            tableauBordMenu = (
                <li
                    className={
                        "tableaux-bord " +
                        (this.state.currentPanel === "tableaux-bord" ? "active" : "")
                    }
                    onClick={(e) => this.showMenu("tableaux-bord")}
                    data-tip="Tableaux de bord"
                >
                    <div className="menu-icon" />
                </li>
            );
        }
        let autresFiltres = (
            <div className="left-menu">
                <div className="menu-container">
                    <ul className={"menu" + classTheme}>
                        <li
                            className={classToggleButton}
                            onClick={(e) => this.toggleMenu()}
                            data-tip="Afficher/Masquer le menu"
                        >
                            <div className="menu-icon" />
                        </li>
                        <li
                            className={
                                "indicateur " +
                                (this.state.currentPanel === "indicateur"
                                    ? "active"
                                    : "")
                            }
                            onClick={(e) => this.showMenu("indicateur")}
                            data-tip="Indicateurs"
                        >
                            <div className="menu-icon" />
                        </li>
                        {poiMenu}
                        {tableauBordMenu}
                        {analyseMenu}
                        {planActionsMenu}
                    </ul>
                </div>
                <ReactTooltip
                    place="right"
                    type="success"
                    effect="float"
                    data-delay-show="1000"
                    className="react-tooltip-custom"
                />

                <div className={"toggle-panel toggle-panel-" + classToggleMenu}>
                    <div
                        className={
                            "panel-off panel-indicateur-" + this.state.currentPanel
                        }
                    >
                        <label className="title">Indicateurs</label>
                        <input
                            type="text"
                            name="search"
                            value={this.state.searchPattern}
                            onChange={(e) => this.handleSearchChange(e)}
                            placeholder="Rechercher"
                        />
                        <div className="sub-panel">{listIndicateurs}</div>
                    </div>

                    <div
                        className={
                            "panel-off panel-installation-" + this.state.currentPanel
                        }
                    >
                        <label className="title">Equipements</label>
                        <div className="sub-panel">
                            <div className="panel-container">{poiLayersFinal}</div>
                        </div>
                    </div>

                    <div
                        className={"panel-off panel-analyse-" + this.state.currentPanel}
                    >
                        <label className="title">Analyses territoriales</label>
                        <div className="sub-panel">{listAnalyses}</div>
                    </div>

                    <div
                        className={
                            "panel-off panel-plan-action-" + this.state.currentPanel
                        }
                    >
                        <label className="title">Stratégies territoriales</label>
                        <div className="sub-panel">{listPlansActions}</div>
                    </div>

                    <div
                        className={
                            "panel-off panel-tableaux-bord-" + this.state.currentPanel
                        }
                    >
                        <label className="title">Tableaux de bord</label>
                        <div className="sub-panel">{creerTableauBord}</div>
                    </div>
                </div>
            </div>
        );

        const { zonesManager } = this.props.parentApi.controller;
        let filtreTerritoires = "";
        if (zonesManager.zones) {
            filtreTerritoires = <MainZoneSelect parentApi={this.props.parentApi} />;
        }

        return (
            <div>
                <SEO
                    settings={this.props.parentApi.data.settings["seo"]}
                    page="home-page"
                    seoDetails={this.state.dataReferencement}
                />
                {filtreTerritoires}
                {autresFiltres}
            </div>
        );
    }
}

export default Filters;
