/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import * as d3 from "d3";
import { Line } from "react-chartjs-2";
import configData from "../settings_data.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "chartjs-adapter-moment";

/**
 * Ce composant permet de définir et d'afficher les trajectoires cibles.
 * Il génère les trajectoires cibles et qui est appelé dans :ref:`PlanActions`.
 */
class PlanActionsTrajectoiresCibles extends React.Component {
    constructor(props) {
        super(props);

        let trajectoiresCiblesData = this.initTrajectoire();

        this.state = {
            trajectoiresCiblesData: trajectoiresCiblesData,
            mode: "valeur",
            valeurReference: trajectoiresCiblesData[this.props.id].valeurs[0],
            objectifsAffiches: this.initialiserAffichagesObjectifs(),
            objectifsAffichageInit: true,
        };
    }

    componentDidMount() {
        this.setState({
            ref: this.refs,
        });

        // Gestion des trajectoires cibles (actions utilisateurs)
        this.manageTrajectoiresCibles();

        if (this.props.provenance === "objectifs") {
            this.setState({
                mode: "pourcent",
            });
        }

        // si la trajectoirePcaetAdeme est égale à true on affiche par défaut la trajectoire en filigrane dans les impacts
        if (this.props.trajectoirePcaetAdeme) {
            this.saveTrajectoiresCibles();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.trajectoirePcaetAdeme && !this.props.trajectoirePcaetAdeme) {
            // Cas rencontré lorsqu'on désactive les pcaet ademe sur une trajectoire cible avec les valeurs de pcaet.
            // Reset la trajectoire car on ne souhaite pas que le state savedTrajectoiresCibles soit à true.
            // Par conséquent, la trajectoire cible en filigrane ne sera pas affiché sur les impacts.
            this.saveTrajectoiresCibles("reset");
        }

        if (this.props.dataReference && this.props.dataReference[0]) {
            let nomObjectifsAffiches =
                this.state.objectifsAffiches[
                    this.props.dataReference[0].titre +
                        this.props.dataReference[0].table
                ];
            if (
                nomObjectifsAffiches === undefined ||
                nomObjectifsAffiches === "undefined"
            ) {
                this.setState({
                    objectifsAffiches: this.initialiserAffichagesObjectifs(),
                });
            }
        }

        if (this.state.ref !== prevState.ref) {
            // Patch to enable interactions in the graph
            this.manageTrajectoiresCibles();
        }

        let pcaetChanged =
            JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data);
        let shouldResetData = this.props.shouldResetData && !prevProps.shouldResetData;
        if (pcaetChanged || shouldResetData) {
            // Reset target trajectory
            let trajectoiresCiblesData = this.initTrajectoire();
            this.manageTrajectoiresCibles(trajectoiresCiblesData);
            this.setState({
                trajectoiresCiblesData: trajectoiresCiblesData,
                // MAJ de la valeur de référence pour avoir un pourcentage à jour lorsqu'on revient au PCAET proposé par défaut
                valeurReference: trajectoiresCiblesData[this.props.id].valeurs[0],
            });
        }
    }

    /** Call props.saveTrajectoiresCible with the right values and parameters */
    saveTrajectoiresCibles(reset = false) {
        if (reset) {
            this.props.saveTrajectoiresCibles(false);
            return;
        }
        let trajectoiresCibles = {};
        // TODO: stop using this.props.id everywhere: use raw state.targetTrajectory
        trajectoiresCibles[this.props.id] =
            this.state.trajectoiresCiblesData[this.props.id];
        this.props.saveTrajectoiresCibles(trajectoiresCibles);
    }

    miseAJourGraphiquesAPartirValeursSaisies(
        event,
        pourcent,
        anneeModifiee,
        nbDecimals
    ) {
        /**
        Méthode déclanchée lorsqu'on modifie les valeurs dans le tableau située juste en-dessous des trajectoires cibles.
        Elle permet de mettre à jour les trajectoires cibles à chaque fois qu'on modifie une valeur ou une évolution dans
        le tableau.
        @param {chaine de caractères} refPourcent : référence de la balise dans laquelle on saisit une évolution en pourcentage
        @param {chaine de caractères} refValeur : référence de la balise dans laquelle on saisit une valeur
        @param {booléen} pourcent : Booléen grâce auquel nous savons la valeur saisie est un pourcentage ou une valeur
        @param {chaine de caractères} : Année pour laquelle la valeur de la trajectoire cible est modifiée.
        */
        let index =
            // Recherche de l'index associée à l'année dont on modifie la valeur (nous en avons besoin dans la méthode manageTrajectoiresCibles car on n'utilise pas d3 pour l'obtenir).
            this.state.trajectoiresCiblesData[this.props.id].annees.findIndex(
                (annee) => parseInt(annee) === parseInt(anneeModifiee)
            );

        let valeur = event.target.value;
        let nouvelleValeurGraph = valeur;
        if (this.state.mode !== "pourcent" && pourcent) {
            // Si le mode est « valeur »
            // Mais si on saisit la valeur dans le tableau des évolutions (en d'autres termes on saisit un pourcentage)
            nouvelleValeurGraph = (
                parseFloat(this.state.valeurReference) +
                parseFloat(this.state.valeurReference) * (valeur / 100)
            ).toFixed(nbDecimals); // Calcul de la nouvelle valeur à partir du pourcentage saisi
        } else if (this.state.mode === "pourcent" && !pourcent) {
            // Si le mode est « pourcent »
            // Si la valeur saisie n'est pas un pourcentage
            nouvelleValeurGraph = (valeur * 100) / this.state.valeurReference; // Calcul du pourcentage associé
        }
        if (isNaN(parseFloat(valeur))) {
            // Si la valeur n'est pas convertible en nombre, c'est qu'elle contient une erreur.
            return;
        } else {
            this.manageTrajectoiresCibles(
                this.state.trajectoiresCiblesData,
                parseFloat(nouvelleValeurGraph),
                anneeModifiee,
                this.props.index,
                index
            );
        }
    }

    /**
     * Get the target trajectory from props and do the linear interpolation on it
     */
    initTrajectoire() {
        let data = this.props.data;

        if (this.props.provenance === "objectifs") {
            let trajectoiresCiblesData = {};

            if (data.annees) {
                // Data are already in array form => we keep it like that
                trajectoiresCiblesData[this.props.id] = JSON.parse(
                    JSON.stringify(data)
                );
            } else {
                // Data are in dict form => we extract keys and values arrays
                trajectoiresCiblesData[this.props.id] = {
                    annees: [...Object.keys(data.annees_valeurs)],
                    valeurs: [...Object.values(data.annees_valeurs)],
                    annees_modifiees: [...Object.values(data.annees_modifiees)],
                };
            }
            return trajectoiresCiblesData;
        }

        // Sort the years for which data is defined and get the corresponding values
        const inputYears = Object.keys(data)
            .map((x) => parseInt(x))
            .sort();
        const inputValues = inputYears.map((year) => parseFloat(data[year]));

        let trajectoryData = {
            annees: [],
            valeurs: [],
            annees_modifiees: [],
        };
        const trajectoryLastYear = configData.planActionDerniereAnnee;
        if (inputYears.length > 0) {
            for (let year = inputYears[0]; year <= trajectoryLastYear; year++) {
                trajectoryData.annees.push(year);
                trajectoryData.annees_modifiees.push(year in data);
            }
            trajectoryData.valeurs = this.linearInterpolation(
                inputYears,
                inputValues,
                trajectoryData.annees
            );
        }

        let trajectoiresCiblesData = {};
        trajectoiresCiblesData[this.props.id] = trajectoryData;
        return trajectoiresCiblesData;
    }

    afficherInfoBulle(description, margeHauteFinale) {
        let margeARetrancher = description.length / 2;
        if (margeARetrancher < 45) {
            margeARetrancher = 45;
        }
        let margeFinale = margeHauteFinale - margeARetrancher;
        let style = {
            backgroundColor: "#000000",
            color: "#fff",
            width: "30%",
            minHeight: "20px",
            marginTop: margeFinale + "px",
            marginLeft: "825px",
            borderRadius: "10px",
            position: "absolute",
            zIndex: 9999,
        };
        this.setState({
            descriptionInfoBulle: description,
            style: style,
        });
    }

    desactiverAffichageInfobulle() {
        this.setState({
            descriptionInfoBulle: "",
            style: "",
        });
    }

    initialiserAffichagesObjectifs() {
        let objectifsAffiches = {};
        if (
            this.props.provenance !== "objectifs" &&
            Array.isArray(this.props.dataReference)
        ) {
            for (let a of this.props.dataReference) {
                objectifsAffiches[a.titre + a.table] = true;
            }
        }
        return objectifsAffiches;
    }

    buildLegend(entreeLegende, cle) {
        let styleSpan = {
            background: entreeLegende.couleur,
            display: "block",
            width: "20px",
            height: "5px",
        };
        let coche =
            this.state.objectifsAffiches[entreeLegende.titreBrut + entreeLegende.table];
        if (this.state.objectifsAffichageInit) {
            coche = true;
        }

        let id = (
            "check_trajectory_" +
            entreeLegende.titreBrut +
            entreeLegende.table
        ).replace(" ", "-");
        let desactiverLegende = (
            <div className="legende-modalite">
                <span style={styleSpan} className="legende-modalite"></span>
                <input
                    type="checkbox"
                    defaultChecked={coche}
                    id={id}
                    className="element-legende-modalites"
                    onChange={() =>
                        this.desactiverAffichageObjectif(
                            entreeLegende.titreBrut,
                            entreeLegende.table
                        )
                    }
                ></input>
                <label
                    htmlFor={id}
                    className={"element-legende-modalites marge-element-legende"}
                >
                    {" "}
                    {" " + entreeLegende.titre}
                </label>
            </div>
        );
        return (
            <div
                className="strategy-trajectories-legend"
                onMouseOut={() => this.desactiverAffichageInfobulle()}
                onMouseOver={() => this.afficherInfoBulle(entreeLegende.description)}
            >
                {desactiverLegende}
            </div>
        );
    }

    desactiverAffichageObjectif(titre, graphique) {
        let objectifsAffiches = this.state.objectifsAffiches;
        objectifsAffiches[titre + graphique] =
            !this.state.objectifsAffiches[titre + graphique];
        this.setState({
            objectifsAffiches: objectifsAffiches,
            objectifsAffichageInit: false,
        });
    }

    buildTrajectoireCible() {
        let ui = <div>Trajectoire cible</div>;
        let width = 1250;
        let data = this.props.data;
        let annees = [];
        for (let annee in data) {
            annees.push(parseInt(annee, 10));
        }
        let annee_min = String(Math.min.apply(null, annees));
        let suggestedMin = 0;

        let suggestedMax = data[annee_min] * 1.1;
        if (this.props.options && this.props.options.indexOf("doublemax") !== -1) {
            suggestedMax = data[annee_min] * 2;
        }

        if (this.state.mode === "pourcent") {
            suggestedMax = 100;
            suggestedMin = -100;
        }

        // Et pour chaque graphe proposer soit: saisir en valeur, soit saisie en pourcentage

        let dataSetsTrajectoireCibleParValeur = [];
        // we initialize the datasets styles
        let datasetReference = {
            borderColor: "#444444",
            label: "Valeur de référence",
            borderDash: [5, 5],
            pointRadius: 1,
        };
        let dataset = {
            borderColor: "#aaaaaa",
            label: "Trajectoire cible",
            fill: "origin",
        };
        let datasetHistory = {
            borderColor: "#000000",
            label: "Historique",
        };

        let datasetData = [];
        let datasetHistoricalData = [];
        let datasetDataReference = [];

        let baseRef = "trajectoire-cible-" + this.props.id + "-";
        let tableCells = [];
        let scenarioValeur = (
            <div key={baseRef + "td-valeur-title"} className="cell">
                Valeur
            </div>
        );
        if (this.props.provenance === "objectifs") {
            scenarioValeur = "";
        }
        const addTitleCells = function (baseRef, tableCells) {
            tableCells.push(
                <div key={baseRef + "td-block"} className="block block-title">
                    <div key={baseRef + "td-annee-title"} className="cell">
                        Année
                    </div>
                    {scenarioValeur}
                    <div key={baseRef + "td-pourcent-title"} className="cell">
                        Évolution (%)
                    </div>
                </div>
            );
            return tableCells;
        };

        let impacts_configuration = this.props.parentApi.data.settings["impacts"];
        const absDecimal = impacts_configuration
            .filter((x) => x.type === "target-goal-absolute")
            .map((x) => x.decimal)[0];
        const relDecimal = impacts_configuration
            .filter((x) => x.type === "target-goal-relative")
            .map((x) => x.decimal)[0];

        // Pour chaque année
        if (this.state.trajectoiresCiblesData[this.props.id]) {
            for (
                let a = 0;
                a < this.state.trajectoiresCiblesData[this.props.id].annees.length;
                a++
            ) {
                let annee = this.state.trajectoiresCiblesData[this.props.id].annees[a];
                let valeurTable = parseFloat(
                    this.state.trajectoiresCiblesData[this.props.id].valeurs[a]
                );
                let valeurGraph = valeurTable;
                let valeurGraphPourcent =
                    parseFloat((valeurGraph * 100) / this.state.valeurReference) -
                    100.0;
                if (this.props.provenance === "objectifs") {
                    valeurGraphPourcent = parseFloat(valeurGraph);
                }
                let valeurGraphRef = data[annee_min];
                if (this.state.mode === "pourcent") {
                    valeurGraph = parseFloat(valeurGraphPourcent);
                    valeurGraphRef = 0;
                }
                if (a % 13 === 0) {
                    // ajouter un entête régulièrement
                    tableCells.push(
                        <div
                            key={a + baseRef + "break-block-" + annee}
                            className="block break-block"
                        ></div>
                    );
                    tableCells = addTitleCells(a + baseRef, tableCells);
                }
                datasetData.push({
                    x: new Date(annee + "-01-01"),
                    y: parseFloat(valeurGraph).toFixed(1),
                });
                datasetDataReference.push({
                    x: new Date(annee + "-01-01"),
                    y: parseFloat(valeurGraphRef).toFixed(1),
                });

                let refValeur = baseRef + "td-valeur-" + annee;
                let refPourcent = baseRef + "td-pourcent-" + annee;
                let tabValeur = (
                    <input
                        className="cell cell-val"
                        key={a + refValeur}
                        onChange={(event) =>
                            a > 0
                                ? this.miseAJourGraphiquesAPartirValeursSaisies(
                                      event,
                                      false,
                                      annee,
                                      absDecimal
                                  )
                                : ""
                        }
                        value={parseFloat(valeurTable).toFixed(absDecimal)}
                    />
                );
                if (this.props.provenance === "objectifs") {
                    tabValeur = "";
                }
                tableCells.push(
                    <div key={a + baseRef + "td-block-" + annee} className="block">
                        <div className="cell" key={a + baseRef + "td-annee-" + annee}>
                            {annee}
                        </div>
                        {tabValeur}
                        <input
                            className="cell cell-val"
                            key={a + refPourcent}
                            onChange={(event) =>
                                a > 0
                                    ? this.miseAJourGraphiquesAPartirValeursSaisies(
                                          event,
                                          true,
                                          annee,
                                          relDecimal
                                      )
                                    : ""
                            }
                            value={parseFloat(valeurGraphPourcent).toFixed(relDecimal)}
                        />
                    </div>
                );
            }
        }
        dataset.data = datasetData;
        if (this.props.couleur) {
            dataset.borderColor = this.props.couleur;
        }
        datasetReference.data = datasetDataReference;

        // we add the historical value to the plot
        if (this.props.historyData && Array.isArray(this.props.historyData)) {
            this.props.historyData.forEach((element) => {
                let annee = element["annee"];
                let valeurGraph = parseFloat(element["valeur"]);
                let valeurGraphPourcent =
                    parseFloat((valeurGraph * 100) / this.state.valeurReference) -
                    100.0;
                if (this.props.provenance === "objectifs") {
                    valeurGraphPourcent = parseFloat(valeurGraph);
                }
                if (this.state.mode === "pourcent") {
                    valeurGraph = parseFloat(valeurGraphPourcent);
                }
                datasetHistoricalData.push({
                    x: new Date(annee + "-01-01"),
                    y: parseFloat(valeurGraph).toFixed(1),
                });
            });
        }
        datasetHistory.data = datasetHistoricalData;

        // we add all datasets to current plot
        dataSetsTrajectoireCibleParValeur.push(dataset);
        dataSetsTrajectoireCibleParValeur.push(datasetReference);
        if (this.props.historyData) {
            dataSetsTrajectoireCibleParValeur.push(datasetHistory);
        }
        let listeEntreesLegende = [];
        let anneesDisponibles = Object.keys(this.props.data);

        if (
            this.props.provenance !== "objectifs" &&
            Array.isArray(this.props.dataReference)
        ) {
            for (let a of this.props.dataReference) {
                let dataSetRef = [];
                // la somme des valeurs qui correspond à l'année de réference renseignée
                let valeurRef = parseFloat(a.valeur_reference);

                let yearsToDisplay = a.valeurs_annees;
                if (!a.valeurs_annees.some((y) => y.annee === a.annee_reference)) {
                    yearsToDisplay = [
                        { annee: a.annee_reference, valeur: 0.0 },
                        ...a.valeurs_annees,
                    ];
                }
                for (let c in yearsToDisplay) {
                    let valeur = yearsToDisplay[c].valeur / 100; // les valeurs sont exprimées en pourcentage en BD

                    let annee = yearsToDisplay[c].annee;

                    // calculer la réduction par rapport à la valeur totale de l'année de référence
                    // multiplier la valeur absolue de la réduction entrée par l'utilisateur par la valeur totale de l'année de référence
                    let valeurGraph = valeur * valeurRef + valeurRef;
                    if (this.state.mode === "pourcent") {
                        valeurGraph =
                            (valeurGraph / this.state.valeurReference - 1) * 100;
                    }

                    if (this.props.historyData || annee >= anneesDisponibles[0]) {
                        dataSetRef.push({
                            x: new Date(annee + "-01-01"),
                            y: parseFloat(valeurGraph).toFixed(1),
                        });
                    }
                }

                let affectation = JSON.parse(a.affectation);
                let titre = a.titre + " (" + a.annee_reference + ")";
                let entreeLegende = this.buildLegend(
                    {
                        titre: titre,
                        titreBrut: a.titre,
                        couleur: a.couleur,
                        description: a.description,
                        table: a.table,
                    },
                    a.titre + " - " + a.table
                );
                let ds = {
                    borderColor: a.couleur,
                    backgroundColor: a.couleur,
                    label: titre,
                    borderDash: [5, 5],
                    pointRadius: a.titre.includes("PCAET") ? 3 : 1,
                    order: -1,
                };
                if (this.state.objectifsAffiches[a.titre + a.table]) {
                    ds.data = dataSetRef;
                }
                let modeValeur = this.state.mode !== "pourcent";
                let affectationExiste =
                    affectation[this.props.parentApi.data.zone.zone] ||
                    affectation[this.props.parentApi.data.zone.zone + "s"] === "";
                let correspondanceAffectationAvecTerritoireCourant =
                    affectation[this.props.parentApi.data.zone.zone + "s"] === "" ||
                    affectation[this.props.parentApi.data.zone.zone] ===
                        this.props.parentApi.data.currentZone;
                if (
                    (modeValeur &&
                        affectationExiste &&
                        correspondanceAffectationAvecTerritoireCourant) ||
                    !modeValeur
                ) {
                    dataSetsTrajectoireCibleParValeur.push(ds);
                    listeEntreesLegende.push(entreeLegende);
                }
            }
        }

        let chartDataTrajectionCibleParValeur = {
            datasets: dataSetsTrajectoireCibleParValeur,
        };

        let source_suivi_traj = JSON.parse(
            this.props.parentApi.data.settings["source_suivi_traj"]
        );
        let source = source_suivi_traj["source_impacts"];
        let position_annotation = source_suivi_traj["position_annotation"];
        let lastYearData = this.props.historyData
            ? this.props.historyData[this.props.historyData.length - 1]
            : "2019";
        let firstYear =
            lastYearData !== undefined && lastYearData["annee"]
                ? lastYearData["annee"]
                : "2019";

        let estimatedYears = [];
        let estimatedLegend = "";
        if (
            this.props.parentApi.controller.analysisManager &&
            this.props.dataReference &&
            this.props.dataReference[0]
        ) {
            // TODO: Relying on props.dataReference is problematic, as it won't work if
            // there is none. Could we get the analysis_id / table_name otherwise ?
            estimatedYears =
                this.props.parentApi.controller.analysisManager.getAnalysisEstimatedYears(
                    this.props.parentApi.controller.analysisManager.obtenirIdentifiantAnalyse(
                        this.props.dataReference[0].table
                    )
                );
        }
        if (estimatedYears.length > 0) {
            estimatedLegend = "(e) = données estimées";
        }

        let chartTrajectoireCibleOptions = {
            responsive: false,
            maintainAspectRatio: false,
            plugins: {
                title: {
                    display: true,
                },
                tooltip: {
                    callbacks: {
                        title: function (data) {
                            return new Date(data[0].label).getFullYear();
                        },
                        label: function (data) {
                            return parseFloat(data.raw.y).toFixed(1);
                        },
                    },
                },
                legend: {
                    display: false,
                },
                annotation: this.props.historyData
                    ? {
                          annotations: [
                              {
                                  type: "line",
                                  mode: "vertical",
                                  scaleID: "x-axis-0",
                                  value: new Date(firstYear + "-01-01"),
                                  borderColor: "red",
                                  label: {
                                      xAdjust: position_annotation,
                                      yAdjust: 130,
                                      fontSize: 10,
                                      fontColor: "#444",
                                      fontStyle: "normal",
                                      backgroundColor: "rgba(0,0,0,0.7)",
                                      content: source,
                                      enabled: true,
                                  },
                              },
                              {
                                  type: "line",
                                  mode: "vertical",
                                  scaleID: "x-axis-0",
                                  value: new Date(firstYear + "-01-01"),
                                  borderColor: "red",
                                  label: {
                                      xAdjust: 50,
                                      yAdjust: 130,
                                      fontSize: 10,
                                      fontColor: "#444",
                                      fontStyle: "normal",
                                      backgroundColor: "rgba(0,0,0,0.7)",
                                      content: "Projection",
                                      enabled: true,
                                  },
                              },
                          ],
                      }
                    : {},
            },
            spanGaps: false,
            scales: {
                "x-axis-0": {
                    type: "time",
                    display: true,
                    scaleLabel: {
                        display: true,
                        labelString: "Années",
                    },
                    time: {
                        unit: "year",
                    },
                    ticks: {
                        autoSkip: false,
                        minRotation: 45,
                        source: "data",
                        callback: (value) =>
                            value + (estimatedYears.includes(+value) ? " (e)" : ""),
                    },
                },
                "y-axis-trajectoire": {
                    suggestedMin: suggestedMin,
                    suggestedMax: suggestedMax,
                    display: true,
                    scaleLabel: {
                        display: true,
                        labelString: "Valeurs",
                    },
                },
            },
            width: width,
            height: 450,
        };

        let ref = "ref_chart_trajectoire_cible";
        let chartTrajectoireCibleParValeur = (
            <div className="block-row">
                <Line
                    ref={ref}
                    width={chartTrajectoireCibleOptions.width}
                    height={chartTrajectoireCibleOptions.height}
                    options={chartTrajectoireCibleOptions}
                    data={chartDataTrajectionCibleParValeur}
                />
            </div>
        );
        let unit = " " + this.props.unite;

        if (this.state.mode === "pourcent") {
            unit = " (%)";
        }

        chartTrajectoireCibleOptions.plugins.title.text =
            "Trajectoire cible - " + this.props.title + unit;
        chartTrajectoireCibleOptions.scales["x-axis-0"].scaleLabel.labelString =
            this.props.title + unit;
        // Tableau pour autoriser les changements de valeurs pour une année + récapitulatif des valeurs modifiées
        let tableChanges = (
            <div className="block-row">
                <div className="table-trajectoire-cible">{tableCells}</div>
            </div>
        );

        const onChangeMode = (e) => {
            let currentMode = this.state.mode;
            if (currentMode === "valeur") {
                currentMode = "pourcent";
            } else {
                currentMode = "valeur";
            }
            this.setState({
                mode: currentMode,
            });
        };

        let libelleMode = "Passer en mode valeur";
        if (this.state.mode === "valeur") {
            libelleMode = "Passer en mode %";
        }
        let boutonLibelleMode = (
            <button
                type="button"
                className="btn btn-info block-row"
                onClick={() => onChangeMode()}
            >
                <span>{libelleMode}</span>
            </button>
        );
        if (this.props.provenance === "objectifs") {
            boutonLibelleMode = "";
        }
        let infoBulle = "";

        if (this.state.descriptionInfoBulle) {
            infoBulle = (
                <div style={this.state.style}>
                    <p
                        style={{
                            textAlign: "justify",
                            width: "90%",
                            margin: "10px",
                        }}
                    >
                        {this.state.descriptionInfoBulle}
                    </p>
                </div>
            );
        }

        ui = (
            <div
                ref="trajectoire_cible"
                className="trajectoire-cible options centered-widget corps-tableau-bord"
            >
                <div className="trajectoire-cible-mode">{boutonLibelleMode}</div>
                <div>
                    {infoBulle}
                    <div className="flex-ligne">{chartTrajectoireCibleParValeur}</div>
                    {estimatedLegend}
                    <div className="strategy-trajectories-legends">
                        <div>{listeEntreesLegende}</div>
                    </div>
                </div>
                {tableChanges}
            </div>
        );

        return ui;
    }

    /**
     * Interpolate Y values corresponding to newX depending on known values
     * TODO: also use this function when changing the trajectory
     *
     * @param {Array} knownX X coordinates of known values, sorted in ascending order
     * @param {Array} knownY Y coordinates of known values, array of same length as knownX
     * @param {Array} newX X coordinates of wanted values, sorted in ascending order
     * @returns {Array} Calculated Y values, array of same length as newX
     */
    linearInterpolation(knownX, knownY, newX) {
        const newY = [];
        for (const x of newX) {
            // Find where in the known data, the values to interpolate would be inserted
            const previousKnownIndex =
                knownX.length - 1 - [...knownX].reverse().findIndex((knX) => knX < x);
            const nextKnownIndex = knownX.findIndex((knX) => knX >= x);

            // Special cases outside the known region
            if (previousKnownIndex >= knownX.length) {
                newY.push(knownY[nextKnownIndex]);
                continue;
            }
            if (nextKnownIndex < 0) {
                newY.push(knownY[previousKnownIndex]);
                continue;
            }

            // Calculate the slope of regions that each newX value falls in.
            const xPrevious = knownX[previousKnownIndex];
            const yPrevious = knownY[previousKnownIndex];
            const xNext = knownX[nextKnownIndex];
            const yNext = knownY[nextKnownIndex];
            const slope = (yNext - yPrevious) / (xNext - xPrevious);

            // Calculate the actual value for each entry in newX.
            newY.push(slope * (x - xPrevious) + yPrevious);
        }
        return newY;
    }

    manageTrajectoiresCibles(data, nouvelleValeur, anneeModifiee, datasetIndex, index) {
        let par = {};
        let planActionObject = this;
        let trajectoiresCiblesData = JSON.parse(
            JSON.stringify(planActionObject.state.trajectoiresCiblesData)
        );
        if (data) {
            trajectoiresCiblesData = data;
        }

        let getEventPoints = function (event) {
            var retval = {
                point: [],
                type: event.type,
            };
            // Get x,y of mouse point or touch event
            if (event.type.startsWith("mouse")) {
                // Return x,y of mouse event
                retval.point.push({
                    x: event.layerX,
                    y: event.layerY,
                    clientX: event.clientX,
                    clientY: event.clientY,
                });
            }
            return retval;
        };
        let selectDataOnTrajectoire = function () {
            var e = d3.event.sourceEvent;
            let elements = planActionObject.state.ref[
                "ref_chart_trajectoire_cible"
            ].getElementsAtEventForMode(e, "nearest", { intersect: true }, false);
            par.element = elements.filter((elem) => elem.datasetIndex === 0)[0];

            par.scale = undefined;
            par.chart = undefined;
            if (!par.element) {
                return;
            }

            par.chart =
                planActionObject.state.ref.ref_chart_trajectoire_cible.config._config;
            par.scale =
                planActionObject.state.ref.ref_chart_trajectoire_cible.scales[
                    "y-axis-trajectoire"
                ];
            // Get pixel y-offset from datapoint to mouse/touch point
            par.grabOffsetY =
                par.scale.getPixelForValue(
                    par.chart.data.datasets[par.element.datasetIndex].data[
                        par.element.index
                    ].y,
                    par.element.index
                ) - getEventPoints(e).point[0].clientY;
        };

        let moveDataOnTrajectoire = function () {
            let nouvelleValeurGraph = nouvelleValeur;
            if (
                !isNaN(nouvelleValeur) &&
                nouvelleValeur !== undefined &&
                nouvelleValeur !== false
            ) {
                par.chart =
                    planActionObject.state.ref.ref_chart_trajectoire_cible.config._config;
                par.scale =
                    planActionObject.state.ref.ref_chart_trajectoire_cible.scales[
                        "y-axis-trajectoire"
                    ];
            }
            if (!par.chart) {
                return;
            }
            let anneeSelectionnee = anneeModifiee;
            let curDataset;

            if (!anneeModifiee) {
                curDataset = par.chart.data.datasets[par.element.datasetIndex];
                anneeSelectionnee = curDataset.data[par.element.index].x.getFullYear();
            } else {
                par.element = { index: index, datasetIndex: datasetIndex };
                curDataset = par.chart.data.datasets[datasetIndex];
            }

            // On interdit la modification de la première année (valeur de référence)
            if (
                anneeSelectionnee ===
                trajectoiresCiblesData[planActionObject.props.id].annees[0]
            ) {
                return;
            }

            // On récupère la première année suivante cochée. Si on n'en trouve pas, on prend la max
            let indexAnneeSuivante =
                trajectoiresCiblesData[planActionObject.props.id].annees.length - 1;
            for (
                let a = 0;
                a < trajectoiresCiblesData[planActionObject.props.id].annees.length;
                a++
            ) {
                var annee = parseInt(
                    trajectoiresCiblesData[planActionObject.props.id].annees[a],
                    10
                );
                if (annee > anneeSelectionnee) {
                    if (
                        trajectoiresCiblesData[planActionObject.props.id]
                            .annees_modifiees[a]
                    ) {
                        indexAnneeSuivante = a;
                        break; // Par contre ici le break est nécessaire
                    }
                }
            }

            // On récupère la première année précédente cochée. Si on n'en trouve pas, on prend la min
            let indexAnneePrecedente = 0;
            for (
                let a = 0;
                a < trajectoiresCiblesData[planActionObject.props.id].annees.length;
                a++
            ) {
                annee = parseInt(
                    trajectoiresCiblesData[planActionObject.props.id].annees[a],
                    10
                );
                if (annee < parseInt(anneeSelectionnee, 10)) {
                    if (
                        trajectoiresCiblesData[planActionObject.props.id]
                            .annees_modifiees[a]
                    ) {
                        indexAnneePrecedente = a;
                        // Surtout pas de break ici, car on veut l'année la cochée la plus proche
                    }
                }
            }

            let e;
            let posY = nouvelleValeurGraph;

            if (!anneeModifiee) {
                e = d3.event.sourceEvent;
                posY = par.grabOffsetY + getEventPoints(e).point[0].clientY;
            }

            if (!par.prevY) {
                par.prevY = posY;
            }
            par.prevY = posY;

            // TODO on peut se passer de D3 ?
            // https://stackoverflow.com/questions/48147421/map-event-position-to-y-axis-value-in-chartjs-line-chart

            let mapChart = function (value, start1, stop1, start2, stop2) {
                return (
                    start2 + (stop2 - start2) * ((value - start1) / (stop1 - start1))
                );
            };

            let newVal = nouvelleValeurGraph;
            if (
                nouvelleValeur === false ||
                nouvelleValeur === undefined ||
                isNaN(nouvelleValeur)
            ) {
                var yAxis = par.scale;
                var yValue = mapChart(
                    posY,
                    par.scale.chart.chartArea.bottom,
                    par.scale.chart.chartArea.top,
                    yAxis.min,
                    yAxis.max
                );
                newVal = yValue;
            }
            // Calcul de l'équation affine précédente
            // y = ax + b
            let apr =
                (newVal - parseFloat(curDataset.data[indexAnneePrecedente].y)) /
                (curDataset.data[par.element.index].x -
                    curDataset.data[indexAnneePrecedente].x);
            let bpr =
                parseFloat(curDataset.data[indexAnneePrecedente].y) -
                apr * curDataset.data[indexAnneePrecedente].x;
            // Calcul de l'équation affine suivante
            // y = ax + b
            let asu =
                (parseFloat(curDataset.data[indexAnneeSuivante].y) - newVal) /
                (curDataset.data[indexAnneeSuivante].x -
                    curDataset.data[par.element.index].x);
            let bsu =
                parseFloat(curDataset.data[indexAnneeSuivante].y) -
                asu * curDataset.data[indexAnneeSuivante].x;
            // Application des modifications de valeurs sur toutes les années entre l'année courante et anneePrecedente / anneeSuivante
            for (let a = indexAnneePrecedente + 1; a <= indexAnneeSuivante; a++) {
                anneeSelectionnee = parseInt(anneeSelectionnee, 10);
                annee = parseInt(
                    trajectoiresCiblesData[planActionObject.props.id].annees[a],
                    10
                );
                if (annee < anneeSelectionnee) {
                    curDataset.data[a].y = apr * curDataset.data[a].x + bpr;
                    trajectoiresCiblesData[planActionObject.props.id].valeurs[a] =
                        curDataset.data[a].y;
                    if (
                        planActionObject.state.mode === "pourcent" &&
                        planActionObject.props.provenance !== "objectifs"
                    ) {
                        // Dans le cas du pourcentage, on enregistre quand même des valeurs absolues
                        trajectoiresCiblesData[planActionObject.props.id].valeurs[a] =
                            ((curDataset.data[a].y + 100.0) *
                                planActionObject.state.valeurReference) /
                            100.0;
                    } else if (
                        planActionObject.state.mode === "pourcent" &&
                        planActionObject.props.provenance === "objectifs"
                    ) {
                        trajectoiresCiblesData[planActionObject.props.id].valeurs[a] =
                            curDataset.data[a].y;
                    }
                }
                if (annee > anneeSelectionnee) {
                    curDataset.data[a].y = asu * curDataset.data[a].x + bsu;
                    trajectoiresCiblesData[planActionObject.props.id].valeurs[a] =
                        curDataset.data[a].y;
                    if (
                        planActionObject.state.mode === "pourcent" &&
                        planActionObject.props.provenance !== "objectifs"
                    ) {
                        // Dans le cas du pourcentage, on enregistre quand même des valeurs absolues
                        trajectoiresCiblesData[planActionObject.props.id].valeurs[a] =
                            ((curDataset.data[a].y + 100) *
                                parseFloat(planActionObject.state.valeurReference)) /
                            100;
                    } else if (
                        planActionObject.state.mode === "pourcent" &&
                        planActionObject.props.provenance === "objectifs"
                    ) {
                        trajectoiresCiblesData[planActionObject.props.id].valeurs[a] =
                            curDataset.data[a].y.toFixed(1);
                    }
                }
            }
            // Cas particulier pour les extrémités et la valeur courante
            for (let a in trajectoiresCiblesData[planActionObject.props.id].annees) {
                annee = parseInt(
                    trajectoiresCiblesData[planActionObject.props.id].annees[a],
                    10
                );
                if (annee === parseInt(anneeSelectionnee, 10)) {
                    trajectoiresCiblesData[planActionObject.props.id].valeurs[a] =
                        newVal;
                    trajectoiresCiblesData[planActionObject.props.id].annees_modifiees[
                        a
                    ] = true;
                    if (
                        planActionObject.state.mode === "pourcent" &&
                        planActionObject.props.provenance !== "objectifs"
                    ) {
                        // Dans le cas du pourcentage, on enregistre quand même des valeurs absolues
                        trajectoiresCiblesData[planActionObject.props.id].valeurs[a] =
                            ((newVal + 100) *
                                parseFloat(planActionObject.state.valeurReference)) /
                            100;
                    } else if (
                        planActionObject.state.mode === "pourcent" &&
                        planActionObject.props.provenance === "objectifs"
                    ) {
                        trajectoiresCiblesData[planActionObject.props.id].valeurs[a] =
                            newVal;
                    }
                    curDataset.data[par.element.index].y = newVal;
                    break;
                }
            }
            // Mise à jour du graphique
            par.scale.chart.update(0);
        };

        let endMoveDataOnTrajectoire = function () {
            // Mettre à jour le tableau dans le state
            planActionObject.setState({
                trajectoiresCiblesData: trajectoiresCiblesData,
            });

            planActionObject.saveTrajectoiresCibles();
        };
        if (nouvelleValeur !== false && !isNaN(nouvelleValeur)) {
            moveDataOnTrajectoire();
            endMoveDataOnTrajectoire();
        }

        if (this.state.ref) {
            if (this.state.ref.ref_chart_trajectoire_cible) {
                d3.select(this.state.ref.ref_chart_trajectoire_cible.ctx.canvas).call(
                    d3
                        .drag()
                        .container(
                            this.state.ref.ref_chart_trajectoire_cible.ctx.canvas
                        )
                        .on("start", selectDataOnTrajectoire)
                        .on("drag", moveDataOnTrajectoire)
                        .on("end", endMoveDataOnTrajectoire)
                );
            }
        }
    }

    render() {
        return this.buildTrajectoireCible();
    }
}

export default PlanActionsTrajectoiresCibles;
