/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import { useState } from "react";
import ReactTable from "react-table-6";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-table-6/react-table.css";

import Api from "../../Controllers/Api";
import DetailsPopup from "../DetailsPopup";
import config from "../../settings.js";
import { buildRegionUrl, filterCaseInsensitive } from "../../utils.js";

/**
 * This component is used to edit the perimeter year of a data table ()
 */

function SingleZoneEdition({
    parentApi,
    onSuccess,
    territorialLevels,
    region,
    level,
    maille,
    name,
    additionMode = false,
}) {
    const [errorMessage, setErrorMessage] = useState(false);
    const [changeZoneIsLoading, setChangeZoneIsLoading] = useState(false);
    const [currentName, setName] = useState(name);
    const [currentLevel, setLevel] = useState(level);
    const [currentMaille, setMaille] = useState(maille);

    /**
     * Calls the API to modify the perimeter year of the table
     * @param {*} e
     */
    const handleZoneChange = (e) => {
        e.preventDefault();
        // get value from form input
        setChangeZoneIsLoading(true);
        setErrorMessage("");

        // we select the right URL / params / query method
        let url, params, method;
        if (additionMode) {
            url = buildRegionUrl(config.api_add_zone_url, region);
            params = {
                name: currentName,
                level: currentLevel,
                maille: currentMaille,
            };
            method = "POST";
        } else {
            url = buildRegionUrl(config.api_edit_zone_url, region);
            params = {
                old_name: name,
                name: currentName,
                level: currentLevel,
                maille: currentMaille,
            };
            method = "PUT";
        }
        Api.callApi(url, JSON.stringify(params), method)
            .then((response) => {
                setChangeZoneIsLoading(false);
                // close modal and refetch tables
                onSuccess();
            })
            .catch((e) => {
                setChangeZoneIsLoading(false);
                setErrorMessage(e.message);
            });
    };

    let listOptionsTerritorialLevels = [];
    for (const territorialLevel of territorialLevels) {
        listOptionsTerritorialLevels.push(
            <option key={territorialLevel} value={territorialLevel}>
                {territorialLevel}
            </option>
        );
    }

    return (
        <div className="mt-3">
            <p>Attention, le libellé de la zone doit être unique !</p>
            <form className="mb-3" onSubmit={handleZoneChange}>
                <div className="mb-3">
                    <label htmlFor="edition_zone_name" className="form-label">
                        Libellé
                    </label>
                    <input
                        className="form-control"
                        id="edition_zone_name"
                        value={currentName}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="edition_zone_level" className="form-label">
                        Zone
                    </label>
                    <select
                        className="form-select"
                        id="edition_zone_level"
                        required
                        value={currentLevel}
                        onChange={(e) => {
                            setLevel(e.target.value);
                        }}
                    >
                        {listOptionsTerritorialLevels}
                    </select>
                </div>
                <div className="mb-3">
                    <label htmlFor="edition_zone_division" className="form-label">
                        Maille
                    </label>
                    <select
                        className="form-select"
                        id="edition_zone_division"
                        required
                        value={currentMaille}
                        onChange={(e) => {
                            setMaille(e.target.value);
                        }}
                    >
                        {listOptionsTerritorialLevels}
                    </select>
                </div>

                <button type="submit" className="btn btn-primary">
                    {changeZoneIsLoading && (
                        <>
                            <span
                                className="spinner-border spinner-border-sm me-1"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            <span className="visually-hidden">Loading...</span>
                        </>
                    )}
                    Modifier la zone
                </button>
            </form>
            {errorMessage && (
                <div className="alert alert-danger" role="alert">
                    {errorMessage}
                </div>
            )}
        </div>
    );
}

class ZonesManage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            territorialDataFile: undefined,
            zonesList: undefined,
            territorialLevelsList: undefined,
            anneeCourante: parseInt(new Date().getFullYear(), 10),
            showSingleZoneModal: false,
            singleZoneModalTitle: "",
        };

        this.region = this.props.parentApi.data.region;
    }

    componentDidMount() {
        this.getZonesList();
        this.getTerritorialLevelsList();
    }

    /**
     * Get zones list
     * @param {region} region : region selected name
     */
    getZonesList() {
        this.props.parentApi.controller.zonesManager
            .fetchConfiguration()
            // we update zones list
            .then(() => this.props.parentApi.controller.zonesManager.fetchZones())
            .then(() => {
                this.setState({
                    zonesList: this.props.parentApi.controller.zonesManager.zones,
                });
            });
    }

    /**
     * Get territorial levels list
     * @param {region} region : region selected name
     */
    getTerritorialLevelsList() {
        Api.callApi(
            buildRegionUrl(config.api_territorial_levels_url, this.region)
        ).then((response) => {
            this.setState({
                territorialLevelsList: response,
            });
        });
    }

    changeOrderZone(props, direction) {
        Api.callApi(
            buildRegionUrl(config.api_order_zone_url, this.region),
            JSON.stringify({
                name: props.original.libelle,
                direction: direction === "up" ? "up" : "down",
            }),
            "PUT"
        )
            .then((response) => {
                this.props.parentApi.callbacks.updateMessages(
                    response.message,
                    "success"
                );
                this.getZonesList();
            })
            .catch((e) => this.props.parentApi.callbacks.updateMessages(e.message));
    }

    toggleZone(props) {
        Api.callApi(
            buildRegionUrl(config.api_toggle_zone_url, this.region),
            JSON.stringify({
                name: props.original.libelle,
            }),
            "PUT"
        )
            .then((response) => {
                this.props.parentApi.callbacks.updateMessages(
                    response.message,
                    "success"
                );
                this.getZonesList();
            })
            .catch((e) => this.props.parentApi.callbacks.updateMessages(e.message));
    }

    /**
     * Callback to delete a table
     * @param  {json} table : current row
     */
    deleteZone(props) {
        let r = window.confirm(
            "Attention, cette opération n'est pas réversible. Voulez-vous supprimer cette définition de zone ?"
        );
        if (r !== true) {
            return;
        } else {
            Api.callApi(
                buildRegionUrl(config.api_delete_zone_url, this.region),
                JSON.stringify({
                    name: props.original.libelle,
                }),
                "DELETE"
            )
                .then((response) => {
                    this.props.parentApi.callbacks.updateMessages(
                        response.message,
                        "success"
                    );
                    this.getZonesList();
                })
                .catch((e) => this.props.parentApi.callbacks.updateMessages(e.message));
        }
    }

    openZoneEdition(props) {
        let modalContent = (
            <SingleZoneEdition
                parentApi={this.props.parentApi}
                onSuccess={() => {
                    this.closeSingleZoneEditionPopup();
                    this.getZonesList();
                }}
                region={this.region}
                territorialLevels={this.state.territorialLevelsList}
                level={props.original.nom}
                maille={props.original.maille}
                name={props.original.libelle}
            />
        );
        this.setState({
            showSingleZoneModal: true,
            singleZoneModalTitle: "Modifier une zone existante",
            perimeterYearEdit: modalContent,
        });
    }

    openZoneAddition(props) {
        let modalContent = (
            <SingleZoneEdition
                parentApi={this.props.parentApi}
                onSuccess={() => {
                    this.closeSingleZoneEditionPopup();
                    this.getZonesList();
                }}
                region={this.region}
                territorialLevels={this.state.territorialLevelsList}
                additionMode={true}
                level={"region"}
                maille={"region"}
                name={"Nouvelle zone"}
            />
        );
        this.setState({
            showSingleZoneModal: true,
            singleZoneModalTitle: "Ajouter une nouvelle zone",
            perimeterYearEdit: modalContent,
        });
    }

    closeSingleZoneEditionPopup() {
        this.setState({ showSingleZoneModal: false });
    }

    getZonesTable() {
        const columns = [
            {
                Header: "Libellé affiché",
                accessor: "libelle",
            },
            {
                Header: "Zone",
                accessor: "nom",
            },
            {
                Header: "Maille",
                accessor: "maille",
            },
            {
                id: "action",
                Header: "Action",
                Cell: (props) => {
                    return (
                        <div className="actions-territoire">
                            <div>
                                <button
                                    className={"btn btn-primary ms-1"}
                                    onClick={() => this.openZoneEdition(props)}
                                >
                                    Modifier
                                </button>
                                <button
                                    className={"btn btn-danger ms-1"}
                                    onClick={() => this.deleteZone(props)}
                                >
                                    Supprimer
                                </button>
                            </div>
                            <div>
                                <button
                                    className={
                                        "btn ms-1 btn-" +
                                        (props.original.hide ? "danger" : "success")
                                    }
                                    onClick={() => this.toggleZone(props)}
                                >
                                    {props.original.hide ? "Montrer" : "Cacher"}
                                </button>
                            </div>
                            <div>
                                {props.index > 0 && (
                                    <button
                                        className={"btn ms-1 btn-info"}
                                        onClick={() =>
                                            this.changeOrderZone(props, "up")
                                        }
                                    >
                                        Monter
                                    </button>
                                )}
                                {props.index < this.state.zonesList.length - 1 && (
                                    <button
                                        className={"btn ms-1 btn-info"}
                                        onClick={() =>
                                            this.changeOrderZone(props, "down")
                                        }
                                    >
                                        Descendre
                                    </button>
                                )}
                            </div>
                        </div>
                    );
                },
                filterable: false,
            },
        ];
        return (
            <div className="panel-body user-scenarii">
                <div className="block-etape">
                    <h4>Gestion des zones</h4>
                </div>
                <p>
                    <button
                        className={"btn btn-primary ms-1"}
                        onClick={() => this.openZoneAddition()}
                    >
                        Ajouter une zone
                    </button>
                </p>
                <ReactTable
                    data={this.state.zonesList}
                    columns={columns}
                    className="-striped donnees-territoires"
                    defaultPageSize={30}
                    filterable={true}
                    defaultFilterMethod={(filter, row) =>
                        filterCaseInsensitive(filter, row)
                    }
                    sortable={false}
                />
            </div>
        );
    }

    render() {
        if (
            !this.props.connected ||
            !this.props.userInfos ||
            this.props.userInfos?.profil !== "admin"
        ) {
            return <div>Non accessible.</div>;
        }

        return (
            <div>
                <h3>Gestion des zones disponibles</h3>
                {this.getZonesTable()}
                <p>
                    <input
                        type="checkbox"
                        defaultChecked={
                            this.props.parentApi.data.settings.auto_select_maille
                        }
                        onChange={(event) => {
                            Api.callApi(
                                buildRegionUrl(
                                    config.api_switch_auto_select_maille_url,
                                    this.props.parentApi.data.region
                                ) +
                                    "?value=" +
                                    event.target.checked,
                                undefined,
                                "PUT"
                            );
                        }}
                        id="auto-select-maille"
                    />{" "}
                    <label htmlFor="auto-select-maille">
                        Sélectionner automatiquement la première maille de la liste
                    </label>
                    <br />
                    Si cette case est décochée, c'est l'échelle actuelle qui sera
                    sélectionnée si elle est disponible en tant que maille
                    <br />
                    Merci de recharger la page pour appliquer les modifications
                </p>
                {this.state.showSingleZoneModal && (
                    <DetailsPopup
                        title={this.state.singleZoneModalTitle}
                        content={this.state.perimeterYearEdit}
                        show={this.state.showSingleZoneModal}
                        emptyMsg="Nothing to show"
                        callbackAfterClosing={() => this.closeSingleZoneEditionPopup()}
                    />
                )}
            </div>
        );
    }
}

export default ZonesManage;
