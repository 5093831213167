/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import FormGenerator from "../FormGenerator.js";

import "bootstrap/dist/css/bootstrap.min.css";

import config from "../../settings.js";

export default function Newsletter(props) {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        let options = [];
        if (props.parentApi.data.settings.enable_newsletter_regional) {
            options.push({ value: "regional_newsletter", label: "régional" });
        }
        if (props.parentApi.data.settings.enable_newsletter_national) {
            options.push({ value: "national_newsletter", label: "national" });
        }
        setOptions(options);
    }, [props]);

    let submissionFormFields = [
        {
            id: "prenom",
            label: "Prénom",
            type: "text",
            required: true,
        },
        { id: "nom", label: "Nom", type: "text", required: true },
        {
            id: "mail",
            label: "Mail",
            type: "text",
            required: true,
            // cf. https://www.w3.org/TR/2012/WD-html-markup-20121025/input.email.html#form.data.emailaddress_xref2
            regex: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
        },
        {
            id: "organisation",
            label: "Organisation",
            type: "text",
            required: true,
        },
        {
            id: "fonction",
            label: "Fonction",
            type: "text",
            required: true,
        },
        {
            id: "code_postal",
            label: "Code postal",
            type: "text",
            required: true,
            regex: /^[0-9]{5}$/,
        },
        {
            id: "type_newsletter",
            label: "Type de newsletter",
            type: "multiSelect",
            options: options,
            required: true,
            multiple: true,
        },
        {
            id: "rgpd",
            label: (
                <>
                    En soumettant ce formulaire, je reconnais avoir pris connaissance et
                    accepter{" "}
                    <Link
                        to="/mentions_legales_cgu"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        les mentions légales et les conditions générales d'utilisation
                        du site
                    </Link>
                    . J'accepte également que les informations saisies soient exploitées
                    par {props.parentApi.data.settings.contact_resp_rgpd} pour me
                    recontacter selon les spécifications inscrites dans les mentions
                    légales.
                </>
            ),
            type: "checkbox",
            required: true,
        },
    ];

    return (
        <div className="submission widgets full-screen-widget">
            <Link to={"/" + props.parentApi.data.urlPartageable}>
                <button
                    type="button"
                    className="close close-big"
                    data-dismiss="alert"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </Link>
            <FormGenerator
                parentApi={props.parentApi}
                title="S'inscrire à une newsletter"
                fields={submissionFormFields}
                url={config.newsletter_url}
                rgpd={true}
                region={props.parentApi.data.region}
            />
        </div>
    );
}
