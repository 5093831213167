/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import { Link } from "react-router-dom";

import Auth from "./Auth";

import "bootstrap/dist/css/bootstrap.min.css";

/**
 * This component is used to display the header of the application
 */
class Header extends React.Component {
    render() {
        // if region is not Pays de la Loire, leaves the old version
        if (this.props.parentApi && this.props.parentApi.data.region === "occitanie") {
            let className = "navbar";
            if (this.props.parentApi && this.props.parentApi.data) {
                className += " navbar-" + this.props.parentApi.data.theme;
            }

            return (
                <div className={className}>
                    {!this.props.dataLoaded && <div className="loader"></div>}
                    {this.props.ui_show_login && (
                        <Auth
                            parentApi={this.props.parentApi}
                            history={this.props.history}
                        />
                    )}
                </div>
            );
        }

        let className = "navbar-logo-terristory";
        // if we have a theme
        if (this.props.parentApi && this.props.parentApi.data) {
            className += " navbar-logo-terristory-" + this.props.parentApi.data.theme;
        }

        let textDiffuseur = "";
        let imageDiffuseur = "";
        if (
            this.props.parentApi &&
            this.props.parentApi.data &&
            this.props.parentApi.data.settings &&
            this.props.parentApi.data.settings.footer
        ) {
            // we get the diffuser from the footer
            let settingsFooter = JSON.parse(this.props.parentApi.data.settings.footer);
            if (settingsFooter["left"]) {
                textDiffuseur = settingsFooter["left"][0]["title"];
                let paramsLogos = settingsFooter["left"][0]["logos"][0];
                imageDiffuseur = (
                    <img
                        src={"img/" + paramsLogos.img}
                        title={paramsLogos.title}
                        alt={paramsLogos.title}
                    />
                );
            }
        }

        return (
            <div className="new-navbar">
                <div className="potential-loader d-flex align-items-center justify-content-center">
                    {!this.props.dataLoaded && <div className="loader"></div>}
                </div>
                <Link to="/">
                    <div className={className}></div>
                </Link>
                <div className="navbar-logo-diffuseur">
                    <div>
                        <label>{textDiffuseur}</label>
                        {imageDiffuseur}
                    </div>
                </div>
                <div className="navbar-auth-widget">
                    {this.props.ui_show_login && (
                        <Auth
                            parentApi={this.props.parentApi}
                            history={this.props.history}
                        />
                    )}
                </div>
            </div>
        );
    }
}

export default Header;
