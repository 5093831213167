/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import moment from "moment";

import Api from "../Controllers/Api";
import config from "../settings.js";
import { buildRegionUrl } from "../utils.js";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";

/**
 * This component is used to manage user authentication
 */
class Auth extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loginPopupOn: false,
            status: "",
            connected: false,
            userInfos: undefined,
        };
    }

    componentDidMount() {
        // Check if there is a token stored
        if (localStorage.getItem("access_token") !== "") {
            this.getUserInfo().then(() => {
                if (!this.state.connected) return;
                if (this.state.userInfos.utiliser_territoire_predilection) {
                    this.props.parentApi.callbacks.setZoneFromUserPreferedTerritory(
                        this.state.userInfos.territoire_predilection
                    );
                }
            });
        }
    }

    /**
     * Get infos on current connected user
     */
    async getUserInfo() {
        const authManager = this.props.parentApi.controller.authManager;
        const connected = await authManager.getMe(this.props.parentApi.callbacks);
        if (connected) {
            this.setState({
                userInfos: authManager.userInfos,
                regionUtilisateur: authManager.regionUtilisateur,
                connected: true,
            });
            this.handleTimeOut(authManager.userInfos.exp);
        } else {
            this.setState({
                regionUtilisateur: authManager.regionUtilisateur,
                connected: false,
            });
        }
    }

    /**
     * Disconnect the current user
     */
    disconnect() {
        this.props.parentApi.controller.authManager.disconnect({
            ...this.props.parentApi.callbacks,
            refreshUserID: () =>
                this.props.parentApi.controller.gestionSuiviConsultations.obtenirIdUtilisateur(
                    () => {}
                ),
        });
        this.setState({
            connected: false,
            status: "",
        });
    }

    /**
     * Manage connection time
     */
    handleTimeOut(exp) {
        const warningNotice = 60;
        let expires = moment.unix(exp);
        let now = moment();
        let diff =
            moment(expires, "DD/MM/YYYY HH:mm:ss").diff(
                moment(now, "DD/MM/YYYY HH:mm:ss")
            ) / 1000; // remaining connection time in seconds
        let secondsToAlert = diff - warningNotice;
        setTimeout(() => {
            // Call API to refresh the token, provided the user is still active
            if (this.props.parentApi.data.utilisateurActif) {
                this.refreshConnexion();
            } else {
                let r = window.confirm(
                    "Votre session est arrivée à expiration. Voulez-vous effectuer une reconnexion automatique ?"
                );
                if (r !== true) {
                    // Redirect to home page
                    window.location.reload();
                }
                this.refreshConnexion();
                this.props.parentApi.callbacks.updateUtilisateurActif(true);
            }
        }, secondsToAlert * 1000);
    }

    /**
     * Refresh connexion
     */
    refreshConnexion() {
        Api.callApi(
            buildRegionUrl(config.auth_refresh_url, this.props.parentApi.data.region),
            {},
            "POST"
        )
            .then((response) => {
                // We store the new token
                localStorage.setItem("access_token", response.accessToken);
                this.getUserInfo();
            })
            .catch((e) => this.setState({ status: e.message }));
    }

    render() {
        const handleOpenCloseLoginWidget = (e) => {
            // Open or close the login widget
            this.setState({ loginPopupOn: !this.state.loginPopupOn });
        };

        const handleLogin = (e) => {
            e.preventDefault();
            // Call api to login and get access token
            let login = ReactDOM.findDOMNode(this.refs["login"]).value;
            let password = ReactDOM.findDOMNode(this.refs["password"]).value;
            let region = this.props.parentApi.data.region;
            const body = JSON.stringify({ login, password, region });
            Api.callApi(
                buildRegionUrl(config.auth_url, this.props.parentApi.data.region),
                body,
                "POST"
            )
                .then((json) => {
                    const accessToken = json["access_token"];
                    const refreshToken = json["refresh_token"];
                    localStorage.setItem("access_token", accessToken);
                    localStorage.setItem("refresh_token", refreshToken);

                    this.getUserInfo().then(() => {
                        if (!this.state.connected) {
                            this.setState({
                                status: "Authentification impossible.",
                            });
                            throw new Error("Authentification impossible.");
                        }
                        this.props.parentApi.controller.gestionSuiviConsultations.obtenirIdUtilisateur(
                            () => {}
                        );
                        this.setState({
                            loginPopupOn: false,
                            status: "",
                        });
                        if (this.state.userInfos.utiliser_territoire_predilection) {
                            this.props.parentApi.callbacks.setZoneFromUserPreferedTerritory(
                                this.state.userInfos.territoire_predilection,
                                true
                            );
                        }
                    });
                })
                .catch((error) => {
                    this.setState({ status: "Authentification impossible." });
                    console.error(`${error.message} Code d'erreur ${error.status}`);
                });
        };

        const handleDisconnect = (e) => {
            this.disconnect();
        };

        const handleOpenFormSubmission = (e) => {
            this.setState({
                loginPopupOn: false,
            });
        };

        const handleForgottenPassword = (e) => {
            // Check if login is filled
            let login = ReactDOM.findDOMNode(this.refs["login"]).value;
            if (login === "") {
                this.setState({ status: "Vous devez renseigner un login" });
                return;
            }

            if (
                !window.confirm(
                    `Le mot de passe pour "${login}" va être réinitialisé. Voulez-vous continuer ?`
                )
            ) {
                return;
            }

            // Request a new password
            let url = buildRegionUrl(
                config.user_request_new_password,
                this.props.parentApi.data.region
            );
            const body = JSON.stringify({ mail: login });
            Api.callApi(url, body, "POST")
                .then((response) => {
                    this.setState({
                        status: "Demande prise en compte. Un nouveau mot de passe vous sera envoyé par email.",
                    });
                })
                .catch((e) => this.setState({ status: e.message }));
        };

        const accesPageTableauBord = (e) => {
            this.props.parentApi.callbacks.updateDashboardsList(undefined);
            this.props.parentApi.callbacks.updateAnalysis("creation_tableaux_bord", {
                fromMenu: true,
            });
        };

        // Login form -------------------------------------------------
        let loginPopup = "";
        if (this.state.loginPopupOn) {
            loginPopup = (
                <form className="panel-body user-login-form" onSubmit={handleLogin}>
                    <div className="input">
                        <label>Adresse mail</label>
                        <input type="text" id="login" ref="login" autoComplete="on" />
                    </div>
                    <div className="input">
                        <label>Mot de passe</label>
                        <input
                            type="password"
                            id="password"
                            ref="password"
                            autoComplete="on"
                        />
                    </div>
                    <div>
                        <input
                            type="button"
                            className="tsbtn"
                            style={{ display: "block", marginLeft: "auto" }}
                            onClick={handleForgottenPassword}
                            value="Mot de passe oublié ?"
                        />
                    </div>
                    <div className="buttons">
                        <input
                            type="submit"
                            id="se-connecter"
                            className="tsbtn info"
                            value="Se connecter"
                        />{" "}
                        <Link
                            to="/inscription"
                            className="tsbtn success"
                            onClick={handleOpenFormSubmission}
                        >
                            S'inscrire
                        </Link>
                    </div>
                    {this.state.status && <div>{this.state.status}</div>}
                </form>
            );
        }

        // Login menu -------------------------------------------------
        let labelConnect = "Se connecter";
        if (this.state.connected) {
            labelConnect = "";
        }
        if (this.state.loginPopupOn) {
            labelConnect = "Fermer";
        }
        let menuUser = (
            <div className="user-menu">
                <ul>
                    <li>
                        <button
                            type="button"
                            className="btn-login"
                            onClick={handleOpenCloseLoginWidget}
                        >
                            {labelConnect}
                        </button>
                    </li>
                </ul>
            </div>
        );

        // User menu -------------------------------------------------
        if (this.state.connected) {
            let labelMyAccount = "Mon compte";
            if (this.state.userInfos?.profil === "admin") {
                labelMyAccount = "Administration";
            }

            menuUser = (
                <ul className="user-menu">
                    <li>
                        <Link to="/profile" className="btn-login">
                            {labelMyAccount}
                        </Link>
                    </li>
                    <li>
                        <i
                            className="bi bi-exclamation-diamond"
                            title={
                                'Vous cherchez vos stratégies territoriales ? Elles sont désormais dans l\'onglet "Stratégies Territoriales" du menu de gauche'
                            }
                            style={{ fontSize: 16, margin: 4 }}
                        />
                    </li>
                    <li>
                        <Link
                            to="/gestion_tableaux_bord"
                            className="btn-login"
                            onClick={accesPageTableauBord}
                        >
                            Mes tableaux de bord
                        </Link>
                    </li>
                    <li>
                        <button
                            className="btn-login disconnect-button"
                            onClick={handleDisconnect}
                            title="Se déconnecter"
                        >
                            <span className="bi bi-power"></span>
                        </button>
                    </li>
                </ul>
            );
        }

        // My account / dashboard ----------------------------------------
        let classWidget = " auth-widget-collapsed";
        if (this.state.loginPopupOn) classWidget = "";

        return (
            <div className={"auth-widget" + classWidget}>
                {menuUser}
                {loginPopup}
            </div>
        );
    }
}

export default Auth;
