/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import ReactDOM from "react-dom";
import ReCAPTCHA from "react-google-recaptcha";
import Select from "react-select";
import Api from "../Controllers/Api";
import { ZoneSelect } from "./SelectionObjet";
import { buildRegionUrl, trim } from "../utils.js";
import config from "../settings.js";

import "bootstrap/dist/css/bootstrap.min.css";

/**
 * This component is used to generate forms for user login
 */
class FormGenerator extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            responseError: false,
            mailSent: false,
            sending: false,
            captchaVerified: undefined,
            submitted: undefined,
            isHide: false,
            multiSelectData: [],
        };
    }

    verifyCallbackCaptcha(token) {
        this.setState({
            captchaVerified: token,
        });
    }

    resetCaptcha() {
        window.grecaptcha.reset();
        this.setState({
            captchaVerified: undefined,
        });
    }

    resetForm() {
        document.getElementById("form").reset();
    }

    render() {
        const handleSubmit = (e) => {
            e.preventDefault();
            if (
                this.isValid() &&
                (this.state.captchaVerified || config.DISABLE_CAPTCHA)
            ) {
                let url = `${this.props.url}`;
                let body = undefined;
                let paramsFields = {};
                this.props.fields.forEach((field) => {
                    let value = "";
                    if (field.type === "checkbox") {
                        value = ReactDOM.findDOMNode(this.refs[field.id]).checked;
                    } else if (field.type === "territory") {
                        const territory = this.state["territory-value-" + field.id];
                        value = JSON.stringify(territory);
                    } else if (field.type === "multiSelect") {
                        value = this.state.multiSelectData;
                    } else {
                        value = trim(ReactDOM.findDOMNode(this.refs[field.id]).value);
                    }

                    paramsFields[field.id] = value;
                });
                paramsFields["captcha_token"] = this.state.captchaVerified;

                body = JSON.stringify(paramsFields);

                this.setState({
                    mailSent: false,
                    sending: true,
                    responseError: false,
                    submitted: undefined,
                });
                Api.callApi(buildRegionUrl(url, this.props.region), body, "POST") // this.props.region is the name of the working region embedded in the properties by Suscribe.js
                    .then((json) => {
                        let msg = "Votre demande a bien été envoyée.";
                        if (json.message) msg = json.message;
                        if (json.email_status && json.email_status !== "ok")
                            msg += "\nAttention toutefois : " + json.email_status;

                        this.setState({
                            submitted: msg,
                            responseError: false,
                            mailSent: true,
                            sending: false,
                        });

                        if (this.props.callback) this.props.callback(msg);
                        if (json.email_status === "ok" || json.status === "ok")
                            this.resetForm();
                    })
                    .catch((error) => {
                        this.setState({
                            submitted: error.message,
                            responseError: true,
                            sending: false,
                        });
                    })
                    .finally(() => {
                        this.resetCaptcha();
                    })
                    .then(() => {
                        setTimeout(() => {
                            this.setState({
                                isHide: true,
                            });
                        }, 6000);
                    });
            } else {
                this.setState({
                    submitted:
                        "Vous devez remplir les champs marqués d'une * en respectant le format (mail, code postal...).",
                    responseError: true,
                    isHide: false,
                    sending: false,
                });
            }
        };

        var submitted;
        if (this.state.submitted !== undefined) {
            submitted = (
                <div className="form-group centered-row">
                    <label
                        className={
                            "control-label alert alert-" +
                            (this.state.responseError ? "warning" : "success")
                        }
                    >
                        {this.state.submitted}
                    </label>
                </div>
            );
        }

        let mailSent = "";
        if (this.state.sending && !this.state.responseError) {
            mailSent = <div className="loader centered-widget centered-row"></div>;
        }

        let form = [];
        let userInfos = undefined;
        const authManager = this.props.parentApi.controller.authManager;
        if (
            authManager.connected &&
            authManager.userInfos?.id &&
            !this.state.mailSent
        ) {
            userInfos = authManager.userInfos;
        }
        this.props.fields.forEach((field) => {
            let defaultValue = "";
            if (userInfos !== undefined) {
                defaultValue = userInfos[field.id];
                if (field.id === "mail") defaultValue = userInfos["id"];
            }
            if (field.type === "text")
                form.push(
                    this.renderTextInput(
                        field.id,
                        field.label,
                        field.required,
                        field.className,
                        field.info,
                        defaultValue
                    )
                );
            if (field.type === "textarea")
                form.push(
                    this.renderTextarea(
                        field.id,
                        field.label,
                        field.required,
                        field.className,
                        field.info
                    )
                );
            if (field.type === "checkbox")
                form.push(
                    this.renderCheckbox(
                        field.id,
                        field.label,
                        field.required,
                        field.className,
                        field.info
                    )
                );
            if (["multiSelect", "select"].includes(field.type))
                form.push(
                    this.renderSelect(
                        field.id,
                        field.label,
                        field.options,
                        field.required,
                        field.className,
                        field.info,
                        field.multiple
                    )
                );
            if (field.type === "territory")
                form.push(
                    this.renderTerritorySelect(
                        field.id,
                        field.label,
                        field.required,
                        field.className,
                        field.info
                    )
                );
        });

        let rgpdInfo = (
            <div className="small-links label-alert centered-row">
                Conformément au RGPD, vous disposez d’un droit d’accès, de modification,
                de rectification et de suppression des données qui vous concernent.
                <br />
                Vous pouvez l’exercer en adressant un e-mail à l’adresse suivante :{" "}
                <a href={"mailto:" + this.props.parentApi.data.settings.contact_email}>
                    {this.props.parentApi.data.settings.contact_email}
                </a>
            </div>
        );

        if (!this.props.rgpd) {
            rgpdInfo = "";
        }

        return (
            <div>
                <div className="panel-heading clearfix">
                    <h3 className="panel-title pull-left tstitle">
                        {this.props.title}
                    </h3>
                </div>
                <form id="form" className="tsform" onSubmit={handleSubmit}>
                    {form}
                    <p style={{ fontSize: 14, textAlign: "center", marginBottom: 0 }}>
                        <span className="danger">*Mention obligatoire</span>
                    </p>
                    <ReCAPTCHA
                        sitekey={config.captcha_key}
                        onChange={(t) => this.verifyCallbackCaptcha(t)}
                    />
                    <button type="submit" className="tsbtn info big">
                        Envoyer
                    </button>
                    {mailSent}
                    {!this.state.isHide && submitted}
                </form>
                {rgpdInfo}
            </div>
        );
    }

    isValid() {
        let isValid = true;
        let errors = {};
        this.props.fields.forEach(
            function (field) {
                if (field.required) {
                    if (field.type === "checkbox") {
                        let value = ReactDOM.findDOMNode(this.refs[field.id]).checked;
                        if (!value) {
                            errors[field.id] = "Ce champ est requis";
                            isValid = false;
                        }
                    } else if (field.type === "territory") {
                        let value = this.state["territory-value-" + field.id];
                        if (
                            !value?.zoneType ||
                            !value.zoneMaille ||
                            (value.zoneType !== "region" && !value.zoneId)
                        ) {
                            errors[field.id] = "Ce champ est requis";
                            isValid = false;
                        }
                    } else if (field.type === "multiSelect") {
                        let value = this.state.multiSelectData;
                        if (value.length === 0) {
                            errors[field.id] = "Ce champ est requis";
                            isValid = false;
                        }
                    } else {
                        let value = trim(
                            ReactDOM.findDOMNode(this.refs[field.id]).value
                        );
                        if (!value) {
                            errors[field.id] = "Ce champ est requis";
                            isValid = false;
                        }
                        if (field.regex && !value.match(field.regex)) {
                            errors[field.id] = "Ce champ attend un format particulier";
                            isValid = false;
                        }
                    }
                }
            }.bind(this)
        );
        this.setState({ errors: errors });

        return isValid;
    }

    renderTextInput(id, label, required, className, info, defaultValue) {
        return this.renderField(
            id,
            label,
            <input
                type="text"
                id={id}
                ref={id}
                key={id}
                defaultValue={defaultValue}
                required={required}
            />,
            required,
            className
        );
    }

    renderSelect(id, label, options, required, className, info, multiple) {
        let select = (
            <select id={id} ref={id} key={id}>
                {options.map(function (o) {
                    return (
                        <option value={o} ref={o} key={o}>
                            {o}
                        </option>
                    );
                })}
            </select>
        );
        if (multiple) {
            select = (
                <Select
                    isMulti
                    className="multi-select control-label"
                    id={id}
                    ref={id}
                    key={id}
                    name="newsletter"
                    options={options}
                    placeholder={<div>Sélectionner une valeur</div>}
                    onChange={(e) => this.handleMultiSelect(e)}
                />
            );
        }
        return this.renderField(id, label, select, required, className, info);
    }

    handleMultiSelect(event) {
        let multiSelectData = [];
        event.forEach(function (item) {
            multiSelectData.push(item.value);
        });

        this.setState({
            multiSelectData: multiSelectData,
        });
    }

    renderTextarea(id, label, required, className, info) {
        return this.renderField(
            id,
            label,
            <textarea
                className="form-text-inscription"
                id={id}
                ref={id}
                key={id}
                required={required}
            />,
            required,
            className,
            info
        );
    }

    renderCheckbox(id, label, required, className, info) {
        let star = "";
        if (required) star = <span className="danger">*</span>;
        return (
            <div
                key={id}
                className={
                    "check-input " +
                    (id in this.state.errors ? "has-error " : "") +
                    (className ?? "")
                }
            >
                <input type="checkbox" id={id} ref={id} required={required} />
                <label
                    htmlFor={id}
                    title={id in this.state.errors ? this.state.errors[id] : undefined}
                >
                    {label}
                    {star}
                </label>
                {info && (
                    <>
                        <br />
                        <i className="bi bi-info-circle"></i>
                        <i>{info}</i>
                    </>
                )}
            </div>
        );
    }

    renderField(id, label, field, required, className, info) {
        let star = "";
        if (required) star = <span className="danger">*</span>;
        return (
            <div
                key={id}
                className={
                    "input " +
                    (id in this.state.errors ? "has-error " : "") +
                    (className ?? "")
                }
            >
                <label
                    htmlFor={id}
                    title={id in this.state.errors ? this.state.errors[id] : undefined}
                >
                    {label}
                    {star}
                </label>
                {field}
                {info && (
                    <>
                        <br />
                        <i className="bi bi-info-circle"></i>
                        <i>{info}</i>
                    </>
                )}
            </div>
        );
    }

    renderTerritorySelect(id, label, required, className, info) {
        const preferedTerritory = this.state["territory-value-" + id];

        const updateTerritoryChoice = (territory) => {
            this.setState({ ["territory-value-" + id]: territory });
        };
        return this.renderField(
            id,
            label,
            <div className="multi-select">
                <ZoneSelect
                    parentApi={this.props.parentApi}
                    zoneType={preferedTerritory?.zoneType}
                    zoneMaille={preferedTerritory?.zoneMaille}
                    zoneId={preferedTerritory?.zoneId}
                    onSelect={updateTerritoryChoice}
                    title=""
                    className="filter filter-territory"
                    forIdOnSelect="territoire_predilection"
                />
            </div>,
            required,
            className,
            info
        );
    }
}

export default FormGenerator;
