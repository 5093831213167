/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React, { useEffect } from "react";

/**
 * Popup window with one or several buttons
 *
 * @param {Object} props
 * @param {str} props.title
 * @param {JSX.Element} props.content
 * @param {boolean} props.hideCancel
 * @param {str[]} props.buttons
 * @param {(button: str, event: ClickEvent) => void} props.onConfirm
 * @param {(event: ClickEvent) => void} props.onCancel
 */
function Confirm({
    title,
    content = <></>,
    hideCancel = false,
    buttons = ["OK"],
    onConfirm,
    onCancel,
}) {
    useEffect(() => {
        const dialog = document.getElementById("confirm-dialog");
        dialog.showModal();
    }, []);

    const dialog = (
        <dialog id="confirm-dialog">
            <h3>{title}</h3>
            {content}
            <div className="dialog-buttons">
                {!hideCancel && (
                    <button className="btn btn-secondary" onClick={onCancel}>
                        Annuler
                    </button>
                )}
                {buttons.map((button) => (
                    <button
                        key={button}
                        className="btn btn-primary"
                        name={button}
                        onClick={(e) => onConfirm(button, e)}
                    >
                        {button}
                    </button>
                ))}
            </div>
        </dialog>
    );
    return dialog;
}

export default Confirm;
