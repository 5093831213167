/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import NumberFormat from "react-number-format";

import config from "../../settings.js";
import { consulteAutrePage, createPdfMethodoLink } from "../../utils.js";
import { ExportIndicatorButton } from "../ExportButton.js";

import "bootstrap/dist/css/bootstrap.min.css";

/**
 * Component to display a select input with available years from an analysis parameters.
 *
 * @param {Array} years a list of all available years for this analysis
 * @param {integer} currentYear if years is empty, will contain the value to display
 * @param {integer} yearSelected contains the currently selected year
 * @param {callable} onChangeCallback triggered with new integer value when the select value is changed.
 */
function AnalysisYearsSelection({
    years,
    estimatedYears,
    currentYear,
    yearSelected,
    onChangeCallback,
}) {
    if (years && Array.isArray(years) && years.length > 1) {
        let year = yearSelected ?? years[0];
        return (
            <select
                onChange={(e) => onChangeCallback(parseInt(e.target.value, 10))}
                value={year}
            >
                {years.map((year, i) => {
                    const isEstimated = estimatedYears && estimatedYears.includes(year);
                    if (isEstimated) {
                        return (
                            <option
                                key={"analysis-year-" + year}
                                title="Données estimées pour cette année"
                                className="italic"
                                value={year}
                            >
                                {year} (e)
                            </option>
                        );
                    }
                    return (
                        <option key={"analysis-year-" + year} value={year}>
                            {year}
                        </option>
                    );
                })}
            </select>
        );
    } else if (currentYear) {
        return currentYear;
    } else {
        return "";
    }
}
/**
 * Component to display a select input with available units.
 *
 * @param {Array} units a list of all available units for this analysis
 * @param {integer} singleUnit if units is empty, will contain the value to display
 * @param {integer} unitSelected contains the currently selected unit
 * @param {callable} onChangeCallback triggered with new integer value when the select value is changed.
 */
export function AnalysisUnitSelection({
    units,
    singleUnit,
    zoneName,
    unitSelected,
    onChangeCallback,
    hiddenUnits,
}) {
    if (units && Object.keys(units).length > 0) {
        return (
            <select
                onChange={(e) => onChangeCallback(parseInt(e.target.value, 10))}
                value={unitSelected}
            >
                {units.map((unit) => {
                    // no need to check, hidden units should have the same keys than units
                    if (hiddenUnits[unit.unit_id].split(",").includes(zoneName)) {
                        return "";
                    }
                    return (
                        <option
                            key={"analysis-unit-" + unit.unit_id}
                            value={unit.unit_id}
                        >
                            {unit.unit_name}
                        </option>
                    );
                })}
            </select>
        );
    } else if (singleUnit) {
        return singleUnit;
    } else {
        return "";
    }
}

/**
 * Ce composant permet d'afficher une infobulle.
 */
class Infos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            display: true,
            nomInstallationCourante: props.parentApi.data.nomInstallationCourante,
            nomIndicateurCourant: props.parentApi.data.nomIndicateurCourant,
            indicateurOuInstallation: undefined,
            poiLayersCheckedLabel: [],
            poiLayersCheckedRubrique: [],
            uiTheme: undefined,
            indicateurActif: false,
            analysisSelectedYear: props.parentApi.data.analysisSelectedYear,
            analysisSelectedUnit: props.parentApi.data.analysisSelectedUnit,
        };
        this.toggleDisplay = this.toggleDisplay.bind(this);
    }

    componentDidUpdate(prevProps) {
        let nomInstallationCourante = this.props.parentApi.data.nomInstallationCourante;
        let nomIndicateurCourant = this.props.parentApi.data.nomIndicateurCourant;
        let poiLayers = this.props.parentApi.data.poiLayers;
        let uiTheme = this.props.parentApi.data.uiTheme;
        let indicateurOuInstallation;
        let poiLayerLabel = [];
        let poiLayerRubrique = [];
        let finalUiTheme = false;

        if (prevProps.parentApi.data.poiLayers !== poiLayers) {
            for (let poiLayer of poiLayers) {
                // si un POI est coché on l'ajoute au tableau poiLayerLabel
                if (poiLayer.checked) {
                    poiLayerLabel.push(poiLayer.label);
                    poiLayerRubrique.push(poiLayer.theme);
                }
            }
            this.setState({
                poiLayersCheckedLabel: poiLayerLabel,
                poiLayersCheckedRubrique: poiLayerRubrique,
            });
        }

        // ensemble de conditions qui déterminent le contenu de l'espace "Analyse" dans le titre h1 de la légende
        if (prevProps.parentApi.data.analysis !== this.props.parentApi.data.analysis) {
            indicateurOuInstallation = nomIndicateurCourant;
            // on réinitialise le tableau pour ne pas avoir dans la legende ", ..." et pour ne pas avoir un thème d'installation lorsque le state indicateurActif est égale à true
            this.setState({
                poiLayersCheckedLabel: [],
                indicateurActif: true,
                analysisSelectedYear: undefined,
                analysisSelectedUnit: undefined,
            });
        } else if (
            prevProps.parentApi.data.analysisSelectedYear !==
                this.props.parentApi.data.analysisSelectedYear &&
            this.props.parentApi.data.analysisSelectedYear === undefined
        ) {
            this.setState({
                analysisSelectedYear: undefined,
            });
        } else if (
            prevProps.parentApi.data.analysisSelectedUnit !==
                this.props.parentApi.data.analysisSelectedUnit &&
            this.props.parentApi.data.analysisSelectedUnit === undefined
        ) {
            this.setState({
                analysisSelectedUnit: undefined,
            });
        } else if (
            prevProps.parentApi.data.nomInstallationCourante !==
                nomInstallationCourante &&
            this.state.indicateurActif === false
        ) {
            indicateurOuInstallation = nomInstallationCourante;
            // si la valeur courante de l'installation a été réinitialisée
            if (nomInstallationCourante === undefined) {
                // On prend la première valeur du tableau contenant les noms des POI
                indicateurOuInstallation = poiLayerLabel[0];
                // si le tableau est vide on établit la valeur a undefined
                if (poiLayerLabel.length === 0) {
                    indicateurOuInstallation = undefined;
                }
            }
        } else if (
            prevProps.parentApi.data.nomInstallationCourante ===
                nomInstallationCourante &&
            this.state.indicateurActif === false &&
            poiLayerLabel.length > 0
        ) {
            indicateurOuInstallation = poiLayerLabel[0];
        } else {
            indicateurOuInstallation = undefined;
        }

        // si indicateurOuInstallation est différent de undefined
        if (
            indicateurOuInstallation &&
            indicateurOuInstallation !== this.state.indicateurOuInstallation
        ) {
            this.setState({
                indicateurOuInstallation: indicateurOuInstallation,
            });
        }

        if (
            prevProps.parentApi.data.uiTheme !== uiTheme ||
            poiLayerRubrique.length > 0
        ) {
            if (this.state.indicateurActif === false && poiLayerRubrique.length > 0) {
                // si le uiTheme est égal à undefined alors on sélectionne une valeur de poiLayRubrique
                finalUiTheme = uiTheme || poiLayerRubrique[0];
            } else if (this.state.indicateurActif && poiLayerRubrique.length === 0) {
                finalUiTheme = uiTheme;
            } else if (uiTheme === undefined && poiLayerRubrique.length === 0) {
                // si uiTheme est égal à undefined et que le tableau poiLayRubrique est vide alors on garde la prevProps pour ne pas avoir une valeur égale à undefined côté front
                finalUiTheme = prevProps.parentApi.data.uiTheme;
            }
        }

        if (
            (finalUiTheme &&
                this.state.uiTheme !== finalUiTheme &&
                this.props.parentApi.data.provenance !== "sub_indicator") ||
            (!finalUiTheme && this.state.uiTheme !== uiTheme)
        ) {
            this.setState({
                uiTheme: finalUiTheme ? finalUiTheme : uiTheme,
            });
        }
    }

    toggleDisplay() {
        this.setState({
            display: !this.state.display,
        });
    }

    /**
     * Check we changed year and, if new year is valid (inside available years)
     * for current analysis, will trigger updateSelectedYearAnalysis from parent api.
     *
     * @param {integer} newYear new value for selected year
     */
    updateAnalysisSelectedYear(newYear) {
        if (newYear !== this.state.analysisSelectedYear) {
            let years =
                this.props.parentApi.data &&
                this.props.parentApi.controller.analysisManager
                    ? this.props.parentApi.controller.analysisManager.getAnalysisYears(
                          this.props.parentApi.data.analysis
                      )
                    : [];
            if (years && Array.isArray(years) && years.includes(newYear)) {
                this.setState({
                    analysisSelectedYear: newYear,
                });
                this.props.parentApi.callbacks.updateSelectedYearAnalysis(newYear);
            }
        }
    }

    /**
     * Check we changed unit and, if new unit is valid (inside available units)
     * for current analysis, will trigger updateSelectedUnitAnalysis from parent api.
     *
     * @param {integer} newUnit new value for selected unit
     */
    updateAnalysisSelectedUnit(newUnit) {
        if (newUnit !== this.state.analysisSelectedUnit) {
            let units = Object.keys(
                this.props.parentApi.data?.analysisMeta?.unitsDefaultDetails || {}
            );
            if (units && Array.isArray(units) && units.includes(newUnit.toString())) {
                this.setState({
                    analysisSelectedUnit: newUnit,
                });
                this.props.parentApi.callbacks.updateSelectedUnitAnalysis(newUnit);
            }
        }
    }

    suiviConsultation(text) {
        let region = this.props.parentApi.data.region;
        let idUtilisateur =
            this.props.parentApi.controller.gestionSuiviConsultations.idUtilisateur;
        let url = config.api_consultation_autre_page_url;
        let nomPdf = text;
        consulteAutrePage(
            url,
            region,
            idUtilisateur,
            "Indicateur - PDF méthodologique",
            nomPdf
        );
        return false;
    }

    /**
     * Build html legend for current analysis sources and providers
     */
    buildSource() {
        if (this.props.parentApi.data.analysisMeta) {
            let meta = this.props.parentApi.data.analysisMeta;
            return (
                <div className="row">
                    <div className="content sources-analysis">
                        <DataSource
                            label={
                                meta.creditsDataSources?.length > 1
                                    ? "Sources des données"
                                    : "Source des données"
                            }
                            tableSources={meta.creditsDataSources}
                        />
                        <DataSource
                            label={
                                meta.creditsDataProducers?.length > 1
                                    ? "Producteurs des données"
                                    : "Producteur des données"
                            }
                            tableSources={meta.creditsDataProducers}
                        />
                        <DataSource
                            label={
                                meta.creditsAnalysisProducers?.length > 1
                                    ? "Producteurs de l'indicateur"
                                    : "Producteur de l'indicateur"
                            }
                            tableSources={meta.creditsAnalysisProducers}
                        />
                    </div>
                </div>
            );
        }
        return "";
    }

    switchButtonCategory(category, modalities_filtered) {
        if (modalities_filtered.length === 0) {
            return;
        }
        const currentFilterVal = this.props.parentApi.data.localMapFilter?.[category];
        // we set localMapFilter for current category
        //   1. if empty => set to modalities_filtered[1]
        //      because it would mean we are in default state => modalities_filtered[0] is currently selected
        //   2. if not empty
        //        a. if equals modalities_filtered[1] => set to modalities_filtered[0]
        //        b. else => set to modalities_filtered[1]
        this.props.parentApi.callbacks.updateSingleValueMapFilter(
            currentFilterVal?.[0]?.filtre_categorie === modalities_filtered[1]
                ? modalities_filtered[0]
                : modalities_filtered[1],
            category
        );
    }

    getSwitchButtonCategoryColor(category, options) {
        // we return the color associated with current filter value
        // default (ie. no value) => options[0].color
        return this.props.parentApi.data.localMapFilter?.[category][0]
            .filtre_categorie ===
            category + "." + options[1].label
            ? options[1]?.color ?? "#cccccc"
            : options[0]?.color ?? "#cccccc";
    }

    getCategorySelectionTooltip(category) {
        return this.props.parentApi.data?.analysisMeta?.representationDetails?.[
            "additional_details_text_" + category
        ];
    }

    buildSwitchButton(chart) {
        if (!chart?.data?.datasets?.[0]?.data) {
            return null;
        }
        const category = chart.data.categorie;

        // we create and initialize the options for current switch button
        let options = [];
        for (let i in chart.data.datasets[0].data) {
            if (chart.data.labels[i] === "indisponible") {
                continue;
            }
            options.push({
                label: chart.data.labels[i],
                color: chart.data.datasets[0].backgroundColor[i],
            });
            if (options.length === 2) {
                break;
            }
        }
        if (options.length < 2) return null;

        const tooltipText = this.getCategorySelectionTooltip(category);

        return (
            <div key={"switch-button-" + category} className="category-switch">
                <p>
                    <strong>{chart.data.titre}</strong>
                </p>
                <label
                    key="before"
                    className="switch-labels"
                    htmlFor={"infos-switch-button-" + category}
                    title={tooltipText}
                >
                    {options[0].label}
                </label>
                <label key="button" className="switch">
                    {this.props.parentApi.data.dataLoaded ? (
                        <>
                            <input
                                type="checkbox"
                                defaultChecked={
                                    // we check (ie. we go to right) if current filter value is equal to option[1] (ie. right)
                                    this.props.parentApi.data.localMapFilter?.[
                                        category
                                    ]?.[0]?.filtre_categorie ===
                                    category + "." + options[1].label
                                }
                                onClick={() =>
                                    this.switchButtonCategory(category, [
                                        category + "." + options[0].label,
                                        category + "." + options[1].label,
                                    ])
                                }
                                id={"infos-switch-button-" + category}
                                name={"infos-switch-button-" + category}
                            />
                            <span
                                className="slider round"
                                style={{
                                    backgroundColor: this.getSwitchButtonCategoryColor(
                                        category,
                                        options
                                    ),
                                }}
                            ></span>
                        </>
                    ) : (
                        <>
                            <span
                                className="spinner-border spinner-border-sm me-1"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            <span className="visually-hidden">Loading...</span>
                        </>
                    )}
                </label>
                <label
                    key="after"
                    htmlFor={"infos-switch-button-" + category}
                    className="switch-labels"
                    title={tooltipText}
                >
                    {options?.[1].label}
                </label>
            </div>
        );
    }

    changeCategorySelection(category, modality_filtered) {
        if (!modality_filtered) {
            return;
        }
        // we set localMapFilter for current category
        this.props.parentApi.callbacks.updateSingleValueMapFilter(
            modality_filtered,
            category
        );
    }

    buildSelectionField(chart) {
        if (!chart?.data?.datasets?.[0]?.data) {
            return null;
        }
        const category = chart.data.categorie;

        // we create and initialize the options for current switch button
        let options = [];
        for (let i in chart.data.datasets[0].data) {
            if (chart.data.labels[i] === "indisponible") {
                continue;
            }
            options.push({
                label: chart.data.labels[i],
                color: chart.data.datasets[0].backgroundColor[i],
            });
        }

        const tooltipText = this.getCategorySelectionTooltip(category);

        return (
            <div key={"selection-" + category} className="category-selection">
                <label
                    className="selection-label"
                    htmlFor={"infos-selection-" + category}
                    title={tooltipText}
                >
                    {chart.data.titre}
                </label>
                <select
                    onChange={(e) =>
                        this.changeCategorySelection(category, e.target.value)
                    }
                    id={"infos-selection-" + category}
                    name={"infos-selection-" + category}
                >
                    {options.map((option) => {
                        return (
                            <option
                                key={option.label}
                                value={category + "." + option.label}
                                style={{
                                    color: option.color,
                                    fontWeight: "bold",
                                }}
                                selected={
                                    category + "." + option.label ===
                                    this.props.parentApi.data.localMapFilter?.[
                                        category
                                    ]?.[0]?.filtre_categorie
                                }
                            >
                                {option.label}
                            </option>
                        );
                    })}
                </select>
            </div>
        );
    }

    /**
     * Build html tools to select category when manipulating categories
     * either of switch button or of selection type.
     */
    categoriesSelectionTools() {
        let output = [];
        for (let a of this.props.parentApi.controller.analysisManager.analysis) {
            if (a.id !== this.props.parentApi.data.analysis) {
                continue;
            }
            for (let c = 0; c < a.charts.length; c++) {
                if (!["switch-button", "selection"].includes(a.charts?.[c]?.type)) {
                    continue;
                }
                let data = this.props.parentApi.controller.analysisManager.getDataChart(
                    this.props.parentApi.data.analysis,
                    this.props.parentApi.data.analysis + a.charts[c].categorie
                );

                if (a.charts[c].type === "switch-button") {
                    output.push(this.buildSwitchButton(data));
                } else if (a.charts[c].type === "selection") {
                    output.push(this.buildSelectionField(data));
                }
            }
            break;
        }
        return output;
    }
    render() {
        // Get zone selected
        let res = "";
        let sum = "";
        let zoneInfos = "";
        let source = this.buildSource();

        let zoneName = this.props.parentApi.controller.zonesManager.getZoneName(
            this.props.parentApi.data.currentZone,
            this.props.parentApi.data.zone
        );

        if (this.props.parentApi.data.zone.zone === "region") {
            zoneInfos = this.props.parentApi.data.regionLabel;
        } else {
            zoneInfos = zoneName ? zoneName : "";
        }

        let meta = this.props.parentApi.data.analysisMeta;

        if (
            meta &&
            meta.sum &&
            meta.display_total &&
            this.props.parentApi.data.analysis
        ) {
            sum = meta.sum;
            if (Number(sum) === 0) {
                sum = "";
            }
            if (meta.sum === "confidentiel") {
                sum = "confidentiel";
            }
        }

        if (
            this.props.parentApi.data.infos !== "" ||
            this.props.parentApi.data.analysis !== undefined ||
            this.props.parentApi.data.zone.zone ||
            this.props.parentApi.data.zone.maille
        ) {
            let methodoPdf = "";
            let pdfUrl = "";
            let pdfName =
                this.props.parentApi.controller.analysisManager.getAnalysisMethodoPdf(
                    this.props.parentApi.data.analysis
                );

            if (pdfName) {
                pdfUrl = createPdfMethodoLink(
                    config.methodo_url,
                    this.props.parentApi.data.region,
                    pdfName
                );
                methodoPdf = (
                    <a
                        href={pdfUrl}
                        onClick={() => this.suiviConsultation(pdfName)}
                        title="Méthodologie"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <div className="pdf"></div>
                    </a>
                );
            }

            let classe = "infos float-widget";
            if (
                (meta && meta.dataDeuxiemeRepresentation) ||
                (meta && meta.afficherVersionSimple)
            ) {
                classe = "infos infos-double-width float-widget";
            }

            // Handle years
            let years = [];
            let estimatedYears = [];
            let isSelectedYearEstimated = false;
            let currentYear =
                this.props.parentApi.controller.analysisManager.getAnalysisLastYear(
                    this.props.parentApi.data.analysis
                );
            if (
                this.props.parentApi.data &&
                this.props.parentApi.controller.analysisManager
            ) {
                years =
                    this.props.parentApi.controller.analysisManager.getAnalysisYears(
                        this.props.parentApi.data.analysis
                    );
                estimatedYears =
                    this.props.parentApi.controller.analysisManager.getAnalysisEstimatedYears(
                        this.props.parentApi.data.analysis
                    );
                isSelectedYearEstimated = estimatedYears.includes(
                    this.state.analysisSelectedYear ?? currentYear
                );
            }

            let ratioYearImprecise = false;
            if (
                meta &&
                meta.ratio_best_year &&
                meta.ratio_best_year !== this.state.analysisSelectedYear
            ) {
                ratioYearImprecise = (
                    <div className="row warning-ratio-year">
                        Attention, les données utilisées pour le calcul du ratio sont
                        issues de l'année {meta.ratio_best_year} contrairement aux
                        données absolues qui sont bien dans l'année sélectionnée
                        ci-dessus.
                    </div>
                );
            }

            // Handle categories
            const numberOfThemes =
                (this.state.indicateurActif ? 1 : 0) +
                this.state.poiLayersCheckedRubrique.length;

            const unitsParams =
                this.props.parentApi.controller.analysisManager.getUnitParamsForIndicator(
                    this.state.analysisSelectedUnit,
                    meta,
                    this.props.parentApi.data.zone.maille
                );
            let { unit } = unitsParams;
            const { relevantUnits, defaultUnitForThisZoneType } = unitsParams;

            // Title
            const noteTitle = (
                <>
                    Analyse :{" "}
                    {this.state.indicateurOuInstallation ??
                        this.props.parentApi.controller.analysisManager.getAnalysisName(
                            this.props.parentApi.data.analysis
                        )}
                    {this.state.poiLayersCheckedLabel.length > 1 &&
                        this.state.indicateurActif === false &&
                        ", ..."}
                    <br />
                    {this.state.uiTheme && (
                        <>
                            Thématique : {this.state.uiTheme}
                            {numberOfThemes > 1 && ", ..."}
                            <br style={{ display: "block", marginBottom: "2%" }} />
                        </>
                    )}
                    {zoneInfos !== "" ? "Territoire : " + zoneInfos : ""}
                </>
            );

            let libelleTotal = "Total ";
            if (meta) {
                if (meta && this.props.parentApi.data.analysis) {
                    if (sum === "confidentiel" || sum === "") {
                        unit = "";
                    }
                }
                if (
                    meta.moyennePonderee ||
                    ["accessibilite_emploi", "depenses_carburant_menage"].includes(
                        meta.data_type
                    )
                ) {
                    libelleTotal = "Moyenne ";
                }
            }
            let total_label = "Total : ";
            let total = "";

            if (
                typeof this.props.parentApi.data.zone.maille !== "undefined" &&
                sum !== ""
            ) {
                // Généraliser pour toutes les mailles et résoudre le problème d'affichage
                total_label = libelleTotal + " : ";
                total = (
                    <div className="row">
                        <label>
                            <b>{total_label}</b>{" "}
                            <NumberFormat
                                value={sum}
                                displayType={"text"}
                                thousandSeparator={" "}
                            />{" "}
                            {unit}{" "}
                        </label>
                        <div className="content"></div>
                    </div>
                );
            }

            let donneesExportables = [];
            if (meta) {
                if (meta.sum === "confidentiel") {
                    total = (
                        <div className="row">
                            <label>
                                {total_label} {"confidentiel"}{" "}
                            </label>
                            <div className="content"></div>
                        </div>
                    );
                }

                if (meta.donnees_exportables) {
                    donneesExportables = meta.donnees_exportables.split(",");
                }

                if (["accessibilite_emploi"].includes(meta.data_type)) {
                    currentYear = "Cf. Méthodologie de l'indicateur";
                }
            }
            let exportCSV = "";
            if (
                donneesExportables.indexOf(this.props.parentApi.data.zone.maille) !== -1
            ) {
                exportCSV = (
                    <ExportIndicatorButton
                        parentApi={this.props.parentApi}
                        analysisSelectedYear={this.state.analysisSelectedYear}
                        className="csv csv-float-widget"
                    />
                );
            }

            // potential categories requiring some selection input inside Infos module
            const selectionTools = this.categoriesSelectionTools();

            res = (
                <div className={classe + (this.state.display ? "" : " infos-closed")}>
                    <button
                        type="button"
                        className={
                            this.state.display
                                ? "infos-close-button"
                                : "infos-open-button"
                        }
                        aria-label="Masquer/Afficher"
                        title={(this.state.display ? "Masquer" : "Afficher") + " infos"}
                        onClick={this.toggleDisplay}
                    >
                        {this.state.display && (
                            <div className="panel-close-icon" aria-hidden="true"></div>
                        )}
                    </button>
                    {this.state.display && (
                        <div className="infos-content">
                            <div className="d-flex justify-content-between">
                                <h1
                                    style={{
                                        fontSize: "1em",
                                        fontWeight: "bold",
                                    }}
                                >
                                    {noteTitle}
                                </h1>

                                <div className="d-flex gap-1">
                                    {exportCSV} {methodoPdf}
                                </div>
                            </div>
                            {this.state.indicateurActif && currentYear && (
                                <div className="row">
                                    <label>
                                        <b>Année</b> :{" "}
                                        <AnalysisYearsSelection
                                            years={years}
                                            estimatedYears={estimatedYears}
                                            currentYear={currentYear}
                                            yearSelected={
                                                this.state.analysisSelectedYear
                                            }
                                            onChangeCallback={(e) =>
                                                this.updateAnalysisSelectedYear(e)
                                            }
                                        />
                                        {isSelectedYearEstimated && (
                                            <span
                                                role="tooltip"
                                                title="Données estimées pour cette année, voir fiche méthodologique ci-dessus"
                                                style={{
                                                    fontWeight: "normal",
                                                    width: "auto",
                                                }}
                                            >
                                                (estimée)
                                            </span>
                                        )}
                                    </label>
                                    <div className="content"> </div>
                                </div>
                            )}
                            {Object.keys(relevantUnits).length > 0 && (
                                <div className="row">
                                    <label>
                                        <b>Unité</b> :{" "}
                                        <AnalysisUnitSelection
                                            units={relevantUnits}
                                            hiddenUnits={meta?.unitsHiddenDetails}
                                            singleUnit={unit}
                                            zoneName={
                                                this.props.parentApi.data.zone.maille
                                            }
                                            unitSelected={
                                                this.state.analysisSelectedUnit ??
                                                defaultUnitForThisZoneType
                                            }
                                            onChangeCallback={(e) =>
                                                this.updateAnalysisSelectedUnit(e)
                                            }
                                        />
                                    </label>
                                </div>
                            )}
                            {ratioYearImprecise}
                            {this.state.indicateurActif && total}
                            {source}

                            {selectionTools}
                        </div>
                    )}
                </div>
            );
        }
        return res;
    }
}

/**
 * Renders a sources/providers list for an analysis (or a POI)
 *
 * @prop {String} label Label of the sources list
 * @prop {Array<{name: string, url?: string}>} tableSources List of the sources
 */
export class DataSource extends React.Component {
    render() {
        if (!this.props.tableSources || !this.props.tableSources.length) {
            return null;
        }

        const output = this.props.tableSources.map((element) => {
            // if we have an URL, we display a HTML link
            if (element.url && element.url !== "") {
                let url = element.url.startsWith("http")
                    ? element.url
                    : "http://" + element.url;
                return (
                    <a target="_blank" rel="noreferrer" href={url}>
                        {element.name}
                    </a>
                );
            } else {
                // otherwise, just the text
                return <>{element.name}</>;
            }
        });

        return (
            <div>
                <strong>{this.props.label} :</strong>{" "}
                {output.reduce((previous, current) => (
                    <>
                        {previous}, {current}
                    </>
                ))}
            </div>
        );
    }
}

export default Infos;
