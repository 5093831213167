/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import { Line, Chart } from "react-chartjs-2";
import annotationPlugin from "chartjs-plugin-annotation";

import { buildRegionUrl } from "../../utils.js";
import config from "../../settings.js";
import configData from "../../settings_data.js";
import Api from "../../Controllers/Api.js";

Chart.register(annotationPlugin);

/**
 * This component is used to create a graph with compared curves (energy consumption and production curves)
 */
class CourbesComparees extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            analysisId: String(props.id_analysis),
            representation: "courbes-historiques",
            disabled: false,
            data: {},
            isLoading: false,
        };
        this.graphOptions = undefined;
        this.graphData = undefined;

        this.widthGraph = 750;
        this.heightGraph = 250;
        this.legendPos = "bottom";
        this.heightAnnotationLabel = 40;

        this.updateAnnotations = this.updateAnnotations.bind(this);
        this.getMinMaxYValues = this.getMinMaxYValues.bind(this);
    }

    componentDidMount() {
        this.fetchConfiguration();

        let chartsLegendsDiv = document.getElementsByClassName("charts-legende");
        if (chartsLegendsDiv.length > 0) {
            let widthDiv =
                document.getElementsByClassName("charts-legende")[0].offsetWidth;
            if (this.props.parentApi.data.tailleDiv !== widthDiv) {
                this.props.parentApi.callbacks.tailleDiv(widthDiv);
            }
        }
    }

    /**
     * This function updates the data from which we build charts when changing territory
     * @param {object key => value} prevProps : main component properties before territory change via selection tool
     */
    componentDidUpdate(prevProps) {
        let chartsLegendsDiv = document.getElementsByClassName("charts-legende");
        if (chartsLegendsDiv.length > 0) {
            let widthDiv =
                document.getElementsByClassName("charts-legende")[0].offsetWidth;
            if (
                this.props.parentApi.data.tailleDiv !== widthDiv &&
                this.props.provenance === "tableau_de_bord_restitue"
            ) {
                this.props.parentApi.callbacks.tailleDiv(widthDiv);
            }
        }
        const werePropsFiltersForced =
            this.props.forcedFilters &&
            JSON.stringify(this.props.forcedFilters) !==
                JSON.stringify(prevProps.forcedFilters);
        let currentZone = this.props.zoneId ?? this.props.parentApi.data.currentZone;
        let analysis = this.props.parentApi.data.analysis;
        if (
            (prevProps.zoneId ?? prevProps.parentApi.data.currentZone) !== currentZone
        ) {
            if (analysis !== undefined && analysis !== "") {
                this.fetchConfiguration();
            }
        } else if (werePropsFiltersForced) {
            this.setState({
                filters: {
                    ...this.state.filters,
                    ...this.props.forcedFilters,
                },
            });
        }
    }

    componentWillUnmount() {
        if (this.dataPromise) {
            this.dataPromise.abort();
        }
    }

    fetchConfiguration() {
        if (this.state.isLoading) {
            return;
        }
        this.setState({ isLoading: true });
        // Get data from API
        let pZone =
            "?zone=" + (this.props.zoneType ?? this.props.parentApi.data.zone.zone);
        let pMaille =
            "&maille=" +
            (this.props.zoneMaille ?? this.props.parentApi.data.zone.maille);
        let pZoneId =
            "&zone_id=" + (this.props.zoneId ?? this.props.parentApi.data.currentZone);
        let urlIdUtilisateur =
            "&id_utilisateur=" +
            this.props.parentApi.controller.gestionSuiviConsultations.idUtilisateur;
        if ((this.props.zoneType ?? this.props.parentApi.data.zone.zone) === "region") {
            pZoneId = "&zone_id=" + this.props.parentApi.data.regionCode;
        }
        const representation = this.state.representation;
        const dataSource =
            config.api_analysis_meta_url +
            this.state.analysisId +
            "/graphique/" +
            representation +
            pZone +
            pMaille +
            pZoneId +
            urlIdUtilisateur;
        if (this.dataPromise) this.dataPromise.abort();
        this.dataPromise = Api.callApi(
            buildRegionUrl(dataSource, this.props.parentApi.data.region),
            JSON.stringify({}),
            "POST"
        );
        this.dataPromise
            .then((json) => {
                if (!json) {
                    this.setState({
                        isLoading: false,
                    });
                    return;
                }
                if (json["disabled"] && json["disabled"] === true) {
                    this.setState({
                        disabled: true,
                        isLoading: false,
                    });
                    return;
                }

                // Set states
                this.setState({
                    data: json,
                    isLoading: false,
                });
            })
            .catch((error) => {
                if (error.name === "AbortError") return;
                this.setState({ isLoading: false });
                console.error(error);
            });
    }

    tooltipTitle(infos) {
        return "Évolution sur la période";
    }

    getMinMaxYValues(annotations) {
        // get min and max values for the whole scale
        let scaleYmin = false,
            scaleYmax = false;
        for (const indicatorId in this.state.data) {
            if (
                Object.hasOwnProperty.call(annotations, indicatorId) &&
                annotations[indicatorId].display === false
            ) {
                continue;
            }
            const data = this.state.data[indicatorId];
            for (const year in data.analyse) {
                if (Object.hasOwnProperty.call(data.analyse, year)) {
                    const value = data.analyse[year];
                    if (!scaleYmin || scaleYmin > value) {
                        scaleYmin = value;
                    }
                    if (!scaleYmax || scaleYmax < value) {
                        scaleYmax = value;
                    }
                }
            }
        }
        return { scaleYmin, scaleYmax };
    }

    updateAnnotations(datasets, annotations) {
        const { scaleYmin, scaleYmax } = this.getMinMaxYValues(annotations);
        const heightAnnotationLabelRealVals =
            ((scaleYmax - scaleYmin) * this.heightAnnotationLabel) / this.heightGraph;

        // we retrieve the last year real value
        let yValuesInOrder = [];
        for (const iterator of datasets) {
            const val = iterator.data[iterator.data.length - 1];
            if (val === undefined) continue;
            yValuesInOrder.push({
                val: val,
                indicatorId: iterator.indicatorId,
            });
        }

        // better localization along yAxis
        // we order values and we add them in order from bottom
        yValuesInOrder = yValuesInOrder.sort((a, b) => a.val - b.val);

        let lastVal = false;
        yValuesInOrder.forEach((p) => {
            const { val, indicatorId } = p;
            if (
                !annotations[indicatorId] ||
                annotations[indicatorId].display === false
            ) {
                return;
            }
            // if annotation is not at the right value => reset
            if (annotations[indicatorId].yValue !== val) {
                annotations[indicatorId].yValue = val;
            }
            // if first value we don't change anything
            if (!lastVal) {
                lastVal = val;
            }
            // we already have stacked some
            else {
                // we check that the label below is not too close
                // if it is
                if (val - lastVal < heightAnnotationLabelRealVals) {
                    // we put it at the closest above previous label
                    lastVal += heightAnnotationLabelRealVals;
                    annotations[indicatorId].yValue = lastVal;
                } else {
                    // otherwise we don't change anything
                    lastVal = val;
                }
            }
        });

        return annotations;
    }

    render() {
        if (this.state.disabled === true) {
            return (
                <div className="charts-legende">
                    <div className={"confid-chart"}>
                        Cet indicateur n'est pas activé actuellement, veuillez contacter
                        l'administrateur régional.
                    </div>
                </div>
            );
        }
        if (this.state.isLoading) {
            return (
                <div className="charts-legende">
                    <div className={"loader"}></div>
                </div>
            );
        }

        let unit = "GWh";
        let labels = [];
        let annotations = {};
        let datasets = [];
        let isAnythingConfidential = false;
        if (this.state.data) {
            for (const indicatorId in this.state.data) {
                // we create dataset
                const data = this.state.data[indicatorId];
                let dataset = {
                    indicatorId: indicatorId,
                    label: data.meta.nom,
                    backgroundColor:
                        data.meta.representation_details?.color ?? "darkgray",
                    borderColor: data.meta.representation_details?.color ?? "darkgray",
                    data: [],
                };

                // we create unit
                unit = data.unite;

                // we include all years inside labels/dataset
                let years = Object.keys(data.analyse)
                    .sort()
                    .map((x) => parseInt(x, 10));
                if (data.confidentiel === true) {
                    dataset.label += " (c)";
                    datasets.push(dataset);
                    isAnythingConfidential = true;
                    continue;
                }
                if (years.length === 0) {
                    continue;
                }

                // we store reference year values
                const refYear =
                    this.props.options &&
                    this.props.options.evolutionRefYear &&
                    this.props.options.evolutionRefYear > 1900
                        ? parseInt(this.props.options.evolutionRefYear, 10)
                        : data.evolution.ref_year;
                const maxYear = data.evolution.max_year;

                let maxYearVal = 0;
                let refYearVal = 0;

                years.forEach((year) => {
                    if (Object.hasOwnProperty.call(data.analyse, year.toString())) {
                        const value = data.analyse[year.toString()];
                        dataset.data.push(value);
                        // fix this: if we don't have the same years => problem
                        if (!labels.includes(year.toString())) {
                            labels.push(year.toString());
                        }

                        // we store corresponding values for evolution computation
                        if (data.evolution && year === refYear) {
                            refYearVal = value;
                        }
                        if (data.evolution && year === maxYear) {
                            maxYearVal = value;
                        }
                    }
                });

                if (data.evolution) {
                    // we compute evolution and displayed message
                    const evol =
                        refYearVal === 0
                            ? "? "
                            : Math.round(
                                  ((maxYearVal - refYearVal) / refYearVal) * 100.0
                              );
                    const ref = " par rapport à " + refYear;
                    // we compute position of the annotations
                    const lastValue = dataset.data[dataset.data.length - 1];
                    const xValue = labels[labels.length - 1];

                    // DEPRECATED: if any adjustment was forced through backend
                    const xAdjust = parseFloat(data.evolution?.x_adjust ?? 0);
                    const yAdjust = parseFloat(data.evolution?.y_adjust ?? 0);

                    // we initialize annotations
                    annotations[indicatorId] = {
                        type: "label",
                        xValue: xValue,
                        xAdjust: 5 + xAdjust,
                        yValue: lastValue,
                        yAdjust: -10 + yAdjust,
                        content: [(evol > 0 ? "+" + evol : evol) + "%" + ref],
                        color: data.meta.representation_details?.color ?? "darkgray",
                        backgroundColor: "rgba(150, 150, 150, 0.1)",
                        borderRadius: 5,
                        position: "start",
                        font: {
                            size: 12,
                            weight: "bold",
                        },
                    };
                }
                datasets.push(dataset);
            }

            // we make better positions for annotations to avoid overlapping and
            // too much spacing
            annotations = this.updateAnnotations(datasets, annotations);
        }

        let estimatedYears = [];
        let estimatedLegend = "Années";
        if (this.props.parentApi.controller.analysisManager) {
            estimatedYears =
                this.props.parentApi.controller.analysisManager.getAnalysisEstimatedYears(
                    parseInt(this.props.id_analysis, 10)
                );
        }
        if (estimatedYears.length > 0) {
            estimatedLegend += " ; (e) données estimées";
        }
        if (isAnythingConfidential) {
            estimatedLegend += " ; (c) données confidentielles";
        }

        this.graphData = {
            labels: labels,
            datasets: datasets,
        };

        const defaultLegendClickHandler = Chart.defaults.plugins.legend.onClick;

        let enabledAnnotations = annotations;
        if (
            !this.props.options?.enableEvolution ||
            Object.keys(enabledAnnotations).length === 0
        ) {
            enabledAnnotations = [];
        }

        this.graphOptions = {
            plugins: {
                tooltip: {
                    mode: "index",
                    intersect: false,
                    callbacks: {
                        title: this.tooltipTitle,
                    },
                },
                legend: {
                    maxWidth: 250,
                    labels: {
                        boxWidth: 10,
                        font: {
                            size: 14,
                        },
                        color: "rgb(33, 37, 41)",
                    },
                    position: this.legendPos,
                    align: "start",
                    onClick: (evt, legendItem, legend) => {
                        defaultLegendClickHandler(evt, legendItem, legend);
                        const index = legendItem.datasetIndex;
                        const datasetElement = legend.chart.data.datasets[index];
                        const realId = datasetElement.indicatorId;
                        if (
                            legend.chart.options.plugins.annotation.annotations[realId]
                        ) {
                            legend.chart.options.plugins.annotation.annotations[
                                realId
                            ].display = !legendItem.hidden;
                        }
                        // we update annotations position
                        this.graphOptions.plugins.annotation.annotations =
                            this.updateAnnotations(
                                this.graphData.datasets,
                                this.graphOptions.plugins.annotation.annotations
                            );
                        legend.chart.update();
                    },
                },
                annotation: {
                    clip: false,
                    annotations: enabledAnnotations,
                },
            },
            scales: {
                y: {
                    display: true,
                    beginAtZero: true,
                    title: {
                        display: false,
                        text:
                            this.props.id_analysis === 102
                                ? "Émissions en t"
                                : "Valeurs exprimées en " + unit,
                        color: "rgb(33, 37, 41)",
                        font: {
                            size: 14,
                        },
                    },
                    ticks: {
                        color: "rgb(33, 37, 41)",
                        font: {
                            size: 14,
                        },
                    },
                },
                x: {
                    display: true,
                    title: {
                        display: true,
                        text: estimatedLegend,
                        color: "rgb(33, 37, 41)",
                        font: {
                            size: 14,
                        },
                    },
                    ticks: {
                        font: {
                            size: 14,
                        },
                        color: "rgb(33, 37, 41)",
                        autoSkip: false,
                        source: "labels",
                        callback: function (value) {
                            const label = this.getLabelForValue(value);
                            return (
                                label + (estimatedYears.includes(+label) ? " (e)" : "")
                            );
                        },
                    },
                },
            },
            // responsive: true,
            maintainAspectRatio: false,
            devicePixelRatio: configData.chartsDevicePixelRatio,
            layout: {
                padding: {
                    right: enabledAnnotations ? 190 : 10,
                    top: 40,
                    left: 10,
                },
            },
            elements: {
                point: {
                    radius: 0,
                },
            },
        };
        let barConsoVsPnr = (
            <div className="block-row">
                <Line
                    width={this.widthGraph}
                    height={this.heightGraph}
                    options={this.graphOptions}
                    data={this.graphData}
                />
            </div>
        );
        return <div className="charts-legende">{barConsoVsPnr}</div>;
    }
}

export default CourbesComparees;
