/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import { Line, Chart } from "react-chartjs-2";
import chroma from "chroma-js";
// import NumberFormat from 'react-number-format';
import pattern from "patternomaly";

import Api from "../../Controllers/Api";
import { buildRegionUrl, splitCategoryFilters } from "../../utils.js";
import annotationPlugin from "chartjs-plugin-annotation";

import config from "../../settings.js";
import configData from "../../settings_data.js";

Chart.register(annotationPlugin);

/**
 * This component used to create a graph with stacked curves
 */
class CourbesEmpilees extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id_analyse: String(props.id_analysis),
            representation: this.props.representation ?? "line",
            id: props.type.categorie,
            disabled: false,
            filters: {},
            isLoading: false,
        };

        this.heightAnnotationLabel = 40;
        this.widthGraph = this.props.width;
        this.heightGraph = this.props.height;
    }

    componentDidMount() {
        // Retrieve initial filters
        let filters =
            this.props.filters ??
            this.props.parentApi.controller.analysisManager.initFiltersByCategory(
                parseInt(this.props.id_analysis)
            );
        this.setState({ filters: filters });

        // Get data from API
        this.fetchConfiguration(filters);

        let chartsLegendsDiv = document.getElementsByClassName("charts-legende");
        if (chartsLegendsDiv.length > 0) {
            let widthDiv =
                document.getElementsByClassName("charts-legende")[0].offsetWidth;
            if (
                this.props.parentApi.data.tailleDiv !== widthDiv &&
                this.props.provenance === "tableau_de_bord_restitue"
            ) {
                this.props.parentApi.callbacks.tailleDiv(widthDiv);
            }
        }
    }

    /**
     * This function updates the data from which we build the stacked curves charts when changing territory
     * @param {object key => value} prevProps : main component properties before territory change via selection tool
     */
    componentDidUpdate(prevProps, prevState) {
        let chartsLegendsDiv = document.getElementsByClassName("charts-legende");
        if (chartsLegendsDiv.length > 0) {
            let widthDiv =
                document.getElementsByClassName("charts-legende")[0].offsetWidth;
            if (
                this.props.parentApi.data.tailleDiv !== widthDiv &&
                this.props.provenance === "tableau_de_bord_restitue"
            ) {
                this.props.parentApi.callbacks.tailleDiv(widthDiv);
            }
        }

        let currentZone = this.props.zoneId ?? this.props.parentApi.data.currentZone;
        let filtreCategorieCourant = this.props.parentApi.data.filtreCategorieCourant;
        let numeroIndicateurCourant = this.props.parentApi.data.numeroIndicateur;
        let elemHtml = document.getElementsByClassName("liste-modalites");

        // we find different reasons to retrieve configuration
        const wasUnitUpdated = prevProps.unit !== this.props.unit;
        const wereFiltersUpdated =
            JSON.stringify(this.state.filters) !== JSON.stringify(prevState.filters);
        const wasZoneUpdated =
            (prevProps.zoneId ?? prevProps.parentApi.data.currentZone) !==
                currentZone && this.state.id_analyse;
        const wasCurrentFilterUpdated =
            filtreCategorieCourant !==
                prevProps.parentApi.data.filtreCategorieCourant &&
            numeroIndicateurCourant === this.props.id;
        const werePropsFiltersForced =
            this.props.forcedFilters &&
            JSON.stringify(this.props.forcedFilters) !==
                JSON.stringify(prevProps.forcedFilters);

        if (this.state.dataTraj && prevState.dataTraj) {
            if (
                this.state.dataTraj.codeInseeTerritoire !==
                prevState.dataTraj.codeInseeTerritoire
            ) {
                this.fetchConfiguration();
            }
        }

        if (
            this.props.provenance === "tableau_de_bord" ||
            this.props.provenance === "tableau_de_bord_restitue"
        ) {
            this.props.parentApi.controller.dashboardManager.applyBottomMargin(
                elemHtml
            );
        }

        // we find different reasons to retrieve configuration
        if (
            wasUnitUpdated ||
            wereFiltersUpdated ||
            wasZoneUpdated ||
            wasCurrentFilterUpdated
        ) {
            this.fetchConfiguration(this.state.filters, true);
        } else if (werePropsFiltersForced) {
            this.setState({
                filters: {
                    ...this.state.filters,
                    ...this.props.forcedFilters,
                },
            });
        }
    }

    componentWillUnmount() {
        if (this.dataPromise) {
            this.dataPromise.abort();
            this.props.parentApi.callbacks.updateDataLoaded(true);
        }
    }

    fetchConfiguration(filtres, update = false) {
        if (this.state.isLoading) {
            return;
        }
        this.setState({ isLoading: !update });

        let codeInseeTerritoire =
            this.props.zoneId ?? this.props.parentApi.data.currentZone;
        let pZone =
            "?zone=" + (this.props.zoneType ?? this.props.parentApi.data.zone.zone);
        let pMaille =
            "&maille=" +
            (this.props.zoneMaille ?? this.props.parentApi.data.zone.maille);
        let pZoneId = "&zone_id=" + codeInseeTerritoire;
        if ((this.props.zoneType ?? this.props.parentApi.data.zone.zone) === "region") {
            pZoneId = "&zone_id=" + this.props.parentApi.data.regionCode;
        }
        let unit = this.props?.unit ? "&unit_id=" + this.props.unit : "";
        let id_analyse = this.state.id_analyse;
        let representation = this.state.representation;
        let dataSource =
            config.api_analysis_meta_url +
            id_analyse +
            "/graphique/" +
            representation +
            pZone +
            pMaille +
            pZoneId +
            unit;

        this.props.parentApi.callbacks.updateDataLoaded(false);

        let body = JSON.stringify(filtres);
        if (this.dataPromise) this.dataPromise.abort();
        this.dataPromise = Api.callApi(
            buildRegionUrl(dataSource, this.props.parentApi.data.region),
            body,
            "POST"
        );
        this.dataPromise
            .then((json) => {
                this.props.parentApi.callbacks.updateDataLoaded(true);
                if (!json) {
                    this.setState({
                        isLoading: false,
                    });
                    return;
                }

                if (json["disabled"] && json["disabled"] === true) {
                    this.setState({
                        disabled: true,
                        isLoading: false,
                    });
                    return;
                }

                json["codeInseeTerritoire"] = codeInseeTerritoire;
                if (
                    codeInseeTerritoire ===
                    (this.props.zoneId ?? this.props.parentApi.data.currentZone)
                ) {
                    let categorie = this.props.type.categorie;
                    this.setState({
                        dataTraj: json,
                        data: json["analyse"][categorie] ?? {},
                        isLoading: false,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                }
            })
            .catch((error) => {
                if (error.name === "AbortError") return;
                this.props.parentApi.callbacks.updateDataLoaded(true);
                this.setState({ isLoading: false });
            });
    }

    /**
     * Launches a main component method to add/remove a modality to the filter
     * Triggered when a checkbox associated with a modality is checked
     * @param {chaine de caractère} modalite : modality checked / unchecked
     */
    filtrerSurLegende(event, modalite, categorie) {
        let filterWithTableName = categorie + "." + modalite;
        // if we do have filters
        if (!this.state.filters) return;

        let _filters = JSON.parse(JSON.stringify(this.state.filters));
        // if we do not have current filter
        if (!_filters[categorie]) return;

        // we first retrieve the filter ID if present inside the filters
        const idIfPresent = _filters[categorie].findIndex(
            (element) => element && element.filtre_categorie === filterWithTableName
        );

        // do we have to add or do we have to remove
        if (event.target.checked) {
            // if we couldn't find it, it means we need to add it
            if (idIfPresent === -1) {
                _filters[categorie].push({
                    filtre_categorie: filterWithTableName,
                    type: "pie",
                });
            }
        } else {
            // we are going to delete the filter if we can find it
            if (idIfPresent !== -1) {
                _filters[categorie].splice(idIfPresent, 1);
            }
        }

        // if the action resulted in some changes between previous filters
        // and new filters => we update the state
        if (JSON.stringify(this.state.filters) !== JSON.stringify(_filters)) {
            this.setState({
                filters: _filters,
            });
            if (this.props.provenance === "tableau_de_bord") {
                // we also callback the updated filters in case of dashboard edition
                this.props.parentApi.data.tableauBordDonnees.donnees[
                    this.props.thematique
                ].indicateurs[this.props.id].filters = _filters;
            }
        }
    }

    processConfidentialityColor(ds) {
        let bgColor = undefined;
        let borderColor = undefined;
        if (ds.confidentiel === "oui") {
            // confidential
            bgColor = pattern.draw("diagonal-right-left", "#9e0012");
            borderColor = chroma("#9e0012").darken().alpha(1).css();
        } else {
            bgColor = chroma(ds.couleur).alpha(1).css();
            borderColor = chroma(ds.couleur).darken().alpha(1).css();
        }
        return { bgColor: bgColor, borderColor: borderColor };
    }

    /**
     * Builds the legend with which we can select or delete modalities
     * in the data to be displayed using checkboxes, from modalities by categories.
     * @param {object ke value} chart : data for a chart constructed as follows:
     * {
     *     data : {
     *         confid : Type of confidentiality,
     *         id : indicator identifier,
     *         labels : list of modalities,
     *         datasets : [{
     *             data : List of values in the correct order to match them
     *             to the order of the modalities,
     *             backgroundColor : List of colors in the correct order to match them
     *             to the order of the modalities
     *         }]
     *     }
     * }
     * This array contains other keys that we don't use in this method.
     */
    buildLegend(data, categorie) {
        let identifiant_indicateur = this.state.id_analyse;
        let legende = [];
        let tailleSpan = "10px";
        let taillePoliceModalites = "";
        let hauteurLegendeModalites = "";
        let hauteurLegende = "";
        if (
            this.props.provenance !== "tableau_de_bord" &&
            this.props.provenance !== "tableau_de_bord_restitue"
        ) {
            tailleSpan = "15px";
            taillePoliceModalites = " taille-police-suivi-energetique";
            hauteurLegendeModalites = " legende-modalite-suivi-energetique";
            hauteurLegende = " liste-modalites-suivi-energetique";
        }
        let retourLigneLibelleModalites = "";
        if (this.props.provenance === "tableau_de_bord_restitue") {
            retourLigneLibelleModalites = " legende-modalite-tableau-bord";
        }
        let filtreInitial = this.state.filters;
        let listeModaliteFiltre = [];
        for (let elem of filtreInitial[categorie]) {
            listeModaliteFiltre.push(splitCategoryFilters(elem["filtre_categorie"])[1]);
        }
        for (let i in data[categorie].categories) {
            let checked = false;
            let disabled = false;
            let couleur = data[categorie].categories[i].couleur;
            if (listeModaliteFiltre.indexOf(data[categorie].categories[i].nom) !== -1) {
                checked = true;
            }
            if (data[categorie].categories[i].confidentiel === "oui") {
                disabled = true;
                couleur = "#9e0012";
            }
            legende.push(
                <div
                    className={"legende-modalite" + hauteurLegendeModalites}
                    key={"legende-" + i + "-" + data[categorie].categories[i].nom}
                >
                    <span
                        className="element-legende-modalites"
                        style={{
                            backgroundColor: couleur,
                            display: "block",
                            height: tailleSpan,
                            width: tailleSpan,
                        }}
                    ></span>
                    <input
                        type="checkbox"
                        id={
                            "legende-" +
                            this.props.id +
                            "-" +
                            i +
                            this.state.id +
                            "-" +
                            data[categorie].categories[i].nom
                        }
                        defaultChecked={checked}
                        className="element-legende-modalites"
                        disabled={disabled}
                        onChange={(event) =>
                            this.filtrerSurLegende(
                                event,
                                data[categorie].categories[i].nom,
                                categorie
                            )
                        }
                    ></input>
                    <label
                        htmlFor={
                            "legende-" +
                            this.props.id +
                            "-" +
                            i +
                            this.state.id +
                            "-" +
                            data[categorie].categories[i].nom
                        }
                        className={
                            "element-legende-modalites" +
                            retourLigneLibelleModalites +
                            taillePoliceModalites
                        }
                    >
                        {" "}
                        {data[categorie].categories[i].nom}
                    </label>
                </div>
            );
        }
        legende = legende.reverse();
        return (
            <ul
                className={"liste-modalites" + hauteurLegende}
                key={"legende-finale-" + identifiant_indicateur}
            >
                {legende}
            </ul>
        );
    }

    /**
     * This function returns the total of values by type. Thus, they are formatted
     * in order to be used in the graph where the curves of renewable energy production and final energy
     * consumption are displayed, within the impact results.
     * @param  {object} data : array of data by type, then by year/value
     * @param  {object} liste_annees: list of years for which data is available for consumption and production
     */
    totalParAnnee(donnees, type) {
        let miseEnFormeTotal = {
            backgroundColor: "rgb(255, 255, 255, 0)", // add transparency to avoid the option "fill : false"
            borderColor: "rgba(0, 0, 0, 0)",
            label: "",
            yAxisID: "y-axis-trajectoire",
            borderWidth: 2,
            order: 2,
        };

        if (donnees) {
            let liste_annees = [];
            if (donnees[type].traj[0] && donnees[type].traj !== "Confidentiel") {
                for (let j in donnees[type].traj[0].data) {
                    if (
                        donnees[type].traj[0].data[j]["annee"] >=
                            configData.anneeMin - 1 &&
                        (this.props.provenance === "tableau_de_bord" ||
                            this.props.provenance === "tableau_de_bord_restitue")
                    ) {
                        liste_annees.push(donnees[type].traj[0].data[j]["annee"]); // generate the list of data to be entered as parameters of dataForConsoVsProd
                    } else if (this.props.provenance === "suivi_energetique") {
                        liste_annees.push(donnees[type].traj[0].data[j]["annee"]);
                    }
                }
                let dataSet = [];
                for (let annee in liste_annees) {
                    // For each year
                    let v = 0;
                    for (let i in donnees[type].traj) {
                        // We browse each modality (arbitrarily by type of energy because common to the production and consumption)
                        for (let k in donnees[type].traj[i].data) {
                            // We browse each row of data {year: value}
                            if (
                                donnees[type].traj[i].data[k].annee ===
                                liste_annees[annee]
                            ) {
                                // If the year matches then
                                v += parseFloat(
                                    donnees[type].traj[i].data[k].valeur.toFixed(2)
                                ); // We sum all the types to calculate the total
                            }
                        }
                    }
                    dataSet.push({
                        x: new Date(parseInt(liste_annees[annee], 10) + "-01-01"),
                        y: parseFloat(v.toFixed(2)),
                    });
                }
                let donneesFinales = { dataset: dataSet, labels: liste_annees }; // We transmit the data in the correct format
                miseEnFormeTotal.data = donneesFinales.dataset;
                return miseEnFormeTotal;
            }
        }
    }

    getMinMaxYValues(dataByYear) {
        // get min and max values for the whole scale
        let scaleYmin = false,
            scaleYmax = false;
        for (const year in dataByYear) {
            if (Object.hasOwnProperty.call(dataByYear, year)) {
                const value = dataByYear[year];
                if (!scaleYmin || scaleYmin > value) {
                    scaleYmin = value;
                }
                if (!scaleYmax || scaleYmax < value) {
                    scaleYmax = value;
                }
            }
        }
        scaleYmin = 0.0;
        return { scaleYmin, scaleYmax };
    }

    updateAnnotations(datasets, annotations) {
        // we create total by year
        let dataByYear = {},
            dataByCat = {};
        for (const catData of this.state.data.traj) {
            if (
                Object.hasOwnProperty.call(annotations, catData.nom) &&
                annotations[catData.nom].display === false
            ) {
                continue;
            }
            // we save real y values (as curves are stacked)
            dataByCat[catData.nom] = {};
            const data = catData.data;
            for (const yearData of data) {
                const value = yearData.valeur;
                const year = yearData.annee;
                if (!dataByYear[year]) {
                    dataByYear[year] = 0.0;
                }
                dataByYear[year] += value;
                dataByCat[catData.nom][year] = dataByYear[year];
            }
        }

        // we get min / max
        const { scaleYmin, scaleYmax } = this.getMinMaxYValues(dataByYear);
        // we compute min vertical distance between two evolution annotations
        const heightAnnotationLabelRealVals =
            ((scaleYmax - scaleYmin) * this.heightAnnotationLabel) / this.heightGraph;

        // we retrieve the last year real value
        let yValuesInOrder = [];
        for (const cat in dataByCat) {
            // we get last year value
            const maxYear = Math.max(...Object.keys(dataByCat[cat]));
            const val = dataByCat[cat][maxYear];
            if (val === undefined) continue;
            yValuesInOrder.push({
                val: val,
                cat: cat,
            });
        }

        // better localization along yAxis
        // we order values and we add them in order from bottom
        yValuesInOrder = yValuesInOrder.sort((a, b) => a.val - b.val);

        let lastVal = false;
        yValuesInOrder.forEach((p) => {
            const { val, cat } = p;
            if (!annotations[cat] || annotations[cat].display === false) {
                return;
            }
            // if annotation is not at the right value => reset
            if (annotations[cat].yValue !== val) {
                annotations[cat].yValue = val;
            }
            // if first value we don't change anything
            if (!lastVal) {
                lastVal = val;
            }
            // we already have stacked some
            else {
                // we check that the label below is not too close
                // if it is
                if (val - lastVal < heightAnnotationLabelRealVals) {
                    // we put it at the closest above previous label
                    lastVal += heightAnnotationLabelRealVals;
                    annotations[cat].yValue = lastVal;
                } else {
                    // otherwise we don't change anything
                    lastVal = val;
                }
            }
        });

        return annotations;
    }

    render() {
        if (this.state.disabled === true) {
            return (
                <div className="charts-legende">
                    <div className={"confid-chart"}>
                        Cet indicateur n'est pas activé actuellement, veuillez contacter
                        l'administrateur régional.
                    </div>
                </div>
            );
        }

        if (this.state.isLoading) {
            return (
                <div className="charts-legende">
                    <div className={"loader"}></div>
                </div>
            );
        }

        let consoData = {};
        let uniteConso = "";
        let categorie = this.props.type.categorie;
        let titre = "";
        if (this.props.type) {
            titre = this.props.type.titre;
        }
        let nomIndicateur =
            this.props.parentApi.controller.analysisManager.getAnalysisName(
                parseInt(this.state.id_analyse, 10)
            );
        let legendeFinale = "";
        let datasets = [];
        let estimatedRatioYearsLegend = "";
        let annotations = {};

        if (this.state.dataTraj && categorie) {
            if (
                this.state.dataTraj["analyse"][categorie] &&
                this.state.dataTraj["analyse"][categorie].traj &&
                this.state.dataTraj["analyse"][categorie].traj !== "Confidentiel"
            ) {
                legendeFinale = this.buildLegend(
                    this.state.dataTraj.analyse,
                    categorie
                );
                let tot = this.totalParAnnee(this.state.dataTraj.analyse, categorie);
                if (
                    this.state.dataTraj["analyse"][categorie] &&
                    this.state.dataTraj["analyse"][categorie].traj
                ) {
                    const categoryData = this.state.dataTraj["analyse"][categorie];
                    for (let ds of categoryData.traj) {
                        let colors = this.processConfidentialityColor(ds);
                        let dataset = {
                            backgroundColor: colors.bgColor,
                            borderColor: colors.borderColor,
                            label: ds.nom,
                            fill: { target: "origin", above: colors.bgColor },
                        };

                        const isEvolutionEnabled =
                            this.props.provenance !== "suivi_energetique" &&
                            categoryData.evolution;

                        // we store reference year values
                        const refYear = parseInt(
                            this.props.options &&
                                this.props.options.evolutionRefYear &&
                                this.props.options.evolutionRefYear > 1900 // we would like to have "realistic years"
                                ? this.props.options.evolutionRefYear
                                : categoryData.evolution.ref_year,
                            10
                        );
                        let maxYear = categoryData.evolution.max_year;
                        let maxYearVal = 0;
                        let refYearVal = 0;

                        let datasetData = [];
                        for (let d of ds.data) {
                            if (
                                d.annee >= configData.anneeMin - 1 &&
                                (this.props.provenance === "tableau_de_bord" ||
                                    this.props.provenance ===
                                        "tableau_de_bord_restitue")
                            ) {
                                datasetData.push({
                                    x: new Date(d.annee + "-01-01"),
                                    y: d.valeur,
                                });
                            } else if (this.props.provenance === "suivi_energetique") {
                                datasetData.push({
                                    x: new Date(d.annee + "-01-01"),
                                    y: d.valeur,
                                });
                            }

                            // we store corresponding values for evolution computation
                            if (
                                isEvolutionEnabled &&
                                parseInt(d.annee, 10) === refYear
                            ) {
                                refYearVal = d.valeur;
                            }
                            if (
                                isEvolutionEnabled &&
                                parseInt(d.annee, 10) === maxYear
                            ) {
                                maxYearVal = d.valeur;
                            }
                        }

                        if (isEvolutionEnabled) {
                            // we compute evolution and displayed message
                            const evol =
                                refYearVal === 0
                                    ? ""
                                    : Math.round(
                                          ((maxYearVal - refYearVal) / refYearVal) *
                                              100.0
                                      );
                            const ref = " par rapport à " + refYear;
                            // we compute position of the annotations
                            const lastValue = datasetData[datasetData.length - 1].y;
                            const xValue = datasetData[datasetData.length - 1].x;

                            // DEPRECATED: if any adjustment was forced through backend
                            const xAdjust = parseFloat(
                                categoryData.evolution?.x_adjust ?? 0
                            );
                            const yAdjust = parseFloat(
                                categoryData.evolution?.y_adjust ?? 0
                            );

                            // we initialize annotations
                            annotations[ds.nom] = {
                                type: "label",
                                xValue: xValue,
                                xAdjust: 5 + xAdjust,
                                yValue: lastValue,
                                yAdjust: -10 + yAdjust,
                                content: [(evol > 0 ? "+" + evol : evol) + "%" + ref],
                                color: colors.bgColor ?? "darkgray",
                                backgroundColor: "rgba(150, 150, 150, 0.1)",
                                borderRadius: 5,
                                position: "start",
                                font: {
                                    size: 12,
                                    weight: "bold",
                                },
                            };
                        }

                        dataset.data = datasetData;
                        datasets.push(dataset);
                        consoData[categorie] = {
                            datasets: datasets,
                        };
                        uniteConso = this.state.dataTraj["unite"];
                    }
                }
                if (tot) {
                    consoData[categorie].datasets.unshift(tot);
                }
                annotations = this.updateAnnotations(datasets, annotations);
            }

            if (this.state.dataTraj["analyse"][categorie]) {
                const { estimated_years_for_ratio } =
                    this.state.dataTraj["analyse"][categorie];
                if (estimated_years_for_ratio && estimated_years_for_ratio.length) {
                    let yearLegends = estimated_years_for_ratio.map(
                        (yearEstimation) =>
                            yearEstimation.original + " par " + yearEstimation.used
                    );
                    if (yearLegends.length > 0) {
                        yearLegends = yearLegends.join(", ");
                        estimatedRatioYearsLegend = `Attention ! Les années suivantes ont été estimées pour le calcul de ratio: ${yearLegends}.`;
                    }
                }
            }
        }

        if (consoData[categorie]) {
            consoData[categorie].datasets[0].label = "Total";
        }

        let estimatedYears = [];
        let estimatedLegend = "";
        if (this.props.parentApi.controller.analysisManager) {
            estimatedYears =
                this.props.parentApi.controller.analysisManager.getAnalysisEstimatedYears(
                    parseInt(this.props.id_analysis, 10)
                );
        }
        if (estimatedYears.length > 0) {
            estimatedLegend = " ; (e) = données estimées";
        }
        let enabledAnnotations = annotations;
        if (
            !this.props.options?.enableEvolution ||
            Object.keys(enabledAnnotations).length === 0
        ) {
            enabledAnnotations = false;
        }

        let titreAxeOrdonnees = nomIndicateur + " en " + uniteConso;
        let chartOptionsSuivi = {
            maintainAspectRatio: true,
            spanGaps: false,
            scales: {
                x: {
                    type: "time",
                    display: true,
                    title: {
                        display: true,
                        text: "Années" + estimatedLegend,
                        font: {
                            size: 16,
                        },
                    },
                    time: {
                        unit: "year",
                    },
                    ticks: {
                        autoSkip: false,
                        minRotation: 55,
                        source: "data",
                        font: {
                            size: 16,
                        },
                        callback: (value) =>
                            value + (estimatedYears.includes(+value) ? "(e)" : ""),
                    },
                },
                y: {
                    stacked: true,
                    display: true,
                    ticks: {
                        font: {
                            size: 16,
                        },
                    },
                    title: {
                        display: true,
                        text: titreAxeOrdonnees,
                        font: {
                            size: 16,
                        },
                        marginRight: 20,
                        paddingLeft: 20,
                    },
                    beginAtZero: true,
                },
                "y-axis-trajectoire": {
                    type: "linear",
                    display: false,
                    position: "right",
                    grid: {
                        drawOnChartArea: false,
                    },
                    beginAtZero: true,
                },
            },
            hover: {
                mode: "single",
            },
            plugins: {
                title: {
                    display: true,
                    text: titre,
                    padding: 5,
                    font: {
                        size: 16,
                    },
                },
                legend: {
                    position: annotations.length > 0 ? "left" : "right",
                    display: false,
                    labels: {
                        font: {
                            size: 16,
                        },
                        boxWidth: 10,
                    },
                    reverse: true,
                    // onClick NOT NEEDED here as legend is made outside of curve
                },
                tooltip: {
                    reverse: true,
                    mode: "x",
                    intersect: false,
                    callbacks: {
                        title: function (data) {
                            data[0].dataset.label = "Total";
                            return new Date(data[0].label).getFullYear();
                        },
                        label: function (tooltipItems) {
                            return (
                                tooltipItems.dataset.label +
                                ": " +
                                new Intl.NumberFormat("fr-FR").format(
                                    tooltipItems.raw.y
                                )
                            );
                        },
                    },
                },

                annotation: {
                    clip: false,
                    annotations: enabledAnnotations,
                },
            },
            layout: {
                padding: {
                    right: enabledAnnotations ? 190 : 10,
                    top: 40,
                    left: 10,
                },
            },

            width: this.props.width,
            height: this.props.height,
            devicePixelRatio: configData.chartsDevicePixelRatio,
            elements: {
                point: {
                    radius: 0,
                },
            },
        };

        let chartEvolConsoSecteur = "";

        if (this.state.dataTraj) {
            if (
                this.state.dataTraj["analyse"][categorie] &&
                this.state.dataTraj["analyse"][categorie].traj !== "Confidentiel"
            ) {
                chartEvolConsoSecteur = (
                    <div>
                        <div className="block-row">
                            <Line
                                width={chartOptionsSuivi.width}
                                height={chartOptionsSuivi.height}
                                options={chartOptionsSuivi}
                                data={consoData[categorie]}
                            />
                        </div>
                        {estimatedRatioYearsLegend ? (
                            <p className="warning-ratio-year">
                                {estimatedRatioYearsLegend}
                            </p>
                        ) : (
                            ""
                        )}
                    </div>
                );
            } else if (
                this.state.dataTraj["analyse"][categorie] &&
                this.state.dataTraj["analyse"][categorie].traj === "Confidentiel"
            ) {
                chartEvolConsoSecteur = (
                    <div className={"confid-chart"}>Données confidentielles</div>
                );
            } else if (!this.state.dataTraj["analyse"][categorie]) {
                chartEvolConsoSecteur = (
                    <div className={"confid-chart"}>
                        Catégorie non disponible pour cet indicateur, veuillez contacter
                        l'administrateur régional.
                    </div>
                );
            }
        }

        return (
            <div>
                <div className="charts-legende" style={{ width: "101%" }}>
                    {chartEvolConsoSecteur}
                    <div className="choisir-methode elem-courbes-empilees">
                        {legendeFinale}
                    </div>
                </div>
            </div>
        );
    }
}

export default CourbesEmpilees;
