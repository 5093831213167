/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import { Bar } from "react-chartjs-2";

import { buildRegionUrl } from "../../utils.js";
import config from "../../settings.js";
import configData from "../../settings_data.js";
import Api from "../../Controllers/Api.js";

/**
 * This component is used to create a Histogram chart.
 */
class Histogramme extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id_analyse: String(props.id_analysis),
            representation: "histogramme",
            widthDOM: window.innerWidth,
            disabled: false,
            filters: props.filters ?? {},
        };
    }

    componentDidMount() {
        // Get data from API
        this.fetchConfiguration().then((data) => {
            if (!data) return;
            // Retrieve initial filters
            let filters = this.props.filters ?? this.initFilter(data, "secteur");
            this.setState({ filters: filters });
        });
    }

    /**
     * This function updates the data from which we build the histogram charts when changing territory
     * @param {object key => value} prevProps : main component properties before territory change via selection tool
     */
    componentDidUpdate(prevProps) {
        let currentZone = this.props.zoneId ?? this.props.parentApi.data.currentZone;
        if (
            (prevProps.zoneId ?? prevProps.parentApi.data.currentZone) !== currentZone
        ) {
            // If the territory has changed
            if (this.state.id_analyse) {
                this.fetchConfiguration(); // Get data
            }
        }
    }

    componentWillUnmount() {
        if (this.dataPromise) {
            this.dataPromise.abort();
        }
    }

    /**
     * Launches a main component method to add/remove a modality to the filter
     * Triggered when a checkbox associated with a modality is checked
     * @param {chaine de caractère} modalite : modality checked / unchecked
     */
    filtrerSurLegende(event, modalite, categorie) {
        // if we do have filters and current filter
        if (!this.state.filters || !this.state.filters[categorie]) return;
        let _filters = JSON.parse(JSON.stringify(this.state.filters));

        // we first retrieve the filter ID if present inside the filters
        const idIfPresent = _filters[categorie].indexOf(modalite);

        // do we have to add or do we have to remove
        if (event.target.checked) {
            // if we couldn't find it, it means we need to add it
            if (idIfPresent === -1) {
                _filters[categorie].push(modalite);
            }
        } else {
            // we are going to delete the filter if we can find it
            if (idIfPresent !== -1) {
                _filters[categorie].splice(idIfPresent, 1);
            }
        }
        // if the action resulted in some changes between previous filters
        // and new filters => we update the state
        if (JSON.stringify(this.state.filters) !== JSON.stringify(_filters)) {
            this.setState({ filters: _filters });
            if (this.props.provenance === "tableau_de_bord") {
                // we also callback the updated filters in case of dashboard edition
                this.props.parentApi.data.tableauBordDonnees.donnees[
                    this.props.thematique
                ].indicateurs[this.props.id].filters = _filters;
            }
        }
    }

    /**
     * Builds the legend with which we can select or delete modalities
     * in the data to be displayed using checkboxes, from modalities by categories.
     * @param {Object} data
     * @param {string} categorie
     */
    buildLegend(data, filters, categorie) {
        let tailleSpan = "10px";
        let taillePoliceModalites = "";
        let hauteurLegendeModalites = "";
        let hauteurLegende = "";
        if (
            this.props.provenance !== "tableau_de_bord" &&
            this.props.provenance !== "tableau_de_bord_restitue"
        ) {
            tailleSpan = "15px";
            taillePoliceModalites = " taille-police-suivi-energetique";
            hauteurLegendeModalites = " legende-modalite-suivi-energetique";
            hauteurLegende = " liste-modalites-suivi-energetique";
        }
        let retourLigneLibelleModalites = "";
        if (this.props.provenance === "tableau_de_bord_restitue") {
            retourLigneLibelleModalites = " legende-modalite-tableau-bord";
        }

        let legende = [];
        for (let i in data["Datasets"]) {
            const modalite = data["Datasets"][i][categorie];
            const couleur = data["BackgroundCouleur"][i];

            let checked = true;
            if (filters && filters[categorie])
                checked = filters[categorie].includes(modalite);

            const checkboxId = `legende-${this.props.id}-${i}-${modalite}`;

            legende.push(
                <div
                    className={"legende-modalite" + hauteurLegendeModalites}
                    key={"legende-" + i}
                >
                    <span
                        className="element-legende-modalites"
                        style={{
                            backgroundColor: couleur,
                            display: "block",
                            height: tailleSpan,
                            width: tailleSpan,
                        }}
                    ></span>
                    <input
                        type="checkbox"
                        id={checkboxId}
                        defaultChecked={checked}
                        className="element-legende-modalites"
                        onChange={(event) =>
                            this.filtrerSurLegende(event, modalite, categorie)
                        }
                    ></input>
                    <label
                        htmlFor={checkboxId}
                        className={
                            "element-legende-modalites" +
                            retourLigneLibelleModalites +
                            taillePoliceModalites
                        }
                    >
                        {modalite}
                    </label>
                </div>
            );
        }
        return <ul className={"liste-modalites" + hauteurLegende}>{legende}</ul>;
    }

    fetchConfiguration() {
        let pZone =
            "?zone=" + (this.props.zoneType ?? this.props.parentApi.data.zone.zone);
        let pMaille =
            "&maille=" +
            (this.props.zoneMaille ?? this.props.parentApi.data.zone.maille);
        let pZoneId =
            "&zone_id=" + (this.props.zoneId ?? this.props.parentApi.data.currentZone);
        if ((this.props.zoneType ?? this.props.parentApi.data.zone.zone) === "region") {
            pZoneId = "&zone_id=" + this.props.parentApi.data.regionCode;
        }
        let id_analyse = this.state.id_analyse;
        let representation = this.state.representation;
        let body = JSON.stringify({});
        let dataSource =
            config.api_analysis_meta_url +
            id_analyse +
            "/graphique/" +
            representation +
            pZone +
            pMaille +
            pZoneId;

        if (this.dataPromise) this.dataPromise.abort();
        this.dataPromise = Api.callApi(
            buildRegionUrl(dataSource, this.props.parentApi.data.region),
            body,
            "POST"
        );
        return this.dataPromise
            .then((json) => {
                if (json["disabled"] && json["disabled"] === true) {
                    this.setState({
                        disabled: true,
                    });
                    return;
                }

                let donneesFormatees = {};
                let modalite = [];
                let couleur = [];
                let valeurs = [];

                donneesFormatees["unit"] = json["unit"];
                delete json["unit"];

                for (let i in json) {
                    json[i].forEach((item) => {
                        if (!modalite.includes(item.modalite))
                            modalite.push(item.modalite);
                        if (!couleur.includes(item.couleur)) couleur.push(item.couleur);
                    });
                }
                donneesFormatees["BackgroundCouleur"] = couleur;
                donneesFormatees["indicateurs"] = Object.keys(json);
                donneesFormatees["Datasets"] = [];
                let exist;
                for (let k in modalite) {
                    for (let i in Object.values(json)) {
                        exist = false;
                        for (let j in Object.values(json)[i]) {
                            if (
                                Object.values(json)[i][j].modalite.includes(modalite[k])
                            ) {
                                exist = true;
                                break;
                            }
                        }
                        if (exist) {
                            for (let j in Object.values(json)[i]) {
                                if (
                                    Object.values(json)[i][j].modalite === modalite[k]
                                ) {
                                    valeurs.push(Object.values(json)[i][j].val);
                                }
                            }
                        } else {
                            valeurs.push(0);
                        }
                    }
                    donneesFormatees["Datasets"].push({
                        secteur: modalite[k],
                        valeurs: valeurs,
                    });
                    valeurs = [];
                }

                this.setState({
                    data: donneesFormatees,
                });
                return donneesFormatees;
            })
            .catch((error) => {
                if (error.name === "AbortError") return;
                console.error(error);
            });
    }

    initFilter(data, category) {
        let filters = {};
        filters[category] = data["Datasets"].map((entry) => entry[category]);
        return filters;
    }

    render() {
        if (this.state.disabled === true) {
            return (
                <div className="charts-legende">
                    <div className={"confid-chart"}>
                        Cet indicateur n'est pas activé actuellement, veuillez contacter
                        l'administrateur régional.
                    </div>
                </div>
            );
        }

        if (!this.state.data) return null;

        let unit = this.state.data.unit;
        const options = {
            offsetGridLines: true,
            drawTicks: true,
            layout: {
                padding: 0,
            },
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    callbacks: {
                        label: function (tooltipItems) {
                            return (
                                tooltipItems.dataset.label +
                                " : " +
                                tooltipItems.formattedValue +
                                " " +
                                unit
                            );
                        },
                    },
                },
            },
            responsive: false,
            maintainAspectRatio: false,
            scales: {
                x: {
                    stacked: true,
                    ticks: {
                        padding: 5,
                    },
                    SVGRadialGradientElement: {
                        display: true,
                    },
                },
                y: {
                    stacked: true,
                    grid: {
                        drawBorder: false,
                    },
                    ticks: {
                        beginAtZero: true,
                        font: {
                            size: 10,
                        },
                    },
                    title: {
                        display: true,
                        text: "Valeurs exprimées en " + this.state.data["unit"],
                        font: {
                            size: 10,
                        },
                        marginRight: 20,
                        paddingLeft: 20,
                    },
                },
            },
            devicePixelRatio: configData.chartsDevicePixelRatio,
        };

        let dataHistogramme = [];
        for (let [i, item] of this.state.data["Datasets"].entries()) {
            if (
                this.state.filters &&
                this.state.filters["secteur"] &&
                !this.state.filters["secteur"].includes(item.secteur)
            ) {
                continue;
            }
            // we unshift to have the first items on the top and not at the bottom
            dataHistogramme.unshift({
                label: item.secteur,
                backgroundColor: this.state.data["BackgroundCouleur"][i],
                barThickness: 30,
                categoryPercentage: 1,
                data: item.valeurs,
            });
        }
        const data = {
            labels: this.state.data["indicateurs"],
            responsive: true,
            offset: true,
            datasets: dataHistogramme,
        };

        const legend = this.buildLegend(this.state.data, this.state.filters, "secteur");
        return (
            <div className="charts-legende">
                <div className="block-row">
                    <Bar data={data} height={250} width={350} options={options} />
                </div>
                <div className="choisir-methode" style={{ marginBottom: 60 }}>
                    {legend}
                </div>
            </div>
        );
    }
}

export default Histogramme;
