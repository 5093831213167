/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import Select from "react-select";
import Api from "../../Controllers/Api";
import config from "../../settings.js";
import { buildRegionUrl } from "../../utils.js";

/**
 * This component is used to add or update a didactic file.
 */
class AddDidacticFile extends React.Component {
    defaultTitle = "Ex. Se déplacer autrement ";
    defaultCategoryTitle = "Ex. Besoins de se déplacer pour travailler ? ";
    defaultSectionTitle = "Favoriser l'utilisation des transports en commun";
    defaultSectionComment =
        "Levier activé : augmentation de la part modale des transports en commun";

    constructor(props) {
        super(props);

        this.state = {
            analyses: [], // analysis
            actions: [], // action names
            statusMessage: false,
            status: false,
            maxNbElements: 0, // used to generate new IDs for key attribute
            didacticFile: {
                // 1 : {
                //     id : 0,
                //     categoryTitle: "Titre catégorie",
                //     subCategory: {
                //         1 : {
                //             id : undefined
                //             subCategoryTitle: "Titre sous catégorie",
                //             typeAnalysis : "",
                //             section : {
                //                 1 : {
                //                      id : undefined
                //                      sectionTitle: "Titre section",
                //                      sectionComment : "Commentaire",
                //                      analysis : {},
                //                      },
                //                 2 : { ... }
                //         },
                //         2 : {
                //             ...
                //         },
                // }
                data: {},
                metadata: {
                    fileTitle: "",
                    description: "",
                },
            },
        };
    }

    componentDidMount() {
        if (this.props.mode === "update" && this.props.dataCurrentDidacticFile.id) {
            // There are two possible modes: "update" and "add"
            this.getDidacticFileData(this.props.dataCurrentDidacticFile.id);
        }

        // retrieve general information about indicators, actions and didactic file types
        this.getListAnalysis();
        this.getListActions(); // TODO : replace actions with new levers
    }

    /**
     * Get the list of indicators and equipments currently available in the region. Will set the
     * internal state analyses to a list sorted alphabetically by label.
     */
    getListAnalysis() {
        let analysesRawList = this.props.parentApi.controller.analysisManager.analysis;
        let equipementsRawList =
            this.props.parentApi.controller.equipementsManager.getEquipementsLayers();
        let analysesList = [];

        // add all analysis
        for (let a of analysesRawList) {
            analysesList.push({ value: a.id, label: a.nom, type: "analysis" });
        }
        // add all equipements
        for (let a of equipementsRawList) {
            analysesList.push({ value: a.id, label: a.label, type: "equipment" });
        }

        analysesList.sort((a, b) => a.label > b.label);

        // update list analysis
        this.setState({
            analyses: analysesList,
        });
    }

    /**
     * Retrieve the actions list from an API entrypoint. Will set the
     * internal state actions to a list sorted alphabetically by label.
     */
    getListActions() {
        let url = buildRegionUrl(
            config.api_actions_list,
            this.props.parentApi.data.region
        );
        Api.callApi(url, null, "GET").then((response) => {
            let actionsList = [];
            for (let a of response) {
                actionsList.push({ value: a.type, label: a.type });
            }
            actionsList.sort((a, b) => a.label > b.label);

            this.setState({
                actions: actionsList,
            });
        });
    }

    /**
     * Get the data for a specific didactic file identified by its ID.
     * @param {int} id the didactic file ID
     */
    getDidacticFileData(id) {
        let url = buildRegionUrl(
            config.api_didactic_file,
            this.props.parentApi.data.region
        ).replace("#id#", id);
        Api.callApi(url, null, "GET").then((response) => {
            // find max id in response data
            let maxId = 0;
            if (response.data) {
                for (let category in response.data) {
                    if (response.data[category].id > maxId) {
                        maxId = response.data[category].id + 1;
                    }
                    if (response.data[category].subCategory) {
                        for (let subCategory in response.data[category].subCategory) {
                            if (
                                response.data[category].subCategory[subCategory].id >
                                maxId
                            ) {
                                maxId =
                                    response.data[category].subCategory[subCategory]
                                        .id + 1;
                            }
                        }
                    }
                }
            }

            this.setState({
                didacticFile: response,
                maxNbElements: maxId,
            });
        });
    }

    /**
     * Send the didactic file to the API to either save it for the first time or update it.
     * @param {str} mode the mode selected (either add or update, will raise an error otherwise)
     * @returns false if the form is invalid or nothing
     */
    sendDidacticFile(mode) {
        let url = "";

        // depending on the mode, we choose either add or update URL
        if (mode === "add") {
            url = buildRegionUrl(
                config.api_integration_didactic_file,
                this.props.parentApi.data.region
            );
        } else if (mode === "update") {
            let id = this.props.dataCurrentDidacticFile.id;
            url = buildRegionUrl(
                config.api_update_didactic_file,
                this.props.parentApi.data.region
            ).replace("#id#", id);
        } else {
            this.setState({
                status: "warning",
                statusMessage:
                    "Le mode d'envoi n'est pas valide (ajout ou mise à jour, fourni : " +
                    mode +
                    ")",
            });
            return false;
        }

        let formData = new FormData();
        if (!this.validateForm()) {
            return false;
        }
        formData.append("didacticFile", JSON.stringify(this.state.didacticFile));

        Api.callApi(url, formData, "PUT", "default")
            .then((response) => {
                this.setState({
                    status: "success",
                    statusMessage: response.message,
                });
                if (mode === "add") {
                    this.resetForm(); //reset form
                    // we go to the list of didactic files, tab number 0, 1 being the add/update screen
                    this.props.updateTabDataDidacticFile(0);
                }
            })
            .catch((e) => {
                this.setState({ status: "error", statusMessage: e.message });
            });
    }

    /**
     * Reset the form to an empty form with no category and no subcategory.
     */
    resetForm() {
        let didacticFile = this.state.didacticFile;

        didacticFile.data = {};
        didacticFile.metadata.fileTitle = "";
        didacticFile.metadata.description = "";

        this.setState({
            didacticFile: didacticFile,
        });
    }

    /**
     * Reset the status to false and the message to empty message.
     */
    resetStatusMessage() {
        if (this.state.status && this.state.statusMessage) {
            this.setState({
                status: false,
                statusMessage: false,
            });
        }
    }

    /**
     * Test that the form inputs are correct:
     *   - main title can not be empty
     *   - category titles can not be empty
     *   - sub category titles can not be empty
     *   - actions or indicators lists can not be empty
     * @returns boolean, indicating whether the form is valid or not
     */
    validateForm() {
        let didacticFile = this.state.didacticFile;

        if (didacticFile.metadata.fileTitle === "") {
            this.setState({
                status: "warning",
                statusMessage: "Vous devez renseigner un titre pour la fiche",
            });
            return false;
        }

        for (const categoryId in this.state.didacticFile.data) {
            const category = this.state.didacticFile.data[categoryId];
            let title = category.categoryTitle;
            if (title === "") {
                this.setState({
                    status: "warning",
                    statusMessage: "Vous devez renseigner un titre pour la catégore",
                });
                return false;
            }
            if (category.subCategory) {
                for (const subCatId in category.subCategory) {
                    const subcat = category.subCategory[subCatId];
                    let titleSubCat = subcat.subCategoryTitle;

                    if (titleSubCat === "") {
                        this.setState({
                            status: "warning",
                            statusMessage:
                                "Vous devez renseigner un titre pour la sous catégorie",
                        });
                        return false;
                    }

                    if (subcat.section) {
                        for (const sectionId in subcat.section) {
                            const section = subcat.section[sectionId];
                            let titleSection = section.sectionTitle;

                            if (titleSection === "") {
                                this.setState({
                                    status: "warning",
                                    statusMessage:
                                        "Vous devez renseigner un titre pour la section",
                                });
                                return false;
                            }
                        }
                    }
                }
            }
        }

        return true;
    }

    /**
     * Handle the file title change event to update current didactic file metadata.
     * @param {Event} event
     */
    updateFileTitle(event) {
        // reset status message
        this.resetStatusMessage();

        let title = event.target.value;
        let didacticFile = this.state.didacticFile;
        didacticFile.metadata.fileTitle = title;
        this.setState({
            didacticFile: didacticFile,
        });
    }

    /**
     * Handle the file description change event to update current didactic file metadata.
     * @param {Event} event
     */
    updateFileDescription(event) {
        // reset status message
        this.resetStatusMessage();

        let description = event.target.value;
        let didacticFile = this.state.didacticFile;
        didacticFile.metadata.description = description;
        this.setState({
            didacticFile: didacticFile,
        });
    }

    /**
     * Handle a category name change event to update corresponding category data.
     * @param {Event} event
     */
    updateCategoryName(event, categoryId) {
        let title = event.target.value;
        let didacticFile = this.state.didacticFile;
        didacticFile.data[categoryId].categoryTitle = title;

        this.setState({
            didacticFile: didacticFile,
        });
    }

    /**
     * Handle a sub category name change event to update corresponding sub category data.
     * @param {Event} event
     * @param {int} categoryId current parent category ID in categories array
     * @param {int} subCategoryId current subcategory ID in subcategories array
     */
    updateSubCategoryName(event, categoryId, subCategoryId) {
        let title = event.target.value;
        let didacticFile = this.state.didacticFile;
        didacticFile.data[categoryId].subCategory[subCategoryId].subCategoryTitle =
            title;

        this.setState({
            didacticFile: didacticFile,
        });
    }

    /**
     * Handle a section name change event to update corresponding section data (sub sub-category).
     * @param {Event} event
     * @param {int} categoryId current parent category ID in categories array
     * @param {int} subCategoryId current subcategory ID in subcategories array
     * @param {int} sectionId current section ID in sections array
     */
    updateSectionName(event, categoryId, subCategoryId, sectionId) {
        let title = event.target.value;
        let didacticFile = this.state.didacticFile;
        didacticFile.data[categoryId].subCategory[subCategoryId].section[
            sectionId
        ].sectionTitle = title;

        this.setState({
            didacticFile: didacticFile,
        });
    }

    /**
     * Handle a section comment change event to update corresponding section data (sub sub-category).
     * @param {Event} event
     * @param {int} categoryId current parent category ID in categories array
     * @param {int} subCategoryId current subcategory ID in subcategories array
     * @param {int} sectionId current section ID in sections array
     */
    updateSectionComment(event, categoryId, subCategoryId, sectionId) {
        let comment = event.target.value;
        let didacticFile = this.state.didacticFile;
        didacticFile.data[categoryId].subCategory[subCategoryId].section[
            sectionId
        ].sectionComment = comment;

        this.setState({
            didacticFile: didacticFile,
        });
    }

    /**
     * Display the form to handle didactic file description.
     *
     * Fill it with current description if editing an existing file.
     * @returns JSX tree
     */
    fileDescription() {
        let description = "";
        if (this.props.mode === "update") {
            description = this.state.didacticFile.metadata.description;
        }
        return (
            <div className="form-horizontal">
                <label htmlFor="description" className="col-sm-2 control-label">
                    Objectif :
                </label>
                <textarea
                    type="text"
                    className="form-control form-inline equipement-name"
                    id="description"
                    defaultValue={description}
                    onChange={(e) => this.updateFileDescription(e)}
                />
            </div>
        );
    }

    /**
     * Update, for a specific subcategory located inside a specific category,
     * the indicators/actions list. Is triggered whenever a subcategory is edited.
     *
     * @param {Event} event the event triggered by changing the analysis/action
     * @param {str} type the type of element (either actions or analysis)
     * @param {int} categoryId the ID in categories array
     * @param {int} subCategoryId the ID in sub categories array
     */
    updateSubCategoryAnalysis(event, categoryId, subCategoryId, sectionId) {
        // we update the analysis from the select content
        let analysis = {};
        analysis["nom"] = event.label;
        analysis["id"] = event.value;
        analysis["type"] = event.type;
        // we then update the main section object
        let didacticFile = this.state.didacticFile;
        didacticFile.data[categoryId].subCategory[subCategoryId].typeAnalysis =
            event.type;
        didacticFile.data[categoryId].subCategory[subCategoryId].section[
            sectionId
        ].analysis = analysis;

        this.setState({
            didacticFile: didacticFile,
        });
    }

    /**
     *
     * @param {str} type the type of element (either actions or analysis)
     * @param {int} categoryId the ID in categories array
     * @param {int} subCategoryId the ID in sub categories array
     * @param {int} index if adding
     * @returns
     */
    analysisActionsStructure(categoryId, subCategoryId, sectionId) {
        let didacticFile = this.state.didacticFile;
        let label = "indicateurs";
        let optionsAnalysis = this.state.analyses;
        // TODO : replace it with levers ?
        // if (type === "actions") {
        //     label = "actions";
        //     optionsAnalysis = this.state.actions;
        // }
        let idSection =
            this.state.didacticFile.data[categoryId].subCategory[subCategoryId].section[
                sectionId
            ].id;
        let listAnalysis = [];
        Object.keys(
            didacticFile.data[categoryId].subCategory[subCategoryId].section[sectionId]
                .analysis
        ).forEach(function (id) {
            let analysisLabel =
                didacticFile.data[categoryId].subCategory[subCategoryId].section[
                    sectionId
                ].analysis[id];
            listAnalysis.push({ value: id, label: analysisLabel });
        });
        const defaultsValues = listAnalysis;
        let title =
            didacticFile.data[categoryId].subCategory[subCategoryId].section[sectionId]
                .sectionTitle;
        let comment =
            didacticFile.data[categoryId].subCategory[subCategoryId].section[sectionId]
                .sectionComment;
        const fieldId =
            "title-cat-" +
            categoryId +
            "-sub-cat-" +
            subCategoryId +
            "-section-" +
            idSection;
        return (
            <div
                className="en-tete-section"
                key={
                    "category_" +
                    categoryId +
                    "_sub_category_" +
                    subCategoryId +
                    "_section_" +
                    idSection
                }
            >
                <div className="form-horizontal">
                    <label htmlFor={fieldId} className="col-sm-2 control-label">
                        Nom de la section
                    </label>
                    <input
                        type="text"
                        className="form-control form-inline equipement-name"
                        id={fieldId}
                        defaultValue={title}
                        placeholder={this.defaultSectionTitle}
                        onChange={(e) =>
                            this.updateSectionName(
                                e,
                                categoryId,
                                subCategoryId,
                                sectionId
                            )
                        }
                    />
                </div>
                <div className="form-horizontal">
                    <label htmlFor={fieldId} className="col-sm-2 control-label">
                        Commentaire
                    </label>
                    <input
                        type="text"
                        className="form-control form-inline equipement-name"
                        id={fieldId}
                        defaultValue={comment}
                        placeholder={this.defaultSectionComment}
                        onChange={(e) =>
                            this.updateSectionComment(
                                e,
                                categoryId,
                                subCategoryId,
                                sectionId
                            )
                        }
                    />
                </div>
                <div className="form-horizontal">
                    <label className="col-sm-2 control-label">
                        {"Liste des " + label}
                    </label>
                    <Select
                        isMulti
                        defaultValue={defaultsValues}
                        name="analysis"
                        options={optionsAnalysis}
                        placeholder={<div>Sélectionner une valeur</div>}
                        onChange={(e) =>
                            this.updateSubCategoryAnalysis(
                                e,
                                categoryId,
                                subCategoryId,
                                sectionId
                            )
                        }
                        closeMenuOnSelect={true}
                        className="basic-multi-select"
                        classNamePrefix="select"
                    />
                </div>
                <button
                    className="suppr-analyse"
                    onClick={() =>
                        this.deleteSection(categoryId, subCategoryId, sectionId)
                    }
                >
                    x
                </button>
                <hr />
            </div>
        );
    }

    subCategoryStructure(categoryId, subCategoryId) {
        let didacticFile = this.state.didacticFile;
        let idSubCategory =
            this.state.didacticFile.data[categoryId].subCategory[subCategoryId].id;

        let title =
            didacticFile.data[categoryId].subCategory[subCategoryId].subCategoryTitle;

        const fieldId = "title-cat-" + categoryId + "-sub-cat-" + idSubCategory;
        return (
            <div
                className="en-tete-sous-categorie"
                key={"category_" + categoryId + "_sub_category_" + idSubCategory}
            >
                <div className="form-horizontal">
                    <label htmlFor={fieldId} className="col-sm-2 control-label">
                        Nom de la sous catégorie
                    </label>
                    <input
                        type="text"
                        className="form-control form-inline equipement-name"
                        id={fieldId}
                        defaultValue={title}
                        placeholder={this.defaultCategoryTitle}
                        onChange={(e) =>
                            this.updateSubCategoryName(e, categoryId, subCategoryId)
                        }
                    />
                </div>
                {this.addNewSectionButton(
                    categoryId,
                    subCategoryId,
                    "analysis",
                    "Nouvelle section"
                )}

                <br />
                {this.state.didacticFile.data[categoryId].subCategory[subCategoryId]
                    .section &&
                    Object.keys(
                        this.state.didacticFile.data[categoryId].subCategory[
                            subCategoryId
                        ].section
                    ).length > 0 &&
                    Object.keys(
                        this.state.didacticFile.data[categoryId].subCategory[
                            subCategoryId
                        ].section
                    ).map((sectionId) => {
                        return this.analysisActionsStructure(
                            categoryId,
                            subCategoryId,
                            sectionId
                        );
                    })}

                <br />
                <div className="form-horizontal">
                    {this.displaySubCategoryActionsButtons(categoryId, subCategoryId)}
                </div>
            </div>
        );
    }

    /**
     * Display a button to add a section in current subcategory.
     *
     * @param {int} categoryId current category ID in categories array
     * @param {int} subCategoryId current subcategory ID in subcategories array
     * @param {str} type the element type (either actions or analysis)
     * @param {str} buttonContent the button content displayed
     * @returns JSX tree
     */
    addNewSectionButton(categoryId, subCategoryId, type, buttonContent) {
        return (
            <button
                key={"add_new_" + type}
                className="btn btn-success"
                onClick={() => this.addNewSection(categoryId, subCategoryId, type)}
            >
                {buttonContent}
            </button>
        );
    }

    /**
     * Display a button to add a subcategory in current category.
     *
     * @param {int} categoryId current category ID in categories array
     * @returns JSX tree
     */
    addNewSubCategoryButton(categoryId) {
        return (
            <button
                key={"add_new_sub_category"}
                className="btn btn-success"
                onClick={() => this.addNewSubCategory(categoryId)}
            >
                Nouvelle sous catégorie
            </button>
        );
    }

    /**
     * Insert a new category to main didactic file.
     */
    addNewCategory() {
        let didacticFile = this.state.didacticFile;
        const newCategoryId = Object.keys(didacticFile.data).length;

        // We initialize it with a new empty object
        didacticFile.data[newCategoryId] = {
            categoryTitle: "",
            id: this.state.maxNbElements,
            subCategory: {},
        };

        this.setState({
            didacticFile: didacticFile,
            maxNbElements: this.state.maxNbElements + 1,
        });
    }

    /**
     * Add a new sub category of specified type to chosen category.
     *
     * @param {int} categoryId the current category ID in categories array
     */
    addNewSubCategory(categoryId) {
        let didacticFile = this.state.didacticFile;

        const newSubCategoryId = Object.keys(
            didacticFile.data[categoryId].subCategory
        ).length;
        // We initialize it with a new empty object
        didacticFile.data[categoryId].subCategory[newSubCategoryId] = {
            subCategoryTitle: "",
            typeAnalysis: "",
            id: this.state.maxNbElements,
            section: {},
        };

        this.setState({
            didacticFile: didacticFile,
            maxNbElements: this.state.maxNbElements + 1,
        });
    }

    /**
     * Add a new sub sub category of specified type to chosen sub category.
     *
     * @param {int} categoryId the current category ID in categories array
     * @param {str} type the type of element (either actions or analysis)
     */
    addNewSection(categoryId, subCatId, type) {
        let didacticFile = this.state.didacticFile;

        const newSectionId = Object.keys(
            didacticFile.data[categoryId].subCategory[subCatId].section
        ).length;
        // We initialize it with a new empty object
        didacticFile.data[categoryId].subCategory[subCatId].section[newSectionId] = {
            sectionTitle: "",
            sectionComment: "",
            type: type,
            id: this.state.maxNbElements,
            analysis: {},
        };

        this.setState({
            didacticFile: didacticFile,
            maxNbElements: this.state.maxNbElements + 1,
        });
    }

    /**
     * Display the "new category" button
     * @returns JSX tree
     */
    addCategoryButton() {
        return (
            <button
                key="add_new_category"
                className="btn btn-success"
                onClick={() => this.addNewCategory()}
            >
                Nouvelle catégorie
            </button>
        );
    }

    /**
     * Display the form to specify the main title of the didactic file
     * @returns JSX tree
     */
    fileTitle() {
        let title;
        if (this.props.mode === "update") {
            title = this.state.didacticFile.metadata.fileTitle;
        }
        return (
            <div className="form-horizontal">
                <label htmlFor="title" className="col-sm-2 control-label">
                    Nom :
                </label>
                <input
                    type="text"
                    className="form-control form-inline equipement-name"
                    id="title"
                    placeholder={this.defaultTitle}
                    defaultValue={title}
                    onChange={(e) => this.updateFileTitle(e)}
                />
            </div>
        );
    }

    /**
     * Display the form to specify the title of a category
     * @param {int} categoryId the category ID in the categories array
     * @returns JSX tree
     */
    titleCategory(categoryId) {
        let inputId = "title-cat" + categoryId;
        let title = "";
        if (this.props.mode === "update") {
            title = this.state.didacticFile.data[categoryId].categoryTitle;
        }

        return (
            <div>
                <label htmlFor={inputId} className="col-sm-2 control-label">
                    Nom de la catégorie
                </label>
                <input
                    type="text"
                    className="form-control form-inline equipement-name"
                    id={inputId}
                    defaultValue={title}
                    placeholder={this.defaultCategoryTitle}
                    onChange={(e) => this.updateCategoryName(e, categoryId)}
                />
            </div>
        );
    }

    /**
     * Move a category inside a didactic file, either up or down depending on
     * the direction chosen.
     *
     * @param {int} categoryId the category ID in the categories array
     * @param {str} direction the direction to move to (either up or down, default: down)
     * @returns false if something failed
     */
    moveCategory(categoryId, direction) {
        let didacticFile = this.state.didacticFile;

        let otherId = categoryId;
        if (direction === "up") otherId--;
        else otherId++;

        // already on top or bottom ?
        if (
            otherId < 0 ||
            otherId >= Object.keys(didacticFile.data).length ||
            categoryId < 0 ||
            categoryId >= Object.keys(didacticFile.data).length
        ) {
            return false;
        }

        // we really invert
        [didacticFile.data[categoryId], didacticFile.data[otherId]] = [
            didacticFile.data[otherId],
            didacticFile.data[categoryId],
        ];

        this.setState({
            didacticFile: didacticFile, // update didactic file
        });
    }

    /**
     * Delete a category from a didactic file categories list. Will reorder the
     * other remaining categories to have ID following each others.
     *
     * @param {int} categoryId the category ID in the categories array
     * @returns false if the deletion failed
     */
    deleteCategory(categoryId) {
        let didacticFile = this.state.didacticFile;
        delete didacticFile.data[categoryId]; // delete category

        // reset IDs
        let newCategorie = {};
        Object.keys(didacticFile.data).forEach(function (item, id) {
            newCategorie[id] = didacticFile.data[item];
        });
        didacticFile.data = newCategorie;

        this.setState({
            didacticFile: didacticFile, // update didactic file
        });
    }

    /**
     * Display the buttons associated to actions that can be performed on a category.
     *
     * @param {int} categoryId the category ID in the categories array
     * @returns JSX tree
     */
    displayCategoryActionsButtons(categoryId) {
        return (
            <>
                {/* If we do not have the first element, we can move up */}
                {parseInt(categoryId, 10) === 0 ? (
                    ""
                ) : (
                    <button
                        className="move-thematique up"
                        onClick={() => this.moveCategory(categoryId, "up")}
                    >
                        ▲
                    </button>
                )}
                {/* If we do not have the last element, we can move down */}
                {this.state.didacticFile.data &&
                parseInt(categoryId, 10) ===
                    Object.keys(this.state.didacticFile.data).length - 1 ? (
                    ""
                ) : (
                    <button
                        className="move-thematique down"
                        onClick={() => this.moveCategory(categoryId, "down")}
                    >
                        ▼
                    </button>
                )}
                <button
                    className="suppr-thematique"
                    onClick={() => this.deleteCategory(categoryId)}
                >
                    x
                </button>
            </>
        );
    }

    /**
     * Move a subcategory inside a category, either up or down depending on the direction
     * chosen.
     *
     * @param {int} categoryId the category ID in the categories array
     * @param {int} subCategoryId the sub category ID in the subcategories array
     * @param {str} direction the direction to move to (either up or down, default: down)
     * @returns false if something failed
     */
    moveSubCategory(categoryId, subCategoryId, direction) {
        let didacticFile = this.state.didacticFile;
        if (!didacticFile.data[categoryId]) {
            return false;
        }

        // we move the ID into the right direction
        let otherId = subCategoryId;
        if (direction === "up") otherId--;
        else otherId++;

        // already on top or bottom ?
        if (
            otherId < 0 ||
            otherId >= Object.keys(didacticFile.data[categoryId].subCategory).length ||
            subCategoryId < 0 ||
            subCategoryId >=
                Object.keys(didacticFile.data[categoryId].subCategory).length
        ) {
            return false;
        }

        // we really invert
        [
            didacticFile.data[categoryId].subCategory[subCategoryId],
            didacticFile.data[categoryId].subCategory[otherId],
        ] = [
            didacticFile.data[categoryId].subCategory[otherId],
            didacticFile.data[categoryId].subCategory[subCategoryId],
        ];

        this.setState({
            didacticFile: didacticFile, // update didactic file
        });
    }

    /**
     * Delete a subcategory from a category subcategories list. Will reorder the
     * other remaining subcategories to have ID following each others.
     *
     * @param {int} categoryId the category ID in the categories array
     * @param {int} subCatId the subcategory ID in the subcategories array
     * @returns false if the deletion failed
     */
    deleteSubCategory(categoryId, subCatId) {
        let didacticFile = this.state.didacticFile;
        if (!didacticFile.data[categoryId]) {
            return false;
        }
        // delete sub category
        delete didacticFile.data[categoryId].subCategory[subCatId];

        // reset IDs
        let newSubCategory = {};
        Object.keys(didacticFile.data[categoryId].subCategory).forEach(function (
            item,
            id
        ) {
            newSubCategory[id] = didacticFile.data[categoryId].subCategory[item];
        });
        didacticFile.data[categoryId].subCategory = newSubCategory;

        this.setState({
            didacticFile: didacticFile, // update didactic file
        });
    }

    deleteSection(categoryId, subCatId, sectionId) {
        let didacticFile = this.state.didacticFile;
        if (
            !didacticFile.data[categoryId] &&
            !didacticFile.data[categoryId].subCategory[subCatId]
        ) {
            return false;
        }
        // delete section
        delete didacticFile.data[categoryId].subCategory[subCatId].section[sectionId];

        // reset IDs
        let newSection = {};
        Object.keys(
            didacticFile.data[categoryId].subCategory[subCatId].section
        ).forEach(function (item, id) {
            newSection[id] =
                didacticFile.data[categoryId].subCategory[subCatId].section[item];
        });
        didacticFile.data[categoryId].subCategory[subCatId].section = newSection;

        this.setState({
            didacticFile: didacticFile, // update didactic file
        });
    }

    /**
     * Display the buttons associated to actions that can be performed on a subcategory.
     *
     * @param {int} categoryId the category ID in the categories array
     * @param {int} subCategoryId the subcategory ID in the subcategories array
     * @returns JSX tree
     */
    displaySubCategoryActionsButtons(categoryId, subCategoryId) {
        return (
            <>
                {/* If we do not have the first element, we can move up */}
                {parseInt(subCategoryId, 10) === 0 ? (
                    ""
                ) : (
                    <button
                        className="move-thematique up"
                        onClick={() =>
                            this.moveSubCategory(categoryId, subCategoryId, "up")
                        }
                    >
                        ▲
                    </button>
                )}
                {/* If we do not have the last element, we can move down */}
                {this.state.didacticFile.data[categoryId].subCategory &&
                parseInt(subCategoryId, 10) ===
                    Object.keys(this.state.didacticFile.data[categoryId].subCategory)
                        .length -
                        1 ? (
                    ""
                ) : (
                    <button
                        className="move-thematique down"
                        onClick={() =>
                            this.moveSubCategory(categoryId, subCategoryId, "down")
                        }
                    >
                        ▼
                    </button>
                )}
                <button
                    className="suppr-sub-cat"
                    onClick={() => this.deleteSubCategory(categoryId, subCategoryId)}
                >
                    x
                </button>
            </>
        );
    }

    /**
     * Display the categories associated to current didactic file.
     *
     * Iterate over state didacticFile data then, for each category, on its
     * subcategories located in subCategory value.
     * @returns JSX tree
     */
    fileCategories() {
        return Object.keys(this.state.didacticFile.data).map((categoryId) => {
            let id = this.state.didacticFile.data[categoryId].id;
            return (
                <div
                    key={"category_" + id} // unique key ?
                    className="en-tete-categorie"
                    id={categoryId}
                >
                    <div className="block-categorie">
                        <h4>Ajouter une catégorie</h4>
                        <div className="actions">
                            {this.titleCategory(categoryId)}
                            <br />
                            <div>{this.addNewSubCategoryButton(categoryId)}</div>
                            <br />
                            {this.state.didacticFile.data[categoryId].subCategory &&
                                Object.keys(
                                    this.state.didacticFile.data[categoryId].subCategory
                                ).length > 0 &&
                                Object.keys(
                                    this.state.didacticFile.data[categoryId].subCategory
                                ).map((subCatId) => {
                                    return this.subCategoryStructure(
                                        categoryId,
                                        subCatId
                                    );
                                })}

                            <br />
                        </div>
                    </div>
                    {this.displayCategoryActionsButtons(categoryId)}
                </div>
            );
        });
    }

    render() {
        let errors = "";
        if (this.state.status && this.state.statusMessage) {
            errors = (
                <div className={"alert alert-" + this.state.status}>
                    {this.state.statusMessage}
                </div>
            );
        }
        let actionButton = "";
        let title = "Ajouter une fiche didactique";
        if (Object.keys(this.state.didacticFile.data).length > 0) {
            actionButton = (
                <div>
                    <hr />
                    <button
                        className="btn btn-info"
                        onClick={() => this.sendDidacticFile(this.props.mode)}
                    >
                        Enregistrer la fiche didactique
                    </button>
                </div>
            );
            if (this.props.mode === "update") {
                actionButton = (
                    <div>
                        <hr />

                        <button
                            className="btn btn-info"
                            onClick={() => this.sendDidacticFile(this.props.mode)}
                        >
                            Modifier la fiche didactique
                        </button>
                    </div>
                );
                title = "Mettre à jour une fiche didactique";
            }
        }

        return (
            <div className="panel-ajout-indicateur">
                <h3 className="panel-title pull-left"> {title} </h3>
                <div className="block-categorie">
                    <h4>
                        Sélectionner un type pour suivre l'évolution du fiche didactique
                    </h4>
                    <div className="actions">
                        {this.fileTitle()}
                        {this.fileDescription()}
                        <br />
                        {this.fileCategories()}
                        <br />
                        <div className="elements-alignes-a-gauche">
                            {this.addCategoryButton()}
                        </div>
                        <div>{actionButton}</div>
                        <br />
                        <div className={this.state.classeMessage}>{errors}</div>
                    </div>
                </div>
            </div>
        );
    }
}
export default AddDidacticFile;
