import React from "react";

import Header from "./Header";

/**
 * Handle error page for front.
 */
function ErrorPage(props) {
    let errorMessage = "";
    if (props.message) {
        errorMessage = (
            <>
                {" "}
                : <em>{props.message}</em>
            </>
        );
    }
    return (
        <div className="main">
            <Header {...props} dataLoaded={true} ui_show_login={false} />
            <div className="widgets full-screen-widget">
                <div className="tout">
                    <div className="support">
                        <div className="corps">
                            <p>
                                Désolé, l'application rencontre actuellement un problème{" "}
                                {errorMessage}.
                            </p>
                            <p>
                                Vous pouvez essayer d'actualiser la page. Si cette page
                                vous apparaît plusieurs fois au même endroit, vous
                                pouvez signaler ce bug en allant sur{" "}
                                <a href="https://gitlab.com/terristory/terristory/-/issues/new?issuable_template=bug_report">
                                    cette page
                                </a>{" "}
                                ou bien en envoyant un mail à{" "}
                                <em>contact@terristory.fr</em> qui décrit les étapes
                                suivies pour aboutir à cette page.
                            </p>
                            <p>
                                L'équipe de TerriSTORY® vous prie de l'excuser pour la
                                gêne occasionnée.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ErrorPage;
