/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import MDEditor from "@uiw/react-md-editor";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState, useEffect } from "react";
import Carousel from "./Carousel";
import { useMediaQuery } from "react-responsive";

import Api from "../Controllers/Api";
import { buildRegionUrl } from "../utils.js";

import config from "../settings.js";

/**
 * This function displays the Splash Screen
 */
function SplashScreen(props) {
    // Detect brower
    // Internet Explorer 6-11
    let isIE = /*@cc_on!@*/ false || !!document.documentMode;
    // Edge 20+
    let isEdge = !isIE && !!window.StyleMedia;
    const isIEState = isIE || isEdge;

    // responsive design
    const petitEcran = useMediaQuery({ query: "(max-height: 900px)" });

    const [affichageCarousel, updateAffichageCarousel] = useState(false);
    //  state for all image url arrays
    const [ensembleListeImagesCarousel, updateEnsembleListeImagesCarousel] = useState(
        []
    );
    // state which determines the width of the div with className "float-widget"
    const [sizeWindowWidthFloatWidget, updateSizeWindowWidthFloatWidget] =
        useState(500);
    // state which determines the height of the div with className "scroller-page-accueil"
    const [
        sizeWindowHeightScrollerPageAccueil,
        updateSizeWindowHeightScrollerPageAccueil,
    ] = useState(250);
    // state which determines the width of the div with className "scroller-page-accueil"
    const [
        sizeWindowWidthScrollerPageAccueil,
        updateSizeWindowWidthScrollerPageAccueil,
    ] = useState(470);
    // state which contains the array number we want to call
    const [numArray, updateNumArray] = useState(0);
    // states which determines the size of the carousel
    const [sizeCarouselHeight, updateSizeCarouselHeight] = useState(350);
    const [sizeCarouselWidth, updateSizeCarouselWidth] = useState(560);

    /**
     * deletes the cache of the application.
     */
    const suppessionCache = () => {
        // caches returns le CacheStorage
        caches.keys().then((arrayCache) => {
            // arrayCache = cache key array
            arrayCache.forEach((name) => {
                // deleting the cache
                caches.delete(name);
            });
        });
    };

    const getInformationOnClick = (e) => {
        // to modify because we have an opening of the links on the page.
        // using a span tag can be a solution otherwise you can use other HTML tags in the markdown.
        let path = e["target"];
        if (path["className"] === "carousel") {
            updateNumArray(parseInt(path["id"], 10) - 1);
            // If the value of the id is less than or equal to the length of the array then we display the carousels.
            // This prevents the application from crashing if there are no images associated with the carousel.
            if (path["id"] <= ensembleListeImagesCarousel.length) {
                updateAffichageCarousel(true);
                if (!petitEcran) {
                    updateSizeWindowWidthFloatWidget(800);
                    updateSizeWindowHeightScrollerPageAccueil(400);
                    updateSizeWindowWidthScrollerPageAccueil(580);
                }
            }
        }
    };
    /**
     * Updates the size of the divs with the classes "FloatWidget", "ScrollerPageAccueil" and the size of the component "Carousel"
     * @param {*} petitEcran : screen size
     */
    const changementTaillePopUpResponsive = (petitEcran) => {
        if (petitEcran) {
            // if the screen size is less than 900px
            updateSizeWindowWidthFloatWidget(500);
            updateSizeWindowHeightScrollerPageAccueil(250);
            updateSizeWindowWidthScrollerPageAccueil(470);
            updateSizeCarouselHeight(200);
            updateSizeCarouselWidth(450);
        } else if (!petitEcran && affichageCarousel) {
            // if the screen is larger than 900px and the carousel display is set to true
            updateSizeWindowWidthFloatWidget(800);
            updateSizeWindowHeightScrollerPageAccueil(400);
            updateSizeWindowWidthScrollerPageAccueil(580);
            updateSizeCarouselHeight(350);
            updateSizeCarouselWidth(560);
        }
    };

    // launch the getImagesPathCarousel() function on props change
    useEffect(() => {
        const getImagesPathCarousel = (e) => {
            let url = buildRegionUrl(
                config.images_carousel,
                props.parentApi.data.region
            );
            Api.callApi(url, undefined, "GET").then((response) => {
                updateEnsembleListeImagesCarousel(response.images);
            });
        };
        getImagesPathCarousel();
    }, [props]);

    useEffect(() => {
        // for each rendering of the component, the screen size is adapted
        changementTaillePopUpResponsive(petitEcran);
    });

    const retourPageAccueil = () => {
        updateAffichageCarousel(false);
        updateSizeWindowWidthFloatWidget(500);
        updateSizeWindowHeightScrollerPageAccueil(250);
        updateSizeWindowWidthScrollerPageAccueil(470);
    };

    const closePopup = (e) => {
        // do not handle click when clicking on inner div and not on main div
        if (e.target !== e.currentTarget) {
            return;
        }
        props.parentApi.callbacks.updateSplashScreen(false);
        window.removeEventListener("click", getInformationOnClick);
        suppessionCache();
    };

    // get the id of the element in which the URL is stored
    if (affichageCarousel === false) {
        window.addEventListener("click", getInformationOnClick);
    }

    let IEWarning = "";
    if (isIEState) {
        IEWarning = (
            <p className="warning">
                Ce site est optimisé pour fonctionner avec Chrome et Mozilla Firefox
            </p>
        );
    }

    let textePersonnalise = "";
    let actu = "";
    if (props.parentApi.data.settings) {
        textePersonnalise = props.parentApi.data.settings.splash_screen_custom;
        actu = props.parentApi.data.settings.actu;
    }

    return (
        <div className="splash full-screen-modal-bg" onClick={closePopup}>
            <div
                className="float-widget"
                style={{ width: sizeWindowWidthFloatWidget + "px" }}
            >
                <button className="bouton-exit-widget" title="Fermer la fenêtre.">
                    <i
                        className="bi bi-x-lg"
                        style={{ fontSize: "20px" }}
                        onClick={closePopup}
                    ></i>
                </button>
                <div className="panel panel-default">
                    Bienvenue sur le site de TerriSTORY®, outil d'aide au pilotage de la
                    transition des territoires en {props.parentApi.data.regionLabel}
                    {textePersonnalise}.
                    <br />
                    <br />
                    <div
                        className="scroller-page-accueil"
                        style={{
                            height: sizeWindowHeightScrollerPageAccueil + "px",
                            width: sizeWindowWidthScrollerPageAccueil + "px",
                        }}
                    >
                        {affichageCarousel ? (
                            <div>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    style={{ marginBottom: "10px" }}
                                    onClick={retourPageAccueil}
                                >
                                    <i className="bi bi-arrow-left-circle"></i>
                                    <span style={{ marginLeft: "5px" }}>Retour</span>
                                </button>
                                <Carousel
                                    listImg={ensembleListeImagesCarousel[numArray]}
                                    height={sizeCarouselHeight}
                                    width={sizeCarouselWidth}
                                />
                            </div>
                        ) : (
                            <div>
                                <MDEditor.Markdown source={actu} />
                            </div>
                        )}
                    </div>
                    <i>
                        Veuillez sélectionner une maille d'analyse et un territoire dans
                        les listes déroulantes situées en haut de la page.
                    </i>
                    <br />
                    <br />
                    {IEWarning}
                </div>
                <div className="panel panel-default centered-row ">
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={closePopup}
                    >
                        J'ai compris
                    </button>
                </div>
            </div>
        </div>
    );
}

export default SplashScreen;
