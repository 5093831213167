/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */
import React from "react";
import { useState } from "react";
import ReactTable from "react-table-6";

import DetailsPopup from "../DetailsPopup.js";
import Api from "../../Controllers/Api.js";
import config from "../../settings.js";
import { buildRegionUrl, filterCaseInsensitive } from "../../utils.js";
import { TablePreview } from "./DataManage.js";

function SankeyEdition({ parentApi, onSuccess, sankey, isEditing }) {
    const [errorMessage, setErrorMessage] = useState(false);
    const [editionIsLoading, setEditionIsLoading] = useState(false);

    const [dataTable, setDataTable] = useState(sankey?.data_table ?? "");
    const [paramName, setParamName] = useState(sankey?.sankey_name ?? "");
    const [paramIntroText, setParamIntroText] = useState(
        sankey?.introduction_text ?? ""
    );
    const [paramYear, setParamYear] = useState(
        sankey?.year ?? new Date().getFullYear()
    );
    const [paramDivisionFactors, setParamDivisionFactors] = useState(
        sankey?.division_factors ?? ""
    );
    const [paramDivisionUnits, setParamDivisionUnits] = useState(
        sankey?.division_units ?? ""
    );
    const [paramUnit, setParamUnit] = useState(sankey?.unit ?? "");
    const [paramIsRegionalDefault, setParamIsRegionalDefault] = useState(
        sankey?.is_regional_default ?? false
    );
    const [paramSource, setParamSource] = useState(sankey?.source ?? "");
    const [paramGeographicalLevels, setParamGeographicalLevels] = useState(
        sankey?.geographical_levels_enabled ?? ""
    );
    const [paramNbDecimals, setParamNbDecimals] = useState(sankey?.nb_decimals ?? "0");

    /**
     * Calls the API to modify the perimeter year of the table
     * @param {*} e
     */
    const handleEdition = (e) => {
        e.preventDefault();
        setEditionIsLoading(true);
        setErrorMessage("");

        let url, method;
        if (isEditing) {
            url = buildRegionUrl(
                config.api_update_sankey_metadata,
                parentApi.data.region
            ).replace("#table_name#", sankey?.data_table);
            method = "PUT";
        } else {
            url = buildRegionUrl(config.api_update_sankey_new, parentApi.data.region);
            method = "POST";
        }
        Api.callApi(
            url,
            JSON.stringify({
                sankey_name: paramName,
                introduction_text: paramIntroText,
                year: paramYear,
                data_table: dataTable,
                unit: paramUnit,
                is_regional_default: paramIsRegionalDefault,
                source: paramSource,
                geographical_levels_enabled: paramGeographicalLevels,
                division_factors: paramDivisionFactors,
                division_units: paramDivisionUnits,
                nb_decimals: paramNbDecimals,
            }),
            method
        )
            .then((response) => {
                setEditionIsLoading(false);
                setErrorMessage("Modifications enregistrées !");
                // close modal and refetch tables
                onSuccess();
            })
            .catch((e) => {
                setEditionIsLoading(false);
                setErrorMessage(e.message);
            });
    };

    const parseJson = (json) => {
        let output = [];
        for (let key in json) {
            output.push(key + ": " + json[key]);
        }
        return output.join("\n");
    };

    const saveJsonEdit = (input, editionCallback) => {
        let entries = input.split("\n");
        let finalEntries = {};
        entries.forEach((line) => {
            if (line.includes(":")) {
                let objects = line.split(":", 2);
                finalEntries[objects[0].trim()] = objects[1].trim();
            }
        });
        editionCallback(finalEntries);
    };

    return (
        <div className="mt-3">
            {!isEditing ? (
                <p>
                    Le formulaire suivant permet d'éditer les caractéristiques d'un
                    nouveau diagramme de Sankey.
                </p>
            ) : (
                <p>
                    Le formulaire suivant permet d'éditer les caractéristiques du
                    diagramme de Sankey <strong>{sankey?.data_table}</strong>.
                </p>
            )}
            <form className="mb-3" onSubmit={handleEdition}>
                {!isEditing && (
                    <div className="mb-3">
                        <label className="col-sm-6 control-label" htmlFor="data_table">
                            Nom de la table de données :{" "}
                        </label>
                        <input
                            className="col-sm-6"
                            type="text"
                            id="data_table"
                            value={dataTable}
                            onChange={(e) => setDataTable(e.target.value)}
                            required
                        />
                    </div>
                )}
                <div className="mb-3">
                    <label className="col-sm-6 control-label" htmlFor="sankey_name">
                        Nom du diagramme de Sankey :{" "}
                    </label>
                    <input
                        className="col-sm-6"
                        type="text"
                        id="sankey_name"
                        value={paramName}
                        onChange={(e) => setParamName(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label
                        className="col-sm-6 control-label"
                        htmlFor="sankey_table_name"
                    >
                        Texte d'introduction :{" "}
                    </label>
                    <textarea
                        className="col-sm-6"
                        type="text"
                        id="sankey_table_name"
                        defaultValue={paramIntroText}
                        onChange={(e) => setParamIntroText(e.target.value)}
                    ></textarea>
                </div>
                <div className="mb-3">
                    <label
                        className="col-sm-6 control-label"
                        htmlFor="sankey_division_factors"
                    >
                        Facteurs multiplicatifs* :{" "}
                    </label>
                    <textarea
                        className="col-sm-6"
                        type="text"
                        id="sankey_division_factors"
                        defaultValue={parseJson(paramDivisionFactors)}
                        onChange={(e) => {
                            saveJsonEdit(e.target.value, setParamDivisionFactors);
                        }}
                    ></textarea>
                </div>
                <div className="mb-3">
                    <label
                        className="col-sm-6 control-label"
                        htmlFor="sankey_division_units"
                    >
                        Unités* :{" "}
                    </label>
                    <textarea
                        className="col-sm-6"
                        type="text"
                        id="sankey_division_units"
                        defaultValue={parseJson(paramDivisionUnits)}
                        onChange={(e) => {
                            saveJsonEdit(e.target.value, setParamDivisionUnits);
                        }}
                    ></textarea>
                </div>
                <div className="mb-3">
                    <label
                        className="col-sm-6 control-label"
                        htmlFor="sankey_geographical_levels"
                    >
                        Échelles auxquelles le diagramme est disponible :{" "}
                    </label>
                    <input
                        className="col-sm-6"
                        type="text"
                        id="sankey_geographical_levels"
                        value={paramGeographicalLevels}
                        onChange={(e) => setParamGeographicalLevels(e.target.value)}
                    />{" "}
                </div>
                <div className="mb-3">
                    <label className="col-sm-6 control-label" htmlFor="sankey_year">
                        Année :{" "}
                    </label>
                    <input
                        className="col-sm-6"
                        type="text"
                        id="sankey_year"
                        value={paramYear}
                        onChange={(e) => setParamYear(e.target.value)}
                    />
                </div>
                <div className="mb-3">
                    <label
                        className="col-sm-6 control-label"
                        htmlFor="sankey_is_regional_default"
                    >
                        Est le diagramme par défaut de la région (remplace le précédent
                        éventuellement) :{" "}
                    </label>
                    <input
                        className="col-sm-6"
                        type="checkbox"
                        id="sankey_is_regional_default"
                        checked={paramIsRegionalDefault}
                        onChange={(e) => {
                            setParamIsRegionalDefault(!paramIsRegionalDefault);
                        }}
                    />
                </div>
                <div className="mb-3">
                    <label
                        className="col-sm-6 control-label"
                        htmlFor="sankey_nb_decimals"
                    >
                        Nombre de décimales :{" "}
                    </label>
                    <input
                        className="col-sm-6"
                        type="text"
                        id="sankey_nb_decimals"
                        value={paramNbDecimals}
                        onChange={(e) => setParamNbDecimals(e.target.value)}
                    />{" "}
                </div>
                <div className="mb-3">
                    <label className="col-sm-6 control-label" htmlFor="sankey_unit">
                        Unité :{" "}
                    </label>
                    <input
                        className="col-sm-6"
                        type="text"
                        id="sankey_unit"
                        value={paramUnit}
                        onChange={(e) => setParamUnit(e.target.value)}
                    />{" "}
                </div>
                <div className="mb-3">
                    <label className="col-sm-6 control-label" htmlFor="sankey_source">
                        Source :{" "}
                    </label>
                    <input
                        className="col-sm-6"
                        type="text"
                        id="sankey_source"
                        value={paramSource}
                        onChange={(e) => setParamSource(e.target.value)}
                    />{" "}
                </div>
                <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={editionIsLoading}
                >
                    {editionIsLoading && (
                        <>
                            <span
                                className="spinner-border spinner-border-sm me-1"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            <span className="visually-hidden">Loading...</span>
                        </>
                    )}
                    Enregistrer les modifications
                </button>
            </form>
            {errorMessage && (
                <div className="alert alert-danger" role="alert">
                    {errorMessage}
                </div>
            )}
            <p>
                *Les deux champs marqués par l'astérisque doivent être remplis avec un
                couple <em>type de territoire</em> et <em>valeur</em> par ligne, par
                exemple de la façon suivante :
            </p>
            <pre>
                epci: GWh
                <br />
                departement: MWh
            </pre>
        </div>
    );
}

class Sankeys extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sankeysList: [],
            showSankeyEdition: false,
            sankeyEditionModalContent: "",
            showDetailsModal: false,
            detailsModalContent: "",

            layoutFileReady: {},
            dataFileReady: {},
        };
    }

    componentDidMount() {
        if (
            this.props.connected &&
            this.props.userInfos &&
            this.props.userInfos.profil &&
            this.props.userInfos?.profil === "admin" &&
            this.state.sankeysList.length === 0
        ) {
        }
        this.getSankeysList();
    }

    closeSankeyEditionModal() {
        this.setState({ showSankeyEdition: false });
    }
    closeDetailsTableModal() {
        this.setState({ showDetailsModal: false });
    }

    openSankeyEditionModal(props = undefined) {
        let modalContent = (
            <SankeyEdition
                parentApi={this.props.parentApi}
                onSuccess={() => {
                    this.closeSankeyEditionModal();
                    this.getSankeysList();
                }}
                sankey={props}
                isEditing={props !== undefined}
            />
        );
        this.setState({
            showSankeyEdition: true,
            sankeyEditionModalContent: modalContent,
        });
    }

    deleteSankey(props) {
        let r = window.confirm(
            "Souhaitez vous vraiment supprimer le diagramme de Sankey '" +
                props.sankey_name +
                "' ?"
        );
        if (r !== true) {
            return;
        }
        Api.callApi(
            buildRegionUrl(
                config.api_update_sankey_delete,
                this.props.parentApi.data.region
            ).replace("#table_name#", props.data_table),
            null,
            "DELETE"
        )
            .then((response) => {
                this.getSankeysList();
                this.props.parentApi.callbacks.updateMessages(
                    "La suppression a bien été effectuée !",
                    "success"
                );
            })
            .catch((e) => {
                this.props.parentApi.callbacks.updateMessages(
                    "Un problème est survenu lors de la suppression du diagramme de Sankey (" +
                        e +
                        ").",
                    "danger"
                );
            });
    }

    getSankeysList() {
        Api.callApi(
            buildRegionUrl(config.api_list_sankeys, this.props.parentApi.data.region),
            null,
            "GET"
        )
            .then((response) => {
                this.setState({ sankeysList: response });
            })
            .catch((e) => {
                alert("Erreur lors de la récupération des données de l'API.");
            });
    }

    /**
     * Layout file to import
     * @param {file} file : the file given
     * @param {str} sankeyTable : sankey key
     */
    editLayoutFile(file, sankeyTable) {
        let layoutFileReady = { ...this.state.layoutFileReady };
        layoutFileReady[sankeyTable] = file.target.files[0];
        // update the state
        this.setState({
            layoutFileReady: layoutFileReady,
        });
    }

    /**
     * Data file to import
     * @param {file} file : the file given
     * @param {str} sankeyTable : sankey key
     */
    editDataFile(file, sankeyTable) {
        let dataFileReady = { ...this.state.dataFileReady };
        dataFileReady[sankeyTable] = file.target.files[0];
        // update the state
        this.setState({
            dataFileReady: dataFileReady,
        });
    }

    toggleDetailsCell(details) {
        let toggle = this.state.showDetailsModal;
        // we toggle between displaying details or not
        if (!toggle) {
            this.setState({
                showDetailsModal: true,
                detailsTable: details,
            });
        } else {
            this.setState({
                showDetailsModal: false,
                detailsTable: "",
            });
        }
    }

    downloadButton = (tableName) => {
        let url = buildRegionUrl(
            config.api_analysis_data_export,
            this.props.parentApi.data.region
        ).replace("#table_name#", tableName);
        return (
            <p className="export-data-button-in-popup">
                <a
                    className="btn btn-info normal-size"
                    target="_blank"
                    rel="noreferrer"
                    href={url}
                >
                    Exporter le contenu complet de la table en csv
                </a>
            </p>
        );
    };

    /**
     * Update the layout from a Sankey diagram.
     * @param {str} sankeyTable sankey key
     */
    updateLayout(sankeyTable) {
        if (!(sankeyTable in this.state.layoutFileReady)) {
            this.props.parentApi.callbacks.updateMessages(
                "Merci de fournir un fichier de template !"
            );
            return false;
        }
        let r = window.confirm(
            "Attention, cette opération n'est pas réversible. Voulez vous continuer ?"
        );
        if (r !== true) {
            return;
        }
        let layoutFileReady = { ...this.state.layoutFileReady };

        let formData = new FormData();
        formData.append("template", layoutFileReady[sankeyTable]);
        let url = buildRegionUrl(
            config.api_sankey_layout,
            this.props.parentApi.data.region
        ).replace("#table_name#", sankeyTable);

        Api.callApi(url, formData, "PUT", "default")
            .then((response) => {
                this.setState({ status: response.message });
                this.props.parentApi.callbacks.updateMessages(
                    response.message,
                    "success"
                );
                delete layoutFileReady[sankeyTable];
                this.setState({
                    layoutFileReady,
                });
            })
            .catch((e) => this.props.parentApi.callbacks.updateMessages(e.message));
    }

    /**
     * Update the data from a Sankey diagram.
     * @param {str} sankeyTable sankey key
     */
    updateData(sankeyTable) {
        if (!(sankeyTable in this.state.dataFileReady)) {
            this.props.parentApi.callbacks.updateMessages(
                "Merci de fournir un fichier de données !"
            );
            return false;
        }
        let r = window.confirm(
            "Attention, cette opération n'est pas réversible. Voulez vous continuer ?"
        );
        if (r !== true) {
            return;
        }
        let dataFileReady = { ...this.state.dataFileReady };

        let formData = new FormData();
        formData.append("data_file", dataFileReady[sankeyTable]);
        let url = buildRegionUrl(
            config.api_sankey_data,
            this.props.parentApi.data.region
        ).replace("#table_name#", sankeyTable);

        Api.callApi(url, formData, "PUT", "default")
            .then((response) => {
                this.setState({ status: response.message });
                this.props.parentApi.callbacks.updateMessages(
                    response.message,
                    "success"
                );
                delete dataFileReady[sankeyTable];
                this.setState({
                    dataFileReady,
                });
                this.getSankeysList();
            })
            .catch((e) => this.props.parentApi.callbacks.updateMessages(e.message));
    }

    render() {
        if (
            !this.props.connected ||
            !this.props.userInfos ||
            this.props.userInfos?.profil !== "admin"
        ) {
            return <div>Non accessible.</div>;
        }

        const columns = [
            {
                Header: "Nom de la table",
                accessor: "data_table",
                style: { whiteSpace: "unset" },
            },
            {
                Header: "Nom du diagramme",
                accessor: "sankey_name",
            },
            {
                Header: "Texte d'introduction",
                accessor: "introduction_text",
            },
            {
                Header: "Année",
                accessor: "year",
            },
            {
                Header: "Unité",
                accessor: "unit",
            },
            {
                Header: "Sources de données",
                accessor: "source",
                style: { whiteSpace: "unset" },
            },
            {
                Header: "Copyright activé",
                accessor: "copyright",
                filterable: false,
                Cell: (props) => <div>{props.row.copyright ? "Oui" : "Non"}</div>,
            },
            {
                Header: "Facteurs multiplicatifs selon l'échelle",
                accessor: "division_factors",
                Cell: (props) => {
                    let finalList = [];
                    let i = 0;
                    for (let scale in props.row.division_factors) {
                        finalList.push(
                            <li key={i++}>
                                {scale} : {props.row.division_factors[scale]}
                            </li>
                        );
                    }
                    return <ul>{finalList}</ul>;
                },
            },
            {
                Header: "Unités selon l'échelle",
                accessor: "division_units",
                Cell: (props) => {
                    let finalList = [];
                    let i = 0;
                    for (let scale in props.row.division_units) {
                        finalList.push(
                            <li key={i++}>
                                {scale} : {props.row.division_units[scale]}
                            </li>
                        );
                    }
                    return <ul>{finalList}</ul>;
                },
            },
            {
                Header: "Échelles activées",
                accessor: "geographical_levels_enabled",
                Cell: (props) => {
                    return props.row.geographical_levels_enabled.join(", ");
                },
            },
            {
                Header: "Sankey par défaut ?",
                accessor: "is_regional_default",
                Cell: (props) => (
                    <div>{props.row.is_regional_default ? "Oui" : "Non"}</div>
                ),
            },
            {
                Header: "Nb de décimales",
                accessor: "nb_decimals",
            },
            {
                Header: "Fichier template",
                Cell: (props) => (
                    <>
                        <div className="datatable-subcell">
                            <a
                                href={buildRegionUrl(
                                    config.api_sankey_layout,
                                    this.props.parentApi.data.region
                                ).replace("#table_name#", props.row.data_table)}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="btn btn-primary"
                            >
                                Télécharger{" "}
                                <i
                                    aria-hidden="true"
                                    style={{ cursor: "pointer", paddingLeft: "7px" }}
                                    title={"Exporter le template du diagramme"}
                                    className={"bi-download"}
                                />
                            </a>
                        </div>
                        <div className="datatable-subcell">
                            <label
                                htmlFor={"sankey_layout_file_" + props.row.data_table}
                            >
                                Nouveau template :
                            </label>
                            <input
                                type="file"
                                id={"sankey_layout_file_" + props.row.data_table}
                                onChange={(e) => {
                                    this.editLayoutFile(e, props.row.data_table);
                                }}
                            />
                            <button
                                className={
                                    "btn btn-warning " +
                                    (props.row.data_table in this.state.layoutFileReady
                                        ? ""
                                        : "hidden")
                                }
                                onClick={() => this.updateLayout(props.row.data_table)}
                            >
                                Mettre à jour
                            </button>
                        </div>
                    </>
                ),
            },
            {
                Header: "Données",
                Cell: (props) => {
                    return (
                        <>
                            <div className="datatable-subcell">
                                <img
                                    className="table_preview_icon"
                                    src="img/table_preview.svg"
                                    alt="Visualiser une partie des données"
                                    title="Visualiser une partie des données"
                                    onClick={() =>
                                        this.toggleDetailsCell(
                                            <TablePreview
                                                data={props.original.data_header}
                                                additionalElements={this.downloadButton(
                                                    props.original.data_table
                                                )}
                                            />
                                        )
                                    }
                                />
                            </div>
                            <div className="datatable-subcell">
                                <label
                                    htmlFor={"sankey_data_file_" + props.row.data_table}
                                >
                                    Nouvelles données :
                                </label>
                                <input
                                    type="file"
                                    id={"sankey_data_file_" + props.row.data_table}
                                    onChange={(e) => {
                                        this.editDataFile(e, props.row.data_table);
                                    }}
                                />
                                <button
                                    className={
                                        "btn btn-warning " +
                                        (props.row.data_table in
                                        this.state.dataFileReady
                                            ? ""
                                            : "hidden")
                                    }
                                    onClick={() =>
                                        this.updateData(props.row.data_table)
                                    }
                                >
                                    Mettre à jour
                                </button>
                            </div>
                        </>
                    );
                },
                filterable: false,
            },
            {
                Header: "Actions",
                accessor: "action",
                filterable: false,
                Cell: (row) => {
                    return (
                        <div className="actions">
                            <button
                                className={"btn btn-primary ms-1"}
                                onClick={() =>
                                    this.openSankeyEditionModal(row.original)
                                }
                            >
                                Modifier
                            </button>
                            <button
                                className={"btn btn-danger ms-1"}
                                onClick={() => this.deleteSankey(row.original)}
                            >
                                Supprimer
                            </button>
                        </div>
                    );
                },
                style: { whiteSpace: "unset" },
            },
        ];

        return (
            <div>
                <div className="panel-body panel-ajout-indicateur">
                    <h3 className="panel-title pull-left">Diagrammes de Sankey</h3>
                    <p>
                        <button
                            className={"btn btn-success ms-1"}
                            onClick={() => this.openSankeyEditionModal()}
                        >
                            Ajouter un nouveau diagramme de Sankey
                        </button>
                    </p>
                </div>
                <ReactTable
                    data={this.state.sankeysList}
                    columns={columns}
                    className="-striped"
                    defaultPageSize={10}
                    filterable={true}
                    defaultFilterMethod={(filter, row) =>
                        filterCaseInsensitive(filter, row)
                    }
                />
                {this.state.showSankeyEdition && (
                    <DetailsPopup
                        title="Modification d'un diagramme de Sankey"
                        content={this.state.sankeyEditionModalContent}
                        show={this.state.showSankeyEdition}
                        emptyMsg="Nothing to show"
                        callbackAfterClosing={() => this.closeSankeyEditionModal()}
                    />
                )}
                {this.state.showDetailsModal && (
                    <DetailsPopup
                        title="Aperçu de la table"
                        content={this.state.detailsTable}
                        show={this.state.showDetailsModal}
                        emptyMsg="Nothing to show"
                        callbackAfterClosing={() => this.toggleDetailsCell()}
                    />
                )}
            </div>
        );
    }
}

export default Sankeys;
