/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import { Route, Switch } from "react-router-dom/cjs/react-router-dom.min";

import NationalAbout from "../Components/StaticPages/NationalAbout.js";
import Contact from "../Components/StaticPages/Contact.js";
import LegalInformation from "../Components/StaticPages/LegalInformation.js";
import Modules from "../Components/StaticPages/Modules.js";
import OpenSource from "../Components/StaticPages/OpenSource.js";
import Stats from "../Components/StaticPages/Stats.js";

import Strategies from "../Components/TerritorialStrategies/Strategies";
import StrategyEdition from "../Components/TerritorialStrategies/StrategyEdition.js";
import RegionSelect from "../Components/RegionSelect.js";
import Simulator from "../Components/Simulator/Simulator.js";

import Auth from "../Components/National/Auth.js";
import DataManageDashboard from "../Components/National/DataManageDashboard.js";
import DataImport from "../Components/National/DataImport.js";
import DefaultTerritory from "../Components/National/DefaultTerritory.js";
import Login from "../Components/National/Login.js";
import NationalSubscribe from "../Components/National/Subscribe.js";
import Portal from "../Components/National/Portal.js";

import NationalLogin from "../wrappers/NationalLogin.js";
import NationalPublicPages from "../wrappers/NationalPublicPages.js";
import NotFound from "../NotFound.js";

/**
 * Router describing the routes for the homepages and the national pages.
 */
function NationalRouter({
    regions,
    regionsSettings,
    fromMenu,
    parentApi,
    history,
    connected,
    authComplete,
}) {
    const nationalEnabled = regionsSettings?.get("national")?.is_national_enabled;
    return (
        <div
            className="main"
            data-theme={parentApi.data.theme}
            data-color-mode="light" // MDEditor switch to light/dark theme depending on browser preference. We override this theme to keep consistency throughout the application.
        >
            {/* Conditional Route for National Auth */}
            {nationalEnabled && (
                <Route
                    path="/national/*"
                    render={(props) =>
                        parentApi.controller?.authManager ? (
                            <Auth {...props} parentApi={parentApi} history={history} />
                        ) : null
                    }
                />
            )}

            {/* Switch for Main Routes */}
            <Switch>
                {/* Home Route */}
                <Route
                    exact
                    path="/"
                    render={(props) => (
                        <NationalPublicPages parentApi={parentApi}>
                            <RegionSelect
                                {...props}
                                parentApi={parentApi}
                                regions={regions}
                                regionsSettings={regionsSettings}
                            />
                        </NationalPublicPages>
                    )}
                />
                {/* Common Routes */}
                <Route
                    exact
                    path="/national/a_propos"
                    render={(props) => (
                        <NationalPublicPages parentApi={parentApi}>
                            <NationalAbout {...props} parentApi={parentApi} />
                        </NationalPublicPages>
                    )}
                />
                <Route
                    exact
                    path="/national/modules"
                    render={(props) => (
                        <NationalPublicPages parentApi={parentApi}>
                            <Modules {...props} parentApi={parentApi} />
                        </NationalPublicPages>
                    )}
                />
                <Route
                    exact
                    path="/national/statistiques"
                    render={(props) => (
                        <NationalPublicPages parentApi={parentApi}>
                            <Stats {...props} parentApi={parentApi} />
                        </NationalPublicPages>
                    )}
                />
                <Route
                    exact
                    path="/national/mentions_legales_cgu"
                    render={(props) => (
                        <NationalPublicPages parentApi={parentApi}>
                            <LegalInformation
                                {...props}
                                parentApi={parentApi}
                                fromMenu={fromMenu}
                                hideBackToMap={true}
                            />
                        </NationalPublicPages>
                    )}
                />
                <Route
                    exact
                    path="/national/open_source"
                    render={(props) => (
                        <NationalPublicPages parentApi={parentApi}>
                            <OpenSource
                                {...props}
                                parentApi={parentApi}
                                fromMenu={fromMenu}
                                hideBackToMap={true}
                            />
                        </NationalPublicPages>
                    )}
                />
                <Route
                    exact
                    path="/national/contact"
                    render={(props) => (
                        <NationalPublicPages parentApi={parentApi}>
                            <Contact
                                {...props}
                                parentApi={parentApi}
                                hideBackToMap={true}
                            />
                        </NationalPublicPages>
                    )}
                />
                {/* National Routes */}
                {nationalEnabled && (
                    <>
                        <Route
                            exact
                            path="/national"
                            render={(props) => (
                                <NationalPublicPages parentApi={parentApi}>
                                    <DefaultTerritory
                                        {...props}
                                        parentApi={parentApi}
                                    />
                                </NationalPublicPages>
                            )}
                        />
                        <Route
                            exact
                            path="/national/login"
                            render={(props) => (
                                <NationalPublicPages parentApi={parentApi}>
                                    <Login {...props} parentApi={parentApi} />
                                </NationalPublicPages>
                            )}
                        />
                        <Route
                            exact
                            path="/national/inscription"
                            render={(props) => (
                                <NationalPublicPages parentApi={parentApi}>
                                    <NationalSubscribe
                                        {...props}
                                        parentApi={parentApi}
                                    />
                                </NationalPublicPages>
                            )}
                        />
                        <Route
                            exact
                            path="/national/:region/portail"
                            render={(props) => (
                                <NationalLogin
                                    parentApi={parentApi}
                                    connected={connected}
                                >
                                    <Portal {...props} parentApi={parentApi} />
                                </NationalLogin>
                            )}
                        />
                        <Route
                            exact
                            path="/national/:region/import"
                            render={(props) => (
                                <NationalLogin
                                    parentApi={parentApi}
                                    connected={connected}
                                >
                                    <DataManageDashboard
                                        {...props}
                                        parentApi={parentApi}
                                    />
                                </NationalLogin>
                            )}
                        />
                        <Route
                            exact
                            path="/national/:region/import/formulaire"
                            render={(props) => (
                                <NationalLogin
                                    parentApi={parentApi}
                                    connected={connected}
                                >
                                    <DataImport {...props} parentApi={parentApi} />
                                </NationalLogin>
                            )}
                        />
                        {(authComplete ||
                            localStorage.getItem("access_token") === "") && (
                            <>
                                <Route
                                    exact
                                    path="/national/:region/simulateur"
                                    render={(props) => (
                                        <NationalLogin
                                            parentApi={parentApi}
                                            connected={connected}
                                        >
                                            <Simulator
                                                {...props}
                                                parentApi={parentApi}
                                            />
                                        </NationalLogin>
                                    )}
                                />
                                <Route
                                    exact
                                    path="/national/:region/strategies_territoriales"
                                    render={(props) => (
                                        <NationalLogin
                                            parentApi={parentApi}
                                            connected={connected}
                                        >
                                            <Strategies
                                                {...props}
                                                parentApi={parentApi}
                                                fromMenu={fromMenu}
                                            />
                                        </NationalLogin>
                                    )}
                                />
                                <Route
                                    path="/national/:region/edition_strategie"
                                    render={(props) => (
                                        <NationalLogin
                                            parentApi={parentApi}
                                            connected={connected}
                                        >
                                            <StrategyEdition
                                                {...props}
                                                parentApi={parentApi}
                                                fromMenu={fromMenu}
                                            />
                                        </NationalLogin>
                                    )}
                                />
                            </>
                        )}
                    </>
                )}
                {/* Catch-all Route */}
                <Route path="/national/*">
                    <NotFound />
                </Route>
            </Switch>
        </div>
    );
}

export default NationalRouter;
