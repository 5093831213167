/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

/**
 * Ce fichier rassemble plusieurs fonction utiles dans toute l'application.
 */
import html2canvas from "html2canvas";
import Api from "./Controllers/Api.js";
import jszip from "jszip";
import iconv from "iconv-lite";
import saveAs from "file-saver";

/**
 * Cette fonction retire les espaces en début et fin d'une chaîne de caractères.
 * @param {string} string la chaîne de caractères à nettoyer
 * @returns la chaîne de caractères nettoyées
 */
export const trim = (string) => {
    let TRIM_RE = /^\s+|\s+$/g;
    return string.replace(TRIM_RE, "");
};

/**
 * Transforme une url brute de requête sur l'API (// template)
 * en une url contenant l'information de région.
 * @param {str} url
 * @param {str} region le nom de la région considérée
 * @returns l'url avec la bonne région encodée
 */
export const buildRegionUrl = (url, region) => {
    return url.replace("#region#", region);
};

/**
 * Utilise le bon format de chaîne de caractère pour l'URL d'une région en
 * transformant les - en _.
 * @param {str} region le nom de la région considérée
 * @returns le nom de région corrigée
 */
export const convertRegionToUrl = (region) => {
    return region.replace(/-/g, "_");
};

/**
 * Retire le nom de la région dans l'URL
 * @param {string} layer l'URL de la couche
 * @param {string} region le nom de la région considérée
 * @returns l'URL initiale dont on a retiré le nom de la région + _
 */
export const removeRegionFromLayer = (layer, region) => {
    return layer.replace(convertRegionToUrl(region) + ".", "");
};
/**
 * Retire les caractères accentués et spéciaux d'une chaîne de caractères.
 * @param {string} string la chaîne à normaliser
 * @returns la chaîne de caractères normalisées
 */
export const normalize = (string) => {
    return string
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();
};
/**
 * Cette fonction permet de factoriser l'export des graphiques en format PNG.
 * Deprecated => use ref.
 * @param {*} ref : le réfrence de la partie du code à exporter
 * @param {*} nom : le nom sous lequel enregistrer le graphique exporté
 */
export const saveAsPng = (ref, nom) => {
    let input = ref.current;
    html2canvas(input, {
        allowTaint: true,
        useCORS: true,

        letterRendering: true,
        removeContainer: true,
        onclone: (document) => {
            Array.from(
                document.querySelectorAll(
                    ".formulaire-thematique h4, .formulaire-thematique .analysis-launcher-button"
                )
            ).forEach((e) => {
                let existingStyle = e.getAttribute("style") || "";
                e.setAttribute(
                    "style",
                    existingStyle +
                        "; display:block; word-break: keep-all; text-align: center;"
                );
            });
            Array.from(document.querySelectorAll(".do-not-print")).forEach((e) => {
                e.setAttribute("hidden", true);
            });
        },
    }).then(function (canvas) {
        var link = document.createElement("a");
        document.body.appendChild(link);
        link.download = nom + ".png";
        link.href = canvas.toDataURL();
        link.target = "_blank";
        link.click();
    });
};
/**
 * Cette fonction notifie l'API qu'un tableau de bord a été visité
 *
 * @param {string} url le code de l'url à visiter
 * @param {string} region le code de la région
 * @param {int} idUtilisateur l'ID de l'utilisateur
 * @param {int} tableau_id l'ID du tableau visité
 * @param {int} code_territoire le code du territoire
 * @param {string} type_territoire le type de territoire
 */
export const consulteTableauDeBord = (
    url,
    region,
    idUtilisateur,
    tableau_id,
    code_territoire,
    type_territoire
) => {
    const body = JSON.stringify({
        tableau_id: tableau_id,
        code_territoire: code_territoire,
        type_territoire: type_territoire,
        id_utilisateur: idUtilisateur,
    });
    Api.callApi(buildRegionUrl(url, region), body, "PUT");
};

/**
 * Cette fonction notifie l'API qu'une autre page a été visitée
 *
 * @param {string} url le code de l'url à visiter
 * @param {string} region le code de la région
 * @param {int} idUtilisateur l'ID de l'utilisateur
 * @param {string} page le nom de la page
 * @param {string} details les détails supplémentaires
 */
export const consulteAutrePage = (url, region, idUtilisateur, page, details) => {
    const body = JSON.stringify({
        page: page,
        details: details,
        id_utilisateur: idUtilisateur,
    });
    Api.callApi(buildRegionUrl(url, region), body, "PUT").catch((error) => {
        console.warn(`Error when trying to push consultation stat: ${error}`);
    });
};

/**
 * Crée une URL pointant vers le PDF méthodologique ou bien retourne le lien
 * complet si le nom du PDF en est un.
 *
 * @param {string} mainUrl l'url du fichier de settings
 * @param {string} region le code de la région à faire figurer dans l'url
 * @param {string} pdfName le nom du PDF (ou l'URL) figurant dans la base
 * @returns l'URL complète pointant vers le PDF
 */
export const createPdfMethodoLink = (mainUrl, region, pdfName) => {
    if (!pdfName) {
        return "";
    } else if (pdfName.indexOf("http") === 0) {
        return pdfName;
    } else {
        return mainUrl.replace("#region#", region) + pdfName;
    }
};

/**
 * Test if a hex color corresponds to a light color or not.
 * @param {string} hex the color parameter
 * @returns true if luminance is higher than 200 (light) or false otherwise
 */
export const isLightColor = (hex) => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (!result) {
        return false;
    }
    let r = parseInt(result[1], 16),
        g = parseInt(result[2], 16),
        b = parseInt(result[3], 16);
    let luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
    return luma > 150;
};

/**
 * Exporte des données au format CSV et les télécharge dans un CSV ou ZIP
 * @param {*} data : les données brutes à exporter
 * @param {*} exportType : le type de fichier exporté : "csv" ou "zip"
 * @param {*} fileName : le nom de fichier/zip à exporter
 * @param {*} pdfUrl : l'url du PDF méthodologie
 */
export const exportToCSV = (
    data,
    exportType,
    fileName = "",
    pdfUrl = "",
    pdfName = "",
    callback = false,
    encoding = "utf8"
) => {
    const keys = Object.keys(data[0]);
    let csv = keys.join(";") + "\r\n"; // Inserer les noms de colonne en premier
    for (let lineid in data) {
        // Pour chaque objet, contruire une ligne CSV séparé par des points-virgules
        csv += keys.map((k) => JSON.stringify(data[lineid][k])).join(";") + "\r\n";
    }
    if (encoding !== "utf8") {
        csv = iconv.encode(csv, encoding);
    }

    let exportedFilename = fileName || "export";

    exportedFilename = exportedFilename.replace(/\s/g, ""); //supprimer les espaces
    exportedFilename = exportedFilename.replace(/\//g, "_"); //remplacer le "/" par "_"
    if (exportType === "zip") {
        const zip = new jszip(); //  Crée un archive .zip
        zip.file(exportedFilename + ".csv", csv); // Ajouter le fichier CSV au zip
        const PdfMethodo = fetch(pdfUrl).then((t) => t.blob()); // Retourne le PDF-méthodo à partir de son URL
        zip.file(pdfName, PdfMethodo); // Ajouter le PDF au zip
        zip.generateAsync({ type: "blob" }).then(function (content) {
            saveAs(content, exportedFilename + ".zip"); // Exporter le zip
            if (callback) {
                callback();
            }
        });
    } else {
        const blob = new Blob([csv], { type: "text/csv;charset=" + encoding + ";" }); // Renvoie un objet Blob qui contient une concaténation de toutes les données du tableau passées au constructeur.
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob); // Cette fonction permet de créer un DOMString qui contient l'url de l'objet à exporter
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFilename + ".csv");
        link.style.visibility = "hidden";
        link.click();
        if (callback) {
            callback();
        }
    }
};

/**
 * Convert a string to a normalized string lowercase without unusual characters.
 *
 * Thanks to https://stackoverflow.com/a/37511463
 * @param {str} str input string
 * @returns string without unusual characters.
 */
export const normalizeString = (str) => {
    return str
        .toLowerCase()
        .normalize("NFD")
        .replace(/\p{Diacritic}/gu, "");
};

/**
 * Filter React Table rows based on a filter value. Fix the case-sensitive default
 * filter.
 * cf. https://github.com/TanStack/table/issues/335#issuecomment-308655776
 * @param {object} filter the filter object from the input text
 * @param {object} row the row to test
 * @returns whether a row contains the filter value
 */
export const filterCaseInsensitive = (filter, row) => {
    const id = filter.pivotId || filter.id;

    if (row[id] !== null) {
        return row[id] !== undefined
            ? normalizeString(String(row[id])).includes(normalizeString(filter.value))
            : true;
    }
};

export const addPlusSign = (n) => (n > 0 ? "+" : "") + n;

export const splitCategoryFilters = (s) => {
    var i = s.indexOf(".");
    return [s.slice(0, i), s.slice(i + 1)];
};

// cf. https://byby.dev/js-slugify-string
export const slugify = (s) => {
    return String(s)
        .normalize("NFKD") // split accented characters into their base characters and diacritical marks
        .replace(/[\u0300-\u036f]/g, "") // remove all the accents, which happen to be all in the \u03xx UNICODE block.
        .trim() // trim leading or trailing whitespace
        .toLowerCase() // convert to lowercase
        .replace(/['.\s]/g, "-") // replace apostrophes, dots and spaces
        .replace(/[^a-z0-9-]/g, "") // remove non-alphanumeric characters
        .replace(/-+/g, "-"); // remove consecutive hyphens
};

export const getDateFromStr = (s) => {
    if (!s) {
        return null;
    }
    const regexFrenchFormat = /^(\d{2})[.-/](\d{2})[.-/](\d{4})$/;
    const matchFrenchFormat = s.match(regexFrenchFormat);

    const regexYearOnly = /^(\d{4})$/;
    const matchYearOnly = s.match(regexYearOnly);

    if (matchFrenchFormat) {
        const day = matchFrenchFormat[1];
        const month = matchFrenchFormat[2];
        const year = matchFrenchFormat[3];
        return new Date(Date.UTC(year, month - 1, day, 0, 0, 0));
    } else if (matchYearOnly) {
        const year = matchYearOnly[1];
        return new Date(Date.UTC(year, 0, 1, 0, 0, 0));
    } else {
        return new Date(s);
    }
};
