/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";

import { buildRegionUrl } from "../../utils.js";
import config from "../../settings.js";
import Api from "../../Controllers/Api.js";

/**
 * This component is used to create an link
 */
class DataTable extends React.Component {
    constructor(props) {
        super(props);
        // we retrieve initial filters
        this.state = {
            name: props.name,
            representation: "pcaet-trajectory-table",
            disabled: false,
            isLoading: false,
            dataTraj: undefined,
            refYear: undefined,
            unit: undefined,
        };
    }

    componentDidMount() {
        // Get data from API
        this.fetchConfiguration();
    }

    componentDidUpdate(prevProps) {
        const currentZone = this.props.zoneId ?? this.props.parentApi.data.currentZone;

        // we find different reasons to retrieve configuration
        if (
            this.props.id_analysis &&
            ((prevProps.zoneId ?? prevProps.parentApi.data.currentZone) !==
                currentZone ||
                this.props.id_analysis !== prevProps.id_analysis)
        ) {
            this.fetchConfiguration();
        }
    }

    componentWillUnmount() {
        if (this.dataPromise) {
            this.dataPromise.abort();
            this.props.parentApi.callbacks.updateDataLoaded(true);
        }
    }

    fetchConfiguration() {
        let codeInseeTerritoire =
            this.props.zoneId ?? this.props.parentApi.data.currentZone;
        let pZone =
            "?zone=" + (this.props.zoneType ?? this.props.parentApi.data.zone.zone);
        let pMaille =
            "&maille=" +
            (this.props.zoneMaille ?? this.props.parentApi.data.zone.maille);
        let pZoneId = "&zone_id=" + codeInseeTerritoire;
        if ((this.props.zoneType ?? this.props.parentApi.data.zone.zone) === "region") {
            pZoneId = "&zone_id=" + this.props.parentApi.data.regionCode;
        }
        this.setState({ isLoading: true });
        let dataSource =
            config.api_pcaet_trajectory_details.replace(
                "#trajectory_id#",
                this.props.id_analysis
            ) +
            pZone +
            pMaille +
            pZoneId;

        this.props.parentApi.callbacks.updateDataLoaded(false);

        if (this.dataPromise) this.dataPromise.abort();
        this.dataPromise = Api.callApi(
            buildRegionUrl(dataSource, this.props.parentApi.data.region)
        );
        this.dataPromise
            .then((json) => {
                this.props.parentApi.callbacks.updateDataLoaded(true);
                if (!json) {
                    this.setState({
                        isLoading: false,
                        dataTraj: undefined,
                    });
                    return;
                }
                if (json["disabled"] === true) {
                    this.setState({
                        disabled: true,
                        isLoading: false,
                    });
                    return;
                }

                this.setState({
                    dataTraj: json,
                    refYear: json["ref_year"],
                    unit: json["unit"],
                    isLoading: false,
                });
            })
            .catch((error) => {
                if (error.name === "AbortError") return;
                this.props.parentApi.callbacks.updateDataLoaded(true);
                this.setState({ isLoading: false, dataTraj: undefined });
                console.error(error);
            });
    }

    render() {
        if (this.state.disabled) {
            return (
                <div className="charts-legende">
                    <div className={"confid-chart"}>
                        Cet indicateur n'est pas activé actuellement, veuillez contacter
                        l'administrateur régional.
                    </div>
                </div>
            );
        }
        if (this.state.isLoading) {
            return (
                <div className="charts-legende">
                    <div className={"loader"}></div>
                </div>
            );
        }
        if (this.state.dataTraj?.pcaet_trajectory == null) {
            return (
                <div className="charts-legende">
                    <div className={"confid-chart"}>
                        Aucune donnée disponible actuellement.
                    </div>
                </div>
            );
        }

        let finalArrayColumns = {};
        let totalByColumn = {};
        let finalTableHead = [];
        let finalTableBody = [];
        let columnsHeads = [<th key="year">Année</th>];

        let i = 0;
        for (const sectorData of this.state.dataTraj.pcaet_trajectory) {
            columnsHeads.push(<th key={i}>{sectorData.nom}</th>);
            i++;
            let refValue = undefined,
                evol = undefined;
            for (const data of sectorData.data) {
                if (refValue === undefined) {
                    refValue = data.valeur;
                } else if (refValue !== 0) {
                    evol = ((data.valeur - refValue) / refValue) * 100.0;
                }
                if (!finalArrayColumns[data.annee]) {
                    finalArrayColumns[data.annee] = [];
                    totalByColumn[data.annee] = 0;
                }
                totalByColumn[data.annee] += data.valeur;
                finalArrayColumns[data.annee].push(
                    <td key={data.annee + "_" + sectorData.nom}>
                        {new Intl.NumberFormat("fr-FR").format(data.valeur)}
                        <br />
                        <span className="evol_digit">
                            {evol !== undefined ? <>{Math.round(evol)}%</> : "-"}
                        </span>
                    </td>
                );
            }
        }

        columnsHeads.push(
            <th key={i}>
                <strong>Total</strong>
            </th>
        );
        finalTableHead.push(<tr key="row_heads">{columnsHeads}</tr>);

        let refValue = undefined,
            evol = undefined;
        for (const year in finalArrayColumns) {
            if (Object.hasOwnProperty.call(finalArrayColumns, year)) {
                const columns = finalArrayColumns[year];
                if (refValue === undefined) {
                    refValue = totalByColumn[year];
                } else {
                    evol = ((totalByColumn[year] - refValue) / refValue) * 100.0;
                }
                finalTableBody.push(
                    <tr key={year}>
                        <td key="year_column">
                            {year}
                            <br />
                            <span className="evol_digit">
                                {year === this.state.refYear
                                    ? "année de référence"
                                    : "objectif"}
                            </span>
                        </td>
                        {columns}
                        <td key="total_column">
                            {new Intl.NumberFormat("fr-FR").format(
                                Math.round(totalByColumn[year])
                            )}
                            <br />
                            <span className="evol_digit">
                                {evol ? <>{Math.round(evol)}%</> : "-"}
                            </span>
                        </td>
                    </tr>
                );
            }
        }

        return (
            <div>
                <table className="pcaet_table">
                    <thead>{finalTableHead}</thead>
                    <tbody>{finalTableBody}</tbody>
                </table>
                <p className="pcaet_table_legend">
                    Objectifs en {this.state.unit} et en % par rapport à l'année de
                    référence
                </p>
            </div>
        );
    }
}

export default DataTable;
