/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";

import Api from "../../Controllers/Api";

import config from "../../settings.js";
import { buildRegionUrl } from "../../utils.js";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState, useEffect, useRef } from "react";
import Select from "react-select";
import configData from "../../settings_data.js";
import { SketchPicker } from "react-color";
import reactCSS from "reactcss";
import Creatable from "react-select/creatable";

/**
 * Indicator data management (Registry data)
 */
function ConfigurerDonneesRegistre({
    connected,
    userInfos,
    parentApi,
    donneesRegistre,
    configurer,
    metaDonnees,
    annee,
}) {
    const [listeColonnesRegistre, majListeColonnesRegistre] = useState([]);
    const [erreur, majErreur] = useState("");
    const [intitulesParColonnes, majIntitulesParColonnes] = useState([]);
    const [categorie, majCategorie] = useState([]);
    const [modalite, majModalite] = useState("");
    const [modaliteId, majModaliteId] = useState(0);
    const [couleur, majCouleur] = useState(configData.defaultColor);
    const [displayCouleurPicker, majDisplayCouleurPicker] = useState(false);
    const [colonneCourante, majColonneCourante] = useState([]);
    const [isLoadingData, setLoadingData] = useState(false);
    const [isNeedingDataUpdate, setNeedForDataUpdate] = useState(true);
    const [colonneValeur, majColonneValeur] = useState("");
    const [colonneCommune, majColonneCommune] = useState("");
    const [colonneAnnee, majColonneAnnee] = useState("");
    const [donneesFinales, majDonneesFinales] = useState({});
    const [listeTablesDispo, majListeTablesDispo] = useState([]);
    const [nomTable, majNomTable] = useState("");
    const [message, majMessage] = useState("");
    const [messageChargement, majMessageChargement] = useState("");

    const prev = useRef({ categorie }).current; // previous properties

    useEffect(() => majModaliteId(modaliteId), [modaliteId]);
    useEffect(() => majModalite(modalite), [modalite]);
    useEffect(() => majCouleur(couleur), [couleur]);
    useEffect(() => {
        if (JSON.stringify(prev.categorie) !== JSON.stringify(categorie)) {
            majCategorie(categorie);
        }
    }, [prev.categorie, categorie]);

    // If the columns of the selected dataset (to be configured) have not been downloaded yet.
    // Otherwise, there is no need to request the API ODRé
    if (isNeedingDataUpdate) {
        obtenirListeColonnes();
    }

    /**
     * Get the columns of the data table to configure
     */
    function obtenirListeColonnes() {
        // is not already loading data
        if (!isLoadingData) {
            setLoadingData(true);

            let url = config.api_liste_colonnes_donnees_registre;
            Api.callApi(
                buildRegionUrl(url, parentApi.data.region).replace(
                    "#table#",
                    donneesRegistre.jeu_de_donnees
                )
            )
                .then((response) => {
                    majListeColonnesRegistre(response.liste_colonnes); // List of columns
                    majIntitulesParColonnes(response.valeurs_par_colonnes); // List of values for each column, without duplicates
                    setNeedForDataUpdate(false); // It is no longer necessary to download the columns
                    setLoadingData(false);
                    majDonneesFinales(response.donnees_finales); // Registry data
                    majListeTablesDispo(response.liste_tables_dispo); // List of available tables requested by at least n indicators
                })
                .catch((e) => {
                    majErreur(e.message);
                });
        }
    }

    /**
     * Updates the new title of the modality of the category to be configured.
     * @param {e} event : the value selected by the user
     * @param {a} string : Name of the column whose configuration is updated (new title, color, new identifier)
     * @param {index} integer : index used to locate the right category to update (cat is a list)
     */
    function majNouvelIntitule(e, a, index) {
        let modaliteId;
        let nvCouleur = couleur;
        let associationModaliteId = categorie;
        if (
            associationModaliteId[index].association[a] &&
            associationModaliteId[index].association[a].modaliteId
        ) {
            modaliteId = associationModaliteId[index].association[a].modaliteId;
        }

        if (
            associationModaliteId[index].association[a] &&
            associationModaliteId[index].association[a].couleur
        ) {
            nvCouleur = associationModaliteId[index].association[a].couleur;
        }

        associationModaliteId[index].association[a] = {
            modalite: e.target.value,
            modaliteId: modaliteId,
            couleur: nvCouleur,
        };
        majModalite(e.target.value);
        majCategorie(associationModaliteId);
    }

    /**
     * Updates the new modality identifier of the category to be configured.
     * @param {e} event : the value selected by the user
     * @param {a} string : Name of the column whose configuration is updated (new title, color, new identifier)
     * @param {index} integer : index used to locate the correct category to update (cat is a list)
     */
    function majIdentifiant(e, a, index) {
        let modalite;
        let nvCouleur = couleur;
        let associationModaliteId = categorie;
        if (
            associationModaliteId[index].association[a] &&
            associationModaliteId[index].association[a].modalite
        ) {
            modalite = associationModaliteId[index].association[a].modalite;
        }

        if (
            associationModaliteId[index].association[a] &&
            associationModaliteId[index].association[a].couleur
        ) {
            nvCouleur = associationModaliteId[index].association[a].couleur;
        }

        associationModaliteId[index].association[a] = {
            modalite: modalite,
            modaliteId: e.target.value,
            couleur: nvCouleur,
        };
        majModaliteId(e.target.value);
        majCategorie(associationModaliteId);
    }

    /**
     * Updates the new color of the modality of the category to be configured
     * @param {color} string : hexadecimal color
     * @param {e} event : the value selected by the user
     * @param {a} string : Name of the column whose configuration is updated (new title, color, new identifier)
     * @param {index} integer : index used to locate the correct category to update (cat is a list)
     */
    function majCouleurModalite(color, e, a, index) {
        let modalite;
        let modaliteId;
        let displayCouleurPicker;
        let associationModaliteId = categorie;

        if (
            associationModaliteId[index].association[a] &&
            associationModaliteId[index].association[a].modalite
        ) {
            modalite = associationModaliteId[index].association[a].modalite;
        }

        if (
            associationModaliteId[index].association[a] &&
            associationModaliteId[index].association[a].modaliteId
        ) {
            modaliteId = associationModaliteId[index].association[a].modaliteId;
        }

        if (
            associationModaliteId[index].association[a] &&
            associationModaliteId[index].association[a].displayCouleurPicker
        ) {
            displayCouleurPicker =
                associationModaliteId[index].association[a].displayCouleurPicker;
        }

        associationModaliteId[index].association[a] = {
            modalite: modalite,
            modaliteId: modaliteId,
            couleur: color.hex,
            displayCouleurPicker: displayCouleurPicker,
        };

        majCategorie(associationModaliteId);
        majCouleur(color.hex);
    }

    /**
     * Removes or adds a column to be configured as a user-triggered event category
     * @param {colonne} string : name of the column to remove from the list of columns to configure as a category
     * @param {index} integer : position of the category in the list
     */
    function majCategorieSelectionnee(colonne, index) {
        let listeColonnes = [];
        for (let a of colonne) {
            if (a.label !== "") {
                listeColonnes.push(a.label);
            }
        }

        let cat = categorie;
        let pos = 0;
        for (let c of cat) {
            if (listeColonnes.indexOf(c.colonne) === -1) {
                // If the column is no longer part of the list of columns to configure
                cat.splice(pos, 1); // Remove it from the category table
            }
            pos += 1;
        }

        if (colonne.length > colonneCourante.length) {
            // If the list of columns passed as an argument is longer than the one that is part of the component's state.
            cat.push({
                colonne: colonne[index].label,
                nom: "",
                region: parentApi.data.region,
                association: {},
            });
            for (let i in intitulesParColonnes[colonne[index].label]) {
                if (intitulesParColonnes[colonne[index].label][i] !== "") {
                    cat[index].association[
                        intitulesParColonnes[colonne[index].label][i]
                    ] = {
                        modalite: intitulesParColonnes[colonne[index].label][i].replace(
                            ".",
                            " "
                        ),
                        modaliteId: i,
                        couleur: configData.defaultColor,
                    };
                }
            }
        }
        for (let a in intitulesParColonnes[colonne.label]) {
            if (intitulesParColonnes[colonne.label][a] !== "") {
                cat[index].association[intitulesParColonnes[colonne.label][a]] = {
                    couleur: configData.defaultColor,
                };
            }
        }

        majColonneCourante(colonne);
        majCategorie(cat);
    }

    /**
     * Returns the graphical rendering of the table with which we configure the categories
     * @param {colonne} string : List of columns that the user has selected for configuration
     */
    function afficherListeIntitulesPourCategorie(colonne) {
        let cat = categorie;
        let listeFormTexte = [];
        for (let index in cat) {
            let enTeteLibelle = (
                <b className="col-sm-2 control-label">Libelle actuel</b>
            );
            let enTeteNouvLibelle = (
                <b className="form-inline taille-libelle-registre" type="text">
                    Nouveau libellé
                </b>
            );
            let enTeteIdentifiant = (
                <b className="form-inline taille-libelle-registre" type="text">
                    Identifiant
                </b>
            );
            let enTete = (
                <div
                    key="en-tete"
                    className="form-horizontal"
                    style={{ display: "flex", alignItems: "center" }}
                >
                    {enTeteLibelle}
                    {enTeteNouvLibelle}
                    {enTeteIdentifiant}
                </div>
            );
            listeFormTexte.push(enTete);
            let modaliteIDParDefaut = 1;
            for (let a of intitulesParColonnes[cat[index].colonne]) {
                let displayCouleurPicker = false;
                let couleurSelectionnee = configData.defaultColor;
                if (cat[index]) {
                    if (cat[index].association[a]) {
                        displayCouleurPicker =
                            cat[index].association[a].displayCouleurPicker;
                        if (cat[index].association[a].couleur) {
                            couleurSelectionnee = cat[index].association[a].couleur;
                        }
                    }
                }
                const styles = reactCSS({
                    default: {
                        color: {
                            background: couleurSelectionnee,
                        },
                    },
                });
                let formulaireCouleur = (
                    <div key={"form-couleur-" + a} className="custom-block">
                        <div
                            key={"couleur-click-" + a}
                            className="color-picker-swatch"
                            onClick={(e) => handleCouleurClick(e, a, index)}
                        >
                            <div
                                key={"style-couleur-" + a}
                                className="color-picker-color"
                                style={styles.color}
                            />
                        </div>
                        {displayCouleurPicker ? (
                            <div key={"popover-" + a} className="color-picker-popover">
                                <div
                                    key={"color-picker-cover-" + a}
                                    className="color-picker-cover"
                                    onClick={(e) => handleCouleurClose(e, a, index)}
                                />
                                <SketchPicker
                                    color={couleurSelectionnee}
                                    key={"couleur-" + a}
                                    onChange={(color, e) =>
                                        majCouleurModalite(color, e, a, index)
                                    }
                                />
                            </div>
                        ) : null}
                    </div>
                );

                let libelle = (
                    <label key={"libelle-" + a} className="col-sm-2 control-label">
                        {a}
                    </label>
                );
                let formTexte = (
                    <input
                        key={"txt-" + a}
                        className="form-control form-inline col-sm-4 taille-formulaire-registre"
                        type="text"
                        defaultValue={modaliteIDParDefaut}
                        onChange={(e) => majIdentifiant(e, a, index)}
                    ></input>
                );
                let formTexteIntitule = (
                    <input
                        key={"form-" + a}
                        className="form-control form-inline col-sm-4 taille-formulaire-registre"
                        type="text"
                        defaultValue={a}
                        onChange={(e) => majNouvelIntitule(e, a, index)}
                    ></input>
                );
                let elemAAjouter = (
                    <div
                        key={"elem-" + a}
                        className="form-horizontal"
                        style={{ display: "flex", alignItems: "center" }}
                    >
                        {libelle}
                        {formTexteIntitule}
                        {formTexte}
                        {formulaireCouleur}
                    </div>
                );
                if (a !== "") {
                    listeFormTexte.push(elemAAjouter);
                }
                modaliteIDParDefaut += 1;
            }
        }
        return listeFormTexte;
    }

    /**
     * colorPicker management
     * Updates the configuration of a category by activating the display of the color selection form.
     * @param {e} event : the value selected by the user
     * @param {a} string : name of the column whose configuration is updated (new title, color, new identifier)
     * @param {index} integer : index used to locate the right category to update (cat is a list)
     * @return {dom} component rendering
     */
    function handleCouleurClick(e, a, index) {
        let cat = categorie;
        if (cat[index].association[a]) {
            let displayCouleurPicker = false;
            if (cat[index].association[a].displayCouleurPicker !== undefined) {
                displayCouleurPicker = cat[index].association[a].displayCouleurPicker;
            }
            cat[index].association[a].displayCouleurPicker = !displayCouleurPicker;
        } else {
            cat[index].association[a] = { displayCouleurPicker: true };
        }

        majCategorie(cat);
        majDisplayCouleurPicker(!displayCouleurPicker);
    }

    /**
     * Integrates data after user validation
     */
    function integrerDonnees() {
        if (colonneCommune === "") {
            majErreur("Veuillez sélectionner la colonne « Commune »");
        }

        if (colonneValeur === "") {
            majErreur("Veuillez sélectionner la colonne « Valeur »");
        }

        let colAnnee = colonneAnnee;

        if (colonneAnnee === "") {
            let r = window.confirm(
                "Aucune colonne qui contient l'information sur le millésime des données a été sélectionné. Par défaut, nous utiliserons l'année de la création des données. Souhaitez-vous continuer ?"
            );
            if (r !== true) {
                return;
            } else {
                colAnnee = colonneAnnee;
            }
        }

        let donneesAIntegrer = {
            donnees: donneesFinales,
            categorie: categorie,
            colAnnee: colAnnee,
            colCommune: colonneCommune,
            colValeur: colonneValeur,
            annee: annee,
            nomTable: nomTable,
        };
        let r = window.confirm(
            "Attention cette action est irréversible. Souhaitez-vous poursuivre ? "
        );
        if (r !== true) {
            return;
        } else {
            majErreur("");
            majMessage("");
            majMessageChargement("Chargement...");
            Api.callApi(
                buildRegionUrl(
                    config.api_integration_donnees_registre,
                    parentApi.data.region
                ).replace("#table#", donneesRegistre.jeu_de_donnees),
                JSON.stringify(donneesAIntegrer),
                "PUT",
                "default"
            )
                .then((response) => {
                    majMessage(response.message);
                    majErreur("");
                    majMessageChargement("");
                })
                .catch((e) => {
                    majErreur(e.message);
                    majMessage("");
                    majMessageChargement("");
                });
        }
    }

    /**
     * Closes the colorpicker popup
     * Updates the configuration of a category by disabling the display of the color selection form
     * @param {e} event :  the value selected by the user
     * @param {a} string : name of the column whose configuration is updated (new title, color, new identifier)
     * @param {index} integer : index used to locate the right category to update (cat is a list)
     */
    function handleCouleurClose(e, a, index) {
        let cat = categorie;
        if (cat[index].association[a]) {
            cat[index].association[a].displayCouleurPicker = false;
        }
        majCategorie(cat);
        majDisplayCouleurPicker(false);
    }

    /**
     * Updates the year column.
     * @param {e} object object => value : Object used to get the name of the selected column
     */
    function majColAnnee(e) {
        majColonneAnnee(e.label);
    }

    /**
     * Updates the column of insee codes for the communes.
     * @param {e} object object => value: Object used to get the name of the selected column
     */
    function majColCommune(e) {
        majColonneCommune(e.label);
    }

    /**
     * Updates the column of values to be represented graphically and cartographically.
     * @param {e}  object object => value: Object used to get the name of the selected column
     */
    function majColValeur(e) {
        majColonneValeur(e.label);
    }

    let selectionCategorie = (
        <div className="form-horizontal panel-ajout-indicateur basic-multi-select">
            <Select
                isMulti
                defaultValue={[]}
                name="zone_only"
                onChange={(e) => majCategorieSelectionnee(e, categorie.length)}
                options={listeColonnesRegistre}
                className="basic-multi-select"
                classNamePrefix="select"
            />
        </div>
    );

    let selectionColAnnee = (
        <div className="panel-ajout-indicateur basic-multi-select">
            <Select
                defaultValue={[]}
                name="zone_only"
                onChange={(e) => majColAnnee(e)}
                options={listeColonnesRegistre}
                className="basic-multi-select"
                classNamePrefix="select"
            />
        </div>
    );

    let selectionColCommune = (
        <div className="panel-ajout-indicateur basic-multi-select">
            <Select
                defaultValue={[]}
                name="zone_only"
                onChange={(e) => majColCommune(e)}
                options={listeColonnesRegistre}
                className="basic-multi-select"
                classNamePrefix="select"
            />
        </div>
    );

    let selectionColValeur = (
        <div className="panel-ajout-indicateur basic-multi-select">
            <Select
                defaultValue={[]}
                name="zone_only"
                onChange={(e) => majColValeur(e)}
                options={listeColonnesRegistre}
                className="basic-multi-select"
                classNamePrefix="select"
            />
        </div>
    );

    let listeIntitulesAffiches = "";

    if (JSON.stringify(colonneCourante) !== "[]") {
        listeIntitulesAffiches = afficherListeIntitulesPourCategorie(
            colonneCourante[colonneCourante.length - 1]
        );
    }

    let donnees = {};

    if (!configurer) {
        return "";
    } else {
        if (listeTablesDispo.length !== 0) {
            let messageFinal = "";
            let erreurFinale = "";
            let messageChargementFinal = "";
            if (message !== "") {
                messageFinal = <div className="alert alert-success">{message}</div>;
            }

            if (erreur !== "") {
                erreurFinale = <div className="alert alert-danger">{erreur}</div>;
            }

            if (messageChargement !== "") {
                messageChargementFinal = <div>{messageChargement}</div>;
            }

            return (
                <div key="rendu-final">
                    <div
                        className="basic-multi-select"
                        style={{
                            marginBottom: "20px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <b>Nom de la table : </b>
                        <div
                            className="basic-multi-select form-horizontal"
                            style={{ width: "300px" }}
                        >
                            <Creatable
                                name="table-name"
                                classNamePrefix="select"
                                options={listeTablesDispo.map(({ label }) => ({
                                    value: label,
                                    label,
                                    isDisabled: true,
                                }))}
                                value={{ value: nomTable, label: nomTable }}
                                onChange={(e) => majNomTable(e.value)}
                                formatCreateLabel={(val) => `Créer la table "${val}"`}
                                createOptionPosition="first"
                                isValidNewOption={(inputValue, _, options) =>
                                    inputValue.match(/^[a-z0-9_]+$/) &&
                                    options.every((opt) => opt.value !== inputValue)
                                }
                                noOptionsMessage={() => "Format incorrect"}
                            />
                        </div>
                    </div>
                    <b>{"Sélection des catégories du jeu de données"}</b>
                    {selectionCategorie}
                    {listeIntitulesAffiches}
                    <b>{"Sélection de la colonne des valeurs"}</b>
                    {selectionColValeur}
                    <b>{"Sélection de la colonne des communes"}</b>
                    {selectionColCommune}
                    <b>{"Sélection de la colonne des années (facultatif)"}</b>
                    {selectionColAnnee}
                    <button
                        className="btn btn-success"
                        onClick={(e) => integrerDonnees(donnees)}
                    >
                        Valider
                    </button>
                    {messageFinal}
                    {erreurFinale}
                    {messageChargementFinal}
                </div>
            );
        } else {
            return <div>{"Chargement en cours..."}</div>;
        }
    }
}

export default ConfigurerDonneesRegistre;
