/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import { Link } from "react-router-dom";

import Api from "../Controllers/Api";
import { buildRegionUrl, saveAsPng, createPdfMethodoLink } from "../utils.js";
import FabriqueRepresentation from "./FabriqueRepresentation.js";
import config from "../settings.js";
import { ExportIndicatorButton } from "./ExportButton.js";

/**
 * This component is used to display energy consumption and production tracking graphs
 */
class SuiviEnergetique extends React.Component {
    constructor(props) {
        super(props);

        // Get data from API
        this.state = {
            identifiantIndicateurConso: false,
            identifiantIndicateurProd: false,
            identifiantIndicateurPartProdConso: false,
            passageTable: false,

            urlPartageable: "",
            parameters: {},
        };
    }

    componentDidMount() {
        this.getParametersFromApi();
        this.getDataFromApi();
    }

    getParametersFromApi() {
        const url = buildRegionUrl(
            config.api_analysis_page_parameters,
            this.props.parentApi.data.region
        ).replace("#page#", "suivi_energetique");
        Api.callApi(url).then((json) => {
            if (!json || !json.parameters) {
                return;
            }
            this.setState({
                parameters: json.parameters,
                passageTable: json.passage_table,
                identifiantIndicateurConso:
                    this.props.parentApi.controller.analysisManager.obtenirIdentifiantAnalyse(
                        json.passage_table["DataSet.CONSUMPTION"].name
                    ),
                identifiantIndicateurProd:
                    this.props.parentApi.controller.analysisManager.obtenirIdentifiantAnalyse(
                        json.passage_table["DataSet.PRODUCTION"].name
                    ),
                identifiantIndicateurPartProdConso:
                    this.props.parentApi.controller.analysisManager.obtenirIdentifiantAnalyse(
                        json.passage_table["DataSet.PRODUCTION"].name,
                        true
                    ),
            });
        });
    }

    getDataFromApi() {
        let pZone = "?zone=" + this.props.parentApi.data.zone.zone;
        let pMaille = "&maille=" + this.props.parentApi.data.zone.maille;
        let pZoneId = "&zone_id=" + this.props.parentApi.data.currentZone;
        let nomTerritoire =
            "&nom_territoire=" +
            encodeURI(
                this.props.parentApi.controller.zonesManager.getZoneName(
                    this.props.parentApi.data.currentZone,
                    this.props.parentApi.data.zone
                )
            );
        if (
            !this.props.parentApi.controller.zonesManager.getZoneName(
                this.props.parentApi.data.currentZone,
                this.props.parentApi.data.zone
            )
        ) {
            nomTerritoire =
                "&nom_territoire=" + encodeURI(this.props.parentApi.data.nomTerritoire);
        }
        let urlIdUtilisateur = "";
        if (this.props.parentApi.controller.gestionSuiviConsultations) {
            urlIdUtilisateur =
                "&id_utilisateur=" +
                this.props.parentApi.controller.gestionSuiviConsultations.idUtilisateur;
            if (this.props.parentApi.data.zone.zone === "region") {
                pZoneId = "&zone_id=" + this.props.parentApi.data.regionCode;
            }

            this.props.parentApi.callbacks.mettreAJourParametresUrls(
                pZone + pMaille + pZoneId + nomTerritoire
            );
            let dataSource =
                config.api_suivi_trajectoire_energetique_url +
                pZone +
                pMaille +
                pZoneId +
                urlIdUtilisateur;
            Api.callApi(
                buildRegionUrl(dataSource, this.props.parentApi.data.region)
            ).then((json) => {
                if (!json) {
                    return;
                }
                this.setState({
                    data: json,
                });
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (
            !prevProps.parentApi.controller.gestionSuiviConsultations &&
            this.props.parentApi.controller.gestionSuiviConsultations
        ) {
            this.getDataFromApi();
        }
    }

    /**
     * factorizes the creation of the Save graphic, export and PDF methodo buttons
     * @param {*} id_analysis : the id of the current indicator, used to find the name of PDF methodo
     * @param {*} ref : the reference of the part of the code to export
     * @param {*} nom : the name used to save the exported graph
     */
    getPdfSaveButtons(id_analysis, ref, nom) {
        return (
            <div className="selection-graph bouton-methodo do-not-print">
                <button
                    id="buttons"
                    type="button"
                    className="btn btn-info btn-save block-row bouton-flex liste-modalites-diagramme"
                    onClick={() => {
                        saveAsPng(ref, nom);
                    }}
                >
                    <span>PNG</span>
                </button>
                <ExportIndicatorButton
                    parentApi={this.props.parentApi}
                    analysisId={id_analysis}
                />
                <a
                    target="_blank"
                    rel="noreferrer"
                    href={createPdfMethodoLink(
                        config.methodo_url,
                        this.props.parentApi.data.region,
                        this.props.parentApi.controller.analysisManager.getAnalysisMethodoPdf(
                            parseInt(id_analysis, 10)
                        )
                    )}
                >
                    <div className="pdf"></div>
                </a>
            </div>
        );
    }

    render() {
        if (!this.state.data) {
            return null;
        }
        let width = 620;
        let height = 450;

        let chartEvolConsoSecteur =
            this.state.parameters && this.state.parameters["chartEvolConsoSecteur"] ? (
                <FabriqueRepresentation
                    // FabriqueRepresentation instantiates the representation specified in the representation property
                    // Here, we instantiate a CourbesEmpilees component
                    parentApi={this.props.parentApi}
                    id_analysis={this.state.identifiantIndicateurConso}
                    representation={"line"}
                    type={{
                        categorie:
                            this.state.passageTable["DataSet.CONSUMPTION"].cols[
                                "SECTOR"
                            ],
                        titre: "Par secteur",
                    }}
                    provenance="suivi_energetique"
                    width={width}
                    height={height}
                    id={1}
                />
            ) : (
                false
            );

        let chartEvolConsoEnergie =
            this.state.parameters && this.state.parameters["chartEvolConsoEnergie"] ? (
                <FabriqueRepresentation
                    parentApi={this.props.parentApi}
                    id_analysis={this.state.identifiantIndicateurConso}
                    representation={"line"}
                    type={{
                        categorie:
                            this.state.passageTable["DataSet.CONSUMPTION"].cols[
                                "COMMODITY"
                            ],
                        titre: "Par type d'énergie",
                    }}
                    provenance="suivi_energetique"
                    width={width}
                    height={height}
                    id={2}
                />
            ) : (
                false
            );

        let chartEvolProd =
            this.state.parameters && this.state.parameters["chartEvolProd"] ? (
                <FabriqueRepresentation
                    parentApi={this.props.parentApi}
                    id_analysis={this.state.identifiantIndicateurProd}
                    representation={"line"}
                    type={{
                        categorie:
                            this.state.passageTable["DataSet.PRODUCTION"].cols[
                                "RENEWABLEPROD"
                            ],
                        titre: "Par filière de production",
                    }}
                    provenance="suivi_energetique"
                    width={width}
                    height={height}
                    id={3}
                />
            ) : (
                false
            );

        let barConsoVsPnr =
            this.state.parameters && this.state.parameters["barConsoVsPnr"] ? (
                <FabriqueRepresentation
                    parentApi={this.props.parentApi}
                    id_analysis={this.state.identifiantIndicateurPartProdConso}
                    representation={"histogramme-data-ratio"}
                    unit="GWh"
                    type={{
                        categorie:
                            this.state.passageTable["DataSet.PRODUCTION"].cols[
                                "RENEWABLEPROD"
                            ],
                        titre: "Par filière de production",
                    }}
                    provenance="suivi_energetique"
                    width={width}
                    height={height}
                    id={4}
                />
            ) : (
                false
            );

        let chartEvoConsoUsage =
            this.state.parameters && this.state.parameters["chartEvoConsoUsage"] ? (
                <FabriqueRepresentation
                    parentApi={this.props.parentApi}
                    id_analysis={this.state.identifiantIndicateurConso}
                    representation={"line"}
                    type={{
                        categorie:
                            this.state.passageTable["DataSet.CONSUMPTION"].cols[
                                "USAGE"
                            ],
                        titre: "Par usage",
                    }}
                    provenance="suivi_energetique"
                    width={width}
                    height={height}
                    id={3}
                />
            ) : (
                false
            );

        let zoneName = this.props.parentApi.controller.zonesManager.getZoneName(
            this.props.parentApi.data.currentZone,
            this.props.parentApi.data.zone
        );
        let title = zoneName;
        if (!title) {
            title = this.props.parentApi.data.nomTerritoire;
        }

        if (this.props.parentApi.data.zone.zone === "region") {
            title = this.props.parentApi.data.regionLabel;
        }

        let refEvolConsoSecteur = React.createRef(),
            refEvolConsoEnergie = React.createRef(),
            refEvolProd = React.createRef(),
            refConsoVsPnr = React.createRef(),
            refUsagePnr = React.createRef();
        let classTheme = this.props.parentApi.data.theme;
        let source_suivi_traj = JSON.parse(
            this.props.parentApi.data.settings["source_suivi_traj"]
        );
        return (
            <div
                className="suivi-energetique widgets full-screen-widget"
                id="suivi-energetique"
            >
                <Link
                    className="back-to-map"
                    to={"/" + this.props.parentApi.data.urlPartageable}
                >
                    <button
                        type="button"
                        className="close close-big"
                        data-dismiss="alert"
                        aria-label="Close"
                        onClick={() => {
                            this.props.parentApi.callbacks.mettreAJourParametresUrls(
                                this.state.urlPartageable
                            );
                            this.props.parentApi.callbacks.updateCurrentMenu("analyse");
                        }}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Link>
                <div
                    className={"page-content " + classTheme}
                    id="suivi-energetique-content"
                >
                    <div className="title centered-row">
                        <label className="suivi-energetique-title">
                            Suivi de la trajectoire énergétique
                        </label>
                        <label className="suivi-energetique-territoire">
                            Territoire : {title}
                        </label>
                    </div>

                    {(chartEvolConsoSecteur ||
                        chartEvolConsoEnergie ||
                        chartEvoConsoUsage) && (
                        <>
                            <h3>Évolution de la consommation énergétique finale</h3>
                            <p>{source_suivi_traj["titre_graphique"]}</p>
                            <div align="center"></div>
                        </>
                    )}
                    {chartEvolConsoSecteur && (
                        <div ref={refEvolConsoSecteur}>
                            <h4>Par secteur :</h4>
                            {this.getPdfSaveButtons(
                                this.state.identifiantIndicateurConso,
                                refEvolConsoSecteur,
                                "Évolution de la consommation énergétique finale"
                            )}
                            {chartEvolConsoSecteur}
                            <div className="chart-legend">
                                {source_suivi_traj["source"]}
                            </div>
                        </div>
                    )}
                    {chartEvolConsoEnergie && (
                        <div ref={refEvolConsoEnergie}>
                            <h4>Par énergie :</h4>
                            {this.getPdfSaveButtons(
                                this.state.identifiantIndicateurConso,
                                refEvolConsoEnergie,
                                "Évolution de la consommation énergétique finale"
                            )}
                            {chartEvolConsoEnergie}
                            <div className="chart-legend">
                                {source_suivi_traj["source"]}
                            </div>
                        </div>
                    )}
                    {chartEvoConsoUsage && (
                        <div ref={refUsagePnr}>
                            <h4>Par usage :</h4>
                            {this.getPdfSaveButtons(
                                this.state.identifiantIndicateurConso,
                                refUsagePnr,
                                "Évolution de la consommation énergétique par usage"
                            )}
                            {chartEvoConsoUsage}
                            <div className="chart-legend">
                                {source_suivi_traj["source"]}
                            </div>
                        </div>
                    )}
                    {chartEvolProd && (
                        <div ref={refEvolProd}>
                            <h3>Évolution de la production EnR (en GWh)</h3>
                            {this.getPdfSaveButtons(
                                this.state.identifiantIndicateurProd,
                                refEvolProd,
                                "Évolution de la production EnR (en GWh)"
                            )}
                            {chartEvolProd}
                            <div className="chart-legend">
                                {source_suivi_traj["source"]}
                            </div>
                        </div>
                    )}
                    {barConsoVsPnr && (
                        <div ref={refConsoVsPnr}>
                            <h3>
                                Évolution comparée de la consommation d’énergie finale
                                et de la production d’énergie renouvelable locale
                            </h3>
                            {this.getPdfSaveButtons(
                                this.state.identifiantIndicateurPartProdConso,
                                refConsoVsPnr,
                                "Évolution comparée de la consommation d’énergie finale et de la production d’énergie renouvelable locale"
                            )}
                            {barConsoVsPnr}
                            <div className="chart-legend">
                                {source_suivi_traj["source"]}
                            </div>
                        </div>
                    )}
                    {this.props.parentApi.data.settings &&
                    this.props.parentApi.data.settings.ui_show_plan_actions ? (
                        <div className="lien-strategies-territoire">
                            <Link to="/strategies_territoriales">
                                <button className="btn btn-success">
                                    {" "}
                                    Mesurer l'impact de votre plan d'actions sur la
                                    trajectoire énergétique{" "}
                                </button>
                            </Link>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            </div>
        );
    }
}

export default SuiviEnergetique;
