/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import { Link } from "react-router-dom";
import Api from "../Controllers/Api";
import config from "../settings.js";
import { buildRegionUrl } from "../utils.js";

/**
 * This component is used to to display the didactic file
 */
class DidacticFile extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            didacticFile: undefined,
        };
    }

    componentDidMount() {
        if (this.props.currentDidacticFile) {
            // Get data from API
            this.getDidacticFileData(this.props.currentDidacticFile);
            this.props.parentApi.callbacks.updateProvenance("didactic-file");
        }
    }

    /**
     * Get the data for a specific didactic file identified by its ID.
     * @param {int} id the didactic file ID
     */
    getDidacticFileData(id) {
        this.props.parentApi.callbacks.mettreAJourParametresUrls(
            this.props.parentApi.data.urlPartageable
        );
        let url = buildRegionUrl(
            config.api_didactic_file,
            this.props.parentApi.data.region
        ).replace("#id#", id);
        Api.callApi(url, null, "GET").then((response) => {
            this.setState({
                didacticFile: response,
            });
        });
    }

    builAnalysisUrl(id, type) {
        let typeTerritoire = "?zone=" + this.props.parentApi.data.zone.zone;
        let maille = "&maille=" + this.props.parentApi.data.zone.maille;
        let uiTheme =
            "&theme=" +
            this.props.parentApi.controller.analysisManager.getAnalysisNameTheme(
                parseInt(id, 10)
            );
        let codeInseeTerritoire = "&zone_id=" + this.props.parentApi.data.currentZone;
        let nomTerritoire =
            "&nom_territoire=" +
            encodeURI(
                this.props.parentApi.controller.zonesManager.getZoneName(
                    this.props.parentApi.data.currentZone,
                    this.props.parentApi.data.zone
                ) ?? this.props.parentApi.data.nomTerritoire
            );

        let poiOrAnalysis = "";
        if (type === "equipment") {
            poiOrAnalysis =
                "&installation=" +
                this.props.parentApi.controller.equipementsManager
                    .getEquipementsLayers()
                    .filter((layer) => layer.id === id)
                    .map((layer) => layer.nom)
                    .join("-");
        } else {
            poiOrAnalysis = "&analysis=" + id;
        }
        let url =
            "/" +
            typeTerritoire +
            maille +
            codeInseeTerritoire +
            nomTerritoire +
            uiTheme +
            poiOrAnalysis;

        return url;
    }

    fileCategories() {
        return Object.keys(this.state.didacticFile.data).map((categoryId) => {
            let id = this.state.didacticFile.data[categoryId].id;
            return (
                <div key={"category_" + id} id={"category_" + categoryId}>
                    <h3>{this.state.didacticFile.data[categoryId].categoryTitle}</h3>
                    <ul>
                        {this.state.didacticFile.data[categoryId].subCategory &&
                            Object.keys(
                                this.state.didacticFile.data[categoryId].subCategory
                            ).length > 0 &&
                            Object.keys(
                                this.state.didacticFile.data[categoryId].subCategory
                            ).map((subCatId) => {
                                return this.fileSubCategories(categoryId, subCatId);
                            })}

                        <br />
                    </ul>
                </div>
            );
        });
    }

    fileSubCategories(categoryId, subCatId) {
        let type =
            this.state.didacticFile.data[categoryId].subCategory[subCatId].typeAnalysis;
        let typeName = type === "-actions" ? "plan-action" : "-indicateur";
        let classTheme = "";
        if (this.props.parentApi && this.props.parentApi.data) {
            classTheme += "icon-" + this.props.parentApi.data.theme + typeName;
        }
        return (
            <div
                key={"category_" + categoryId + "_sub_category_" + subCatId}
                id={"category_" + categoryId + "_sub_category_" + subCatId}
                className={classTheme}
            >
                <li>
                    {
                        this.state.didacticFile.data[categoryId].subCategory[subCatId]
                            .subCategoryTitle
                    }{" "}
                    :
                    <ul>
                        {this.fileSubCategoriesSectionAnalysis(
                            //type,
                            categoryId,
                            subCatId
                        )}

                        <br />
                    </ul>
                </li>
            </div>
        );
    }

    fileSubCategoriesSectionAnalysis(categoryId, subCatId) {
        let colorRegion = getComputedStyle(document.body).getPropertyValue(
            "--" + this.props.parentApi.data.settings.theme
        );
        let style = {
            color: colorRegion,
        };

        return Object.values(
            this.state.didacticFile.data[categoryId].subCategory[subCatId].section
        ).map((section) => {
            // one analysis/equipment per section
            let id_analysis = section.analysis.id;
            let type = section.analysis.type;
            let sectionId = section.id;
            const className =
                type === "equipment" ? "bi bi-geo-fill" : "bi bi-pie-chart-fill";
            let text = "";
            // TODO : replace it with levers ?
            if (type === "actions") {
                text = "  ✓ action TerriSTORY  ";
                style["background"] = "#f0ad4e66";
                style["color"] = "#723e00";
            }
            return (
                <li key={`analysis_${categoryId}_${subCatId}_${sectionId}`}>
                    {section.sectionTitle}
                    &nbsp; &nbsp;
                    {["analysis", "equipment"].includes(type) && id_analysis && (
                        <a
                            style={style}
                            className={className}
                            href={this.builAnalysisUrl(id_analysis, type)}
                            target="_blank"
                            rel="noreferrer"
                            title="Visualiser l'indicateur"
                        >
                            {" "}
                        </a>
                    )}
                    {/* TODO : replace action with levers className should be "actions"*/}
                    {type === "actions" && (
                        <i style={style} className={className}>
                            &nbsp;{text}&nbsp;
                        </i>
                    )}
                    <br />
                    {section.sectionComment && (
                        <div>
                            <i className="didactic-file-section">
                                {section.sectionComment}
                            </i>
                            <br />
                        </div>
                    )}
                    <br />
                </li>
            );
        });
    }

    getDashboardURL() {
        let args = this.props.parentApi.data.urlPartageable.split("&");
        let argsKeys = {};
        for (let arg of args) {
            argsKeys[arg.split("=")[0].replace("?", "")] = arg.split("=")[1];
        }

        let territoryType = "?zone=" + argsKeys.zone;
        let maille = "&maille=" + argsKeys.maille;
        let codeInseeTerritory = "&zone_id=" + argsKeys.zone_id;
        let idDashboard = "&id_tableau=" + argsKeys.id_tableau;
        if (argsKeys.id_tableau === "undefined") {
            return false;
        }
        let territoryName = "&nom_territoire=" + argsKeys.nom_territoire;

        let url =
            territoryType + maille + codeInseeTerritory + idDashboard + territoryName;
        return url;
    }

    render() {
        let fileTitle = "";
        let blockCategory = "";
        let description = "";
        let urlDashboard = "";
        if (this.state.didacticFile) {
            fileTitle = this.state.didacticFile.metadata.fileTitle;
            description = this.state.didacticFile.metadata.description;
            blockCategory = this.fileCategories();
            urlDashboard = this.getDashboardURL();
        }

        return (
            <div className="widgets full-screen-widget">
                <Link
                    className="back-to-map"
                    to={"/" + this.props.parentApi.data.urlPartageable}
                >
                    <button
                        type="button"
                        className="close close-big"
                        data-dismiss="alert"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Link>
                <div className="structure-fiche-didactique">
                    <h2>Fiche didactique : « {fileTitle} »</h2>
                    <div className="block-categorie-restitution">
                        <p>
                            <em>Objectif :</em> {description}
                        </p>
                        <br /> <br /> <br />
                        {blockCategory}
                    </div>

                    <div className="lien-fiche_didactique">
                        <br />
                        <hr />
                        {this.props.parentApi.data.settings &&
                        this.props.parentApi.data.settings.ui_show_plan_actions ? (
                            <div style={{ marginRight: "20px" }}>
                                <Link className="btn btn-success" to={"/simulateur"}>
                                    {" "}
                                    Simuler les leviers d'actions du territoire pour{" "}
                                    {fileTitle}{" "}
                                </Link>
                            </div>
                        ) : (
                            ""
                        )}
                        {urlDashboard && (
                            <div>
                                <Link
                                    className="btn btn-warning"
                                    to={"/restitution_tableaux_bord" + urlDashboard}
                                >
                                    {" "}
                                    Retour au tableau de bord{" "}
                                </Link>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default DidacticFile;
