/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React, { useState } from "react";

/**
 * Display a popup on the full screen
 *
 * **How to use?**
 *
 * You can call DetailsPopup by giving it a variable and a callback. Make sure that
 * if you use the variable to trigger showing the popup, you do not forget, in the
 * callback, to remove the forcing. Otherwise, the popup will always be displayed
 * as `show` props forces displaying the variable over any internal state.
 *
 *  .. code-block:: javascript
 *
 *      <DetailsPopup
 *                   title="A specific title"
 *                   content={contentVariable}
 *                   show={showPopup}
 *                   emptyMsg="Nothing to show"
 *                   callbackAfterClosing={() => toggleDetailsCell()} />
 *
 * **Examples**
 *
 * See DataHistory Component.
 *
 * @param {ReactProps} props Should contain the following elements:
 *  * title (string)
 *  * content (string)
 *  * emptyMsg (string) to be displayed when content is empty
 *  * callbackAfterClosing (callable) to be called after closing the window
 *  * show (boolean) forces showing the window
 */
function DetailsPopup(props) {
    const [display, setDisplay] = useState(false);

    if (props.show && !display) {
        setDisplay(true);
    }

    if (!display) {
        return "";
    }

    function handleClick(e) {
        // do not handle click when clicking on inner div and not on main div
        if (e.target !== e.currentTarget) {
            return;
        }
        setDisplay(false);
        props.callbackAfterClosing();
    }

    let emptyMsg = props.emptyMsg ? props.emptyMsg : "No details";

    return (
        <div className="overlay-popup" onClick={(e) => handleClick(e)}>
            <div className="details-popup">
                <h2>{props.title}</h2>
                <button className="details-popup-close" onClick={(e) => handleClick(e)}>
                    &times;
                </button>
                <div className="details-popup-content">
                    {props.content ? props.content : <em>{emptyMsg}</em>}
                </div>
            </div>
        </div>
    );
}

/**
 * Display a full-screen popup
 *
 * **Example**
 *
 *  .. code-block:: javascript
 *
 *      <DetailsPopup
 *          title="A specific title"
 *          isOpen={showPopup}
 *          emptyMsg="Nothing to show"
 *          onClose={() => setShowPopup(false)}
 *      >
 *          Some JSX content
 *      </DetailsPopup>
 *
 * @param {Object} props
 * @param {string} props.title optionnal title shown on the top of the popup
 * @param {string} props.emptyMsg displayed when the content is empty
 * @param {function} props.onClose called after the popup is closed
 * @param {boolean} props.isOpen if the popup should be shown
 * @param {string?} props.className additional classNames for the popup
 */
export function NewDetailsPopup(props) {
    if (!props.isOpen) {
        return "";
    }

    function handleClose(e) {
        // do not handle click when clicking on inner div and not on main div
        if (e.target !== e.currentTarget) {
            return;
        }
        props.onClose();
    }

    let emptyMsg = props.emptyMsg ?? "Pas de détails";

    return (
        <div className="overlay-popup" onClick={(e) => handleClose(e)}>
            <div className={"responsive-popup " + (props.className ?? "")}>
                <button
                    className="responsive-popup-close"
                    onClick={(e) => handleClose(e)}
                >
                    &times;
                </button>
                {props.title && <h2>{props.title}</h2>}
                <div className="responsive-popup-content">
                    {props.children || <em>{emptyMsg}</em>}
                </div>
            </div>
        </div>
    );
}

export default DetailsPopup;
