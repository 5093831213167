/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import { Bar } from "react-chartjs-2";

import FadeInSection from "../Utils/FadeInSection";

function Visits({ regions, years, data, paramKey, title, unit }) {
    const colors = ["#00a0dd", "#f5217a", "#84c753", "#f7ad4c", "#00857d", "#f6424a"];
    const config = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                text: title,
                position: "bottom",
                padding: {
                    bottom: 20,
                },
                color: "rgb(33, 37, 41)",
                font: {
                    size: 16,
                },
            },
        },
        scales: {
            x: {
                ticks: {
                    minRotation: 45,
                    font: {
                        size: 14,
                    },
                },
            },
            y: {
                ticks: {
                    font: {
                        size: 14,
                    },
                },
                display: true,
                title: {
                    display: true,
                    text: unit,
                    color: "rgb(33, 37, 41)",
                    font: {
                        size: 16,
                    },
                },
            },
        },
    };

    const getTransparency = (yearId, nb_years) => {
        return parseInt(((yearId + 1) / nb_years) * 200 + 55, 10).toString(16);
    };

    const datasets = [];
    years.forEach((year, yearId) => {
        datasets.push({
            label: year,
            data: regions.map((r) => data[r]?.[paramKey]?.[year]),
            borderColor: regions.map(
                (r, i) => colors[i] + getTransparency(yearId, years.length)
            ),
            backgroundColor: regions.map(
                (r, i) => colors[i] + getTransparency(yearId, years.length)
            ),
        });
    });

    const finalData = {
        labels: regions,
        datasets: datasets,
    };

    return <Bar height={250} options={config} data={finalData} />;
}

function Indicators({ regions, data, paramKey, title, unit }) {
    const colors = ["#00a0dd", "#f5217a", "#84c753", "#f7ad4c", "#00857d", "#f6424a"];
    const config = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                text: title,
                position: "bottom",
                padding: {
                    bottom: 20,
                },
                color: "rgb(33, 37, 41)",
                font: {
                    size: 16,
                },
            },
        },
        scales: {
            x: {
                ticks: {
                    minRotation: 45,
                    font: {
                        size: 14,
                    },
                },
            },
            y: {
                ticks: {
                    font: {
                        size: 14,
                    },
                },
                display: true,
                title: {
                    display: true,
                    text: unit,
                    color: "rgb(33, 37, 41)",
                    font: {
                        size: 16,
                    },
                },
            },
        },
    };

    const datasets = [];
    datasets.push({
        data: regions.map((r) => data[r]?.[paramKey]),
        borderColor: regions.map((r, i) => colors[i] + "88"),
        backgroundColor: regions.map((r, i) => colors[i] + "88"),
    });

    const finalData = {
        labels: regions,
        datasets: datasets,
    };

    return <Bar height={250} options={config} data={finalData} />;
}

/**
 * This component is used to manage the 'A propos' page of the application
 */
function Stats() {
    const regionalStats = {
        "Auvergne-Rhône-Alpes": {
            indicators: 89,
            poi: 14,
            unique_users: 5000,
            population: 8235923,
            ratio_pop: 12,
            visits: {
                2022: 123340,
                2023: 185788,
            },
        },
        Bretagne: {
            indicators: 38,
            poi: 8,
            unique_users: 1245,
            population: 3453023,
            ratio_pop: 5.1,
            visits: {
                2022: 34776,
                2023: 41718,
            },
        },
        Corse: {
            indicators: 35,
            unique_users: 140,
            population: 355528,
            ratio_pop: 0.5,
            visits: {
                2023: 3868,
            },
        },
        "Nouvelle-Aquitaine": {
            indicators: 34,
            unique_users: 1400,
            population: 6154772,
            ratio_pop: 9,
            visits: {
                2022: 37079,
                2023: 40623,
            },
        },
        Occitanie: {
            indicators: 35,
            poi: 11,
            unique_users: 2159,
            population: 6154729,
            ratio_pop: 9,
            visits: {
                2022: 45706,
                2023: 91185,
            },
        },
        "Pays de la Loire": {
            indicators: 46,
            poi: 23,
            unique_users: 1600,
            population: 3926389,
            ratio_pop: 5.7,
            visits: {
                2022: 16410,
                2023: 55172,
            },
        },
    };

    const nationalStats = {
        indicators: 0,
        poi: 0,
        unique_users: 0,
        population: 0,
        ratio_pop: 0,
        visits: {
            2022: 0,
            2023: 0,
        },
    };
    Object.values(regionalStats).forEach((value) => {
        nationalStats.indicators += value.indicators ?? 0.0;
        nationalStats.poi += value.poi ?? 0.0;
        nationalStats.unique_users += value.unique_users ?? 0.0;
        for (const key in nationalStats.visits) {
            nationalStats.visits[key] += value.visits?.[key] ?? 0.0;
        }
        nationalStats.population += value.population ?? 0.0;
        nationalStats.ratio_pop += value.ratio_pop ?? 0.0;
    });

    return (
        <div className="widgets full-screen-widget">
            <div className="tout about">
                <div className="texte-corps">
                    <div className="text-block">
                        <div className="whole-width">
                            <FadeInSection>
                                <h1>Le projet en quelques chiffres</h1>
                            </FadeInSection>
                        </div>

                        <div>
                            <FadeInSection>
                                <p className="digit color1">
                                    {new Intl.NumberFormat("fr-FR").format(
                                        nationalStats.unique_users
                                    )}
                                </p>
                                <p>
                                    <strong>utilisateurs uniques</strong> sur l'année
                                    qui visitent l'ensemble des différents sites
                                    régionaux.
                                </p>
                            </FadeInSection>
                        </div>

                        <div>
                            <FadeInSection>
                                <p className="digit color2">
                                    {nationalStats.indicators}
                                </p>
                                <p>indicateurs sur l'ensemble des régions</p>
                                <p className="digit color2">15</p>
                                <p>
                                    thématiques abordées dont la{" "}
                                    <em>consommation d'énergie</em>, les{" "}
                                    <em>puits de carbone</em> ou encore l'<em>eau</em>
                                </p>
                                <p className="digit-s color2">{nationalStats.poi}</p>
                                <p>couches d'équipements disponibles</p>
                            </FadeInSection>
                        </div>
                    </div>
                    <div className="text-block">
                        <div className="whole-width">
                            <FadeInSection>
                                <h1>Les régions à la manoeuvre</h1>
                            </FadeInSection>
                        </div>
                        <div>
                            <FadeInSection>
                                <p className="digit color3">6</p>
                                <p>
                                    <strong>régions</strong> accessibles et engagées
                                    dans le consortium TerriSTORY®
                                </p>
                            </FadeInSection>
                        </div>
                        <div>
                            <FadeInSection>
                                <p className="digit-xs color3">
                                    {new Intl.NumberFormat("fr-FR").format(
                                        Math.round(nationalStats.population / 1000, 0) *
                                            1000
                                    )}
                                </p>
                                <p>
                                    <strong>habitants couverts</strong> par les
                                    déclinaisons régionales sur toute la France, soit
                                </p>
                                <p className="digit-s color1">
                                    {Math.round(nationalStats.ratio_pop, 0)} %
                                </p>
                                <p>de la population française.</p>
                            </FadeInSection>
                        </div>
                        <div>
                            <FadeInSection>
                                <Visits
                                    regions={Object.keys(regionalStats)}
                                    years={[2022, 2023]}
                                    data={regionalStats}
                                    paramKey="visits"
                                    title="Nombre de visites sur les dernières années par région"
                                    unit="Nombre de visites"
                                />
                            </FadeInSection>
                        </div>
                        <div>
                            <p>
                                La dynamique de TerriSTORY® se{" "}
                                <strong>renforce année après année</strong> grâce au
                                soutien de nos utilisateurs. De nouvelles régions ont{" "}
                                <strong>rejoint progressivement le consortium</strong>{" "}
                                pour déployer leur plateforme régionale et accompagner
                                toujours plus les territoires dans leurs démarches de
                                transition environnementale.
                            </p>
                        </div>
                        <div>
                            <p>
                                Chaque structure en charge du déploiement régional
                                décide des <strong>indicateurs</strong> qu'elle souhaite
                                inclure et <strong>mettre à disposition</strong> dans sa
                                plateforme TerriSTORY®.
                            </p>
                        </div>
                        <div>
                            <FadeInSection>
                                <Indicators
                                    regions={Object.keys(regionalStats)}
                                    data={regionalStats}
                                    paramKey="indicators"
                                    title="Nombre d'indicateurs par région"
                                    unit="Indicateurs"
                                />
                            </FadeInSection>
                        </div>
                    </div>
                    <div className="text-block">
                        <div className="whole-width">
                            <FadeInSection>
                                <h1>Un outil informatique en développement</h1>
                            </FadeInSection>
                        </div>
                        <div>
                            <FadeInSection>
                                <p className="digit-xs color5">2 500</p>
                                <p>
                                    propositions d'amélioration ou retours de bugs
                                    faites sur le{" "}
                                    <a
                                        href="https://gitlab.com/terristory/terristory/-/issues"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        dépôt public du code
                                    </a>{" "}
                                    dont
                                </p>
                                <p className="digit-xs color5">2 100</p>
                                <p>
                                    demandes traitées (voir la{" "}
                                    <a
                                        href="/national/open_source"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        page open-source pour plus d'informations
                                    </a>
                                    ).
                                </p>
                            </FadeInSection>
                        </div>

                        <div>
                            <FadeInSection>
                                <p className="digit-xs color6">250 000</p>
                                <p>
                                    <strong>lignes de code source</strong> à l'oeuvre
                                    derrière TerriSTORY® dont 86 000 de <em>Python</em>{" "}
                                    et 88 000 de <em>JavaScript</em>.
                                </p>
                            </FadeInSection>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Stats;
