/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import { Link } from "react-router-dom";

import Navbar from "../Components/National/Navbar";

export default function NationalLogin({ connected, parentApi, children }) {
    if (!connected) {
        return (
            <div className="widgets full-screen-widget">
                <Link to="/">
                    <div className="navbar"></div>
                </Link>
                <div className="tout">
                    <div className="support">
                        <div className="corps">
                            <p>
                                Désolé, vous n'êtes pas autorisé à effectuer cette
                                action.
                                <br />
                                <code>
                                    Vous devez vous connecter pour pouvoir effectuer
                                    cette action.
                                </code>
                            </p>
                            <Link to="/national/login">
                                <input
                                    type="button"
                                    className="tsbtn"
                                    value="Se connecter"
                                />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className="submission widgets full-screen-widget">
                <div className="navbar">
                    <div className="logo">
                        <Link to="/">
                            <img
                                src="/img/logo_TerriSTORY_small.png"
                                alt="Logo TerriSTORY®"
                                width="100%"
                            ></img>
                        </Link>
                    </div>
                </div>
                <Navbar parentApi={parentApi} />
                {children}
            </div>
        );
    }
}
