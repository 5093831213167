/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React, { useRef, useState, useEffect } from "react";

import "../../style/fadeInSection.css";

function FadeInSection(props) {
    const [isVisible, setVisible] = useState(false);
    const [hasBeenShownOnce, setShown] = useState(false);
    const domRef = useRef(null);

    useEffect(() => {
        let observerRefValue = null;
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => setVisible(entry.isIntersecting));
        });
        if (domRef.current) {
            observer.observe(domRef.current);
            observerRefValue = domRef.current;
        }

        return () => observer.unobserve(observerRefValue);
    }, [domRef]);

    useEffect(() => {
        if (isVisible) {
            setShown(true);
        }
    }, [isVisible]);

    return (
        <div
            className={`fade-in-section ${
                hasBeenShownOnce || isVisible ? "is-visible" : ""
            }`}
            ref={domRef}
        >
            {props.children}
        </div>
    );
}

export default FadeInSection;
