/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import { buildRegionUrl } from "../../utils.js";
import config from "../../settings.js";
import Api from "../../Controllers/Api";

/**
 * This component is used to create a clickable data source logo.
 */
class Logo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id_analyse: String(props.id_analysis),
            representation: "logo",
            widthDOM: window.innerWidth,
            selectedLogo: false,
        };
    }

    fetchConfiguration() {
        // Retrieve logo data associated to given id
        const id = this.props.id_analysis;
        let url = buildRegionUrl(
            config.api_logo_sources,
            this.props.parentApi.data.region
        ).replace("#id#", id);

        if (this.dataPromise) this.dataPromise.abort();
        this.dataPromise = Api.callApi(url, null, "GET");
        this.dataPromise
            .then((response) => {
                this.setState({
                    selectedLogo: response,
                });
            })
            .catch((error) => {
                if (error.name === "AbortError") return;
                console.error(error);
            });
    }

    componentDidMount() {
        let chartsLegendsDiv = document.getElementsByClassName("charts-legende");
        if (chartsLegendsDiv.length > 0) {
            let widthDiv =
                document.getElementsByClassName("charts-legende")[0].offsetWidth;
            if (
                this.props.parentApi.data.tailleDiv !== widthDiv &&
                this.props.provenance === "tableau_de_bord_restitue"
            ) {
                this.props.parentApi.callbacks.tailleDiv(widthDiv);
            }
        }

        // Get data from API
        this.fetchConfiguration();
    }

    componentWillUnmount() {
        if (this.dataPromise) this.dataPromise.abort();
    }

    displayLogo(id, extension, url, name) {
        // display logo chart in dashboard
        return (
            <span style={{ cursor: "pointer" }} onClick={() => this.OpenURL(url)}>
                <img
                    id={id}
                    src={
                        "img/logo_source_fiches/" +
                        this.props.parentApi.data.region +
                        "_logo" +
                        id +
                        "." +
                        extension
                    }
                    style={{ maxHeight: "260px", maxWidth: "260px" }}
                    alt={name}
                    title={name}
                />
                <span className="only-print" style={{ marginLeft: "20px" }}>
                    Contient un <a href={url}>lien</a>
                </span>
            </span>
        );
    }

    OpenURL(url) {
        // add URL to the associated data source logo
        if (this.props.provenance === "tableau_de_bord") {
            return "";
        }
        return window.open(url, "_blank");
    }

    render() {
        let logo = "";
        if (this.state.selectedLogo) {
            logo = this.displayLogo(
                this.state.selectedLogo.id,
                this.state.selectedLogo.extension,
                this.state.selectedLogo.url,
                this.state.selectedLogo.name
            );
        }
        return (
            <div className="charts-legende">
                <div>{logo}</div>
            </div>
        );
    }
}

export default Logo;
