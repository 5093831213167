/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import BarGraph from "./Graphs/BarGraph.js";
import CourbesEmpilees from "./Graphs/CourbesEmpilees.js";
import CourbesComparees from "./Graphs/CourbesComparees.js";
import DiagrammeCirculaire from "./Graphs/DiagrammeCirculaire.js";
import Jauge from "./Graphs/Jauge.js";
import JaugeCirculaire from "./Graphs/JaugeCirculaire.js";
import Histogramme from "./Graphs/Histogramme.js";
import DiagrammeRadar from "./Graphs/DiagrammeRadar";
import HistogrammeNormalise from "./Graphs/HistogrammeNormalise.js";
import HistogrammeDataRatio from "./Graphs/HistogrammeDataRatio.js";
import MarqueurSVG from "./Graphs/MarqueurSVG.js";
import AnalysisLauncher from "./Graphs/AnalysisLauncher.js";
import AnalysisMap from "./Graphs/AnalysisMap.js";
import PoiMap from "./Graphs/PoiMap.js";
import Logo from "./Graphs/Logo.js";
import DidacticFileLauncher from "./Graphs/DidacticFileLauncher.js";
import LinkLauncher from "./Graphs/LinkLauncher.js";
import DataTable from "./Graphs/DataTable.js";
import PCAETTrajectory from "./Graphs/PCAETTrajectory.js";
import TextBlock from "./Graphs/TextBlock.js";

/**
 * This component is used to instantiate a representation component (CircularDiagram, StackedCurves etc.)
 * according to the representation parameter to add in the properties.
 */
class FabriqueRepresentation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            customFilters: {},
            customFiltersInitiated: false,
        };
    }

    componentDidMount() {
        const { analysisManager } = this.props.parentApi.controller;
        const categoriesSettings = analysisManager.getCategories(
            this.props.id_analysis
        );
        this.initCustomFilters(categoriesSettings);
    }

    initCustomFilters(categoriesSettings) {
        // if we have no category, no need to display anything
        // if we have no props filters -> no constraints -> nothing to do
        if (!categoriesSettings || !this.props.filters) {
            return "";
        }
        let customFilters = {};
        for (const categorySettings of categoriesSettings) {
            if (!categorySettings) continue;
            const category = categorySettings.categorie;
            if (
                !this.props.filters.hasOwnProperty(category) ||
                !["switch-button", "selection"].includes(categorySettings?.type)
            ) {
                continue;
            }
            // for each valid category (ie. category using the right representation), we save the custom filters
            customFilters = {
                ...customFilters,
                [category]: this.props.filters[category],
            };
        }
        // if we performed any difference
        if (
            customFilters &&
            JSON.stringify(customFilters) !== JSON.stringify(this.state.customFilters)
        ) {
            this.setState({ customFilters, customFiltersInitiated: true });
        } else {
            this.setState({ customFiltersInitiated: true });
        }
    }

    updateCurrentDashboardWhenEditing(_filters) {
        if (this.props.provenance === "tableau_de_bord") {
            // we also callback the updated filters in case of dashboard edition
            // if we have no filters yet, we initialize the object
            let currentFilters =
                this.props.parentApi.data.tableauBordDonnees.donnees[
                    this.props.thematique
                ].indicateurs[this.props.id].filters;
            if (!currentFilters) {
                currentFilters =
                    this.props.parentApi.controller.analysisManager.initFiltersByCategory(
                        parseInt(this.props.id_analysis)
                    );
            }

            this.props.parentApi.data.tableauBordDonnees.donnees[
                this.props.thematique
            ].indicateurs[this.props.id].filters = {
                ...currentFilters,
                ..._filters,
            };
        }
    }

    /**
     * Update the specific filters currently stored based on switch button edited.
     */
    switchButtonCategory(category, modalities_filtered) {
        const currentCat = this.state.customFilters?.[category]?.[0]?.filtre_categorie;
        let newValue;
        if (!currentCat || currentCat === modalities_filtered[0]) {
            newValue = modalities_filtered[1];
        } else {
            newValue = modalities_filtered[0];
        }
        this.setState({
            customFilters: {
                ...this.state.customFilters,
                [category]: [{ filtre_categorie: newValue }],
            },
        });
        this.updateCurrentDashboardWhenEditing({
            [category]: [{ filtre_categorie: newValue }],
        });
    }

    /**
     * Update the specific filters currently stored based on selection field.
     */
    changeCategorySelection(category, modality) {
        this.setState({
            customFilters: {
                ...this.state.customFilters,
                [category]: [{ filtre_categorie: modality }],
            },
        });
        this.updateCurrentDashboardWhenEditing({
            [category]: [{ filtre_categorie: modality }],
        });
    }

    /**
     * Get specific rules applying to current graph
     * @param {string} key the unique key used to identify current graph in the dashboard
     * @returns a list of filters
     */
    getSpecificCategoryFilters() {
        let output = {};
        for (const category in this.state.customFilters) {
            // if no specific rules for this category => continue with next
            if (!this.state.customFilters?.[category]) {
                continue;
            }

            // otherwise, we include our filter
            output = {
                ...output,
                [category]: this.state.customFilters?.[category],
            };
        }
        return output;
    }

    buildSelectionField(
        categorySettings,
        filtersInitiated,
        representationDetails,
        key
    ) {
        const category = categorySettings.categorie;

        // we create and initialize the options for current switch button
        let options = [];
        for (let filter of filtersInitiated) {
            options.push({
                label: filter.filtre_categorie.slice((category + ".").length),
            });
        }

        const tooltipText =
            representationDetails?.["additional_details_text_" + category];

        return (
            <div key={"selection-" + category} className="category-selection">
                <label
                    className="selection-label"
                    htmlFor={"infos-selection-" + category + key}
                    title={tooltipText}
                >
                    {categorySettings.titre}
                </label>
                <select
                    onChange={(e) =>
                        this.changeCategorySelection(category, e.target.value)
                    }
                    id={"infos-selection-" + category + key}
                    name={"infos-selection-" + category + key}
                >
                    {options.map((option) => {
                        return (
                            <option
                                key={option.label}
                                value={category + "." + option.label}
                                selected={
                                    category + "." + option.label ===
                                    this.state.customFilters?.[category]?.[0]
                                        ?.filtre_categorie
                                }
                            >
                                {option.label}
                            </option>
                        );
                    })}
                </select>
            </div>
        );
    }

    buildSwitchButton(categorySettings, filtersInitiated, representationDetails, key) {
        const category = categorySettings.categorie;

        // we create and initialize the options for current switch button
        let options = [];
        for (let filter of filtersInitiated) {
            options.push({
                label: filter.filtre_categorie.slice((category + ".").length),
            });
            if (options.length === 2) {
                break;
            }
        }
        // if not enough options => nothing to display
        if (options.length < 2) return null;

        const tooltipText =
            representationDetails?.["additional_details_text_" + category];

        // needed to be able to apply the defaultChecked value after first init
        // otherwise, first defaultChecked was empty and was then not changed even after rerendering
        let customOptions = {};
        if (this.state.customFiltersInitiated) {
            // we check (ie. we go to right) if current filter value is equal to option[1] (ie. right)
            customOptions["defaultChecked"] =
                this.state.customFilters?.[category]?.[0]?.filtre_categorie ===
                category + "." + options[1].label;
        }

        return (
            <div key={"switch-button-" + category} className="category-switch">
                <p>
                    <strong>{categorySettings.titre}</strong>
                </p>
                <label
                    key="before"
                    className="switch-labels"
                    htmlFor={"infos-switch-button-" + category + key}
                    title={tooltipText}
                >
                    {options[0].label}
                </label>
                <label key="button" className="switch">
                    <input
                        type="checkbox"
                        onClick={() =>
                            this.switchButtonCategory(category, [
                                category + "." + options[0].label,
                                category + "." + options[1].label,
                            ])
                        }
                        id={"infos-switch-button-" + category + key}
                        name={"infos-switch-button-" + category + key}
                        {...customOptions}
                    />
                    <span className="slider round"></span>
                </label>
                <label
                    key="after"
                    htmlFor={"infos-switch-button-" + category + key}
                    className="switch-labels"
                    title={tooltipText}
                >
                    {options?.[1].label}
                </label>
            </div>
        );
    }

    /**
     * Add switch or selection field for specific categories.
     */
    insertSpecificCategorySelector(
        categoriesSettings,
        filtersInitiated,
        representationDetails,
        key
    ) {
        // if we have no category, no need to display anything
        if (!categoriesSettings) {
            return "";
        }
        let outputs = [];

        for (const categorySettings of categoriesSettings) {
            if (!categorySettings) continue;
            const category = categorySettings.categorie;
            const filters = filtersInitiated?.["____all_" + category];
            // if we have the category is not concerned => nothing to display
            if (
                !["switch-button", "selection"].includes(categorySettings?.type) ||
                !filters
            ) {
                continue;
            }
            if (categorySettings?.type === "selection") {
                outputs.push(
                    this.buildSelectionField(
                        categorySettings,
                        filters,
                        representationDetails,
                        key
                    )
                );
            }
            if (categorySettings?.type === "switch-button") {
                outputs.push(
                    this.buildSwitchButton(
                        categorySettings,
                        filters,
                        representationDetails,
                        key
                    )
                );
            }
        }
        return outputs;
    }

    render() {
        const unit = this.props.unit ? this.props.unit : false;
        const zoneType = this.props?.zoneType ?? this.props.parentApi.data.zone.zone;
        const zoneId = this.props?.zoneId ?? this.props.parentApi.data.currentZone;
        const zoneMaille =
            this.props?.zoneMaille ?? this.props.parentApi.data.zone.maille;
        const mapCallback = this.props?.mapCallback;

        let forcedFilters = false;
        let selector = "";

        if (
            [
                "line",
                "line-years",
                "bar",
                "bar-years",
                "pie",
                "courbes_croisees",
                "radar",
                "courbes-historiques",
                "marqueur-svg",
                "map",
            ].includes(this.props.representation)
        ) {
            const { analysisManager } = this.props.parentApi.controller;
            const categoriesSettings = analysisManager.getCategories(
                this.props.id_analysis
            );
            const representationDetails =
                analysisManager.getAnalysisRepresentationDetails(
                    this.props.id_analysis
                );
            const filtersInitiated = analysisManager.getFullCategoriesValues(
                this.props.id_analysis
            );
            selector = this.insertSpecificCategorySelector(
                categoriesSettings,
                filtersInitiated,
                representationDetails,
                this.props.id
            );
            forcedFilters = this.getSpecificCategoryFilters();
        }

        let graphique = "";
        if (
            this.props.representation === "line" ||
            this.props.representation === "line-years"
        ) {
            graphique = (
                <CourbesEmpilees
                    parentApi={this.props.parentApi}
                    zoneType={zoneType}
                    zoneId={zoneId}
                    zoneMaille={zoneMaille}
                    id_analysis={this.props.id_analysis}
                    id={this.props.id}
                    type={this.props.type}
                    provenance={this.props.provenance}
                    width={this.props.width}
                    height={this.props.height}
                    representation={this.props.representation}
                    thematique={this.props.thematique}
                    filters={this.props.filters}
                    forcedFilters={forcedFilters}
                    unit={this.props.unit}
                    options={this.props.options}
                />
            );
        } else if (
            this.props.representation === "bar" ||
            this.props.representation === "bar-years"
        ) {
            graphique = (
                <BarGraph
                    parentApi={this.props.parentApi}
                    id_analysis={this.props.id_analysis}
                    id={this.props.id}
                    type={this.props.type}
                    provenance={this.props.provenance}
                    representation={this.props.representation}
                    width={this.props.width}
                    height={this.props.height}
                    thematique={this.props.thematique}
                    filters={this.props.filters}
                    forcedFilters={forcedFilters}
                    unit={this.props.unit}
                    zoneType={zoneType}
                    zoneId={zoneId}
                    zoneMaille={zoneMaille}
                />
            );
        } else if (this.props.representation === "pie") {
            graphique = (
                <DiagrammeCirculaire
                    parentApi={this.props.parentApi}
                    zoneType={zoneType}
                    zoneId={zoneId}
                    zoneMaille={zoneMaille}
                    id_analysis={this.props.id_analysis}
                    id={this.props.id}
                    thematique={this.props.thematique}
                    type={this.props.type}
                    provenance={this.props.provenance}
                    filters={this.props.filters}
                    forcedFilters={forcedFilters}
                    unit={this.props.unit}
                />
            );
        } else if (this.props.representation === "courbes_croisees") {
            graphique = (
                <CourbesComparees
                    parentApi={this.props.parentApi}
                    zoneType={zoneType}
                    zoneId={zoneId}
                    zoneMaille={zoneMaille}
                    id_analysis={this.props.id_analysis}
                    type={this.props.type}
                    options={this.props.options}
                    width={this.props.width}
                    height={this.props.height}
                />
            );
        } else if (this.props.representation === "jauge") {
            graphique = (
                <Jauge
                    parentApi={this.props.parentApi}
                    zoneType={zoneType}
                    zoneId={zoneId}
                    zoneMaille={zoneMaille}
                    id_analysis={this.props.id_analysis}
                    type={this.props.type}
                />
            );
        } else if (this.props.representation === "jauge-circulaire") {
            graphique = (
                <JaugeCirculaire
                    parentApi={this.props.parentApi}
                    zoneType={zoneType}
                    zoneId={zoneId}
                    zoneMaille={zoneMaille}
                    id_analysis={this.props.id_analysis}
                    type={this.props.type}
                />
            );
        } else if (this.props.representation === "histogramme") {
            graphique = (
                <Histogramme
                    parentApi={this.props.parentApi}
                    zoneType={zoneType}
                    zoneId={zoneId}
                    zoneMaille={zoneMaille}
                    id_analysis={this.props.id_analysis}
                    id={this.props.id}
                    type={this.props.type}
                    provenance={this.props.provenance}
                    thematique={this.props.thematique}
                    filters={this.props.filters}
                    forcedFilters={forcedFilters}
                    unit={this.props.unit}
                />
            );
        } else if (this.props.representation === "radar") {
            graphique = (
                <DiagrammeRadar
                    parentApi={this.props.parentApi}
                    zoneType={zoneType}
                    zoneId={zoneId}
                    zoneMaille={zoneMaille}
                    id_analysis={this.props.id_analysis}
                    id={this.props.id}
                    thematique={this.props.thematique}
                    type={this.props.type}
                    provenance={this.props.provenance}
                    inverserAxeVertical={this.props.inverserAxeVertical}
                    data={this.props.data}
                    mapCallback={mapCallback}
                    filters={this.props.filters}
                    forcedFilters={forcedFilters}
                    unit={this.props.unit}
                />
            );
        } else if (this.props.representation === "histogramme-normalise") {
            graphique = (
                <HistogrammeNormalise
                    parentApi={this.props.parentApi}
                    zoneType={zoneType}
                    zoneId={zoneId}
                    zoneMaille={zoneMaille}
                    id_analysis={this.props.id_analysis}
                    id={this.props.id}
                    thematique={this.props.thematique}
                    type={this.props.type}
                    provenance={this.props.provenance}
                    filters={this.props.filters}
                    forcedFilters={forcedFilters}
                    unit={this.props.unit}
                />
            );
        } else if (this.props.representation === "courbes-historiques") {
            graphique = (
                <CourbesComparees
                    parentApi={this.props.parentApi}
                    zoneType={zoneType}
                    zoneId={zoneId}
                    zoneMaille={zoneMaille}
                    id_analysis={this.props.id_analysis}
                    type={this.props.type}
                    options={this.props.options}
                    forcedFilters={forcedFilters}
                />
            );
        } else if (this.props.representation === "histogramme-data-ratio") {
            graphique = (
                <HistogrammeDataRatio
                    parentApi={this.props.parentApi}
                    zoneType={zoneType}
                    zoneId={zoneId}
                    zoneMaille={zoneMaille}
                    id_analysis={this.props.id_analysis}
                    id={this.props.id}
                    type={this.props.type}
                    provenance={this.props.provenance}
                    unit={unit}
                />
            );
        } else if (this.props.representation === "marqueur-svg") {
            graphique = (
                <MarqueurSVG
                    parentApi={this.props.parentApi}
                    zoneType={zoneType}
                    zoneId={zoneId}
                    zoneMaille={zoneMaille}
                    id_analysis={this.props.id_analysis}
                    id={this.props.id}
                    type={this.props.type}
                    provenance={this.props.provenance}
                    tailleSVG={this.props.tailleSVG}
                    tailleData={this.props.tailleData}
                    image={this.props.image}
                    filters={this.props.filters}
                    forcedFilters={forcedFilters}
                />
            );
        } else if (this.props.representation === "analysis-launcher") {
            graphique = (
                <AnalysisLauncher
                    parentApi={this.props.parentApi}
                    zoneType={zoneType}
                    zoneId={zoneId}
                    zoneMaille={zoneMaille}
                    id_analysis={this.props.id_analysis}
                    id={this.props.id}
                    type={this.props.type}
                    provenance={this.props.provenance}
                    color={this.props.couleur}
                />
            );
        } else if (this.props.representation === "map") {
            graphique = (
                <AnalysisMap
                    parentApi={this.props.parentApi}
                    zoneType={zoneType}
                    zoneId={zoneId}
                    zoneMaille={zoneMaille}
                    id_analysis={this.props.id_analysis}
                    id={this.props.id}
                    type={this.props.type}
                    provenance={this.props.provenance}
                    thematique={this.props.thematique}
                    filters={this.props.filters}
                    forcedFilters={forcedFilters}
                    unit={this.props.unit}
                />
            );
        } else if (this.props.representation === "poi_map") {
            graphique = (
                <PoiMap
                    parentApi={this.props.parentApi}
                    zoneType={zoneType}
                    zoneId={zoneId}
                    zoneMaille={zoneMaille}
                    id_analysis={this.props.id_analysis}
                    id={this.props.id}
                    type={this.props.type}
                    provenance={this.props.provenance}
                    thematique={this.props.thematique}
                    filters={this.props.filters}
                    unit={this.props.unit}
                />
            );
        } else if (this.props.representation === "logo") {
            graphique = (
                <Logo
                    parentApi={this.props.parentApi}
                    zoneType={zoneType}
                    zoneId={zoneId}
                    zoneMaille={zoneMaille}
                    id_analysis={this.props.id_analysis}
                    id={this.props.id}
                    type={this.props.type}
                    provenance={this.props.provenance}
                />
            );
        } else if (this.props.representation === "didactic-file-launcher") {
            graphique = (
                <DidacticFileLauncher
                    parentApi={this.props.parentApi}
                    zoneType={zoneType}
                    zoneId={zoneId}
                    zoneMaille={zoneMaille}
                    id_analysis={this.props.id_analysis}
                    id={this.props.id}
                    type={this.props.type}
                    provenance={this.props.provenance}
                    color={this.props.couleur}
                    id_tableau_bord={this.props.id_tableau_bord}
                />
            );
        } else if (this.props.representation === "link-launcher") {
            graphique = (
                <LinkLauncher
                    parentApi={this.props.parentApi}
                    zoneType={zoneType}
                    zoneId={zoneId}
                    zoneMaille={zoneMaille}
                    id_analysis={this.props.id_analysis}
                    name={this.props.nom}
                    id={this.props.id}
                    type={this.props.type}
                    provenance={this.props.provenance}
                    color={this.props.couleur}
                    id_tableau_bord={this.props.id_tableau_bord}
                />
            );
        } else if (this.props.representation === "pcaet-trajectory-line") {
            graphique = (
                <PCAETTrajectory
                    parentApi={this.props.parentApi}
                    zoneType={zoneType}
                    zoneId={zoneId}
                    zoneMaille={zoneMaille}
                    id_analysis={this.props.id_analysis}
                    id={this.props.id}
                    type={this.props.type}
                    provenance={this.props.provenance}
                    width={this.props.width}
                    height={this.props.height}
                    representation={this.props.representation}
                    thematique={this.props.thematique}
                    filters={this.props.filters}
                    unit={this.props.unit}
                    options={this.props.options}
                />
            );
        } else if (this.props.representation === "pcaet-trajectory-table") {
            graphique = (
                <DataTable
                    parentApi={this.props.parentApi}
                    zoneType={zoneType}
                    zoneId={zoneId}
                    zoneMaille={zoneMaille}
                    id_analysis={this.props.id_analysis}
                    name={this.props.nom}
                    id={this.props.id}
                    type={this.props.type}
                    provenance={this.props.provenance}
                    color={this.props.couleur}
                    id_tableau_bord={this.props.id_tableau_bord}
                />
            );
        } else if (this.props.representation === "text-block") {
            graphique = (
                <div className="text-block-show">
                    <TextBlock content={this.props.content} />
                </div>
            );
        }
        return (
            <div>
                {" "}
                {selector}
                {graphique}
            </div>
        );
    }
}

export default FabriqueRepresentation;
