/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import { saveAs } from "file-saver";
import React from "react";

import Api from "../../Controllers/Api";
import config from "../../settings.js";
import { buildRegionUrl } from "../../utils.js";

/**
 * This component allows to edit the template Excel file used to export the results
 * and parameters of territorial strategies.
 */
class EditStrategyResultsFile extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            file: undefined,
            status: false,
            statusMessage: false,
            updateDate: this.props.parentApi.data.settings.export_excel_update_date,
        };
    }
    /**
     * Change File into state
     * @return None
     */
    changeFile = (event) => {
        this.setState({ file: event.target.files[0] });
    };

    /**
     * Validate the form
     * @return {status} true or false whether data is valid
     */
    formIsValid() {
        // file is required (only condition)
        if (this.state.file === undefined) {
            this.setState({
                status: "warning",
                statusMessage: "Vous devez fournir un fichier.",
            });
            return false;
        } else {
            return true;
        }
    }

    /**
     * Update the File through API
     * @return {status} false if the form is invalid
     */
    updateFile() {
        let formData = new FormData();
        // data is invalid => exits
        if (!this.formIsValid()) {
            return false;
        }

        // Updates the formdata before sending it to the API
        formData.append("file", this.state.file);

        // Calls the API with files and metadata
        Api.callApi(
            buildRegionUrl(
                config.api_export_strategy_results_update_template,
                this.props.parentApi.data.region
            ),
            formData,
            "POST",
            "default"
        )
            .then((response) => {
                this.setState({
                    status: "success",
                    statusMessage: response.message,
                    updateDate: response.date,
                });
            })
            .catch((e) => {
                this.setState({ status: "error", statusMessage: e.message });
            });
    }

    /**
     * Get current template file
     * @returns the file from the API
     */
    getFile() {
        fetch(
            buildRegionUrl(
                config.api_export_strategy_results_get_template,
                this.props.parentApi.data.region
            ),
            {
                method: "get",
                body: null,
                credentials: "include",
            }
        ).then((response) => {
            if (response.ok) {
                return response.blob().then((data) => {
                    // Display results
                    saveAs(
                        data,
                        "export-impacts-strategie-territoriale-" +
                            this.props.parentApi.data.region +
                            ".xlsx"
                    );
                });
            } else {
                return response.text().then((text) => {
                    alert(text);
                });
            }
        });
    }

    render() {
        let errors = "";
        if (this.state.status && this.state.statusMessage) {
            errors = (
                <div className={"alert alert-" + this.state.status}>
                    {this.state.statusMessage}
                </div>
            );
        }

        return (
            <div>
                <h3>
                    Modifier le fichier utilisé pour exporter les résultats des
                    stratégies territoriales
                </h3>
                <p>Le fichier actuellement utilisé est le fichier suivant :</p>
                <p>
                    <button
                        className="btn btn-secondary"
                        onClick={() => this.getFile()}
                    >
                        export-impacts-strategie-territoriale-
                        {this.props.parentApi.data.region}.xlsx
                    </button>
                </p>
                <p>Dernière mise à jour du fichier de template :</p>
                <p>
                    <em>{this.state.updateDate}</em>
                </p>
                <h4>Mettre à jour le fichier</h4>
                <p>
                    <input type="file" onChange={this.changeFile} />
                </p>
                <p>
                    <button
                        className="btn btn-success"
                        onClick={() => this.updateFile()}
                    >
                        Mettre à jour la couche équipement
                    </button>
                </p>
                {errors}
            </div>
        );
    }
}

export default EditStrategyResultsFile;
