/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */
import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => (
    <div className="widgets full-screen-widget">
        <Link className="back-to-map" to={"/"}>
            <button
                type="button"
                className="close close-big"
                data-dismiss="alert"
                aria-label="Close"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </Link>{" "}
        <div className="tout open-source">
            <div className="img">
                <img
                    src="../../img/logo_TerriSTORY_transparent.png"
                    alt="Logo de TerriSTORY®"
                ></img>
            </div>
            <div className="texte-corps">
                <h3>Page non trouvée, erreur 404</h3>
                <p>
                    Vous pouvez essayer d'actualiser la page. Si cette page vous
                    apparaît plusieurs fois au même endroit, vous pouvez signaler ce bug
                    en allant sur{" "}
                    <a href="https://gitlab.com/terristory/terristory/-/issues/new?issuable_template=bug_report">
                        cette page
                    </a>{" "}
                    ou bien en envoyant un mail à <em>contact@terristory.fr</em> qui
                    décrit les étapes suivies pour aboutir à cette page.
                </p>
                <p>
                    L'équipe de TerriSTORY® vous prie de l'excuser pour la gêne
                    occasionnée.
                </p>
            </div>
        </div>
    </div>
);

export default NotFound;
