/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import Api from "./Api";

import config from "../settings.js";
import { buildRegionUrl } from "../utils.js";

/**
 * This component display the dashboards list that are available, either because
 * owned by current user, or because they have been published by the owner at
 * current geographical level.
 */
class DashboardService {
    constructor(
        callback,
        region,
        regionCode,
        territoryType,
        territoryIDCode,
        wasEditing
    ) {
        this.listMyDashboards = undefined;
        this.listPublicDashboards = undefined;
        this.status = undefined;
        this.region = region;
        this.regionCode = regionCode;
        this.territoryType = territoryType;
        this.territoryIDCode = territoryIDCode;
        // if was editing another board, propose to reload it and continue editing
        this.wasEditing = wasEditing;
        // retrieve current user dashboards list
        this.retrieveMyDashboardsList(callback);
        // retrieve currently available public dashboards
        this.retrievePublicDashboardList(callback);
    }

    /**
     * Retrieve all public dashboards for this specific territory
     * @param {string} territoryType the territory type (region, etc.)
     * @param {string} territoryIDCode the INSEE id for this territory
     */
    refreshDashboardLists(territoryType, territoryIDCode) {
        if (
            territoryType === this.territoryType &&
            territoryIDCode === this.territoryIDCode
        ) {
            return;
        }
        this.territoryType = territoryType;
        this.territoryIDCode = territoryIDCode;

        this.retrievePublicDashboardList(() => {});
    }

    /**
     * Add classes to a list of large enough (150 offset) children of current
     * HTML element.
     *
     * @param {DOM element} elemHtml the element whose children should be edited
     * @param {string} diagramme the optional additionnal class to add
     */
    applyBottomMargin(elemHtml, diagramme) {
        if (!diagramme) {
            diagramme = "";
        }
        for (let enfant of elemHtml) {
            for (let listeModalite of enfant.children) {
                for (let libelleModalite of listeModalite.children) {
                    if (libelleModalite.offsetWidth >= 150) {
                        listeModalite.setAttribute(
                            "class",
                            "legende-modalite " + diagramme
                        );
                    }
                }
            }
        }
    }

    /**
     * Change wasEditing value.
     * @param {boolean} wasEditing the new value
     */
    setEditingModeDashboard(wasEditing) {
        this.wasEditing = wasEditing;
    }

    /**
     * Retrieves the list of current user dashboards
     * @param {callable} callback the function to call after API query succeeded
     */
    retrieveMyDashboardsList(callback) {
        /**
         * This function initializes the `listMyDashboards` parameter of
         * current component. This parameter is intended to contain
         * the list of all the dashboards created by currently connected person.
         * 
         * As a reminder, this is a list of objects structured as follows
        {
            id : group id
            titre : title
            identifiant_tableau : dashboard id
            graphiques : [
                  {
                      identifiant_analyse : graph id
                      representation: graph type
                      categories {
                          first category {
                              nom : category name (ex : secteurs, energies, usages, type_prod_enr etc.)
                              titre : category title (ex : Par secteurs, Par filières de production etc.)
                              visible : true if should be displayed or false
                          }
                          .
                          .
                          .
                          second category {
                                .
                                .
                                .
                          }
                      }
                  },
                  {
                    .
                    .
                    .
                  }
            ]
        }
        */
        const url = buildRegionUrl(config.tableau_bord_utilisateur_url, this.region);
        Api.callApi(url, null, "GET")
            .then((response) => {
                if (response.statut && response.statut === "Non connecté") {
                    this.listMyDashboards = [];
                } else {
                    this.listMyDashboards = response;
                }
                callback(this.listMyDashboards);
            })
            .catch((e) => (this.status = e));
    }

    /**
     * Retrieves all public dashboards available at current geographical level.
     * @param {callable} callback the function to call after API query succeeded
     */
    retrievePublicDashboardList(callback) {
        /**
        * This function initializes the `listPublicDashboards` parameter of
         * current component. This parameter is intended to contain
         * the list of all the dashboards available (because public) at
         * current geographical level and for current zone selected.
         * 
         * As a reminder, this is a list of objects structured as follows
        {
            id : group id
            titre : title
            identifiant_tableau : dashboard id
            graphiques : [
                  {
                      identifiant_analyse : graph id
                      representation: graph type
                      categories {
                          first category {
                              nom : category name (ex : secteurs, energies, usages, type_prod_enr etc.)
                              titre : category title (ex : Par secteurs, Par filières de production etc.)
                              visible : true if should be displayed or false
                          }
                          .
                          .
                          .
                          second category {
                                .
                                .
                                .
                          }
                      }
                  },
                  {
                    .
                    .
                    .
                  }
            ]
        }
        */
        if (this.territoryType) {
            // Normal URL ends with « /tableau » (cf. settings.js file).
            // We need here the list of *public* dashboards, therefore we change
            // the URL used and specify geographical level and zone ID.
            const url =
                buildRegionUrl(config.tableau_bord_public_url, this.region) +
                "?zone=" +
                this.territoryType +
                "&zone_id=" +
                this.territoryIDCode;
            Api.callApi(url, null, "GET")
                .then((response) => {
                    this.listPublicDashboards = response;
                    callback();
                })
                .catch((e) => (this.status = e));
        }
    }
}
export default DashboardService;
