/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import { Link } from "react-router-dom";
import "rc-slider/assets/index.css";
import Slider from "rc-slider";
import "bootstrap/dist/css/bootstrap.min.css";
import Api from "../../Controllers/Api";
import config from "../../settings.js";
import SEO from "../SEO";
import { buildRegionUrl, addPlusSign, createPdfMethodoLink } from "../../utils.js";
import configData from "../../settings_data.js";
import "../../style/simulator.css";
import { get_consumption } from "./consumption";
import { get_ghg } from "./ghg";
import { get_energy_bill } from "./energy_bill";
import { get_pollutant } from "./air_pollutant";

class OverflowSlider extends React.Component {
    render() {
        const type = this.props.type;
        const handleBorder = this.props.handleBorder;
        const defaultVal = this.props.defaultVal;
        const maxValue = this.props.maxValue;
        const defaultCurrentValue = this.props.defaultCurrentValue;
        const maxValueMarks = this.props.maxValueMarks;
        if (defaultVal > maxValue) {
            return (
                <Slider
                    className={
                        "slider-overflowed slider-simulateur-impact " +
                        type +
                        handleBorder
                    }
                    defaultValue={maxValue}
                    min={0}
                    max={defaultVal}
                    step={1}
                    disabled={true}
                    value={maxValue}
                    marks={{
                        0: 0,
                        [maxValue]: maxValueMarks,
                        [defaultVal]: defaultCurrentValue,
                    }}
                    style={{ width: (defaultVal / maxValue) * 85 + "%" }}
                />
            );
        }

        return (
            <Slider
                className={"slider-simulateur-impact " + type + handleBorder}
                defaultValue={defaultVal}
                min={0}
                max={maxValue}
                step={1}
                disabled={true}
                value={defaultVal}
                marks={{
                    0: 0,
                    [defaultVal]: defaultCurrentValue,
                    [maxValue]: maxValueMarks,
                }}
            />
        );
    }
}

/**
 * This component is used to
 */
class Simulator extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            configuration: {},
            generic_input_param: {},
            specific_input_param: {},
            genericIntermediateParams: {},
            intermediate_compute: {},
            status: false,
        };
    }

    componentDidMount() {
        this.fetchConfigurations();
    }

    componentWillUnmount() {
        if (this.promise) this.promise.abort();
    }

    fetchConfigurations() {
        let territory = "&maille=" + this.props.parentApi.data.zone.maille;
        let territoryType = "?zone=" + this.props.parentApi.data.zone.zone;
        let territoryCode = "&zone_id=" + this.props.parentApi.data.currentZone;
        let urlPartageable =
            territoryType + territory + territoryCode + "&type=mobilité";
        this.props.parentApi.callbacks.mettreAJourParametresUrls(urlPartageable);
        const is_national = this.props.parentApi.data.settings["is_national"];
        const { region } = this.props.parentApi.data;
        const url = this.buildSimulatorUrl(
            is_national,
            region,
            territoryType,
            territory,
            territoryCode
        );
        if (this.promise) this.promise.abort();
        this.promise = Api.callApi(url, null, "GET");
        this.promise
            .then((response) => {
                if (response.metadata.level.status && response.metadata.level.message) {
                    // throw missing data error if the level is not filled in the database
                    this.setState({
                        status: response.metadata.level.status,
                        errorMsg: response.metadata.level.message,
                        is_national: this.props.parentApi.data.settings["is_national"],
                    });
                } else {
                    let actions_params = {};
                    // set default values for each action
                    Object.values(response["metadata"].data).forEach(function (
                        category
                    ) {
                        Object.values(category.levers).forEach(function (lever) {
                            Object.values(lever.actions).forEach(function (action) {
                                for (let nbrAction in response["actions_params"]) {
                                    if (nbrAction === action.number) {
                                        action.defaultValue =
                                            response["actions_params"][nbrAction];
                                        actions_params[nbrAction] =
                                            response["actions_params"][
                                                nbrAction
                                            ].default;
                                    }
                                }
                            });
                        });
                    });

                    // set default values for impact graphs
                    Object.values(response["metadata"].impacts).forEach(function (
                        impact
                    ) {
                        for (let type in response["impacts_params"]) {
                            if (type === impact["impactType"]) {
                                impact.defaultValue =
                                    response["impacts_params"][type].default;
                                impact.typeDisplay =
                                    response["impacts_params"][type].type;
                            }
                        }
                    });
                    this.setState({
                        configuration: response["metadata"],
                        activated_impacts: response["activated_impacts"],
                        generic_input_param: response["generic_input_param"],
                        specific_input_param: response["specific_input_param"],
                        genericIntermediateParams: response["generic_inter_param"],
                        intermediate_compute: response["intermediate_compute"],
                        actions_params: actions_params,
                        is_national: this.props.parentApi.data.settings["is_national"],
                    });
                }
            })
            .catch((e) => {
                if (e.name === "AbortError") return;
                this.setState({
                    status: e.message,
                    is_national: this.props.parentApi.data.settings["is_national"],
                });
            });
    }

    buildSimulatorUrl(isNational, region, territoryType, territory, territoryCode) {
        const baseApi = isNational
            ? config.api_national_simulator
            : config.api_simulator;
        const baseRegion = isNational ? "national" : region;

        let url = buildRegionUrl(baseApi, baseRegion)
            .replace("#type#", "mobility")
            .replace("#territory#", region);

        url += territoryType + territory + territoryCode;

        return url;
    }

    addCategory() {
        let configuration = this.state.configuration;
        let actions_params = this.state.actions_params;
        let regionName = this.props.parentApi.data.region;
        const handleCategoryChange = (e) => {
            e.stopPropagation();
            // we enable the checked category
            Object.values(configuration.data).forEach(function (category) {
                if (parseInt(e.target.name, 10) === category.id) {
                    category.enabled = e.target.checked;
                    // if category lever is unchecked
                    if (!e.target.checked) {
                        // reset actions and impacts to default values
                        reset(category.id);
                    }
                }
            });
            this.setState({
                configuration: configuration,
                actions_params: actions_params,
            });
        };

        const reset = (categoryId) => {
            // reset all actions to default values
            Object.values(configuration.data[categoryId].levers).forEach(function (
                lever
            ) {
                Object.values(lever.actions).forEach(function (action) {
                    // reseting action
                    for (let nbrAction in actions_params) {
                        if (nbrAction === action.number) {
                            action.value = action.defaultValue.default;
                            actions_params[nbrAction] = action.defaultValue.default;
                            action.totalMotorization = undefined;
                            action.enabled = false;
                            // reseting sub-action if exist
                            if (action.defaultValue.type === "complex") {
                                Object.keys(action.defaultValue.default).forEach(
                                    function (subActionIndex) {
                                        for (let nbrSubAction in action.defaultValue
                                            .default) {
                                            if (nbrSubAction === subActionIndex) {
                                                action.defaultValue.default[
                                                    nbrSubAction
                                                ].value =
                                                    action.defaultValue.default[
                                                        nbrSubAction
                                                    ].default;
                                                actions_params[action.number][
                                                    subActionIndex
                                                ].value =
                                                    action.defaultValue.default[
                                                        nbrSubAction
                                                    ].default;
                                            }
                                        }
                                    }
                                );
                            }
                        }
                    }
                });
            });
            // update the results impacts
            this.handleParamsChange();
        };
        return (
            this.state.configuration.data &&
            Object.keys(this.state.configuration.data).map((categoryId) => {
                let id = this.state.configuration.data[categoryId].id;
                let title = this.state.configuration.data[categoryId].categoryTitle;
                let enabled = this.state.configuration.data[categoryId].enabled;
                let enabledRegionsCategory =
                    this.state.configuration.data[categoryId].regions_enabled;
                let enabledCategory = enabledRegionsCategory.includes(regionName);
                return (
                    enabledCategory && (
                        <div
                            className="checkbox-container-category-simulator"
                            key={"catgory-" + categoryId}
                        >
                            <ul>
                                <li>
                                    <input
                                        type="checkbox"
                                        id={"category-" + id}
                                        name={id}
                                        onClick={handleCategoryChange}
                                    ></input>
                                    <label htmlFor={"category-" + id}>{title}</label>
                                    {this.state.configuration.data[categoryId].levers &&
                                        Object.keys(
                                            this.state.configuration.data[categoryId]
                                                .levers
                                        ).length > 0 &&
                                        enabled &&
                                        Object.keys(
                                            this.state.configuration.data[categoryId]
                                                .levers
                                        ).map((leverId) => {
                                            let enabledRegion =
                                                this.state.configuration.data[
                                                    categoryId
                                                ].levers[leverId].regions_enabled;
                                            let enabledLever =
                                                enabledRegion.includes(regionName);
                                            return this.addLevers(
                                                categoryId,
                                                leverId,
                                                enabledLever
                                            );
                                        })}
                                </li>{" "}
                            </ul>
                        </div>
                    )
                );
            })
        );
    }

    addLevers(categoryId, leverId, enabledLever) {
        return (
            enabledLever && (
                <div
                    key={"category_" + categoryId + "_sub_category_" + leverId}
                    id={"category_" + categoryId + "_sub_category_" + leverId}
                    className="checkbox-container-lever-simulator"
                >
                    <h3>
                        {
                            this.state.configuration.data[categoryId].levers[leverId]
                                .leverTitle
                        }
                    </h3>

                    <ul>
                        {Object.keys(
                            this.state.configuration.data[categoryId].levers[leverId]
                                .actions
                        ).map((actionId) => {
                            return this.addActions(categoryId, leverId, actionId);
                        })}
                    </ul>
                </div>
            )
        );
    }

    addActions(categoryId, leverId, actionId) {
        let configuration = this.state.configuration;
        let actions_params = this.state.actions_params; // simple dict {action : value} used for calcul
        let action =
            this.state.configuration.data[categoryId].levers[leverId].actions[actionId];
        const handleActionChange = (e) => {
            let configuration = this.state.configuration;
            // we enable the checked action
            Object.values(
                configuration.data[categoryId].levers[leverId].actions
            ).forEach(function (action) {
                if (parseInt(e.target.name, 10) === action.id) {
                    action.enabled = e.target.checked;
                    if (!e.target.checked) {
                        // reset actions and impacts to default values
                        action.totalMotorization = undefined;
                        reset();
                    }
                }
            });

            this.setState({
                configuration: configuration,
            });
        };

        const handleChangeSlider = (e, actionNumber) => {
            Object.values(
                configuration.data[categoryId].levers[leverId].actions
            ).forEach(function (action) {
                if (actionNumber === action.number) {
                    if (e === Number(action.defaultValue.default.toFixed(0))) {
                        actions_params[actionNumber] = action.defaultValue.default;
                    } else if (e === Number(action.defaultValue.max.toFixed(0))) {
                        actions_params[actionNumber] = action.defaultValue.max;
                    } else {
                        actions_params[actionNumber] = e;
                    }
                    action.value = e;
                }
            });
            this.setState({
                configuration: configuration,
                actions_params: actions_params,
            });
        };

        const handleAfterChangeSlider = () => {
            this.handleParamsChange();
        };

        const reset = () => {
            // reset unchecked action to default values
            for (let nbrAction in actions_params) {
                if (nbrAction === action.number) {
                    action.value = action.defaultValue.default;
                    actions_params[nbrAction] = action.defaultValue.default;
                    action.enabled = false;
                    // reseting sub-action if exist
                    if (action.defaultValue.type === "complex") {
                        Object.keys(action.defaultValue.default).forEach(function (
                            subActionIndex
                        ) {
                            for (let nbrSubAction in action.defaultValue.default) {
                                if (nbrSubAction === subActionIndex) {
                                    action.defaultValue.default[nbrSubAction].value =
                                        action.defaultValue.default[
                                            nbrSubAction
                                        ].default;
                                    actions_params[action.number][
                                        subActionIndex
                                    ].value =
                                        action.defaultValue.default[
                                            nbrSubAction
                                        ].default;
                                }
                            }
                        });
                    }
                }
            }
            // update the results impacts
            this.handleParamsChange();
        };

        let colorRegion = getComputedStyle(document.body).getPropertyValue(
            "--" + this.props.parentApi.data.settings.theme
        );
        let regionName = this.props.parentApi.data.region;
        let style = {
            backgroundColor: colorRegion.toUpperCase() + "33", // add transparency to region colors '33': 20%
            border: "solid " + colorRegion,
        };

        let idAction = action.id;
        let enabled = action.enabled;
        let helpComment = action.comment;
        let maxv;
        let defaultVal;
        let unite = action.unit ? " " + action.unit : "";
        let idInput =
            "category_" + categoryId + "_lever_" + leverId + "_action_" + idAction;
        if (action.defaultValue.type === "simple") {
            maxv = Number(action.defaultValue.max).toFixed(0);
            defaultVal =
                action.value !== undefined
                    ? Number(action.value.toFixed(0))
                    : Number(action.defaultValue.default.toFixed(0));
        }
        let totalMotorization = action.totalMotorization
            ? action.totalMotorization
            : undefined;
        return (
            <li key={idInput} style={style}>
                &nbsp; &nbsp;
                <input
                    style={{ color: colorRegion, backgroundColor: colorRegion }}
                    type="checkbox"
                    id={idInput}
                    name={idAction}
                    onClick={handleActionChange}
                ></input>
                <label style={{ color: colorRegion }} htmlFor={idInput}>
                    {action.title}
                </label>
                {helpComment && (
                    <i title={helpComment} className="bi bi-question-circle-fill"></i>
                )}
                <br />
                {totalMotorization !== undefined &&
                    Number(totalMotorization).toFixed(0) < 100 && (
                        <em className="comment-impact">
                            {" "}
                            * Attention ! la somme des différentes motorisations est
                            inférieure à 100%
                        </em>
                    )}
                {enabled && action.defaultValue.type === "simple" && (
                    <div>
                        <Slider
                            data-testid={action.title}
                            className={"slider-simulateur " + regionName}
                            defaultValue={defaultVal}
                            min={Number(action.defaultValue.min)}
                            max={Number(action.defaultValue.max).toFixed(0)}
                            step={1}
                            value={defaultVal}
                            marks={{
                                0: 0,
                                [defaultVal]: {
                                    style: {
                                        color: "black",
                                    },
                                    label:
                                        Intl.NumberFormat("fr-FR").format(defaultVal) +
                                        unite,
                                },
                                [maxv]:
                                    Intl.NumberFormat("fr-FR").format(
                                        Number(action.defaultValue.max).toFixed(0)
                                    ) + unite,
                            }}
                            onAfterChange={handleAfterChangeSlider}
                            onChange={(e) => handleChangeSlider(e, action.number)}
                        />
                    </div>
                )}
                {enabled &&
                    action.defaultValue.type === "complex" &&
                    Object.keys(action.defaultValue.default).map((subActionId) => {
                        return this.addSubAction(
                            categoryId,
                            leverId,
                            actionId,
                            action,
                            subActionId,
                            unite
                        );
                    })}
            </li>
        );
    }

    addSubAction(categoryId, leverId, actionId, action, subActionIndex, unite) {
        let subAction = action.defaultValue.default[subActionIndex];
        let subActionId = subAction.id;
        let regionName = this.props.parentApi.data.region;
        let idInput =
            "category_" +
            categoryId +
            "_lever_" +
            leverId +
            "_action_" +
            actionId +
            "_subaction_" +
            subActionId;

        let maxv = subAction.max;
        let defaultVal =
            subAction.value !== undefined
                ? Number(subAction.value.toFixed(0))
                : Number(subAction.default.toFixed(0));
        const maxValue = 100;
        const handleChangeSlider = (e, subActionId) => {
            let configuration = this.state.configuration;
            let actions_params = this.state.actions_params; // simple dict {action : value} used for calcul
            let total = 0;
            if (action.defaultValue.summable) {
                for (let i = 1; i <= 4; i++) {
                    if (
                        subActionId !== actions_params[action.number][i].id &&
                        actions_params[action.number][i].type === "primary"
                    ) {
                        total +=
                            actions_params[action.number][i].value !== undefined
                                ? actions_params[action.number][i].value
                                : actions_params[action.number][i].default;
                    }
                }
            }

            Object.values(action.defaultValue.default).forEach(function (subAction) {
                if (subActionId === subAction.id) {
                    if (
                        e + total <= maxValue &&
                        actions_params[action.number][subActionIndex].type ===
                            "primary" &&
                        action.defaultValue.summable
                    ) {
                        subAction.value = e;
                        actions_params[action.number][subActionIndex].value = e;
                        configuration.data[categoryId].levers[leverId].actions[
                            actionId
                        ].totalMotorization = e + total;
                    } else if (
                        actions_params[action.number][subActionIndex].type ===
                            "secondary" ||
                        !action.defaultValue.summable
                    ) {
                        subAction.value = e;
                        actions_params[action.number][subActionIndex].value = e;
                    } else {
                        return;
                    }
                }
            });

            this.setState({
                configuration: configuration,
                actions_params: actions_params,
            });
        };
        const handleAfterChangeSlider = () => {
            this.handleParamsChange();
        };
        return (
            <div>
                {subAction.type === "secondary" && <hr />}
                <li key={idInput} className="li-complex">
                    <label>{subAction.title}</label>
                    &nbsp; &nbsp;
                    <Slider
                        className={"slider-simulateur " + regionName}
                        defaultValue={defaultVal}
                        min={subAction.min}
                        max={subAction.max}
                        step={1}
                        value={defaultVal}
                        marks={{
                            0: 0,
                            [defaultVal]: {
                                style: {
                                    color: "black",
                                },
                                label:
                                    Intl.NumberFormat("fr-FR").format(defaultVal) +
                                    unite,
                            },
                            [maxv]:
                                Intl.NumberFormat("fr-FR").format(subAction.max) +
                                unite,
                        }}
                        onAfterChange={handleAfterChangeSlider}
                        onChange={(e) => handleChangeSlider(e, subAction.id)}
                    />
                </li>
            </div>
        );
    }

    addImpact() {
        const handleImpactChange = (e) => {
            let configuration = this.state.configuration;

            // we enable the checked impact
            Object.values(configuration.impacts).forEach(function (impact) {
                if (e.target.name === impact.impactType) {
                    impact.enabled = e.target.checked;
                }
            });
            this.setState({
                configuration: configuration,
            });
        };

        return (
            <div className="checkbox-container-category-simulator">
                <div>
                    {this.state.configuration.impacts &&
                        Object.values(this.state.configuration.impacts).map(
                            (impact) => {
                                let type = impact.impactType;
                                let color = impact.color;
                                let unit = impact.unit;
                                let title = impact.impactTitle;
                                let enabled = impact.enabled;
                                let typeDisplay = impact.typeDisplay;
                                let name = unit
                                    ? title + " (en " + unit + ") :"
                                    : title;
                                let defaultVal;
                                let maxValue;
                                let gain = Number(impact.gain).toFixed(1);
                                if (typeDisplay === "simple") {
                                    maxValue = Number(impact.defaultValue.toFixed(0));
                                    defaultVal =
                                        impact.value !== undefined
                                            ? Number(impact.value.toFixed(0))
                                            : Number(impact.defaultValue.toFixed(0));
                                }

                                let maxValueMarks =
                                    Intl.NumberFormat("fr-FR").format(maxValue);
                                let defaultCurrentValue = {
                                    style: {
                                        color: "black",
                                        marginTop: "28px",
                                    },
                                    label: new Intl.NumberFormat("fr-FR").format(
                                        defaultVal
                                    ),
                                };
                                let handleBorder = "";
                                if (defaultVal === maxValue) {
                                    handleBorder = " handle-border";
                                }
                                if (defaultVal > maxValue) {
                                    handleBorder = " over-handle-border";
                                }
                                let classSign = "";
                                if (Math.sign(gain) === 1) {
                                    gain = addPlusSign(gain);
                                    classSign = "positive";
                                }

                                return (
                                    <div key={type} className="simulator-impact">
                                        <div>
                                            <input
                                                style={{ color: color }}
                                                type="checkbox"
                                                id={type}
                                                name={type}
                                                defaultChecked={enabled}
                                                onClick={handleImpactChange}
                                            ></input>
                                            <label
                                                style={{ color: color }}
                                                htmlFor={type}
                                            >
                                                {name}
                                            </label>
                                            {enabled && typeDisplay === "simple" && (
                                                <OverflowSlider
                                                    type={type}
                                                    handleBorder={handleBorder}
                                                    defaultVal={defaultVal}
                                                    maxValue={maxValue}
                                                    defaultCurrentValue={
                                                        defaultCurrentValue
                                                    }
                                                    maxValueMarks={maxValueMarks}
                                                />
                                            )}

                                            {enabled &&
                                                typeDisplay === "complex" &&
                                                Object.keys(impact.defaultValue).map(
                                                    (subImpactIndex) => {
                                                        return this.addSubImpact(
                                                            impact,
                                                            subImpactIndex
                                                        );
                                                    }
                                                )}
                                        </div>
                                        <div className="impact-reduction-value">
                                            {typeDisplay === "simple" &&
                                            enabled &&
                                            gain &&
                                            (gain < -1e-3 || gain > 1e-3) ? (
                                                <i className={classSign}>{gain}% </i>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                        <div></div>
                                    </div>
                                );
                            }
                        )}
                </div>
            </div>
        );
    }

    addSubImpact(impact, subImpactIndex) {
        let subImpact = impact.defaultValue[subImpactIndex];
        let subImpactId = subImpact.id;
        let impactType = impact.impactType;
        let defaultVal =
            subImpact.value !== undefined
                ? Number(subImpact.value.toFixed(0))
                : Number(subImpact.default.toFixed(0));
        let maxValue = Number(subImpact.default.toFixed(0));
        let maxValueMarks = Intl.NumberFormat("fr-FR").format(maxValue);
        let defaultCurrentValue = {
            style: {
                color: "black",
                marginTop: "28px",
            },
            label: Intl.NumberFormat("fr-FR").format(defaultVal),
        };
        let separator = true;
        let handleBorder = "";
        if (defaultVal === maxValue) {
            handleBorder = " handle-border";
        }
        if (defaultVal > maxValue) {
            handleBorder = " over-handle-border";
        }
        if (
            Number(subImpactIndex) === Number(Object.keys(impact.defaultValue).length)
        ) {
            separator = false;
        }
        let classSign = "";
        let gain = Number(subImpact.gain).toFixed(1);
        if (Math.sign(gain) === 1) {
            classSign = "positive";
            gain = addPlusSign(gain);
        }
        return (
            <div key={impactType + "_" + subImpactId}>
                <div className="li-complex">
                    <label>{subImpact.title}</label>
                    &nbsp; &nbsp;
                    <OverflowSlider
                        type={impactType}
                        handleBorder={handleBorder}
                        defaultVal={defaultVal}
                        maxValue={maxValue}
                        defaultCurrentValue={defaultCurrentValue}
                        maxValueMarks={maxValueMarks}
                    />
                    <div className="impact-reduction-value">
                        {gain && (gain < -1e-3 || gain > 1e-3) ? (
                            <i className={classSign}>{gain}% </i>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
                {separator && <hr />}
            </div>
        );
    }

    handleParamsChange() {
        const genericInputParam = this.state.generic_input_param;
        const specificInputParam = this.state.specific_input_param;
        const genericIntermediateParams = this.state.genericIntermediateParams;
        const intermediateCompute = this.state.intermediate_compute;
        let configuration = this.state.configuration;
        let actions_params = this.state.actions_params;
        let activated_impacts = this.state.activated_impacts;
        let pollutants;
        let gain_ener, gain_ghg, energy_bill;

        if (activated_impacts.includes("impact-ener")) {
            gain_ener = get_consumption(
                specificInputParam,
                genericInputParam,
                genericIntermediateParams,
                intermediateCompute["consumption"],
                actions_params,
                configuration["level"]
            );
        }
        if (activated_impacts.includes("impact-carbone")) {
            gain_ghg = get_ghg(
                specificInputParam,
                genericInputParam,
                genericIntermediateParams,
                intermediateCompute["ghg"],
                actions_params,
                configuration["level"]
            );
        }
        if (activated_impacts.includes("impact-facture-ener")) {
            energy_bill = get_energy_bill(
                specificInputParam,
                genericInputParam,
                genericIntermediateParams,
                intermediateCompute["energy_bill"],
                actions_params,
                configuration["level"]
            );
        }

        if (configuration["level"] > 0 && activated_impacts.includes("impact-atmo")) {
            pollutants = {
                covnm: {
                    fe_name: "fe_covnm",
                },
                pm10: {
                    fe_name: "fe_pm10",
                },
                pm25: {
                    fe_name: "fe_pm25",
                },
                nox: {
                    fe_name: "fe_nox",
                },
            };

            for (let item in pollutants) {
                let gain_value = get_pollutant(
                    specificInputParam,
                    genericInputParam,
                    genericIntermediateParams,
                    intermediateCompute[item],
                    actions_params,
                    configuration["level"],
                    pollutants[item].fe_name
                );
                pollutants[item].value = gain_value;
            }
        }

        // update impacts
        Object.values(configuration.impacts).forEach(function (impact) {
            if (activated_impacts.includes(impact.impactType)) {
                switch (impact.impactType) {
                    case "impact-ener":
                        impact.gain =
                            (-gain_ener /
                                intermediateCompute["consumption"]["before"][
                                    "total_conso_transport"
                                ]) *
                            100;
                        impact.value =
                            intermediateCompute["consumption"]["before"][
                                "total_conso_transport"
                            ] - gain_ener;
                        break;
                    case "impact-carbone":
                        impact.gain =
                            (-gain_ghg /
                                intermediateCompute["ghg"]["before"][
                                    "total_ghg_transport"
                                ]) *
                            100;
                        impact.value =
                            intermediateCompute["ghg"]["before"][
                                "total_ghg_transport"
                            ] - gain_ghg;
                        break;
                    case "impact-facture-ener":
                        impact.gain =
                            (-energy_bill /
                                intermediateCompute["energy_bill"]["before"][
                                    "total_energy_bill_transport"
                                ]) *
                            100;
                        impact.value =
                            intermediateCompute["energy_bill"]["before"][
                                "total_energy_bill_transport"
                            ] - energy_bill;
                        break;
                    case "impact-atmo":
                        if (configuration["level"] > 0) {
                            Object.values(impact.defaultValue).forEach(function (
                                subimpact
                            ) {
                                subimpact.gain =
                                    (-pollutants[subimpact.id].value /
                                        intermediateCompute[subimpact.id]["before"][
                                            "total_emission_transport"
                                        ]) *
                                    100;
                                subimpact.value =
                                    intermediateCompute[subimpact.id]["before"][
                                        "total_emission_transport"
                                    ] - pollutants[subimpact.id].value;
                            });
                        }
                        break;
                    default:
                        impact.value = impact.defaultValue;
                }
            }
        });
        this.setState({
            configuration: configuration,
        });
    }

    getTerritoryName() {
        if (this.state.is_national)
            return (
                this.props.parentApi.data.region.charAt(0).toUpperCase() +
                this.props.parentApi.data.region.slice(1)
            );

        let territoryName = this.props.parentApi.controller.zonesManager.getZoneName(
            this.props.parentApi.data.currentZone,
            this.props.parentApi.data.zone
        );
        if (
            !this.props.parentApi.controller.zonesManager.getZoneName(
                this.props.parentApi.data.currentZone,
                this.props.parentApi.data.zone
            )
        ) {
            territoryName = this.props.parentApi.data.territoryName;
        }
        if (this.props.parentApi.data.zone.zone === "region") {
            territoryName = this.props.parentApi.data.settings.label;
        }

        return territoryName;
    }

    getDashboardURL() {
        if (this.state.is_national) return "";
        let territoryType = "?zone=" + this.props.parentApi.data.zone.zone;
        let maille = "&maille=" + this.props.parentApi.data.zone.maille;
        let codeInseeTerritory = "&zone_id=" + this.props.parentApi.data.currentZone;
        let idDashboard = "&id_tableau=" + this.props.currentDashboard;
        let territoryName = "&nom_territoire=" + this.getTerritoryName();
        if (this.props.parentApi.data.zone.zone === "region") {
            codeInseeTerritory = "&zone_id=" + this.props.parentApi.data.regionCode;
        }

        let url =
            territoryType + maille + codeInseeTerritory + idDashboard + territoryName;
        return url;
    }

    render() {
        const commentLevel0 =
            "La distinction entre le transport de personnes et le transport de marchandise n'étant actuellement pas disponible sur le territoire sélectionné, les calculs d'impact se limitent aux trajets domicile-travail. Les trajets personnels hors travail ainsi que le transport de marchandise ne sont donc pour l'instant pas simulés.";
        // Methodo
        let pdfUrl = createPdfMethodoLink(
            config.methodo_url,
            this.props.parentApi.data.region,
            configData.methodoSimulateurPdf
        );
        let methodoPdf = (
            <a
                href={pdfUrl}
                target="_blank"
                title="Note méthodologique"
                rel="noreferrer"
            >
                <div className="help"></div>
            </a>
        );
        let returnButton = (
            <Link to="/">
                <button
                    type="button"
                    className="close close-big"
                    data-dismiss="alert"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </Link>
        );
        let linkTerritorialStrategy = "/strategies_territoriales";
        if (this.state.is_national) {
            linkTerritorialStrategy =
                "/national/" +
                this.props.parentApi.data.region +
                "/strategies_territoriales";
            returnButton = (
                <div>
                    <Link
                        to={
                            "/national/" + this.props.parentApi.data.region + "/portail"
                        }
                        className="btn btn-light return-button"
                    >
                        <i className="bi bi-arrow-left"></i>
                    </Link>
                </div>
            );
        }
        if (this.state.status) {
            return (
                <div className="widgets full-screen-widget">
                    {returnButton}
                    <div className="tout">
                        <div className="support">
                            <div className="corps">
                                <p>
                                    Désolé, l'application rencontre actuellement un
                                    problème :
                                    <br />
                                    <code>{this.state.errorMsg}</code>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        if (!this.state.configuration.data) {
            return (
                <div className="widgets full-screen-widget">
                    {this.props.parentApi.data.settings && (
                        <SEO
                            settings={this.props.parentApi.data.settings["seo"]}
                            page="simulator"
                        />
                    )}
                    <div className={"page-content"}>
                        <div className="loader centered-widget"></div>
                    </div>
                </div>
            );
        }
        return (
            <div className="widgets full-screen-widget">
                {this.props.parentApi.data.settings && (
                    <SEO
                        settings={this.props.parentApi.data.settings["seo"]}
                        page="simulator"
                    />
                )}
                {returnButton}
                {methodoPdf}
                <div className="structure-fiche-didactique">
                    <h2> Simulateur d’impacts mobilité </h2>
                    <h3>[Territoire : {this.getTerritoryName()}]</h3>
                    &nbsp;
                    <div className="block-category-restitution">
                        <p>
                            <em>Objectif :</em> donner les ordres de grandeur d’impacts
                            associés aux principaux leviers de la mobilité
                        </p>
                        {this.state.configuration["level"] === 0 && (
                            <em className="comment-impact">
                                Le simulateur mobilité est actuellement restreint à
                                l'évaluation de l'impact des trajets domicile-travail
                                <i
                                    title={commentLevel0}
                                    className="bi bi-question-circle-fill"
                                    style={{ fontSize: "0.95rem", marginLeft: "6px" }}
                                ></i>
                            </em>
                        )}
                        <br /> <br /> <br />
                        <div className="row-simulateur">
                            <div className="column-simulateur">
                                {this.state.configuration && this.addCategory()}
                            </div>
                            <div className="column-simulateur right-column-simulateur">
                                {this.addImpact()}
                                <div className="lien-simulateur">
                                    <br />
                                    <hr />
                                    <div style={{ marginRight: "20px" }}>
                                        <Link
                                            className="btn btn-success"
                                            to={linkTerritorialStrategy}
                                        >
                                            Prêt pour construire votre plan d'actions «
                                            mobilité » ?
                                        </Link>
                                    </div>
                                    {[
                                        "didactic-file",
                                        "tableau_de_bord_restitue",
                                    ].includes(
                                        this.props.parentApi.data.provenance
                                    ) && (
                                        <div>
                                            <Link
                                                className="btn btn-warning"
                                                to={
                                                    "/restitution_tableaux_bord" +
                                                    this.getDashboardURL()
                                                }
                                            >
                                                {" "}
                                                Retour au tableau de bord Mobilité{" "}
                                            </Link>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Simulator;
