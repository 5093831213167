/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import config from "../settings.js";
import configData from "../settings_data.js";
import { buildRegionUrl } from "../utils.js";
import Api from "./Api";

/**
 * This component retrieves data relating to the DB and transforms it into functions that can be called in other components.
 * This module therefore makes the link to the API via the Component:ref:`Api`
 * For exemple :
 * `this.props.parentApi.controller.analysisManager.getLegendTitles(id_indicateur)`
 * is a function called in **Legend.js** to get legend titles.
 */
class Analysis {
    constructor(callback, region, regionCode, failCallback = () => {}) {
        this.region = region;
        this.regionCode = regionCode;
        this.analysis = undefined;
        this.pcaetTrajectories = undefined;
        this.dataUnits = [];
        this.dataUnitsByIds = {};
        this.fetchConfiguration(callback, failCallback);
        // Unique identifier to detect changes in components when refreshing the analysis list
        this.id = Date.now();
    }

    fetchConfiguration(callback, failCallback) {
        // List all analysis available
        let url = buildRegionUrl(config.api_analysis_meta_url, this.region);
        Api.callApi(url, null, "GET")
            .then((response) => {
                // Save the metadata
                this.analysis = response;
                for (let a of this.analysis) {
                    a.saveData = undefined;
                    // Rebuild the dataset for the charts
                    if (a.charts[0] != null) {
                        for (let c of a.charts) {
                            c.filter_table = c.categorie; // Save the filter in specific var
                            c.datasets = [];
                            c.datasets.push({});
                        }
                    }
                }

                // Tell the main app that the configuration has been loaded
                callback();
            })
            .catch((e) => {
                failCallback();
            });

        Api.callApi(
            buildRegionUrl(config.api_data_units_url, this.region),
            null,
            "GET"
        ).then((response) => {
            this.dataUnits = response;
            response.forEach((unit) => {
                this.dataUnitsByIds[unit.unit_id] = unit;
            });
        });
    }

    /**
     * This function is used to retrieve data for a particular chart (data are already fetch by the map)
     * @param  {string} id : indicator identifier
     * @param  {string} idChart : chart identifier (nom_indicateur + categorie)
     * @return {json} : the data for the chart and the metadata for the analysis
     */
    getDataChart(id, idChart, selectedUnit) {
        let chartData = {};

        // Get current analysis
        let currentAnalysis = undefined;
        for (let a of this.analysis) {
            if (a.id === id) {
                currentAnalysis = a;
                break;
            }
        }
        // Get chart's metadata of the current analysis
        for (let c of currentAnalysis.charts) {
            // The unique identifier of a pie chart is determined by the name of the indicator + category (formerly we had an ID)
            let unique_id = id + c.categorie;
            if (unique_id === idChart) {
                let data = undefined;
                let labels = undefined;
                let colors = undefined;
                let name = undefined;
                let sub_indicator = undefined;
                let modality_id = undefined;
                // Get data from saveData, and affect them to the dataset
                if (currentAnalysis.saveData) {
                    if (currentAnalysis.saveData.charts) {
                        for (let sdc of currentAnalysis.saveData.charts) {
                            let saved_unique_id = sdc.indicateur + sdc.name;
                            if (saved_unique_id === unique_id) {
                                // Update labels
                                if (sdc.labels) {
                                    labels = sdc.labels;
                                }
                                data = sdc.data;
                                // Insert the data inside the meta, and return meta
                                if (sdc.colors) {
                                    colors = sdc.colors;
                                }
                                if (sdc.modalite_id) {
                                    modality_id = sdc.modalite_id;
                                }
                                if (currentAnalysis.sub_indicator) {
                                    sub_indicator = JSON.parse(
                                        currentAnalysis.sub_indicator
                                    );
                                }
                                name = sdc.name;
                                break;
                            }
                        }
                        c.name = name;
                        c.labels = labels;
                        c.datasets[0].data = data;
                        c.confid = currentAnalysis.saveData.confid;
                        c.filtre_initial = currentAnalysis.saveData.filtre_initial;
                        c.sub_indicator = sub_indicator;
                        c.modality_id = modality_id;
                        //c.category_indicator = category_indicator;
                        this.filtreInitial = currentAnalysis.saveData.filtre_initial;
                        // Check colors given in data. For each colors given, remove it from the fixed color palette
                        if (colors) {
                            let fixedPalette = configData.colors.slice();
                            for (let col of colors) {
                                if (col) {
                                    let index = fixedPalette.indexOf(col.toLowerCase());
                                    fixedPalette.splice(index, 1);
                                }
                            }

                            // Now assign colors for data that don't have one
                            let idx = 0;
                            let finalColors = [];
                            for (let col of colors) {
                                if (!col) {
                                    col = fixedPalette[idx];
                                    idx += 1;
                                }
                                finalColors.push(col);
                            }

                            c.datasets[0].backgroundColor = finalColors.slice();
                            c.datasets[0].origBackgroundColor = finalColors.slice();
                            c.datasets[0].hoverBackgroundColor = finalColors.slice();
                        } else if (c.categorie === "climat" && !colors) {
                            void 0; // To avoid entering the "else", and continuing to the end
                        } else {
                            c.messageNePasAfficherDonneesMailles =
                                "Cet indicateur n'est pas disponible à la maille sélectionnée.";
                        }
                        chartData = c;
                        break;
                    }
                }
            }
        }

        chartData.unit = selectedUnit
            ? this.dataUnitsByIds[selectedUnit].unit_name
            : currentAnalysis.unit;
        return { data: chartData, meta: currentAnalysis.saveMetaMap };
    }

    /**
     * Get data (data are already fetch)
     */
    getDataMap(id) {
        let mapData = undefined;
        let mapMeta = undefined;
        let mapConfid = undefined;
        let stations_mesures = undefined;
        let charts = undefined; // for climate data
        let representations_possibles = undefined;
        let mapDataDeuxiemeRepresentation = undefined;
        let minMaxValeursFiltrees = undefined;
        let distinctValues = undefined; // for categorical data
        let bornesFiltre = undefined;
        let afficherDonneesMailles = undefined;
        let existentTerritoiresConfidentiels = false;
        let existentTerritoiresIndisponibles = false;
        let typeConfidCamembert;
        for (let a of this.analysis) {
            if (a.id === id) {
                if (a.saveData) {
                    afficherDonneesMailles = a.saveData.afficher_indicateur_maille;
                    if (!afficherDonneesMailles) {
                        mapData = a.saveData.map;
                        mapDataDeuxiemeRepresentation =
                            a.saveData.donnees_deuxieme_representation;
                        bornesFiltre = a.saveData.bornes_filtres;
                        minMaxValeursFiltrees = a.saveData.min_max_valeurs_filtrees;
                        distinctValues = a.saveData.distinct_values;
                        mapConfid = a.saveData.confid;
                        mapMeta = a.saveMetaMap;
                        stations_mesures = a.saveData.stations_mesures;
                        charts = a.saveData.charts;
                        if (a.saveData.confid)
                            typeConfidCamembert = a.saveData.confid.charts;
                        for (let donnee of mapData) {
                            if (donnee.val === null) {
                                existentTerritoiresIndisponibles = true;
                            }
                            if (donnee.confidentiel) {
                                existentTerritoiresConfidentiels = true;
                            }
                        }
                        representations_possibles =
                            a.saveData.representations_possibles;
                    } else {
                        mapData = [];
                        mapMeta = {};
                    }
                }
                break;
            }
        }
        if (mapMeta !== undefined) {
            mapMeta.confidentiel = existentTerritoiresConfidentiels;
            mapMeta.indisponible = existentTerritoiresIndisponibles;
            mapMeta.typeConfidCamembert = typeConfidCamembert;
        }

        return {
            data: mapData,
            mapDataDeuxiemeRepresentation: mapDataDeuxiemeRepresentation,
            confid: mapConfid,
            meta: mapMeta,
            representations_possibles: representations_possibles,
            bornesFiltre: bornesFiltre,
            minMaxValeursFiltrees: minMaxValeursFiltrees,
            distinctValues: distinctValues,
            stations_mesures: stations_mesures,
            charts: charts,
        };
    }

    /**
     * Call the API to retrieve data for a specific analysis, a zone, a year and filters.
     *
     * @param {integer} id the analysis Id
     * @param {object} zone contains two values associated to zone and maille keys
     * @param {string} zoneId current zone ID (e.g., "84", "06018", "FR8000019")
     * @param {callable} callback function called after retrieving if data or if data already present
     * @param {boolean} reload forces reloading even if data already present
     * @param {object} filters contains categories filters grouped by category name.
     * @param {string} provenance where the data retrieval is triggered from
     * @param {integer} idUtilisateur user id from SuiviConsultations class
     * @param {string} filtre when values can be filtered (e.g., distance) will contain one or two values (separated by comma)
     * @param {boolean} reinitialiserFiltres decide whether to reset filters or not in backend call
     * @param {integer} selectedYear the year selected for values
     */
    fetchData(
        id,
        zone,
        zoneId,
        callback,
        reload,
        filters,
        provenance,
        idUtilisateur,
        filtre,
        reinitialiserFiltres,
        selectedYear = false,
        selectedUnit = false
    ) {
        if (filtre === undefined && id) {
            filtre = this.getFilterDefaultValue(parseInt(id, 10));
        }
        if (!filters && id) {
            filters = this.initFiltersByCategory(parseInt(id, 10));
        }
        if (id === "" || id === undefined || zone === "") {
            callback();
            return;
        }
        // hack for the region, we must pass it a unique identifier
        if (zone.zone === "region") {
            zoneId = this.regionCode; // to be replaced by the region code
        }

        let currentAnalysis = undefined;

        for (let a of this.analysis) {
            if (a.id === parseInt(id, 10)) {
                currentAnalysis = a;
                break;
            }
        }

        if (currentAnalysis && (!currentAnalysis.saveData || reload)) {
            let meta = {};
            let dataSource = "";

            let pZone = "?zone=" + zone.zone;
            let pMaille = "&maille=" + zone.maille;
            let pZoneId = "&zone_id=" + zoneId;
            let annee = "";
            if (!selectedYear) {
                if (currentAnalysis.years) {
                    annee = "&annee=" + currentAnalysis.years[0];
                }
            } else {
                annee = "&annee=" + selectedYear;
            }
            let argUnit = "";
            if (selectedUnit) {
                argUnit = "&unit=" + selectedUnit;
            }
            let urlType = "/data";

            let url = buildRegionUrl(config.api_analysis_url, this.region);

            let urlProvenance = "&provenance=" + provenance;
            let urlIdUtilisateur = "&id_utilisateur=" + idUtilisateur;
            let filtreDeuxiemeRepresentation = "";
            if (filtre) {
                filtreDeuxiemeRepresentation = "&filtre=" + filtre;
            }
            let urlReinitialiserFiltres = "";

            if (reinitialiserFiltres) {
                urlReinitialiserFiltres = "&reinitialiserFiltres=True";
            }

            dataSource =
                url +
                id +
                urlType +
                pZone +
                pMaille +
                pZoneId +
                annee +
                argUnit +
                urlProvenance +
                urlIdUtilisateur +
                filtreDeuxiemeRepresentation +
                urlReinitialiserFiltres;

            meta.color_start = currentAnalysis.color_start;
            meta.color_end = currentAnalysis.color_end;

            if (!meta.color_start) {
                meta.color_start = configData.defaultColor;
            }
            if (!meta.color_end) {
                meta.color_end = configData.defaultColor;
            }

            meta.type = currentAnalysis.type;
            meta.data_name = currentAnalysis.nom;
            meta.isratio = currentAnalysis.isratio;
            meta.display_total = currentAnalysis.display_total;
            meta.nb_classes_color_representation =
                currentAnalysis.nb_classes_color_representation;
            meta.donnees_exportables = currentAnalysis.donnees_exportables;
            meta.representationDetails = currentAnalysis.representation_details;
            // contains relevant units with default
            meta.unitsDefaultDetails = currentAnalysis.units_default_details;
            meta.unitsHiddenDetails = currentAnalysis.units_hidden_details;
            meta.data_type = currentAnalysis.data_type;
            meta.data = currentAnalysis.data;
            meta.creditsAnalysisProducers = JSON.parse(
                currentAnalysis.credits_analysis_producers
            );
            meta.creditsDataSources = JSON.parse(currentAnalysis.credits_data_sources);
            meta.creditsDataProducers = JSON.parse(
                currentAnalysis.credits_data_producers
            );
            meta.titre_dans_infobulle = currentAnalysis.titre_dans_infobulle;
            meta.titreGraphiqueIndicateursClimat =
                currentAnalysis.titre_graphiques_indicateurs;
            meta.carto_category = currentAnalysis.carto_category;

            // if a specific unit has been selected, we use it
            if (selectedUnit) {
                meta.unit = this.dataUnitsByIds[selectedUnit].unit_name;
            }
            // otherwise, we retrieve unit currently used by indicator => it can vary on the maille
            else {
                meta.unit = currentAnalysis.unit;
                // we update it eventually if anything different has been specified (custom level)
                const { unit } = this.getUnitParamsForIndicator(
                    selectedUnit,
                    meta,
                    zone.maille
                );
                meta.unit = unit;
            }

            if (this.promise) this.promise.abort();
            let body = JSON.stringify(filters);
            this.promise = Api.callApi(dataSource, body, "POST");
            this.promise
                .then((json) => {
                    currentAnalysis = this.analysis.find((a) => a.id === parseInt(id));
                    if (Object.keys(json).length === 0) {
                        currentAnalysis.saveData = false;
                        callback();
                    } else {
                        // Save the data
                        currentAnalysis.saveData = json;
                        this.filtreInitial = json.filtre_initial;
                        if (json.min_max_valeurs) {
                            meta.min = json.min_max_valeurs.min;
                            meta.max = json.min_max_valeurs.max;
                        }
                        if (json.bornes_filtre) {
                            meta.bornesFiltre = json.bornes_filtre;
                        }
                        if (json.donnees_deuxieme_representation) {
                            meta.dataDeuxiemeRepresentation =
                                json.donnees_deuxieme_representation;
                        }
                        if (json.annee) {
                            meta.annee = json.annee;
                        }
                        if (json.ratio_best_year) {
                            meta.ratio_best_year = json.ratio_best_year;
                        }
                        if (json.intervalle_temps) {
                            meta.intervalle_temps = json.intervalle_temps;
                            meta.intervalles_temps_recent_ancien =
                                json.intervalles_temps_recent_ancien;
                        }
                        if (json.titre_dans_infobulle) {
                            meta.titre_dans_infobulle = json.titre_dans_infobulle;
                        }
                        if (json.total) {
                            meta.sum = json.total.val;
                            meta.divider = json.total.divider;
                        }
                        meta.nbValeurs = json.nb_valeurs;
                        meta.filtre_initial_modalites_par_categorie =
                            json.filtre_initial_modalites_par_categorie;
                        meta.afficherVersionSimple =
                            json.afficher_calcul_et_donnees_table;
                        meta.moyennePonderee = json.moyenne_ponderee;
                        currentAnalysis.saveMetaMap = meta;

                        callback(currentAnalysis);
                    }
                })
                .catch((error) => {
                    if (error.name === "AbortError") return;
                    currentAnalysis.saveData = false;
                    callback();
                });
        } else {
            callback();
        }
    }

    /*
     * Return analysis's name
     */
    getAnalysisName(id) {
        if (this.analysis) {
            for (let a of this.analysis) {
                if (a.id === id) {
                    return a.nom;
                }
            }
        }
        return undefined;
    }

    /*
     * Return analysis's parent
     */
    getSubIndicatorMetaData(id) {
        if (this.analysis) {
            for (const analysis of this.analysis) {
                if (analysis.sub_indicator) {
                    const subIndicators = JSON.parse(analysis.sub_indicator);
                    const matchingSubIndicator = subIndicators.find(
                        (item) => item.indicator === id
                    );

                    if (matchingSubIndicator) {
                        const { parent, modality_id, category, modality } =
                            matchingSubIndicator;

                        return {
                            subIndicatorParent: {
                                value: parent,
                                label: this.getAnalysisName(parent),
                            },
                            subIndicatorCategory: category
                                ? {
                                      value: modality_id,
                                      label: `${category}.${modality}`,
                                  }
                                : null,
                        };
                    }
                }
            }
        }

        return undefined;
    }

    obtenirIdentifiantAnalyse(jeuDeDonnees, dataRatio, filter) {
        if (this.analysis) {
            for (let a of this.analysis) {
                if (dataRatio) {
                    if (a.data === jeuDeDonnees && a.data_ratio) {
                        return a.id;
                    }
                } else if (filter) {
                    if (a.data === jeuDeDonnees && a.filter) {
                        return a.id;
                    }
                } else {
                    if (
                        a.data === jeuDeDonnees &&
                        (a.data_ratio === null || a.data_ratio === "") &&
                        (a.filter === null || a.filter === "")
                    ) {
                        return a.id;
                    }
                }
            }
        }
        return undefined;
    }

    /**
     * Returns the list of tables used to calculate an indicator
     */
    getListeTables() {
        let listTable = [];
        if (this.analysis) {
            for (let a of this.analysis) {
                // retrieve the name of the tale by removing operations if necessary
                let nomTable = a.data.split("*")[0].split("/")[0];
                if (!listTable.find((item) => item.nom === nomTable)) {
                    listTable.push({ id: a.id, nom: nomTable });
                }
            }
        }
        listTable = listTable.sort(function (item1, item2) {
            if (item1.nom < item2.nom) {
                return -1;
            }
            return 1;
        });
        return listTable;
    }

    /**
     * Returns the list of possible representations for an indicator
     * from the data transmitted by the API
     * @param {entier} id : Unique identifier of the indicator
     */
    obtenirRepresentationsPossibles(id) {
        if (this.analysis) {
            for (let a of this.analysis) {
                if (a.id === id && a.representations_possibles) {
                    return a.representations_possibles;
                }
            }
        }
        return [];
    }

    /**
     * Returns the list of categories for an indicator
     * @param {entier} id :  Unique identifier of the indicator for which we want to know the available charts
     */
    getCategories(id) {
        if (this.analysis) {
            for (let a of this.analysis) {
                if (a.id === id) {
                    return a.charts;
                }
            }
        }
    }

    /**
     * Returns a key-value object constructed as follows:
     * {
     *   identifiant indicateur + categorie1 :
     *   [{"filtre_categorie": modalité1}, ..., {"filtre_categorie": modalitén}]}
     *   identifiant indicateur + categorie2 :
     *   [{"filtre_categorie": modalité1}, ..., {"filtre_categorie": modalitén}]}
     * }
     * At initialization, it gathers all the categories and modalities formatted
     * according to the API requirements to define the filters in the SQL queries
     * @param {entier} id : Unique identifier of the indicator
     */
    initFiltersByCategory(id) {
        if (this.analysis) {
            for (let a of this.analysis) {
                if (a.id === id) {
                    let filters = a.filtre_initial_modalites_par_categorie;
                    filters = Object.fromEntries(
                        Object.entries(filters).filter(
                            ([key, _]) => !key.startsWith("____all_")
                        )
                    );
                    return JSON.parse(JSON.stringify(filters));
                }
            }
        }
        return undefined;
    }

    /**
     * Returns a list of filters based on an indicator's ID.
     */
    getFullCategoriesValues(id, category) {
        if (this.analysis) {
            for (let a of this.analysis) {
                if (a.id === id) {
                    return JSON.parse(
                        JSON.stringify(a.filtre_initial_modalites_par_categorie)
                    );
                }
            }
        }
        return undefined;
    }

    /**
     * Returns the default filter value for indicators for which certain values can be filtered
     * (ex : migrations pendulaires, Distance domicile - travail).
     * @param {entier} id : Unique identifier of the indicator
     */
    getFilterDefaultValue(id) {
        id = parseInt(id, 10);
        if (this.analysis) {
            for (let a of this.analysis) {
                if (a.id === id) {
                    return parseInt(a.valeur_filtre_defaut, 10);
                }
            }
        }
        return undefined;
    }

    /**
     * Returns legend titles (two legends if the indicator allows the combination of two representations)
     * @param {entier} id : Unique identifier of the indicator
     */
    getLegendTitles(id) {
        if (this.analysis) {
            for (let a of this.analysis) {
                if (a.id === id) {
                    return {
                        titre_legende: a.titre_legende,
                        titre_legende_deuxieme_representation:
                            a.titre_legende_deuxieme_representation,
                    };
                }
            }
        }
        return undefined;
    }

    /**
     * Returns the value of the title column (only works if the indicator allows the combination of two representations)
     * @param {entier} id : Unique identifier of the indicator
     */
    getTitle(id) {
        if (this.analysis) {
            for (let a of this.analysis) {
                if (a.id === id) {
                    return {
                        titre: a.titre,
                    };
                }
            }
        }
        return {
            titre: "Indicateur non disponible",
        };
    }

    /**
     * Returns the value of the afficher_proportion column
     * @param {entier} id : Unique identifier of the indicator
     */
    getProportionForCircleDisplay(id) {
        if (this.analysis) {
            for (let a of this.analysis) {
                if (a.id === id) {
                    return {
                        afficherProportion: a.afficher_proportion,
                    };
                }
            }
        }
        return {};
    }

    /**
     * Returns the value of the only_for_zone column which indicates if the indicator
     * is only available on some territories and if so which ones
     * @param {entier} id : Unique identifier of the indicator
     */
    getOnlyForZones(id) {
        if (this.analysis) {
            for (let a of this.analysis) {
                if (a.id === id) {
                    return a.only_for_zone;
                }
            }
        }
        return undefined;
    }

    /**
     * Returns the value of the disabled_for_macro_level column which indicates
     * if the indicator is disabled at certain macro levels.
     * @param {entier} id : Unique identifier of the indicator
     */
    getDisabledMacroLevels(id) {
        if (this.analysis) {
            for (let a of this.analysis) {
                if (a.id === id) {
                    return a.disabled_for_macro_level;
                }
            }
        }
        return undefined;
    }

    /**
     * Returns the value of the active column which indicates if the indicator
     * is enabled.
     * @param {entier} id : Unique identifier of the indicator
     */
    isActive(id) {
        if (this.analysis) {
            for (let a of this.analysis) {
                if (a.id === id) {
                    return a.active;
                }
            }
        }
        return false;
    }

    /**
     * Returns the value of the disabled_for_zone column which indicates if the
     * indicator is not available on some territories and if so which ones
     * @param {entier} id : Unique identifier of the indicator
     */
    getDisabledZones(id) {
        if (this.analysis) {
            for (let a of this.analysis) {
                if (a.id === id) {
                    return a.disabled_for_zone;
                }
            }
        }
        return undefined;
    }

    /**
     * Return analysis's year
     */
    getAnalysisLastYear(id) {
        if (this.analysis) {
            for (let a of this.analysis) {
                if (a.id === id && a.years) {
                    return a.years[0];
                }
            }
        }
        return undefined;
    }

    /**
     * Return analysis's details
     */
    getAnalysisRepresentationDetails(id) {
        for (let a of this.analysis) {
            if (a.id === id && a.representation_details) {
                return a.representation_details;
            }
        }
        return [];
    }

    /**
     * Return analysis's years
     */
    getAnalysisYears(id) {
        for (let a of this.analysis) {
            if (a.id === id && a.years) {
                return a.years;
            }
        }
        return [];
    }

    /**
     * Return analysis's estimated years
     */
    getAnalysisEstimatedYears(id) {
        for (let a of this.analysis) {
            if (a.id === id && a.estimated_years) {
                return a.estimated_years;
            }
        }
        return [];
    }

    /**
     * Return analysis's methodology's pdf name
     */
    getAnalysisMethodoPdf(id) {
        if (this.analysis) {
            for (let a of this.analysis) {
                if (a.id === id) {
                    return a.methodo_pdf;
                }
            }
        }
        return undefined;
    }

    /**
     * Return analysis's theme
     */
    getAnalysisNameTheme(id) {
        if (this.analysis) {
            for (let a of this.analysis) {
                if (a.id === id) {
                    return a.ui_theme;
                }
            }
        }
        return undefined;
    }

    /**
     * Retrieve the list of PCAET trajectories
     */
    async getPCAETTrajectories() {
        let url = buildRegionUrl(config.api_pcaet_trajectories_list, this.region);
        await new Promise((resolve) =>
            Api.callApi(url, null, "GET").then((response) => {
                this.pcaetTrajectories = response;
                resolve();
            })
        );
    }

    /**
     * Retrieve the list of PCAET trajectories
     */
    async getDetailsOnTrajectory(trajectoryId) {
        if (!this.pcaetTrajectories) {
            await this.getPCAETTrajectories();
        }
        return this.pcaetTrajectories.find((t) => t.id === trajectoryId);
    }

    /**
     * Return analysis's theme
     */
    getUnitsForAnalysis(id) {
        if (this.analysis) {
            for (let a of this.analysis) {
                if (a.id === id) {
                    return {
                        unitsDefaultDetails: a.units_default_details,
                        unitsHiddenDetails: a.units_hidden_details,
                        unit: a.unit,
                    };
                }
            }
        }
        return undefined;
    }

    /**
     * Get associated units from IDs list
     */
    getUnitsByIds(idsList) {
        if (!this.dataUnits) {
            return;
        }
        return this.dataUnits.filter((value) => {
            return idsList.includes(value.unit_id.toString());
        });
    }

    /**
     * Get unique unit from ID
     */
    getUnit(unitId) {
        if (!this.dataUnits) {
            return;
        }
        const correspondingUnits = this.dataUnits.filter((value) => {
            return parseInt(unitId, 10) === parseInt(value.unit_id, 10);
        });
        return correspondingUnits?.[0];
    }

    /**
     * Compute units parameters associated to indicator with specific maille
     */
    getUnitParamsForIndicator(currentAnalysisSelectedUnit, meta, currentZoneMaille) {
        // we use metadata unit
        // as it could have been edited by API response when selecting a specific unit
        let unit = meta?.unit ?? "";
        // Units
        const associatedUnits = Object.keys(meta?.unitsDefaultDetails || {});
        const relevantUnits = this.getUnitsByIds(associatedUnits);
        // we check if there is any unit defined as default for this specific level
        const unitAsDefaultForThisZoneType = associatedUnits.filter((key) => {
            return meta?.unitsDefaultDetails[key]
                .split(",")
                .includes(currentZoneMaille);
        });
        let defaultUnitForThisZoneType =
            unitAsDefaultForThisZoneType.length > 0
                ? parseInt(unitAsDefaultForThisZoneType[0], 10)
                : undefined;
        // if no specific unit was selected => we need to select the right one
        if (!currentAnalysisSelectedUnit) {
            // either a default from config based on current maille level
            if (defaultUnitForThisZoneType) {
                unit = this.getUnit(defaultUnitForThisZoneType)?.unit_name;
                // or a specific one from possible units
            } else {
                let possibleUnits = relevantUnits.filter((_u) => _u.unit_name === unit);
                if (possibleUnits.length > 0) {
                    unit = possibleUnits[0].unit_name;
                    defaultUnitForThisZoneType = possibleUnits[0].unit_id;
                }
            }
        }
        // else, we have nothing to do, API gave the right unit when asked for data
        // cf. fetchData success block
        return {
            relevantUnits,
            unit,
            defaultUnitForThisZoneType,
        };
    }

    /**
     * Return analysis's theme
     */
    isDisabledInDashboard(id) {
        if (this.analysis) {
            for (let a of this.analysis) {
                if (a.id === id) {
                    return a.disabled_in_dashboard;
                }
            }
        }
        return false;
    }
}

export default Analysis;
