/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import { Link } from "react-router-dom";

import FranceMap from "./National/FranceMap.js";

import "bootstrap/dist/css/bootstrap.min.css";

/**
 * This component is used to manage the selection page for the different regions
 */
class RegionSelect extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        let settings = {};
        let is_national_enabled = false;
        for (let r of this.props.regions) {
            // 1 if we are on localhost, we redirect to localhost/$region$
            // 2 if we are NOT on localhost we redirect to $region$.dev.terristory.fr
            let url = r;
            if (window.location.hostname !== "localhost") {
                url =
                    window.location.protocol +
                    "//" +
                    this.props.regionsSettings.get(r).url +
                    "." +
                    window.location.hostname;
            }
            const title =
                this.props.regionsSettings.get(r)?.label ?? "Territoire non couvert";
            const enabled = this.props.regionsSettings.get(r)?.enabled;
            is_national_enabled =
                this.props.regionsSettings.get(r)?.is_national_enabled;
            settings[r] = {
                url,
                title,
                enabled,
            };
        }

        let message =
            "Bienvenue sur TerriSTORY® ! Veuillez choisir la région dans laquelle vous souhaitez utiliser la plateforme.";
        if (this.props.regions.length === 0) {
            message = "Aucune région n'est disponible.";
        }

        return (
            <div className="home-choice">
                <p>{message}</p>
                <FranceMap
                    enabledRegions={settings}
                    is_national_enabled={is_national_enabled}
                />
                <Link to="/national/" className="btn-france-other-region">
                    Si votre région n'est pas colorée, vous pouvez tout de même accéder
                    à certaines fonctionnalités de TerriSTORY® en cliquant ici !
                </Link>
            </div>
        );
    }
}

export default RegionSelect;
