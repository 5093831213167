/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";

import OlMap from "../Map/OlMap.js";
import config from "../../settings";
import configData from "../../settings_data.js";
import { buildRegionUrl, splitCategoryFilters } from "../../utils";
import Api from "../../Controllers/Api.js";
import NumberFormat from "react-number-format";
import StylesApi from "../../Controllers/style";

/**
 * Small map representing an indicator, present in a dashboard
 */
class AnalysisMap extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            representation: "map",
            disabled: false, // if the indicator is disabled, render an error message instead of the chart
            filters: {},
            filterString: "",
            data: {},
        };
    }

    componentDidMount() {
        // Retrieve initial filters
        let filters =
            this.props.filters ??
            this.props.parentApi.controller.analysisManager.initFiltersByCategory(
                parseInt(this.props.id_analysis)
            );
        this.setState({ filters: filters });

        this.fetchConfiguration(filters);
    }

    componentDidUpdate(prevProps, prevState) {
        const filterUpdated = this.state.filters !== prevState.filters;
        const werePropsFiltersForced =
            this.props.forcedFilters &&
            JSON.stringify(this.props.forcedFilters) !==
                JSON.stringify(prevProps.forcedFilters);
        const zoneUpdated =
            (this.props.zoneId ?? this.props.parentApi.data.currentZone) !==
            (prevProps.zoneId ?? prevProps.parentApi.data.currentZone);
        if (filterUpdated || zoneUpdated) {
            this.fetchConfiguration(this.state.filters);
        } else if (werePropsFiltersForced) {
            this.setState({
                filters: {
                    ...this.state.filters,
                    ...this.props.forcedFilters,
                },
            });
        }
    }

    componentWillUnmount() {
        if (this.dataPromise) {
            this.dataPromise.abort();
            this.props.parentApi.callbacks.updateDataLoaded(true);
        }
    }

    /** Simulates the Analysis.getDataMap function to transmit data to OlMap */
    getDataMap() {
        const data = this.state.data;
        if (!data.saveData) {
            return {};
        }
        if (data.saveData.afficher_indicateur_maille) {
            return {
                data: [],
                meta: { confidentiel: false, indisponible: false },
            };
        }
        let mapData = data.saveData.map;
        let mapMeta = data.saveMetaMap;
        let existUnavailableTerritories = false;
        let existConfidentialTerritories = false;
        for (let donnee of mapData) {
            if (donnee.val === null) existUnavailableTerritories = true;
            if (donnee.confidentiel) existConfidentialTerritories = true;
        }
        let typeConfidCamembert;
        if (data.saveData.confid) typeConfidCamembert = data.saveData.confid.charts;
        if (mapMeta !== undefined) {
            mapMeta.confidentiel = existConfidentialTerritories;
            mapMeta.indisponible = existUnavailableTerritories;
            mapMeta.typeConfidCamembert = typeConfidCamembert;
        }

        return {
            data: mapData,
            mapDataDeuxiemeRepresentation:
                data.saveData.donnees_deuxieme_representation,
            confid: data.saveData.confid,
            meta: mapMeta,
            bornesFiltre: data.saveData.bornes_filtres,
            minMaxValeursFiltrees: data.saveData.min_max_valeurs_filtrees,
            distinctValues: data.saveData.distinct_values,
            stations_mesures: data.saveData.stations_mesures,
        };
    }

    /**
     * Retrieve map data for the representation. Adapted from Analysis.fetchData
     */
    fetchConfiguration(filters) {
        const analysisId = this.props.id_analysis;
        let currentZone = this.props.zoneId ?? this.props.parentApi.data.currentZone;
        if ((this.props.zoneType ?? this.props.parentApi.data.zone.zone) === "region") {
            currentZone = this.props.parentApi.data.regionCode;
        }
        if (analysisId === undefined || analysisId === "" || currentZone === "") {
            return;
        }

        const analysisToCopy =
            this.props.parentApi.controller.analysisManager.analysis.find(
                (a) => a.id === parseInt(analysisId, 10)
            );
        if (!analysisToCopy) {
            this.setState({ disabled: true });
            return;
        }
        // Deep copy to prevent matadata from different representations from being linked
        let currentAnalysis = JSON.parse(JSON.stringify(analysisToCopy));
        // Don't deep-copy the functions
        if (currentAnalysis.saveMetaMap && currentAnalysis.saveMetaMap.colorScale) {
            currentAnalysis.saveMetaMap.colorScale.scale =
                analysisToCopy.saveMetaMap.colorScale.scale;
        }

        let url = buildRegionUrl(
            config.api_analysis_url,
            this.props.parentApi.data.region
        );

        let year = "";
        if (currentAnalysis.years) {
            year = "&annee=" + currentAnalysis.years[0];
        }
        let userId =
            this.props.parentApi.controller.gestionSuiviConsultations.idUtilisateur;

        const fluxThreshold =
            this.props.parentApi.controller.analysisManager.getFilterDefaultValue(
                this.props.id_analysis
            );
        this.setState({ fluxThreshold: fluxThreshold });
        let filtre = "";
        if (fluxThreshold) filtre = `&filtre=${fluxThreshold}`;

        let dataSource =
            `${url}${analysisId}/data` +
            `?zone=${this.props.zoneType ?? this.props.parentApi.data.zone.zone}` +
            `&maille=${
                this.props.zoneMaille ?? this.props.parentApi.data.zone.maille
            }` +
            `&zone_id=${currentZone}` +
            year +
            `&provenance=tableaux_de_bord` +
            `&id_utilisateur=${userId}` +
            filtre;

        let meta = {
            color_start: currentAnalysis.color_start || configData.defaultColor,
            color_end: currentAnalysis.color_end || configData.defaultColor,
            type: currentAnalysis.type,
            unit: currentAnalysis.unit,
            data_name: currentAnalysis.nom,
            isratio: currentAnalysis.isratio,
            display_total: currentAnalysis.display_total,
            nb_classes_color_representation:
                currentAnalysis.nb_classes_color_representation,
            donnees_exportables: currentAnalysis.donnees_exportables,
            representationDetails: currentAnalysis.representation_details,
            data_type: currentAnalysis.data_type,
            data: currentAnalysis.data,
            creditsAnalysisProducers: JSON.parse(
                currentAnalysis.credits_analysis_producers ?? "{}"
            ),
            creditsDataSources: JSON.parse(
                currentAnalysis.credits_data_sources ?? "{}"
            ),
            creditsDataProducers: JSON.parse(
                currentAnalysis.credits_data_producers ?? "{}"
            ),
            titre_dans_infobulle: currentAnalysis.titre_dans_infobulle,
            titreGraphiqueIndicateursClimat:
                currentAnalysis.titre_graphiques_indicateurs,
            carto_category: currentAnalysis.carto_category,
        };

        this.props.parentApi.callbacks.updateDataLoaded(false);

        let body = JSON.stringify(filters);
        if (this.dataPromise) this.dataPromise.abort();
        this.dataPromise = Api.callApi(dataSource, body, "POST");
        this.dataPromise
            .then((json) => {
                if (Object.keys(json).length === 0) {
                    throw Error("Response json was empty");
                }
                // Save the data
                currentAnalysis.saveData = json;
                meta = {
                    ...meta,
                    bornesFiltre: json.bornes_filtre,
                    dataDeuxiemeRepresentation: json.donnees_deuxieme_representation,
                    annee: json.annee,
                    titre_dans_infobulle: json.titre_dans_infobulle,
                    sum: json.total?.val,
                    divider: json.total?.divider,
                    nbValeurs: json.nb_valeurs,
                    filtre_initial_modalites_par_categorie:
                        json.filtre_initial_modalites_par_categorie,
                    afficherVersionSimple: json.afficher_calcul_et_donnees_table,
                    moyennePonderee: json.moyenne_ponderee,
                };
                if (json.min_max_valeurs) {
                    meta.min = json.min_max_valeurs.min;
                    meta.max = json.min_max_valeurs.max;
                }
                if (json.intervalle_temps) {
                    meta.intervalle_temps = json.intervalle_temps;
                    meta.intervalles_temps_recent_ancien =
                        json.intervalles_temps_recent_ancien;
                }
                currentAnalysis.saveMetaMap = meta;

                this.props.parentApi.callbacks.updateDataLoaded(true);
                this.setState({
                    disabled: false,
                    data: currentAnalysis,
                    filterString: JSON.stringify(filters),
                });
            })
            .catch((error) => {
                if (error.name === "AbortError") return;
                this.props.parentApi.callbacks.updateDataLoaded(true);
                console.error(error);
            });
    }

    /**
     * Launches a main component method to add/remove a modality to the filter
     * Triggered when a checkbox associated with a modality is checked
     * @param {string} modality : checked / unchecked modality
     */
    filterFromLegend(event, modality, category) {
        if (!this.state.filters || !this.state.filters[category]) return;

        const filterWithTableName = category + "." + modality;
        const filters = JSON.parse(JSON.stringify(this.state.filters));
        const filterIndex = filters[category].findIndex(
            (filter) => filter && filter.filtre_categorie === filterWithTableName
        );
        // do we have to add or do we have to remove
        if (event.target.checked) {
            // we first check that the filter is not already present to avoid adding it
            const alreadyPresent = filterIndex >= 0;

            // if we couldn't find it, it means we need to add it
            if (!alreadyPresent) {
                filters[category].push({
                    filtre_categorie: filterWithTableName,
                    type: "pie",
                });
            }
        } else {
            // we are going to delete the filter if we can find it
            if (filterIndex >= 0) {
                filters[category].splice(filterIndex, 1);
            }
        }

        this.setState({ filters: filters });
        if (this.props.provenance === "tableau_de_bord") {
            // we also callback the updated filters in case of dashboard edition
            this.props.parentApi.data.tableauBordDonnees.donnees[
                this.props.thematique
            ].indicateurs[this.props.id].filters = filters;
        }
    }

    /**
     * Builds the legend with which we can select or delete modalities
     * in the data to be displayed using checkboxes, from modalities by categories.
     */
    buildFilterLegend(data, category) {
        if (!data || !category) return;
        let taillePoliceModalites = "";
        let hauteurLegendeModalites = "";
        let hauteurLegende = "";
        let retourLigneLibelleModalites = "";
        if (
            this.props.provenance !== "tableau_de_bord" &&
            this.props.provenance !== "tableau_de_bord_restitue"
        ) {
            taillePoliceModalites = " taille-police-suivi-energetique";
            hauteurLegendeModalites = " legende-modalite-suivi-energetique";
            hauteurLegende = " liste-modalites-suivi-energetique";
        }
        if (this.props.provenance === "tableau_de_bord_restitue") {
            retourLigneLibelleModalites = " legende-modalite-tableau-bord";
        }
        let initialFilter = this.state.filters;
        let listeModaliteFiltre = [];
        for (let elem of initialFilter[category]) {
            listeModaliteFiltre.push(splitCategoryFilters(elem["filtre_categorie"])[1]);
        }
        let legend = [];
        const chartData = data.charts.find((c) => c.name === category);

        for (let i in chartData.labels) {
            const label = chartData.labels[i];
            const checked = listeModaliteFiltre.includes(label);
            if (
                !chartData.modalites_disponibles ||
                !chartData.modalites_disponibles.includes(label)
            ) {
                continue;
            }
            const id = `legende-${i}-${this.props.id}`;
            legend.push(
                <div className={"legende-modalite" + hauteurLegendeModalites} key={i}>
                    <span className="element-legende-modalites"></span>
                    <input
                        type="checkbox"
                        id={id}
                        defaultChecked={checked}
                        className="element-legende-modalites"
                        onChange={(event) =>
                            this.filterFromLegend(event, label, category)
                        }
                    ></input>
                    <label
                        htmlFor={id}
                        className={
                            "element-legende-modalites" +
                            retourLigneLibelleModalites +
                            taillePoliceModalites
                        }
                    >
                        {label}
                    </label>
                </div>
            );
        }
        legend = legend.reverse();
        return <ul className={"liste-modalites" + hauteurLegende}>{legend}</ul>;
    }

    buildLegend() {
        const meta = this.state.data.saveMetaMap;
        const { analysisManager } = this.props.parentApi.controller;
        if (!this.props.id_analysis || !meta) return;

        const titresLegende = analysisManager.getLegendTitles(this.props.id_analysis);
        let affichageProportion =
            analysisManager.isActive(this.props.id_analysis) &&
            analysisManager.getProportionForCircleDisplay(this.props.id_analysis)
                .afficherProportion;

        let legends = [];

        if (meta.dataDeuxiemeRepresentation && !affichageProportion) {
            let min = parseInt(meta.bornesFiltre.min);
            let max = parseInt(meta.bornesFiltre.max);
            let thresholdLabel = "";
            if (meta.data_type === "accessibilite_emploi") {
                let filterMax = max;
                if (this.state.fluxThreshold) {
                    filterMax = this.state.fluxThreshold;
                }
                thresholdLabel = "Tous les distances";
                if (filterMax < max) {
                    thresholdLabel = `Moins de ${filterMax} km`;
                }
            } else {
                max += 1; // Why ??
                let filterMin = min;
                let filterMax = max;
                if (this.state.fluxThreshold) {
                    if (this.state.fluxThreshold.length === 2) {
                        filterMin = this.state.fluxThreshold[0];
                        filterMax = this.state.fluxThreshold[1];
                    } else {
                        filterMin = this.state.fluxThreshold;
                    }
                }
                thresholdLabel = "Toutes les distances";
                if (filterMin > min && filterMax >= max) {
                    thresholdLabel = `Plus de ${filterMin} km`;
                } else if (filterMin > min && filterMax < max) {
                    thresholdLabel = `Entre ${filterMin} et ${filterMax} km`;
                } else if (filterMin <= min && filterMax < max) {
                    thresholdLabel = `Moins de ${filterMax} km`;
                }
            }
            legends.push(
                <div key="legend-threshold" className="legend-container">
                    Filtre : {thresholdLabel}
                </div>
            );
        }
        if (meta.type === "flow") {
            let min = configData.minFluxThreshold;
            let filterMin = min;
            if (this.state.fluxThreshold) {
                filterMin = this.state.fluxThreshold;
            }
            let thresholdLabel = "Tous les trajets";
            if (filterMin > min) {
                thresholdLabel = `Plus de ${filterMin} trajets`;
            }
            legends.push(
                <div key="legend-flow" className="legend-container">
                    Seuil d'affichage des flux : {thresholdLabel}
                </div>
            );
        }
        if (meta.type === "circle") {
            let color = meta.color_end;
            if (meta.min === 0) {
                color = configData.circleZeroColor;
            }

            const minRadius = configData.minRadiusCircle;
            const maxRadius = configData.dashboardMaxRadiusCircle;

            const styles = {
                circleMin: {
                    width: 2 * minRadius + "px",
                    height: 2 * minRadius + "px",
                    borderRadius: minRadius + "px",
                    background: color,
                    marginLeft: maxRadius - minRadius / 2 + "px",
                    marginRight: maxRadius + "px",
                },
                circleMax: {
                    width: 2 * maxRadius + "px",
                    height: 2 * maxRadius + "px",
                    borderRadius: maxRadius + "px",
                    background: meta.color_end,
                },
                circleWhenOnlyOneCircle: {
                    width: 2 * 0.75 * (minRadius + maxRadius) + "px",
                    height: 2 * 0.75 * (minRadius + maxRadius) + "px",
                    borderRadius: 0.75 * (minRadius + maxRadius) + "px",
                    background: meta.color_end,
                },
            };

            let { min, max } = meta;
            if (min < 0) {
                [min, max] = [max, min];
            }

            const legendMax = (
                <div key="legend-max" className="legend-item legend-max">
                    <span
                        className="circle-max legend-symbol"
                        style={styles.circleMax}
                    ></span>
                    <label>
                        <NumberFormat
                            value={max}
                            displayType={"text"}
                            thousandSeparator={" "}
                        />{" "}
                        {meta.unit}
                    </label>
                </div>
            );

            legends.push(
                <div className="legend-container legend-circle" key="0">
                    <p>{titresLegende.titre_legende}</p>
                    <div key="legend-min" className="legend-item legend-min">
                        <span
                            className="circle-min legend-symbol"
                            style={
                                min === max
                                    ? styles.circleWhenOnlyOneCircle
                                    : styles.circleMin
                            }
                        ></span>
                        <label>
                            <NumberFormat
                                value={min}
                                displayType={"text"}
                                thousandSeparator={" "}
                            />{" "}
                            {meta.unit}
                        </label>
                    </div>
                    {min !== max && legendMax}
                </div>
            );
        } else if (meta.type === "pixels_cat") {
            let choroLegend = [];
            // we get colors code from meta data corresponding to current analysis
            let colorsCode = {};
            // TOFIX ? cf. same code in Legend.js
            let data = this.getDataMap();
            if (data?.distinctValues) {
                data.distinctValues.forEach((cat) => {
                    colorsCode[cat.valeur] = cat;
                });
            }
            if (colorsCode) {
                for (const c in colorsCode) {
                    if (!Object.hasOwnProperty.call(colorsCode, c)) continue;
                    // the "color code" gives both value and color used
                    // corresponding to this class
                    const catCode = colorsCode[c];

                    choroLegend.push(
                        <div key={c} className="legend-item">
                            <span
                                className="legend-square legend-symbol"
                                style={{ background: catCode.couleur }}
                            ></span>
                            <label>{catCode.modalite}</label>
                        </div>
                    );
                }
                // if we have no result, it means that no value is available here
                if (Object.keys(colorsCode).length === 0) {
                    choroLegend = "Pas de données disponibles pour ce territoire.";
                }
            } else {
                choroLegend = "Aucune légende n'a été définie pour cet indicateur.";
            }

            legends.push(
                <div className="legend-container" key="2">
                    <p>{titresLegende.titre_legende_deuxieme_representation}</p>
                    {choroLegend}
                </div>
            );
        } else if (meta.type === "stars") {
            // we currently retrieve min and max from breaks given in colorScale
            let minStar = 0,
                maxStar = 5;
            if (meta.representationDetails) {
                // we parse as integers
                const _minStar = parseInt(meta.representationDetails.minValue ?? 0, 10);
                const _maxStar = parseInt(meta.representationDetails.maxValue ?? 5, 10);
                // just in case we don't have correct order here
                minStar = Math.min(_minStar, _maxStar);
                maxStar = Math.max(_minStar, _maxStar);
            }
            // we rebuild color scale
            const colorScale = StylesApi.getColorScale(
                Array(maxStar - minStar)
                    .fill()
                    .map((x, i) => ({
                        val: i + minStar,
                    })),
                maxStar - minStar,
                meta.color_start,
                meta.color_end,
                "equidistant",
                meta.dataDeuxiemeRepresentation,
                meta.type === "flow",
                meta.data_type,
                maxStar - minStar + 1
            );

            let choroLegend = [];
            // we then build the legend, at the same time stars and choropleth
            // legend alongside
            for (let nbStars = minStar; nbStars < maxStar + 1; nbStars++) {
                // color used to fill shapes on map (choropleth)
                let color = colorScale.scale(nbStars);
                let style = { background: color };
                // if we have a specific color chose to fill stars, we use it
                let fullStarStyle = { backgroundColor: "darkblue" };
                if (meta.representationDetails) {
                    if (meta.representationDetails.color) {
                        fullStarStyle.backgroundColor =
                            meta.representationDetails.color;
                    }
                }

                // then we display the right number of stars for this class
                let currentStars = [];
                for (let currentStarNb = 0; currentStarNb < maxStar; currentStarNb++) {
                    if (currentStarNb >= nbStars) {
                        currentStars.push(
                            <span
                                key={"current-star-" + currentStarNb}
                                className="analysis-legend-star analysis-empty-legend-star"
                            />
                        );
                    } else {
                        currentStars.push(
                            <span
                                key={"current-star-" + currentStarNb}
                                className="analysis-legend-star analysis-full-legend-star"
                                style={fullStarStyle}
                            />
                        );
                    }
                }
                choroLegend.push(
                    <p key={"stars-" + nbStars} className="legend-item">
                        <span
                            className="legend-square legend-symbol"
                            style={style}
                        ></span>
                        {currentStars}
                    </p>
                );
            }

            if (meta.min === 0 && meta.max === 0) {
                legends.push(
                    <div className="legend-container" key="2">
                        <p>Pas de données</p>
                    </div>
                );
            } else {
                legends.push(
                    <div className="legend-container" key="2">
                        <p>{titresLegende.titre_legende_deuxieme_representation}</p>
                        {choroLegend}
                    </div>
                );
            }
        }
        if (
            meta.type === "choropleth" ||
            meta.type === "pixels" ||
            meta.type === "flow" ||
            meta.dataDeuxiemeRepresentation ||
            meta.afficherVersionSimple
        ) {
            let choroLegend = [];
            let nbClasses =
                meta.nb_classes_color_representation &&
                Number.isInteger(meta.nb_classes_color_representation)
                    ? meta.nb_classes_color_representation
                    : configData.nbClassChoropleth;
            if (meta.nbValeurs < nbClasses) {
                nbClasses = meta.nbValeurs;
            }

            let colorScale = meta.colorScale;
            let data = this.getDataMap();
            if (data.data) {
                const deuxiemeRepresentation =
                    meta.dataDeuxiemeRepresentation || meta.afficherVersionSimple;

                colorScale = StylesApi.getColorScale(
                    data.data,
                    data.data.length,
                    meta.color_start,
                    meta.color_end,
                    meta.type === "stars"
                        ? "equidistant"
                        : this.props.parentApi.data.classMethod,
                    deuxiemeRepresentation,
                    meta.type === "flow",
                    meta.data_type,
                    nbClasses
                );
            }

            for (let c = 0; c < nbClasses; c++) {
                let legendVal = colorScale.breaks[c];
                let color = colorScale.scale(legendVal);
                let style = { background: color };
                let key = "legend-item-" + legendVal + c;
                let label = "";
                let lastIncluder = "[";

                if (c === nbClasses - 1) {
                    lastIncluder = "]";
                }

                let unit = meta.unit;
                if (meta.dataDeuxiemeRepresentation || meta.afficherVersionSimple) {
                    unit = "%";
                }
                if (meta.data_type === "accessibilite_emploi") {
                    // the accessibility index has no direct meaning, replaced by : Très mauvaise, Assez mauvaise, Plutôt mauvaise, Plutôt bonne, Assez bonne, Très bonne
                    label = (
                        <label>
                            <span>{colorScale.classesLegende[c]}</span>
                        </label>
                    );
                }
                if (meta.data_type !== "accessibilite_emploi") {
                    label = (
                        <label>
                            <span>[</span>
                            <NumberFormat
                                value={Number(colorScale.breaks[c].toFixed(2))}
                                displayType={"text"}
                                thousandSeparator={" "}
                            />
                            <span>&nbsp;à&nbsp;</span>
                            <NumberFormat
                                value={Number(colorScale.breaks[c + 1].toFixed(2))}
                                displayType={"text"}
                                thousandSeparator={" "}
                            />{" "}
                            <span>
                                {lastIncluder} {unit}
                            </span>
                        </label>
                    );
                }
                if (meta.type === "flow") {
                    style = { background: color, height: c + 2 + "px" };
                }

                choroLegend.push(
                    <div key={key} className="legend-item">
                        <span
                            className="legend-square legend-symbol"
                            style={style}
                        ></span>
                        {label}
                    </div>
                );
            }

            let legend = (
                <div className="legend-container" key="2">
                    <p>{titresLegende.titre_legende_deuxieme_representation}</p>
                    {choroLegend}
                </div>
            );
            if (meta.type === "flow") {
                legend = (
                    <div className="legend-container" key="2">
                        <p>{titresLegende.titre_legende}</p>
                        {choroLegend}
                    </div>
                );
            }

            if (meta.min === 0 && meta.max === 0) {
                legend = (
                    <div className="legend-container" key="2">
                        <p>Pas de données</p>
                    </div>
                );
            }

            legends.push(legend);
        } else if (meta.type === "wms_feed") {
            legends.push(
                <div className="legend-wms">
                    {meta.unit && <p>Valeurs en {meta.unit}</p>}
                    <img
                        className={"legend-wms-" + this.props.id_analysis}
                        alt="Légende du flux WMS"
                    />
                </div>
            );
        }
        return <div role="group">{legends}</div>;
    }

    render() {
        if (this.state.disabled) {
            return (
                <div className="charts-legende">
                    <div className={"confid-chart"}>
                        Cet indicateur n'est pas activé actuellement, veuillez contacter
                        l'administrateur régional.
                    </div>
                </div>
            );
        }

        const metaStyle = {
            ...this.props.parentApi.data.metaStyle,
            maxRadius: configData.dashboardMaxRadiusCircle,
        };
        let map = (
            <div className="minimapContainerContent">
                <OlMap
                    id={"analysismap-" + this.props.id}
                    parentApi={this.props.parentApi}
                    mapOptions={{ controls: [], interactions: [] }}
                    zone={
                        this.props.zoneType && this.props.zoneMaille
                            ? {
                                  zone: this.props.zoneType,
                                  maille: this.props.zoneMaille,
                              }
                            : this.props.parentApi.data.zone
                    }
                    currentZone={
                        this.props.zoneId ?? this.props.parentApi.data.currentZone
                    }
                    analysisId={this.props.id_analysis}
                    fluxThreshold={this.state.fluxThreshold}
                    filterString={this.state.filterString}
                    metaStyle={metaStyle}
                    getDataMap={this.getDataMap.bind(this)}
                />
            </div>
        );

        const data = this.state.data.saveData;
        const category = this.props.type.categorie;

        //#region Not sure about this, copied and adapted from DiagrammeCirculaire
        let confidA, confidB, confidC, confidD;
        if (data && data.confid) {
            confidA = // In case of A confidentiality, if the user has clicked on a chart, we must hide the others
                this.props.parentApi.data.confidFilteredPieId !== undefined &&
                this.props.parentApi.data.confidFilteredPieId !== this.props.id;
            confidB = ["energie", "facture_energie", "usage", "facture_usage"];
            confidB = data.confid.charts === "B" && confidB.includes(category);
            // TODO: passage table here?
            confidC = ["secteur", "facture_secteur", "usage", "facture_usage"];
            confidC = data.confid.charts === "C" && confidC.includes(category);
            confidD = data.confid.charts === "D";
        }
        if (confidA || confidB || confidC || confidD) {
            let div = document.getElementsByClassName("diagramme");

            for (let elemHtml of div) {
                elemHtml.setAttribute(
                    "class",
                    "formulaire-thematique diagramme-confidentiel"
                );
            }

            return (
                <div className="charts-legende confid-chart">
                    Données confidentielles
                </div>
            );
        }
        //#endregion
        return (
            <div className="charts-legende">
                {map}
                <div className="choisir-methode">
                    {this.buildLegend()}
                    {this.buildFilterLegend(data, category)}
                </div>
            </div>
        );
    }
}

export default AnalysisMap;
