/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";

import Api from "../../Controllers/Api";
import LoadingButton from "../Utils/LoadingButton.js";

import config from "../../settings.js";
import { buildRegionUrl } from "../../utils.js";
import "bootstrap/dist/css/bootstrap.min.css";

/**
 * Gestion des données des indicateurs
 */
class TerritoiresManage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fichierDonnees: undefined,
            donneesTerritoriales: undefined,
            messageChargementEnCours: undefined,
            donneesGeographiques: undefined,
            currentPerimeter: undefined,
            perimeters: [],
            nouveau_statut: false,
            anneeCourante: parseInt(new Date().getFullYear(), 10),
            launchPerimeterUpdate: {
                isLoading: false,
            },
            rebuildTerritorialAggregations: {
                isLoading: false,
            },
            launchDataConversion: {
                isLoading: false,
            },
        };
        this.region = this.props.parentApi.data.region;
    }

    componentDidMount() {
        this.retrievePerimeters();
        if (!this.state.donneesGeographiques) {
            this.retrieveMandatoryGeoData();
        }
    }

    /**
     * Cette fonction est déclenchée dès que le composant est sollicité d'une quelconque
     * manière. Ici, si l'état du composant est différent de son état avant la mise à
     * jour, on relance la methode render.
     * @param {objet clé => valeur} props propriété de l'objet avant la mise à jour
     * @param {objet clé => valeur} state état du composant avant sa mise à jour
     */
    componentDidUpdate(props, state) {
        if (state.status !== this.state.status) {
            this.setState({
                nouveau_statut: true,
            });
        }
        if (
            this.state.anneeCourante !== state.anneeCourante ||
            this.state.tableCourante !== state.tableCourante
        ) {
            this.retrieveMandatoryData();
            this.setState({
                anneeCourante: this.state.anneeCourante,
            });
        }
    }

    retrieveCurrentPerimeter() {
        let url = buildRegionUrl(config.api_current_perimeter, this.region);
        Api.callApi(buildRegionUrl(url)).then((response) => {
            this.setState({
                currentPerimeter: response["current_perimeter"],
            });
        });
    }

    retrievePerimeters() {
        let url = buildRegionUrl(config.api_perimeters_available, this.region);
        Api.callApi(buildRegionUrl(url)).then((response) => {
            this.setState({
                perimeters: response,
            });
        });
    }

    /**
     * Met à jour l'état du composant avec la liste des tables de données géographiques
     * On dispose pour la table des communes IGN sur toutes les France et de la table
     * des territoires, si elle est déjà présente en base ou non.
     * @param {region} region : Nom de la région sélectionnée
     */
    retrieveMandatoryGeoData() {
        let urlTmp = config.api_donnees_geo;
        let url = buildRegionUrl(urlTmp, this.region);
        Api.callApi(buildRegionUrl(url)).then((response) => {
            this.setState({
                donneesGeographiques: response,
            });
        });
    }

    /**
     * Intégration des données associées aux mailles territoriales
     * sous la forme d'une table située en base de données au sein
     * du schéma régional
     * @param {chaine de caractère} nom : Nom de la table dans laquelle on insère les données.
     */
    ajouterDonnees(nom) {
        // Objet de type FormData
        let formData = new FormData();
        // Validité des données
        if (!this.state.fichierDonnees) {
            return false;
        }

        let donneesTerritoire = "";
        if (nom === "territoire") {
            donneesTerritoire = 1;
        }

        // Mise à jour du formulaire
        formData.append("fichier", this.state.fichierDonnees);
        formData.append("nomFichier", this.state.fichierDonnees.name);
        // Appel à l'api avec le fichier et les métadonnées saisies par l'utilisateur
        Api.callApi(
            buildRegionUrl(
                config.api_transfer_data_from_global_region_url,
                this.props.parentApi.data.region
            ).replace("#table_name#", nom) +
                "?territoire=" +
                donneesTerritoire +
                "&date_perimetre=" +
                this.state.anneeCourante,
            formData,
            "POST",
            "default"
        )
            .then((response) => {
                this.setState({ status: response.message });
            })
            .catch((e) => {
                this.setState({ erreur: e.message });
            });
    }

    /**
     * Récupère le fichier à partir duquel on met à jour la table des territoires.
     * @param {événement} evenement : événement qui permet d'obtenir la liste des fichiers (format shp) sélectionnés
     */
    tableTerritoire(evenement) {
        this.setState({
            fichierDonnees: evenement.target.files[0],
        });
    }

    /**
     * Déclenchée sur événement,
     * Lance la mise à jour de la table des territoires.
     * @param {chaine de caractère} url : url sollicitée pour lancer la fonction Python chargée de mettre à jour la table des territoires
     */
    mettreAJourTableTerritoire(url) {
        let formData = new FormData();
        if (this.state.fichierDonnees) {
            formData.append("fichier", this.state.fichierDonnees);
            formData.append("nomFichier", this.state.fichierDonnees.name);
        }
        Api.callApi(url, formData, "PUT", "default")
            .then((response) => {
                this.setState({ status: response.message });
            })
            .catch((e) => this.props.parentApi.callbacks.updateMessages(e.message));
    }

    /**
     * Lance la procédure de mise à jour / crétion de l'ensemble des données associées aux territoires c'est-à-dire
     * - Les tables territoriales des schémas régionaux comme occitanie.epci, occitanie.petr ou encore auvergne_rhone_aleps.teposcv
     * - Les tables des communes, epci et départements de France indispensables au fonctionnement de l'indicateur « migrations pendulaires »
     */
    launchPerimeterUpdate() {
        if (!this.state.perimeterYear) {
            this.setState({
                launchPerimeterUpdate: {
                    message:
                        "Merci de sélectionner le millésime du périmètre géographique de votre table des territoires.",
                    status: "danger",
                    isLoading: false,
                },
            });
            return;
        }
        let regionCode = this.props.parentApi.data.regionCode;
        let url = buildRegionUrl(config.api_update_perimeters, this.region)
            .replace("#region_code#", regionCode)
            .replace("#year#", this.state.perimeterYear);
        this.setState({
            launchPerimeterUpdate: {
                message:
                    "Chargement des données en cours... Cette opération peut prendre plusieurs minutes, merci de ne pas interrompre le processus.",
                error: undefined,
                isLoading: true,
            },
        });
        Api.callApi(url, {}, "PUT", "default")
            .then((response) => {
                this.setState({
                    launchPerimeterUpdate: {
                        message: response.message,
                        status: "success",
                        isLoading: false,
                    },
                });
            })
            .catch((e) =>
                this.setState({
                    launchPerimeterUpdate: {
                        message: e.message,
                        status: "danger",
                        isLoading: false,
                    },
                })
            );
    }

    rebuildTerritorialAggregations() {
        let url = buildRegionUrl(config.api_rebuild_perimeters, this.region);
        this.setState({
            rebuildTerritorialAggregations: {
                message:
                    "Chargement des données en cours... Cette opération peut prendre plusieurs minutes, merci de ne pas interrompre le processus.",
                status: undefined,
                error: undefined,
                isLoading: true,
            },
        });
        Api.callApi(url, {}, "PUT", "default")
            .then((response) => {
                this.setState({
                    rebuildTerritorialAggregations: {
                        message: response.message,
                        status: "success",
                        isLoading: false,
                    },
                });
            })
            .catch((e) =>
                this.setState({
                    rebuildTerritorialAggregations: {
                        message: e.message,
                        status: "danger",
                        isLoading: false,
                    },
                })
            );
    }

    launchDataConversion() {
        let url = buildRegionUrl(config.api_convert_data_to_perimeter, this.region);
        this.setState({
            launchDataConversion: {
                message:
                    "Chargement des données en cours... Cette opération peut prendre plusieurs minutes, merci de ne pas interrompre le processus.",
                status: undefined,
                isLoading: true,
            },
        });
        Api.callApi(url, {}, "PUT", "default")
            .then((response) => {
                this.setState({
                    launchDataConversion: {
                        message: response.message,
                        status: "success",
                        isLaunchingDataConversion: false,
                    },
                });
            })
            .catch((e) =>
                this.setState({
                    launchDataConversion: {
                        message: e.message,
                        status: "danger",
                        isLaunchingDataConversion: false,
                    },
                })
            );
    }

    setPerimeterYear(e) {
        this.setState({
            perimeterYear: parseInt(e.target.value, 10),
        });
    }

    /**
     * Rendu du composant
     * @return {dom} le rendu du composant
     */
    render() {
        if (
            !this.props.connected ||
            !this.props.userInfos ||
            this.props.userInfos?.profil !== "admin"
        ) {
            return <div>Non accessible.</div>;
        }

        let message = "";
        if (this.state.status) {
            message = <div className="alert alert-success">{this.state.status}</div>;
        } else if (this.state.erreur) {
            message = <div className="alert alert-warning">{this.state.erreur}</div>;
        } else if (this.state.messageChargementEnCours) {
            message = <div>{this.state.messageChargementEnCours}</div>;
        }
        let territorialTables = [];
        if (this.state.donneesGeographiques) {
            for (let donneeGeo of this.state.donneesGeographiques) {
                let texteBouton = "Intégrer";
                if (donneeGeo.presente) {
                    texteBouton = "Mettre à jour";
                }

                if (donneeGeo.nom === "territoire") {
                    if (donneeGeo.presente) {
                        let urlTerritoire =
                            buildRegionUrl(config.api_analysis_data_url, this.region) +
                            "/territoire?territoire=True";
                        territorialTables.push(
                            <div key="maj-territoires">
                                <h4>Table des territoires</h4>
                                <div>
                                    <input
                                        type="file"
                                        onChange={(evenement) => {
                                            this.tableTerritoire(evenement);
                                        }}
                                    ></input>
                                    <button
                                        className="btn btn-info"
                                        onClick={() =>
                                            this.mettreAJourTableTerritoire(
                                                urlTerritoire
                                            )
                                        }
                                    >
                                        {texteBouton}
                                    </button>
                                </div>
                            </div>
                        );
                    } else {
                        territorialTables.push(
                            <div key={"maj-territoires " + donneeGeo.nom}>
                                <h4>Table des territoires</h4>
                                <div>
                                    <input
                                        type="file"
                                        onChange={(evenement) => {
                                            this.tableTerritoire(evenement);
                                        }}
                                    ></input>
                                    <button
                                        className="btn btn-info"
                                        onClick={() => {
                                            this.ajouterDonnees("territoire");
                                        }}
                                    >
                                        {texteBouton}
                                    </button>
                                </div>
                            </div>
                        );
                    }
                } else if (
                    donneeGeo.nom === "confid_maille" ||
                    donneeGeo.nom === "confid_camembert"
                ) {
                    let urlConfid = buildRegionUrl(
                        config.api_analysis_confid_data_url,
                        this.region
                    );

                    let urlConfidMaille = urlConfid.replace(
                        "#table_name#",
                        "confid_maille"
                    );
                    let urlConfidCamembert = urlConfid.replace(
                        "#table_name#",
                        "confid_camembert"
                    );
                    territorialTables.push(
                        <div key="maj-confid" className="form-maj-confid">
                            <h4>Tables de confidentialité</h4>
                            <b>Confidentialité pour les territoires</b>
                            <br></br>
                            <div>
                                <input
                                    type="file"
                                    onChange={(evenement) => {
                                        this.tableTerritoire(evenement);
                                    }}
                                ></input>
                                <button
                                    className="btn btn-info"
                                    onClick={() =>
                                        this.mettreAJourTableTerritoire(urlConfidMaille)
                                    }
                                >
                                    {texteBouton}
                                </button>
                            </div>
                            <b>Confidentialité pour les camemberts</b>
                            <div>
                                <input
                                    type="file"
                                    onChange={(evenement) => {
                                        this.tableTerritoire(evenement);
                                    }}
                                ></input>
                                <button
                                    className="btn btn-info"
                                    onClick={() =>
                                        this.mettreAJourTableTerritoire(
                                            urlConfidCamembert
                                        )
                                    }
                                >
                                    {texteBouton}
                                </button>
                            </div>
                        </div>
                    );
                }
            }
        }
        let listePerimetresGeo = [
            <option key={"selection-annee"}>{"Sélectionnez une année"}</option>,
        ];
        this.state.perimeters.forEach((i) => {
            listePerimetresGeo.push(
                <option key={i} value={i}>
                    {i}
                </option>
            );
        });

        return (
            <div>
                <h3>Gestion des territoires</h3>
                <div className="territorial-updates-blocks">
                    <div>
                        <div>
                            <h4>
                                Reconstruction du maillage géographique au niveau
                                communal
                            </h4>
                            <p>
                                Pour mettre à jour les{" "}
                                <strong>
                                    contours territoriaux pour une nouvelle année
                                </strong>
                                , les périmètres IGN de la base ADMIN EXPRESS ont été
                                inclus dans la base commune. Il vous suffit de
                                sélectionner le millésime recherché dans la liste
                                suivante puis de lancer la mise à jour des contours
                                géographiques. Cette étape reconstruira les contours
                                géographiques de tous les périmètres définis dans la
                                table territoire.
                            </p>
                            <p>
                                Pour enrichir cette base de nouveaux périmètres, vous
                                pouvez contacter l'équipe par mail ou sur le dépôt
                                gitlab (voir page <em>Open-Source</em>).
                            </p>
                            <p>
                                <label htmlFor="perimeter-year">
                                    Quel est le périmètre géographique de la table des
                                    territoires ? Le périmètre actuel est celui de{" "}
                                    <strong>{this.state.currentPerimeter}</strong>.
                                </label>
                            </p>
                            <select
                                id="perimeter-year"
                                onChange={(e) => this.setPerimeterYear(e)}
                            >
                                {listePerimetresGeo}
                            </select>
                        </div>
                        <div>
                            <p className="alert alert-warning">
                                Attention, il vous faudra mettre manuellement à main les
                                PCAET après cette MAJ. Vous pouvez utiliser l'onglet de
                                MAJ des PCAET pour cela.
                            </p>
                            <p>
                                <LoadingButton
                                    forceDisabled={
                                        this.state.launchPerimeterUpdate.isLoading
                                    }
                                    callback={() => {
                                        this.launchPerimeterUpdate();
                                    }}
                                    title="Lancer la mise à jour des périmètres territoriaux"
                                />
                            </p>
                            {this.state.launchPerimeterUpdate.message && (
                                <div
                                    className={
                                        "alert alert-" +
                                        this.state.launchPerimeterUpdate.status
                                    }
                                >
                                    {this.state.launchPerimeterUpdate.message}
                                </div>
                            )}
                        </div>
                    </div>

                    <div>
                        <h4>Mise à jour des périmètres supra-communaux</h4>
                        <p>
                            Si votre table des territoires a changé (ajout d'un nouveau
                            maillage, modifications d'associations, etc.), vous pouvez
                            reconstruire l'ensemble des niveaux supra-communaux sans
                            toucher aux périmètres des communes (pas de mise à jour des
                            contours géographiques des communes qui resteront au
                            millésime de <strong>{this.state.currentPerimeter}</strong>
                            ).
                        </p>
                        <p>
                            <LoadingButton
                                forceDisabled={
                                    this.state.rebuildTerritorialAggregations.isLoading
                                }
                                callback={() => {
                                    this.rebuildTerritorialAggregations();
                                }}
                                title="Lancer la reconstruction des périmètres"
                            />
                        </p>
                        {this.state.rebuildTerritorialAggregations.message && (
                            <div
                                className={
                                    "alert alert-" +
                                    this.state.rebuildTerritorialAggregations.status
                                }
                            >
                                {this.state.rebuildTerritorialAggregations.message}
                            </div>
                        )}
                    </div>

                    <div>
                        <h4>Mise à jour des données des indicateurs</h4>
                        <p>
                            Une fois les contours géographiques et la table des
                            territoires mises à jour, vous pouvez{" "}
                            <strong>
                                rafraichir les données des indicateurs pour les
                                convertir dans le nouveau périmètre
                            </strong>
                            . Cela est possible table par table en utilisant le tableau
                            ci-dessus, ou bien toutes les tables d'un coup via le bouton
                            ci-dessous.
                        </p>
                        <p className="alert alert-warning">
                            <strong>
                                Attention, cette étape peut être longue en fonction de
                                la taille de vos tables de données !
                            </strong>
                        </p>
                        <p>
                            <LoadingButton
                                forceDisabled={
                                    this.state.launchDataConversion.isLoading
                                }
                                callback={() => {
                                    this.launchDataConversion();
                                }}
                                title="Lancer la conversion des données"
                            />
                        </p>
                        {this.state.launchDataConversion.message && (
                            <div
                                className={
                                    "alert alert-" +
                                    this.state.launchDataConversion.status
                                }
                            >
                                {this.state.launchDataConversion.message}
                            </div>
                        )}
                    </div>

                    <div>{territorialTables}</div>
                </div>

                {message}
            </div>
        );
    }
}

export default TerritoiresManage;
