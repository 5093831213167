/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import config from "../../settings";

import OlMap from "../Map/OlMap.js";
import { createPdfMethodoLink } from "../../utils.js";

/**
 * Small map representing installations, present in a dashboard.
 * The id_analysis prop must correspond to an existing POI theme.
 */
class PoiMap extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            representation: "map",
            disabled: false, // if the indicator is disabled, render an error message instead of the chart
            displayedLayers: [],
            poiLayers: [],
        };
    }

    componentDidMount() {
        const theme = this.props.id_analysis;
        // Retrieve POI layers associated to given theme
        const poiLayers = this.props.parentApi.controller.equipementsManager
            .getEquipementsLayers()
            .filter((layer) => layer.theme === theme);
        const displayedLayers =
            this.props.filters ?? poiLayers.map((layer) => layer.nom);

        this.setState({
            poiLayers: poiLayers,
            displayedLayers: displayedLayers,
        });
    }

    /**
     * Add or remove a layer from displayed ones
     * Triggered when a checkbox associated with a layer is checked
     */
    filterLegend(event, layerName) {
        if (!this.state.displayedLayers) return;
        const displayedLayers = JSON.parse(JSON.stringify(this.state.displayedLayers));
        const layerIndex = displayedLayers.findIndex((layer) => layer === layerName);
        const isLayerPresent = layerIndex >= 0;
        // do we have to add or do we have to remove
        if (event.target.checked) {
            // the layer isn't already present, it means we need to add it
            if (!isLayerPresent) {
                displayedLayers.push(layerName);
            }
        } else {
            // we are going to delete the filter if we can find it
            if (isLayerPresent) {
                displayedLayers.splice(layerIndex, 1);
            }
        }
        this.setState({ displayedLayers: displayedLayers });
        if (this.props.provenance === "tableau_de_bord") {
            // Callback the updated filters in case of dashboard edition
            this.props.parentApi.data.tableauBordDonnees.donnees[
                this.props.thematique
            ].indicateurs[this.props.id].filters = displayedLayers;
        }
    }

    /**
     * Builds the legend with which we can select or delete modalities
     * in the data to be displayed using checkboxes, from modalities by themes.
     */
    buildLegend(displayedLayers) {
        if (!displayedLayers) return;
        let legende = [];
        let tailleSpan = "10px";
        let taillePoliceModalites = "";
        let hauteurLegendeModalites = "";
        let hauteurLegende = "";
        if (
            this.props.provenance !== "tableau_de_bord" &&
            this.props.provenance !== "tableau_de_bord_restitue"
        ) {
            tailleSpan = "15px";
            taillePoliceModalites = " taille-police-suivi-energetique";
            hauteurLegendeModalites = " legende-modalite-suivi-energetique";
            hauteurLegende = " liste-modalites-suivi-energetique";
        }
        let retourLigneLibelleModalites = "";
        if (this.props.provenance === "tableau_de_bord_restitue") {
            retourLigneLibelleModalites = " legende-modalite-tableau-bord";
        }
        for (let layer of this.state.poiLayers) {
            const checked = this.state.displayedLayers.includes(layer.nom);
            const couleur = layer.couleur;
            const pdfUrl = createPdfMethodoLink(
                config.methodo_url,
                this.props.parentApi.data.region,
                layer.nom + ".pdf"
            );
            legende.push(
                <div
                    className={"legende-modalite" + hauteurLegendeModalites}
                    key={layer.nom}
                >
                    <span
                        className="element-legende-modalites"
                        style={{
                            backgroundColor: couleur,
                            display: "block",
                            height: tailleSpan,
                            width: tailleSpan,
                            border: "1px solid rgb(150,150,150)",
                        }}
                    ></span>
                    <input
                        type="checkbox"
                        id={"legende-" + this.props.id + "-" + layer.nom}
                        defaultChecked={checked}
                        className="element-legende-modalites"
                        onChange={(event) => this.filterLegend(event, layer.nom)}
                    ></input>
                    <label
                        htmlFor={"legende-" + this.props.id + "-" + layer.nom}
                        className={
                            "element-legende-modalites" +
                            retourLigneLibelleModalites +
                            taillePoliceModalites
                        }
                    >
                        {" "}
                        {layer.label}
                        <a href={pdfUrl} target="_blank" rel="noreferrer">
                            <div className="pdf"></div>
                        </a>
                    </label>
                </div>
            );
        }
        legende = legende.reverse();
        return <div className={"liste-modalites" + hauteurLegende}>{legende}</div>;
    }

    render() {
        const map = (
            <div className="minimapContainerContent">
                <OlMap
                    id={"poimap-" + this.props.id}
                    parentApi={this.props.parentApi}
                    mapOptions={{ controls: [], interactions: [] }}
                    zone={
                        this.props.zoneType && this.props.zoneMaille
                            ? {
                                  zone: this.props.zoneType,
                                  maille: this.props.zoneMaille,
                              }
                            : this.props.parentApi.data.zone
                    }
                    currentZone={
                        this.props.zoneId ?? this.props.parentApi.data.currentZone
                    }
                    poiLayers={this.state.displayedLayers}
                />
            </div>
        );

        let legend = "";
        legend = this.buildLegend(this.state.displayedLayers);
        return (
            <div>
                <div className="charts-legende">
                    {map}
                    <div className="choisir-methode">{legend}</div>
                </div>
            </div>
        );
    }
}

export default PoiMap;
