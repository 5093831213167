/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import Api from "./Api";
import config from "../settings";
import { buildRegionUrl } from "../utils.js";

/**
 * Handle authentication related queries with backend.
 */
class AuthManager {
    constructor(callback, region, regionCode = undefined) {
        this.region = region;
        this.regionCode = regionCode;
        this.userInfos = {};
        this.connected = false;
        this.regionUtilisateur = undefined;
        if (callback) callback();
    }

    async getMe(callbacks = {}, nationalRegion = false) {
        let url = buildRegionUrl(config.auth_url, this.region) + "/me";
        if (nationalRegion) {
            url += "?national_region=" + nationalRegion;
        }
        const json = await Api.callApi(url).catch((error) => {
            console.error(error);
            return {};
        });
        if (
            json.me &&
            (json.me.region === this.region ||
                (config.ENABLE_GLOBAL_ADMIN && json.me.global_admin))
        ) {
            this.connected = true;
            this.userInfos = {
                id: json.me.user_id.mail,
                region: json.me.user_id.region,
                prenom: json.me.prenom,
                nom: json.me.nom,
                organisation: json.me.organisation,
                fonction: json.me.fonction,
                territoire: json.me.territoire,
                territoire_predilection: JSON.parse(json.me.territoire_predilection),
                utiliser_territoire_predilection:
                    json.me.utiliser_territoire_predilection,
                code_postal: json.me.code_postal,
                profil: json.me.profil,
                exp: json.me.exp,
                accesIndicateursDesactives: json.me.acces_indicateurs_desactives,
                canValidatePOIContributions: json.me.can_validate_poi_contributions,
                globalAdmin: json.me.global_admin,
                nationalRegion: json.me.national_region,
            };
            this.regionUtilisateur = json.me.region;
        } else {
            this.connected = false;
            if (json.me) {
                // TODO: What to do when a user connects to the wrong region ? Is it even possible ?
                this.regionUtilisateur = json.me.region;
            }
        }
        // Update parent
        if (callbacks.updateConnected) callbacks.updateConnected(this.connected);
        if (json.me && this.region === "national" && callbacks.updateRegionName) {
            callbacks.updateRegionName(json.me.national_region);
            callbacks.handleAuthComplete(true);
        }
        if (json.me && this.connected && callbacks.updateProfil) {
            callbacks.updateProfil(
                json.me.profil,
                json.me.publication,
                json.me.acces_indicateurs_desactives
            );
        }
        return this.connected;
    }

    disconnect(callbacks = {}) {
        let url = buildRegionUrl(config.auth_logout_url, this.region);
        localStorage.setItem("access_token", "");
        localStorage.setItem("refresh_token", "");
        Api.callApi(url, null, "GET").then((response) => {
            if (response.status !== "logout") {
                alert(
                    "Une erreur s'est produite pendant la déconnexion. Merci de contacter un administrateur."
                );
            }
            if (callbacks.refreshUserID) callbacks.refreshUserID();
        });
        this.userInfos = {};
        this.connected = false;
        this.regionUtilisateur = undefined;
        // Update parent
        if (callbacks.updateConnected) callbacks.updateConnected(false);
        if (callbacks.updateProfil) callbacks.updateProfil();
        return this.connected;
    }
}

export default AuthManager;
