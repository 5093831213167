/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
// import NumberFormat from 'react-number-format';

import Api from "../../Controllers/Api";
import config from "../../settings.js";
import configData from "../../settings_data.js";
import { buildRegionUrl, isLightColor, splitCategoryFilters } from "../../utils.js";

/**
 * This component is used to create a pie chart (circular diagram)
 */
class DiagrammeCirculaire extends React.Component {
    constructor(props) {
        super(props);

        const legendChartOptions = {
            display: false,
            position: "left",
            fullWidth: false,
            reverse: false,
            labels: {
                fontColor: configData.chartFontColor,
                fontSize: configData.chartFontSize,
                padding: configData.chartPadding,
                boxWidth: 10,
            },
        };

        this.state = {
            id_analyse: String(props.id_analysis),
            representation: "pie",
            legend: legendChartOptions,
            data: {},
            id: props.type.categorie,
            thematique: props.thematique,
            code_analyse: props.id,
            donneesDiagrammes: {},
            type: props.type,
            widthDOM: window.innerWidth,
            disabled: false,
            missingCategory: false,
            filters: {},
        };
    }

    componentDidMount() {
        // Retrieve initial filters
        let filters =
            this.props.filters ??
            this.props.parentApi.controller.analysisManager.initFiltersByCategory(
                parseInt(this.props.id_analysis)
            );
        this.setState({ filters: filters });

        // Get data from API
        this.fetchConfiguration(filters);

        let chartsLegendsDiv = document.getElementsByClassName("charts-legende");
        if (chartsLegendsDiv.length > 0) {
            let widthDiv =
                document.getElementsByClassName("charts-legende")[0].offsetWidth;
            if (
                this.props.parentApi.data.tailleDiv !== widthDiv &&
                this.props.provenance === "tableau_de_bord_restitue"
            ) {
                this.props.parentApi.callbacks.tailleDiv(widthDiv);
            }
        }
    }

    /**
     * This function updates the data from which we build the pie charts when changing territory
     * @param {object key => value} prevProps : main component properties before territory change via selection tool
     * @param {object key => value} prevState : main component state before change
     */
    componentDidUpdate(prevProps, prevState) {
        let chartsLegendsDiv = document.getElementsByClassName("charts-legende");
        if (chartsLegendsDiv.length > 0) {
            let widthDiv =
                document.getElementsByClassName("charts-legende")[0].offsetWidth;
            if (
                this.props.parentApi.data.tailleDiv !== widthDiv &&
                this.props.provenance === "tableau_de_bord_restitue"
            ) {
                this.props.parentApi.callbacks.tailleDiv(widthDiv);
            }
        }

        let elemHtml = document.getElementsByClassName("liste-modalites-tdb");
        let currentZone = this.props.zoneId ?? this.props.parentApi.data.currentZone;
        let filtreCategorieCourant = this.props.parentApi.data.filtreCategorieCourant;
        let numeroIndicateurCourant = this.props.parentApi.data.numeroIndicateur;
        if (
            this.props.provenance === "tableau_de_bord" ||
            this.props.provenance === "tableau_de_bord_restitue"
        ) {
            this.props.parentApi.controller.dashboardManager.applyBottomMargin(
                elemHtml,
                "diagramme-tdb"
            );
        }

        // we find different reasons to retrieve configuration
        const wasUnitUpdated = prevProps.unit !== this.props.unit;
        const wereFiltersUpdated =
            JSON.stringify(this.state.filters) !== JSON.stringify(prevState.filters);
        const werePropsFiltersForced =
            this.props.forcedFilters &&
            JSON.stringify(this.props.forcedFilters) !==
                JSON.stringify(prevProps.forcedFilters);
        const wasZoneUpdated =
            (prevProps.zoneId ?? prevProps.parentApi.data.currentZone) !==
                currentZone && this.state.id_analyse;
        const wasCurrentFilterUpdated =
            filtreCategorieCourant !==
                prevProps.parentApi.data.filtreCategorieCourant &&
            numeroIndicateurCourant === this.props.id;

        if (
            wasUnitUpdated ||
            wereFiltersUpdated ||
            wasZoneUpdated ||
            wasCurrentFilterUpdated
        ) {
            this.fetchConfiguration(this.state.filters);
        } else if (werePropsFiltersForced) {
            this.setState({
                filters: {
                    ...this.state.filters,
                    ...this.props.forcedFilters,
                },
            });
        }
    }

    componentWillUnmount() {
        if (this.dataPromise) {
            this.dataPromise.abort();
            this.props.parentApi.callbacks.updateDataLoaded(true);
        }
    }

    fetchConfiguration(filtres) {
        let pZone =
            "?zone=" + (this.props.zoneType ?? this.props.parentApi.data.zone.zone);
        let pMaille =
            "&maille=" +
            (this.props.zoneMaille ?? this.props.parentApi.data.zone.maille);
        let pZoneId =
            "&zone_id=" + (this.props.zoneId ?? this.props.parentApi.data.currentZone);
        if ((this.props.zoneType ?? this.props.parentApi.data.zone.zone) === "region") {
            pZoneId = "&zone_id=" + this.props.parentApi.data.regionCode;
        }
        let unit = this.props?.unit ? "&unit_id=" + this.props.unit : "";

        let id_analyse = this.props.id_analysis;

        this.props.parentApi.callbacks.updateDataLoaded(false);

        let representation = this.state.representation;
        let pProvenance = "&provenance=" + this.props.provenance;
        let dataSource =
            config.api_analysis_meta_url +
            id_analyse +
            "/graphique/" +
            representation +
            pZone +
            pMaille +
            pZoneId +
            pProvenance +
            unit;
        let body = JSON.stringify(filtres);

        if (this.dataPromise) {
            this.dataPromise.abort();
        }
        this.dataPromise = Api.callApi(
            buildRegionUrl(dataSource, this.props.parentApi.data.region),
            body,
            "POST"
        );
        this.dataPromise
            .then((json) => {
                this.props.parentApi.callbacks.updateDataLoaded(true);
                if (!json) {
                    return;
                }

                if (json["disabled"] && json["disabled"] === true) {
                    this.setState({
                        disabled: true,
                    });
                    return;
                }

                // Set states
                let donneesDiagrammes = undefined;
                if (json.charts) {
                    for (let a in json.charts) {
                        if (json.charts[a].name === this.props.type.categorie) {
                            donneesDiagrammes = json.charts[a];
                        }
                    }
                }
                let donneesDiagrammesFormattees = {};
                let datasets = {};
                if (donneesDiagrammes) {
                    datasets["data"] = donneesDiagrammes.data;
                    datasets["backgroundColor"] = donneesDiagrammes.colors;
                    datasets["origBackgroundColor"] = donneesDiagrammes.colors;
                    datasets["hoverBackgroundColor"] = donneesDiagrammes.colors;
                    donneesDiagrammesFormattees["type"] = "pie";
                    donneesDiagrammesFormattees["titre"] = this.props.type.titre;
                    donneesDiagrammesFormattees["categorie"] =
                        this.props.type.categorie;
                    donneesDiagrammesFormattees["datasets"] = [datasets];
                    donneesDiagrammesFormattees["labels"] = donneesDiagrammes.labels;
                    donneesDiagrammesFormattees["confid"] = json.confid;
                    donneesDiagrammesFormattees["visible"] = true;
                    donneesDiagrammesFormattees["unite"] = json.unite;
                    donneesDiagrammesFormattees["name"] = donneesDiagrammes.name;
                    this.setState({
                        data: donneesDiagrammesFormattees,
                    });
                } else if (donneesDiagrammes === undefined) {
                    this.setState({
                        missingCategory: true,
                    });
                }
            })
            .catch((error) => {
                if (error.name === "AbortError") return;
                this.props.parentApi.callbacks.updateDataLoaded(true);
            });
    }

    /**
     * Launches a main component method to add/remove a modality to the filter
     * Triggered when a checkbox associated with a modality is checked
     * @param {chaine de caractère} modalite : modality checked / unchecked
     */
    filtrerSurLegende(event, modalite, categorie) {
        let filterWithTableName = categorie + "." + modalite;
        // if we do not have filters
        if (!this.state.filters) return;

        let _filters = JSON.parse(JSON.stringify(this.state.filters));
        // if we do not have current filter
        if (!_filters[categorie]) return;

        // we first retrieve the filter ID if present inside the filters
        const idIfPresent = _filters[categorie].findIndex(
            (element) => element && element.filtre_categorie === filterWithTableName
        );

        // do we have to add or do we have to remove
        if (event.target.checked) {
            // if we couldn't find it, it means we need to add it
            if (idIfPresent === -1) {
                _filters[categorie].push({
                    filtre_categorie: filterWithTableName,
                    type: "pie",
                });
            }
        } else {
            // we are going to delete the filter if we can find it
            if (idIfPresent !== -1) {
                _filters[categorie].splice(idIfPresent, 1);
            }
        }

        // if the action resulted in some changes between previous filters
        // and new filters => we update the state
        if (JSON.stringify(this.state.filters) !== JSON.stringify(_filters)) {
            this.setState({
                filters: _filters,
            });
            if (this.props.provenance === "tableau_de_bord") {
                // we also callback the updated filters in case of dashboard edition
                this.props.parentApi.data.tableauBordDonnees.donnees[
                    this.props.thematique
                ].indicateurs[this.props.id].filters = _filters;
            }
        }
    }

    /**
     * Returns an information about total value
     *
     * @param {object} data the dataset used as inputs
     * @returns the data tables formatted
     */
    addTotalInfo(data) {
        if (data && data.datasets) {
            let total = 0;
            let v = Intl.NumberFormat("fr-FR");
            for (let i in data.datasets[0].data) {
                const element = data.datasets[0].data[i];
                total += element;
            }

            return (
                <div className="graph-static-data">
                    Total : {v.format(total)} {data.unite}
                </div>
            );
        } else {
            return [];
        }
    }

    /**
     * Builds the legend with which we can select or delete modalities
     * in the data to be displayed using checkboxes, from modalities by categories.
     * @param {object ke value} chart : data for a chart constructed as follows:
     * {
     *     data : {
     *         confid : Type of confidentiality,
     *         id : indicator identifier,
     *         labels : list of modalities,
     *         datasets : [{
     *             data : List of values in the correct order to match them
     *             to the order of the modalities,
     *             backgroundColor : List of colors in the correct order to match them
     *             to the order of the modalities
     *         }]
     *     }
     * }
     * This array contains other keys that we don't use in this method.
     */
    buildLegend(data) {
        let id = this.state.id;
        let legende = [];
        let filtreInitial = this.state.filters;
        let listeModaliteFiltre = [];

        if (filtreInitial[data.name]) {
            for (let elem of filtreInitial[data.name]) {
                listeModaliteFiltre.push(
                    splitCategoryFilters(elem["filtre_categorie"])[1]
                );
            }
        }

        let retourLigneLibelleModalites = "";
        if (this.props.provenance === "tableau_de_bord_restitue") {
            retourLigneLibelleModalites = " legende-modalite-tableau-bord";
        }
        for (let i in data.datasets[0].data) {
            let checked = false;
            if (listeModaliteFiltre.indexOf(data.labels[i]) !== -1) {
                checked = true;
            }

            legende.push(
                <div className="legende-modalite" key={"legende-" + i + "-" + id}>
                    <span
                        className="element-legende-modalites marge-element-legende"
                        style={{
                            backgroundColor: data.datasets[0].backgroundColor[i],
                            display: "block",
                            height: "12px",
                            width: "12px",
                        }}
                    ></span>
                    <input
                        type="checkbox"
                        defaultChecked={checked}
                        id={"legende-" + i + this.props.id + "-" + data.labels[i]}
                        className="element-legende-modalites"
                        onChange={(event) =>
                            this.filtrerSurLegende(
                                event,
                                data.labels[i],
                                this.props.type.categorie
                            )
                        }
                    ></input>
                    <label
                        htmlFor={"legende-" + i + this.props.id + "-" + data.labels[i]}
                        className={
                            "element-legende-modalites marge-element-legende" +
                            retourLigneLibelleModalites
                        }
                    >
                        {" "}
                        {data.labels[i]}
                    </label>
                </div>
            );
        }
        return (
            <ul
                className={"liste-modalites liste-modalites-tdb"}
                key={"legende-finale-" + id}
            >
                {legende}
            </ul>
        );
    }

    render() {
        if (this.state.disabled === true) {
            return (
                <div className="charts-legende">
                    <div className={"confid-chart"}>
                        Cet indicateur n'est pas activé actuellement, veuillez contacter
                        l'administrateur régional.
                    </div>
                </div>
            );
        }
        if (this.state.missingCategory === true) {
            return (
                <div className="charts-legende">
                    <div className={"confid-chart"}>
                        Catégorie non disponible pour cet indicateur, veuillez contacter
                        l'administrateur régional.
                    </div>
                </div>
            );
        }

        let data = this.state.data;

        let titre = "";
        if (this.state.data.titre) {
            titre = this.state.data.titre;
        }

        let chart = "";

        /**
         *
         * management of the confidentiality of circular diagrams:
         *  Case A: we remove the interactivity between pie charts, but we still update the map
         *  Case B: the energy and usage pie charts are deleted
         *  Case C: the sector and usage pie charts are deleted
         *  Case D: we delete all the pie charts
         */
        // Tooltip according to https://github.com/chartjs/Chart.js/blob/master/docs/samples/tooltip/html.md
        // Function that creates the main div receiving the tooltip if it doesn't exists
        const getOrCreateTooltip = (chart) => {
            let tooltipEl = chart.canvas.parentNode.querySelector("div");

            if (!tooltipEl) {
                // the style elements for the dev of the tooltip (including transparency)
                tooltipEl = document.createElement("div");
                tooltipEl.style.background = "rgba(0, 0, 0, 0.8)";
                tooltipEl.style.borderRadius = "5px";
                tooltipEl.style.color = "white";
                tooltipEl.style.opacity = 1;
                tooltipEl.style.pointerEvents = "none";
                tooltipEl.style.position = "absolute";
                tooltipEl.style.display = "flex";
                tooltipEl.style.width = "max-content";
                tooltipEl.style.zIndex = 999999;
                tooltipEl.style.alignItems = "center";
                tooltipEl.style.justifyContent = "center";
                tooltipEl.style.transform = "translate(-50%, 0)";
                tooltipEl.style.transition = "all .4s ease";

                const div = document.createElement("div");
                div.style.margin = "0px";

                tooltipEl.appendChild(div);
                chart.canvas.parentNode.appendChild(tooltipEl);
            }

            return tooltipEl;
        };

        /**
         * Create the tooltip and fill it with relevant information.
         */
        const externalTooltipHandler = (context) => {
            // Tooltip Element
            const { chart, tooltip } = context;
            const tooltipEl = getOrCreateTooltip(chart);

            // Hide if no tooltip
            if (tooltip.opacity === 0) {
                tooltipEl.style.opacity = 0;
                return;
            }

            // Set Text
            if (tooltip.body) {
                const bodyLines = tooltip.body.map((b) => b.lines);
                const tooltipBody = document.createElement("div");
                bodyLines.forEach((body, i) => {
                    // if we have more than one element to display, we need to skip a new line
                    if (i > 0) tooltipBody.appendChild(document.createElement("br"));

                    const colors = tooltip.labelColors[i];

                    // label from the label of the target object
                    let libelleBrut = tooltip.dataPoints[i].label;
                    let libelle =
                        libelleBrut +
                        "\u00a0: " +
                        new Intl.NumberFormat("fr-FR").format(
                            tooltip.dataPoints[i].raw
                        ) +
                        "\u00a0" +
                        data.unite;

                    // square containing the color of the dotted diagram piece
                    const span = document.createElement("span");
                    span.style.background = colors.backgroundColor;
                    span.style.borderColor = colors.borderColor;
                    span.style.borderWidth = "2px";
                    span.style.marginRight = "10px";
                    span.style.height = "10px";
                    span.style.width = "10px";
                    span.style.display = "inline-block";

                    const text = document.createTextNode(libelle);

                    tooltipBody.appendChild(span);
                    tooltipBody.appendChild(text);

                    let sum = 0;
                    let dataArr = context.chart.data.datasets[0].data;
                    dataArr.forEach((data) => {
                        sum += data;
                    });
                    let percentage = (tooltip.dataPoints[i].raw * 100) / sum;

                    const percentageText = document.createElement("span");
                    percentageText.appendChild(
                        document.createTextNode(percentage.toFixed(1) + "%")
                    );
                    percentageText.style.marginLeft = "20px";

                    tooltipBody.appendChild(document.createElement("br"));
                    tooltipBody.appendChild(percentageText);
                });

                // We get the tooltip to put our object in it
                const tableRoot = tooltipEl.querySelector("div");

                // Remove any other information that may already be present
                while (tableRoot.firstChild) {
                    tableRoot.firstChild.remove();
                }

                // Add new children
                tableRoot.appendChild(tooltipBody);
            }

            const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

            // Display, position, and set styles for font
            tooltipEl.style.opacity = 1;
            tooltipEl.style.left = positionX + tooltip.caretX + "px";
            tooltipEl.style.top = positionY + tooltip.caretY + "px";
            tooltipEl.style.font = tooltip.options.bodyFont.string;
            tooltipEl.style.padding =
                tooltip.options.padding + "px " + tooltip.options.padding + "px";
        };

        let state_datasets = this.state.data.datasets;

        const options = {
            width: 250,
            height: 250,
            maintainAspectRatio: false,
            elements: {
                arc: {
                    borderWidth: 0, // No space beetween pie parts
                },
            },
            plugins: {
                title: {
                    display: true,
                    text: titre,
                    padding: 5,
                    font: {
                        size: 16,
                    },
                },
                legend: {
                    display: false,
                },
                tooltip: {
                    enabled: false,
                    external: externalTooltipHandler,
                },
                datalabels: {
                    formatter: (value, ctx) => {
                        let sum = 0;
                        let dataArr = ctx.chart.data.datasets[0].data;
                        dataArr.forEach((data) => {
                            sum += data;
                        });
                        let percentage = (value * 100) / sum;
                        if (percentage < configData.thresholdCircularDiagram) {
                            return "";
                        }
                        return percentage.toFixed(1) + "%";
                    },
                    color: function (context) {
                        var index = context.dataIndex;
                        return isLightColor(state_datasets[0].backgroundColor[index])
                            ? "#222"
                            : "#fff";
                    },
                    font: {
                        weight: "bold",
                        size: 16,
                    },
                },
            },
            devicePixelRatio: configData.chartsDevicePixelRatio,
        };
        let legendeFinale = "";
        if (this.state.data.datasets && data) {
            chart = (
                <div>
                    <div className="block-row">
                        <Pie
                            plugins={[ChartDataLabels]}
                            options={options}
                            data={data}
                            legend={this.state.legend}
                            width={this.props.width ?? options.width}
                            height={this.props.height ?? options.height}
                        />
                    </div>
                </div>
            );
            legendeFinale = this.buildLegend(data);
            // TODO: passage table here?
            if (
                this.state.data.confid.charts === "D" ||
                (this.state.data.confid.charts === "B" &&
                    ["energie", "facture_energie"].includes(
                        this.state.data.categorie
                    )) ||
                (this.state.data.confid.charts === "B" &&
                    ["usage", "facture_usage"].includes(this.state.data.categorie)) ||
                (this.state.data.confid.charts === "C" &&
                    ["secteur", "facture_secteur"].includes(
                        this.state.data.categorie
                    )) ||
                (this.state.data.confid.charts === "C" &&
                    ["usage", "facture_usage"].includes(this.state.data.categorie)) ||
                (this.props.parentApi.data.confidFilteredPieId !== undefined &&
                    this.props.parentApi.data.confidFilteredPieId !== this.state.id) // In case of A confidentiality, if the user has clicked on a chart, we must hide the others
            ) {
                let div = document.getElementsByClassName("diagramme");

                for (let elemHtml of div) {
                    elemHtml.setAttribute(
                        "class",
                        "formulaire-thematique diagramme-confidentiel"
                    );
                }

                let titleChart = "";
                // TODO: passage table here?
                if (["energie", "facture_energie"].includes(this.state.data.name))
                    titleChart = (
                        <label className="title-chart">Par type d'énergie</label>
                    );
                if (["usage", "facture_usage"].includes(this.state.data.name))
                    titleChart = <label className="title-chart">Par usage</label>;
                if (["secteur", "facture_secteur"].includes(this.state.data.name))
                    titleChart = <label className="title-chart">Par secteur</label>;
                chart = (
                    <div className={"confid-chart"}>
                        {titleChart}Données confidentielles
                    </div>
                );
                legendeFinale = "";
            }

            let elemHtml = document.getElementsByClassName("liste-modalites");
            if (
                this.props.provenance === "tableau_de_bord" ||
                this.props.provenance === "tableau_de_bord_restitue"
            ) {
                this.props.parentApi.controller.dashboardManager.applyBottomMargin(
                    elemHtml
                );
            }
        }

        let totalData = this.addTotalInfo(data);

        return (
            <div>
                <div className="charts-legende circular-diagram">
                    <div>
                        {chart}
                        {totalData}
                    </div>
                    <div className="choisir-methode">{legendeFinale}</div>
                </div>
            </div>
        );
    }
}

export default DiagrammeCirculaire;
