/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import { useState, useEffect } from "react";
import EditeurMarkdown from "../EditeurMarkdown";
import GestionnaireImagesCarousel from "../GestionnaireImagesCarousel";
import "bootstrap/dist/css/bootstrap.min.css";

import Api from "../../Controllers/Api";
import { buildRegionUrl } from "../../utils.js";

import config from "../../settings.js";

/**
 * This function is used to edit the content of the information popup on the application home page
 */
function EditeurPopUp({ connected, userInfos, parentApi }) {
    // state containing markdown text
    const [value, setValue] = useState("");
    // state containing all manager images
    const [allImages, setAllImages] = useState([]);
    // state to add image manager for Carousel. It counts the carousels.
    const [numGestionnaireImagesCarousel, updateNumGestionnaireImagesCarousel] =
        useState(0);
    // state containing the number of the carousel to delete
    const [numCarouselSupp, updateNumCarouselSupp] = useState(0);
    // boolean that determines which page to display
    const [affichage, updateAffichage] = useState(true);
    // list containing image managers for Carousel
    const [gestionnaireImagesCarousel, updateGestionnaireImagesCarousel] = useState([]);
    // variable useful to manipulate the gestionnaireImagesCarousel array
    let carousels = gestionnaireImagesCarousel;

    function ajoutCarousel() {
        carousels.push(
            <GestionnaireImagesCarousel
                key={numGestionnaireImagesCarousel}
                number={numGestionnaireImagesCarousel}
                allImages={allImages}
                setAllImages={setAllImages}
            />
        );
        updateGestionnaireImagesCarousel(carousels);
        updateNumGestionnaireImagesCarousel(numGestionnaireImagesCarousel + 1);
    }

    useEffect(() => {
        setValue(parentApi.data.settings.actu);
    }, [parentApi]);

    function suppCarousel(deleteCarouselWithId) {
        if (numGestionnaireImagesCarousel !== 0) {
            let index = deleteCarouselWithId
                ? numCarouselSupp - 1
                : numGestionnaireImagesCarousel - 1;
            // if the GestionnaireImagesCarousel contains one or more images, in this case we remove its images from the list
            if (typeof allImages[index] !== "undefined") {
                let listImTemp = allImages;
                if (deleteCarouselWithId) {
                    listImTemp.splice(index, 1);
                    setAllImages(listImTemp);
                } else {
                    listImTemp.pop();
                    setAllImages(listImTemp);
                }
            }
            // we remove the image manager from the front
            if (deleteCarouselWithId) {
                carousels.splice(index, 1);
                updateGestionnaireImagesCarousel(carousels);
            } else {
                carousels.pop();
                updateGestionnaireImagesCarousel(carousels);
            }
            updateNumGestionnaireImagesCarousel(numGestionnaireImagesCarousel - 1);
        }
    }

    useEffect(() => {
        // condition that allows you to delete all the carousels and associated images when you return to the "Éditeur pop-up accueil" page
        if (affichage) {
            setAllImages([]);
            updateGestionnaireImagesCarousel([]);
            updateNumGestionnaireImagesCarousel(0);
        }
    }, [affichage]);

    function envoiDataImageEnBd() {
        const confirmBox = window.confirm(
            "Êtes-vous sûr de vouloir changer la pop-up d'accueil ?"
        );
        if (confirmBox) {
            // call API to update welcome popup data
            let url = buildRegionUrl(config.api_pop_up_accueil, parentApi.data.region);
            const body = JSON.stringify({
                texteMarkdown: value,
                tableauImg: allImages,
            });
            Api.callApi(url, body, "put").then((response) => {});
        }
    }

    function getInputNumberCarousel(e) {
        updateNumCarouselSupp(parseInt(e.target.value, 10));
    }

    // ternary which determines the display of the page
    return (
        connected &&
        userInfos.profil === "admin" &&
        (affichage ? (
            <div className="pop-up-accueil">
                <h1 style={{ marginTop: "1%" }}>Éditeur pop-up accueil</h1>
                <EditeurMarkdown value={value} setValue={setValue} />
                <h3 id="info-bouton-editeur-pop-up-suivant">
                    Personnalisation des carrousels
                </h3>
                <button
                    className="bouton-editeur-pop-up"
                    id="bouton-editeur-pop-up-suivant"
                    onClick={() => updateAffichage(false)}
                >
                    <i
                        className="bi bi-arrow-right-circle-fill"
                        style={{ fontSize: "32px" }}
                    ></i>
                </button>
            </div>
        ) : (
            <div className="pop-up-carousel">
                <h1 style={{ marginTop: "1%" }}>Personnalisation des carrousels</h1>
                <div id="interface-image">
                    <div id="ensemble-boutons-carousel">
                        <button onClick={() => ajoutCarousel()}>
                            <span style={{ paddingRight: "10px" }}>
                                Ajouter un carrousel
                            </span>
                            <i
                                className="bi bi-plus-circle"
                                style={{ fontSize: "16px" }}
                            ></i>
                        </button>
                        <button onClick={() => suppCarousel(false)}>
                            <span style={{ paddingRight: "10px" }}>
                                Supprimer le dernier carrousel
                            </span>
                            <i
                                className="bi bi-dash-circle"
                                style={{ fontSize: "16px" }}
                            ></i>
                        </button>
                        <label
                            style={{ marginRight: "10px" }}
                            htmlFor="number-carousel"
                        >
                            Carrousel
                        </label>
                        <input
                            type="number"
                            onChange={(e) => getInputNumberCarousel(e)}
                            id="number-carousel"
                            name="number-carousel"
                            min="1"
                            max={numGestionnaireImagesCarousel}
                            step="1"
                        />
                        <button
                            disabled={
                                numCarouselSupp > numGestionnaireImagesCarousel
                                    ? true
                                    : false
                            }
                            onClick={() => suppCarousel(true)}
                        >
                            <span style={{ paddingRight: "10px" }}>Supprimer</span>
                            <i
                                className="bi bi-dash-circle"
                                style={{ fontSize: "16px" }}
                            ></i>
                        </button>
                    </div>
                    <div id="contener-carousel">{gestionnaireImagesCarousel}</div>
                </div>
                <h3 id="info-bouton-editeur-pop-up-retour">Éditeur pop-up d'accueil</h3>
                <button
                    className="bouton-editeur-pop-up"
                    id="bouton-editeur-pop-up-retour"
                    onClick={() => updateAffichage(true)}
                >
                    <i
                        className="bi bi-arrow-left-circle-fill"
                        style={{ fontSize: "32px" }}
                    ></i>
                </button>
                <h3 id="info-bouton-editeur-pop-up-validation">
                    Générer la pop-up d'accueil
                </h3>
                <button
                    onClick={() => envoiDataImageEnBd()}
                    className="bouton-editeur-pop-up"
                    id="bouton-editeur-pop-up-validation"
                >
                    <i className="bi bi-check-circle" style={{ fontSize: "32px" }}></i>
                </button>
            </div>
        ))
    );
}

export default EditeurPopUp;
