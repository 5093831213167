/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import { Link } from "react-router-dom";
import config from "../../settings.js";
import { consulteAutrePage } from "../../utils.js";

/**
 * This component is used to display the Support page
 */
class Support extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            didSendVisit: false,
        };
    }

    componentDidUpdate() {
        if (
            this.props.parentApi.controller.gestionSuiviConsultations &&
            !this.state.didSendVisit
        ) {
            let region = this.props.parentApi.data.region;
            let idUtilisateur =
                this.props.parentApi.controller.gestionSuiviConsultations.idUtilisateur;
            let url = config.api_consultation_autre_page_url;
            consulteAutrePage(url, region, idUtilisateur, "Support", "");
            this.setState({
                didSendVisit: true,
            });
        }
    }

    suiviConsultation(e, text = false) {
        let region = this.props.parentApi.data.region;
        let idUtilisateur =
            this.props.parentApi.controller.gestionSuiviConsultations.idUtilisateur;
        let url = config.api_consultation_autre_page_url;
        let nomPdf = text;
        if (!text) {
            nomPdf = e.target.innerText;
        }
        consulteAutrePage(url, region, idUtilisateur, "Support - PDF", nomPdf);
        return false;
    }

    render() {
        let lien_faq_final = (
            <a
                href={
                    config.faq_url.replace(
                        "#region#",
                        this.props.parentApi.data.region
                    ) + "faq.pdf"
                }
                target="_blank"
                rel="noreferrer"
            >
                {" "}
                Foire aux questions
            </a>
        );
        if (this.props.parentApi.data.region === "nouvelle-aquitaine") {
            return (
                <div className="widgets full-screen-widget">
                    <Link
                        className="back-to-map"
                        to={"/" + this.props.parentApi.data.urlPartageable}
                    >
                        <button
                            type="button"
                            className="close close-big"
                            data-dismiss="alert"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </Link>
                    <div className="tout">
                        <div className="support">
                            <div className="img">
                                <img
                                    src="../../img/logo_TerriSTORY_region_nouvelle_aquitaine_small.png"
                                    alt="Logo de TerriSTORY"
                                ></img>
                            </div>
                            <div className="corps">
                                <p>
                                    Bienvenue dans la page support aux utilisateurs de
                                    l'outil TerriSTORY®.
                                </p>
                                <p>
                                    Cette page recense l'ensemble des ressources à
                                    disposition pour faciliter votre utilisation de
                                    l'outil.
                                </p>
                                <p>Bonne navigation !</p>
                                <br></br>
                                <h3>Méthodologies de calcul et sources des données</h3>
                                <p>
                                    Pour chaque indicateur, chaque installation,
                                    estimation d'impact, consulter la méthodologie de
                                    calcul et les sources en cliquant sur le{" "}
                                    <img src="./img/icon_help_small.png" alt=""></img>{" "}
                                    présent sur la page (généralement en haut à droite).
                                </p>
                                <br></br>
                                <h3>Réponses aux questions </h3>
                                <ul className="liste">
                                    <li>
                                        Les réponses aux questions les plus fréquentes
                                        se trouvent dans la
                                        {lien_faq_final}
                                    </li>
                                    <li>
                                        Pour toute autre question,{" "}
                                        <Link to="/contact">
                                            <p>contactez-nous</p>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else if (this.props.parentApi.data.region === "occitanie") {
            return (
                <div className="widgets full-screen-widget">
                    <Link
                        className="back-to-map"
                        to={"/" + this.props.parentApi.data.urlPartageable}
                    >
                        <button
                            type="button"
                            className="close close-big"
                            data-dismiss="alert"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </Link>
                    <div className="tout">
                        <div className="support">
                            <div className="img">
                                <img
                                    src="img/logo_TerriSTORY_region_occitanie.png"
                                    alt="Logo de TerriSTORY"
                                ></img>
                            </div>
                            <div className="corps">
                                <p>
                                    Bienvenue dans la page support aux utilisateurs de
                                    l'outil TerriSTORY®.
                                </p>
                                <p>
                                    Cette page recense l'ensemble des ressources à
                                    disposition pour faciliter votre utilisation de
                                    l'outil.
                                </p>
                                <p>Bonne navigation !</p>
                                <h3>Tutoriels</h3>
                                <div className="support-tutoriels">
                                    <ul>
                                        <li>
                                            Pour débuter avec l'outil TerriSTORY®
                                            laissez-vous guider par le document{" "}
                                            <a
                                                onClick={(e) =>
                                                    this.suiviConsultation(e)
                                                }
                                                href="https://auvergnerhonealpes.terristory.fr/pdf/auvergne-rhone-alpes/Premiers_pas.pdf"
                                            >
                                                <span className="underline">
                                                    Premiers pas avec l'outil
                                                </span>
                                            </a>{" "}
                                            (PDF)
                                        </li>
                                        <li>
                                            <a href="https://www.youtube.com/watch?v=U0azEEcjoF4">
                                                <span className="underline">
                                                    Présentation - démonstration de
                                                    l'outil
                                                </span>
                                            </a>{" "}
                                            (vidéo 38mn)
                                        </li>
                                        <li>
                                            Pour découvrir toutes les différentes
                                            fonctionnalités de TerriSTORY®, consultez
                                            les tuto démo :
                                            <ul>
                                                <li>
                                                    Découverte de l'interface (
                                                    <a
                                                        onClick={(e) =>
                                                            this.suiviConsultation(
                                                                e,
                                                                "Découverte de l'interface"
                                                            )
                                                        }
                                                        href="https://auvergnerhonealpes.terristory.fr/pdf/auvergne-rhone-alpes/interface.pdf"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        PDF
                                                    </a>
                                                    )
                                                </li>
                                                <li>
                                                    Visualisation d'indicateurs (
                                                    <a
                                                        onClick={(e) =>
                                                            this.suiviConsultation(
                                                                e,
                                                                "Visualisation d'indicateurs"
                                                            )
                                                        }
                                                        href="https://auvergnerhonealpes.terristory.fr/pdf/auvergne-rhone-alpes/indicateurs.pdf"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        PDF
                                                    </a>
                                                    )
                                                </li>
                                                <li>
                                                    Cartographie des équipements (
                                                    <a
                                                        onClick={(e) =>
                                                            this.suiviConsultation(
                                                                e,
                                                                "Cartographie des équipements"
                                                            )
                                                        }
                                                        href="https://auvergnerhonealpes.terristory.fr/pdf/auvergne-rhone-alpes/equipements.pdf"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        PDF
                                                    </a>
                                                    )
                                                </li>
                                                <li>
                                                    Consultation d'analyses
                                                    territoriales (
                                                    <a
                                                        onClick={(e) =>
                                                            this.suiviConsultation(
                                                                e,
                                                                "Consultation d'analyses territoriales"
                                                            )
                                                        }
                                                        href="https://auvergnerhonealpes.terristory.fr/pdf/auvergne-rhone-alpes/analyses-territoriales.pdf"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        PDF
                                                    </a>
                                                    )
                                                </li>
                                                <li>
                                                    Construction de stratégies
                                                    territoriales : plans d'actions et
                                                    impacts (
                                                    <a
                                                        onClick={(e) =>
                                                            this.suiviConsultation(
                                                                e,
                                                                "Construction de stratégies territoriales : plans d'actions et impacts"
                                                            )
                                                        }
                                                        href="https://auvergnerhonealpes.terristory.fr/pdf/auvergne-rhone-alpes/strategies.pdf"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        PDF
                                                    </a>
                                                    )
                                                </li>
                                                <li>
                                                    Consultation et création de tableaux
                                                    de bord (
                                                    <a
                                                        onClick={(e) =>
                                                            this.suiviConsultation(
                                                                e,
                                                                "Consultation et création de tableaux de bord"
                                                            )
                                                        }
                                                        href="https://auvergnerhonealpes.terristory.fr/pdf/auvergne-rhone-alpes/tableaux-de-bord.pdf"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        PDF
                                                    </a>
                                                    )
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            Modules indicateurs :{" "}
                                            <a href="https://www.youtube.com/watch?v=jHZoNmfigjo&amp;t=3s">
                                                Tutoriel affichage des indicateurs
                                            </a>{" "}
                                            (vidéo 5mn)
                                        </li>
                                        <li>
                                            Module synthèse des indicateurs :{" "}
                                            <a href="https://www.youtube.com/watch?v=DLL235y5yds&amp;t=3s">
                                                Tutoriel affichage de la synthèse des
                                                indicateurs territoriaux
                                            </a>{" "}
                                            (vidéo 7 mn)
                                        </li>
                                        <li>
                                            Créer un compte utilisateur
                                            <ul>
                                                <li>
                                                    <a
                                                        onClick={(e) =>
                                                            this.suiviConsultation(e)
                                                        }
                                                        href="https://auvergnerhonealpes.terristory.fr/pdf/auvergne-rhone-alpes/guide_creation_compte_utilisateur.pdf"
                                                    >
                                                        Guide de création d'un compte
                                                        utilisateur
                                                    </a>{" "}
                                                    (PDF)
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            Module stratégie territoriale
                                            <ul>
                                                <li>
                                                    <a
                                                        onClick={(e) =>
                                                            this.suiviConsultation(e)
                                                        }
                                                        href="https://www.youtube.com/watch?v=G1Rd-kBAotA"
                                                    >
                                                        Tutoriel créer un plan d'action
                                                        et en mesurer les impacts
                                                    </a>{" "}
                                                    (vidéo 9 mn)
                                                </li>
                                                <li>
                                                    <a
                                                        onClick={(e) =>
                                                            this.suiviConsultation(e)
                                                        }
                                                        href="https://auvergnerhonealpes.terristory.fr/pdf/auvergne-rhone-alpes/guide_plan_actions_transition_énergétique.pdf"
                                                    >
                                                        Guide utilisateur et
                                                        méthodologie du module stratégie
                                                        territoriale
                                                    </a>{" "}
                                                    (PDF)
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            Module tableaux de bord
                                            <ul>
                                                <li>
                                                    <a
                                                        onClick={(e) =>
                                                            this.suiviConsultation(e)
                                                        }
                                                        href="https://auvergnerhonealpes.terristory.fr/pdf/auvergne-rhone-alpes/creation_tableau_de_bord.pdf"
                                                    >
                                                        Guide de construction d'un
                                                        tableau de bord
                                                    </a>{" "}
                                                    (PDF)
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>

                                <h3>Méthodologies de calcul et sources des données</h3>

                                <ul>
                                    <li>
                                        Pour chaque indicateur, chaque installation,
                                        estimation d'impact, consulter la méthodologie
                                        de calcul et les sources en cliquant sur le{" "}
                                        <img
                                            src="./img/icon_help_small.png"
                                            alt=""
                                        ></img>{" "}
                                        présent sur la page (généralement en haut à
                                        droite).
                                    </li>
                                </ul>

                                <h3>Réponses aux questions </h3>

                                <ul>
                                    <li>
                                        Les réponses aux questions les plus fréquentes
                                        se trouvent dans la{" "}
                                        <a
                                            onClick={(e) => this.suiviConsultation(e)}
                                            href="https://arec-occitanie.terristory.fr/pdf/occitanie/faq.pdf"
                                        >
                                            <span className="underline">
                                                Foire aux questions
                                            </span>
                                        </a>
                                    </li>
                                    <li>
                                        Pour toute autre question,{" "}
                                        <Link to="/contact">
                                            <p>contactez-nous</p>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else if (this.props.parentApi.data.region === "auvergne-rhone-alpes") {
            return (
                <div className="widgets full-screen-widget">
                    <Link
                        className="back-to-map"
                        to={"/" + this.props.parentApi.data.urlPartageable}
                    >
                        <button
                            type="button"
                            className="close close-big"
                            data-dismiss="alert"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </Link>
                    <div className="tout">
                        <div className="support">
                            <div className="img">
                                <img
                                    src="../../img/logo_TerriSTORY_region_aura_small.png"
                                    alt="Logo de TerriSTORY"
                                ></img>
                            </div>
                            <div className="corps">
                                <p>
                                    Bienvenue dans la page support aux utilisateurs de
                                    l'outil TerriSTORY®.
                                </p>
                                <p>
                                    Cette page recense l'ensemble des ressources à
                                    disposition pour faciliter votre utilisation de
                                    l'outil.
                                </p>
                                <p>Bonne navigation !</p>

                                <h3>Tutoriels</h3>
                                <div className="support-tutoriels">
                                    <ul className="liste">
                                        <li>
                                            {" "}
                                            Pour débuter avec l'outil TerriSTORY®
                                            laissez-vous guider par le document{" "}
                                            <a
                                                onClick={(e) =>
                                                    this.suiviConsultation(e)
                                                }
                                                href="../../pdf/auvergne-rhone-alpes/Premiers_pas.pdf"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                Premiers pas avec l'outil
                                            </a>{" "}
                                            (PDF)
                                        </li>

                                        <li>
                                            <a
                                                href="https://www.youtube.com/watch?v=U0azEEcjoF4"
                                                rel="noopener noreferrer"
                                            >
                                                Présentation - démonstration de l'outil{" "}
                                            </a>
                                            (vidéo 38mn)
                                        </li>

                                        <li>
                                            Pour découvrir toutes les différentes
                                            fonctionnalités de TerriSTORY®, consultez
                                            les tuto démo :
                                        </li>
                                        <ul>
                                            <li>
                                                Découverte de l'interface (
                                                <a
                                                    onClick={(e) =>
                                                        this.suiviConsultation(
                                                            e,
                                                            "Découverte de l'interface"
                                                        )
                                                    }
                                                    href="../../pdf/auvergne-rhone-alpes/interface.pdf"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    PDF
                                                </a>
                                                )
                                            </li>
                                            <li>
                                                Visualisation d'indicateurs (
                                                <a
                                                    onClick={(e) =>
                                                        this.suiviConsultation(
                                                            e,
                                                            "Visualisation d'indicateurs"
                                                        )
                                                    }
                                                    href="../../pdf/auvergne-rhone-alpes/indicateurs.pdf"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    PDF
                                                </a>
                                                )
                                            </li>
                                            <li>
                                                Cartographie des équipements (
                                                <a
                                                    onClick={(e) =>
                                                        this.suiviConsultation(
                                                            e,
                                                            "Cartographie des équipements"
                                                        )
                                                    }
                                                    href="../../pdf/auvergne-rhone-alpes/equipements.pdf"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    PDF
                                                </a>
                                                )
                                            </li>
                                            <li>
                                                Consultation d'analyses territoriales (
                                                <a
                                                    onClick={(e) =>
                                                        this.suiviConsultation(
                                                            e,
                                                            "Consultation d'analyses territoriales"
                                                        )
                                                    }
                                                    href="../../pdf/auvergne-rhone-alpes/analyses-territoriales.pdf"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    PDF
                                                </a>
                                                )
                                            </li>
                                            <li>
                                                Construction de stratégies territoriales
                                                : plans d'actions et impacts (
                                                <a
                                                    onClick={(e) =>
                                                        this.suiviConsultation(
                                                            e,
                                                            "Construction de stratégies territoriales : plans d'actions et impacts"
                                                        )
                                                    }
                                                    href="../../pdf/auvergne-rhone-alpes/strategies.pdf"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    PDF
                                                </a>
                                                )
                                            </li>
                                            <li>
                                                Consultation et création de tableaux de
                                                bord (
                                                <a
                                                    onClick={(e) =>
                                                        this.suiviConsultation(
                                                            e,
                                                            "Consultation et création de tableaux de bord"
                                                        )
                                                    }
                                                    href="../../pdf/auvergne-rhone-alpes/tableaux-de-bord.pdf"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    PDF
                                                </a>
                                                )
                                            </li>
                                        </ul>

                                        <li>
                                            {" "}
                                            Module indicateurs :{" "}
                                            <a
                                                href="https://www.youtube.com/watch?v=jHZoNmfigjo&t=3s"
                                                rel="noopener noreferrer"
                                            >
                                                Tutoriel affichage des indicateurs{" "}
                                            </a>{" "}
                                            (vidéo 5mn)
                                        </li>

                                        <li>
                                            {" "}
                                            Module synthèse des indicateurs :{" "}
                                            <a
                                                href="https://www.youtube.com/watch?v=DLL235y5yds&t=3s"
                                                rel="noopener noreferrer"
                                            >
                                                Tutoriel affichage de la synthèse des
                                                indicateurs territoriaux{" "}
                                            </a>
                                            (vidéo 7 mn)
                                        </li>

                                        <li>
                                            {" "}
                                            Créer un compte utilisateur
                                            <ul className="sous-liste">
                                                <li>
                                                    <a
                                                        onClick={(e) =>
                                                            this.suiviConsultation(e)
                                                        }
                                                        href="../../pdf/auvergne-rhone-alpes/guide_creation_compte_utilisateur.pdf"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Guide de création d'un compte
                                                        utilisateur
                                                    </a>{" "}
                                                    (PDF)
                                                </li>
                                            </ul>
                                        </li>

                                        <li>
                                            {" "}
                                            Module stratégie territoriale
                                            <ul className="sous-liste">
                                                <li>
                                                    <a
                                                        href="https://www.youtube.com/watch?v=G1Rd-kBAotA"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Tutoriel créer un plan d'action
                                                        et en mesurer les impacts{" "}
                                                    </a>
                                                    (vidéo 9 mn)
                                                </li>
                                                <li>
                                                    <a
                                                        onClick={(e) =>
                                                            this.suiviConsultation(e)
                                                        }
                                                        href="../../pdf/auvergne-rhone-alpes/guide_plan_actions_transition_énergétique.pdf"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Guide utilisateur et
                                                        méthodologie du module stratégie
                                                        territoriale
                                                    </a>{" "}
                                                    (PDF)
                                                </li>
                                            </ul>
                                        </li>

                                        <li>
                                            {" "}
                                            Module tableaux de bord
                                            <ul className="sous-liste">
                                                <li>
                                                    <a
                                                        onClick={(e) =>
                                                            this.suiviConsultation(e)
                                                        }
                                                        href="../../pdf/auvergne-rhone-alpes/creation_tableau_de_bord.pdf"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Guide de construction d'un
                                                        tableau de bord
                                                    </a>{" "}
                                                    (PDF)
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>

                                <h3>Café démo</h3>
                                <p>
                                    Chaque 1er vendredi du mois, de 13h30 à 14h,
                                    retrouvez l'équipe TerriSTORY® lors d'un café démo
                                    pour découvrir les nouveautés de l'outil, poser vos
                                    questions, échanger avec d'autres utilisateurs,
                                    faire remonter vos besoins,...
                                </p>
                                <p>
                                    <a href="https://us02web.zoom.us/meeting/register/tZIlfuqupjsjGNBWPfKUkkj7hwtRaXi-2O-w">
                                        Inscrivez-vous
                                    </a>{" "}
                                    au prochain café démo (réunion en ligne par Zoom)
                                </p>
                                <h3>Méthodologies de calcul et sources des données</h3>
                                <p>
                                    Pour chaque indicateur, chaque installation,
                                    estimation d'impact, consulter la méthodologie de
                                    calcul et les sources en cliquant sur le{" "}
                                    <img src="./img/icon_help_small.png" alt=""></img>{" "}
                                    présent sur la page (généralement en haut à droite).
                                </p>

                                <h3>Réponses aux questions </h3>
                                <ul className="liste">
                                    <li>
                                        Les réponses aux questions les plus fréquentes
                                        se trouvent dans la
                                        {lien_faq_final}
                                    </li>
                                    <li>
                                        Pour toute autre question,{" "}
                                        <Link to="/contact">
                                            <p>contactez-nous</p>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return "";
        }
    }
}

export default Support;
