import React from "react";

import ErrorPage from "./Components/ErrorPage";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.warn("Error happened");
        console.warn(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <ErrorPage
                    message={
                        "une erreur inconnue est survenue qui a bloqué le fonctionnement normal de la page"
                    }
                />
            );
        }
        return this.props.children;
    }
}
export default ErrorBoundary;
