/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import { Link } from "react-router-dom";
import MDEditor from "@uiw/react-md-editor";

import Api from "../../Controllers/Api.js";
import config from "../../settings.js";
import { consulteAutrePage } from "../../utils.js";
import SEO from "../SEO.js";
import Loading from "../../wrappers/Loading.js";

/**
 * This component is used to to display the legal notices relating to the selected region
 */
class OpenSource extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            changelog: undefined,
            lastVersion: undefined,
            lastVersionDate: undefined,
        };
    }

    componentDidMount() {
        let region = this.props.parentApi.data.region;
        let idUtilisateur =
            this.props.parentApi.data &&
            this.props.parentApi.controller.gestionSuiviConsultations
                ? this.props.parentApi.controller.gestionSuiviConsultations
                      .idUtilisateur
                : 0;
        let url = config.api_consultation_autre_page_url;
        if (region) consulteAutrePage(url, region, idUtilisateur, "Open-Source", "");

        if (this.state.changelog === undefined) {
            Api.callApi(config.changelog_url).then((json) => {
                this.setState({
                    changelog: json["content"],
                    lastVersion: json["last_version"],
                    lastVersionDate: json["last_version_date"],
                });
            });
        }
    }

    render() {
        if (!this.props.parentApi.data.settings) {
            return (
                <Loading
                    isLoaded={
                        this.props.parentApi.data.settings &&
                        Object.values(this.props.parentApi.data.settings).length > 0
                    }
                />
            );
        }
        return (
            <div className="widgets full-screen-widget">
                <SEO
                    settings={this.props.parentApi.data.settings["seo"]}
                    page="open-source"
                />
                {!this.props.hideBackToMap && (
                    <Link
                        className="back-to-map"
                        to={"/" + this.props.parentApi.data.urlPartageable}
                    >
                        <button
                            type="button"
                            className="close close-big"
                            data-dismiss="alert"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </Link>
                )}
                <div className="tout">
                    <div className="img larger-logo">
                        <img
                            src="../../img/logo_TerriSTORY_transparent.png"
                            alt="Logo de TerriSTORY®"
                        ></img>
                    </div>
                    <div className="texte-corps">
                        <br />
                        <br />
                        <h1 className="title-referencement-a-propos">
                            Politique Open-Source
                        </h1>

                        <h3 className="a-propos">Informations générales</h3>
                        <p>
                            TerriSTORY®, initié par Auvergne-Rhône-Alpes Energie
                            Environnement est porté depuis début 2020 par un consortium
                            d'acteurs de niveau régional et national. Tous ces acteurs
                            ayant une mission de service public ou d'intérêt général,
                            ils ont décidé de passer TerriSTORY® en open-source. Cette
                            ouverture a notamment pour objectif de faciliter les
                            évolutions de l'outil, pour répondre de mieux en mieux au
                            besoin des territoires de pilotage de leur trajectoire de
                            transition.
                        </p>

                        <p>
                            Le code source est ainsi rendu disponible sur le site gitlab
                            à l'adresse{" "}
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://gitlab.com/terristory/terristory"
                            >
                                https://gitlab.com/terristory/terristory
                            </a>{" "}
                            sous la licence aGPL-v3 disponible sur{" "}
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://gitlab.com/terristory/terristory/-/blob/master/LICENSE.md"
                            >
                                le dépôt git
                            </a>
                            .
                        </p>

                        <p>
                            Vous pouvez contribuer à faire évoluer TerriSTORY® de
                            plusieurs façons :
                        </p>

                        <p>
                            <ul>
                                <li>
                                    En signalant tout problème, bug, comportement
                                    inhabituel ou non désirable sur{" "}
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href="https://gitlab.com/terristory/terristory/-/issues/new?issuable_template=bug_report"
                                    >
                                        ce lien
                                    </a>
                                </li>
                                <li>
                                    En proposant une piste d'amélioration ou une
                                    nouvelle fonctionnalité sur{" "}
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href="https://gitlab.com/terristory/terristory/-/issues/new?issuable_template=new_feature"
                                    >
                                        ce lien
                                    </a>
                                </li>
                            </ul>
                        </p>

                        <p>
                            Attention, avant de signaler un bug ou de proposer une
                            amélioration, pensez à bien vous assurer qu'un autre ticket
                            n'a pas déjà été créé dans la{" "}
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://gitlab.com/terristory/terristory/-/issues"
                            >
                                base disponible sur gitlab
                            </a>{" "}
                            pour le même problème ou la même idée.
                        </p>

                        <h3 className="a-propos">Si vous êtes développeur</h3>

                        <p>
                            La documentation technique du projet est disponible sur{" "}
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://docs.terristory.fr/"
                            >
                                https://docs.terristory.fr/
                            </a>
                            . Elle inclut notamment :
                        </p>

                        <p>
                            <ul>
                                <li>
                                    le{" "}
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href="https://docs.terristory.fr/installation.html"
                                    >
                                        guide d'installation
                                    </a>
                                </li>
                                <li>
                                    la{" "}
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href="https://docs.terristory.fr/notice_contribution.html"
                                    >
                                        notice de contribution
                                    </a>
                                </li>
                                <li>
                                    la{" "}
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href="https://docs.terristory.fr/architecture_logicielle.html"
                                    >
                                        description technique du système
                                    </a>
                                </li>
                                <li>
                                    quelques{" "}
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href="https://docs.terristory.fr/tools.html"
                                    >
                                        outils pratiques
                                    </a>
                                </li>
                            </ul>
                        </p>

                        <p>
                            Si vous souhaitez soumettre vos modifications ou vos ajouts
                            au projet principal de TerriSTORY®, il vous faudra passer
                            par une <em>fork</em> puis par une{" "}
                            <em>demande de fusion</em> ou <em>pull request</em>. Dans ce
                            cas, vous serez contacté(e) par AURA-EE, propriétaire du
                            code source de la plateforme, pour procéder à la signature :
                            <ul>
                                <li>
                                    d'un document certifiant que vous êtes bien à
                                    l'origine du code et que vous disposez des droits
                                    nécessaires. Ce document, appelé{" "}
                                    <em>Developer Certificate of Origin</em> (DCO), est{" "}
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href="https://gitlab.com/terristory/terristory/-/blob/master/docs/legal/dco.md"
                                    >
                                        accessible à l'adresse suivante
                                    </a>
                                    .
                                </li>
                                <li>
                                    d'un document dans lequel vous cédez la propriété de
                                    votre code au propriétaire de TerriSTORY®, AURA-EE.
                                    Ce document est{" "}
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href="https://gitlab.com/terristory/terristory/-/blob/master/docs/legal/cla.md"
                                    >
                                        accessible à l'adresse suivante
                                    </a>
                                    .
                                </li>
                            </ul>
                        </p>

                        <h1 className="title-referencement-a-propos">
                            Derniers changements
                        </h1>
                        {this.state.lastVersion && (
                            <p>
                                La dernière version est la{" "}
                                <strong>version {this.state.lastVersion}</strong> (voir{" "}
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={
                                        "https://gitlab.com/terristory/terristory/-/tree/prod-" +
                                        this.state.lastVersion
                                    }
                                >
                                    ici pour le code source
                                </a>
                                ) qui date approximativement du{" "}
                                <em>{this.state.lastVersionDate}</em>.
                            </p>
                        )}

                        <div className="markdown-changelog">
                            <MDEditor.Markdown source={this.state.changelog} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default OpenSource;
