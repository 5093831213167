/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import { Link } from "react-router-dom";

import config from "../../settings.js";
import { consulteAutrePage } from "../../utils.js";
import SEO from "../SEO.js";

/**
 * This component is used to manage the 'A propos' page of the application
 */
class RegionalAbout extends React.Component {
    componentDidMount() {
        let region = this.props.parentApi.data.region;
        let idUtilisateur =
            this.props.parentApi.data &&
            this.props.parentApi.controller.gestionSuiviConsultations
                ? this.props.parentApi.controller.gestionSuiviConsultations
                      .idUtilisateur
                : 0;
        let url = config.api_consultation_autre_page_url;
        consulteAutrePage(url, region, idUtilisateur, "À propos", "");
    }

    render() {
        const regionName = this.props.parentApi.data.regionLabel;
        return (
            <div className="widgets full-screen-widget">
                <SEO
                    settings={this.props.parentApi.data.settings["seo"]}
                    page="about"
                />
                <Link
                    className="back-to-map"
                    to={"/" + this.props.parentApi.data.urlPartageable}
                >
                    <button
                        type="button"
                        className="close close-big"
                        data-dismiss="alert"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Link>
                <div className="tout">
                    <div className="img">
                        <img
                            src="../../img/logo_TerriSTORY_region_aura_small.png"
                            alt="Logo de TerriSTORY"
                        ></img>
                    </div>
                    <div className="texte-corps">
                        <br></br>
                        <h1 className="title-referencement-a-propos">
                            Outil de pilotage de la transition écologique en{" "}
                            {regionName} : diagnostic, stratégie et prospective
                            territoriale
                        </h1>
                        <h3 className="a-propos">
                            Un outil web de référence pour les territoires en transition
                        </h3>
                        <p>
                            Outil d’aide à la décision, pédagogique et multithématique
                            (énergie, climat, économie...), TerriSTORY® est co-construit
                            avec les territoires. Il a été créé en 2018, en
                            Auvergne-Rhône-Alpes, à l’initiative de l’agence régionale
                            Auvergne-Rhône-Alpes Énergie Environnement (AURA-EE) et avec
                            le soutien de la Région Auvergne-Rhône-Alpes et de l’ADEME.
                        </p>
                        <p>
                            Grâce à une interface de visualisation dynamique et
                            interactive, TerriSTORY® permet de comprendre son
                            territoire, d’identifier ses potentiels et les leviers
                            d’actions prioritaires. Sur la base de cette analyse,
                            l’outil propose de simuler des scénarios prospectifs en
                            mesurant leurs impacts socio-économiques et environnementaux
                            pour construire une trajectoire territoriale à la hauteur
                            des enjeux.
                        </p>
                        <br></br>
                        <h3 className="a-propos">
                            Une communauté d'acteurs engagés dans un projet d'intérêt
                            général
                        </h3>
                        <p>
                            Depuis 2020, TerriSTORY® est un projet porté par une
                            communauté ouverte d’acteurs publics et d’intérêt général
                            engagés face au défi climatique afin que les données soient
                            gérées comme un bien commun. La signature d'un accord de
                            consortium a officialisé le partenariat entre les différents
                            membres du projet TerriSTORY®. Cette nouvelle gouvernance
                            apporte une réponse concrète aux enjeux d’harmonisation et
                            d’agrégation des objectifs multi-niveaux. Cela permet le
                            déploiement de l’outil TerriSTORY® dans d’autres régions et
                            favorise la mutualisation des moyens pour le développement
                            de nouvelles fonctionnalités et la mise à disposition de
                            données complémentaires pour chacune des régions.
                        </p>
                        <br></br>
                        <h3 className="a-propos">TerriSTORY® Auvergne-Rhône-Alpes</h3>
                        <p>
                            Les partenaires du projet TerriSTORY® en
                            Auvergne-Rhône-Alpes sont Atmo Auvergne-Rhône-Alpes,
                            AURA-EE, Enedis, GRDF, GRTgaz, l’Inria, RTE, Grenoble-Alpes
                            Métropole et Via Sèva. Ce projet est soutenu par la Région
                            Auvergne-Rhône-Alpes, l’ADEME et l’Europe.
                        </p>
                        <br></br>
                        <p>
                            <a
                                href="/pdf/auvergne-rhone-alpes/TerriSTORY_Presentation.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Plaquette de présentation de TerriSTORY®
                            </a>
                        </p>
                        <p>
                            <a
                                href="/pdf/auvergne-rhone-alpes/fiche_regionale_aura.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Présentation de TerriSTORY® Auvergne-Rhône-Alpes
                            </a>
                        </p>
                        <p>
                            <a
                                href="/pdf/auvergne-rhone-alpes/TerriSTORY_Bilan_activite.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Bilan d'activité TerriSTORY®
                            </a>
                        </p>
                        <p>
                            <a
                                href="https://www.youtube.com/watch?v=RHSQ_oS7XDA"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Vidéo de présentation de TerriSTORY®
                            </a>
                        </p>
                    </div>
                    <div className="logos">
                        <div className="logos-soutenus-par">
                            <p>Soutenu par :</p>
                            <div className="images images-soutenu-par">
                                <img
                                    src="/img/logo_aura.png"
                                    className="logo-region"
                                    alt=""
                                ></img>
                                <img src="/img/logo_ademe.png" alt=""></img>
                                <img src="/img/logo_ue.png" alt=""></img>
                            </div>
                        </div>
                        <div className="logos-partenaires">
                            <p>Partenaires :</p>
                            <div className="images-partenaires">
                                <img src="/img/logo_atmo_aura.png" alt=""></img>
                                <img src="/img/logo_aura_ee.png" alt=""></img>
                                <img
                                    src="/img/logo_enedis.png"
                                    alt=""
                                    id="enedis"
                                ></img>
                                <img src="/img/grdf.png" alt=""></img>
                                <img
                                    src="/img/grtgaz.png"
                                    alt=""
                                    className="petite-taille"
                                ></img>
                                <img src="/img/inria.png" alt="" id="inria"></img>
                                <img
                                    src="/img/rte.png"
                                    alt=""
                                    className="petite-taille"
                                ></img>
                                <img
                                    src="/img/logo_grenoble.png"
                                    alt=""
                                    className="petite-taille"
                                ></img>
                                <img
                                    src="/img/logo_viaseva.png"
                                    alt=""
                                    className="petite-taille"
                                ></img>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default RegionalAbout;
