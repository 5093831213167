/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import ReactTable from "react-table-6";

import Api from "../../Controllers/Api";
import config from "../../settings.js";
import { buildRegionUrl, filterCaseInsensitive, exportToCSV } from "../../utils.js";

/**
 * This component allows to edit the template Excel file used to export the results
 * and parameters of territorial strategies.
 */
class PassageTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            passageTableFile: false,
        };
    }

    componentDidMount() {
        this.getInformation();
    }

    /**
     * Get current template file
     * @returns the file from the API
     */
    getInformation() {
        const url = buildRegionUrl(
            config.api_passage_table,
            this.props.parentApi.data.region
        );
        Api.callApi(url, null, "GET").then((response) => {
            this.setState({
                data: response,
            });
        });
    }

    exportTable() {
        exportToCSV(this.state.data, "csv");
    }

    /**
     * Data file to import
     * @param {event} event : event object from input[file] (file selection)
     * @param {tableRow} : current row of indicators array
     */
    onFileUpload = (event) => {
        // update the state
        this.setState({
            passageTableFile: event.target.files[0],
        });
    };

    importTable() {
        if (!this.state.passageTableFile) {
            this.props.parentApi.callbacks.updateMessages(
                "Merci de fournir un fichier csv."
            );
        }
        let r = window.confirm(
            "Attention, cette opération n'est pas réversible. Voulez vous continuer ?"
        );
        if (r !== true) {
            return;
        }
        let formData = new FormData();
        formData.append("file", this.state.passageTableFile);
        let url = buildRegionUrl(
            config.api_passage_table,
            this.props.parentApi.data.region
        );

        Api.callApi(url, formData, "PUT", "default")
            .then((response) => {
                this.setState({ status: response.message });
                this.getInformation();
                this.props.parentApi.callbacks.updateMessages(
                    response.message,
                    "success"
                );
            })
            .catch((e) => this.props.parentApi.callbacks.updateMessages(e.message));
    }

    render() {
        const columns = [
            {
                Header: "Type",
                accessor: "association_type",
            },
            {
                Header: "Clé",
                accessor: "key",
            },
            {
                Header: "Valeur régionale",
                accessor: "match",
            },
            {
                Header: "Confidentialité",
                accessor: "is_confidential",
                Cell: (props) => String(props.original.is_confidential),
            },
            {
                Header: "Unité",
                accessor: "data_unit",
            },
        ];

        return (
            <div className="panel-body user-scenarii">
                <h3>Table de passage</h3>
                <p>
                    <button className="btn btn-info" onClick={() => this.exportTable()}>
                        Exporter les données en csv
                    </button>{" "}
                    <input
                        type="file"
                        onChange={(e) => {
                            this.onFileUpload(e);
                        }}
                    />
                    <button className="btn btn-info" onClick={() => this.importTable()}>
                        Importer sa table de passage en csv
                    </button>
                </p>
                {this.state.data && (
                    <ReactTable
                        data={this.state.data}
                        columns={columns}
                        filterable={true}
                        defaultFilterMethod={(filter, row) =>
                            filterCaseInsensitive(filter, row)
                        }
                        className="-striped"
                        defaultPageSize={100}
                    />
                )}
            </div>
        );
    }
}

export default PassageTable;
