/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import { Radar } from "react-chartjs-2";

import Api from "../../Controllers/Api";
import config from "../../settings.js";
import configData from "../../settings_data.js";
import { buildRegionUrl } from "../../utils.js";

const getLabelIndex = (x, y, pointLabels) => {
    let index = -1;
    // we look at existing labels and check whether one of them is under the mouse
    for (let i = 0; i < pointLabels.length; i++) {
        const { top, right, bottom, left } = pointLabels[i];
        // if so, we return the index
        if (x >= left && x <= right && y >= top && y <= bottom) {
            index = i;
            break;
        }
    }
    // else, returns -1
    return index;
};

/**
 * This component is used to create a radar chart
 */
class DiagrammeRadar extends React.Component {
    constructor(props) {
        super(props);
        const legendChartOptions = {
            display: true,
            position: "left",
            fullWidth: false,
            reverse: false,
            labels: {
                fontColor: configData.chartFontColor,
                fontSize: configData.chartFontSize,
                padding: configData.chartPadding,
                boxWidth: 10,
            },
        };

        this.state = {
            id_analyse: String(props.id_analysis),
            representation: "radar",
            legend: legendChartOptions,
            data: {},
            filtre: undefined,
            id: props.type.categorie,
            thematique: props.thematique,
            code_analyse: props.id,
            donneesRadar: {},
            type: props.type,
            widthDOM: window.innerWidth,
            disabled: false,
            filters: {},
        };

        this.stylesLabel = {};

        // Get data from API
        if (this.props.data) {
            this.state.data = props.data;
            this.state.data.unite = props.data.unit;
        }
    }

    componentDidMount() {
        // Retrieve initial filters
        let filters =
            this.props.filters ??
            this.props.parentApi.controller.analysisManager.initFiltersByCategory(
                parseInt(this.props.id_analysis)
            );
        this.setState({ filters: filters });

        if (!this.props.data) {
            // Get data from API
            this.fetchConfiguration(filters);
        }

        let chartsLegendsDiv = document.getElementsByClassName("charts-legende");
        if (chartsLegendsDiv.length > 0) {
            let widthDiv =
                document.getElementsByClassName("charts-legende")[0].offsetWidth;
            if (
                this.props.parentApi &&
                this.props.parentApi.callbacks &&
                this.props.parentApi.data &&
                this.props.parentApi.data.tailleDiv !== widthDiv &&
                this.props.provenance === "tableau_de_bord_restitue"
            ) {
                this.props.parentApi.callbacks.tailleDiv(widthDiv);
            }
        }
    }

    /**
     * This function updates the data from which we build the radar charts when changing territory
     * @param {object key => value} prevProps : main component properties before territory change via selection tool
     */
    componentDidUpdate(prevProps, prevState) {
        let chartsLegendsDiv = document.getElementsByClassName("charts-legende");
        if (chartsLegendsDiv.length > 0) {
            let widthDiv =
                document.getElementsByClassName("charts-legende")[0].offsetWidth;
            if (!this.state.canvas) {
                let canvas = document.getElementById("canvas");
                if (canvas) {
                    this.setState({
                        canvas: canvas.children[0],
                    });
                }
            }
            if (
                this.props.parentApi.data.tailleDiv !== widthDiv &&
                this.props.provenance === "tableau_de_bord_restitue"
            ) {
                this.props.parentApi.callbacks.tailleDiv(widthDiv);
            }
        }

        let elemHtml = document.getElementsByClassName("liste-modalites-tdb");
        let currentZone = this.props.zoneId ?? this.props.parentApi.data.currentZone;
        let filtreCategorieCourant = this.props.parentApi.data.filtreCategorieCourant;
        let numeroIndicateurCourant = this.props.parentApi.data.numeroIndicateur;
        if (
            this.props.provenance === "tableau_de_bord" ||
            this.props.provenance === "tableau_de_bord_restitue"
        ) {
            this.props.parentApi.controller.dashboardManager.applyBottomMargin(
                elemHtml,
                "diagramme-tdb"
            );
        }
        const werePropsFiltersForced =
            this.props.forcedFilters &&
            JSON.stringify(this.props.forcedFilters) !==
                JSON.stringify(prevProps.forcedFilters);

        if (prevProps.data !== this.props.data && this.props.data) {
            let _data = this.props.data;
            _data.unite = this.props.data.unit;
            this.setState({
                data: _data,
            });
        }

        // we find different reasons to retrieve configuration
        const wasUnitUpdated = prevProps.unit !== this.props.unit;
        const wereFiltersUpdated =
            JSON.stringify(this.state.filters) !== JSON.stringify(prevState.filters);
        const wasZoneUpdated =
            (prevProps.zoneId ?? prevProps.parentApi.data.currentZone) !==
                currentZone && this.state.id_analyse;
        const wasCurrentFilterUpdated =
            filtreCategorieCourant !==
                prevProps.parentApi.data.filtreCategorieCourant &&
            numeroIndicateurCourant === this.props.id;

        if (
            wasUnitUpdated ||
            wereFiltersUpdated ||
            wasZoneUpdated ||
            wasCurrentFilterUpdated
        ) {
            this.fetchConfiguration(this.state.filters);
        } else if (werePropsFiltersForced) {
            this.setState({
                filters: {
                    ...this.state.filters,
                    ...this.props.forcedFilters,
                },
            });
        }
    }

    componentWillUnmount() {
        if (this.dataPromise) {
            this.dataPromise.abort();
            this.props.parentApi.callbacks.updateDataLoaded(true);
        }
    }

    fetchConfiguration(filtres) {
        let pZone =
            "?zone=" + (this.props.zoneType ?? this.props.parentApi.data.zone.zone);
        let pMaille =
            "&maille=" +
            (this.props.zoneMaille ?? this.props.parentApi.data.zone.maille);
        let pZoneId =
            "&zone_id=" + (this.props.zoneId ?? this.props.parentApi.data.currentZone);
        if ((this.props.zoneType ?? this.props.parentApi.data.zone.zone) === "region") {
            pZoneId = "&zone_id=" + this.props.parentApi.data.regionCode;
        }
        let unit = this.props?.unit ? "&unit_id=" + this.props.unit : "";

        let id_analyse = this.state.id_analyse;
        let representation = this.state.representation;
        let pProvenance = "&provenance=" + this.props.provenance;
        let dataSource =
            config.api_analysis_meta_url +
            id_analyse +
            "/graphique/" +
            representation +
            pZone +
            pMaille +
            pZoneId +
            pProvenance +
            unit;
        this.props.parentApi.callbacks.updateDataLoaded(false);

        let body = JSON.stringify(filtres);
        if (this.dataPromise) this.dataPromise.abort();
        this.dataPromise = Api.callApi(
            buildRegionUrl(dataSource, this.props.parentApi.data.region),
            body,
            "POST"
        );
        this.dataPromise
            .then((json) => {
                this.props.parentApi.callbacks.updateDataLoaded(true);
                if (!json) {
                    return;
                }

                if (json["disabled"] && json["disabled"] === true) {
                    this.setState({
                        disabled: true,
                    });
                    return;
                }

                // Set states
                let donneesRadar = undefined;
                if (json.charts) {
                    for (let a in json.charts) {
                        if (json.charts[a].name === this.props.type.categorie) {
                            donneesRadar = json.charts[a];
                        }
                    }
                }
                let donneesRadarFormattees = {};
                let datasets = {};
                if (donneesRadar) {
                    let colorRegion = getComputedStyle(document.body).getPropertyValue(
                        "--" + this.props.parentApi.data.settings.theme
                    );
                    datasets["data"] = donneesRadar.data;
                    datasets["backgroundColor"] = colorRegion.toUpperCase() + "66"; // add transparency to region colors '66': 40%
                    datasets["pointBackgroundColor"] = donneesRadar.colors;
                    datasets["pointHoverBorderColor"] = donneesRadar.colors;
                    donneesRadarFormattees["type"] = "radar";
                    donneesRadarFormattees["titre"] = this.props.type.titre;
                    donneesRadarFormattees["categorie"] = this.props.type.categorie;
                    donneesRadarFormattees["datasets"] = [datasets];
                    donneesRadarFormattees["labels"] = donneesRadar.labels;
                    donneesRadarFormattees["confid"] = json.confid;
                    donneesRadarFormattees["visible"] = true;
                    donneesRadarFormattees["unite"] = json.unite;
                    donneesRadarFormattees["name"] = donneesRadar.name;
                }
                this.setState({
                    data: donneesRadarFormattees,
                });
            })
            .catch((error) => {
                if (error.name === "AbortError") return;
                this.props.parentApi.callbacks.updateDataLoaded(true);
            });
    }

    /**
     * Launches a main component method to add/remove a modality to the filter
     * Triggered when a checkbox associated with a modality is checked
     * @param {chaine de caractère} modalite : modality checked / unchecked
     */
    filtrerSurLegende(modalite, categorie) {
        let filterWithTableName = categorie + "." + modalite;
        // if we do have filters
        if (!this.state.filters) return;

        let _filters = JSON.parse(JSON.stringify(this.state.filters));
        // if we do not have current filter
        if (!_filters[categorie]) return;

        // we first retrieve the filter ID if present inside the filters
        const idIfPresent = _filters[categorie].findIndex(
            (element) => element && element.filtre_categorie === filterWithTableName
        );

        // if we couldn't find it, it means we need to add it
        if (idIfPresent === -1) {
            _filters[categorie].push({
                filtre_categorie: filterWithTableName,
                type: "pie",
            });
        } else {
            // we are going to delete the filter if we can find it
            _filters[categorie].splice(idIfPresent, 1);
        }

        // if the action resulted in some changes between previous filters
        // and new filters => we update the state
        if (JSON.stringify(this.state.filters) !== JSON.stringify(_filters)) {
            if (this.props.mapCallback) {
                this.props.parentApi.callbacks.updateMapFilter(
                    filterWithTableName,
                    this.props.parentApi.data.fluxThreshold,
                    categorie
                );
            }
            this.setState({
                filters: _filters,
            });
            if (this.props.provenance === "tableau_de_bord") {
                // we also callback the updated filters in case of dashboard edition
                this.props.parentApi.data.tableauBordDonnees.donnees[
                    this.props.thematique
                ].indicateurs[this.props.id].filters = _filters;
            }
        }
    }

    render() {
        if (this.state.disabled === true) {
            return (
                <div className="charts-legende">
                    <div className={"confid-chart"}>
                        Cet indicateur n'est pas activé actuellement, veuillez contacter
                        l'administrateur régional.
                    </div>
                </div>
            );
        }

        let data = this.state.data;

        let titre = "";
        if (this.state.data.titre) {
            titre = this.state.data.titre;
        }
        let chart = "";

        /**
         *
         * How confidentiality Works ?
         *  Case A: we remove the interactivity between charts, but we still update the map
         *  Case B: the energy and usage charts are deleted
         *  Case C: the sector and usage charts are deleted
         *  Case D: we delete all the charts
         */
        let obj = this;

        const dataRadar = {
            labels: data?.labels,
            datasets: data?.datasets,
        };

        let options = {
            maintainAspectRatio: false,
            plugins: {
                title: {
                    display: true,
                    text: titre,
                    padding: 25,
                },
                legend: {
                    display: false,
                },
                tooltip: {
                    callbacks: {
                        label: function (tooltipItems) {
                            return (
                                tooltipItems.label +
                                " : " +
                                tooltipItems.formattedValue +
                                " " +
                                data.unite
                            );
                        },
                    },
                },
            },
            scales: {
                r: {
                    pointLabels: {
                        font: {
                            size: 11,
                            family: "Roboto",
                        },
                        /**
                         * Change color depending on filtered or not
                         */
                        color: function (context) {
                            // we check that current object is filtered
                            const currentCat = obj.state.type.categorie;
                            if (obj.state.filters[currentCat]) {
                                // we check if the current label is not filtered
                                if (
                                    obj.state.filters[currentCat].findIndex(
                                        (filter) =>
                                            filter.filtre_categorie ===
                                            currentCat + "." + context.label
                                    ) === -1
                                ) {
                                    return "#CCCCCC";
                                }
                            }
                            return "#666666";
                        },
                    },
                    reverse: this.props.inverserAxeVertical,
                    ticks: {
                        beginAtZero: true,
                        font: {
                            size: 8,
                        },
                        color: "black",
                    },
                    grid: {
                        color: "#AAA",
                    },
                },
                r1: {
                    ticks: {
                        display: false,
                    },
                    grid: {
                        display: false,
                    },
                    angleLines: {
                        display: false,
                    },
                    pointLabels: {
                        font: {
                            size: 0,
                        },
                    },
                },
            },
            /*
             * functions to allow clicking on the labels of the radar
             * source: https://www.youtube.com/watch?v=RKVRqRcDWO0
             */
            onHover: ({ x, y }, activeHover, chart) => {
                const { canvas } = chart;
                // we get the index of the label hovered
                let index = getLabelIndex(x, y, chart.scales.r._pointLabelItems);
                if (index === -1) {
                    canvas.style.cursor = "default";
                } else {
                    canvas.style.cursor = "pointer";
                }
            },
            onClick: ({ x, y }, activeHover, chart) => {
                // we get the index of the label clicked (if a label is clicked)
                let index = getLabelIndex(x, y, chart.scales.r._pointLabelItems);
                if (index === -1) {
                    return;
                }
                const selectedLabel = chart.scales.r._pointLabels[index];
                this.filtrerSurLegende(selectedLabel, this.props.type.categorie);
            },
            devicePixelRatio: configData.chartsDevicePixelRatio,
        };

        if (this.state.data.datasets && data) {
            chart = (
                <div className="block-row" id="canvas">
                    <Radar
                        options={options}
                        data={dataRadar}
                        height={350}
                        width={550}
                    />
                </div>
            );
            // TODO: passage table here?
            if (
                this.state.data.confid.charts === "D" ||
                (this.state.data.confid.charts === "B" &&
                    ["energie", "facture_energie"].includes(
                        this.state.data.categorie
                    )) ||
                (this.state.data.confid.charts === "B" &&
                    ["usage", "facture_usage"].includes(this.state.data.categorie)) ||
                (this.state.data.confid.charts === "C" &&
                    ["secteur", "facture_secteur"].includes(
                        this.state.data.categorie
                    )) ||
                (this.state.data.confid.charts === "C" &&
                    ["usage", "facture_usage"].includes(this.state.data.categorie)) ||
                (this.props.parentApi.data.confidFilteredPieId !== undefined &&
                    this.props.parentApi.data.confidFilteredPieId !== this.state.id) // In case of A confidentiality, if the user has clicked on a chart, we must hide the others
            ) {
                let div = document.getElementsByClassName("diagramme");

                for (let elemHtml of div) {
                    elemHtml.setAttribute(
                        "class",
                        "formulaire-thematique diagramme-confidentiel"
                    );
                }

                // TODO: passage table here?
                let titleChart = "";
                if (["energie", "facture_energie"].includes(this.state.data.name))
                    titleChart = (
                        <label className="title-chart">Par type d'énergie</label>
                    );
                if (["usage", "facture_usage"].includes(this.state.data.name))
                    titleChart = <label className="title-chart">Par usage</label>;
                if (["secteur", "facture_secteur"].includes(this.state.data.name))
                    titleChart = <label className="title-chart">Par secteur</label>;
                chart = (
                    <div className={"confid-chart"}>
                        {titleChart}Données confidentielles
                    </div>
                );
            }

            let elemHtml = document.getElementsByClassName("liste-modalites");
            if (
                this.props.provenance === "tableau_de_bord" ||
                this.props.provenance === "tableau_de_bord_restitue"
            ) {
                this.props.parentApi.controller.dashboardManager.applyBottomMargin(
                    elemHtml
                );
            }
        }

        return <div className="charts-legende">{chart}</div>;
    }
}

export default DiagrammeRadar;
