/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import Api from "../../Controllers/Api";
import config from "../../settings.js";
import { buildRegionUrl } from "../../utils.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-table-6/react-table.css";
import ReactTable from "react-table-6";

/**
 * This component displays a table from which you can delete a data source logo or access the update interface of a data source logo
 * (which is the same as that of adding a data source logo but with the values pre-filled with what has previously saved in the database).
 */
class DataSourceLogoManage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status: "",
        };
    }

    componentDidMount(prevProps, prevState) {
        this.getDataSource();
    }
    /**
     * Compares the old and the new state of the component and launches an update if a difference is found
     * @param {objet clé => valeur} prevProps : We don't use this parameter here, but it corresponds to the old properties
     * @param {objet clé => valeur} prevState : Old state of the component before an event-triggered update
     */
    componentDidUpdate(prevProps, prevState) {
        if (this.state.status !== prevState.status) {
            // If a difference is observed, the method is launched to list the data source logos present in the database
            this.getDataSource();
        }
    }

    /**
     * Get the characteristics of a data source logo (url, logo)
     */
    getDataSource() {
        let url = buildRegionUrl(
            config.api_logo_sources_list,
            this.props.parentApi.data.region
        );
        Api.callApi(url, null, "GET").then((response) => {
            this.setState({
                data: response,
            });
        });
    }

    /**
     * Delete a data source logo
     * @param {objet clé => valeur} propsTableau : object to access the values displayed in the columns of the table
     */
    deleteDataSource(propsTableau) {
        let id = propsTableau.props.original.id;
        let url = buildRegionUrl(
            config.api_delete_logo_sources,
            this.props.parentApi.data.region
        ).replace("#id#", id);
        Api.callApi(url, null, "DELETE").then((response) => {
            this.setState({
                status: response.message,
            });
        });
    }

    displayLogo(propsTableau) {
        let id = propsTableau.props.original.id;
        let extension = propsTableau.props.original.extension;
        return (
            <span style={{ height: "40px", maxWidth: "60px" }}>
                <img
                    id={id}
                    src={
                        "img/logo_source_fiches/" +
                        this.props.parentApi.data.region +
                        "_logo" +
                        id +
                        "." +
                        extension
                    }
                    alt=""
                    style={{ height: "40px", maxWidth: "60px" }}
                />
            </span>
        );
    }

    render() {
        if (
            !this.props.connected ||
            !this.props.userInfos ||
            this.props.userInfos?.profil !== "admin"
        ) {
            return <div>Non accessible.</div>;
        }

        const updateDataSourceLogo = (propsTableau) => {
            let url = propsTableau.props.original.url;
            let name = propsTableau.props.original.name;
            let path_logo = propsTableau.props.original.path_logo;
            let id = propsTableau.props.original.id;
            let dataSourceLogoCourant = {
                id: id,
                name: name,
                url: url,
                path_logo: path_logo,
            };
            this.props.updateTabDataSource("update", dataSourceLogoCourant);
        };

        const columns = [
            {
                Header: "Logo",
                filterable: false,
                Cell: (props) => this.displayLogo({ props }),
            },
            {
                Header: "Nom",
                accessor: "name",
            },
            {
                Header: "URL",
                accessor: "url",
            },
            {
                id: "activate",
                Header: "Action",
                accessor: "actif",
                Cell: (props) => (
                    <div className="actions">
                        <button
                            className={"btn btn-danger"}
                            onClick={() => this.deleteDataSource({ props })}
                        >
                            Supprimer
                        </button>
                        <button
                            className="btn btn-warning"
                            onClick={() => updateDataSourceLogo({ props })}
                        >
                            Mettre à jour
                        </button>
                    </div>
                ),
            },
        ];

        return (
            <div>
                <div className="panel-body user-scenarii">
                    <h3 className="panel-title pull-left">Gestion des logos</h3>
                    {/* Data contained in this.state.data are automatically browsed with the method associated with the Cell key in columns */}
                    <ReactTable
                        data={this.state.data}
                        columns={columns}
                        rowKey="id"
                        className="-striped"
                        defaultPageSize={30}
                        filterable={true}
                    />
                </div>
            </div>
        );
    }
}

export default DataSourceLogoManage;
