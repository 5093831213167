/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import DetailsPopup from "../DetailsPopup";
import Api from "../../Controllers/Api";
import config from "../../settings";
import { ZoneSelect } from "../SelectionObjet";
import { buildRegionUrl } from "../../utils.js";

class UserEdition extends React.Component {
    constructor(props) {
        super(props);

        let territoire_predilection =
            props.parentApi.controller.authManager.userInfos?.territoire_predilection;
        if (props.user?.territoire_predilection !== undefined) {
            territoire_predilection = JSON.parse(props.user.territoire_predilection);
        }
        this.state = {
            territoryInChoice: {
                zoneType: territoire_predilection?.zoneType,
                zoneMaille: territoire_predilection?.zoneMaille,
                zoneId: territoire_predilection?.zoneId,
            },
        };
    }

    validate = async (event) => {
        event.preventDefault();
        const form = event.target.elements;

        let login_connected = this.props.parentApi.controller.authManager.connected
            ? this.props.parentApi.controller.authManager.userInfos.id
            : undefined;

        const userInfos = {
            login: this.props.user?.mail ?? login_connected,
            prenom: form["prenom"].value,
            nom: form["nom"].value,
            organisation: form["organisation"].value,
            fonction: form["fonction"].value,
            territoire_predilection: JSON.stringify({
                zoneType: this.state.territoryInChoice.zoneType,
                zoneMaille: this.state.territoryInChoice.zoneMaille,
                zoneId: this.state.territoryInChoice.zoneId,
            }),
            utiliser_territoire_predilection: form["use_prefered_territory"].checked,
            code_postal: form["code_postal"].value,
            publication: form["can_publish"]?.checked,
        };

        await Api.callApi(
            buildRegionUrl(config.user_update_url, this.props.parentApi.data.region),
            JSON.stringify(userInfos),
            "PUT"
        );

        // we update main authmanager data set
        this.props.parentApi.controller.authManager.userInfos = {
            ...this.props.parentApi.controller.authManager.userInfos,
            prenom: form["prenom"].value,
            nom: form["nom"].value,
            organisation: form["organisation"].value,
            fonction: form["fonction"].value,
            territoire_predilection: {
                zoneType: this.state.territoryInChoice.zoneType,
                zoneMaille: this.state.territoryInChoice.zoneMaille,
                zoneId: this.state.territoryInChoice.zoneId,
            },
            utiliser_territoire_predilection: form["use_prefered_territory"].checked,
            code_postal: form["code_postal"].value,
        };

        this.setState({
            territoryInChoice: {
                zone: {
                    zone: this.state.territoryInChoice.zoneType,
                    maille: this.state.territoryInChoice.zoneMaille,
                },
                zoneId: this.state.territoryInChoice.zoneId,
            },
        });

        if (this.props.onClose) this.props.onClose();
    };

    updateTerritoryChoice(territory) {
        this.setState({
            territoryInChoice: territory,
        });
    }

    renderTerritorySelect(userInfos) {
        let territoire_predilection = userInfos?.territoire_predilection;
        if (
            typeof territoire_predilection === "string" ||
            territoire_predilection instanceof String
        ) {
            territoire_predilection = JSON.parse(territoire_predilection);
        }
        return (
            <div className="d-inline-block">
                <ZoneSelect
                    parentApi={this.props.parentApi}
                    zoneType={territoire_predilection?.zoneType}
                    zoneMaille={territoire_predilection?.zoneMaille}
                    zoneId={territoire_predilection?.zoneId}
                    onSelect={(territory) => this.updateTerritoryChoice(territory)}
                    title=""
                />
            </div>
        );
    }

    render() {
        let userInfos = this.props.parentApi.controller.authManager.userInfos;
        if (this.props.user !== undefined) {
            userInfos = {
                ...this.props.user,
                id: this.props.user.mail,
            };
        }

        return (
            <DetailsPopup
                title={"Modifier l'utilisateur : " + userInfos.id}
                show={true}
                callbackAfterClosing={() => {
                    if (this.props.onClose) this.props.onClose();
                }}
                content={
                    <form onSubmit={this.validate} className="user-edition-content">
                        {[
                            ["prenom", "Prénom"],
                            ["nom", "Nom"],
                            ["organisation", "Organisation"],
                            ["fonction", "Fonction"],
                        ].map(([id, label]) => (
                            <div className="form-group" key={id}>
                                <label className="strong" htmlFor={id}>
                                    {label} :
                                </label>{" "}
                                <input
                                    id={id}
                                    name={id}
                                    type="text"
                                    defaultValue={userInfos[id]}
                                    required
                                />
                            </div>
                        ))}
                        <div className="form-group">
                            <label className="strong" htmlFor="code_postal">
                                Code postal :
                            </label>{" "}
                            <input
                                id="code_postal"
                                name="code_postal"
                                type="text"
                                defaultValue={userInfos.code_postal}
                                pattern="[0-9]{5}"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label className="strong" htmlFor="territory">
                                Territoire :
                            </label>{" "}
                            {this.renderTerritorySelect(userInfos)}
                        </div>
                        <div className="form-group">
                            <input
                                id="use_prefered_territory"
                                name="use_prefered_territory"
                                type="checkbox"
                                defaultChecked={
                                    userInfos.utiliser_territoire_predilection
                                }
                            />{" "}
                            <label className="strong" htmlFor="use_prefered_territory">
                                Sélectionner ce territoire automatiquement lors de la
                                connexion
                            </label>
                        </div>
                        {this.props.mode === "admin" && (
                            <div className="form-group">
                                <input
                                    id="can_publish"
                                    name="can_publish"
                                    type="checkbox"
                                    defaultChecked={userInfos.publication}
                                />{" "}
                                <label className="strong" htmlFor="can_publish">
                                    Cet utilisateur peut publier une stratégie
                                    territoriale
                                </label>
                            </div>
                        )}
                        <div>
                            <input
                                type="submit"
                                className="btn btn-success"
                                value="Valider"
                            />
                        </div>
                    </form>
                }
            />
        );
    }
}

export default UserEdition;
