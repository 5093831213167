/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React, { useState } from "react";
import { Line, Bar } from "react-chartjs-2";
import pattern from "patternomaly";
import chroma from "chroma-js";

import { slugify } from "../../utils";
import configData from "../../settings_data.js";
import { NewDetailsPopup as DetailsPopup } from "../DetailsPopup.js";

const impactsColors = {
    jobs: {
        territory: configData.planActionsColorEmploiTerritoire,
        region: configData.planActionsColorEmploiRegion,
    },
    valueAdded: {
        territory: configData.planActionsColorVAGTerritoire,
        region: configData.planActionsColorVAGRegion,
    },
    energyBill: configData.planActionsFactureEnergetique,
    investments: configData.planActionsInvestissement,
    taxRevenue: {
        commune: configData.planActionsRetombeesFiscalesCommune,
        epci: configData.planActionsRetombeesFiscalesEPCI,
        departement: configData.planActionsRetombeesFiscalesDepartement,
        region: configData.planActionsRetombeesFiscalesRegion,
    },
};

export default function Impacts({
    strategy,
    actionsMeta,
    activeActions,
    setActiveActions,
    computeImpacts,
    trajectoriesMeta,
    refTrajectories,
    trajectories,
    impacts,
    isLoadingImpacts,
    impactsSettings,
    zoneName,
    actionsHaveChanged,
    actionErrors,
}) {
    const [checkedOptions, setCheckedOptions] = useState({});
    const [shownImpact, setShownImpact] = useState(null);

    const actionCategories = [
        ...new Set(actionsMeta.map(({ categorie }) => categorie)),
    ];
    function renderActionsCategory(category) {
        const actions = actionsMeta.filter(({ categorie }) => categorie === category);
        const filledActions = actions.filter(
            ({ numero }) => activeActions[numero] !== undefined
        );
        const isEveryActionActive = filledActions.every(
            ({ numero }) => activeActions[numero]
        );
        const isSomeActionActive = filledActions.some(
            ({ numero }) => activeActions[numero]
        );

        const checkAllActions = (active) =>
            setActiveActions((oldValue) => ({
                ...oldValue,
                ...Object.fromEntries(
                    filledActions.map(({ numero }) => [numero, active])
                ),
            }));

        return (
            <div key={category}>
                <div>
                    <input
                        type="checkbox"
                        id={"activate-category-" + slugify(category)}
                        onChange={(event) => checkAllActions(event.target.checked)}
                        disabled={filledActions.length === 0}
                        checked={isSomeActionActive}
                        ref={(el) =>
                            el &&
                            (el.indeterminate =
                                isSomeActionActive && !isEveryActionActive)
                        }
                    />{" "}
                    <label htmlFor={"activate-category-" + slugify(category)}>
                        {category} (
                        {
                            Object.values(filledActions).filter(
                                ({ numero }) => activeActions[numero]
                            ).length
                        }
                        /{Object.keys(filledActions).length})
                    </label>
                </div>
                <div style={{ paddingLeft: 16 }}>
                    {actions.map((action) => (
                        <div key={action.numero}>
                            <input
                                type="checkbox"
                                checked={activeActions[action.numero]}
                                id={"activate-action-" + action.numero}
                                disabled={activeActions[action.numero] === undefined}
                                onChange={(event) =>
                                    setActiveActions((oldActions) => ({
                                        ...oldActions,
                                        [action.numero]: event.target.checked,
                                    }))
                                }
                            />{" "}
                            <label htmlFor={"activate-action-" + action.numero}>
                                {action.name}
                                {activeActions[action.numero] === undefined
                                    ? " (non rempli)"
                                    : null}
                            </label>
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    function isAnyImpactEnabled(key) {
        return impactsSettings.some(
            (setting) =>
                (setting.type === key || setting.type.startsWith(key + "-")) &&
                setting.enabled
        );
    }
    function isImpactEnabled(key, category) {
        return impactsSettings.some(
            (setting) =>
                (setting.type === key || setting.type === `${key}-${category}`) &&
                setting.enabled
        );
    }

    function renderImpactsForAllCategories(trajId) {
        let meta = trajectoriesMeta.find(({ id }) => id === trajId);
        if (meta) {
            const charts = [];
            for (const [category_id, category] of Object.entries(impacts[meta.id])) {
                if (!(category instanceof Array)) continue;
                if (!isImpactEnabled(meta.id, category_id)) continue;

                charts.push(
                    <Impact
                        key={category_id}
                        strategy={strategy}
                        meta={meta}
                        category={category_id}
                        impactsSettings={impactsSettings}
                        refTrajectories={refTrajectories?.[meta.id]}
                        userTrajectory={trajectories?.[meta.id]}
                        impacts={category}
                    />
                );
            }
            if (charts.length === 0) return null;
            return charts;
        }
        meta = socioEconomicMeta.find(({ id }) => id === trajId);
        if (meta) {
            return (
                <SocioEconomicImpact
                    key={meta.id}
                    meta={meta}
                    impacts={seDatasets?.[meta.id]}
                    impactsSettings={impactsSettings}
                    setCheckedOptions={setCheckedOptions}
                />
            );
        }
        throw Error(`Trajectory id ${trajId} was not found`);
    }

    const socioEconomicMeta = [
        {
            id: "jobs",
            name: "Nombre d'emplois générés",
            totalInTooltip: true,
            hasDirectIndirectFilter: true,
        },
        {
            id: "valueAdded",
            name: "Valeur ajoutée générée",
            unit: "millions d'euros",
            totalInTooltip: true,
            hasDirectIndirectFilter: true,
        },
        { id: "energyBill", name: "Facture énergétique", unit: "milliers d'euros" },
        {
            id: "taxRevenue",
            name: "Retombées fiscales",
            unit: "milliers d'euros",
            totalInTooltip: true,
        },
        { id: "investments", name: "Investissement", unit: "millions d'euros" },
    ];

    const seDatasets = parseSocioEconomicData(impacts, zoneName, checkedOptions);

    const filledActionsNb = actionsMeta.filter(
        ({ numero }) => activeActions[numero] !== undefined
    ).length;
    const activeActionsNb = actionsMeta.filter(
        ({ numero }) => activeActions[numero] === true
    ).length;

    function renderImpactCard(meta) {
        if (!strategy || !impacts?.[meta.id] || impacts[meta.id].is_empty) {
            return null;
        }
        return (
            <ImpactCard
                strategy={strategy}
                key={meta.id}
                impacts={impacts[meta.id]}
                meta={meta}
                onClick={() => setShownImpact(meta.id)}
            />
        );
    }
    // TODO: find a better way to distinguish between pollutants and other trajs ?
    const energyClimateImpacts = trajectoriesMeta
        .filter((meta) => isAnyImpactEnabled(meta.id) && meta.unit !== "t")
        .map(renderImpactCard);
    const pollutantsImpacts = trajectoriesMeta
        .filter((meta) => isAnyImpactEnabled(meta.id) && meta.unit === "t")
        .map(renderImpactCard);

    const socioEcoImpacts = socioEconomicMeta
        .filter((meta) => isAnyImpactEnabled(meta.id))
        .map((meta) => {
            if (!strategy || !seDatasets?.[meta.id]) {
                return null;
            }
            return (
                <button
                    className="tscard"
                    onClick={() => setShownImpact(meta.id)}
                    key={meta.id}
                >
                    <h4>{meta.name}</h4>
                    {/* TODO: short impacts */}
                    <div className="tsbtn full-width">Détails</div>
                </button>
            );
        });

    const actionsThatHaveAnError = actionsMeta.filter(
        ({ numero }) => actionErrors[numero] !== undefined
    );

    return (
        <div>
            <div className="alert alert-info right-info-card">
                <b>Point d’attention :</b> par souci de simplification et de lisibilité,
                le plan d'actions TerriSTORY n'intègre pas d'éléments de prospective.
                Par conséquent, une action dans TerriSTORY enregistrera le même impact
                qu'elle soit mise en place en 2020 ou en 2050 (même si des évolutions en
                termes de performance et de coût sont en réalité probables entre ces 2
                dates).
            </div>
            <p>Ici, vous visualisez l’impact de vos actions sur votre territoire.</p>
            {/* TODO: sources */}
            <details className="tsdetails">
                <summary>
                    Actions activées ({activeActionsNb}/{filledActionsNb})
                </summary>
                <div>{actionCategories.map((cat) => renderActionsCategory(cat))}</div>
            </details>
            {actionsThatHaveAnError.length > 0 ? (
                <div className="alert alert-danger info-card">
                    Des actions sont erronées :{" "}
                    {actionsThatHaveAnError.map(({ name }) => (
                        <>{name}</>
                    ))}
                </div>
            ) : actionsHaveChanged && impacts && !isLoadingImpacts ? (
                <div className="alert alert-warning info-card">
                    Des actions ont changé. Les impacts affichés sont ceux du dernier
                    calcul
                </div>
            ) : null}
            <div>
                <button
                    className="tsbtn info big impacts-button"
                    disabled={isLoadingImpacts || actionsThatHaveAnError.length > 0}
                    onClick={computeImpacts}
                >
                    Calculer les impacts
                </button>
            </div>
            {isLoadingImpacts && <div className="loader" />}
            {energyClimateImpacts.length > 0 && (
                <>
                    <h3 className="tstitle2">Impacts énergie-climat</h3>
                    {!impacts || isLoadingImpacts ? (
                        <p>Pas encore d'impacts calculés</p>
                    ) : (
                        <div className="tscards-container">{energyClimateImpacts}</div>
                    )}
                </>
            )}
            {socioEcoImpacts.length > 0 && (
                <>
                    <h3 className="tstitle2">Impacts socio-économiques</h3>
                    {!impacts || isLoadingImpacts ? (
                        <p>Pas encore d'impacts calculés</p>
                    ) : (
                        <div className="tscards-container">{socioEcoImpacts}</div>
                    )}
                </>
            )}
            {pollutantsImpacts.length > 0 && (
                <>
                    <h3 className="tstitle2">Impacts qualité de l'air</h3>
                    {!impacts || isLoadingImpacts ? (
                        <p>Pas encore d'impacts calculés</p>
                    ) : (
                        <div className="tscards-container">{pollutantsImpacts}</div>
                    )}
                </>
            )}
            {energyClimateImpacts.length === 0 &&
                pollutantsImpacts.length === 0 &&
                socioEcoImpacts === 0 && (
                    <div>Aucun impact n'a été activé pour l'instant !</div>
                )}
            {shownImpact !== null && (
                <DetailsPopup
                    isOpen
                    onClose={() => setShownImpact(null)}
                    className="large"
                >
                    {renderImpactsForAllCategories(shownImpact)}
                </DetailsPopup>
            )}
        </div>
    );
}

function ImpactCard({ strategy, meta, impacts, onClick }) {
    const confid_parts = Object.entries(impacts).filter(
        ([k, v]) => v === "Confidentiel"
    );

    impacts = Object.values(impacts).find((v) => v instanceof Array);
    if (!impacts) {
        if (confid_parts.length > 0) {
            return (
                <button className="tscard" disabled>
                    <h4>{meta.name}</h4>
                    <p>Impacts confidentiels</p>
                </button>
            );
        } else {
            return (
                <button className="tscard" disabled>
                    <h4>{meta.name}</h4>
                    <p>Aucun impact disponible</p>
                </button>
            );
        }
    }

    const { referenceYear } = strategy;
    const lastYear = Math.max(...impacts[0].data.map(({ annee }) => annee));
    function valueAtYear(year) {
        return impacts
            .map(({ data }) => data.find(({ annee }) => annee === year)?.valeur ?? 0)
            .reduce((a, b) => a + b, 0);
    }
    let referenceValue = valueAtYear(referenceYear);
    let lastValue = valueAtYear(lastYear);
    let variation = (lastValue / referenceValue - 1) * 100;
    referenceValue = referenceValue.toLocaleString(undefined, {
        maximumSignificantDigits: 4,
    });
    lastValue = lastValue.toLocaleString(undefined, {
        maximumSignificantDigits: 4,
    });
    variation = variation.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        signDisplay: "exceptZero",
    });

    return (
        <button className="tscard" onClick={onClick}>
            <h4>{meta.name}</h4>
            <p>
                <label>Valeur de référence {referenceYear} :</label> {referenceValue} 
                {meta.unit}
                <br />
                <label>Estimation à {lastYear} :</label> {lastValue} {meta.unit} (
                {variation} %)
            </p>
            <div className="tsbtn full-width">Détails</div>
        </button>
    );
}

function Impact({
    strategy,
    meta,
    category,
    refTrajectories,
    userTrajectory,
    impactsSettings,
    impacts,
}) {
    const { referenceYear } = strategy;

    const chartData = { datasets: [] };
    const annotations = [];

    let yMin = 0,
        yMax = 0;

    if (refTrajectories?.historical_data?.length) {
        annotations.push({
            type: "line",
            mode: "vertical",
            scaleID: "x",
            value: new Date(referenceYear + "-01-01"),
            borderColor: "red",
            label: {
                position: "end",
                xAdjust: -50,
                backgroundColor: "rgba(0,0,0,0.7)",
                content: "Historique",
                enabled: true,
            },
        });
        annotations.push({
            type: "line",
            mode: "vertical",
            scaleID: "x",
            value: new Date(referenceYear + "-01-01"),
            borderColor: "red",
            label: {
                position: "end",
                xAdjust: 50,
                backgroundColor: "rgba(0,0,0,0.7)",
                content: "Projection",
                enabled: true,
            },
        });
    }

    if (impacts) {
        const yearsList = impacts[0].data.map(({ annee }) => annee);

        const dataSet = yearsList.map((year) => ({
            x: new Date(year + "-01-01"),
            // Sum all categories corresponding to current year to get total value
            y: impacts
                .map(
                    ({ data }) => data.find(({ annee }) => annee === year)?.valeur ?? 0
                )
                .reduce((a, b) => a + b, 0),
        }));

        chartData.datasets.push({
            borderColor: "#000",
            backgroundColor: "#000",
            pointStyle: "circle",
            label: "Trajectoire calculée",
            yAxisID: "y-axis-trajectories",
            pointRadius: 3,
            borderWidth: 2,
            order: 2,
            data: dataSet,
            labels: yearsList,
        });

        if (impacts !== "Confidentiel") {
            for (let catImpact of impacts) {
                // confidential
                const backgroundColor =
                    catImpact.confidentiel === "oui"
                        ? pattern.draw("diagonal-right-left", "#FF0000")
                        : chroma(catImpact.couleur).alpha(1).css();
                const borderColor =
                    catImpact.confidentiel === "oui"
                        ? chroma("#FF0000").darken().alpha(1).css()
                        : chroma(catImpact.couleur).darken().alpha(1).css();

                let dataset = {
                    backgroundColor: backgroundColor,
                    borderColor: borderColor,
                    label: catImpact === "Confidentiel" ? catImpact : catImpact.nom,
                    yAxisID: "y-axis-main",
                    order: 3,
                    fill: { target: "origin", above: backgroundColor },
                    data: catImpact.data.map((d) => ({
                        x: new Date(d.annee + "-01-01"),
                        y: d.valeur,
                    })),
                };

                chartData.datasets.push(dataset);
            }
        }
    }

    if (
        userTrajectory?.annees_valeurs &&
        Object.keys(userTrajectory.annees_valeurs).length
    ) {
        const { annees_valeurs, annees_modifiees } = userTrajectory;

        const dataSet = Object.entries(annees_valeurs).map(([year, value]) => ({
            x: new Date(year + "-01-01"),
            y: value,
        }));
        chartData.datasets.push({
            borderColor: "#666",
            backgroundColor: "#666",
            pointStyle: "circle",
            label: "Trajectoire cible",
            pointRadius: Object.values(annees_modifiees).map((m) => (m ? 3 : 1)),
            yAxisID: "y-axis-trajectories",
            borderDash: [5, 5],
            borderWidth: 2,
            order: 0,
            data: dataSet,
            labels: Object.keys(annees_valeurs),
        });
    }

    if (refTrajectories?.pcaet_trajectory?.length) {
        const yearsList = refTrajectories.pcaet_trajectory[0].data.map(
            ({ annee }) => annee
        );
        const dataSet = yearsList.map((year) => ({
            x: new Date(year + "-01-01"),
            // Sum all categories corresponding to current year to get total value
            y: refTrajectories.pcaet_trajectory
                .map(
                    ({ data }) => data.find(({ annee }) => annee === year)?.valeur ?? 0
                )
                .reduce((a, b) => a + b, 0),
        }));

        chartData.datasets.push({
            borderColor: "#0dcaf0",
            backgroundColor: "#0dcaf0",
            pointStyle: "circle",
            label: "Trajectoire PCAET",
            borderDash: [5, 6],
            pointRadius: 3,
            yAxisID: "y-axis-trajectories",
            borderWidth: 2,
            order: -1,
            data: dataSet,
            labels: yearsList,
        });
    }

    if (refTrajectories?.supra_goals) {
        for (const supraGoal of refTrajectories.supra_goals) {
            const dataSet = [
                {
                    x: new Date(supraGoal.annee_reference + "-01-01"),
                    y: supraGoal.valeur_reference,
                },
            ];
            dataSet.push(
                ...supraGoal.valeurs_annees.map(({ annee, valeur }) => ({
                    x: new Date(parseInt(annee, 10) + "-01-01"),
                    y: parseFloat(supraGoal.valeur_reference * (1 + valeur / 100)),
                }))
            );
            chartData.datasets.push({
                borderColor: supraGoal.couleur,
                backgroundColor: supraGoal.couleur,
                pointStyle: "circle",
                label: supraGoal.titre,
                yAxisID: "y-axis-trajectories",
                borderDash: [5, 5],
                pointRadius: 1,
                borderWidth: 2,
                order: -2,
                data: dataSet,
                labels: supraGoal.valeurs_annees.map(({ annee }) => annee),
            });
        }
    }

    chartData.datasets?.forEach((dataset) => {
        yMax = Math.max(yMax, ...dataset.data.map((d) => d.y));
    });
    yMax *= 1.1;

    const article = ["consommation_ener", "enr_production"].includes(meta.id)
        ? "la"
        : "les";
    const chartOptions = {
        animation: false,
        scales: {
            x: {
                type: "time",
                title: {
                    text: "Années",
                    font: { size: 16 },
                },
                time: { unit: "year" },
                ticks: {
                    minRotation: 65,
                    source: "data",
                },
            },
            "y-axis-main": {
                type: "linear",
                min: yMin,
                max: yMax,
                stacked: true,
                display: true,
                position: "left",
                scaleLabel: {
                    display: true,
                    labelString: "Valeurs",
                },
                ticks: {
                    beginAtZero: true,
                },
            },
            "y-axis-trajectories": {
                type: "linear",
                min: yMin,
                max: yMax,
                display: false,
                position: "right",
                gridLines: {
                    drawOnChartArea: false,
                },
                ticks: {
                    beginAtZero: true,
                },
            },
        },
        plugins: {
            title: {
                display: true,
                text: `Impacts sur ${article} ${meta.name} par ${category} (${meta.unit})`,
                font: { size: 16 },
            },
            legend: {
                position: "bottom",
                labels: {
                    font: { size: 16 },
                    usePointStyle: true,
                },
                reverse: true,
            },
            tooltip: {
                reverse: true,
                mode: "x",
                intersect: false,
                callbacks: {
                    title: function (data) {
                        return new Date(data[0].label).getFullYear();
                    },
                    label: function (data) {
                        return (
                            data.dataset.label +
                            " : " +
                            data.raw.y.toLocaleString(undefined, {
                                maximumSignificantDigits: 4,
                            }) +
                            " " +
                            meta.unit
                        );
                    },
                },
            },
            annotation: { annotations },
        },
        width: 1200,
        height: 600,
    };
    if (!chartData.datasets?.length) return <p>Chargement en cours...</p>;
    return (
        <div className="trajectory" style={{ minWidth: "1080px" }}>
            <Line
                data={chartData}
                options={chartOptions}
                width={chartOptions.width}
                height={chartOptions.height}
            />
        </div>
    );
}

function SocioEconomicImpact({ meta, impacts, impactsSettings, setCheckedOptions }) {
    function setCheckedOption(newOptions) {
        setCheckedOptions((prevState) => ({
            ...prevState,
            [meta.id]: { ...prevState[meta.id], ...newOptions },
        }));
    }

    const chartOptions = {
        maintainAspectRatio: false,
        responsive: false,
        plugins: {
            title: {
                display: true,
                padding: 10,
            },
            legend: {
                reverse: true,
                position: "top",
                labels: {
                    boxWidth: 10,
                },
            },
        },
        width: 400,
        height: 400,
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: false,
                ticks: {
                    beginAtZero: true,
                },
            },
        },
    };
    chartOptions.plugins.title.text =
        meta.name + (meta.unit ? ` (en ${meta.unit})` : "");
    chartOptions.scales.y.stacked = true;
    chartOptions.plugins.tooltip = {
        callbacks: {
            label: function (tooltipItem) {
                let soloValue =
                    tooltipItem.dataset.label +
                    " : " +
                    tooltipItem.raw.toLocaleString("fr-Fr", {
                        maximumSignificantDigits: 4,
                    });
                if (!meta.totalInTooltip) {
                    return soloValue;
                }
                let agregateValue = 0;

                for (let ds in tooltipItem.parsed._stacks.y) {
                    agregateValue += tooltipItem.parsed._stacks.y[ds];
                }
                return (
                    soloValue +
                    "   Total : " +
                    agregateValue.toLocaleString("fr-Fr", {
                        maximumSignificantDigits: 4,
                    })
                );
            },
        },
    };

    if (!impacts.datasets?.length) return <p>Chargement en cours...</p>;

    return (
        <div
            className={
                "trajectory" + (meta.hasDirectIndirectFilter ? " selection-fields" : "")
            }
        >
            <Bar
                data={impacts}
                options={chartOptions}
                width={chartOptions.width}
                height={chartOptions.height}
            />
            {meta.hasDirectIndirectFilter && (
                <ul>
                    <li>
                        <label>
                            <input
                                type="checkbox"
                                defaultChecked={true}
                                onChange={(e) =>
                                    setCheckedOption({ direct: e.target.checked })
                                }
                            />{" "}
                            Impact direct
                        </label>
                    </li>
                    <li>
                        <label>
                            <input
                                type="checkbox"
                                defaultChecked={true}
                                onChange={(e) =>
                                    setCheckedOption({
                                        indirect: e.target.checked,
                                    })
                                }
                            />{" "}
                            Impact indirect
                        </label>
                    </li>
                </ul>
            )}
        </div>
    );
}

function parseSingleDataset(dataset, label, backgroundColor) {
    const years = Object.keys(dataset);
    return {
        labels: years,
        datasets: [
            {
                label,
                backgroundColor,
                data: years.map((year) => dataset[year]),
            },
        ],
    };
}

function parseSocioEconomicData(data, zoneName, checkedOptions = {}) {
    if (!data) return false;

    const twoSidesIndicators = [
        { id: "jobs", key: "nb_emploi_total", label: "Emplois" },
        { id: "valueAdded", key: "va_totale", label: "Valeur ajoutée" },
    ];

    const rawDatasets = {
        jobs: {
            region: {},
        },
        valueAdded: {
            region: {},
        },
        investments: {},
        energyBill: data?.facture_energetique,
        taxRevenue: {
            region: {},
        },
    };

    for (const [year, results] of Object.entries(data.impact_emplois ?? {})) {
        const { investissement, direct } = results;
        const territory = Object.keys(direct).find((v) => v !== "region");
        const territories = ["region"] + territory ? [territory] : [];

        rawDatasets.investments[year] = parseFloat(investissement);

        twoSidesIndicators.forEach(({ key, id }) => {
            if (territory) {
                rawDatasets[id]["territory"] = rawDatasets[id]["territory"] ?? {};
            }
            territories.forEach((level) => {
                if (!territory) {
                    return;
                }
                ["direct", "indirect"].forEach((element) => {
                    if (!(checkedOptions?.[id]?.[element] ?? true)) {
                        return;
                    }
                    // we replace whatever was the "other" level by "territory"
                    const newLevel = level === "region" ? level : "territory";
                    rawDatasets[id][newLevel] = rawDatasets[id][newLevel] ?? {};
                    rawDatasets[id][newLevel][year] =
                        (rawDatasets[id][newLevel][year] ?? 0) +
                        parseFloat(results[element][level]["ponctuel"][key]) +
                        parseFloat(results[element][level]["perenne"][key]);
                });
            });
        });
    }

    for (const [year, results] of Object.entries(data.retombees_fiscales ?? {})) {
        for (const [level, r] of Object.entries(results ?? {})) {
            rawDatasets.taxRevenue[level] = rawDatasets.taxRevenue[level] ?? {};
            rawDatasets.taxRevenue[level][year] = parseFloat(r);
        }
    }

    // parse data to create compatible datasets for chartjs
    const seDatasets = {};
    seDatasets.investments = parseSingleDataset(
        rawDatasets.investments,
        zoneName,
        impactsColors.investments
    );
    seDatasets.energyBill = parseSingleDataset(
        rawDatasets.energyBill,
        zoneName,
        impactsColors.energyBill
    );

    const verboseLevel = (level) => {
        if (level === "region") {
            return "Région";
        } else if (level === "departement") {
            return "Département";
        } else if (level === "epci") {
            return "EPCI";
        } else if (level === "commune") {
            return "Commune";
        } else if (level === "territory") {
            return zoneName;
        } else {
            return level;
        }
    };
    ["jobs", "valueAdded", "taxRevenue"].forEach((id) => {
        seDatasets[id] = {
            labels: [],
            datasets: [],
        };
        Object.keys(rawDatasets[id]).forEach((level) => {
            const { labels, datasets } = parseSingleDataset(
                rawDatasets[id][level],
                verboseLevel(level),
                impactsColors[id][level]
            );
            seDatasets[id].labels = seDatasets[id].labels.concat(labels);
            seDatasets[id].datasets = seDatasets[id].datasets.concat(datasets);
        });
        seDatasets[id].labels = [...new Set(seDatasets[id].labels)];
    });

    return seDatasets;
}
