/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import Api from "../../Controllers/Api";

import config from "../../settings.js";
import { buildRegionUrl } from "../../utils.js";

import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
/**
 * This component is used to change the display order of indicators and themes
 */
class POIOrder extends React.Component {
    constructor(props) {
        super(props);

        this.region = this.props.parentApi.data.region;

        this.state = {
            listIndicateurs: [],
            dictThemes: {},
            listThemes: [],
        };
    }

    componentDidMount() {
        // Retrieve analysis
        this.getPOIs();
    }

    // retrieve analysis
    getPOIs() {
        // Call API to retrieve analysis
        let url = buildRegionUrl(config.api_poi_layers_url, this.region);
        Api.callApi(url, null, "GET")
            .then((response) => {
                // id, nom, category
                let dictUIThemes = {};
                let listThemes = [];
                let listIndicateurs = [];
                let dictIndicateurs = {};
                let indTheme = 0;
                for (let a of response) {
                    // We store the name/id correspondences of the themes
                    if (!(a.theme in dictUIThemes)) {
                        dictUIThemes[a.theme] = indTheme;
                        indTheme += 1;
                    }

                    if (!dictIndicateurs[dictUIThemes[a.theme]]) {
                        dictIndicateurs[dictUIThemes[a.theme]] = [];
                    }

                    dictIndicateurs[dictUIThemes[a.theme]].push({
                        id: `item-${a.id}-${new Date().getTime()}`,
                        id_analysis: a.id,
                        content: a.label,
                        theme: a.theme,
                    });
                }

                for (let nomTheme in dictUIThemes) {
                    listThemes.push(nomTheme);
                }

                for (let key in dictIndicateurs) {
                    listIndicateurs.push(dictIndicateurs[key]);
                }

                this.setState({
                    listIndicateurs: listIndicateurs,
                    dictThemes: dictUIThemes,
                    listThemes: listThemes,
                });
            })
            .catch((e) => this.setState({ status: "Aucune donnée disponible." }));
    }

    /**
     * @return {dom} the rendering of the component
     */
    render() {
        const onDragEnd = (result) => {
            const { source, destination } = result;

            // If we drag and drop outside the list we do nothing (no deletion)
            if (!destination) {
                return;
            }
            const dInd = +destination.droppableId;
            let themesAReordonner = {};
            let listThemes = [...this.state.listThemes];

            if (source.droppableId === "root_themes") {
                // reordering the themes => it is the index that is authentic
                listThemes = reorder(this.state.listThemes, source.index, dInd);
                this.setState({ listThemes: listThemes });

                // reorganize the storage of indicators and IDs by themes
                let newDictThemes = {};
                let newIndicList = [];
                listThemes.forEach((obj, index) => {
                    // We put an ID simply by incrementing the array resulting from the reordering of objects
                    newDictThemes[obj] = index;
                    // At the same time, we put the list of indicators in the new place
                    newIndicList[index] =
                        this.state.listIndicateurs[this.state.dictThemes[obj]];
                });
                this.setState({
                    dictThemes: newDictThemes,
                    listIndicateurs: newIndicList,
                });
            } else {
                const sInd = +source.droppableId;
                let newTheme = "";
                let oldTheme = "";

                for (let nomTheme in this.state.dictThemes) {
                    if (dInd === this.state.dictThemes[nomTheme]) {
                        newTheme = nomTheme;
                        themesAReordonner[newTheme] = [];
                    }
                    if (sInd === this.state.dictThemes[nomTheme]) {
                        oldTheme = nomTheme;
                        themesAReordonner[oldTheme] = [];
                    }
                }

                if (sInd === dInd) {
                    const items = reorder(
                        this.state.listIndicateurs[sInd],
                        source.index,
                        destination.index
                    );
                    const newState = [...this.state.listIndicateurs];
                    let analysesAReordonner = {};
                    for (let i = 0; i < items.length; i++) {
                        analysesAReordonner[items[i].id_analysis] = i + 1;
                    }

                    newState[sInd] = items;
                    themesAReordonner[newTheme] = analysesAReordonner;

                    this.setState({ listIndicateurs: newState });
                } else {
                    let dictThemes = { ...this.state.dictThemes };
                    let listIndicateurs = JSON.parse(
                        JSON.stringify(this.state.listIndicateurs)
                    );

                    // if we are creating a new theme
                    if (dInd === listIndicateurs.length) {
                        listIndicateurs.push([]);
                        let newThemeName = "";
                        while (
                            newThemeName === "" ||
                            listThemes.includes(newThemeName)
                        ) {
                            newThemeName = prompt(
                                "Comment souhaitez-vous nommer cette nouvelle thématique ?" +
                                    (newThemeName !== ""
                                        ? " (ce nom existe déjà)"
                                        : ""),
                                "Nouvelle thématique"
                            );
                        }
                        // the user pressed cancel
                        if (newThemeName === null) {
                            return false;
                        }
                        newTheme = newThemeName;
                        listThemes = [...listThemes, newThemeName];
                        dictThemes = {
                            ...dictThemes,
                            [newThemeName]: listThemes.length - 1,
                        };
                    }

                    const result = move(
                        listIndicateurs[sInd],
                        listIndicateurs[dInd],
                        source,
                        destination
                    );

                    const newState = [...listIndicateurs];
                    newState[sInd] = result[sInd];
                    newState[dInd] = result[dInd];
                    let analysesAReordonner = {};

                    // we are removing last indicator from the list
                    for (let i = 0; i < newState[sInd].length; i++) {
                        analysesAReordonner[newState[sInd][i].id_analysis] = i + 1;
                    }
                    themesAReordonner[oldTheme] = analysesAReordonner;

                    analysesAReordonner = {};
                    for (let i = 0; i < newState[dInd].length; i++) {
                        analysesAReordonner[newState[dInd][i].id_analysis] = i + 1;
                    }
                    themesAReordonner[newTheme] = analysesAReordonner;
                    if (newState[sInd].length === 0) {
                        //TODO: remove the theme from the lists
                        listThemes = listThemes.filter((_, id) => id !== sInd);
                        dictThemes = {};
                        listThemes.forEach((obj, index) => {
                            // We put an ID simply by incrementing the array resulting from the reordering of objects
                            dictThemes[obj] = index;
                        });
                    }
                    this.setState({
                        listThemes: [...listThemes],
                        dictThemes: dictThemes,
                        listIndicateurs: newState.filter((group) => group.length),
                    });
                }
            }

            // Call API to update the order of this indicator
            let params = {};
            params["themes_analyses_ordres"] = themesAReordonner;
            params["themes_ordres"] = listThemes;
            const body = JSON.stringify(params);
            let url = buildRegionUrl(config.api_poi_order_layers_url, this.region);
            Api.callApi(url, body, "PUT")
                .then((response) => {
                    this.setState({ status: response.message });
                })
                .catch((e) => this.setState({ status: e.message }));
        };

        const reorder = (list, startIndex, endIndex) => {
            const result = Array.from(list);
            const [removed] = result.splice(startIndex, 1);
            result.splice(endIndex, 0, removed);
            return result;
        };

        const editTitle = (idTheme, nomTheme) => {
            let newThemeName = "";
            while (
                newThemeName === "" ||
                (newThemeName !== nomTheme &&
                    this.state.listThemes.includes(newThemeName))
            ) {
                newThemeName = prompt(
                    "Comment souhaitez-vous renommer cette thématique ?" +
                        (newThemeName !== "" && nomTheme !== newThemeName
                            ? " (ce nom existe déjà)"
                            : ""),
                    nomTheme
                );
            }
            // the user pressed cancel
            if (newThemeName === null) {
                return false;
            }

            // Call API to update the order of this indicator
            let params = {};
            params["old_name"] = nomTheme;
            params["new_name"] = newThemeName;
            const body = JSON.stringify(params);
            let url = buildRegionUrl(config.api_poi_theme_rename_url, this.region);
            Api.callApi(url, body, "PUT")
                .then((response) => {
                    let listThemes = [...this.state.listThemes];
                    let dictThemes = { ...this.state.dictThemes };
                    listThemes[idTheme] = newThemeName;
                    delete dictThemes[nomTheme];
                    dictThemes[newThemeName] = idTheme;
                    this.setState({
                        listThemes: listThemes,
                        dictThemes: dictThemes,
                        status: response.message,
                    });
                })
                .catch((e) => this.setState({ status: e.message }));
        };

        /**
         * Move an analysis from one theme to another
         */
        const move = (source, destination, droppableSource, droppableDestination) => {
            const sourceClone = Array.from(source);

            if (destination !== undefined) {
                const destClone = Array.from(destination);
                const [removed] = sourceClone.splice(droppableSource.index, 1);

                destClone.splice(droppableDestination.index, 0, removed);

                const result = {};
                result[droppableSource.droppableId] = sourceClone;
                result[droppableDestination.droppableId] = destClone;

                return result;
            }
        };

        const grid = 8;
        const getItemStyle = (isDragging, draggableStyle) => ({
            // Style
            userSelect: "none",
            padding: grid * 1,
            margin: `0 0 ${grid}px 0`,
            // Changing the color during Drag and Drop
            background: isDragging ? "rgb(178 203 123)" : "rgb(212 225 184)",
            // Style for draggable items
            ...draggableStyle,
        });

        const getListStyle = (isDraggingOver) => ({
            background: isDraggingOver ? "rgb(243, 249, 230)" : "rgb(243, 249, 230)",
            padding: grid,
            width: 150,
        });

        const getListStyleThemes = () => ({
            background: "rgb(243, 249, 230)",
            padding: 0,
            width: 0,
            display: "flex",
        });

        let analysisOrderUI = "";
        let themes = [];
        for (let t in this.state.listThemes) {
            let nomTheme = this.state.listThemes[t];
            let idTheme = this.state.dictThemes[nomTheme];
            let indicateursItems = [];
            let indicateursParTheme = this.state.listIndicateurs[idTheme];
            if (indicateursParTheme === undefined) {
                continue;
            }
            for (let j = 0; j < indicateursParTheme.length; j++) {
                let indicateur = indicateursParTheme[j];
                // Add indicator in a theme
                indicateursItems.push(
                    <Draggable
                        key={indicateur.id}
                        draggableId={indicateur.id}
                        index={j}
                    >
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                )}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-around",
                                    }}
                                >
                                    {indicateur.content}
                                </div>
                            </div>
                        )}
                    </Draggable>
                );
            }

            let keyDragTheme = nomTheme + "_drag";
            let keyDropTheme = nomTheme + "_drop";
            themes.push(
                <Draggable
                    key={keyDragTheme}
                    draggableId={keyDragTheme}
                    index={idTheme}
                >
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                        >
                            <Droppable key={keyDropTheme} droppableId={`${idTheme}`}>
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        style={getListStyle(snapshot.isDraggingOver)}
                                        {...provided.droppableProps}
                                    >
                                        <div
                                            className="category-edition-title"
                                            onDoubleClick={() =>
                                                editTitle(idTheme, nomTheme)
                                            }
                                        >
                                            {nomTheme}
                                            &nbsp;
                                            <span
                                                className="bi bi-pencil cursor-pointer"
                                                onClick={() =>
                                                    editTitle(idTheme, nomTheme)
                                                }
                                            ></span>
                                        </div>

                                        {indicateursItems}

                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </div>
                    )}
                </Draggable>
            );
        }

        const keyDragTheme = "new_theme_drag";
        const keyDropTheme = "new_theme_drop";
        const idTheme = this.state.listThemes.length;
        themes.push(
            <Draggable key={keyDragTheme} draggableId={keyDragTheme} index={idTheme}>
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                    >
                        <Droppable key={keyDropTheme} droppableId={`${idTheme}`}>
                            {(provided, snapshot) => (
                                <div
                                    ref={provided.innerRef}
                                    style={getListStyle(snapshot.isDraggingOver)}
                                    {...provided.droppableProps}
                                >
                                    <p>
                                        <strong>Ajouter une nouvelle thématique</strong>
                                    </p>
                                    <p>
                                        <em>
                                            Glisser-déposer une couche d'équipements ici
                                            pour créer une nouvelle thématique
                                        </em>
                                    </p>

                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </div>
                )}
            </Draggable>
        );

        analysisOrderUI = (
            <div className="ordering-elements-panel">
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable
                        key="root"
                        droppableId="root_themes"
                        direction="horizontal"
                    >
                        {(provided) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyleThemes()}
                            >
                                {themes}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        );

        return analysisOrderUI;
    }
}

export default POIOrder;
