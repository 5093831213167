/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import { Line, Chart } from "react-chartjs-2";
import { buildRegionUrl } from "../../utils.js";
import annotationPlugin from "chartjs-plugin-annotation";

import config from "../../settings.js";
import Api from "../../Controllers/Api.js";
import configData from "../../settings_data.js";

Chart.register(annotationPlugin);

/**
 * This component used to create a graph with stacked curves
 */
class PCAETTrajectory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            representation: this.props.representation ?? "line",
            id: props.type.categorie,
            disabled: false,
            allYears: {},
            filters: this.props.filters,
            isLoading: false,
            showSupraGoals: this.props.options?.showSupraGoals ?? false,
            hiddenTrajs: this.props.options?.hiddenTrajs ?? {},

            trajectoryParams: undefined,

            dataHistoricalTraj: undefined,
            dataPCAETTraj: undefined,
            dataSupraGoals: [],
            unit: "",
        };
    }

    componentDidMount() {
        // Get data from API
        this.fetchConfiguration();
    }

    /**
     * This function updates the data from which we build the stacked curves charts when changing territory
     * @param {object key => value} prevProps : main component properties before territory change via selection tool
     */
    componentDidUpdate(prevProps, prevState) {
        let currentZone = this.props.zoneId ?? this.props.parentApi.data.currentZone;
        let elemHtml = document.getElementsByClassName("liste-modalites");

        if (
            this.props.provenance === "tableau_de_bord" ||
            this.props.provenance === "tableau_de_bord_restitue"
        ) {
            this.props.parentApi.controller.dashboardManager.applyBottomMargin(
                elemHtml
            );
        }

        if (prevProps.options !== this.props.options) {
            this.setState({
                showSupraGoals: this.props.options?.showSupraGoals ?? false,
                hiddenTrajs: this.props.options?.hiddenTrajs ?? {},
            });
        }

        // Update data if zone was updated
        if (
            (prevProps.zoneId ?? prevProps.parentApi.data.currentZone) !==
                currentZone &&
            this.props.id_analysis
        ) {
            this.fetchConfiguration();
        }
        // ...or if id_analysis was updated
        if (
            this.props.id_analysis &&
            this.props.id_analysis !== prevProps.id_analysis
        ) {
            this.fetchConfiguration();
        }
        // ...or if we updated the filters
        else if (
            JSON.stringify(this.state.filters) !== JSON.stringify(prevState.filters)
        ) {
            const newPcaetData = [];
            for (const i in this.state.dataPCAETTraj) {
                const { nom } = this.state.dataPCAETTraj[i];
                const hidden =
                    this.state.filters[this.state.category].findIndex(
                        ({ filtre_categorie }) => filtre_categorie.split(".")[1] === nom
                    ) === -1;
                newPcaetData.push({ ...this.state.dataPCAETTraj[i], hidden });
            }
            const newHistData = [];
            for (const i in this.state.dataHistoricalTraj) {
                const { nom } = this.state.dataHistoricalTraj[i];
                const hidden =
                    this.state.filters[this.state.category].findIndex(
                        ({ filtre_categorie }) => filtre_categorie.split(".")[1] === nom
                    ) === -1;
                newHistData.push({ ...this.state.dataHistoricalTraj[i], hidden });
            }
            this.setState({
                dataPCAETTraj: newPcaetData,
                dataHistoricalTraj: newHistData,
            });
        }
    }

    componentWillUnmount() {
        if (this.dataPromise) {
            this.dataPromise.abort();
            this.props.parentApi.callbacks.updateDataLoaded(true);
        }
    }

    updateDashboard(indicator) {
        const thematique =
            this.props.parentApi.data.tableauBordDonnees.donnees[this.props.thematique];
        if (indicator.options) {
            indicator.options = {
                ...thematique.indicateurs[this.props.id].options,
                ...indicator.options,
            };
        }
        this.props.parentApi.callbacks.updateDashboard(
            this.props.thematique,
            thematique.titre_thematique,
            thematique.description_thematique,
            this.props.id,
            {
                ...thematique.indicateurs[this.props.id],
                ...indicator,
            }
        );
    }

    async fetchConfiguration(update = false) {
        const trajectoryParams =
            await this.props.parentApi.controller.analysisManager.getDetailsOnTrajectory(
                this.props.id_analysis
            );
        this.setState({
            isLoading: !update,
            trajectoryParams: trajectoryParams,
            filters: trajectoryParams.filters,
        });

        const params = {
            zone: this.props.zoneType ?? this.props.parentApi.data.zone.zone,
            maille: this.props.zoneMaille ?? this.props.parentApi.data.zone.maille,
            zone_id: this.props.zoneId ?? this.props.parentApi.data.currentZone,
        };

        let dataSource =
            config.api_pcaet_trajectory_details.replace(
                "#trajectory_id#",
                this.props.id_analysis
            ) +
            "?" +
            Object.entries(params)
                .map(([key, val]) => `${key}=${val}`)
                .join("&");

        this.props.parentApi.callbacks.updateDataLoaded(false);

        if (this.dataPromise) this.dataPromise.abort();
        this.dataPromise = Api.callApi(
            buildRegionUrl(dataSource, this.props.parentApi.data.region)
        );
        this.dataPromise
            .then((json) => {
                this.props.parentApi.callbacks.updateDataLoaded(true);
                if (!json) {
                    this.setState({
                        isLoading: false,
                        dataHistoricalTraj: undefined,
                        dataPCAETTraj: undefined,
                    });
                    return;
                }

                if (json["disabled"] === true) {
                    this.setState({
                        disabled: true,
                        isLoading: false,
                    });
                    return;
                }

                this.setState({
                    dataPCAETTraj: json["pcaet_trajectory"],
                    dataHistoricalTraj: json["historical_data"],
                    dataSupraGoals: json["supra_goals"] ?? [],
                    unit: json["unit"],
                    category: json["category"],
                    isLoading: false,
                });
            })
            .catch((error) => {
                if (error.name === "AbortError") return;
                this.props.parentApi.callbacks.updateDataLoaded(true);
                this.setState({
                    isLoading: false,
                    dataHistoricalTraj: undefined,
                    dataPCAETTraj: undefined,
                });
                console.error(error);
            });
    }

    /**
     * Launches a main component method to add/remove a modality to the filter
     * Triggered when a checkbox associated with a modality is checked
     * @param {chaine de caractère} modalite : modality checked / unchecked
     */
    filtrerSurLegende(event, modalite, categorie) {
        let filterWithTableName = categorie + "." + modalite;
        // if we do have filters
        if (!this.state.filters) return;

        let _filters = JSON.parse(JSON.stringify(this.state.filters));
        // if we do not have current filter
        if (!_filters[categorie]) return;

        // we first retrieve the filter ID if present inside the filters
        const idIfPresent = _filters[categorie].findIndex(
            (element) => element && element.filtre_categorie === filterWithTableName
        );

        // do we have to add or do we have to remove
        if (event.target.checked) {
            // if we couldn't find it, it means we need to add it
            if (idIfPresent === -1) {
                _filters[categorie].push({
                    filtre_categorie: filterWithTableName,
                    type: "pie",
                });
            }
        } else {
            // we are going to delete the filter if we can find it
            if (idIfPresent !== -1) {
                _filters[categorie].splice(idIfPresent, 1);
            }
        }

        // if the action resulted in some changes between previous filters
        // and new filters => we update the state
        if (JSON.stringify(this.state.filters) !== JSON.stringify(_filters)) {
            this.setState({
                filters: _filters,
            });
            if (this.props.provenance === "tableau_de_bord") {
                // we also callback the updated filters in case of dashboard edition
                this.updateDashboard({ filters: _filters });
            }
        }
    }

    switchShowSupraGoals(event) {
        const showSupraGoals = !event.target.checked;
        this.setState({
            showSupraGoals: showSupraGoals,
            filters: this.state.trajectoryParams.filters, // Reset filters
        });
        // Save option in dashboard data
        this.updateDashboard({ options: { showSupraGoals } });
    }

    /**
     * Builds the legend with which we can select or delete modalities
     * in the data to be displayed using checkboxes, from modalities by categories.
     * @param {object ke value} chart : data for a chart constructed as follows:
     * {
     *     data : {
     *         confid : Type of confidentiality,
     *         id : indicator identifier,
     *         labels : list of modalities,
     *         datasets : [{
     *             data : List of values in the correct order to match them
     *             to the order of the modalities,
     *             backgroundColor : List of colors in the correct order to match them
     *             to the order of the modalities
     *         }]
     *     }
     * }
     * This array contains other keys that we don't use in this method.
     */
    buildLegend(data) {
        if (this.state.showSupraGoals) return null;
        let identifiant_indicateur = this.props.id_analysis;
        let legende = [];
        let tailleSpan = "10px";
        let taillePoliceModalites = "";
        let hauteurLegendeModalites = "";
        let hauteurLegende = "";
        if (
            this.props.provenance !== "tableau_de_bord" &&
            this.props.provenance !== "tableau_de_bord_restitue"
        ) {
            tailleSpan = "15px";
            taillePoliceModalites = " taille-police-suivi-energetique";
            hauteurLegendeModalites = " legende-modalite-suivi-energetique";
            hauteurLegende = " liste-modalites-suivi-energetique";
        }
        let retourLigneLibelleModalites = "";
        if (this.props.provenance === "tableau_de_bord_restitue") {
            retourLigneLibelleModalites = " legende-modalite-tableau-bord";
        }
        let filtreInitial = this.state.filters;

        const category = this.state.category;
        let listeModaliteFiltre = [];
        if (filtreInitial && filtreInitial[category]) {
            for (let elem of filtreInitial[category]) {
                listeModaliteFiltre.push(elem["filtre_categorie"].split(".")[1]);
            }
        }
        for (let i in data) {
            let checked = false;
            let disabled = false;
            let couleur = data[i].couleur;
            if (listeModaliteFiltre.indexOf(data[i].nom) !== -1) {
                checked = true;
            }
            if (data[i].hidden) {
                checked = false;
            }
            if (data[i].confidentiel === "oui") {
                disabled = true;
                couleur = "#9e0012";
            }
            legende.push(
                <div
                    className={"legende-modalite" + hauteurLegendeModalites}
                    key={"legende-" + i + "-" + data[i].nom}
                >
                    <span
                        className="element-legende-modalites"
                        style={{
                            backgroundColor: couleur,
                            display: "block",
                            height: tailleSpan,
                            width: tailleSpan,
                        }}
                    ></span>
                    <input
                        type="checkbox"
                        id={
                            "legende-" +
                            this.props.id +
                            "-" +
                            i +
                            this.state.id +
                            "-" +
                            data[i].nom
                        }
                        defaultChecked={checked}
                        className="element-legende-modalites"
                        disabled={disabled}
                        onChange={(event) =>
                            this.filtrerSurLegende(event, data[i].nom, category)
                        }
                    ></input>
                    <label
                        htmlFor={
                            "legende-" +
                            this.props.id +
                            "-" +
                            i +
                            this.state.id +
                            "-" +
                            data[i].nom
                        }
                        className={
                            "element-legende-modalites" +
                            retourLigneLibelleModalites +
                            taillePoliceModalites
                        }
                    >
                        {" "}
                        {data[i].nom}
                    </label>
                </div>
            );
        }
        legende = legende.reverse();
        return (
            <ul
                className={"liste-modalites" + hauteurLegende}
                key={"legende-finale-" + identifiant_indicateur}
            >
                {legende}
            </ul>
        );
    }

    preventAnnotationOverlap(annotations, datasets) {
        // to get the height of the graph, we need to know the max value across all datasets
        const maxYValue = Math.max(
            ...datasets.map(({ data }) => data.map(({ y }) => y)).flat()
        );

        // we compute min vertical distance between two evolution annotations
        const annotationHeight = 50;
        const graphHeight = 500;
        const minDistBetweenAnnotations = (annotationHeight * maxYValue) / graphHeight;

        // better localization along yAxis
        let lastVal = undefined;
        Object.values(annotations)
            .sort((a1, a2) => a1.yValue - a2.yValue) // We order values from bottom to top
            .forEach((annotation) => {
                if (lastVal === undefined) {
                    // If first value we don't change anything
                    lastVal = annotation.yValue;
                    return;
                }
                // We check that the label below is not too close
                // If it is...
                if (annotation.yValue - lastVal < minDistBetweenAnnotations) {
                    // ...we put just above the previous label
                    lastVal += minDistBetweenAnnotations;
                    annotation.yValue = lastVal;
                } else {
                    // ...otherwise we don't change anything
                    lastVal = annotation.yValue;
                }
            });

        return annotations;
    }

    render() {
        if (this.state.disabled === true) {
            return (
                <div className="charts-legende">
                    <div className={"confid-chart"}>
                        Cet indicateur n'est pas activé actuellement, veuillez contacter
                        l'administrateur régional.
                    </div>
                </div>
            );
        }

        if (this.state.isLoading) {
            return (
                <div className="charts-legende">
                    <div className={"loader"}></div>
                </div>
            );
        }

        let consoData = undefined;
        const annotations = {};
        let titre = "";
        if (this.props.type) {
            titre = this.props.type.titre;
        }
        let legendeFinale = "";

        if (
            this.state.dataHistoricalTraj &&
            this.state.dataHistoricalTraj !== "Confidentiel"
        ) {
            legendeFinale = this.buildLegend(this.state.dataHistoricalTraj);
            const categoryData = this.state.dataHistoricalTraj;

            let liste_annees = [];
            for (let j in categoryData[0].data) {
                liste_annees.push(categoryData[0].data[j]["annee"]); // generate the list of data to be entered as parameters of dataForConsoVsProd
            }

            let dataSet = [];
            for (let annee in liste_annees) {
                // For each year
                let v = 0;
                for (let i in categoryData) {
                    if (categoryData[i].hidden) {
                        continue;
                    }
                    // We browse each modality (arbitrarily by type of energy because common to the production and consumption)
                    for (let k in categoryData[i].data) {
                        // We browse each row of data {year: value}
                        if (categoryData[i].data[k].annee === liste_annees[annee]) {
                            // If the year matches then
                            v += parseFloat(categoryData[i].data[k].valeur.toFixed(2)); // We sum all the types to calculate the total
                        }
                    }
                }
                dataSet.push({
                    x: new Date(parseInt(liste_annees[annee], 10) + "-01-01"),
                    y: parseFloat(v.toFixed(2)),
                });
            }

            consoData = {
                datasets: [
                    {
                        borderColor: "#000",
                        pointStyle: "circle",
                        label: "Historique",
                        pointRadius: 3,
                        borderWidth: 1,
                        order: 1,
                        data: dataSet,
                        labels: liste_annees,
                    },
                ],
            };
        }

        if (this.state.dataPCAETTraj && this.state.dataPCAETTraj !== "Confidentiel") {
            const trajectoryValues = this.state.dataPCAETTraj;
            let liste_annees = [];
            for (let j in trajectoryValues[0].data) {
                liste_annees.push(trajectoryValues[0].data[j]["annee"]); // generate the list of data to be entered as parameters of dataForConsoVsProd
            }
            let dataSet = [];
            for (let annee of liste_annees) {
                // For each year
                let v = 0;
                for (let i in trajectoryValues) {
                    if (trajectoryValues[i].hidden) {
                        continue;
                    }
                    // We browse each modality (arbitrarily by type of energy because common to the production and consumption)
                    for (let k in trajectoryValues[i].data) {
                        // We browse each row of data {year: value}
                        if (trajectoryValues[i].data[k].annee === annee) {
                            // If the year matches then
                            v += parseFloat(
                                trajectoryValues[i].data[k].valeur.toFixed(2)
                            ); // We sum all the types to calculate the total
                        }
                    }
                }
                dataSet.push({
                    x: new Date(parseInt(annee, 10) + "-01-01"),
                    y: parseFloat(v.toFixed(2)),
                });
            }

            // We add the PCAET curve to the dataset
            if (consoData) {
                consoData.datasets.push({
                    borderColor: "#0dcaf0",
                    backgroundColor: "#0dcaf0",
                    pointStyle: "circle",
                    label: "Trajectoire PCAET",
                    borderDash: [5, 5],
                    pointRadius: 3,
                    borderWidth: 1,
                    order: -1,
                    data: dataSet,
                    labels: liste_annees,
                });
            }

            if (this.state.showSupraGoals) {
                // Show evolution (eg. "-15% par rapport à 2015") as an annotation
                let evolution = 0;
                let unit = "%";

                if (dataSet[0].y === 0) {
                    evolution = dataSet[dataSet.length - 1].y;
                    unit = this.state.trajectoryParams?.unit ?? "";
                } else {
                    evolution =
                        ((dataSet[dataSet.length - 1].y - dataSet[0].y) /
                            dataSet[0].y) *
                        100;
                }
                const annotationContent = `${evolution > 0 ? "+" : ""}${parseFloat(
                    evolution.toFixed(1)
                )}${unit} par rapport à ${liste_annees[0]}`;
                const annotationPosition = dataSet[dataSet.length - 1];

                // Add to the list of annotations
                annotations["Trajectoire PCAET"] = {
                    type: "label",
                    xValue: annotationPosition.x,
                    xAdjust: 5,
                    yValue: annotationPosition.y,
                    yAdjust: -10,
                    content: annotationContent,
                    color: "#0dcaf0",
                    backgroundColor: "rgba(150, 150, 150, 0.1)",
                    borderRadius: 5,
                    position: "start",
                    font: { size: 14 },
                };
            }
        }
        const zoneType = this.props.zoneType ?? this.props.parentApi.data.zone.zone;
        const zoneId = this.props.zoneId ?? this.props.parentApi.data.currentZone;
        if (this.state.showSupraGoals && this.state.dataSupraGoals) {
            for (const supraGoal of this.state.dataSupraGoals) {
                const affectation = JSON.parse(supraGoal.affectation);
                const isAffectationOK =
                    affectation[zoneType + "s"] === "" ||
                    affectation[zoneType] === zoneId;
                if (!isAffectationOK) continue;
                if (!consoData) continue;

                const dataSet = [
                    {
                        x: new Date(supraGoal.annee_reference + "-01-01"),
                        y: parseFloat(supraGoal.valeur_reference.toFixed(2)),
                    },
                ];
                dataSet.push(
                    ...supraGoal.valeurs_annees.map(({ annee, valeur }) => ({
                        x: new Date(parseInt(annee, 10) + "-01-01"),
                        y: parseFloat(
                            (supraGoal.valeur_reference * (1 + valeur / 100)).toFixed(2)
                        ),
                    }))
                );
                consoData.datasets.push({
                    borderColor: supraGoal.couleur,
                    backgroundColor: supraGoal.couleur,
                    pointStyle: "circle",
                    label: supraGoal.titre,
                    borderDash: [5, 5],
                    pointRadius: 1,
                    borderWidth: 1,
                    order: -2,
                    data: dataSet,
                    labels: supraGoal.valeurs_annees.map(({ annee }) => annee),
                });

                // Show evolution (eg. "-15% par rapport à 2015") as an annotation
                const evolution =
                    supraGoal.valeurs_annees[supraGoal.valeurs_annees.length - 1][
                        "valeur"
                    ];
                const annotationContent = `${evolution > 0 ? "+" : ""}${parseFloat(
                    evolution.toFixed(1)
                )}% par rapport à ${supraGoal.annee_reference}`;
                const annotationPosition = dataSet[dataSet.length - 1];

                // Add to the list of annotations
                annotations[supraGoal.titre] = {
                    type: "label",
                    xValue: annotationPosition.x,
                    xAdjust: 5,
                    yValue: annotationPosition.y,
                    yAdjust: -10,
                    content: annotationContent,
                    color: supraGoal.couleur ?? "darkgray",
                    backgroundColor: "rgba(150, 150, 150, 0.1)",
                    borderRadius: 5,
                    position: "start",
                    font: { size: 14 },
                };
            }
        }

        const year = (date) => new Date(date).getFullYear();
        const { showSupraGoals, hiddenTrajs } = this.state;
        // Read active trajectories
        if (consoData) {
            for (let dataSet of consoData.datasets) {
                dataSet.hidden = hiddenTrajs[dataSet.label] ?? false;
                if (annotations[dataSet.label]) {
                    annotations[dataSet.label].display = !hiddenTrajs[dataSet.label];
                }
            }

            this.preventAnnotationOverlap(annotations, consoData.datasets);
        }

        let titreAxeOrdonnees =
            this.state.trajectoryParams?.name +
            " en " +
            this.state.trajectoryParams?.unit;
        let chartOptionsSuivi = {
            maintainAspectRatio: false,
            spanGaps: false,
            scales: {
                x: {
                    type: "time",
                    display: true,
                    title: {
                        display: true,
                        text: "Années",
                        font: {
                            size: 16,
                        },
                    },
                    time: {
                        unit: "year",
                    },
                    ticks: {
                        minRotation: 65,
                        source: "data",
                        autoSkip: false,
                        callback: function (label, id, array) {
                            const firstYear = year(array[0].value);
                            const lastYear = year(array[array.length - 1].value);
                            if (
                                lastYear - firstYear >= 35 &&
                                array[id - 1]?.label &&
                                year(array[id - 1].value) === Number(label) - 1
                            ) {
                                // Show only half of the indices when they are at a distance of 1
                                return "";
                            }
                            return label;
                        },
                        font: {
                            size: 11,
                        },
                    },
                },
                y: {
                    display: true,
                    ticks: {
                        font: {
                            size: 16,
                        },
                    },
                    title: {
                        display: true,
                        text: titreAxeOrdonnees,
                        font: {
                            size: 16,
                        },
                        marginRight: 20,
                        paddingLeft: 20,
                    },
                    beginAtZero: true,
                },
                "y-axis-trajectoire": {
                    type: "linear",
                    display: false,
                    position: "right",
                    grid: {
                        drawOnChartArea: false,
                    },
                    beginAtZero: true,
                },
            },
            hover: {
                mode: "single",
            },
            plugins: {
                title: {
                    display: !showSupraGoals,
                    text: titre,
                    padding: 5,
                    font: {
                        size: 16,
                    },
                },
                legend: {
                    position: "bottom",
                    display: true,
                    labels: {
                        font: {
                            size: 16,
                        },
                        usePointStyle: true,
                    },
                    reverse: true,
                    onClick: (event, legendItem, ...a) => {
                        const annotationItem =
                            event.chart.options.plugins.annotation?.annotations?.[
                                legendItem.text
                            ];
                        if (annotationItem) {
                            annotationItem.display = !event.chart.isDatasetVisible(
                                legendItem.datasetIndex
                            );
                        }
                        if (this.props.provenance === "tableau_de_bord") {
                            // Save the datasets that are displayed in dashboard data
                            hiddenTrajs[legendItem.text] = event.chart.isDatasetVisible(
                                legendItem.datasetIndex
                            );
                            this.updateDashboard({ options: { hiddenTrajs } });
                        }
                        Chart.defaults.plugins.legend.onClick(event, legendItem, ...a);
                    },
                },
                tooltip: {
                    reverse: true,
                    mode: "x",
                    intersect: false,
                    callbacks: {
                        title: function (data) {
                            return new Date(data[0].label).getFullYear();
                        },
                        label: function (tooltipItems) {
                            return (
                                tooltipItems.dataset.label + ":" + tooltipItems.raw.y
                            );
                        },
                    },
                },
                annotation: {
                    clip: false,
                    annotations: annotations,
                },
            },
            layout: {
                padding: {
                    right: showSupraGoals ? 190 : 10,
                    top: 40,
                    left: 10,
                },
            },

            width: 500,
            height: 500,
            devicePixelRatio: configData.chartsDevicePixelRatio,
            elements: {
                point: {
                    radius: 0,
                },
            },
        };

        let chartEvolConsoSecteur = "";

        if (this.state.dataHistoricalTraj !== "Confidentiel" && consoData) {
            chartEvolConsoSecteur = (
                <div>
                    <div className="block-row">
                        <Line
                            width={chartOptionsSuivi.width}
                            height={chartOptionsSuivi.height}
                            options={chartOptionsSuivi}
                            data={consoData}
                            style={{
                                width: this.state.showSupraGoals ? "680px" : "500px",
                                height: "500px",
                            }}
                        />
                    </div>
                </div>
            );
        } else if (this.state.dataHistoricalTraj === "Confidentiel") {
            chartEvolConsoSecteur = (
                <div className={"confid-chart"}>Données confidentielles</div>
            );
        } else {
            chartEvolConsoSecteur = (
                <div className={"confid-chart"}>
                    Catégorie non disponible pour cet indicateur, veuillez contacter
                    l'administrateur régional.
                </div>
            );
        }

        let showSupraGoalsCheckbox = null;
        if (this.props.provenance === "tableau_de_bord") {
            showSupraGoalsCheckbox = (
                <div>
                    <input
                        type="checkbox"
                        id={this.props.id + "-show-filters"}
                        checked={!this.state.showSupraGoals}
                        onChange={this.switchShowSupraGoals.bind(this)}
                    />{" "}
                    <label htmlFor={this.props.id + "-show-filters"}>
                        Détail {this.props.type?.titre ?? "par " + this.category}
                    </label>
                </div>
            );
        }

        return (
            <div>
                {showSupraGoalsCheckbox}
                <div className="charts-legende" style={{ width: "101%" }}>
                    {chartEvolConsoSecteur}
                    {legendeFinale && (
                        <div className="choisir-methode elem-courbes-empilees">
                            {legendeFinale}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default PCAETTrajectory;
