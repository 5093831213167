/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React, { useState } from "react";
import Api from "../../Controllers/Api.js";
import { buildRegionUrl } from "../../utils.js";
import config from "../../settings.js";
import "../../style/national/login.css";
import ReCAPTCHA from "react-google-recaptcha";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min.js";

function Subscribe(props) {
    const [captchaVerified, setCaptcha] = useState(undefined);
    const [submittedMessage, setSubmittedMessage] = useState("");
    const [responseError, setResponseError] = useState(false);
    const [sending, setSending] = useState(false);
    const [isHide, setIsHide] = useState(false);
    const location = useLocation();
    const previousPage = location.state?.previousPage;

    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.target.elements;

        setSending(true);
        setResponseError(false);

        if (!captchaVerified && !config.DISABLE_CAPTCHA) {
            setSubmittedMessage("Veuillez remplir le captcha.");
            setResponseError(true);
            setSending(false);
            return;
        }

        const body = JSON.stringify({
            firstName: form["first-name"].value,
            lastName: form["last-name"].value,
            fonction: form["fonction"].value,
            territoire: form["territoire"].value,
            email: form["email"].value,
            captcha_token: captchaVerified,
        });

        try {
            const json = await Api.callApi(
                buildRegionUrl(config.subscribe_other_territory_url),
                body,
                "POST"
            );

            let msg = "Votre demande a bien été envoyée.";
            if (json.message) msg = json.message;
            if (json.email_status && json.email_status !== "ok")
                msg += "\nAttention toutefois : " + json.email_status;

            setSubmittedMessage(msg);
            setResponseError(false);

            if (json.email_status === "ok" || json.status === "ok")
                event.target.reset();

            setIsHide(false);
            setTimeout(() => setIsHide(true), 6000);
        } catch (error) {
            setSubmittedMessage(error.message);
            setResponseError(true);
        } finally {
            resetCaptcha();
            setSending(false);
        }
    };

    const verifyCallbackCaptcha = (token) => {
        setCaptcha(token);
    };

    const resetCaptcha = () => {
        setCaptcha(undefined);
    };

    const star = <span className="danger">*</span>;
    return (
        <div className="submission widgets full-screen-widget">
            <div>
                {previousPage && (
                    <div>
                        <Link to={previousPage} className="btn btn-light return-button">
                            <i className="bi bi-arrow-left"></i>
                        </Link>
                    </div>
                )}
                <h3 className="tstitle">S'inscrire</h3>
                <form className="tsform" onSubmit={handleSubmit}>
                    <div className="input">
                        <label htmlFor="email">E-mail{star}</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            required
                            placeholder="nom@mail.pro"
                        />
                    </div>
                    <div className="input">
                        <label htmlFor="first-name">Prénom{star}</label>
                        <input type="text" id="first-name" name="first-name" required />
                    </div>
                    <div className="input">
                        <label htmlFor="last-name">Nom{star}</label>
                        <input type="text" id="last-name" name="last-name" required />
                    </div>
                    <div className="input">
                        <label htmlFor="fonction">Fonction{star}</label>
                        <input type="text" id="fonction" name="fonction" required />
                    </div>
                    {/* TODO: dropList */}
                    <div className="input">
                        <label htmlFor="territoire">Territoire{star}</label>
                        <input type="text" id="territoire" name="territoire" required />
                    </div>
                    <div className="check-input">
                        <input
                            type="checkbox"
                            id="mentions-legales"
                            name="mentions-legales"
                            required
                        />
                        <label htmlFor="mentions-legales">
                            En soumettant ce formulaire, je reconnais avoir pris
                            connaissance et accepter{" "}
                            <a href="/404">
                                les mentions légales et les conditions générales
                                d'utilisation du site
                            </a>
                            . J'accepte également que les informations saisies soient
                            exploitées par AURA-EE pour me recontacter selon les
                            spécifications inscrites dans les mentions légales.
                            {star}
                        </label>
                    </div>
                    <p style={{ fontSize: 14, textAlign: "center", marginBottom: 0 }}>
                        <span className="danger">*Mention obligatoire</span>
                    </p>
                    <ReCAPTCHA
                        sitekey={config.captcha_key}
                        onChange={(t) => verifyCallbackCaptcha(t)}
                    />
                    <input type="submit" className="tsbtn info big" value="Envoyer" />
                    {sending && (
                        <div className="loader centered-widget centered-row"></div>
                    )}
                    {submittedMessage && !isHide && (
                        <div
                            className={`form-group centered-row alert alert-${
                                responseError ? "warning" : "success"
                            }`}
                        >
                            {submittedMessage}
                        </div>
                    )}
                </form>
            </div>
        </div>
    );
}

export default Subscribe;
