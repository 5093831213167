/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import Api from "../../Controllers/Api";
import config from "../../settings.js";
import { buildRegionUrl } from "../../utils.js";

import "bootstrap/dist/css/bootstrap.min.css";

/**
 * This component is used to manage the equipment in the database
 */
class POIManage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        if (
            !this.props.connected ||
            !this.props.userInfos ||
            this.props.userInfos?.profil !== "admin"
        ) {
            return <div>Non accessible.</div>;
        }

        /**
         *
         * Callback to update an equipment layer
         * @param  {json} equipement : current row
         */
        const updateEquipements = (equipements) => {
            // We switch to the creation tab, but in update mode
            this.props.updateTabEquipements(1, "modifier", equipements); // 1 represents the edit tab (0 the data management and 1 the list of equipment layers)
        };

        /**
         *
         * Callback to delete an equipment layer
         * @param  {json} equipement : current row
         */
        const deletePOI = (equipements) => {
            let r = window.confirm(
                "Attention, cette opération n'est pas réversible. Voulez-vous supprimer cette couche d'équipements " +
                    equipements.nom +
                    " ?"
            );
            if (r === true) {
                let url = buildRegionUrl(
                    config.api_poi_layer_url,
                    this.props.parentApi.data.region
                ).replace("#layer#", equipements.id);
                Api.callApi(url, null, "DELETE").then((response) => {
                    // After the update, it is necessary to refresh this.props.parentApi.data.poiLayers
                    this.props.parentApi.callbacks.updatePoiRights();
                });
            }
        };

        /**
         * Makes a layer editable
         * @param  {str} layer : the layer concerned
         * @param  {str} state : new state
         */
        const setEditable = (layer, state) => {
            let url = buildRegionUrl(
                config.api_poi_layer_rights_url,
                this.props.parentApi.data.region
            ).replace("#layer#", layer);
            const body = JSON.stringify({ droit: state });
            Api.callApi(url, body, "put").then((response) => {
                // After the update, it is necessary to refresh this.props.parentApi.data.poiLayers
                this.props.parentApi.callbacks.updatePoiRights();
            });
        };

        let equipementsRenderList = [];
        for (let l of this.props.parentApi.data.poiLayers) {
            let buttonAction = (
                <button
                    type="button"
                    className="btn btn-warning"
                    onClick={() => setEditable(l.nom, true)}
                >
                    Rendre modifiable
                </button>
            );
            if (l.modifiable)
                buttonAction = (
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => setEditable(l.nom, false)}
                    >
                        Rendre non modifiable
                    </button>
                );

            equipementsRenderList.push(
                <li key={l.nom}>
                    <label role="heading" aria-level="2">
                        {l.theme} : {l.label}
                    </label>
                    {buttonAction}
                    <button
                        className="btn btn-warning"
                        onClick={() => updateEquipements(l)}
                    >
                        Mettre à jour
                    </button>
                    <button className="btn btn-danger" onClick={() => deletePOI(l)}>
                        Supprimer
                    </button>
                </li>
            );
        }

        return (
            <div>
                <div className="panel-body block-row">
                    <h3 className="panel-title pull-left">
                        Gestion des couches équipements
                    </h3>
                    <ul className="poi-admin-list">{equipementsRenderList}</ul>
                </div>
            </div>
        );
    }
}

export default POIManage;
