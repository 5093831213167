/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";

import { SketchPicker } from "react-color";
import reactCSS from "reactcss";
import { Link } from "react-router-dom";

import "bootstrap-icons/font/bootstrap-icons.css";
/**
 * This component is used to create an link
 */
class LinkLauncher extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id_analyse: String(props.id_analysis),
            name: props.name,
            representation: "link-launcher",
            widthDOM: window.innerWidth,
            color: props.color,
            displayColorStartPicker: false,
            displayColorEndPicker: false,
        };
    }

    componentDidMount() {
        // we parse ID to remove any eventual prefix due to territorial choice
        let id = this.props.id;
        if (typeof id === "string" && ["-"].includes(id)) {
            id = id.split("-")[-1];
        }

        this.props.parentApi.callbacks.updateLinkNameColor({
            id: parseInt(this.props.id, 10),
            color: this.props.color,
            id_analysis: this.props.id_analysis,
        });
        let widthDiv = document.getElementsByClassName("charts-legende")[0].offsetWidth;
        if (
            this.props.parentApi.data.tailleDiv !== widthDiv &&
            this.props.provenance === "tableau_de_bord_restitue"
        ) {
            this.props.parentApi.callbacks.tailleDiv(widthDiv);
        }
    }

    /**
     * Update component state with the color selected by user
     * @param {chaine de caractères} : color encoded in hexadecimal string
     */
    updateColor = (newColor) => {
        this.setState({
            color: newColor.hex,
        });
        // we parse ID to remove any eventual prefix due to territorial choice
        let id = this.props.id;
        if (["-"].includes(id)) {
            id = id.split("-")[-1];
        }
        let id_analyse = parseInt(this.state.id_analyse, 10);
        let color = newColor.hex;
        this.props.parentApi.callbacks.updateLinkNameColor({
            id: parseInt(id, 10),
            color,
            id_analyse,
        });
    };

    /**
     * Updates this.state.displayColorStartPicker
     * As long as this.state.displayColorStartPicker is equal to false, the color form is not displayed
     * As soon as it is true, the form appears and the administrator can select the color of his choice
     */
    handleColorStartClick = () => {
        this.setState({
            displayColorStartPicker: !this.state.displayColorStartPicker,
        });
    };

    /**
     * Set this.state.displayColorStartPicker to false to close the form when the color has been selected
     */
    handleColorStartCose = () => {
        this.setState({ displayColorStartPicker: false });
    };

    /**
     * The color of the link button
     */
    colorChoice() {
        const stylesColorDebut = reactCSS({
            default: {
                color: {
                    background: this.state.color,
                },
            },
        });

        return (
            <div className="formulaire-horizontal-alignement-vertical-centre">
                <label className="">Couleur de l'étiquette</label>
                <div className="custom-block">
                    <div
                        className="color-picker-swatch"
                        onClick={this.handleColorStartClick}
                    >
                        <div
                            className="color-picker-color"
                            style={stylesColorDebut.color}
                        />
                    </div>
                    {this.state.displayColorStartPicker ? (
                        <div className="color-picker-popover">
                            <div
                                className="color-picker-cover"
                                onClick={this.handleColorStartCose}
                            />
                            <SketchPicker
                                color={this.state.color}
                                onChange={this.updateColor}
                            />
                        </div>
                    ) : null}
                </div>
            </div>
        );
    }

    getSimulatorUrl() {
        let territoryType = "?zone=" + this.props.parentApi.data.zone.zone;
        let territory = "&maille=" + this.props.parentApi.data.zone.maille;
        let codeInseeTerritory = "&zone_id=" + this.props.parentApi.data.currentZone;
        let url = territoryType + territory + codeInseeTerritory + "&type=mobilité";
        return url;
    }

    render() {
        let id = this.state.id_analyse;
        let colorChoice = this.colorChoice();
        let colorRegion = this.state.color?.toUpperCase() + "66";

        const getItemStyle = {
            background: colorRegion,
            border: "solid " + this.state.color,
        };
        return (
            <div>
                <div className="charts-legende">
                    <Link
                        to={"/" + id + this.getSimulatorUrl()}
                        className="no-link-description"
                    >
                        <div className="btn">
                            <div
                                style={getItemStyle}
                                className="analysis-launcher-button"
                            >
                                {/* TODO : completer la génération automotique des noms des liens */}
                                Simulateur Mobilité
                            </div>
                        </div>
                    </Link>
                    <span className="only-print">
                        Contient un{" "}
                        <a
                            href={
                                window.location.origin +
                                "/" +
                                id +
                                this.getSimulatorUrl()
                            }
                        >
                            lien
                        </a>
                    </span>
                </div>
                {this.props.provenance !== "tableau_de_bord_restitue" && colorChoice}
            </div>
        );
    }
}

export default LinkLauncher;
