/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

// import config from '../settings.js';
// import configData from '../settings_data.js'

import * as jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { saveAs } from "file-saver";
import domtoimage from "dom-to-image";

/**
 * This component is used to print the cartographic interface of the application (the map)
 */
class Print {
    constructor() {
        this.output = "img";
        this.originalSize = undefined;
        this.printCanvas = undefined;
        this.map = undefined;
        this.mapExtent = undefined;
        this.printSettings = {
            format: "a4",
            resolution: 150,
            dim: [297, 210],
            bgColor: "#e8e5d8",
        };
    }

    /*
     * Reset print
     */
    resetPrint() {
        this.originalSize = undefined;
        this.printCanvas = undefined;
    }

    /*
     * Print the map
     */
    print(map, toggledMenu, typeTerritoire) {
        this.resetPrint();
        this.map = map;
        this.toggledMenu = toggledMenu;

        // Zoom in to have better fit in the image
        map.getView().setZoom(map.getView().getZoom() + 1);

        let width = Math.round(
            (this.printSettings.dim[0] * this.printSettings.resolution) / 25.4
        );
        let height = Math.round(
            (this.printSettings.dim[1] * this.printSettings.resolution) / 25.4
        );
        this.originalSize = /** @type {module:ol/size~Size} */ (map.getSize());
        this.mapExtent = map.getView().calculateExtent(this.originalSize);
        // Set print size
        let printSize = [width, height];
        map.setSize(printSize);
        map.getView().fit(this.mapExtent, { size: printSize });

        let obj = this;

        // Temporary canvas to compose image for print
        this.printCanvas = document.createElement("canvas");
        let tCtx = obj.printCanvas.getContext("2d");

        const size = map.getSize();
        obj.printCanvas.width = size[0];
        obj.printCanvas.height = size[1];
        map.once("rendercomplete", function () {
            // Fill the canvas with background color
            tCtx.fillStyle = obj.printSettings.bgColor;
            tCtx.fillRect(0, 0, obj.printCanvas.width, obj.printCanvas.height);
            Array.prototype.forEach.call(
                map.getViewport().querySelectorAll(".ol-layer canvas, canvas.ol-layer"),
                function (canvas) {
                    if (canvas.width > 0) {
                        const opacity =
                            canvas.parentNode.style.opacity || canvas.style.opacity;
                        tCtx.globalAlpha = opacity === "" ? 1 : Number(opacity);
                        let matrix;
                        matrix = [
                            parseFloat(canvas.style.width) / canvas.width,
                            0,
                            0,
                            parseFloat(canvas.style.height) / canvas.height,
                            0,
                            0,
                        ];

                        // Apply the transform to the export map context
                        CanvasRenderingContext2D.prototype.setTransform.apply(
                            tCtx,
                            matrix
                        );
                        const backgroundColor = canvas.parentNode.style.backgroundColor;
                        if (backgroundColor) {
                            tCtx.fillStyle = backgroundColor;
                            tCtx.fillRect(0, 0, canvas.width, canvas.height);
                        }
                        tCtx.drawImage(canvas, 0, 0);
                    }
                }
            );
            tCtx.globalAlpha = 1;
            tCtx.setTransform(1, 0, 0, 1, 0, 0);
            obj.composePrint(tCtx, obj);
            obj.backToOriginalMap();
        });
        map.renderSync();
    }

    /*
     * Compose the print
     */
    composePrint(tCtx, obj) {
        // Marge blanche en haut
        tCtx.fillStyle = "#ffffff";
        tCtx.fillRect(0, 0, obj.printCanvas.width, 75);

        let widthMargin = 310;
        if (window.devicePixelRatio > 1) {
            widthMargin = widthMargin * window.devicePixelRatio;
        }
        // Add margin on the left (for infos & legend)
        tCtx.fillRect(0, 65, widthMargin, obj.printCanvas.height);

        // Logo
        let promiseLogo = new Promise((resolve, reject) => {
            obj.getLogo(resolve, tCtx, obj.printCanvas);
        });

        // Add infos
        let promiseInfos = new Promise((resolve, reject) => {
            obj.getInfos(resolve, tCtx);
        });

        // Add legend
        let promiseLegend = new Promise((resolve, reject) => {
            obj.getLegend(resolve, tCtx);
        });

        // Add charts
        let promiseCharts = new Promise((resolve, reject) => {
            obj.getCharts(resolve, tCtx);
        });

        // Add copyright
        obj.addCopyright(tCtx);

        // Build final print
        Promise.all([promiseLogo, promiseInfos, promiseLegend, promiseCharts]).then(
            (values) => {
                obj.buildPrint("map");
            }
        );
    }

    /*
     * Build infos for print
     */
    getInfos(resolve, tCtx) {
        let infosContainer = document.getElementsByClassName("infos-content")[0];
        if (infosContainer) {
            html2canvas(infosContainer).then((canvas) => {
                tCtx.drawImage(canvas, 10, 70);
                resolve("infos-built");
            });
        } else resolve("no-infos");
    }

    /*
     * Build logo for print
     */
    getLogo(resolve, tCtx, printCanvas) {
        let img = new Image();
        img.src = "../img/logo_terristory_print.png";
        img.onload = () => {
            tCtx.drawImage(img, printCanvas.width / 2 - img.width / 2, 10);
            resolve("logo-built");
        };
    }

    /*
     * Build legend for print
     */
    getLegend(resolveLegend, tCtx) {
        let yLegend = 200;
        let promiseContainers = [];
        let legends = document.getElementsByClassName("legend-content");
        Array.from(legends).forEach((legendContainer) => {
            let promiseContainer = new Promise((resolve, reject) => {
                // Add items to print as you go
                if (legendContainer) {
                    // pdf class crashes export (?)
                    let childPdf = legendContainer.querySelector(".pdf");
                    if (childPdf) {
                        // Remove the pdf class that crashes the export
                        childPdf.classList.remove("pdf");
                    }

                    domtoimage
                        .toPng(legendContainer)
                        .then(function (dataUrl) {
                            var img = new Image();
                            img.src = dataUrl;
                            img.onload = () => {
                                let tempImg = document.body.appendChild(img);
                                html2canvas(tempImg).then((canvas) => {
                                    tCtx.drawImage(canvas, 10, yLegend);
                                    yLegend += tempImg.height + 10;
                                    document.body.removeChild(tempImg);
                                    resolve("legend-built");
                                });
                            };
                        })
                        .catch(function (error) {
                            console.error(
                                "Erreur lors de la génération de la légende",
                                error
                            );
                        });

                    if (childPdf) {
                        // Put back the pdf class to the concerned node
                        childPdf.classList.add("pdf");
                    }
                } else {
                    resolve("no-legend");
                }
            });
            promiseContainers.push(promiseContainer);
        });

        // Get all legend items
        Promise.all(promiseContainers).then((values) => {
            resolveLegend("legend-built");
        });
    }
    /*
     * Build charts for print
     */
    getCharts(resolve, tCtx) {
        let chartsContainer = document.getElementsByClassName("chartsContainer")[0];
        if (chartsContainer) {
            // Frame for charts
            let frameHeight = 220;
            if (window.devicePixelRatio > 1)
                frameHeight = frameHeight * window.devicePixelRatio;

            tCtx.fillRect(
                0,
                this.printCanvas.height - frameHeight,
                this.printCanvas.width,
                frameHeight
            );
            // Content
            let left = 0;
            if (this.toggledMenu) left = -250;
            html2canvas(chartsContainer).then((canvas) => {
                tCtx.drawImage(canvas, left, this.printCanvas.height - frameHeight);
                resolve("charts-built");
            });
        } else resolve("no-charts");
    }

    /*
     * Build copyright for print
     */
    addCopyright(tCtx) {
        tCtx.font = "20px Arial";
        tCtx.fillStyle = "#000000";
        tCtx.fillText("© TerriSTORY", this.printCanvas.width - 150, 100);
    }

    /*
     * Build the final image or PDF
     */
    buildPrint(name) {
        if (this.output === "img") {
            // IMAGE
            if (navigator.msSaveBlob) {
                navigator.msSaveBlob(this.printCanvas.msToBlob(), name + ".png");
            } else {
                this.printCanvas.toBlob(function (blob) {
                    saveAs(blob, name + ".png");
                });
            }
        } else {
            // PDF
            let data = this.printCanvas.toDataURL("image/jpeg");
            let pdf = new jsPDF("landscape", undefined, this.printSettings.format);
            pdf.addImage(
                data,
                "JPEG",
                0,
                0,
                this.printSettings.dim[0],
                this.printSettings.dim[1]
            );
            pdf.save(name + ".pdf");
        }
    }

    /*
     * Reset map
     */
    backToOriginalMap() {
        // Reset original map size
        this.map.setSize(this.originalSize);
        this.map.getView().fit(this.mapExtent, { size: this.originalSize });
        document.body.style.cursor = "auto";
        // back to original zoom
        this.map.getView().setZoom(this.map.getView().getZoom() - 1);
    }
}

export default Print;
