/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import { useState, useEffect, useRef, useCallback } from "react";
import Creatable from "react-select/creatable";
import ReactTable from "react-table-6";

import Api from "../../Controllers/Api";
import { TablePreview } from "./DataManage.js";
import DetailsPopup from "../DetailsPopup";
import config from "../../settings.js";
import configData from "../../settings_data.js";
import { buildRegionUrl, filterCaseInsensitive } from "../../utils.js";

import "bootstrap/dist/css/bootstrap.min.css";

/**
 * This component is used to manage data for indicators common to all regions
 */
function GererDonneesFrance({ connected, userInfos, parentApi, goToDataTab }) {
    const [fichierDonnees, majFichierDonnees] = useState(undefined);
    const [donneesTerritoriales, majDonneesTerritoriales] = useState(undefined);
    const [currentTableName, majCurrentTableName] = useState("");
    const [tableMaj, majTableMaj] = useState("");
    const [nouveauStatut, majNouveauStatut] = useState(false);
    const [showDetailsTablesElement, enableShowDetails] = useState(false);
    const [detailsTable, setDetailsTable] = useState("");
    const [fichierIntegre, majFichierIntegres] = useState(0);
    const [status, majStatus] = useState("");
    const [donneesCommunes, majDonneesCommunes] = useState([]);
    const [anneeCourante, majAnneeCourante] = useState(
        parseInt(new Date().getFullYear(), 10)
    );
    const [nbFichiersIntegres, majNbFichierIntegres] = useState(0);
    const [erreur, majErreur] = useState("");
    const [nomTableCourante, majNomTableCourante] = useState("");
    const [anneePerimetreGeographique, majAnneePerimetreGeographique] = useState(2020);
    const [analysis, majAnalysis] = useState(undefined);

    let currentRegion = parentApi.data.region;
    const prev = useRef({
        status,
        anneeCourante,
        fichierIntegre,
        tableMaj,
    }).current;

    /**
     * Updates component state with the list of regional schema data tables.
     * @param {string} region : Name of the selected region
     */

    const obtenirListeDonnees = useCallback((region) => {
        let urlTmp = config.api_analysis_data_url;
        let url = buildRegionUrl(urlTmp, region) + "/presence";
        Api.callApi(buildRegionUrl(url))
            .then((response) => {
                majDonneesTerritoriales(response);
            })
            .catch((e) => {
                majErreur(e.message);
            });
    }, []);

    /**
     * Updates the state of the component with the list of data tables from the schema france to display in the table
     * Information is then available on whether this table is also present in the regional schema
     * @param {string} region : Name of the selected region
     */

    const obtenirListeDonneesCommunes = useCallback(
        (region) => {
            let url = buildRegionUrl(
                config.api_analysis_donnees_communes,
                currentRegion
            ).replace("#global_region#", region);
            Api.callApi(buildRegionUrl(url))
                .then((response) => {
                    majDonneesCommunes(response);
                })
                .catch((e) => {
                    majErreur(e.message);
                });
        },
        [currentRegion]
    );

    useEffect(() => {
        obtenirListeDonnees(currentRegion);
        obtenirListeDonneesCommunes("france");
        if (prev.status !== status) {
            majNouveauStatut(true);
        }
        if (prev.anneeCourante !== anneeCourante) {
            majAnneeCourante(anneeCourante);
        }
        if (fichierIntegre !== prev.fichierIntegre || tableMaj !== prev.tableMaj) {
            obtenirListeDonnees(currentRegion);
            obtenirListeDonneesCommunes("france");
        }
    }, [
        nouveauStatut,
        anneeCourante,
        fichierIntegre,
        prev.anneeCourante,
        prev.fichierIntegre,
        prev.status,
        prev.tableMaj,
        currentRegion,
        status,
        tableMaj,
        obtenirListeDonnees,
        obtenirListeDonneesCommunes,
    ]);

    /**
     * Integrate a new data set common to all regions as a table located in the database within the common schema
     * @param {string} nom : Name of the table in which we insert the data.
     */
    function ajouterDonnees(nom) {
        // Object o type FormData
        let formData = new FormData();
        // Data validity
        if (!fichierDonnees) {
            majErreur("Veuillez sélectionner un fichier de données");
            return false;
        }

        if (!currentTableName) {
            majErreur("Veuillez choisir un nom pour la nouvelle table");
            return false;
        }

        if (
            !anneePerimetreGeographique ||
            anneePerimetreGeographique === "Sélectionnez une année"
        ) {
            majErreur(
                "Veuillez préciser de quand date le périmètre géographique de la donnée"
            );
            return false;
        }

        // update the form
        formData.append("fichier", fichierDonnees);
        formData.append("nomFichier", fichierDonnees.name);

        // Call API with file and metadata entered by user
        Api.callApi(
            buildRegionUrl(
                config.api_transfer_data_from_global_region_url,
                currentRegion
            ).replace("#table_name#", nom) +
                "?territoire=&date_perimetre=" +
                anneePerimetreGeographique +
                "&global_region=france",
            formData,
            "POST",
            "default"
        )
            .then((response) => {
                majStatus(response.message);
                majFichierIntegres(fichierDonnees + nbFichiersIntegres);
                majNbFichierIntegres(nbFichiersIntegres + 1);
            })
            .catch((e) => {
                majErreur(e.message);
            });
    }

    /**
     * Event-triggered.
     * This function is used to update the regional schema table
     * from data located in the france schema
     * @param  {json} tableRow : current row
     */
    function mettreAJourDonnee(tableRow) {
        if (fichierDonnees) {
            majStatus("Vous devez choisir un fichier de données");
            return false;
        }
        let r = window.confirm(
            "Attention, cette opération n'est pas réversible. Voulez vous continuer ?"
        );
        if (r !== true) {
            return;
        }
        let formData = new FormData(); // Forms management object
        formData.append("fichier", fichierDonnees); // fichierDonnees is a object readable by the API
        formData.append("nomFichier", fichierDonnees.name); // send the name of the file to the API
        let url =
            buildRegionUrl(config.api_analysis_data_url, currentRegion) +
            "/" +
            tableRow.props.row.nom +
            "?donnees_territoriales=True";
        Api.callApi(url, formData, "PUT", "default") // Launch the Python function to update dataset associated with a territorial maille
            .then((response) => {
                majStatus(response.message); // Python function response. We get the message confirming that the operation was successful
            })
            .catch((e) => majErreur(e.message)); // otherwise, an error message is displayed that is as clear and precise as possible in order to guide the administrator.
    }

    /**
     * Data file to import
     * @param {evenement} : event object from input[file] (file selection)
     * @param {tableRow} : current row of indicators table
     */
    const onFichierDataSelection = (evenement, tableRow) => {
        // update the state
        let donneesTerritoriales = JSON.parse(JSON.stringify(donneesCommunes));
        let nomTableCourante = "";
        for (let table of donneesTerritoriales) {
            if (tableRow.props.row.nom === table.nom) {
                nomTableCourante = table.nom;
                table.miseAJourEnabled = true;
            } else {
                table.miseAJourEnabled = false;
            }
        }

        majFichierDonnees(evenement.target.files[0]);
        majDonneesCommunes(donneesTerritoriales);
        majNomTableCourante(nomTableCourante);
        majErreur(undefined);
        majStatus(undefined);
    };

    function mettreAJourAnneePerimetreGeographique(evenement) {
        majAnneePerimetreGeographique(parseInt(evenement.target.value, 10));
    }

    function anneePerimetreGeographiqueDonnee(evenement, table) {
        /**
         * This method is used to modify the information on the date of the geographical perimeter of the data as it is.
         * @param {evenement} : event
         * @param {table} : name of the table of which the date of the geographical perimeter must be modified
         */
        let nouvelleAnnee = evenement.target.value;
        let r = window.confirm(
            "Souhaitez vous vraiment définir que le périmètre géographique de la table " +
                table +
                " date de " +
                nouvelleAnnee +
                " ?"
        );
        if (r !== true) {
            return;
        }
        let url =
            buildRegionUrl(config.api_mise_a_jour_perimetre_origine, currentRegion) +
            "?global_region=france";
        Api.callApi(
            url,
            JSON.stringify({ annee_perimetre: nouvelleAnnee, nom: table }),
            "PUT"
        )
            .then((response) => {
                majStatus(response.message);
                majAnneeCourante(nouvelleAnnee);
            })
            .catch((e) => majAnalysis(analysis));
    }

    function copierTableDansSchemaRegional(maj, table, datePerimetre) {
        /**
         *
         * @param {maj} booléen : boolean used to know if it is an update of a table already present
         * in the regional schema or an import of a table that does not exist in the regional scheme yet
         * @param {table} string : table name
         * @param {datePerimetre} integer : Date of the geographical perimeter of the data
         */
        let urlMaj = 1;
        if (!maj) {
            urlMaj = 0;
        }
        let url =
            buildRegionUrl(
                config.api_copier_table.replace("#table#", table),
                parentApi.data.region
            ) +
            "?maj=" +
            urlMaj +
            "&date_perimetre=" +
            datePerimetre;
        Api.callApi(url, null, "PUT")
            .then((response) => {
                majStatus(response.message);
                majTableMaj(table + maj);
            })
            .catch((e) => {
                majErreur(e.message);
                majAnalysis(undefined);
            });
    }

    /**
     * Builds the input field for the name of the table to create
     */
    const construireNomTable = () => {
        let nomTable = "";
        if (donneesCommunes) {
            nomTable = (
                <div style={{ width: 300 }}>
                    <Creatable
                        name="table-name"
                        classNamePrefix="select"
                        options={donneesCommunes.map(({ nom }) => ({
                            value: nom,
                            label: nom,
                            isDisabled: true,
                        }))}
                        value={{ value: currentTableName, label: currentTableName }}
                        onChange={(e) => majCurrentTableName(e.value)}
                        formatCreateLabel={(value) => `Créer la table "${value}"`}
                        createOptionPosition="first"
                        isValidNewOption={(inputValue, _, options) =>
                            inputValue.match(/^[a-z0-9_]+$/) &&
                            options.every((option) => option.value !== inputValue)
                        }
                        noOptionsMessage={() => "Format incorrect"}
                    />
                </div>
            );
        }
        return nomTable;
    };

    const onFichierIndicateurSelection = (evenement) => {
        // update the state
        majFichierDonnees(evenement.target.files[0]);
    };

    function toggleDetailsCell(details) {
        // we toggle between displaying details or not
        if (!showDetailsTablesElement) {
            enableShowDetails(true);
            setDetailsTable(details);
        } else {
            enableShowDetails(false);
            setDetailsTable("");
        }
    }

    /**
     * Callback to update an indicator
     * @param  {json} analysis : current row
     */
    if (donneesCommunes && donneesTerritoriales) {
        for (let donnee of donneesTerritoriales) {
            for (let donneeCommune of donneesCommunes) {
                if (donneeCommune.nom === donnee.nom) {
                    donneeCommune.presenceDansSchemaRegional = true;
                }
            }
        }
    }

    let message = "";
    if (status) {
        message = <div className="alert alert-success">{status}</div>;
    } else if (erreur) {
        message = <div className="alert alert-warning">{erreur}</div>;
    }

    let tablesDashboard = "";

    if (connected && userInfos) {
        if (userInfos.profil === "admin") {
            const columns = [
                {
                    Header: "Nom de la table",
                    accessor: "nom",
                },
                {
                    Header: "Millésime du périmètre géographique",
                    Cell: (props) => {
                        let listePerimetresGeo = [];
                        for (
                            let i = configData.anneeMin;
                            i <= new Date().getFullYear();
                            i++
                        ) {
                            let option = <option value={i}>{i}</option>;
                            if (i === props.original.date_perimetre) {
                                option = (
                                    <option value={i} selected>
                                        {i}
                                    </option>
                                );
                            }
                            listePerimetresGeo.push(option);
                        }
                        return (
                            <div className="actions">
                                <select
                                    onChange={(evenement) =>
                                        anneePerimetreGeographiqueDonnee(
                                            evenement,
                                            props.original.nom
                                        )
                                    }
                                >
                                    {listePerimetresGeo}
                                </select>
                            </div>
                        );
                    },
                },
                {
                    Header: "Aperçu de la table",
                    accessor: "table_preview",
                    Cell: (props) => {
                        return (
                            <div>
                                <img
                                    className="table_preview_icon"
                                    src="img/table_preview.svg"
                                    alt="Visualiser une partie des données"
                                    title="Visualiser une partie des données"
                                    onClick={() =>
                                        toggleDetailsCell(
                                            <TablePreview
                                                data={props.original.header}
                                                additionalElements={
                                                    <p className="export-data-button-in-popup">
                                                        <a
                                                            className="btn btn-info normal-size"
                                                            href={buildRegionUrl(
                                                                config.api_export_common_dataset_table,
                                                                currentRegion
                                                            )
                                                                .replace(
                                                                    "#global_region#",
                                                                    "france"
                                                                )
                                                                .replace(
                                                                    "#table_name#",
                                                                    props.original.nom
                                                                )}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            Télécharger
                                                        </a>
                                                        <br />
                                                        <em>
                                                            Attention, fichier de taille
                                                            importante, le temps
                                                            d'attente peut être long !
                                                        </em>
                                                    </p>
                                                }
                                            />
                                        )
                                    }
                                />
                            </div>
                        );
                    },
                    filterable: false,
                },
                {
                    id: "action",
                    Header: "Action",
                    /**
                     * @param {objet clé => valeur} props : table row properties
                     * Here, only the "original" key is useful to us. Here is the structure
                     * {
                     * indicateur: boolean which gives false if the data is not directly involved in an indicator true otherwise,
                     * nom:  Name of the table to display in the array,
                     * presente: boolean which returns true if the data is already present in the database and false otherwise,
                     * }
                     */
                    Cell: (props) => {
                        let caracteristiquesLigne = props.original;
                        let nom = caracteristiquesLigne.nom;
                        let presence = (
                            <span style={{ backgroundColor: "lightcoral" }}>
                                Données non présente dans le schéma régional
                            </span>
                        );
                        let datePerimetre = props.original.date_perimetre;
                        let boutonImporterDansLeSchemaRegional = (
                            <button
                                className="btn btn-info"
                                onClick={() =>
                                    copierTableDansSchemaRegional(
                                        false,
                                        nom,
                                        datePerimetre
                                    )
                                }
                            >
                                Importer
                            </button>
                        );
                        if (caracteristiquesLigne.presenceDansSchemaRegional) {
                            presence = (
                                <span style={{ backgroundColor: "lightgreen" }}>
                                    Données présentes dans le schéma régional
                                </span>
                            );
                            boutonImporterDansLeSchemaRegional = (
                                <button
                                    className="btn btn-warning"
                                    onClick={() =>
                                        copierTableDansSchemaRegional(
                                            true,
                                            nom,
                                            datePerimetre
                                        )
                                    }
                                >
                                    Importer de nouveau
                                </button>
                            );
                        }
                        // If the administrator has selected a file, we hide the information on the presence of the table in the database
                        let presenceEnBase = (
                            <div
                                className={
                                    !props.original.miseAJourEnabled ? "" : "hidden"
                                }
                            >
                                {presence}
                            </div>
                        );
                        // When selecting a file for a table, we update the state of the component with the name of the selected table (Cf. onDataSelection method)
                        // When we display the messages that indicate either an error or that the operation went well, we need to know on which row of the table
                        // we are displaying it and to do this we check that the name of the table of the row is indeed that of the state of the component.
                        if (caracteristiquesLigne.nom === nomTableCourante) {
                            if (status) {
                                presenceEnBase = (
                                    <div className={"alert alert-success"}>
                                        {status}
                                    </div>
                                );
                            }
                            if (erreur) {
                                presenceEnBase = (
                                    <div className={"alert alert-warning"}>
                                        {erreur}
                                    </div>
                                );
                            }
                        }
                        let bouton = (
                            <div>
                                <button
                                    className={
                                        "btn btn-info " +
                                        (props.original.miseAJourEnabled
                                            ? ""
                                            : "hidden")
                                    }
                                    onClick={() => {
                                        ajouterDonnees(nom);
                                    }}
                                >
                                    Intégrer la donnée
                                </button>
                                {presenceEnBase}
                            </div>
                        );
                        let input = (
                            <input
                                type="file"
                                onChange={(evenement) => {
                                    onFichierDataSelection(evenement, {
                                        props,
                                    });
                                }}
                            />
                        );
                        // If the data is already present in the database, the text "Intégrer la donnée" is changed to " Mettre à jour".
                        if (caracteristiquesLigne.indicateur) {
                            input = (
                                <span>
                                    Cette donnée est utilisée pour un indicateur
                                </span>
                            );
                            bouton = (
                                <button
                                    className={"btn btn-warning "}
                                    onClick={goToDataTab}
                                >
                                    Accéder à l'outil de gestion des données{" "}
                                </button>
                            );
                        } else if (
                            !caracteristiquesLigne.indicateur &&
                            caracteristiquesLigne.presenceDansSchemaRegional
                        ) {
                            bouton = (
                                <div>
                                    <button
                                        className={
                                            "btn btn-info " +
                                            (props.original.miseAJourEnabled
                                                ? ""
                                                : "hidden")
                                        }
                                        onClick={() => mettreAJourDonnee({ props })}
                                    >
                                        Mettre à jour la donnée
                                    </button>
                                    {presenceEnBase}
                                </div>
                            );
                        }
                        return (
                            <div key={nom}>
                                <div>
                                    {input}
                                    {boutonImporterDansLeSchemaRegional}
                                </div>
                                <div>{bouton}</div>
                            </div>
                        );
                    },
                    filterable: false,
                },
            ];
            tablesDashboard = (
                <div className="panel-body user-scenarii">
                    <ReactTable
                        data={donneesCommunes}
                        columns={columns}
                        className="-striped donnees-territoires"
                        defaultPageSize={30}
                        filterable={true}
                        defaultFilterMethod={(filter, row) =>
                            filterCaseInsensitive(filter, row)
                        }
                    />
                </div>
            );
        }
    }

    let anneeMin = configData.anneeMinPerimetre;
    let listeAnnees = [];

    for (let i = anneeMin; i <= anneeCourante; i++) {
        listeAnnees.push({ value: i, label: i });
    }

    let listePerimetresGeo = [
        <option key={"selection-annee"}>{"Sélectionnez une année"}</option>,
    ];
    let cle = 0;
    for (let i = configData.anneeMin; i <= new Date().getFullYear(); i++) {
        listePerimetresGeo.push(
            <option key={cle} value={i}>
                {i}
            </option>
        );
        cle += 1;
    }
    let nomTable = construireNomTable();

    let blockNomTable = (
        <div className="form-maj-territoires">
            <label>Nom de la table</label>
            {nomTable}
        </div>
    );

    let blockDataSelect = (
        <div className="form-maj-territoires">
            <label>Année du périmètre géographique</label>
            <p>
                <select
                    onChange={(evenement) =>
                        mettreAJourAnneePerimetreGeographique(evenement)
                    }
                >
                    {listePerimetresGeo}
                </select>
            </p>
            <div>
                <input type="file" onChange={onFichierIndicateurSelection} />
            </div>
        </div>
    );
    return (
        <div>
            <h3>Registre de données communes</h3>
            {tablesDashboard}
            <div className="flex">
                <div className="form-maj-territoires">
                    <p>
                        Si vous souhaitez ajouter des données au socle commun, il vous
                        faut fournir un fichier <strong>csv</strong> comportant les
                        entêtes suivants :
                    </p>
                    <ul>
                        <li>
                            <code>commune</code> le code INSEE des communes (sur cinq
                            caractères, attention de bien conserver les 0 pour les
                            départements <code>&lt; 10</code>)
                        </li>
                        <li>
                            <code>valeur</code> avec la donnée (sous forme de nombre
                            flottant utilisant un <strong>point</strong> et non une
                            virgule pour les décimales)
                        </li>
                        <li>
                            les autres colonnes seront considérées comme contenant des
                            informations de catégories et devront donc contenir des
                            entiers correspondant aux IDs des catégories.
                        </li>
                    </ul>
                </div>
                {blockNomTable}
                {blockDataSelect}
            </div>
            <button
                className="btn btn-info form-maj-territoires"
                onClick={() => {
                    ajouterDonnees(currentTableName);
                }}
            >
                Intégrer les données
            </button>
            {message}
            {showDetailsTablesElement && (
                <DetailsPopup
                    title="Aperçu de la table"
                    content={detailsTable}
                    show={showDetailsTablesElement}
                    emptyMsg="Nothing to show"
                    callbackAfterClosing={() => toggleDetailsCell()}
                />
            )}
        </div>
    );
}

export default GererDonneesFrance;
