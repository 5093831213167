/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import { Bar } from "react-chartjs-2";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";

import { buildRegionUrl, createPdfMethodoLink, exportToCSV } from "../utils.js";
import Api from "../Controllers/Api.js";
import { GenericExportButton } from "./ExportButton.js";
import configData from "../settings_data.js";
import config from "../settings.js";
import ErrorPage from "./ErrorPage.js";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-accessible-accordion/dist/fancy-example.css";
import "react-tabs/style/react-tabs.css";
import "../style/territorialsynthesis.css";

const pluralizeTerritoryName = (territoryName) => {
    territoryName = territoryName.toUpperCase();
    if (territoryName === "DEPARTEMENT") {
        return "DÉPARTEMENTS";
    } else {
        return territoryName;
    }
};

/**
 * This component is used to manage the rendering of the territorial synthesis table
 */
class TerritorialSynthesis extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isFetchingData: false, data: undefined, isLoadingPDF: false };
    }

    componentDidMount() {
        // if we have not gotten data yet
        if (this.state.data === undefined) {
            this.fetchData();
        }
    }

    componentDidUpdate(prevState) {
        // if we have not gotten data yet
        if (this.state.data === undefined) {
            this.fetchData();
        }
        if (this.state.isLoadingPDF && !prevState.isLoadingPDF) {
            this.exportPdf();
        }
    }

    componentWillUnmount() {
        if (this.promise) this.promise.abort();
    }

    fetchData() {
        // waiting for consulting module to be ready
        if (
            !this.props.parentApi.data ||
            !this.props.parentApi.controller.gestionSuiviConsultations ||
            this.state.isFetchingData
        ) {
            return;
        }
        this.setState({
            isFetchingData: true,
        });

        let id = this.props.parentApi.data.currentZone;
        if (this.props.parentApi.data.zone.zone === "region") {
            id = 1;
        }
        let urlIdUtilisateur =
            "?id_utilisateur=" +
            this.props.parentApi.controller.gestionSuiviConsultations.idUtilisateur;

        // Get actions from API
        let dataSource =
            config.api_notes_territorialsynthesis_url +
            this.props.parentApi.data.zone.zone +
            "/" +
            id +
            urlIdUtilisateur;
        let pZone = "?zone=" + this.props.parentApi.data.zone.zone;
        let pMaille = "&maille=" + this.props.parentApi.data.zone.maille;
        let pZoneId = "&zone_id=" + this.props.parentApi.data.currentZone;
        let nomTerritoire =
            "&nom_territoire=" +
            encodeURI(
                this.props.parentApi.controller.zonesManager.getZoneName(
                    this.props.parentApi.data.currentZone,
                    this.props.parentApi.data.zone
                )
            );
        if (
            !this.props.parentApi.controller.zonesManager.getZoneName(
                this.props.parentApi.data.currentZone,
                this.props.parentApi.data.zone
            )
        ) {
            nomTerritoire =
                "&nom_territoire=" + encodeURI(this.props.parentApi.data.nomTerritoire);
        }
        if (this.promise) this.promise.abort();
        this.promise = Api.callApi(
            buildRegionUrl(dataSource, this.props.parentApi.data.region),
            null,
            "GET"
        );
        this.promise
            .then((json) => {
                // Set states
                this.props.parentApi.callbacks.mettreAJourParametresUrls(
                    pZone + pMaille + pZoneId + nomTerritoire
                );
                this.props.parentApi.callbacks.updateAnalysis("");
                this.setState({
                    data: json,
                    isFetchingData: false,
                });
            })
            .catch((error) => {
                if (error.name === "AbortError") return;
                console.error(error);
                this.setState({ isFetchingData: false });
            });
    }

    /**
     * This function is used to export summary table data to CSV file. The name
     * of the CSV file will be `donnees_synthese_territoriale_ZONETYPE_ZONEID.csv`.
     */
    exportData(format, includePDF, resolve) {
        // we create columns names array
        const currentZoneType = this.props.parentApi.data.zone.zone;
        const columns = [
            "Thème",
            "N°",
            "Indicateur",
            "Unite",
            "Valeur territoire",
            "Valeur normalisée",
            "Médiane (" + currentZoneType + ")",
            "Valeur normalisée médiane",
            "Minimum (" + currentZoneType + ")",
            "Maximum (" + currentZoneType + ")",
            "Année de la donnée",
            "Données estimées",
        ];
        // we create rows from data and combine them with columns
        const rows = this.state.data.map((d, i) => {
            const isEstimated = this.props.parentApi.controller.analysisManager
                .getAnalysisEstimatedYears(d.id)
                .includes(d.years);
            // raw data (same order than columns above)
            let data = [
                d.theme,
                d.numero,
                d.nom,
                d.unit.replace("/", " / "),
                d.value === -9999 ? "confidentielle" : d.value,
                d.note,
                d.median,
                d.median_note,
                d.vmin,
                d.vmax,
                d.years,
                isEstimated ? "Oui" : "Non",
            ];
            // final object for current row
            let result = {};
            columns.forEach((key, i) => (result[key] = data[i]));
            return result;
        });

        let pdfMethodo = "",
            pdfName = "",
            typeExport = "csv",
            encoding = "utf-8";
        if (includePDF) {
            pdfMethodo = createPdfMethodoLink(
                config.methodo_url,
                this.props.parentApi.data.region,
                config.territorialsynthesis_methodo
            );
            pdfName = "methodologie_synthese_territoriale.pdf";
            typeExport = "zip";
        }

        if (format === "excel") {
            encoding = "iso-8859-15";
        }

        // we export data to csv
        exportToCSV(
            rows,
            typeExport,
            "donnees_synthese_territoriale_" +
                this.props.parentApi.data.zone.zone +
                "_" +
                this.props.parentApi.data.currentZone,
            pdfMethodo,
            pdfName,
            () => {
                resolve();
            },
            encoding
        );
    }

    handleExportButton() {
        this.setState({
            isLoadingPDF: true,
        });
    }

    getTableSyntheseSize() {
        let lastTheme = "";
        let cpt = 0;
        for (let d of this.state.data) {
            cpt++;
            if (lastTheme !== d.theme) {
                cpt++;
            }
            lastTheme = d.theme;
        }
        return cpt;
    }
    /**
     * Export current page in PDF using different sections to divide the table
     * in two parts.
     */
    exportPdf() {
        const tableSyntheseSize = this.getTableSyntheseSize();
        // From https://medium.com/@shivekkhurana/how-to-create-pdfs-from-react-components-client-side-solution-7f506d9dfa6d
        const a4WidthMm = 297;
        const a4HeightMm = 210;
        let pdf = undefined;
        pdf = new jsPDF("l", "mm", [a4HeightMm, a4WidthMm]);
        const width = pdf.internal.pageSize.getWidth();
        const margin = 20;

        let input = document.getElementById("part1");
        let ratio1 = input.offsetHeight / input.offsetWidth;
        html2canvas(input).then((canvas) => {
            const imgDataPart1 = canvas.toDataURL("image/png");
            input = document.getElementById("part2");
            let ratio3 = input.offsetHeight / input.offsetWidth;
            html2canvas(input, {
                onclone: (input) => {
                    Array.from(input.querySelectorAll(".not-printable")).forEach(
                        (e) => {
                            e.style.visibility = "hidden";
                        }
                    );
                    Array.from(input.querySelectorAll(".printable")).forEach((e) => {
                        e.style.visibility = "visible";
                    });
                },
            }).then((canvas) => {
                const imgDataPart2 = canvas.toDataURL("image/png");
                input = document.getElementById("part3");
                let ratio4 = input.offsetHeight / input.offsetWidth;
                html2canvas(input, {
                    onclone: (input) => {
                        Array.from(input.querySelectorAll(".not-printable")).forEach(
                            (e) => {
                                e.style.visibility = "hidden";
                            }
                        );
                        Array.from(input.querySelectorAll(".printable")).forEach(
                            (e) => {
                                e.style.visibility = "visible";
                            }
                        );
                    },
                })
                    .then((canvas) => {
                        const imgDataPart3 = canvas.toDataURL("image/png");
                        pdf.addImage(
                            imgDataPart1,
                            "PNG",
                            10,
                            10,
                            width - margin,
                            ratio1 * (width - margin)
                        );
                        pdf.addPage();
                        pdf.addImage(
                            imgDataPart2,
                            "PNG",
                            10,
                            10,
                            width - margin,
                            ratio3 * (width - margin)
                        );
                        if (tableSyntheseSize >= 20) {
                            pdf.addPage();
                            if (imgDataPart3.indexOf("image/png;base64,") !== -1) {
                                pdf.addImage(
                                    imgDataPart3,
                                    "PNG",
                                    10,
                                    10,
                                    width - margin,
                                    ratio4 * (width - margin)
                                );
                            }
                        }
                        pdf.save(`territorialsynthesis.pdf`);
                    })
                    .then(() => {
                        this.setState({
                            isLoadingPDF: false,
                        });
                    });
            });
        });
    }

    renderFooter(footer, pdfMethodo, isAnyAnalysisEstimated) {
        const currentDate = new Date().toLocaleDateString("fr-FR");
        return (
            <div>
                <div style={{ textAlign: "right" }}>
                    {isAnyAnalysisEstimated ? "(e) = données estimées" : ""}
                </div>
                <div className="centered-row not-printable">
                    <button
                        type="button"
                        className="territorialsynthesis-methodo btn btn-primary"
                        data-dismiss="alert"
                        aria-label="Print"
                        onClick={() => this.handleExportButton()}
                        disabled={this.state.isLoadingPDF}
                    >
                        {this.state.isLoadingPDF && (
                            <>
                                <span
                                    className="spinner-border spinner-border-sm me-1"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                            </>
                        )}
                        Exporter en pdf
                    </button>
                    <GenericExportButton
                        parentApi={this.props.parentApi}
                        methodoPdf={pdfMethodo}
                        className="csv csv-float-widget"
                        exportFunction={(format, includePDF, resolve) =>
                            this.exportData(format, includePDF, resolve)
                        }
                    />
                </div>
                <div className="printable">
                    <div className="footer-print">
                        <div
                            className={
                                "logo-territorialsynthesis-print logo-territorialsynthesis-" +
                                this.props.parentApi.data?.theme
                            }
                        ></div>
                        <span>{footer["left"]}</span>
                        <span>{footer["right"]}</span>
                    </div>
                    <div className="export-date">
                        Export réalisé le {currentDate} depuis le site{" "}
                        <em>www.terristory.fr</em>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        if (this.state.isFetchingData) {
            return (
                <div className="plan-actions widgets full-screen-widget">
                    <div className="loader centered-widget"></div>
                </div>
            );
        }
        if (!this.state.data) {
            return <ErrorPage message={"Non accessible pour ce territoire"} />;
        }

        // Title
        let zoneName = this.props.parentApi.controller.zonesManager.getZoneName(
            this.props.parentApi.data.currentZone,
            this.props.parentApi.data.zone
        );
        let title = zoneName;
        if (!title) {
            title = this.props.parentApi.data.nomTerritoire;
        }
        if (this.props.parentApi.data.zone.zone === "region") {
            title = "Région";
        }

        let labels = [];
        let dataCapi = [];
        let dataMediane = [];
        let backgroundColors = [];
        for (let d of this.state.data) {
            labels.push(d.nom);
            dataCapi.push(d.note);
            dataMediane.push(d.median_note);
            if (d.median_note >= d.note) {
                backgroundColors.push(configData.notesChartZoneColorSup);
            } else {
                backgroundColors.push(configData.notesChartZoneColor);
            }
        }

        let isAnyAnalysisEstimated = false;

        // Chart
        let chartNotesData = {
            labels: labels,
            datasets: [
                {
                    label: title,
                    backgroundColor: backgroundColors,
                    borderColor: backgroundColors,
                    borderWidth: 1,
                    data: dataCapi,
                },
                {
                    label:
                        "Médiane des " +
                        pluralizeTerritoryName(
                            this.props.parentApi.data.zone.zone.toUpperCase()
                        ),
                    backgroundColor: configData.notesChartMedianeColor,
                    borderColor: configData.notesChartMedianeColor,
                    borderWidth: 1,
                    data: dataMediane,
                },
            ],
        };

        let chartOptionsNotes = {
            maintainAspectRatio: false,
            responsive: false,
            title: {
                display: true,
                padding: 10,
            },
            legend: {
                position: "top",
                labels: {
                    boxWidth: 10,
                },
            },
            width: 900,
            height: 545,
            scales: {
                xAxes: [
                    {
                        stacked: false,
                        ticks: {
                            autoSkip: false,
                            maxRotation: 45,
                            minRotation: 45,
                        },
                        gridLines: {
                            display: false,
                        },
                    },
                ],
                yAxes: [
                    {
                        scaleLabel: {
                            display: true,
                            labelString: "perfomance",
                        },
                        stacked: false,
                        ticks: {
                            beginAtZero: true,
                        },
                    },
                ],
            },
        };

        let chart = (
            <div className="block-row">
                <Bar
                    width={chartOptionsNotes.width}
                    height={chartOptionsNotes.height}
                    options={chartOptionsNotes}
                    data={chartNotesData}
                />
            </div>
        );
        let tableRows = [];
        let lastTheme = "";
        for (let d of this.state.data) {
            const isEstimated = this.props.parentApi.controller.analysisManager
                .getAnalysisEstimatedYears(d.id)
                .includes(d.years);
            isAnyAnalysisEstimated |= isEstimated;

            let row = undefined;
            if (lastTheme !== d.theme) {
                // Add a theme separator
                row = (
                    <tr>
                        <th className="separator table-theme"></th>
                        <td className="separator table-num"></td>
                        <td className="separator table-indicateur"></td>
                        <td className="separator"></td>
                        <td className="separator"></td>
                        <td className="separator"></td>
                        <td className="separator"></td>
                        <td className="separator"></td>
                        <td className="separator"></td>
                        <td className="separator"></td>
                        <td className="separator"></td>
                    </tr>
                );
                tableRows.push(row);
            }
            row = (
                <tr key={d.nom}>
                    <th className="territorialsynthesis-table-header table-theme">
                        {d.theme}
                    </th>
                    <td className="table-num">{d.numero}</td>
                    <td className="table-indicateur">{d.nom}</td>
                    <td>{d.unit.replace("/", " / ")}</td>
                    {/* using a conditional operator to replace -9999 values with the text value "confidentielle" */}
                    <td>
                        {d.value === -9999 ? (
                            "confidentielle"
                        ) : (
                            <NumberFormat
                                value={d.value}
                                displayType={"text"}
                                thousandSeparator={" "}
                            />
                        )}
                    </td>
                    <td>
                        <NumberFormat
                            value={d.note}
                            displayType={"text"}
                            thousandSeparator={" "}
                        />
                    </td>
                    <td>
                        <NumberFormat
                            value={d.median}
                            displayType={"text"}
                            thousandSeparator={" "}
                        />
                    </td>
                    <td>
                        <NumberFormat
                            value={d.median_note}
                            displayType={"text"}
                            thousandSeparator={" "}
                        />
                    </td>
                    <td>
                        <NumberFormat
                            value={d.vmin}
                            displayType={"text"}
                            thousandSeparator={" "}
                        />
                    </td>
                    <td>
                        <NumberFormat
                            value={d.vmax}
                            displayType={"text"}
                            thousandSeparator={" "}
                        />
                    </td>
                    <td>
                        {d.years}
                        {isEstimated && " (e)"}
                    </td>
                </tr>
            );
            tableRows.push(row);
            lastTheme = d.theme;
        }

        let pdfMethodo = createPdfMethodoLink(
            config.methodo_url,
            this.props.parentApi.data.region,
            config.territorialsynthesis_methodo
        );

        // Table header
        const zoneUpper = this.props.parentApi.data.zone.zone
            .toUpperCase()
            .replace("DEPARTEMENT", "DEPART.");
        let table = (
            <table className="table-notes table-notes-header">
                <thead>
                    <tr className="territorialsynthesis-table-header">
                        <th className="table-theme">Thème</th>
                        <td className="table-num">N°</td>
                        <td className="table-indicateur">Indicateur</td>
                        <td>Unite</td>
                        <td>Valeur territoire</td>
                        <td>Valeur normalisée</td>
                        <td>Médiane ({zoneUpper})</td>
                        <td>
                            Valeur normalisée
                            <br />
                            médiane
                        </td>
                        <td>Minimum ({zoneUpper})</td>
                        <td>Maximum ({zoneUpper})</td>
                        <td>Année de la donnée</td>
                    </tr>
                </thead>
                <tbody></tbody>
            </table>
        );

        // We need to isolate each line of the table in a separate table, so when printing in PDF we can break the table on multiple pages
        let tableLine1 = [];
        let tableLine2 = [];
        let indTable = 0;
        let maxLinePerPage = 20;
        for (let row of tableRows) {
            let id = "table-" + indTable;
            indTable += 1;
            let line = (
                <div id={id} key={id} className="centered-row">
                    <table className="table-notes">
                        <tbody>{row}</tbody>
                    </table>
                </div>
            );

            if (indTable <= maxLinePerPage) {
                tableLine1.push(line);
            } else {
                tableLine2.push(line);
            }
        }
        let classTheme = this.props.parentApi.data.theme;
        let territorialsynthesis_notes_header =
            this.props.parentApi.data.settings.cesba_notes;
        if (!territorialsynthesis_notes_header) {
            return (
                <ErrorPage
                    message={
                        "Non disponible pour cette région (erreur de configuration des méta-éléments de la page)"
                    }
                />
            );
        }
        let territorialsynthesis_notes_elements =
            this.props.parentApi.data.settings.cesba_elements;
        if (!territorialsynthesis_notes_elements) {
            return (
                <ErrorPage
                    message={
                        "Non disponible pour cette région (erreur de configuration des méta-éléments de la page)"
                    }
                />
            );
        }
        // eslint-disable-next-line
        let territorialsynthesis_notes_elements_json = JSON.parse(
            territorialsynthesis_notes_elements.replace("'", "'")
        );

        let footer = {};
        footer.left = [];
        footer.right = [];
        if (this.props.parentApi && this.props.parentApi.data) {
            // Construction of the footer
            if (this.props.parentApi.data.settings.footer) {
                for (let dir of ["left", "right"]) {
                    for (let block of JSON.parse(
                        this.props.parentApi.data.settings.footer
                    )[dir]) {
                        let title = block.title;
                        let subtitle = block.subtitle;
                        let key = title + Date.now();
                        let logos = [];
                        for (let logo of block.logos) {
                            logos.push(
                                <img
                                    key={logo.title}
                                    className="footer-logo"
                                    src={"img/" + logo.img}
                                    alt={logo.title}
                                />
                            );
                        }
                        footer[dir].push(
                            <div className="footer-block" key={key}>
                                <label>{title}</label>
                                <div>{logos}</div>
                                <label className="subtitle">{subtitle}</label>
                            </div>
                        );
                    }
                }
            }
        }
        const footerComposant = this.renderFooter(
            footer,
            pdfMethodo,
            isAnyAnalysisEstimated
        );
        return (
            <div
                className="territorialsynthesis notes widgets full-screen-widget"
                id="territorialsynthesis"
            >
                <div id="myMm" style={{ height: "1mm" }} />

                <Link
                    className="back-to-map"
                    to={"/" + this.props.parentApi.data.urlPartageable}
                >
                    <button
                        type="button"
                        className="close close-big"
                        data-dismiss="alert"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Link>

                <div
                    className={"page-content " + classTheme}
                    id="territorialsynthesis-content"
                >
                    <div id="part1">
                        <div className="title centered-row">
                            <h2 className="territorialsynthesis-title">
                                {" "}
                                {territorialsynthesis_notes_elements_json["titre"]}{" "}
                            </h2>
                            <div
                                className="territorialsynthesis-title territorialsynthesis-logo"
                                dangerouslySetInnerHTML={{
                                    __html: territorialsynthesis_notes_elements_json[
                                        "cesba-logo"
                                    ][0]["logo-entete"],
                                }}
                            ></div>
                            <h3 className="territorialsynthesis-territoire">
                                Territoire : {title}
                            </h3>
                        </div>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: territorialsynthesis_notes_header,
                            }}
                        ></div>
                        {!this.state.isLoadingPDF && (
                            <div className="title title-margin-bottom centered-row">
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    className="territorialsynthesis-methodo btn btn-primary"
                                    href={pdfMethodo}
                                >
                                    Méthodologie
                                </a>
                            </div>
                        )}
                        <div className="title centered-row">
                            <label>
                                Profil du territoire par rapport à la valeur médiane*
                                des{" "}
                                {pluralizeTerritoryName(
                                    this.props.parentApi.data.zone.zone.toUpperCase()
                                )}
                            </label>
                        </div>

                        <div className="centered-row">{chart}</div>
                        <p>
                            * La médiane est la valeur qui sépare en deux parties les
                            territoires : 50% des territoires sont en dessous de la
                            médiane et 50% des territoires sont au-dessus de la médiane{" "}
                        </p>
                    </div>

                    <div id="part2">
                        <div className="title centered-row territorialsynthesis-title-table">
                            <label>Tableau de synthèse</label>
                        </div>

                        <div className="centered-row">{table}</div>
                        {tableLine1}
                        {tableRows.length <= 20 && footerComposant}
                    </div>
                    <div id="part3">
                        <div>{tableLine2}</div>
                        {tableRows.length >= 20 && footerComposant}
                    </div>
                </div>
            </div>
        );
    }
}

export default TerritorialSynthesis;
