/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import { Bar } from "react-chartjs-2";

import Api from "../../Controllers/Api";
import { buildRegionUrl, splitCategoryFilters } from "../../utils.js";

import config from "../../settings.js";
import configData from "../../settings_data.js";

/**
 * This component is used to create a Histogram chart using ratios rather than raw data
 */
class HistogrammeDataRatio extends React.Component {
    constructor(props) {
        super(props);

        // we retrieve initial filters
        let filters = this.props.parentApi.controller.analysisManager
            ? this.props.parentApi.controller.analysisManager.initFiltersByCategory(
                  parseInt(props.id_analysis, 10)
              )
            : {};

        this.state = {
            id_indicateur: String(props.id_analysis),
            idIndicateurADiviser:
                this.props.parentApi.controller.analysisManager.obtenirIdentifiantAnalyse(
                    "prod_enr"
                ),
            representation: "histogramme-data-ratio",
            id: props.id + props.type.categorie,
            disabled: false,
            filters: filters,
        };
    }

    componentDidMount() {
        // Get data from API
        this.fetchConfiguration(this.state.filters);
    }

    /**
     * This function updates the data from which we build the histogram charts when changing territory
     * @param {object key => value} prevProps : main component properties before
     * @param {object key => value} prevState : main component state before
     */
    componentDidUpdate(prevProps, prevState) {
        let filtreCategorieCourant = this.props.parentApi.data.filtreCategorieCourant;
        if (
            filtreCategorieCourant !==
                prevProps.parentApi.data.filtreCategorieCourant &&
            this.state.changementEtat
        ) {
            this.fetchConfiguration(this.state.filters);
            this.setState({
                changementEtat: false,
            });
        }

        // if we updated the filters
        if (JSON.stringify(this.state.filters) !== JSON.stringify(prevState.filters)) {
            this.fetchConfiguration(this.state.filters);
        }
    }

    componentWillUnmount() {
        if (this.dataPromise) {
            this.dataPromise.abort();
            this.props.parentApi.callbacks.updateDataLoaded(true);
        }
    }

    /**
     * Launches a main component method to add/remove a modality to the filter
     * Triggered when a checkbox associated with a modality is checked
     * @param {chaine de caractère} modalite : modality checked / unchecked
     */
    filtrerSurLegende(event, modalite, categorie) {
        let filterWithTableName = categorie + "." + modalite;
        // if we do have filters
        if (!this.state.filters) return;

        let _filters = JSON.parse(JSON.stringify(this.state.filters));
        // if we do not have current filter
        if (!_filters[categorie]) return;

        // we first retrieve the filter ID if present inside the filters
        const idIfPresent = _filters[categorie].findIndex(
            (element) => element && element.filtre_categorie === filterWithTableName
        );

        // do we have to add or do we have to remove
        if (event.target.checked) {
            // if we couldn't find it, it means we need to add it
            if (idIfPresent === -1) {
                _filters[categorie].push({
                    filtre_categorie: filterWithTableName,
                    type: "pie",
                });
            }
        } else {
            // we are going to delete the filter if we can find it
            if (idIfPresent !== -1) {
                delete _filters[categorie][idIfPresent];
                _filters[categorie] = _filters[categorie].flat();
            }
        }

        // if the action resulted in some changes between previous filters
        // and new filters => we update the state
        if (JSON.stringify(this.state.filters) !== JSON.stringify(_filters)) {
            this.setState({
                filters: _filters,
            });
        }
    }

    /**
     * Builds the legend with which we can select or delete modalities
     * in the data to be displayed using checkboxes, from modalities by categories.
     * @param {object ke value} chart : data for a chart constructed as follows:
     * {
     *     data : {
     *         confid : Type of confidentiality,
     *         id : indicator identifier,
     *         labels : list of modalities,
     *         datasets : [{
     *             data : List of values in the correct order to match them
     *             to the order of the modalities,
     *             backgroundColor : List of colors in the correct order to match them
     *             to the order of the modalities
     *         }]
     *     }
     * }
     * This array contains other keys that we don't use in this method.
     */
    buildLegend(data, categorie) {
        let identifiant_indicateur = this.state.id_indicateur;
        let legende = [];
        let tailleSpan = "10px";
        let taillePoliceModalites = "";
        let hauteurLegendeModalites = "";
        let hauteurLegende = "";
        if (
            this.props.provenance !== "tableau_de_bord" &&
            this.props.provenance !== "tableau_de_bord_restitue"
        ) {
            tailleSpan = "15px";
            taillePoliceModalites = " taille-police-suivi-energetique";
            hauteurLegendeModalites = " legende-modalite-suivi-energetique";
            hauteurLegende = " liste-modalites-suivi-energetique";
        }
        let retourLigneLibelleModalites = "";
        if (this.props.provenance === "tableau_de_bord_restitue") {
            retourLigneLibelleModalites = " legende-modalite-tableau-bord";
        }
        let filtreInitial =
            this.props.parentApi.controller.analysisManager.initFiltersByCategory(
                parseInt(identifiant_indicateur, 10)
            );
        let listeModaliteFiltre = [];

        for (let elem of filtreInitial[categorie]) {
            listeModaliteFiltre.push(splitCategoryFilters(elem["filtre_categorie"])[1]);
        }

        for (let i in data[categorie]) {
            let checked = false;
            let disabled = false;
            let couleur = data[categorie][i].couleur;

            if (listeModaliteFiltre.indexOf(data[categorie][i].modalite) !== -1) {
                checked = true;
            }

            if (data[categorie][i].confidentiel === "oui") {
                disabled = true;
                couleur = "#9e0012";
            }

            legende.push(
                <div
                    className={"legende-modalite" + hauteurLegendeModalites}
                    key={"legende-" + i + "-" + data[categorie][i].modalite}
                >
                    <span
                        className="element-legende-modalites"
                        style={{
                            backgroundColor: couleur,
                            display: "block",
                            height: tailleSpan,
                            width: tailleSpan,
                        }}
                    ></span>
                    <input
                        type="checkbox"
                        id={
                            "legende-" +
                            this.props.id +
                            "-" +
                            i +
                            this.state.id +
                            "-" +
                            data[categorie][i].modalite
                        }
                        defaultChecked={checked}
                        className="element-legende-modalites"
                        disabled={disabled}
                        onChange={(event) =>
                            this.filtrerSurLegende(
                                event,
                                data[categorie][i].modalite,
                                "type_prod_enr"
                            )
                        }
                    ></input>
                    <label
                        htmlFor={
                            "legende-" +
                            this.props.id +
                            "-" +
                            i +
                            this.state.id +
                            "-" +
                            data[categorie][i].modalite
                        }
                        className={
                            "element-legende-modalites" +
                            retourLigneLibelleModalites +
                            taillePoliceModalites
                        }
                    >
                        {" "}
                        {data[categorie][i].modalite}
                    </label>
                </div>
            );
        }
        legende = legende.reverse();
        return (
            <ul
                className={"liste-modalites" + hauteurLegende}
                key={"legende-finale-" + identifiant_indicateur}
            >
                {legende}
            </ul>
        );
    }

    fetchConfiguration(filtres) {
        let codeInseeTerritoire =
            this.props.zoneId ?? this.props.parentApi.data.currentZone;
        let pZone =
            "?zone=" + (this.props.zoneType ?? this.props.parentApi.data.zone.zone);
        let pMaille =
            "&maille=" +
            (this.props.zoneMaille ?? this.props.parentApi.data.zone.maille);
        let pZoneId = "&zone_id=" + codeInseeTerritoire;
        if ((this.props.zoneType ?? this.props.parentApi.data.zone.zone) === "region") {
            pZoneId = "&zone_id=" + this.props.parentApi.data.regionCode;
        }
        let id_indicateur = this.state.id_indicateur;
        let representation = this.state.representation;
        let dataSource =
            config.api_analysis_meta_url +
            id_indicateur +
            "/graphique/" +
            representation +
            pZone +
            pMaille +
            pZoneId;
        if (!filtres) {
            filtres =
                this.props.parentApi.controller.analysisManager.initFiltersByCategory(
                    parseInt(id_indicateur, 10)
                );
        }

        this.props.parentApi.callbacks.updateDataLoaded(false);

        let body = JSON.stringify(filtres);
        if (this.dataPromise) this.dataPromise.abort();
        this.dataPromise = Api.callApi(
            buildRegionUrl(dataSource, this.props.parentApi.data.region),
            body,
            "POST"
        );
        this.dataPromise
            .then((json) => {
                this.props.parentApi.callbacks.updateDataLoaded(true);
                if (!json) {
                    return;
                }
                if (json["disabled"] && json["disabled"] === true) {
                    this.setState({
                        disabled: true,
                    });
                    return;
                }
                // Set states
                json["codeInseeTerritoire"] = codeInseeTerritoire;
                if (
                    codeInseeTerritoire ===
                    (this.props.zoneId ?? this.props.parentApi.data.currentZone)
                ) {
                    this.setState({
                        data: json,
                    });
                }
            })
            .catch((error) => {
                if (error.name === "AbortError") return;
                this.props.parentApi.callbacks.updateDataLoaded(true);
                console.error(error);
            });
    }

    personnaliserInfosBulles(infos) {
        return (
            "Total : " +
            (
                (parseInt(infos[1].value, 10) * 100) /
                parseInt(infos[0].value, 10)
            ).toFixed(2) +
            " %"
        );
    }

    render() {
        if (this.state.disabled === true) {
            return (
                <div className="charts-legende">
                    <div className={"confid-chart"}>
                        Cet indicateur n'est pas activé actuellement, veuillez contacter
                        l'administrateur régional.
                    </div>
                </div>
            );
        }

        if (!this.state.data) {
            return null;
        }
        let labels = [];
        let datasetIndicateurRatio = {
            label: this.state.data.indicateur_ratio.nom,
            backgroundColor: configData.suiviConsommationColor,
            data: [],
        };
        let nomIndicateurADiviser =
            this.props.parentApi.controller.analysisManager.getAnalysisName(
                this.state.idIndicateurADiviser
            );
        let datasetIndicateur = {
            label: nomIndicateurADiviser,
            backgroundColor: configData.suiviProductionColor,
            data: [],
        };

        for (let ds of this.state.data.indicateur_ratio.traj) {
            labels.push(ds.annee);
            datasetIndicateurRatio.data.push(ds.valeur);
        }

        for (let ds of this.state.data.indicateur.traj) {
            datasetIndicateur.data.push(ds.valeur);
        }

        let estimatedYears = [];
        let estimatedLegend = "";
        if (this.props.parentApi.controller.analysisManager) {
            estimatedYears =
                this.props.parentApi.controller.analysisManager.getAnalysisEstimatedYears(
                    parseInt(this.props.id_analysis, 10)
                );
        }
        if (estimatedYears.length > 0) {
            estimatedLegend = " ; (e) = données estimées";
        }

        let barConsoVsPnrData = {
            labels: labels,
            datasets: [datasetIndicateurRatio, datasetIndicateur],
        };

        let barOptionsConsoVsPnr = {
            tooltips: {
                mode: "index",
                intersect: false,
                callbacks: {
                    title: this.personnaliserInfosBulles,
                },
            },
            scales: {
                y: {
                    display: true,
                    title: {
                        display: true,
                        text:
                            "Valeurs exprimées en " +
                            (this.props.unit
                                ? this.props.unit
                                : this.state.data.indicateur.unite),
                    },
                    ticks: {
                        beginAtZero: true,
                    },
                },
                x: {
                    display: true,
                    title: {
                        display: true,
                        text: "Années" + estimatedLegend,
                        font: {
                            size: 10,
                        },
                    },
                    ticks: {
                        autoSkip: false,
                        source: "labels",
                        callback: function (value) {
                            const label = this.getLabelForValue(value);
                            return (
                                label + (estimatedYears.includes(+label) ? " (e)" : "")
                            );
                        },
                    },
                },
            },
            responsive: true,
            devicePixelRatio: configData.chartsDevicePixelRatio,
        };

        let legende = this.buildLegend(
            this.state.data.indicateur,
            this.props.type.categorie
        );
        return (
            <div>
                <div className="charts-legende" style={{ width: "101%" }}>
                    <div style={{ width: "750px", height: "450px" }}>
                        <Bar
                            width={750}
                            height={400}
                            options={barOptionsConsoVsPnr}
                            data={barConsoVsPnrData}
                        />
                    </div>
                    <div
                        className="choisir-methode elem-courbes-empilees"
                        style={{ marginLeft: "10px" }}
                    >
                        {legende}
                    </div>
                </div>
            </div>
        );
    }
}

export default HistogrammeDataRatio;
