/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import Api from "../../Controllers/Api";
import config from "../../settings.js";
import { buildRegionUrl, filterCaseInsensitive } from "../../utils.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-table-6/react-table.css";
import ReactTable from "react-table-6";

/**
 * This component displays a table from which you can delete an objective or access the update interface of an objective
 * (which is the same as that of adding an objective but with the values pre-filled with what has previously saved in the database).
 */
class GestionObjectifs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status: "",
        };
    }

    componentDidMount() {
        this.obtenirObjectifs();
    }

    /**
     * Get the characteristics of the objectives (title, reference year, graph)
     */
    obtenirObjectifs() {
        let url = buildRegionUrl(
            config.api_integration_objectifs,
            this.props.parentApi.data.region
        );
        Api.callApi(url, null, "GET").then((response) => {
            this.setState({
                donnees: response,
            });
        });
    }

    /**
     * Delete an objective
     * @param {objet clé => valeur} propsTableau : object to access the values displayed in the columns of the table
     */
    supprimerObjectif(propsTableau) {
        let supra_goal_id = propsTableau.props.original.supra_goal_id;
        let url = buildRegionUrl(
            config.api_objectifs,
            this.props.parentApi.data.region
        ).replace("#supra_goal_id#", supra_goal_id);
        Api.callApi(url, null, "DELETE").then((response) => {
            this.setState({
                status: response.message,
            });
            this.obtenirObjectifs();
        });
    }

    render() {
        if (
            !this.props.connected ||
            !this.props.userInfos ||
            this.props.userInfos?.profil !== "admin"
        ) {
            return <div>Non accessible.</div>;
        }

        const mettreAJourObjectif = (propsTableau) => {
            let supra_goal_id = propsTableau.props.original.supra_goal_id;
            this.props.modeAjoutMiseAJourObjectifs("mise_a_jour", supra_goal_id);
        };

        const columns = [
            {
                Header: "Titre",
                accessor: "titre",
            },
            {
                Header: "Année de référence",
                accessor: "annee_reference",
            },
            {
                Header: "Graphique",
                accessor: "graphique",
            },
            {
                Header: "Table",
                accessor: "table",
                show: false,
            },
            {
                id: "activate",
                Header: "Action",
                accessor: "actif",
                Cell: (props) => (
                    /**
                     * Browses the data transmitted to the ReactTable component and builds the lines in which
                     * we integrate the delete button and the one that allows access to the objective update interface.
                     * @param {key => value} props: Property of each of these lines. They allow access to the data
                     * that is transmitted to the ReactTable component using the accessors defined just above (in this columns variable).
                     */
                    <div className="actions">
                        <button
                            className={"btn btn-danger"}
                            onClick={() => this.supprimerObjectif({ props })}
                        >
                            Supprimer
                        </button>
                        <button
                            className="btn btn-warning"
                            onClick={() => mettreAJourObjectif({ props })}
                        >
                            Mettre à jour
                        </button>
                    </div>
                ),
            },
        ];

        return (
            <div>
                {" "}
                <div className="panel-body user-scenarii">
                    <h3 className="panel-title pull-left">Gestion des objectifs</h3>
                    {/* Data contained in this.state.donnees are automatically browsed with the method associated with the Cell key in columns */}
                    <ReactTable
                        data={this.state.donnees}
                        columns={columns}
                        className="-striped"
                        defaultPageSize={30}
                        filterable={true}
                        defaultFilterMethod={(filter, row) =>
                            filterCaseInsensitive(filter, row)
                        }
                    />
                </div>
            </div>
        );
    }
}

export default GestionObjectifs;
