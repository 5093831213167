/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import ReactTable from "react-table-6";

import Api from "../../Controllers/Api";
import { buildRegionUrl, filterCaseInsensitive } from "../../utils.js";

import config from "../../settings.js";

import "bootstrap/dist/css/bootstrap.min.css";

function displayLinkCoordinates(coordinates) {
    if (coordinates === undefined || !coordinates) {
        return "";
    }
    let coords = coordinates.split(" ");
    return (
        <a
            target="_blank"
            rel="noreferrer"
            className="link_coordinates"
            href={
                "https://www.openstreetmap.org/#map=14/" + coords[1] + "/" + coords[0]
            }
        >
            {Math.round(coords[1] * 1000) / 1000}, {Math.round(coords[0] * 1000) / 1000}
        </a>
    );
}

/**
 * This component allows you to manage the history of modifications on data layers
 */
class POIHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            history: undefined,
        };
        this.getHistory();
    }

    // Get history of modifications on layers
    getHistory() {
        // Call api to get history
        Api.callApi(
            buildRegionUrl(
                config.api_poi_history_url,
                this.props.parentApi.data.region
            ),
            undefined,
            "GET"
        )
            .then((response) => {
                response = response.map((r) => ({
                    ...r,
                    mise_a_jour: new Date(r.mise_a_jour),
                }));
                // Store user list
                this.setState({
                    history: response,
                });
            })
            .catch((e) => this.setState({ status: e.message }));
    }

    render() {
        if (
            !this.props.connected ||
            !this.props.userInfos ||
            this.props.userInfos?.profil !== "admin"
        ) {
            return <div>Non accessible.</div>;
        }
        const columns = [
            {
                Header: "Id",
                accessor: "id",
            },
            {
                Header: "Couche",
                accessor: "layer",
            },
            {
                Header: "Action",
                accessor: "action",
                maxWidth: 100,
            },
            {
                Header: "Utilisateur",
                accessor: "utilisateur",
            },
            {
                Header: "Changement",
                accessor: "changement",
                Cell: (props) => {
                    return Array.isArray(props.value)
                        ? props.value.map((element) => {
                              return (
                                  <>
                                      {element}
                                      <br />
                                  </>
                              );
                          })
                        : props.value ?? "-";
                },
                minWidth: 250,
            },
            {
                Header: "Geolocalisation",
                accessor: "geolocalisation",
            },
            {
                Header: "Position courante",
                accessor: "position_courante",
                Cell: (props) => {
                    return displayLinkCoordinates(props.original.position_courante);
                },
            },
            {
                Header: "Position précédente",
                accessor: "position_precedente",
                Cell: (props) => {
                    return displayLinkCoordinates(props.original.position_precedente);
                },
            },
            {
                Header: "Mise à jour",
                accessor: "mise_a_jour",
                Cell: (props) => {
                    return props.value.toLocaleString();
                },
            },
        ];
        return (
            <div>
                <div className="panel-body">
                    <h3 className="panel-title pull-left">
                        Dernières modifications d'équipements
                    </h3>
                    <ReactTable
                        data={this.state.history}
                        columns={columns}
                        className="-striped"
                        defaultPageSize={10}
                        filterable={true}
                        defaultFilterMethod={(filter, row) =>
                            filterCaseInsensitive(filter, row)
                        }
                    />
                </div>
            </div>
        );
    }
}

export default POIHistory;
