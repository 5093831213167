/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import { get_input_param, get_inter_param, get_complex_action_param } from "./utils";

/**
 * This file gathers the intermediate calculations of energy consumption useful for the simulator
 */

/**
 *
 * @param {*} A : specific input parameters
 * @param {*} B : generic input paramateres
 * @param {*} C : generic intermediate paramateres
 * @param {*} D : generic intermediate paramateres before action
 * @param {*} E : user paramateres
 * @param {*} level : level of the simulator (0, 1 or 2)
 */

export const get_consumption = (A, B, C, D, E, level) => {
    // get consumption after action
    let consumption_after = inter_calcul_after(A, B, C, D, E, level);

    let consumption =
        D["before"]["total_conso_transport"] -
        consumption_after["after"]["total_conso_transport"];

    return consumption;
};

const inter_calcul_after = (A, B, C, D, E, level) => {
    let consumption = {};

    // Move less (suffix _ML)
    /**
     * C_ML = Distance parcourue après action - indice 100 domicile-travail Transport de personnes (100%)
     * D_ML = Distance parcourue après action - indice 100 hors domicile-travail Transport de personnes (100%)
     * E_ML = Distance parcourue après action - indice 100 hors domicile-travail Transport de marchandise (100%)
     */

    const C_ML =
        (E["1a"] * E["1b"]) /
        (A["Nombre d'actifs domicile-travail"] *
            A["Distance moyenne domicile-travail"]);
    const D_ML = E["2a"] / 100;
    const E_ML = E["3a"] / 100;

    // Move efficiently (suffix _ME)
    /**
     * C_ME = Consommation moyenne par km avant action (kWh/pers.km) Transport de personnes Voiture
     * D_ME = Consommation moyenne par km avant action (kWh/pers.km) Transport de personnes Bus et cars
     * E_ME = Consommation moyenne par km avant action (kWh/pers.km) Transport de marchandise Poids lourds
     * F_ME = Consommation moyenne par km avant action (kWh/pers.km) Transport de personnes Vélo / marche à pied
     */

    // substitution levers : vp - passanger transport
    const conso_vp_diesel_essence = get_inter_param(
        C,
        "consommation",
        "véhicule particulier",
        "diesel_essence"
    );
    const conso_vp_elec = get_inter_param(
        C,
        "consommation",
        "véhicule particulier",
        "électrique"
    );
    const conso_vp_gnv = get_inter_param(
        C,
        "consommation",
        "véhicule particulier",
        "GNV"
    );
    // user params
    let diesel_essence_7a = get_complex_action_param(E, "7a", "diesel_essence");
    let elec_7a = get_complex_action_param(E, "7a", "elec");
    let gnv_7a = get_complex_action_param(E, "7a", "gnv");

    const C_ME =
        diesel_essence_7a * conso_vp_diesel_essence +
        elec_7a * conso_vp_elec +
        gnv_7a * conso_vp_gnv;

    // substitution levers : public transport
    const conso_pt_diesel_essence = get_inter_param(
        C,
        "consommation",
        "bus et cars",
        "diesel_essence"
    );
    const conso_pt_elec = get_inter_param(
        C,
        "consommation",
        "bus et cars",
        "électrique"
    );
    const conso_pt_gnv = get_inter_param(C, "consommation", "bus et cars", "GNV");
    const conso_pt_hydrogen = get_inter_param(
        C,
        "consommation",
        "bus et cars",
        "hydrogène"
    );
    // user params
    let diesel_essence_8a = get_complex_action_param(E, "8a", "diesel_essence");
    let elec_8a = get_complex_action_param(E, "8a", "elec");
    let gnv_8a = get_complex_action_param(E, "8a", "gnv");
    let hydro_8a = get_complex_action_param(E, "8a", "hydro");

    const D_ME =
        diesel_essence_8a * conso_pt_diesel_essence +
        elec_8a * conso_pt_elec +
        gnv_8a * conso_pt_gnv +
        hydro_8a * conso_pt_hydrogen;

    // substitution levers : heavyweight transport
    const conso_hw_diesel_essence = get_inter_param(
        C,
        "consommation",
        "poids lourd",
        "diesel"
    );
    const conso_hw_gnv = get_inter_param(C, "consommation", "poids lourd", "GNV");
    const conso_hw_hydrogen = get_inter_param(
        C,
        "consommation",
        "poids lourd",
        "hydrogène"
    );
    // user params
    let diesel_essence_9a = get_complex_action_param(E, "9a", "diesel_essence");
    let gnv_9a = get_complex_action_param(E, "9a", "gnv");
    let hydro_9a = get_complex_action_param(E, "9a", "hydro");

    const E_ME =
        diesel_essence_9a * conso_hw_diesel_essence +
        gnv_9a * conso_hw_gnv +
        hydro_9a * conso_hw_hydrogen;

    // walk and bike
    // const F_ME = 0;

    // 2. Move differently (suffix _MD)
    /**
     * C_MD = Consommation moyenne par km après action (kWh/pers.km) domicile-travail Transport de personnes  distance < 2km
     * D_MD = Consommation moyenne par km après action (kWh/pers.km) domicile-travail Transport de personnes distance 2-10km
     * E_MD = Consommation moyenne par km après action (kWh/pers.km) domicile-travail Transport de personnes distance 10-80km
     * F_MD = Consommation moyenne par km après action (kWh/pers.km) domicile-travail Transport de personnes toute distance
     * G_MD = Consommation moyenne par km après action (kWh/pers.km) hors domicile-travail Transport de personnes toute distance
     * H_MD = Consommation moyenne par km après action (kWh/pers.km) transport de marchandise hors domicile-travail toute distance
     */

    let defaultAssetNbrPerCar = get_input_param(
        B,
        "domicile-travail",
        "Nombre d'actifs par voiture par défaut"
    );
    let fillingRatePt = get_input_param(
        B,
        "transport-personnes",
        "Taux de remplissage des bus/cars"
    );

    // user params
    const share_carpool_users_6a = get_complex_action_param(
        E,
        "6a",
        "share_carpool_users"
    );
    const avg_carpoolers_6a = get_complex_action_param(
        E,
        "6a",
        "avg_carpoolers",
        false
    );

    const C_MD =
        ((A["Part modale voiture + 2 roues pour trajet < 2 km"] / 100 -
            (E["4a"] / 100 -
                A["Part modale marche à pied / vélo pour trajet < 2 km"] / 100)) *
            C_ME) /
            (share_carpool_users_6a * avg_carpoolers_6a +
                defaultAssetNbrPerCar * (1 - share_carpool_users_6a)) +
        ((A["Part modale transport en commun pour trajet < 2 km"] / 100) * D_ME) /
            fillingRatePt;

    const D_MD =
        ((A["Part modale voiture + 2 roues pour trajet [2-10] km"] / 100 -
            (E["4b"] / 100 -
                A["Part modale marche à pied / vélo pour trajet [2-10] km"] / 100)) *
            C_ME) /
            (share_carpool_users_6a * avg_carpoolers_6a +
                defaultAssetNbrPerCar * (1 - share_carpool_users_6a)) +
        ((A["Part modale transport en commun pour trajet [2-10] km"] / 100) * D_ME) /
            fillingRatePt;

    const E_MD =
        ((A["Part modale voiture + 2 roues pour trajet [10-80] km"] / 100 -
            (E["5a"] / 100 -
                A["Part modale transport en commun pour trajet [10-80] km"] / 100)) *
            C_ME) /
            (share_carpool_users_6a * avg_carpoolers_6a +
                defaultAssetNbrPerCar * (1 - share_carpool_users_6a)) +
        ((E["5a"] / 100) * D_ME) / fillingRatePt;

    const F_MD =
        (C_MD *
            A["Nombre d'actifs trajet < 2 km"] *
            A["Distance moyenne trajets < 2 km"] +
            D_MD * A["Nombre d'actifs [2-10] km"] * A["Distance moyenne [2-10] km"] +
            E_MD * A["Nombre d'actifs [10-80] km"] * A["Distance moyenne [10-80] km"]) /
        (A["Nombre d'actifs domicile-travail"] *
            A["Distance moyenne domicile-travail"]);

    const G_MD =
        C_MD * A["Part km hors travail < 2 km"] +
        D_MD * A["Part km hors travail [2-10] km"] +
        E_MD * A["Part km hors travail [10-80] km"];

    const H_MD = E_ME;

    // final result after action
    /**
     * W : Consommation après action Transport de personnes domicile-travail
     * X : Consommation après action Transport de personnes hors domicile-travail
     * Y : Consommation après action Transport de marchandise hors domicile-travail
     * Z : Total consommation transport routier après action
     */

    const workedDaysPerYear = get_input_param(
        B,
        "domicile-travail",
        "Nombre de jours travaillés par an"
    );
    const dailyTrips = get_input_param(
        B,
        "domicile-travail",
        "Nombre de trajets par jour"
    );

    const W =
        (F_MD *
            C_ML *
            A["Nombre d'actifs domicile-travail"] *
            A["Distance moyenne domicile-travail"] *
            workedDaysPerYear *
            dailyTrips) /
        1000000;

    if (level === 0) {
        const Z =
            D["before"]["total_conso_transport"] -
            D["before"]["trasport_passenger_home_work"] +
            W;

        consumption["after"] = {
            trasport_passenger_home_work: W,
            total_conso_transport: Z,
        };
    } else {
        const X =
            ((D["before"]["transport_passenger_off_home_work"] * G_MD) /
                D["move_differently"]["avg_conso_total_out_home_work"]) *
            D_ML;

        const Y =
            ((D["before"]["transport_goods_off_domicile_travail"] * H_MD) /
                D["move_differently"]["avg_conso_goods_transport"]) *
            E_ML;
        const Z = W + X + Y;
        consumption["after"] = {
            trasport_passenger_home_work: W,
            transport_passenger_off_home_work: X,
            transport_goods_off_domicile_travail: Y,
            total_conso_transport: Z,
        };
    }

    return consumption;
};
