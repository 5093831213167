/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import { useState } from "react";

import reactCSS from "reactcss";
import { SketchPicker } from "react-color";
import Select from "react-select";
import Creatable from "react-select/creatable";

import Api from "../../Controllers/Api";
import config from "../../settings.js";
import configData from "../../settings_data.js";
import { buildRegionUrl } from "../../utils.js";
import { SourcesListInput } from "./POIEdition";

import "bootstrap/dist/css/bootstrap.min.css";

export function AdviceOnDataTable() {
    return (
        <>
            Pour fournir des données brutes, il faut que votre fichier respecte les
            contraintes suivantes :
            <ul>
                <li>
                    être au format CSV avec le point-virgule{" "}
                    <strong>
                        <code>;</code>
                    </strong>{" "}
                    en séparateur et dans l'encodage UTF-8 ;
                </li>
                <li>avoir une ligne d'en-tête en première ligne</li>
                <li>
                    comporter les colonnes suivantes :
                    <ul>
                        <li>
                            <em>cas normal</em> :{" "}
                            <strong>
                                <code>commune</code>
                            </strong>{" "}
                            (attention à bien laisser les 0 en début de code INSEE) ,{" "}
                            <strong>
                                <code>annee</code>
                            </strong>
                            ,{" "}
                            <strong>
                                <code>valeur</code>
                            </strong>
                        </li>
                        <li>
                            en plus, pour les <em>cas de cartes de pixels</em>,{" "}
                            <strong>
                                <code>geom</code>
                            </strong>{" "}
                            qui contient la géométrie de la commune au format WKT
                            polygonal (ex. POLYGON((1 1, 1 2, 2 2, 2 1, 1 1))) dans le
                            système de projection EPSG:3857. Le nom de colonne{" "}
                            <strong>
                                <code>id</code>
                            </strong>{" "}
                            est également interdit.
                        </li>
                        <li>
                            <code>valeur_filtre</code> est également autorisée lorsque
                            nécessaire (pour certaines représentations spécifiques).
                        </li>
                        <li>
                            toutes les autres colonnes seront considérées comme des noms
                            de catégories de modalités
                        </li>
                    </ul>
                </li>
            </ul>
        </>
    );
}

/**
 * Component that displays a form input allowing to choose a color. The color is stored
 * outside of current component and selection triggers the callback given.
 *
 * @param {string} color the color selected
 * @param {callable} callback the function to call when changing the color selected
 */
function AnalysisColorPicker(props) {
    const [isPickerDisplayed, displayPicker] = useState(false);

    // handle opening and closing the picker
    const handlePickerClick = () => {
        displayPicker(true);
    };
    const handlePickerClose = () => {
        displayPicker(false);
    };

    // the style used to display current color
    const style = reactCSS({
        default: {
            color: {
                background: props.color,
            },
        },
    });

    const title = props.title ?? undefined;

    return (
        <div className="form-horizontal">
            <label className="col-sm-2 control-label" title={title}>
                {props.label ?? "Sélectionner une couleur"}
            </label>
            <div className="custom-block">
                <div className="color-picker-swatch" onClick={handlePickerClick}>
                    <div className="color-picker-color" style={style.color} />
                </div>
                {isPickerDisplayed ? (
                    <div className="color-picker-popover">
                        <div
                            className="color-picker-cover"
                            onClick={handlePickerClose}
                        />
                        <SketchPicker color={props.color} onChange={props.callback} />
                    </div>
                ) : null}
            </div>
        </div>
    );
}

/**
 * This component is used to add or update an analysis
 */
class AnalysisAjouter extends React.Component {
    constructor(props) {
        super(props);

        // List of indicator data tables
        this.listExistingTables =
            this.props.parentApi.controller.analysisManager.getListeTables();

        this.graphTypes = {
            pie: "Diagramme circulaire",
            histogramme: "Histogramme",
            line: "Courbe empilée",
            radar: "Diagramme radar",
            hbar: "Barres horizontales",
            "switch-button": "Commutateur",
            selection: "Menu déroulant",
        };

        this.state = {
            indicatorType: "circle",
            colorStartGradient: configData.defaultColor,
            colorEndGradient: configData.defaultColor,
            colorStars: configData.defaultColor,
            minValueStars: 0,
            maxValueStars: 5,
            name: "",
            tableName: "",
            theme: "",
            unit: "",
            decimals: "",
            years: "",
            estimatedYears: "",
            categoryName: "",
            displayColorStartPicker: false,
            displayColorEndPicker: false,
            creditsAnalysisProducers: [],
            creditsDataSources: [],
            creditsDataProducers: [],
            filter: "",
            categories: [],
            selectedCategories: [],
            fromExistingData: false,
            disabledInDashboard: false,
            disabledCatInDashboard: {},
            confidential: "",
            categories_modalites: "",
            zoneOnly: "",
            exportableZones: "",
            disabledForZone: "",
            disabledForMacroLevel: "",
            forcedMinScale: null,
            geographicPerimeterYear: configData.anneeMin,
            confidLayers: [],
            indicatorParent: null,
            indicatorParentCategory: null,
            analyses: [],
        };

        this.placeHolders = {
            name: "Ex. Consommation d'énergie",
            tableName: "Ex. conso_energetique",
            theme: "Ex. Consommation d'énergie",
            unit: "Ex. GWh",
            decimals: "Ex. 2",
            years: "Ex. 2016, 2018-2021",
            estimatedYears: "Ex. 2016, 2018-2021",
            dataCustom: "Ex. conso_energetique * 1000000 / maille.population",
            categoryName: "Ma nouvelle catégorie",
            categoryType: "pie",
            forcedMinScale: "Ex. 0",
        };

        this.helpMessages = {
            name: "Nom par lequel le nouvel indicateur sera désigné lorsqu’il apparaîtra dans le menu situé à gauche de l’interface cartographique ou encore dans les tableaux de bord etc",
            tableName:
                "Nom dans la base de données, ne doit pas être déjà utilisé. Caractères acceptés : minuscules, chiffres et tiret bas",
            indicatorType:
                "Type de représentation cartographique (aplats, cercles proportionnels...)",
            unit: "Unité dans laquelle on exprime les valeurs de l’indicateur",
            decimals: "Nombre de chiffres après la virgule",
            theme: "Rubrique où ajouter l’indicateur. Si elle n’existe pas, elle sera créée lors de son intégration",
            forcedMinScale: "Valeur minimale de la taille des cercles sur la carte",
            fullStarColor: "Couleur de remplissage des étoiles atteintes",
            minValueStars: "Nombre minimal d'étoiles (par défaut, 0)",
            maxValueStars: "Nombre maximal d'étoiles atteignables (par défaut, 5)",
            gradientColorStart:
                "Couleur utilisée pour le début du gradient (valeurs minimales)",
            gradientColorEnd:
                "Couleur utilisée pour la fin du gradient (valeurs maximales)",
            creditsAnalysisProducers:
                "Organisme(s) mettant l'indicateur à disposition dans TerriSTORY",
            creditsDataSources: "Organisme(s) d'où proviennent les données sources",
            creditsDataProducers:
                "Organisme(s) produisant les données utilisées pour l'indicateur dans TerriSTORY",
            years: "Années pour lesquelles les données nécessaires au calcul de l’indicateur sont disponibles, séparées par des virgules. Pour indiquer un intervalle, il est possible d'utiliser un tiret",
            estimatedYears:
                "Années pour lesquelles les données ne sont pas réelles mais estimées, séparées par des virgules. Pour indiquer un intervalle, il est possible d'utiliser un tiret",
            filter: "Filtre qui s’écrit sous la forme catégorie.modalité. Par exemple, pour un indicateur de consommation énergétique dans le secteur résidentiel, le filtre s’écrit « secteur.Résidentiel »",
            confidential:
                "Utiliser les filtres de confidentialité d'un type d'analyse des tables de confidentialité",
            pdfMethodo:
                "Fichier PDF auquel on accède en cliquant sur le point d'interrogation",
            dataCustom: "Formule de la forme table_de_données * valeur / maille.table",
            categoryTitle:
                "Titre du graphique représentant l'indicateur selon la catégorie {cat}",
            explanationForCategorySelection:
                "Texte affiché au passage de la souris sur le label de catégorie pour fournir des explications sur la distinction proposée par le champ de sélection ou le bouton.",
            categoryOrder:
                "Place du graphique de la catégorie {cat} parmi les graphiques activés pour cet indicateur (remplir par des nombres entiers, la valeur la plus faible correspondant au graphe de gauche et la plus forte à celui de droite)",
            categoryType:
                "Type de graphique de la catégorie {cat}. Les menus déroulants et les commutateurs seront affichés dans la légende tandis que les autres graphiques seront intégrés en bas de la carte. L'ordre des éléments dans les différents graphiques (diagrammes, menu déroulant, commutateur) est déterminé par la colonne ordre dans la définition des catégories dans l'onglet correspondant.",
            zoneOnly:
                "Mailles pour lesquelles on autorise l’affichage de l’indicateur. Si aucune valeur n’est saisie, l’indicateur pourra être affiché à toutes les mailles",
            disabledForZone:
                "Mailles pour lesquelles on désactive l’affichage de l’indicateur. Si aucune valeur n’est saisie, l’indicateur pourra être affiché à toutes les mailles",
            exportableZones:
                "Mailles pour lesquelles est autorisé l’export des données sous forme de CSV par les visiteurs du site (aucune maille = aucun export possible).",
            wmsUrl: "URL à utiliser pour effectuer la requête au serveur fournissant le flux WMS. Il est possible de faire varier une année de données en intégrant le token #annee# dans le nom de la couche. Cette valeur sera remplacée par la sélection dans le menu déroulant latéral lors de l'affichage.",
            wmsOpacity:
                "Valeur de transparence à appliquer à la couche WMS (entre 0 = complètement transparente et 1 = opaque).",
            wmsLayer:
                "Nom de la couche utilisée sur le serveur fournissant le flux WMS. Il est possible de faire varier une année de données en intégrant le token #annee# dans le nom de la couche. Cette valeur sera remplacée par la sélection dans le menu déroulant latéral lors de l'affichage.",
            wmsProjection:
                "Code CRS de la projection de la couche du serveur fournissant le flux WMS (supportés : 3857, 4326 et 2154)",
            wmsServerType: "Type de serveur fournissant le flux WMS",
            disabledInDashboard:
                "L'indicateur ne sera plus affiché dans la liste des indicateurs lors de l'édition et sera désactivé dans les tableaux de bord qui l'utilisent déjà",
            disabledCatInDashboard:
                "La catégorie ne sera pas affichée dans la liste des catégories qui peuvent être utilisée lors de l'affichage d'un graphique (par exemple de courbes empilées). Cela permet notamment de gérer les catégories de valeurs exclusives. Par exemple, les données à climat réel et à climat normal ne doivent pas être affichées en même temps sur un même graphique, la représentation courbes empilées par type de climat n'a donc pas de sens.",
        };

        // Retrieve the available categories
        this.getListeCategories();

        // Retrieve the available catégories_modalites
        this.getListeCategoriesModalites();

        // Retrieve the lists of themes
        this.getListeThemes();

        // Retrieve the lists of themes
        this.getListConfidentialityLayers();
    }

    /**
     * Method executed at the end of the construction of the form
     * It is used to pre-fill the input fields in the case of an indicator update
     * @param {objet clé => valeur} prevProps : main component properties before change
     */
    componentDidMount(prevProps) {
        // get analysis list
        this.getListAnalysis();

        if (this.props.mode === "modifier") {
            let filter = "";
            if (this.props.indicateur.filter) {
                filter = this.props.indicateur.filter;
            }

            let defaultValuesExportableZones = [];
            if (this.props.indicateur.donnees_exportables) {
                for (let z of this.props.indicateur.donnees_exportables.split(",")) {
                    defaultValuesExportableZones.push({ value: z, label: z });
                }
            }

            let defaultValuesZoneOnly = [];
            if (this.props.indicateur.only_for_zone) {
                for (let z of this.props.indicateur.only_for_zone.split(",")) {
                    defaultValuesZoneOnly.push({ value: z, label: z });
                }
            }

            let defaultValuesDisabledZone = [];
            if (this.props.indicateur.disabled_for_zone) {
                for (let z of this.props.indicateur.disabled_for_zone.split(",")) {
                    defaultValuesDisabledZone.push({ value: z, label: z });
                }
            }

            let defaultValuesDisabledForMacroLevel = [];
            if (this.props.indicateur.disabled_for_macro_level) {
                for (let z of this.props.indicateur.disabled_for_macro_level.split(
                    ","
                )) {
                    defaultValuesDisabledForMacroLevel.push({
                        value: z,
                        label: z,
                    });
                }
            }
            let defaultIndicatorParent = null;
            let defaultIndicatorParentCategory = null;
            if (this.props.indicateur.is_sub_indicator) {
                const subIndicatorMetaData =
                    this.props.parentApi.controller.analysisManager.getSubIndicatorMetaData(
                        this.props.indicateur.id
                    );
                defaultIndicatorParent = subIndicatorMetaData.subIndicatorParent;
                defaultIndicatorParentCategory =
                    subIndicatorMetaData.subIndicatorCategory;
            }

            let representationDetails = this.props.indicateur.representation_details;

            // Star layer case
            let colorStars = this.state.colorStars;
            let minValueStars = this.state.minValueStars;
            let maxValueStars = this.state.maxValueStars;
            if (this.props.indicateur.type === "stars" && representationDetails.color) {
                colorStars = representationDetails.color;
                minValueStars = representationDetails.minValue;
                maxValueStars = representationDetails.maxValue;
            }

            // WMS feed case
            let wmsUrl = this.state.wmsUrl;
            let wmsOpacity = this.state.wmsOpacity;
            let wmsLayer = this.state.wmsLayer;
            let wmsProjection = this.state.wmsProjection;
            let wmsServerType = this.state.wmsServerType;
            if (
                this.props.indicateur.type === "wms_feed" &&
                representationDetails.wmsUrl
            ) {
                wmsUrl = representationDetails.wmsUrl;
                wmsOpacity = representationDetails.wmsOpacity;
                wmsLayer = representationDetails.wmsLayer;
                wmsProjection = representationDetails.wmsProjection;
                wmsServerType = representationDetails.wmsServerType;
            }

            // forced min value for circle graphic reprensentation
            let forcedMinScale = this.state.forcedMinScale;
            if (
                this.props.indicateur.type === "circle" &&
                representationDetails.forcedMinScale
            ) {
                forcedMinScale = representationDetails.forcedMinScale;
            }

            // If we are in edit mode, we check the categories of the indicator in edition
            let selectedCategories = [];
            let dictSelectedCategories = {};
            if (this.props.indicateur.charts) {
                for (let chart of this.props.indicateur.charts) {
                    if (!chart) {
                        continue;
                    }
                    selectedCategories.push(chart.categorie);
                    dictSelectedCategories["titre_" + chart.categorie] = chart.titre;
                    dictSelectedCategories["order_" + chart.categorie] = chart.ordre;
                    dictSelectedCategories["graph_type_" + chart.categorie] =
                        chart.type;

                    // if some additional text has been defined for switch button chart.
                    if (!["switch-button", "selection"].includes(chart.type)) {
                        continue;
                    }
                    if (
                        "additional_details_text_" + chart.categorie in
                        representationDetails
                    ) {
                        dictSelectedCategories[
                            "additional_details_text_" + chart.categorie
                        ] =
                            representationDetails[
                                "additional_details_text_" + chart.categorie
                            ];
                    }
                    if (
                        "additional_details_disable_in_dashboard_" + chart.categorie in
                        representationDetails
                    ) {
                        dictSelectedCategories["disabledCatInDashboard"] = {
                            ...dictSelectedCategories["disabledCatInDashboard"],
                            [chart.categorie]:
                                representationDetails[
                                    "additional_details_disable_in_dashboard_" +
                                        chart.categorie
                                ],
                        };
                    }
                }
            }

            this.setState({
                name: this.props.indicateur.nom,
                unit: this.props.indicateur.unit,
                years: (this.props.indicateur.years || "").toString(),
                estimatedYears: (
                    this.props.indicateur.estimated_years || ""
                ).toString(),
                decimals: this.props.indicateur.decimals,
                filter,
                colorStartGradient: this.props.indicateur.color_start,
                colorEndGradient: this.props.indicateur.color_end,
                indicatorType: this.props.indicateur.type,
                confidential: this.props.indicateur.confidentiel,
                only_for_zone: this.props.indicateur.only_for_zone,
                disabled_for_zone: this.props.indicateur.disabled_for_zone,
                disabled_for_macro_level:
                    this.props.indicateur.disabled_for_macro_level,
                creditsAnalysisProducers: this.props.indicateur
                    .credits_analysis_producers
                    ? JSON.parse(this.props.indicateur.credits_analysis_producers)
                    : [],
                creditsDataSources: this.props.indicateur.credits_data_sources
                    ? JSON.parse(this.props.indicateur.credits_data_sources)
                    : [],
                creditsDataProducers: this.props.indicateur.credits_data_producers
                    ? JSON.parse(this.props.indicateur.credits_data_producers)
                    : [],
                exportableZones: defaultValuesExportableZones,
                zoneOnly: defaultValuesZoneOnly,
                disabledForZone: defaultValuesDisabledZone,
                disabledForMacroLevel: defaultValuesDisabledForMacroLevel,
                disabledInDashboard: this.props.indicateur.disabled_in_dashboard,
                indicatorParent: defaultIndicatorParent,
                indicatorParentCategory: defaultIndicatorParentCategory,
                representationDetails: representationDetails,
                forcedMinScale,
                // Star layer
                colorStars,
                minValueStars,
                maxValueStars,
                // WMS layer
                wmsUrl,
                wmsOpacity,
                wmsLayer,
                wmsProjection,
                wmsServerType,
                selectedCategories: selectedCategories,
                ...dictSelectedCategories,
                fromExistingData: true,
                dataCustom: this.props.indicateur.data,
            });
        }
    }

    /**
     * Retrieve the list of available categories
     * @return {JSON} the list of categories available for this region
     */
    getListeCategories() {
        Api.callApi(
            buildRegionUrl(
                config.api_categories_list_url,
                this.props.parentApi.data.region
            )
        )
            .then((response) => {
                this.setState({ categories: response });
            })
            .catch((e) => this.setState({ status: e.message }));
    }

    /**
     * Retrieve the list of available catégories_modalites
     * @return {JSON} the list of available catégories_modalites for this region
     */
    getListeCategoriesModalites() {
        Api.callApi(
            buildRegionUrl(
                config.api_categories_modalites_list_url,
                this.props.parentApi.data.region
            )
        )
            .then((response) => {
                this.setState({ categories_modalites: response });
            })
            .catch((e) => this.setState({ status: e.message }));
    }

    /**
     * Retrieve the list of available themes
     * @return {JSON} the list of available themes fr this region
     */
    getListeThemes() {
        Api.callApi(
            buildRegionUrl(
                config.api_analysis_uitheme_url,
                this.props.parentApi.data.region
            )
        )
            .then((response) => {
                this.setState({ uiThemes: response });
            })
            .catch((e) => this.setState({ status: e.message }));
    }

    /**
     * Retrieve the list of available themes
     * @return {JSON} the list of available themes fr this region
     */
    getListConfidentialityLayers() {
        Api.callApi(
            buildRegionUrl(
                config.api_analysis_confid_layers_url,
                this.props.parentApi.data.region
            )
        )
            .then((response) => {
                this.setState({ confidLayers: response });
            })
            .catch((e) => this.setState({ status: e.message }));
    }

    /**
     * Form validation
     * @return {status} true or false depending on whether the data is valid or not
     */
    isFormValid() {
        if (!this.state.name) {
            this.setState({ status: "Vous devez renseigner un nom" });
            return false;
        }
        if (!this.state.years) {
            this.setState({
                status: "Au moins une année doit être renseignée",
            });
            return false;
        }
        if (this.props.mode !== "modifier") {
            if (this.state.theme === "") {
                this.setState({ status: "Vous devez renseigner un theme" });
                return false;
            }
        }

        if (!this.state.unit) {
            this.setState({ status: "Vous devez renseigner une unité" });
            return false;
        }

        // specific case of WMS feeds
        if (this.state.indicatorType === "wms_feed") {
            if (!this.state.wmsUrl) {
                this.setState({ status: "Vous devez renseigner l'URL du flux WMS." });
                return false;
            }
            if (!this.state.wmsLayer) {
                this.setState({
                    status: "Vous devez renseigner la couche du flux WMS.",
                });
                return false;
            }
            if (!this.state.wmsProjection) {
                this.setState({
                    status: "Vous devez renseigner la projection du flux WMS.",
                });
                return false;
            }
            if (!this.state.wmsServerType) {
                this.setState({
                    status: "Vous devez renseigner le type de serveur du flux WMS.",
                });
                return false;
            }
            return true;
        }
        if (!Number.isInteger(parseInt(this.state.decimals, 10))) {
            this.setState({
                status: "Le champ décimales doit être un entier",
            });
            return false;
        }
        if (
            this.state.forcedMinScale !== null &&
            this.state.forcedMinScale !== "" &&
            !Number.isInteger(parseInt(this.state.forcedMinScale, 10))
        ) {
            this.setState({
                status: "La valeur minimale de représentation doit être un entier",
            });
            return false;
        }

        for (let cat of this.state.selectedCategories) {
            // Check if graph types are correct
            let title = this.state["titre_" + cat];
            let graphType = this.state["graph_type_" + cat];
            if (!Object.keys(this.graphTypes).includes(graphType)) {
                this.setState({
                    status:
                        "Le type de graphique pour la catégorie " +
                        title +
                        " est incorrect. Les graphiques autorisés sont les suivants : " +
                        Object.values(this.graphTypes).join(", ") +
                        " !",
                });
                return false;
            }
        }

        if (
            this.state.indicatorType === "stars" &&
            +this.state.minValueStars >= +this.state.maxValueStars
        ) {
            this.setState({
                status: "Le nombre minimal d'étoiles doit être inférieur strictement au nombre maximum d'étoiles.",
            });
            return false;
        }

        // Additional checks (case of an indicator addition)
        if (this.props.mode !== "modifier") {
            if (!this.state.fromExistingData) {
                if (!this.state.fichierDonnees) {
                    this.setState({
                        status: "Vous devez choisir un fichier de données",
                    });
                    return false;
                }
                if (this.state.tableName === "") {
                    this.setState({
                        status: "Vous devez renseigner un nom de table",
                    });
                    return false;
                }

                // Check that this name is not already taken
                if (
                    this.listExistingTables.find(
                        (item) => item.nom === this.state.tableName
                    )
                ) {
                    this.setState({
                        status: "Ce nom de table est déjà utilisé. Veuillez en choisir un autre.",
                    });
                    return false;
                }

                // check that there is no accent, space, capital letter etc...
                var re = /^[a-z0-9_]+$/;
                if (!re.test(this.state.tableName)) {
                    this.setState({
                        status: "Le nom de table ne peut contenir que des nombres, lettres (minuscules) et underscore.",
                    });
                    return false;
                }
            }
        }
        return true;
    }

    /**
     * Add analysis
     * @return {status} return code of the integration
     */
    ajouterAnalyse() {
        // Object of type FormData
        let formData = new FormData();
        // Check data validity
        if (!this.isFormValid()) {
            return false;
        }

        // update form
        if (this.state.fichierDonnees) {
            formData.append("fichier", this.state.fichierDonnees);
            formData.append("nomFichier", this.state.fichierDonnees.name);
        }

        if (this.state.fichierPdfMethodo) {
            formData.append("fichierPdfMethodo", this.state.fichierPdfMethodo);
            formData.append("nomFichierPdfMethodo", this.state.fichierPdfMethodo.name);
        }

        // representation details
        let representationDetails = this.getRepresentationDetails(
            this.state.indicatorType
        );
        formData.append(
            "representation_details",
            JSON.stringify(representationDetails)
        );
        formData.append("nomIndicateur", this.state.name);
        formData.append("nomTable", this.state.tableName);
        formData.append("filtre", this.state.filter);
        formData.append("type", this.state.indicatorType);
        formData.append("couleurDebut", this.state.colorStartGradient);
        formData.append("couleurFin", this.state.colorEndGradient);
        formData.append("unite", this.state.unit);
        formData.append("decimales", this.state.decimals);
        formData.append("theme", this.state.theme);
        formData.append("annees", this.state.years);
        formData.append("disabled_in_dashboard", this.state.disabledInDashboard);
        if (this.state.estimatedYears) {
            formData.append("anneesEstimees", this.state.estimatedYears);
        }
        formData.append(
            "credits_analysis_producers",
            JSON.stringify(this.state.creditsAnalysisProducers)
        );
        formData.append(
            "credits_data_sources",
            JSON.stringify(this.state.creditsDataSources)
        );
        formData.append(
            "credits_data_producers",
            JSON.stringify(this.state.creditsDataProducers)
        );
        formData.append("parent", this.state.indicatorParent?.value);
        formData.append(
            "parentCategory",
            JSON.stringify(this.state.indicatorParentCategory)
        );
        let geographicPerimeterYear = undefined;
        if (!this.state.fromExistingData && this.state.geographicPerimeterYear) {
            geographicPerimeterYear = this.state.geographicPerimeterYear;
        }
        formData.append("anneePerimetreGeographique", geographicPerimeterYear);
        let dataCustom = "";
        if (this.state.fromExistingData) {
            dataCustom = this.state.dataCustom;
        }
        formData.append("dataCustom", dataCustom);
        formData.append("donneesExistantes", this.state.fromExistingData);
        formData.append("isRatio", dataCustom.includes("/"));
        formData.append("confidentiel", this.state.confidential);

        let exportableZones = [];
        for (let val of this.state.exportableZones) {
            exportableZones.push(val.value);
        }
        formData.append("donneesExportables", exportableZones.join(","));

        let onlyZoneValues = [];
        for (let val of this.state.zoneOnly) {
            onlyZoneValues.push(val.value);
        }
        formData.append("onlyForZone", onlyZoneValues.join(","));
        let disabledForZones = [];
        for (let val of this.state.disabledForZone) {
            disabledForZones.push(val.value);
        }
        formData.append("disabledForZone", disabledForZones.join(","));
        let disabledForMacroLevel = [];
        for (let val of this.state.disabledForMacroLevel) {
            disabledForMacroLevel.push(val.value);
        }
        formData.append("disabledForMacroLevel", disabledForMacroLevel.join(","));

        let categories = {};
        for (let cat of this.state.selectedCategories) {
            // Check if a title has been entered
            let titre = this.state["titre_" + cat];
            let order = parseInt(this.state["order_" + cat], 10);
            let graphType = this.state["graph_type_" + cat];
            if (titre === "") {
                let r = window.confirm(
                    "Attention, une catégorie n'a pas de titre associé. Elle ne sera donc pas affichée. Voulez vous continuer ?"
                );
                if (r !== true) {
                    return;
                }
            }
            categories[cat] = {
                titre: titre,
                order: order,
                type: graphType,
            };
        }
        formData.append("categories", JSON.stringify(categories));

        // Call API with the file and the metadata entered by the user
        Api.callApi(
            buildRegionUrl(
                config.api_analysis_ajouter_url,
                this.props.parentApi.data.region
            ),
            formData,
            "POST",
            "default"
        )
            .then((response) => {
                this.setState({ status: response.message });
            })
            .catch((e) => {
                this.setState({ status: e.message });
            });
    }

    getRepresentationDetails(type) {
        if (type === "stars") {
            return {
                color: this.state.colorStars,
                minValue: this.state.minValueStars,
                maxValue: this.state.maxValueStars,
            };
        } else if (type === "wms_feed") {
            return {
                wmsUrl: this.state.wmsUrl,
                wmsOpacity: this.state.wmsOpacity,
                wmsLayer: this.state.wmsLayer,
                wmsProjection: this.state.wmsProjection,
                wmsServerType: this.state.wmsServerType,
            };
        } else if (type === "circle" && this.state.forcedMinScale) {
            return {
                forcedMinScale: this.state.forcedMinScale,
            };
        } else {
            let output = {};
            for (let cat in this.state.categories) {
                if (
                    ["switch-button", "selection"].includes(
                        this.state["graph_type_" + cat]
                    )
                ) {
                    output["additional_details_text_" + cat] =
                        this.state["additional_details_text_" + cat];
                    output["additional_details_disable_in_dashboard_" + cat] =
                        this.state.disabledCatInDashboard[cat];
                }
            }
            return output;
        }
    }

    /**
     * Modify an analysis
     * @return {status} return code
     */
    modifierAnalyse() {
        // Object of type FormData
        let formData = new FormData();

        // check data validity
        if (!this.isFormValid()) {
            return false;
        }

        if (this.state.fichierPdfMethodo) {
            formData.append("fichierPdfMethodo", this.state.fichierPdfMethodo);
            formData.append("nomFichierPdfMethodo", this.state.fichierPdfMethodo.name);
        }

        // representation details
        let representationDetails = this.getRepresentationDetails(
            this.state.indicatorType
        );

        // update form
        formData.append("nomIndicateur", this.state.name);
        formData.append("unite", this.state.unit);
        formData.append("decimales", this.state.decimals);
        formData.append("annees", this.state.years);
        if (this.state.estimatedYears) {
            formData.append("anneesEstimees", this.state.estimatedYears);
        }
        formData.append("filtre", this.state.filter);
        formData.append(
            "representation_details",
            JSON.stringify(representationDetails)
        );
        formData.append("couleurDebut", this.state.colorStartGradient);
        formData.append("couleurFin", this.state.colorEndGradient);
        formData.append("type", this.state.indicatorType);
        formData.append("confidentiel", this.state.confidential);
        formData.append("disabled_in_dashboard", this.state.disabledInDashboard);
        formData.append(
            "credits_analysis_producers",
            JSON.stringify(this.state.creditsAnalysisProducers)
        );
        formData.append(
            "credits_data_sources",
            JSON.stringify(this.state.creditsDataSources)
        );
        formData.append(
            "credits_data_producers",
            JSON.stringify(this.state.creditsDataProducers)
        );
        formData.append("parent", this.state.indicatorParent?.value);
        formData.append(
            "parentCategory",
            JSON.stringify(this.state.indicatorParentCategory)
        );
        let exportableZones = [];
        for (let val of this.state.exportableZones) {
            exportableZones.push(val.value);
        }
        formData.append("donneesExportables", exportableZones.join(","));

        let onlyZoneValues = [];
        for (let val of this.state.zoneOnly) {
            onlyZoneValues.push(val.value);
        }
        formData.append("onlyForZone", onlyZoneValues.join(","));
        let disabledForZones = [];
        for (let val of this.state.disabledForZone) {
            disabledForZones.push(val.value);
        }
        formData.append("disabledForZone", disabledForZones.join(","));
        let disabledForMacroLevel = [];
        for (let val of this.state.disabledForMacroLevel) {
            disabledForMacroLevel.push(val.value);
        }
        formData.append("disabledForMacroLevel", disabledForMacroLevel.join(","));
        formData.append("dataCustom", this.state.dataCustom);

        let categories = {};
        for (let cat of this.state.selectedCategories) {
            // Check if a title has been entered
            let titre = this.state["titre_" + cat];
            let order = parseInt(this.state["order_" + cat], 10);
            let graphType = this.state["graph_type_" + cat];
            if (titre === "") {
                let r = window.confirm(
                    "Attention, une catégorie n'a pas de titre associé. Elle ne sera donc pas affichée. Voulez vous continuer ?"
                );
                if (r !== true) {
                    return;
                }
            }
            categories[cat] = {
                titre: titre,
                order: order,
                type: graphType,
            };
        }
        formData.append("categories", JSON.stringify(categories));

        // Call API with the file and the metadata entered by the user
        Api.callApi(
            buildRegionUrl(
                config.api_analysis_url + this.props.indicateur.id,
                this.props.parentApi.data.region
            ),
            formData,
            "PUT",
            "default"
        )
            .then((response) => {
                this.setState({ status: response.message });
            })
            .catch((e) => {
                this.setState({ status: e.message });
            });
    }

    /**
     * update the component state with the data file to import
     */
    onFichierIndicateurSelection = (event) => {
        this.setState({ fichierDonnees: event.target.files[0] });
    };

    /**
     * update the component state with the PDF methodo file
     */
    onFichierPdfMethodoSelection = (event) => {
        this.setState({ fichierPdfMethodo: event.target.files[0] });
    };

    /**
     * update the component state with the indicator type
     */
    onIndicatorTypeChanged = (e) => {
        this.setState({ indicatorType: e.currentTarget.value });
    };

    /**
     * update the component state with the choice of data (upload or data already present in the database)
     */
    handleCheckFromExistingData = (e) => {
        this.setState({ fromExistingData: e.currentTarget.checked });
    };

    /**
     * update the component state with new value for credits analysis producers
     */
    handleCreditsAnalysisProducers = (newVal) => {
        this.setState({ creditsAnalysisProducers: newVal });
    };

    /**
     * update the component state with new value for credits data Sources
     */
    handleCreditsDataSources = (newVal) => {
        this.setState({ creditsDataSources: newVal });
    };

    /**
     * update the component state with new value for credits data Producers
     */
    handleCreditsDataProducers = (newVal) => {
        this.setState({ creditsDataProducers: newVal });
    };

    /**
     * update the component state with new value for credits data Producers
     */
    handleDisabledInDashboard = (newVal) => {
        this.setState({ disabledInDashboard: newVal });
    };

    /**
     * update the component state with new value for credits data Producers
     */
    handleDisabledCatInDashboard = (cat, newVal) => {
        this.setState({
            disabledCatInDashboard: {
                ...this.state.disabledCatInDashboard,
                [cat]: newVal ? "yes" : "no",
            },
        });
    };

    /**
     * Generic renderer for a form line
     * @param {str} id : field identifier
     * @param {str} label : field label
     * @param {input} field : input (form)
     * @param {str} className : class name of the component. Opionnal
     * @param {str} helpMessage : title displayed on hover. If not specified, will be searched in this.helpMessages[id]
     */
    renderField(id, label, field, className, helpMessage) {
        if (!helpMessage) helpMessage = this.helpMessages[id];
        return (
            <div className={className}>
                <label
                    htmlFor={id}
                    className="col-sm-2 control-label"
                    title={helpMessage}
                >
                    {label}
                </label>
                {field}
            </div>
        );
    }

    /**
     * Renderer for an input type field
     * @param {str} id : field identifier
     * @param {str} label : field label
     * @param {str} className : class name of the component. Optionnal
     * @param {str} helpMessage : title displayed on hover. If not specified, will be searched in this.helpMessages[id]
     * @param {str} placeHolder : text displayed in input when empty. If not specified, will be searched in this.placeHolders[id]
     */
    renderTextInput(
        id,
        label,
        className,
        helpMessage,
        placeHolder,
        additionalParams = {}
    ) {
        if (!placeHolder) placeHolder = this.placeHolders[id];
        const handleChangeText = (e) => {
            let dict = {};
            dict[id] = e.currentTarget.value;
            this.setState(dict);
        };
        return this.renderField(
            id,
            label,
            <input
                type="text"
                className="form-control form-inline col-sm-4 taille-formulaire-admin"
                id={id}
                placeholder={placeHolder}
                value={this.state[id] ?? ""}
                onChange={handleChangeText}
                {...additionalParams}
            />,
            className,
            helpMessage
        );
    }

    /**
     * Selected category
     */
    handleCheckCategorie = (c) => {
        let selectedCategories = this.state.selectedCategories;
        const categorieCliquee = selectedCategories.indexOf(c.target.name);

        if (c.target.checked) {
            selectedCategories.push(c.target.name);
        } else {
            selectedCategories.splice(categorieCliquee, 1);
        }
        this.setState({ selectedCategories: selectedCategories });

        if (!this.state["graph_type_" + c.target.name]) {
            this.setState({ ["graph_type_" + c.target.name]: "pie" });
        }
    };

    /**
     * Construction of the list of available categories
     */
    construireListeCategories = () => {
        let listeCategories = [];
        let i = 0;
        for (let cat in this.state.categories) {
            let styleTitre = "hidden";
            let checked = false;
            if (this.state.selectedCategories.includes(cat)) {
                styleTitre = "";
                checked = true;
                i++;
            }

            const valGraphType = this.state["graph_type_" + cat] ?? "pie";

            let additionalInputs = [];
            if (["switch-button", "selection"].includes(valGraphType)) {
                additionalInputs.push(
                    this.renderTextInput(
                        "additional_details_text_" + cat,
                        "Texte explicatif",
                        "inline",
                        this.helpMessages.explanationForCategorySelection,
                        cat,
                        "Texte explicatif pour le champ de sélection ou le bouton de type switch"
                    )
                );
                additionalInputs.push(
                    this.renderField(
                        "additional_details_disable_in_dashboard_" + cat,
                        "Désactiver dans les tableaux de bord",
                        <input
                            type="checkbox"
                            id={"additional_details_disable_in_dashboard_" + cat}
                            name={"additional_details_disable_in_dashboard_" + cat}
                            checked={this.state.disabledCatInDashboard?.[cat] === "yes"}
                            onChange={(e) =>
                                this.handleDisabledCatInDashboard(
                                    cat,
                                    e.target.checked ? true : false
                                )
                            }
                            className="col-sm-2 taille-formulaire-admin"
                        />,
                        "inline",
                        this.helpMessages.disabledCatInDashboard
                    )
                );
            }

            listeCategories.push(
                <li key={cat} className="list-unstyled">
                    <input
                        onChange={this.handleCheckCategorie}
                        name={cat}
                        id={"check_" + cat}
                        type="checkbox"
                        checked={checked}
                    />
                    <label className="form-check-label" htmlFor={"check_" + cat}>
                        {cat}
                    </label>
                    <div className={styleTitre}>
                        {this.renderTextInput(
                            "titre_" + cat,
                            "Titre",
                            "inline",
                            this.helpMessages.categoryTitle
                                ? this.helpMessages.categoryTitle.replace("{cat}", cat)
                                : false,
                            cat
                        )}
                        {this.renderTextInput(
                            "order_" + cat,
                            "Ordre",
                            "inline",
                            this.helpMessages.categoryOrder
                                ? this.helpMessages.categoryOrder.replace("{cat}", cat)
                                : false,
                            i
                        )}
                        {this.renderField(
                            "graph_type_" + cat,
                            "Graphique",
                            <Select
                                value={{
                                    value: valGraphType,
                                    label: this.graphTypes?.[valGraphType] ?? "Inconnu",
                                }}
                                name={"graph_type_" + cat}
                                options={Object.entries(this.graphTypes).map(
                                    ([key, label]) => ({ value: key, label })
                                )}
                                className="inline-basic-select"
                                onChange={(e) => {
                                    let dict = {};
                                    dict["graph_type_" + cat] = e.value;
                                    this.setState(dict);
                                }}
                            />,
                            "inline",
                            this.helpMessages.categoryType
                                ? this.helpMessages.categoryType.replace("{cat}", cat)
                                : false
                        )}
                        {additionalInputs}
                    </div>
                </li>
            );
        }
        return listeCategories;
    };

    /**
     * Construction of the list of available themes
     */
    construireListeThemes = () => {
        let listeThemes = "";
        if (this.state.uiThemes) {
            listeThemes = (
                <Creatable
                    name="ui-theme"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    options={this.state.uiThemes.map(({ label }) => ({
                        value: label,
                        label,
                    }))}
                    value={{ value: this.state.theme, label: this.state.theme }}
                    onChange={(e) => this.setState({ theme: e.value })}
                    formatCreateLabel={(value) => `Créer le thème "${value}"`}
                />
            );
        }
        return listeThemes;
    };

    /**
     * Construction of the input field for the name of the table to create
     * To replace by a renderTextInput ?
     */
    construireNomTable = () => {
        let nomTable = "";
        if (this.listExistingTables) {
            nomTable = (
                <Creatable
                    name="table-name"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    options={this.listExistingTables.map(({ nom }) => ({
                        value: nom,
                        label: nom,
                        isDisabled: true,
                    }))}
                    value={{ value: this.state.tableName, label: this.state.tableName }}
                    onChange={(e) => this.setState({ tableName: e.value })}
                    formatCreateLabel={(value) => `Créer la table "${value}"`}
                    createOptionPosition="first"
                    isValidNewOption={(inputValue, _, options) =>
                        inputValue.match(/^[a-z0-9_]+$/) &&
                        options.every((option) => option.value !== inputValue)
                    }
                    noOptionsMessage={() => "Format incorrect"}
                />
            );
        }
        return nomTable;
    };

    /**
     * Construction of the input field for confidential
     */
    construireConfidentiel = () => {
        let confidentiel = "";
        if (this.state.confidLayers) {
            let options = [{ value: "", label: "Aucune confidentialité" }];
            this.state.confidLayers.forEach((z) => {
                options.push({ value: z.type_analyse, label: z.type_analyse });
            });
            const optionLabel =
                this.state.confidential === ""
                    ? "Aucune confidentialité"
                    : this.state.confidential;
            confidentiel = (
                <Select
                    name={"confidentiality"}
                    options={options}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    defaultValue={{
                        label: optionLabel,
                        value: this.state.confidential,
                    }}
                    value={{
                        label: optionLabel,
                        value: this.state.confidential,
                    }}
                    onChange={(e) => {
                        this.setState({ confidential: e.value });
                    }}
                />
            );
        }
        return confidentiel;
    };

    /**
     * Construction of the default filter input field
     * @return DOM filtreDefaut
     */
    construireFiltreDefaut = () => {
        let filtreDefaut = "";
        if (this.state.categories_modalites) {
            filtreDefaut = (
                <Select
                    name="category-filter"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    options={this.state.categories_modalites.map(({ label }) => ({
                        label,
                        value: label,
                    }))}
                    value={{ value: this.state.filter, label: this.state.filter }}
                    onChange={(e) => this.setState({ filter: e.value })}
                />
            );
        }
        return filtreDefaut;
    };

    majSelection = (e, mode) => {
        if (mode === "disabled_for_zone") {
            this.setState({
                disabledForZone: e,
            });
        } else if (mode === "zone_only") {
            this.setState({
                zoneOnly: e,
            });
        } else if (mode === "donnees_exportables") {
            this.setState({
                exportableZones: e,
            });
        } else if (mode === "disabled_for_macro_level") {
            this.setState({
                disabledForMacroLevel: e,
            });
        }
    };

    handleIndicatorParent = (e) => {
        this.setState({
            indicatorParent: e,
        });
    };

    handleIndicatorParentCategory = (e) => {
        this.setState({
            indicatorParentCategory: e,
        });
    };

    getListAnalysis() {
        const { controller } = this.props.parentApi;
        this.setState({
            analyses: controller.analysisManager.analysis,
        });
    }

    /**
     * Construction of the input field disabled_for_zone / only_for_zone
     * @return DOM zone
     */
    construireSelectionZone = (mode) => {
        let zone = "";
        const mailles = this.props.parentApi.controller.zonesManager.getMaillesList();
        const zones = Object.keys(
            this.props.parentApi.controller.zonesManager.zoneLists
        );
        let zonesData = [];
        zones.forEach((z) => {
            zonesData.push({ value: z, label: z });
        });

        if (mode === "disabled_for_zone") {
            zone = (
                <Select
                    isMulti
                    defaultValue={this.state.disabledForZone}
                    value={this.state.disabledForZone}
                    name={mode}
                    options={mailles}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(e) => this.majSelection(e, mode)}
                />
            );
        } else if (mode === "zone_only") {
            zone = (
                <Select
                    isMulti
                    defaultValue={this.state.zoneOnly}
                    value={this.state.zoneOnly}
                    name={mode}
                    options={mailles}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(e) => this.majSelection(e, mode)}
                />
            );
        } else if (mode === "donnees_exportables") {
            zone = (
                <Select
                    isMulti
                    defaultValue={this.state.exportableZones}
                    value={this.state.exportableZones}
                    name={mode}
                    options={mailles}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(e) => this.majSelection(e, mode)}
                />
            );
        } else if (mode === "disabled_for_macro_level") {
            // TODO: Use zones instead of maille here
            zone = (
                <Select
                    isMulti
                    defaultValue={this.state.disabledForMacroLevel}
                    value={this.state.disabledForMacroLevel}
                    name={mode}
                    options={zonesData}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(e) => this.majSelection(e, mode)}
                />
            );
        }
        return zone;
    };

    /**
     * Manage colorPicker
     * @return {dom} the rendering of the component
     */
    handleCouleurDebutClick = () => {
        this.setState({
            displayColorStartPicker: !this.state.displayColorStartPicker,
        });
    };
    handleCouleurDebutClose = () => {
        this.setState({ displayColorStartPicker: false });
    };
    handleCouleurFinClick = () => {
        this.setState({
            displayColorEndPicker: !this.state.displayColorEndPicker,
        });
    };
    handleCouleurFinClose = () => {
        this.setState({ displayColorEndPicker: false });
    };

    /**
     * Render start color of the indicator
     */
    handleGradientBeginningColor = (newColor, event) => {
        let color = newColor.hex + Math.round(newColor.rgb.a * 255).toString(16);
        this.setState({ colorStartGradient: color });
    };

    /**
     * Render end color of the indicator
     */
    handleGradientEndColor = (newColor, event) => {
        let color = newColor.hex + Math.round(newColor.rgb.a * 255).toString(16);
        this.setState({ colorEndGradient: color });
    };

    /**
     * Render end color of the indicator
     */
    handleStarColor = (newColor, event) => {
        let color = newColor.hex + Math.round(newColor.rgb.a * 255).toString(16);
        this.setState({ colorStars: color });
    };

    /**
     * Render min stars value of the indicator
     */
    handleMinValueStars = (value, event) => {
        this.setState({ minValueStars: value });
    };

    /**
     * Render max stars value of the indicator
     */
    handleMaxValueStars = (value, event) => {
        this.setState({ maxValueStars: value });
    };

    /**
     * Add a category
     */
    ajouterCategorie = () => {
        let formData = new FormData();

        // Verifications
        if (this.state.categoryName === "") {
            this.setState({ status: "Vous devez renseigner un nom" });
            return;
        }
        if (!this.state.fichierDonneesCategorie) {
            this.setState({
                status: "Vous devez choisir un fichier de données",
            });
            return;
        }

        // update form
        formData.append("nom", this.state.categoryName);
        formData.append("fichier", this.state.fichierDonneesCategorie);
        formData.append("nomFichier", this.state.fichierDonneesCategorie.name);
        // Call API with file and category name
        Api.callApi(
            buildRegionUrl(
                config.api_categorie_ajouter_url,
                this.props.parentApi.data.region
            ),
            formData,
            "POST",
            "default"
        )
            .then((response) => {
                this.setState({ status: response.message });
                // Reload the list of categories in the interface
                this.getListeCategories();
            })
            .catch((e) => {
                this.setState({ status: e.message });
            });
    };

    /**
     * update the component state with the new category file to import
     */
    onFichierCategorieSelection = (event) => {
        this.setState({ fichierDonneesCategorie: event.target.files[0] });
    };

    updateGeographicPerimeterYear(e) {
        this.setState({
            geographicPerimeterYear: parseInt(e.target.value, 10),
        });
    }

    /**
     * Generate indicator list for children indicators.
     */
    renderIndicatorsList(indicators) {
        let sel = {},
            themes = [];
        for (let analysis of indicators) {
            if (!sel[analysis.ui_theme]) {
                sel[analysis.ui_theme] = [];
                themes.push({
                    theme: analysis.ui_theme,
                    order: analysis.ordre_ui_theme,
                });
            }
            if (analysis.is_sub_indicator) {
                continue;
            }

            sel[analysis.ui_theme].push({
                label: analysis.nom,
                value: analysis.id,
            });
        }
        themes.sort((a, b) => a.order - b.order);
        return themes.map(({ theme }) => {
            return {
                label: theme,
                options: sel[theme],
            };
        });
    }

    /**
     * @return {dom} the rendering of the component
     */
    render() {
        if (
            !this.props.connected ||
            !this.props.userInfos ||
            this.props.userInfos?.profil !== "admin"
        ) {
            return <div>Non accessible.</div>;
        }

        let erreurs = "";
        if (this.state.status) {
            erreurs = <div className="alert alert-warning">{this.state.status}</div>;
        }

        let categories = this.construireListeCategories();
        let themes = this.construireListeThemes();
        let nomTable = this.construireNomTable();
        let confidentiel = this.construireConfidentiel();
        let filtreDefaut = this.construireFiltreDefaut();
        let exportableZones = this.construireSelectionZone("donnees_exportables");
        let zoneOnly = this.construireSelectionZone("zone_only");
        let disabledForZone = this.construireSelectionZone("disabled_for_zone");
        let disabledForMacroLevel = this.construireSelectionZone(
            "disabled_for_macro_level"
        );
        let listePerimetresGeo = [];
        for (let i = configData.anneeMin; i <= new Date().getFullYear(); i++) {
            listePerimetresGeo.push(
                <option key={"geo_perimeter_" + i} value={i}>
                    {i}
                </option>
            );
        }
        let validationBouton = (
            <div className="form-horizontal">
                <button
                    className="btn btn-success"
                    onClick={() => this.ajouterAnalyse()}
                >
                    Ajouter l'indicateur
                </button>
            </div>
        );
        let blockNomTable = (
            <div className="form-horizontal">
                <label
                    className="col-sm-2 control-label"
                    title={this.helpMessages.tableName}
                >
                    Nom de la table
                </label>
                {nomTable}
            </div>
        );

        let blockColorStar =
            this.state.indicatorType === "stars" ? (
                <>
                    <AnalysisColorPicker
                        color={this.state.colorStars}
                        callback={this.handleStarColor}
                        label="Couleur des étoiles"
                        title={this.helpMessages.fullStarColor}
                    />
                    {this.renderField(
                        "minValueStars",
                        "Nombre minimal d'étoiles",
                        <input
                            type="number"
                            key="min-value-stars"
                            className="form-control form-inline col-sm-4 taille-formulaire-admin"
                            onChange={(e) => this.handleMinValueStars(e.target.value)}
                            value={this.state.minValueStars}
                        />,
                        "",
                        this.helpMessages.minValueStars
                    )}
                    {this.renderField(
                        "maxValueStars",
                        "Nombre maximal d'étoiles",
                        <input
                            type="number"
                            key="max-value-stars"
                            className="form-control form-inline col-sm-4 taille-formulaire-admin"
                            onChange={(e) => this.handleMaxValueStars(e.target.value)}
                            value={this.state.maxValueStars}
                        />,
                        this.helpMessages.maxValueStars
                    )}
                </>
            ) : (
                ""
            );

        let blockWMSParameters =
            this.state.indicatorType === "wms_feed" ? (
                <>
                    <div className="form-horizontal">
                        {this.renderTextInput(
                            "wmsUrl",
                            "URL du flux WMS",
                            "form-inline col-sm-4 taille-formulaire-admin",
                            this.helpMessages.wmsUrl,
                            "https://..."
                        )}
                    </div>
                    <div className="form-horizontal">
                        {this.renderTextInput(
                            "wmsLayer",
                            "Nom de la couche à afficher",
                            "form-inline col-sm-4 taille-formulaire-admin",
                            this.helpMessages.wmsLayer,
                            "nom_de_couche_sur_wms"
                        )}
                    </div>
                    <div className="form-horizontal">
                        {this.renderTextInput(
                            "wmsProjection",
                            "Code de la projection (EPSG:XXXX)",
                            "form-inline col-sm-4 taille-formulaire-admin",
                            this.helpMessages.wmsProjection,
                            "3857"
                        )}
                    </div>
                    <div className="form-horizontal">
                        {this.renderTextInput(
                            "wmsServerType",
                            "Type de serveur (parmi mapserver, geoserver)",
                            "form-inline col-sm-4 taille-formulaire-admin",
                            this.helpMessages.wmsServerType,
                            "geoserver"
                        )}
                    </div>
                    <div className="form-horizontal">
                        {this.renderTextInput(
                            "wmsOpacity",
                            "Opacité (0-1)",
                            "form-inline col-sm-4 taille-formulaire-admin",
                            this.helpMessages.wmsOpacity,
                            "1.0",
                            { type: "number", step: "0.01", min: 0, max: 1 }
                        )}
                    </div>
                </>
            ) : (
                ""
            );

        let blockType = (
            <div className="form-horizontal">
                <label
                    className="col-sm-2 control-label"
                    title={this.helpMessages.indicatorType}
                >
                    Type de représentation
                </label>
                <div className="custom-block">
                    <label>
                        <input
                            type="radio"
                            name="type"
                            value="circle"
                            checked={this.state.indicatorType === "circle"}
                            onChange={this.onIndicatorTypeChanged}
                        />
                        Cercles
                    </label>
                    <label>
                        <input
                            type="radio"
                            name="type"
                            value="choropleth"
                            id="type_choropleth"
                            checked={this.state.indicatorType === "choropleth"}
                            onChange={this.onIndicatorTypeChanged}
                        />
                        Applats de couleur
                    </label>
                    <label>
                        <input
                            type="radio"
                            name="type"
                            value="flow"
                            id="type_flow"
                            checked={this.state.indicatorType === "flow"}
                            onChange={this.onIndicatorTypeChanged}
                        />
                        Flux
                    </label>
                    <label>
                        <input
                            type="radio"
                            name="type"
                            value="pixels"
                            id="type_pixel"
                            checked={this.state.indicatorType === "pixels"}
                            onChange={this.onIndicatorTypeChanged}
                        />
                        Pixels
                    </label>
                    <label>
                        <input
                            type="radio"
                            name="type"
                            value="pixels_cat"
                            id="type_pixels_cat"
                            checked={this.state.indicatorType === "pixels_cat"}
                            onChange={this.onIndicatorTypeChanged}
                        />
                        Pixels par catégorie
                    </label>
                    <label>
                        <input
                            type="radio"
                            name="type"
                            ref="type"
                            value="stars"
                            id="type_stars"
                            checked={this.state.indicatorType === "stars"}
                            onChange={this.onIndicatorTypeChanged}
                        />
                        Étoiles
                    </label>
                    <label>
                        <input
                            type="radio"
                            name="type"
                            value="wms_feed"
                            id="type_wms_feed"
                            checked={this.state.indicatorType === "wms_feed"}
                            onChange={this.onIndicatorTypeChanged}
                        />
                        Flux WMS
                    </label>
                </div>
                {blockColorStar}
                {blockWMSParameters}
            </div>
        );

        let blockCouleurDebut = this.state.indicatorType !== "wms_feed" && (
            <AnalysisColorPicker
                color={this.state.colorStartGradient}
                callback={this.handleGradientBeginningColor}
                label="Couleur de représentation (début)"
                title={this.helpMessages.gradientColorStart}
            />
        );

        let blockCouleurFin = this.state.indicatorType !== "wms_feed" && (
            <AnalysisColorPicker
                color={this.state.colorEndGradient}
                callback={this.handleGradientEndColor}
                label="Couleur de représentation (fin)"
                title={this.helpMessages.gradientColorEnd}
            />
        );

        let blockTheme = (
            <div className="form-horizontal">
                <label
                    className="col-sm-2 control-label"
                    title={this.helpMessages.theme}
                >
                    Thème
                </label>
                {themes}
            </div>
        );

        let blockDataSelect = (
            <div>
                <p className="admin-upload-info">
                    <AdviceOnDataTable />
                </p>
                {blockNomTable}
                <div className="form-horizontal">
                    <label className="col-sm-2 control-label">
                        Année du périmètre géographique
                    </label>
                    <select
                        className="form-control form-inline col-sm-4 taille-formulaire-admin"
                        onChange={(e) => this.updateGeographicPerimeterYear(e)}
                    >
                        {listePerimetresGeo}
                    </select>
                </div>
                <div className="actions">
                    <input type="file" onChange={this.onFichierIndicateurSelection} />
                </div>
                ou
            </div>
        );
        let blockConfidentiel = this.state.indicatorType !== "wms_feed" && (
            <div className="form-horizontal">
                <label
                    className="col-sm-2 control-label"
                    title={this.helpMessages.confidential}
                >
                    Confidentialité
                </label>
                {confidentiel}
            </div>
        );
        let blockExportableZones = this.state.indicatorType !== "wms_feed" && (
            <div className="form-horizontal">
                <label
                    className="col-sm-2 control-label"
                    title={this.helpMessages.exportableZones}
                >
                    Mailles auxquelles les données sont exportables
                </label>
                {exportableZones}
            </div>
        );
        let blockZoneOnly = (
            <div className="form-horizontal">
                <label className="col-sm-2 control-label">
                    Seulement pour les mailles
                </label>
                {zoneOnly}
            </div>
        );
        let blockDisabledForZone = (
            <div className="form-horizontal">
                <label className="col-sm-2 control-label">
                    Désactivé pour les mailles
                </label>
                {disabledForZone}
            </div>
        );
        let blockDisabledForMacroLevel = (
            <div className="form-horizontal">
                <label className="col-sm-2 control-label">
                    Désactivé pour les échelles
                </label>
                {disabledForMacroLevel}
            </div>
        );
        let blockIndicatorParent = (
            <div className="form-horizontal">
                <label className="col-sm-2 control-label">Indicateur parent</label>
                <Select
                    value={this.state.indicatorParent}
                    name="parent"
                    options={this.renderIndicatorsList(this.state.analyses)}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(e) => this.handleIndicatorParent(e)}
                    isClearable
                />
            </div>
        );

        let blockIndicatorParentCategory = (
            <div className="form-horizontal">
                <label className="col-sm-2 control-label">
                    Indicateur parent categorie
                </label>
                <Select
                    value={this.state.indicatorParentCategory}
                    name="parent"
                    options={this.state.categories_modalites}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(e) => this.handleIndicatorParentCategory(e)}
                    isClearable
                    isDisabled={this.state.indicatorParent ? false : true}
                />
            </div>
        );
        let blockPdfMethodo = this.renderField(
            "pdfMethodo",
            "Fichier PDF méthodologie",
            <input
                type="file"
                id="pdfMethodo"
                onChange={this.onFichierPdfMethodoSelection}
            />,
            "actions"
        );

        let blockDataCustom = "";
        if (this.state.fromExistingData) {
            blockDataSelect = "";
            blockDataCustom = (
                <div className="form-horizontal">
                    {this.renderTextInput("dataCustom", "Formule de calcul")}
                </div>
            );
        }
        let blockData = this.state.indicatorType !== "wms_feed" && (
            <div className="block-etape">
                <h4>Sélectionnez les données de l'indicateur</h4>
                {blockDataSelect}

                <div className="actions">
                    <label>
                        <input
                            type="checkbox"
                            className="form-inline col-sm-4 form-checkbox"
                            onChange={this.handleCheckFromExistingData}
                        />
                        <span className="checkbox-label">
                            Construire l'indicateur à partir de données saisies
                            précédemment
                        </span>
                    </label>
                    {blockDataCustom}
                </div>
            </div>
        );

        let blockFiltre = this.state.indicatorType !== "wms_feed" && (
            <div className="form-horizontal">
                <label
                    className="col-sm-2 control-label"
                    title={this.helpMessages.filter}
                >
                    Filtre par défaut
                </label>
                {filtreDefaut}
            </div>
        );

        let titre = "Ajout d'un indicateur";
        if (this.props.mode === "modifier") {
            titre = "Modifier un indicateur";
            validationBouton = (
                <div className="form-horizontal">
                    <button
                        className="btn btn-success"
                        onClick={() => this.modifierAnalyse()}
                    >
                        Mettre à jour l'indicateur
                    </button>
                </div>
            );
            // In edit mode you must hide: data selection, representation type, color and theme
            blockTheme = "";
            blockData = blockDataCustom;
        }

        return (
            <div>
                <div className="panel-body panel-ajout-indicateur">
                    <h3 className="panel-title pull-left">{titre}</h3>
                    <div className="block-etape">
                        <h4>Renseignez les informations pour cet indicateur</h4>
                        <div className="actions">
                            <div className="form-horizontal">
                                {this.renderTextInput("name", "Nom de l'indicateur")}
                            </div>
                            {blockType}

                            {blockCouleurDebut}

                            {blockCouleurFin}

                            <div className="form-horizontal">
                                {this.renderTextInput("unit", "Unité")}
                            </div>

                            <div className="form-horizontal">
                                {this.renderField(
                                    "disabled_in_dashboard",
                                    "Désactiver dans les tableaux",
                                    <input
                                        type="checkbox"
                                        id={"disabled_in_dashboard"}
                                        checked={this.state.disabledInDashboard}
                                        onChange={(e) =>
                                            this.handleDisabledInDashboard(
                                                e.target.checked ? true : false
                                            )
                                        }
                                        className="col-sm-4 taille-formulaire-admin"
                                    />,
                                    "",
                                    this.helpMessages.disabledInDashboard
                                )}
                            </div>
                            {this.state.indicatorType !== "wms_feed" && (
                                <div className="form-horizontal">
                                    {this.renderTextInput("decimals", "Décimales")}
                                </div>
                            )}

                            {blockTheme}
                            {blockIndicatorParent}
                            {blockIndicatorParentCategory}
                            {this.state.indicatorType === "circle" && (
                                <div className="form-horizontal">
                                    {this.renderTextInput(
                                        "forcedMinScale",
                                        "Valeur minimale de représentation",
                                        "",
                                        this.helpMessages.forcedMinScale
                                    )}
                                </div>
                            )}

                            <div className="form-horizontal">
                                <SourcesListInput
                                    name="Sources de la donnée"
                                    id="data_sources"
                                    sources={this.state.creditsDataSources}
                                    changeCallback={this.handleCreditsDataSources}
                                />
                            </div>

                            <div className="form-horizontal">
                                <SourcesListInput
                                    name="Producteurs de la donnée"
                                    id="data_producers"
                                    sources={this.state.creditsDataProducers}
                                    changeCallback={this.handleCreditsDataProducers}
                                />
                            </div>

                            <div className="form-horizontal">
                                <SourcesListInput
                                    name="Producteur(s) de l'indicateur"
                                    id="indicator_producers"
                                    sources={this.state.creditsAnalysisProducers}
                                    changeCallback={this.handleCreditsAnalysisProducers}
                                />
                            </div>

                            <div className="form-horizontal">
                                {this.renderTextInput("years", "Années")}
                            </div>

                            <div className="form-horizontal">
                                {this.renderTextInput(
                                    "estimatedYears",
                                    "Années pour lesquelles l'indicateur est estimé"
                                )}
                            </div>

                            {blockFiltre}

                            {blockConfidentiel}

                            {blockZoneOnly}

                            {blockDisabledForZone}

                            {blockDisabledForMacroLevel}

                            {blockPdfMethodo}

                            {blockExportableZones}
                        </div>
                    </div>

                    {blockData}

                    {this.state.indicatorType !== "wms_feed" && (
                        <div className="block-etape">
                            <h4>
                                Sélectionnez les catégories associées à cet indicateur
                            </h4>
                            <div className="actions">
                                {categories}

                                <div className="sous-block-etape">
                                    <p>
                                        Si votre indicateur requiert une catégorie non
                                        encore présente dans la base Terristory, vous
                                        pouvez l'y ajouter :
                                    </p>
                                    <div className="sous-form">
                                        {this.renderTextInput("categoryName", "Nom")}
                                    </div>
                                    <input
                                        type="file"
                                        onChange={this.onFichierCategorieSelection}
                                    />
                                    <button
                                        className="btn btn-success"
                                        onClick={() => this.ajouterCategorie()}
                                    >
                                        Ajouter la catégorie
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                    {validationBouton}

                    {erreurs}
                </div>
            </div>
        );
    }
}

export default AnalysisAjouter;
