/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import * as icons from "react-icons/md";
import { Link } from "react-router-dom";
import Select from "react-select";
import MDEditor from "@uiw/react-md-editor";
import rehypeSanitize from "rehype-sanitize";

import Api from "../Controllers/Api";
import FabriqueRepresentation from "./FabriqueRepresentation.js";
import {
    MainZoneSelect,
    VisualizationSelect,
    MultiLevelSelect,
} from "./SelectionObjet.js";
import config from "../settings.js";
import configData from "../settings_data.js";
import { buildRegionUrl, saveAsPng, createPdfMethodoLink } from "../utils.js";
import Confirm from "./Confirm";
import SEO from "./SEO";

const options = [
    { value: "MdHomeWork", label: <icons.MdHomeWork /> },
    {
        value: "MdConstruction",
        label: (
            <div>
                <icons.MdConstruction />
            </div>
        ),
    },
    { value: "MdFireplace", label: <icons.MdFireplace /> },
    { value: "MdLocalShipping", label: <icons.MdLocalShipping /> },
    {
        value: "MdOutlineLocalShipping",
        label: <icons.MdOutlineLocalShipping />,
    },
    { value: "MdFlight", label: <icons.MdFlight /> },
    { value: "MdDirectionsCar", label: <icons.MdDirectionsCar /> },
    { value: "MdDirectionsWalk", label: <icons.MdDirectionsWalk /> },
    { value: "MdDirectionsBike", label: <icons.MdDirectionsBike /> },
    { value: "MdDirectionsSubway", label: <icons.MdDirectionsSubway /> },
    { value: "MdDirectionsRailway", label: <icons.MdDirectionsRailway /> },
    { value: "MdTrain", label: <icons.MdTrain /> },
    { value: "MdTram", label: <icons.MdTram /> },
    { value: "MdAgriculture", label: <icons.MdAgriculture /> },
    { value: "MdOutlineAgriculture", label: <icons.MdOutlineAgriculture /> },
    { value: "MdTerrain", label: <icons.MdTerrain /> },
    { value: "MdOutlineTerrain", label: <icons.MdOutlineTerrain /> },
    { value: "MdAir", label: <icons.MdAir /> },
    { value: "MdThermostat", label: <icons.MdThermostat /> },
    { value: "MdWbSunny", label: <icons.MdWbSunny /> },
    { value: "MdEngineering", label: <icons.MdEngineering /> },
    { value: "MdGroups", label: <icons.MdGroups /> },
    { value: "MdOutlineElectricCar", label: <icons.MdOutlineElectricCar /> },
    { value: "MdEvStation", label: <icons.MdEvStation /> },
    { value: "MdOutlineEvStation", label: <icons.MdOutlineEvStation /> },
    { value: "MdCompost", label: <icons.MdCompost /> },
    { value: "MdEuro", label: <icons.MdEuro /> },
    { value: "MdOutlineAttachMoney", label: <icons.MdOutlineAttachMoney /> },
    { value: "MdAttachMoney", label: <icons.MdAttachMoney /> },
    { value: "MdOutlineBolt", label: <icons.MdOutlineBolt /> },
    { value: "MdHome", label: <icons.MdHome /> },
    { value: "MdTrendingUp", label: <icons.MdTrendingUp /> },
    { value: "MdTrendingDown", label: <icons.MdTrendingDown /> },
    { value: "MdOutlineInsights", label: <icons.MdOutlineInsights /> },
];

/**
 Permet de générer la structure d'un tableau de bord.
Pour tout renseignement sur les fonctions de this.props.parentApi.callbacks,
consultez le fichier ../src/index.js

L'enregistrement des données se fait dans la variable this.props.parentApi.data.tableauBordDonnees
dont la structure est la suivante :
{
donnees: {
    thematique1 : {
        numero_analyse {
            id_analysis (identifiant de l'analyse),
            representation : representation sélectionnée,
            categories { (uniquement celles dont la case est cochée)
                categorie1 {titre: titre, categorie: nom de la catégorie, visble: true si elle est active false sinon}
            }
        }
    }
    thematique2:
    etc.
}
metadonnees: {titre: titre du tableau de bord, description: description du tableau de bord} (À préciser par l'utilsiateur.trice)
}
*/
class DashboardEdition extends React.Component {
    constructor(props) {
        super(props);
        let lIndicateursFinal = props.parentApi.controller.analysisManager.analysis;
        this.state = {
            analyses: lIndicateursFinal, // Liste des indicateurs
            nbreAnalyses: 9, // identifiant incrémental pour les analyses (nombre total d'analyses ajoutées y compris celles qui seront supprimées plus tard).
            nbreThematiques: 3,
            listOptionsByAnalysis: {},
            tableauBordDonnees: undefined,
            isDashboardsListRefreshNecessary: true,
            isUpdatingList: false,
            thematique: undefined,
            status: undefined,
            erreur: undefined,
            listeTableaux: props.parentApi.controller.dashboardManager.listMyDashboards,
            dashboardId: props.parentApi.data.tableauBordDonnees.id,
            nouvelleLigne: false,
            chartIndicateur: undefined,
            reloadinChartsRequired: false,
            gotLogos: false,
            gotDidacticFiles: false,
            gotPCAETTrajectories: false,
            isGettingLogos: false,
            isGettingDidacticFiles: false,
            isGettingPCAETTrajectories: false,
            listeAffectations: [],
            listLogo: [],
            listDidacticFile: [],
            listPCAETTrajectories: [],
            imageSVG: "",
            tailleSVG: undefined,
            tailleData: undefined,
            couleurAnalyseName: "",
            chargerMarqueurSVG: true,
            chargerAnalysisLauncher: true,
            confirmJSX: undefined,
            // some elements need to have internal state to be properly updated
            // so we have to keep parentApi internal state up to date as well as local state
            // in order to be able to reload current dashboard when leaving the page
            title:
                this.props.parentApi.data.tableauBordDonnees?.metadonnees?.titre ?? "",
            description:
                this.props.parentApi.data.tableauBordDonnees?.metadonnees
                    ?.description ?? "",
            currentContents: this.fillInBlockContents(),

            // La structure de analysesListByGroup est la suivante :
            // {
            //   thematique1: {
            //       code_analyse1 : sélecteur, cases à cocher par catégorie et graphiques associés
            //   },
            //   thematique2: {}
            //   etc.
            // }
        };

        this.state.listOptionsByAnalysis = this.rebuildListOptions();
        if (
            props.parentApi.data.tableauBordDonnees.id ||
            props.parentApi.controller.dashboardManager.wasEditing
        ) {
            // Refresh the number of sections and charts, in case a dashboard was
            // loaded from profile or from a previous edition
            this.state.nbreThematiques = Object.keys(
                props.parentApi.data.tableauBordDonnees.donnees
            ).length;
            this.state.nbreAnalyses = this.maxAnalysisNumber();
        }
        this.showChart();
    }

    componentDidMount() {
        this.mounted = true;
        let indicateur = this.showChart();
        this.setState({
            chartIndicateur: indicateur,
        });
        if (this.state.dashboardId) {
            this.getTerritorialAssociations(this.state.dashboardId);
        }
        if (!this.state.gotLogos && this.props.parentApi.data.profil === "admin") {
            this.getDataSourceLogo();
        }

        if (!this.state.gotPCAETTrajectories) {
            this.getPCAETTrajectories();
        }

        if (
            !this.state.gotDidacticFiles &&
            this.props.parentApi.data.profil === "admin"
        ) {
            this.getDidacticFileData();
        }
        if (
            this.props.parentApi.controller.dashboardManager.wasEditing &&
            !this.props.parentApi.data.fromMenu &&
            !this.props.parentApi.data.reinitialiserTableauBordApresDeconnexion
        ) {
            // TODO: use a personalized popup to have significant button names instead of ok/cancel
            let r = window.confirm(
                "La construction d'un tableau de bord est en cours. Cliquez sur « OK » si vous souhaitez reprendre là où vous en étiez ou sur « annuler » pour repartir de zéro"
            );
            if (r !== true) {
                this.props.parentApi.controller.dashboardManager.setEditingModeDashboard(
                    false
                );
                let tableauBordInitial = {
                    donnees: {
                        1: {
                            titre_thematique: "Titre de la thématique",
                            indicateurs: {
                                1: {
                                    numero_analyse: "1",
                                    categories: {},
                                },
                                2: {
                                    numero_analyse: "2",
                                    categories: {},
                                },
                                3: {
                                    numero_analyse: "3",
                                    categories: {},
                                },
                            },
                            description_thematique: "Description de la thématique",
                            ordre: 0,
                        },
                        2: {
                            titre_thematique: "Titre de la thématique",
                            indicateurs: {
                                4: {
                                    numero_analyse: "4",
                                    categories: {},
                                },
                                5: {
                                    numero_analyse: "5",
                                    categories: {},
                                },
                                6: {
                                    numero_analyse: "6",
                                    categories: {},
                                },
                            },
                            description_thematique: "Description de la thématique",
                            ordre: 1,
                        },
                        3: {
                            titre_thematique: "Titre de la thématique",
                            indicateurs: {
                                7: {
                                    numero_analyse: "7",
                                    categories: {},
                                },
                                8: {
                                    numero_analyse: "8",
                                    categories: {},
                                },
                                9: {
                                    numero_analyse: "9",
                                    categories: {},
                                },
                            },
                            description_thematique: "Description de la thématique",
                            ordre: 2,
                        },
                    },
                    metadonnees: {},
                    charger: false,
                    affectationTerritoire: false,
                    tableauBordReinitialise: true,
                };

                this.setState({
                    tableauBordDonnees: tableauBordInitial,
                    reinitialisationTableauDeBord: true,
                    dashboardId: undefined,
                    nbreAnalyses: 9,
                    nbreThematiques: 3,
                });
                this.props.parentApi.callbacks.initialiserTableauBord(
                    tableauBordInitial
                );
            }
        }
        this.props.parentApi.callbacks.initialiserTableauBordApresConnexion(false);

        // Load equipments
        if (this.props.parentApi.controller.equipementsManager.themes.length > 0) {
            this.setState({
                poiRubriques: this.props.parentApi.controller.equipementsManager.themes,
            });
        } else {
            this.props.parentApi.controller.equipementsManager.getEquipementsLayers(
                true,
                () => {
                    this.setState({
                        poiRubriques:
                            this.props.parentApi.controller.equipementsManager.themes,
                    });
                }
            );
        }
    }

    /**
    Lorsqu'un.e utilisateur.rice déclenche un événement et que la fonction render est lancée à nouveau
    on met à jour la liste des tableaux de bord qui doivent figurer dans l'onglet « tableaux de bord ».
    Cette opération est nécessaire notamment lors de la mise à jour ou l'enregistrement d'un nouveau
    tableau de bord.
    @param {objet clé => valeur} props : état du composant Main (index.js) auquel on accède avec this.props.parentApi
    @param {objet clé => valeur} etatComposant : état du composant accessible avec this.state
    */
    componentDidUpdate(props, etatComposant) {
        // Tant que la mise à jour est nécessaire c'est-à-dire que le paramètre
        // gestionDashboardEdition.listMyDashboards est égal à listeTableaux de ce composant-ci
        // lors de son ouverture, on met à jour la liste des tableaux de bord créés par
        // la personne connectée en appelant au moyen de la fonction updateDashboardsList
        // du composant Main, la fonction d'obtention de la liste des tableaux de bord située dans
        // le composant DashboardService
        if (
            etatComposant.imageSVG !== this.state.imageSVG ||
            etatComposant.tailleSVG !== this.state.tailleSVG ||
            etatComposant.tailleData !== this.state.tailleData ||
            etatComposant.couleurAnalyseName !== this.state.couleurAnalyseName
        ) {
            let indicateur = this.showChart();
            this.setState({
                chartIndicateur: indicateur,
            });
        }
        if (this.state.tableauBordDonnees) {
            if (this.state.tableauBordDonnees.tableauBordReinitialise) {
                let tableauBordDonnees = this.state.tableauBordDonnees;
                this.props.parentApi.callbacks.initialiserTableauBord(
                    tableauBordDonnees
                );
                tableauBordDonnees.tableauBordReinitialise = false;
                let indicateur = this.showChart();
                this.setState({
                    tableauBordDonnees: tableauBordDonnees,
                    chartIndicateur: indicateur,
                    reinitialisationTableauDeBord: true,
                });
            }
        }

        if (!this.state.gotLogos && this.props.parentApi.data.profil === "admin") {
            this.getDataSourceLogo();
        }

        if (
            !this.state.gotDidacticFiles &&
            this.props.parentApi.data.profil === "admin"
        ) {
            this.getDidacticFileData();
        }

        let changementDeTerritoire =
            this.props.parentApi.data.currentZone !== props.parentApi.data.currentZone;
        let changementDeRepresentation =
            this.props.parentApi.data.representationCourante &&
            this.props.parentApi.data.representationCourante !==
                props.parentApi.data.representationCourante;
        let changementDesFiltres =
            this.props.parentApi.data.filtreCategorieCourant !==
            props.parentApi.data.filtreCategorieCourant;
        if (
            changementDeTerritoire ||
            this.state.reloadinChartsRequired ||
            changementDeRepresentation ||
            changementDesFiltres
        ) {
            if (
                (this.props.parentApi.data.currentZone &&
                    this.props.parentApi.data.currentZone !== "") ||
                this.props.parentApi.data.zone.zone === "region"
            ) {
                let indicateur = this.showChart();
                this.setState({
                    reloadinChartsRequired: false,
                    chartIndicateur: indicateur,
                });
            }
        }

        if (
            this.state.dashboardId &&
            this.state.dashboardId !== etatComposant.dashboardId
        ) {
            this.getTerritorialAssociations(this.state.dashboardId);
        }

        if (
            etatComposant.isDashboardsListRefreshNecessary &&
            !this.state.isUpdatingList
        ) {
            this.setState({
                isUpdatingList: true,
            });
            this.props.parentApi.callbacks.updateDashboardsList(
                this.props.parentApi.data.zone.zone,
                this.props.parentApi.data.currentZone
            );
        }
        if (
            props.parentApi.controller.dashboardManager.listMyDashboards !==
            etatComposant.listeTableaux
        ) {
            this.setState({
                // Lorsque ces deux variables sont différents, cela signifie que la mise à jour est terminée
                listeTableaux:
                    props.parentApi.controller.dashboardManager.listMyDashboards, // On met à jour la liste des tableaux de bord (nécessaire pour ne pas lancer le render indéfiniment)
                isDashboardsListRefreshNecessary: false, // Et donc, il n'est plus nécessaire de lancer this.props.parentApi.callbacks.updateDashboardsList
                isUpdatingList: false, // We finished retrieving the list
            });
        }

        let elem = document.getElementById("tdb-reinitialise");
        if (elem) {
            this.setState({
                reinitialisationTableauDeBord: false,
            });
        }
    }

    fillInBlockContents() {
        let output = {};
        for (const groupNumber in this.props.parentApi.data.tableauBordDonnees
            .donnees) {
            const group =
                this.props.parentApi.data.tableauBordDonnees.donnees[groupNumber];
            if (!group.indicateurs) {
                continue;
            }
            for (const id in group.indicateurs) {
                if (group.indicateurs[id].representation !== "text-block") {
                    continue;
                }
                const value = group.indicateurs[id].content;
                output[id] = value;
            }
        }
        return output;
    }

    getDataSourceLogo() {
        if (this.state.isGettingLogos) {
            return;
        }
        this.setState({
            isGettingLogos: true,
        });
        // Get source data logo from API
        let url = buildRegionUrl(
            config.api_logo_sources_list,
            this.props.parentApi.data.region
        );
        Api.callApi(url, null, "GET").then((response) => {
            this.setState({
                listLogo: response,
                gotLogos: true,
                isGettingLogos: false,
                reloadinChartsRequired: true,
            });
        });
    }

    getDidacticFileData() {
        if (this.state.isGettingDidacticFiles) {
            return;
        }
        this.setState({
            isGettingDidacticFiles: true,
        });
        let url = buildRegionUrl(
            config.api_didactic_file_list,
            this.props.parentApi.data.region
        );
        Api.callApi(url, null, "GET").then((response) => {
            this.setState({
                listDidacticFile: response,
                gotDidacticFiles: true,
                isGettingDidacticFiles: false,
                reloadinChartsRequired: true,
            });
        });
    }

    getPCAETTrajectories() {
        if (this.state.isGettingPCAETTrajectories) {
            return;
        }
        this.setState({
            isGettingPCAETTrajectories: true,
        });
        let url = buildRegionUrl(
            config.api_pcaet_trajectories_list,
            this.props.parentApi.data.region
        );
        Api.callApi(url, null, "GET").then((response) => {
            this.setState({
                listPCAETTrajectories: response,
                gotPCAETTrajectories: true,
                isGettingPCAETTrajectories: false,
                reloadinChartsRequired: true,
            });
        });
    }

    showMarkdownTooltip(jsx) {
        return (
            <span title="Le champ ci-contre supporte le formattage Markdown. Voir en bas de page pour plus d'informations.">
                {jsx}*
            </span>
        );
    }

    /**
    Retourne le numéro d'un indicateur dont la valeur est maximale afin d'éviter que l'ajout d'un nouvel
    indicateur en écrase un qui existe déjà (il faut à tout prix que ce numéro d'indicateur soit discriminant)
    */
    maxAnalysisNumber() {
        let nbreAnalyses = 0;
        for (let thematique in this.props.parentApi.data.tableauBordDonnees.donnees) {
            for (let numero_analyse in this.props.parentApi.data.tableauBordDonnees
                .donnees[thematique].indicateurs) {
                if (parseInt(numero_analyse, 10) > nbreAnalyses) {
                    nbreAnalyses = parseInt(numero_analyse, 10);
                }
            }
        }
        return nbreAnalyses;
    }

    getTerritorialAssociations(dashboardId) {
        let region = this.props.parentApi.controller.analysisManager.region;
        let url = buildRegionUrl(config.tableau_bord_liste_affectations_url, region);
        url = url.replace("#dashboard_id#", dashboardId);
        Api.callApi(url, null, "GET").then((response) => {
            this.setState({
                listeAffectations: response.liste_affectations,
            });
        });
    }

    rebuildListOptions() {
        let listOptions = {};
        for (let groupNumber in this.props.parentApi.data.tableauBordDonnees.donnees) {
            for (let numero_analyse in this.props.parentApi.data.tableauBordDonnees
                .donnees[groupNumber].indicateurs) {
                const options =
                    this.props.parentApi.data.tableauBordDonnees.donnees[groupNumber]
                        .indicateurs[numero_analyse].options;
                listOptions[numero_analyse] = options;
            }
        }
        return listOptions;
    }

    /**
    Déclenchée sur événement
    Cette fonction sert à mettre à jour le nombre d'indicateurs que comprend une thématique
    du tableau de bord.
    @param  {int} groupNumber : group ID
    @param  {mixed} newObjectType : object type (default: undefined)
    */
    updateIndicatorsNumbersInGroups(groupNumber, newObjectType = undefined) {
        this.setState({
            nbreAnalyses: this.state.nbreAnalyses + 1, // Mise à jour du nombre d'analyses
        });
        this.props.parentApi.callbacks.updateDashboard(
            groupNumber,
            this.props.parentApi.data.tableauBordDonnees.donnees[groupNumber]
                .titre_thematique,
            this.props.parentApi.data.tableauBordDonnees.donnees[groupNumber]
                .description_thematique,
            this.state.nbreAnalyses + 1,
            {
                id_analysis: undefined,
                representation: newObjectType,
                numero_analyse: this.state.nbreAnalyses + 1,
            }
        );
        this.props.parentApi.controller.dashboardManager.setEditingModeDashboard(true);
    }

    /**
    Retourne une balise html destinée à ajouter une analyse au tableau de bord
    @param  {int} groupNumber : group ID
    */
    buttonAddNewIndicator(groupNumber) {
        return (
            <button
                key={"ajouter_analyse_" + groupNumber}
                className="btn btn-success"
                onClick={() => this.updateIndicatorsNumbersInGroups(groupNumber)}
            >
                {" "}
                Nouvel indicateur{" "}
            </button>
        );
    }

    buttonAddPOIMap(groupNumber) {
        return (
            <button
                className="btn btn-success"
                onClick={() =>
                    this.updateIndicatorsNumbersInGroups(groupNumber, "poi_map")
                }
            >
                {" "}
                Nouvel équipement{" "}
            </button>
        );
    }

    buttonAddDataSourceLogo(groupNumber) {
        let insertDataSourceLogo = "";
        if (this.props.parentApi.data.profil === "admin") {
            insertDataSourceLogo = (
                <button
                    key={"ajouter_logo_" + groupNumber}
                    className="btn btn-success"
                    onClick={() =>
                        this.updateIndicatorsNumbersInGroups(groupNumber, "logo")
                    }
                >
                    {" "}
                    Nouveau logo{" "}
                </button>
            );
        }
        return insertDataSourceLogo;
    }

    buttonAddDidactifFileLink(groupNumber) {
        let insertDidactifFileLink = "";
        if (this.props.parentApi.data.profil === "admin") {
            insertDidactifFileLink = (
                <button
                    key={"ajouter_file_" + groupNumber}
                    className="btn btn-success"
                    onClick={() =>
                        this.updateIndicatorsNumbersInGroups(
                            groupNumber,
                            "didactic-file-launcher"
                        )
                    }
                >
                    {" "}
                    Nouvelle fiche didactique{" "}
                </button>
            );
        }
        return insertDidactifFileLink;
    }

    buttonAddPCAETCurve(groupNumber) {
        if (!this.props.parentApi.data.settings.ui_show_plan_actions) {
            return "";
        }
        return (
            <button
                key={"add_pcaet_curve_" + groupNumber}
                className="btn btn-success"
                onClick={() =>
                    this.updateIndicatorsNumbersInGroups(
                        groupNumber,
                        "pcaet-trajectory"
                    )
                }
            >
                {" "}
                Nouveau suivi de trajectoire{" "}
            </button>
        );
    }

    buttonAddExternalLink(groupNumber) {
        let addExternalLink = "";
        if (this.props.parentApi.data.profil === "admin") {
            addExternalLink = (
                <button
                    key={"ajouter_lien_" + groupNumber}
                    className="btn btn-success"
                    onClick={() =>
                        this.updateIndicatorsNumbersInGroups(
                            groupNumber,
                            "link-launcher"
                        )
                    }
                >
                    Nouveau lien vers un module
                </button>
            );
        }
        return addExternalLink;
    }

    buttonAddTextBlock(groupNumber) {
        return (
            <button
                key={"ajouter_text-block_" + groupNumber}
                className="btn btn-success"
                onClick={() =>
                    this.updateIndicatorsNumbersInGroups(groupNumber, "text-block")
                }
            >
                Nouveau bloc de texte
            </button>
        );
    }

    renderIndicatorSelection(block, groupNumber, id) {
        let representationsPossibles = [];
        let representationParDefaut = "Sélectionnez une représentation";
        if (block.id_analysis) {
            representationsPossibles =
                this.props.parentApi.controller.analysisManager.obtenirRepresentationsPossibles(
                    parseInt(block.id_analysis, 10)
                );
        }
        for (let representation of representationsPossibles) {
            if (
                representation.arg ===
                this.props.parentApi.data.tableauBordDonnees.donnees[groupNumber]
                    .indicateurs[id].representation
            ) {
                representationParDefaut = representation.nom;
                break;
            }
        }
        return (
            // On affecte un composant de Sélection d'objets à chaque analyse
            // Différenciée à l'aide d'un identifiant incrémental
            <VisualizationSelect
                listeObjetsPremiereSelection={this.state.analyses}
                parentApi={this.props.parentApi}
                liste={representationsPossibles}
                valeurDefaut={representationParDefaut}
                key={"selectionneur" + id}
                id={id}
                numeroThematique={groupNumber}
                premiereSelection={block.id_analysis}
            />
        );
    }

    renderTextBlockForm(groupNumber, id) {
        return (
            <div
                className="text-block alert filter filter-territoire filter-territoire-short relative"
                id={id}
                key={"text-block-" + groupNumber}
            >
                <MDEditor
                    value={this.state.currentContents[id]}
                    commandsFilter={(cmd) =>
                        cmd && /(image)/.test(cmd.name) ? false : cmd
                    }
                    onChange={(newValue) => {
                        this.setState({
                            currentContents: {
                                ...this.state.currentContents,
                                [id]: newValue,
                            },
                        });
                    }}
                    previewOptions={{
                        disallowedElements: ["img"],
                        rehypePlugins: [[rehypeSanitize]],
                    }}
                />
            </div>
        );
    }

    renderPCAETTrajectoriesSelection(groupNumber, id) {
        //add dropdown list with data source logo names
        let listTrajectories = this.state.listPCAETTrajectories;

        const options = [
            {
                values: listTrajectories.map((v) => {
                    return { value: v.id, label: v.name };
                }),
                label: "Trajectoire",
                default: "Sélectionnez une trajectoire",
            },
            {
                values: [
                    { value: "line", label: "Courbes empilées" },
                    { value: "table", label: "Tableau PCAET" },
                ],
                label: "Mode de représentation",
                default: "Sélectionnez un mode de représentation",
            },
        ];

        let defaultSelections = undefined;

        // let value = undefined;
        if (this.props.parentApi.data.tableauBordDonnees.donnees[groupNumber]) {
            if (
                this.props.parentApi.data.tableauBordDonnees.donnees[groupNumber]
                    .indicateurs[id]
            ) {
                const defaultValues =
                    this.props.parentApi.data.tableauBordDonnees.donnees[groupNumber]
                        .indicateurs[id];
                defaultSelections = [
                    defaultValues.id_analysis,
                    defaultValues.representation.replace("pcaet-trajectory-", ""),
                ];
            }
        }

        return (
            <div
                className="alert filter filter-territoire filter-territoire-short relative"
                id={id}
            >
                <MultiLevelSelect
                    availableSelections={options}
                    defaultSelections={defaultSelections}
                    callback={(choices) => {
                        let trajParams = listTrajectories.find(
                            (t) => t.id === choices[0]
                        );
                        const filters = trajParams?.filters ?? {};
                        const trajName = trajParams?.name ?? "unknown";

                        let categories = trajParams?.categories ?? {};

                        this.props.parentApi.callbacks.updateDashboard(
                            groupNumber,
                            this.props.parentApi.data.tableauBordDonnees.donnees[
                                groupNumber
                            ].titre_thematique,
                            this.props.parentApi.data.tableauBordDonnees.donnees[
                                groupNumber
                            ].description_thematique,
                            id,
                            {
                                id_analysis: choices[0],
                                representation: "pcaet-trajectory-" + choices[1],
                                categories: categories,
                                numero_analyse: id,
                                filters: filters,
                                trajectoryName: trajName,
                            }
                        );
                        this.setState({ reloadinChartsRequired: true });
                    }}
                />
            </div>
        );
    }

    renderLogoSelection(groupNumber, id) {
        //add dropdown list with data source logo names
        let nameLogoOptions = [];
        if (this.state.listLogo.length > 0) {
            nameLogoOptions = this.state.listLogo.map((logo) => (
                <option value={logo.id} key={logo.id}>
                    Logo {logo.name}
                </option>
            ));
        }

        let value = undefined;
        if (this.props.parentApi.data.tableauBordDonnees.donnees[groupNumber]) {
            if (
                this.props.parentApi.data.tableauBordDonnees.donnees[groupNumber]
                    .indicateurs[id]
            ) {
                value =
                    this.props.parentApi.data.tableauBordDonnees.donnees[groupNumber]
                        .indicateurs[id].id_analysis;
            }
        }
        return (
            <div
                className="alert filter filter-territoire filter-territoire-short relative"
                id={id}
            >
                <label className="label-selecteur-objet">
                    <strong>Logos</strong>
                </label>
                <select
                    id={"logo_select_" + id}
                    key={"selectionneur_" + groupNumber}
                    className={"short"}
                    onChange={(event) => {
                        this.props.parentApi.callbacks.updateDashboard(
                            groupNumber,
                            this.props.parentApi.data.tableauBordDonnees.donnees[
                                groupNumber
                            ].titre_thematique,
                            this.props.parentApi.data.tableauBordDonnees.donnees[
                                groupNumber
                            ].description_thematique,
                            id,
                            {
                                id_analysis: event.target.value,
                                representation: "logo",
                                categories: {},
                                numero_analyse: id,
                            }
                        );
                        this.setState({ reloadinChartsRequired: true });
                    }}
                    value={value}
                >
                    <option value="selectionnez">Sélectionnez un logo</option>
                    {nameLogoOptions}
                </select>
            </div>
        );
    }

    renderDidacticFileSelection(groupNumber, id) {
        //add dropdown list with data source logo names
        let nameFileOptions = [];
        if (this.state.listDidacticFile.length > 0) {
            nameFileOptions = this.state.listDidacticFile.map((file) => (
                <option value={file.id} key={file.id}>
                    {file.title}
                </option>
            ));
        }
        let value = undefined;
        if (this.props.parentApi.data.tableauBordDonnees.donnees[groupNumber]) {
            if (
                this.props.parentApi.data.tableauBordDonnees.donnees[groupNumber]
                    .indicateurs[id]
            ) {
                value =
                    this.props.parentApi.data.tableauBordDonnees.donnees[groupNumber]
                        .indicateurs[id].id_analysis;
            }
        }
        return (
            <div
                className="alert filter filter-territoire filter-territoire-short relative"
                id={id}
            >
                <label className="label-selecteur-objet">
                    <strong>Fiche</strong>
                </label>
                <select
                    id={id}
                    key={"selectionneur_" + groupNumber}
                    className={"short"}
                    onChange={(event) => {
                        this.props.parentApi.callbacks.updateDashboard(
                            groupNumber,
                            this.props.parentApi.data.tableauBordDonnees.donnees[
                                groupNumber
                            ].titre_thematique,
                            this.props.parentApi.data.tableauBordDonnees.donnees[
                                groupNumber
                            ].description_thematique,
                            id,
                            {
                                id_analysis: event.target.value,
                                representation: "didactic-file-launcher",
                                categories: {},
                                numero_analyse: id,
                            }
                        );
                        this.setState({ reloadinChartsRequired: true });
                    }}
                    value={value}
                >
                    <option value="selectionnez">Sélectionnez une fiche</option>
                    {nameFileOptions}
                </select>
            </div>
        );
    }

    renderLinksSelection(groupNumber, id) {
        //add dropdown list with data source logo names
        let listLink = [{ id: "simulateur", nom: "Simulateur Mobilité" }];
        let nameFileOptions = listLink.map((file) => (
            <option value={file.id} key={file.id}>
                {file.nom}
            </option>
        ));

        let value = undefined;
        if (this.props.parentApi.data.tableauBordDonnees.donnees[groupNumber]) {
            if (
                this.props.parentApi.data.tableauBordDonnees.donnees[groupNumber]
                    .indicateurs[id]
            ) {
                value =
                    this.props.parentApi.data.tableauBordDonnees.donnees[groupNumber]
                        .indicateurs[id].id_analysis;
            }
        }
        return (
            <div
                className="alert filter filter-territoire filter-territoire-short relative"
                id={id}
            >
                <label className="label-selecteur-objet">
                    <strong>Lien vers</strong>
                </label>
                <select
                    id={id}
                    key={"selectionneur_" + groupNumber}
                    className={"short"}
                    onChange={(event) => {
                        this.props.parentApi.callbacks.updateDashboard(
                            groupNumber,
                            this.props.parentApi.data.tableauBordDonnees.donnees[
                                groupNumber
                            ].titre_thematique,
                            this.props.parentApi.data.tableauBordDonnees.donnees[
                                groupNumber
                            ].description_thematique,
                            id,
                            {
                                id_analysis: event.target.value,
                                nom: listLink
                                    .filter((link) => link.id === event.target.value)
                                    .map((link) => link.nom)
                                    .join("-"),
                                representation: "link-launcher",
                                categories: {},
                                numero_analyse: id,
                            }
                        );
                        this.setState({ reloadinChartsRequired: true });
                    }}
                    value={value}
                >
                    <option value="selectionnez">Sélectionnez un lien</option>
                    {nameFileOptions}
                </select>
            </div>
        );
    }

    /**
    Déclenchée sur appel de la fonction ajouterThematique elle-même déclenchée sur événement
    Ajoute une thématique aux données du tableau de bord
    @param  {chaine de caractère} titre : nom de la thématique ou de l'enjeu
    */
    updateNumbersGroups(nombreThematiques, titreThematique, description) {
        this.setState({
            nbreThematiques: nombreThematiques,
            thematique: titreThematique,
        });
        this.props.parentApi.callbacks.updateDashboard(
            nombreThematiques,
            titreThematique,
            description,
            this.state.nbreAnalyses,
            false
        );
        this.props.parentApi.controller.dashboardManager.setEditingModeDashboard(true);
    }

    /**
    Ajoute un bouton qui permet de créer une thématique aux données du tableau de bord
    @param  {entier} groupNumber : identifiant incrémental de la thématique ou de l'enjeu dans alquelle est située l'analyse
    @param  {entier} cle_analyse : numéro de lo'analyse (identifiant incrémental)
    */
    insertIndicatorDeleteButton(groupNumber, cle_analyse) {
        return (
            <button
                className="suppr-analyse"
                onClick={() => this.removeIndicator(groupNumber, cle_analyse)}
            >
                x
            </button>
        );
    }

    /**
    Ajoute un bouton qui permet d'accéder au formulaire de création d'une nouvelle thématique'
    */
    buttonNewGroup() {
        let nombreThematiques = this.state.nbreThematiques + 1;
        return (
            <button
                key="ajout_nouvelle_thematique"
                className="btn btn-success"
                onClick={() =>
                    this.updateNumbersGroups(
                        nombreThematiques,
                        "Titre de la thématique",
                        "Description de la thématique"
                    )
                }
            >
                Nouvelle thématique
            </button>
        );
    }

    /**
    Déclenchée sur événement
    Supprime une thématique aux données du tableau de bord
    @param  {entier} groupNumber : Identifiant incrémental de la thématique ou de l'enjeu dans alquelle est située l'analyse
    @param  {entier} cle_analyse : numéro de lo'analyse (identifiant incrémental)
    */
    removeIndicator(groupNumber, cle_analyse) {
        this.props.parentApi.callbacks.removeIndicatorFromDashboard(
            groupNumber,
            cle_analyse
        ); // Suppression de l'analyse dans les données du tableau de bord
        // Variable this.props.parentApi.data.tableauBordDonnees
        this.props.parentApi.controller.dashboardManager.setEditingModeDashboard(true);
    }

    /**
    Ajoute un bouton qui permet lorsqu'on clique dessus, de supprimer une thématique.
    @param  {entier} groupNumber : identifiant incrémental de la thématique ou de l'enjeu dans alquelle est située l'analyse
    */
    buttonDeleteGroup(groupNumber) {
        return (
            <button
                className="suppr-thematique"
                onClick={() => this.deleteGroup(groupNumber)}
            >
                x
            </button>
        );
    }

    /**
    Déclenchée sur événement
    Supprimer une thématique.
    @param  {entier} groupNumber : Identifiant incrémental de la thématique ou de l'enjeu dans alquelle est située l'analyse
    */
    deleteGroup(groupNumber) {
        this.props.parentApi.callbacks.deleteGroupInDashboard(groupNumber); // Suppression de la thématique de l'objet this.props.parentApi.data.tableauBordDonnees
        this.props.parentApi.controller.dashboardManager.setEditingModeDashboard(true);
    }

    /**
    Déclenchée sur événement
    Supprime une catégorie aux données du tableau de bord
    @param  {entier} groupNumber: Identifiant incrémental de la thématique ou de l'enjeu dans alquelle est située l'analyse
    @param  {entier} cle_analyse : numéro de lo'analyse (identifiant incrémental)
    @param  {chaine de caractère} categorie : nom de la catégorie à supprimer ou restaurer à l'aide d'une case qu'on coche ou décoche.
    */
    toggleCategoriesSelectionForIndicator(groupNumber, cle_analyse, categorie) {
        let categories =
            this.props.parentApi.data.tableauBordDonnees.donnees[groupNumber]
                .indicateurs[cle_analyse].categories;
        for (let cat in categories) {
            if (cat === categorie) {
                this.props.parentApi.callbacks.toggleCategoriesSelectionForIndicatorInDashboard(
                    groupNumber,
                    cle_analyse,
                    cat,
                    true
                );
            } else {
                this.props.parentApi.callbacks.toggleCategoriesSelectionForIndicatorInDashboard(
                    groupNumber,
                    cle_analyse,
                    cat,
                    false
                );
            }
        }

        this.setState({
            reloadinChartsRequired: true,
        });
        this.props.parentApi.controller.dashboardManager.setEditingModeDashboard(true);
    }

    /**
    Ajoute une case à cocher / décocher pour chaque catégorie disponible, qu'on peut choisir - ou non - d'afficher dans le tableau de bord
    @param  {chaine de caractère} thematique : nom de la thématique ou de l'enjeu dans alquelle est située l'analyse
    @param  {entier} cle_analyse : numéro de l'analyse (identifiant incrémental)
    @param  {chaine de caractère} categorie : nom de la catégorie à supprimer ou restaurer à l'aide d'une case qu'on coche ou décoche.
    */
    buttonCategoriesSelection(thematique, cle_analyse, categorie) {
        let objetCategorie =
            this.props.parentApi.data.tableauBordDonnees.donnees[thematique]
                .indicateurs[cle_analyse].categories[categorie];
        // Le titre est le texte qu'on souhaite afficher au dessus des graphiques et au début de chaque case (Par secteurs, Par filières de production etc.)
        // Le nom de la categorie est en revanche la clé normalisée qui nous permet de l'identifier (secteur, usage, energie, type_prod_enr etc.)
        let titre = objetCategorie.titre;
        let visible = objetCategorie.visible;
        let caseACocher = (
            <div
                key={"case_" + cle_analyse + "_" + categorie}
                className="selection-graph"
            >
                <input
                    type="radio"
                    id={"case_" + cle_analyse + "_" + categorie}
                    checked={visible}
                    name={"case-categorie_" + cle_analyse}
                    onChange={() =>
                        this.toggleCategoriesSelectionForIndicator(
                            thematique,
                            cle_analyse,
                            categorie
                        )
                    }
                ></input>
                <label
                    className="titre-categorie"
                    htmlFor={"case_" + cle_analyse + "_" + categorie}
                >
                    {titre}
                </label>
            </div>
        );
        return caseACocher;
    }

    /**
    Cette fonction structure les données selon les exigences de l'API pour l'enregistrement des tableaux
    de bord.
    Pour rappel, l'enregistrement des tableaux de bord est réalisé dans deux tables : l'une contient les métadonnées
    (titre description, identifiant unique, auteur.rice), et une autre qui contient les données relatives aux lignes
    (identifiant, identifiant du tableau de bord auquel elles appartiennent, liste des indicateurs et de leurs
    représentations)
    */
    async formatDataForSavingDashboard() {
        let { donnees } = this.props.parentApi.data.tableauBordDonnees;

        let thematiques = [];

        const allAnalyses = Object.values(donnees)
            .map((theme) => Object.values(theme.indicateurs))
            .flat();
        const areSomeFiltersModified = allAnalyses.some(
            (analysis) => analysis.filters && Object.keys(analysis.filters).length
        );
        let shouldSaveFilters = false;
        if (areSomeFiltersModified) {
            await new Promise((resolve) =>
                this.setState({
                    confirmJSX: (
                        <Confirm
                            title="Voulez-vous enregister les filtres ?"
                            buttons={["Oui", "Non"]}
                            hideCancel
                            onConfirm={(response) => {
                                shouldSaveFilters = response === "Oui";
                                resolve();
                            }}
                        />
                    ),
                })
            );

            this.setState({ confirmJSX: undefined });
        }

        for (let groupNumber in donnees) {
            let graphiques = [];
            for (let analyse in donnees[groupNumber].indicateurs) {
                let graph = donnees[groupNumber].indicateurs[analyse];
                if (graph.representation === "marqueur-svg") {
                    graphiques.push({
                        numero_analyse: analyse,
                        options: this.state.listOptionsByAnalysis[analyse],
                        id_analysis: graph.id_analysis,
                        representation: graph.representation,
                        tailleSVG: graph.tailleSVG,
                        tailleData: graph.tailleData,
                        imageSVG: graph.imageSVG,
                        categories: {},
                    });
                } else if (graph.representation?.startsWith("pcaet-trajectory")) {
                    graphiques.push({
                        id_analysis: graph.id_analysis,
                        numero_analyse: analyse,
                        representation: graph.representation,
                        options: graph.options,
                        categories: graph.categories,
                        filters: shouldSaveFilters ? graph.filters : undefined,
                        trajectoryName: graph.trajectoryName,
                    });
                } else if (graph.representation?.startsWith("text-block")) {
                    graphiques.push({
                        id_analysis: "text-block#" + analyse,
                        numero_analyse: analyse,
                        representation: graph.representation,
                        content: this.state.currentContents?.[analyse] ?? "",
                    });
                } else if (graph.representation === "analysis-launcher") {
                    let couleur = "";
                    for (let item in this.props.parentApi.data.analysisNameColor) {
                        if (
                            this.props.parentApi.data.analysisNameColor[item].id ===
                            parseInt(graph.numero_analyse, 10)
                        ) {
                            couleur =
                                this.props.parentApi.data.analysisNameColor[item].color;
                            break;
                        }
                    }

                    graphiques.push({
                        numero_analyse: analyse,
                        options: this.state.listOptionsByAnalysis[analyse],
                        id_analysis: graph.id_analysis,
                        representation: graph.representation,
                        categories: {},
                        couleur: couleur,
                    });
                } else if (graph.representation === "didactic-file-launcher") {
                    let couleur = "";
                    for (let item in this.props.parentApi.data.didacticFileNameColor) {
                        if (
                            this.props.parentApi.data.didacticFileNameColor[item].id ===
                            parseInt(graph.numero_analyse, 10)
                        ) {
                            couleur =
                                this.props.parentApi.data.didacticFileNameColor[item]
                                    .color;
                            break;
                        }
                    }
                    graphiques.push({
                        numero_analyse: analyse,
                        options: this.state.listOptionsByAnalysis[analyse],
                        id_analysis: graph.id_analysis,
                        representation: graph.representation,
                        categories: {},
                        couleur: couleur,
                    });
                } else if (graph.representation === "link-launcher") {
                    let couleur = "";
                    for (let item in this.props.parentApi.data.linkNameColor) {
                        if (
                            this.props.parentApi.data.linkNameColor[item].id ===
                            parseInt(graph.numero_analyse, 10)
                        ) {
                            couleur =
                                this.props.parentApi.data.linkNameColor[item].color;
                            break;
                        }
                    }
                    graphiques.push({
                        numero_analyse: analyse,
                        options: this.state.listOptionsByAnalysis[analyse],
                        id_analysis: graph.id_analysis,
                        representation: graph.representation,
                        categories: {},
                        couleur: couleur,
                    });
                } else {
                    graphiques.push({
                        numero_analyse: analyse,
                        options: this.state.listOptionsByAnalysis[analyse],
                        id_analysis: graph.id_analysis,
                        representation: graph.representation,
                        categories: graph.categories,
                        filters: shouldSaveFilters ? graph.filters : undefined,
                    });
                }
            }
            let descriptionThematique = donnees[groupNumber].description_thematique;
            let titreThematique = donnees[groupNumber].titre_thematique;
            let ordre = donnees[groupNumber].ordre;
            thematiques.push({
                titre_thematique: titreThematique,
                graphiques: graphiques,
                description_thematique: descriptionThematique,
                ordre: ordre,
            });
        }

        return {
            thematiques: thematiques,
            titre: this.props.parentApi.data.tableauBordDonnees.metadonnees.titre,
            description:
                this.props.parentApi.data.tableauBordDonnees.metadonnees.description,
        };
    }

    /**
    Déclenchée sur événement (changement de l'état du formulaire où on saisit le titre de la ligne)
    Modifie le titre d'une ligne à chaque changement d'état du formulaire
    modification de la clé (this.props.parentApi.data.tableauBordDonnees.donnees[thematique])
    @param {chaine de caractères} ref_titre : référence du formulaire qui contient le titre de la ligne
    @param {entier} groupNumber : Identifiant unique de la ligne dont on souhaite modifier le nom
    */
    editGroupTitle(event, groupNumber) {
        let nouveauNomThematique = event.target.value;
        this.props.parentApi.callbacks.miseAJourNomThematique(
            groupNumber,
            nouveauNomThematique
        ); // Cf. index.js
        this.props.parentApi.controller.dashboardManager.setEditingModeDashboard(true);
    }

    editGroupDescription(event, thematique) {
        let nouvelleDescription = event.target.value;
        this.props.parentApi.callbacks.miseAJourDescriptionThematique(
            thematique,
            nouvelleDescription
        );
        this.props.parentApi.controller.dashboardManager.setEditingModeDashboard(true);
    }

    changeDashboardMetadata(newValue, typeObjet) {
        let titre = this.props.parentApi.data.tableauBordDonnees.metadonnees.titre;
        let description =
            this.props.parentApi.data.tableauBordDonnees.metadonnees.description;
        if (typeObjet === "titre") {
            titre = newValue;
            this.setState({ title: newValue });
        } else if (typeObjet === "description") {
            description = newValue;
            this.setState({ description: newValue });
        }
        this.props.parentApi.callbacks.ajouterMetaDonneesTableauBord(
            titre,
            description
        );
        this.props.parentApi.controller.dashboardManager.setEditingModeDashboard(true);
    }

    /**
    Déclenchée sur événement
    Ajoute les métadonnées {titre: titre du tableau de bord, description: description du tableau de bord}
    aux donneés du tableau de bord.
    @param  {booléen} mise_a_jour : Vrai si cet enregistrement fait suite à une suppression (c'est donc une mise à jour)
    */
    async saveDashboard() {
        let titre = this.props.parentApi.data.tableauBordDonnees.metadonnees.titre; // Titre saisi par l'utilisateur.trice
        if (titre === "" || titre === undefined) {
            alert("Vous devez renseigner le titre de votre thématique");
            return;
        }

        // Formatage des données pour les faire correspondre au modèle de données attendues par l'API
        if (this.props.parentApi.data.tableauBordDonnees.metadonnees) {
            let body = JSON.stringify(await this.formatDataForSavingDashboard());
            let region = this.props.parentApi.controller.analysisManager.region;
            let url = buildRegionUrl(config.tableau_bord_url, region);
            Api.callApi(url, body, "POST")
                .then((response) => {
                    this.setState({
                        status: "Tableau de bord enregistré",
                        dashboardId: response.tableau_id,
                        erreur: undefined,
                    });
                })
                .catch((e) =>
                    this.setState({
                        erreur: e.message, // Message retourné par l'API python si le tableau de bord existe déjà
                        status: undefined,
                    })
                );
        }
    }

    /**
    Déclenchée sur événement
    Met à jour un tableau de bord
    @param  {entier} dashboardId : identifiant en base du tableau de bord que l'on veut mettre à jour
    @param {chaine de caractères} refTitre : Référence de l'entrée où on saisit le titre du tableau de bord
    @param {chaine de caractères} refDescription : Référence de l'entrée où on saisit la description du tableau de bord
    */
    async updateDashboard(dashboardId) {
        if (dashboardId === undefined) {
            this.setState({
                erreur: "Il vous faut d'abord enregistrer le tableau avant de le modifier.",
                status: undefined,
            });
        } else {
            let donneesTableauxBord = await this.formatDataForSavingDashboard();
            let body = JSON.stringify(donneesTableauxBord);
            let region = this.props.parentApi.controller.analysisManager.region;
            let url =
                buildRegionUrl(config.tableau_bord_url, region) + "/" + dashboardId;
            Api.callApi(url, body, "POST")
                .then((response) => {
                    this.setState({
                        status: "Modifications enregistrées",
                        dashboardId: response.tableau_id,
                        erreur: undefined,
                    });
                })
                .catch((e) =>
                    this.setState({
                        erreur: e.message, // Message retourné par l'API python si le tableau de bord existe déjà
                        status: undefined,
                    })
                );
        }
    }

    /**
    Ajoute le formulaire qui permet de saisir les métadonnées i.e le titre et
    la description du tableau de bord.
    */
    formMetadata() {
        const titre = (
            <input
                key={"titreTableauBord"}
                className="form-control col-sm-4"
                type="text"
                id="dashboard-title"
                name="dashboard-title"
                placeholder="Titre du tableau de bord"
                defaultValue={this.state.title}
                onChange={(event) =>
                    this.changeDashboardMetadata(event.target.value, "titre")
                }
            ></input>
        );
        const description = (
            <MDEditor
                key={"descriptionTableauBord"}
                commandsFilter={(cmd) =>
                    cmd && /(image)/.test(cmd.name) ? false : cmd
                }
                className="form-control col-sm-4"
                id="dashboard-description"
                name="dashboard-description"
                placeholder="Description du tableau de bord"
                value={this.state.description}
                onChange={(newValue) =>
                    this.changeDashboardMetadata(newValue, "description")
                }
                previewOptions={{
                    disallowedElements: ["img"],
                    rehypePlugins: [[rehypeSanitize]],
                }}
            />
        );
        const buttonText = !this.state.dashboardId
            ? "Enregistrer un nouveau tableau de bord"
            : "Dupliquer le tableau de bord (en changeant le titre)";
        const saveDashboard = (
            <button
                key={"saveDashboard"}
                className="form-control form-inline col-sm-4 taille-formulaire-admin btn btn-success"
                onClick={() => this.saveDashboard()}
            >
                {buttonText}
            </button>
        );
        return (
            <>
                <div key={"formulaireMetadonnees"} className="formulaire-metadonnees">
                    <div className="form-horizontal">
                        <label htmlFor="dashboard-title">
                            <b>{"Titre"}</b>
                        </label>
                        {titre}
                    </div>
                    <div className="form-horizontal">
                        <p>
                            <label htmlFor="dashboard-description">
                                <b>{this.showMarkdownTooltip("Description")}</b>
                            </label>
                        </p>
                        {description}
                    </div>
                    <div className="form-horizontal">{saveDashboard}</div>
                </div>
                <p className="markdown-tooltip">
                    * Les champs marqués par un astérisque supportent le formatage{" "}
                    <em>Markdown</em>. Plus d'informations{" "}
                    <a
                        href="https://fr.wikipedia.org/wiki/Markdown#Exemples_de_syntaxe"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        sur cette page Wikipédia
                    </a>
                    .
                </p>
            </>
        );
    }

    /**
    Ajoute un bouton associé à l'événement de mise à jour d'un tableau de bord
    @param {entier} dashboardId : identifiant du tableau de bord
    */
    buttonUpdateDashboard(dashboardId) {
        if (dashboardId === undefined) {
            return "";
        }
        return (
            <div key={"formulaireMiseAJour"} className="form-horizontal">
                <button
                    key={"saveDashboard"}
                    className="form-control form-inline col-sm-4 taille-formulaire-admin btn btn-info"
                    onClick={() => this.updateDashboard(dashboardId)}
                >
                    Enregistrer les modifications
                </button>
            </div>
        );
    }

    /**
    Ajoute un bouton associé à l'événement de publication  d'un tableau de bord
    @param {chaine de caractères} typeTerritoire : le type de territoire sur lequel on publie
    @param {chaine de caractères} codeInseeTerritoire : code Insee du territoire sélectionné
    @param {entier} dashboardId : identifiant du tableau de bord
    */
    buttonPublishDashboard(typeTerritoire, codeInseeTerritoire, dashboardId) {
        let ajouterCaseAffectationToutTypeTerritoireOuTerritoireSpecifique = (
            <div key="publication">
                <input type="checkbox" id="publication-territoire"></input>
                <label className="titre-categorie" htmlFor="publication-territoire">
                    Publier pour tous les types de territoire de celui sélectionné
                </label>
            </div>
        );
        let boutonPublication = (
            <button
                className="btn btn-info"
                onClick={() =>
                    this.publishDashboard(
                        typeTerritoire,
                        codeInseeTerritoire,
                        dashboardId
                    )
                }
            >
                Publier
            </button>
        );
        return (
            <div key={"publishDashboard"}>
                {ajouterCaseAffectationToutTypeTerritoireOuTerritoireSpecifique}
                {boutonPublication}
            </div>
        );
    }

    /**
    Affecte un type de territoire et / ou territoire à un tableau de bord en base de données
    @param {chaine de caractères} typeTerritoire : le type de territoire sur lequel on publie
    @param {chaine de caractères} codeInseeTerritoire : code Insee du territoire sélectionné
    @param {entier} dashboardId : identifiant du tableau de bord
    */
    publishDashboard(typeTerritoire, codeInseeTerritoire, dashboardId) {
        // À faire : configurer l'URL et l'API en fonction de si on publie pour tous les types du territoire
        // de celui sélectionné ou si on ne publie que sur le territoire sélectionné.
        let affectationToutTypeTerritoire = document.getElementById(
            "publication-territoire"
        ).checked;
        let region = this.props.parentApi.controller.analysisManager.region;
        let url = buildRegionUrl(config.tableau_bord_affectation_url, region);
        url = url.replace("#dashboard_id#", dashboardId) + "?zone=" + typeTerritoire;
        let affectation = { zone: typeTerritoire, zone_id: null };
        let listeAffectations = this.state.listeAffectations;
        if (!affectationToutTypeTerritoire) {
            affectation = {
                zone: typeTerritoire,
                zone_id: this.props.parentApi.data.currentZone,
            };
            if (typeTerritoire === "region") {
                affectation = {
                    zone: typeTerritoire,
                    zone_id: this.props.parentApi.data.regionCode,
                };
            }
        }
        if (dashboardId && typeTerritoire) {
            Api.callApi(url, JSON.stringify(affectation), "PUT")
                .then((response) => {
                    this.setState({
                        status: response.message,
                        dashboardId: this.state.dashboardId,
                        erreur: undefined,
                    });
                    let affectationExistante = false;
                    for (let affectationTerritoire of listeAffectations) {
                        if (
                            affectationTerritoire.zone === affectation.zone &&
                            affectationTerritoire.zone_id === affectation.zone_id
                        ) {
                            affectationExistante = true;
                        }
                    }

                    if (!affectationExistante) {
                        listeAffectations.push(affectation);
                    }
                    this.setState({
                        listeAffectations: listeAffectations,
                    });
                })
                .catch((e) => {
                    this.setState({
                        erreur: e.message,
                        // Message retourné par l'API python si le tableau de bord existe déjà
                        status: undefined,
                    });
                });
        } else if (dashboardId && !typeTerritoire) {
            alert("Veuillez d'abord sélectionner un territoire");
        } else if (!dashboardId && typeTerritoire) {
            alert(
                "Votre tableau de bord n'est pas enregistré. Veuillez l'enregistrer puis réessayez."
            );
        } else {
            alert(
                "Veuillez d'abord enregistrer votre tableau de bord et sélectionner un territoire"
            );
        }
    }

    unpublishDashboard(dashboardId, affectation) {
        let argCodeInseeTerritoire = "&zone_id=";
        if (affectation.zone_id) {
            argCodeInseeTerritoire = "&zone_id=" + affectation.zone_id;
        }
        let region = this.props.parentApi.controller.analysisManager.region;
        let url = buildRegionUrl(config.tableau_bord_depublication_url, region);
        url =
            url.replace("#dashboard_id#", dashboardId) +
            "?zone=" +
            affectation.zone +
            argCodeInseeTerritoire;
        Api.callApi(url, null, "DELETE")
            .then((response) => {
                this.setState({
                    listeAffectations: response.liste_affectations,
                    status: response.message,
                });
            })
            .catch((e) =>
                this.setState({
                    erreur: "Impossible de dépublier le tableau de bord.",
                    status: undefined,
                })
            );
    }

    publicationForm() {
        let formulaireDePublication = [];
        let zone = {};
        let compteur = 1;
        let couleurDeFond = { background: "rgba(220, 220, 220)" };
        if (this.state.listeAffectations) {
            for (let affectation of this.state.listeAffectations) {
                for (let typeTerritoireMaille in this.props.parentApi.controller
                    .zonesManager.zoneLists) {
                    if (typeTerritoireMaille.split("-")[0] === affectation.zone) {
                        zone = {
                            zone: typeTerritoireMaille.split("-")[0],
                            maille: typeTerritoireMaille.split("-")[1],
                        };
                        break;
                    }
                }
                if (compteur % 2 !== 0) {
                    couleurDeFond = { background: "rgba(220, 220, 220)" };
                } else {
                    couleurDeFond = { background: "rgba(240, 240, 240)" };
                }
                let nomAffectation = "";
                if (affectation.zone) {
                    nomAffectation =
                        this.props.parentApi.controller.zonesManager.obtenirLibelleZone(
                            affectation.zone
                        );
                }

                if (affectation.zone && affectation.zone_id) {
                    nomAffectation =
                        this.props.parentApi.controller.zonesManager.getZoneName(
                            affectation.zone_id,
                            zone
                        ) +
                        " (" +
                        nomAffectation +
                        ")";
                }

                let boutonDepublication = (
                    <button
                        className="btn btn-danger"
                        onClick={() =>
                            this.unpublishDashboard(this.state.dashboardId, affectation)
                        }
                    >
                        Dépublier
                    </button>
                );
                let formulaire = (
                    <tr key={nomAffectation}>
                        <th style={couleurDeFond}>{nomAffectation}</th>
                        <th>{boutonDepublication}</th>
                    </tr>
                );
                formulaireDePublication.push(formulaire);
                compteur += 1;
            }
        }

        return (
            <div className="depublier-tdb">
                <h4>Gestion des publications des tableaux de bord</h4>
                <table>
                    <tbody>{formulaireDePublication}</tbody>
                </table>
            </div>
        );
    }

    handleChangeSVGIcons(
        groupNumber,
        representation,
        id_analysis,
        a,
        tailleSVG,
        tailleData,
        e
    ) {
        this.setState({
            imageSVG: e.value,
        });
        this.props.parentApi.callbacks.updateDashboard(
            groupNumber,
            this.props.parentApi.data.tableauBordDonnees.donnees[groupNumber]
                .titre_thematique,
            this.props.parentApi.data.tableauBordDonnees.donnees[groupNumber]
                .description_thematique,
            a,
            {
                id_analysis: id_analysis,
                representation: representation,
                numero_analyse: a,
                imageSVG: e.value,
                tailleSVG: tailleSVG,
                tailleData: tailleData,
            }
        );
        this.props.parentApi.controller.dashboardManager.setEditingModeDashboard(true);
    }

    /**
     * Cette fonction permet de reduire la taille de l'icon SVG - représentation SVG
     */
    reduceSVGIconSize(
        tailleSVG,
        tailleData,
        imageSVG,
        groupNumber,
        representation,
        id_analysis,
        a
    ) {
        if (tailleSVG > 1) {
            // définir une taille minimale pour les marqueurs SVG = 1rem
            this.setState({
                tailleSVG: tailleSVG - 0.5,
            });
        }
        this.props.parentApi.callbacks.updateDashboard(
            groupNumber,
            this.props.parentApi.data.tableauBordDonnees.donnees[groupNumber]
                .titre_thematique,
            this.props.parentApi.data.tableauBordDonnees.donnees[groupNumber]
                .description_thematique,
            a,
            {
                id_analysis: id_analysis,
                representation: representation,
                numero_analyse: a,
                imageSVG: imageSVG,
                tailleSVG: tailleSVG - 0.5,
                tailleData: tailleData,
            }
        );
        this.props.parentApi.controller.dashboardManager.setEditingModeDashboard(true);
    }

    /**
     * Cette fonction permet de augmenter la taille de l'icon SVG - représentation SVG
     */
    increaseSVGIconSize(
        tailleSVG,
        tailleData,
        imageSVG,
        groupNumber,
        representation,
        id_analysis,
        a
    ) {
        this.setState({
            tailleSVG: tailleSVG + 0.5,
        });
        this.props.parentApi.callbacks.updateDashboard(
            groupNumber,
            this.props.parentApi.data.tableauBordDonnees.donnees[groupNumber]
                .titre_thematique,
            this.props.parentApi.data.tableauBordDonnees.donnees[groupNumber]
                .description_thematique,
            a,
            {
                id_analysis: id_analysis,
                representation: representation,
                numero_analyse: a,
                imageSVG: imageSVG,
                tailleSVG: tailleSVG + 0.5,
                tailleData: tailleData,
            }
        );
        this.props.parentApi.controller.dashboardManager.setEditingModeDashboard(true);
    }

    /**
     * Cette fonction permet de reduire la taille de la donnée - représentation SVG
     */
    reduceDataSizeForSVG(
        tailleSVG,
        tailleData,
        imageSVG,
        groupNumber,
        representation,
        id_analysis,
        a
    ) {
        if (tailleData > 1) {
            // définir une taille minimale pour les marqueurs SVG = 1rem
            this.setState({
                tailleData: tailleData - 0.5,
            });
        }
        this.props.parentApi.callbacks.updateDashboard(
            groupNumber,
            this.props.parentApi.data.tableauBordDonnees.donnees[groupNumber]
                .titre_thematique,
            this.props.parentApi.data.tableauBordDonnees.donnees[groupNumber]
                .description_thematique,
            a,
            {
                id_analysis: id_analysis,
                representation: representation,
                numero_analyse: a,
                imageSVG: imageSVG,
                tailleSVG: tailleSVG,
                tailleData: tailleData - 0.5,
            }
        );
        this.props.parentApi.controller.dashboardManager.setEditingModeDashboard(true);
    }

    /**
     * Cette fonction permet de augmenter la taille de la donnée - représentation SVG
     */
    increaseDataSizeForSVG(
        tailleSVG,
        tailleData,
        imageSVG,
        groupNumber,
        representation,
        id_analysis,
        a
    ) {
        this.setState({
            tailleData: tailleData + 0.5,
        });
        this.props.parentApi.callbacks.updateDashboard(
            groupNumber,
            this.props.parentApi.data.tableauBordDonnees.donnees[groupNumber]
                .titre_thematique,
            this.props.parentApi.data.tableauBordDonnees.donnees[groupNumber]
                .description_thematique,
            a,
            {
                id_analysis: id_analysis,
                representation: representation,
                numero_analyse: a,
                imageSVG: imageSVG,
                tailleSVG: tailleSVG,
                tailleData: tailleData + 0.5,
            }
        );
        this.props.parentApi.controller.dashboardManager.setEditingModeDashboard(true);
    }

    updateAdditionalOption(indicatorNumber, optionName, newVal) {
        let listOptionsByAnalysis = this.state.listOptionsByAnalysis;
        if (!listOptionsByAnalysis[indicatorNumber]) {
            listOptionsByAnalysis[indicatorNumber] = {};
        }
        listOptionsByAnalysis[indicatorNumber][optionName] = newVal;

        let indicateur = this.showChart();
        this.setState({
            chartIndicateur: indicateur,
            listOptionsByAnalysis: listOptionsByAnalysis,
        });
    }

    getAdditionalOptions(thematique, indicatorNumber, analysisId, representation) {
        if (
            !["line", "courbes_croisees", "courbes-historiques"].includes(
                representation
            )
        ) {
            return "";
        }
        const representationDetails =
            this.props.parentApi.controller.analysisManager.getAnalysisRepresentationDetails(
                analysisId
            );
        const graphOptions =
            this.props.parentApi.data.tableauBordDonnees.donnees[thematique]
                .indicateurs[indicatorNumber];
        if (
            !representationDetails ||
            !representationDetails.summary ||
            representationDetails.summary !== "yes"
        ) {
            return "";
        }

        return (
            <>
                <div className="form-group">
                    <label htmlFor={"enable_evol_" + indicatorNumber}>
                        Afficher l'évolution par rapport à une année de référence
                    </label>
                    <input
                        type="checkbox"
                        id={"enable_evol_" + indicatorNumber}
                        name={"enable_evol_" + indicatorNumber}
                        className="form-inline input"
                        defaultChecked={
                            graphOptions.options && graphOptions.options.enableEvolution
                        }
                        onClick={(e) =>
                            this.updateAdditionalOption(
                                indicatorNumber,
                                "enableEvolution",
                                e.target.checked
                            )
                        }
                    />
                </div>
                {((graphOptions.options && graphOptions.options.enableEvolution) ||
                    (this.state.listOptionsByAnalysis[indicatorNumber] &&
                        this.state.listOptionsByAnalysis[indicatorNumber][
                            "enableEvolution"
                        ])) && (
                    <div className="form-group">
                        <label htmlFor={"evol_ref_year" + indicatorNumber}>
                            Année de référence choisie
                        </label>
                        <input
                            type="text"
                            id={"evol_ref_year" + indicatorNumber}
                            name={"evol_ref_year" + indicatorNumber}
                            className="form-inline input"
                            defaultValue={
                                graphOptions.options
                                    ? graphOptions.options.evolutionRefYear
                                    : representationDetails.ref_year
                            }
                            onChange={(e) =>
                                this.updateAdditionalOption(
                                    indicatorNumber,
                                    "evolutionRefYear",
                                    e.target.value
                                )
                            }
                        />
                    </div>
                )}
            </>
        );
    }

    /**
    Si une analyse et la représentation associée ont été sélectionnées, cette méthode retourne un composant constitué par :
    - La liste des catégories accompagnées d'une case à cocher pour lesquelles on décide ou non d'afficher le graphique
    - Les graphiques instanciés en fonction de la representation par le composant FabriqueRepresentation.
    Nous rappelons ici le format des données de la variable this.props.parentApi.data.tableauBordDonnees
    (disponible dans la documentation de ce comoposant) très souvent sollicitée
    {
      donnees: {
          thematique1 : {
              numero_analyse {
                  id_analysis (identifiant de l'analyse),
                  representation : representation sélectionnée,
                  categories {
                      categorie1 {titre: titre, categorie: nom de la catégorie, visible: true si la catégorie doit s'afficher false sinon}
                  }
              }
          }
          thematique2:
          etc.
      }
      metadonnees: {titre: titre du tableau de bord, description: description du tableau de bord} (À préciser par l'utilsiateur.trice)
    }
    */
    showChart() {
        let listeGraphiques = []; // Tableau destiné à contenir les cases à cocher et les graphiques associée aux analyses enregistrées dans this.props.parentApi.data.tableauBordDonnees
        let nouvelleAnalyse = undefined; // Destinée à devenir une balise html qui permette de regrouper les éléments des analyses de telle sorte que la mise en forme soit ensuite facile
        for (let thematique in this.props.parentApi.data.tableauBordDonnees.donnees) {
            // Pour chaque thématique créée au préalabl (Cf. structure des données this.props.parentApi.data.tableauBordDonnees)
            for (let a in this.props.parentApi.data.tableauBordDonnees.donnees[
                thematique
            ].indicateurs) {
                // Pour chaque analyse ajoutée à la thématique
                if (!a || a === "undefined") {
                    continue;
                }
                let ref = React.createRef();
                const analysis =
                    this.props.parentApi.data.tableauBordDonnees.donnees[thematique]
                        .indicateurs[a];
                let id_analysis = analysis.id_analysis;
                if (id_analysis) {
                    this.props.parentApi.controller.dashboardManager.setEditingModeDashboard(
                        true
                    );
                }
                let uniqueZoneEnabled =
                    this.props.parentApi.controller.analysisManager.getOnlyForZones(
                        parseInt(id_analysis, 10)
                    );
                let disabledZones =
                    this.props.parentApi.controller.analysisManager.getDisabledZones(
                        parseInt(id_analysis, 10)
                    );
                let disabledMacroLevels =
                    this.props.parentApi.controller.analysisManager.getDisabledMacroLevels(
                        parseInt(id_analysis, 10)
                    );
                let typeTerritoire = this.props.parentApi.data.zone.zone;
                let mailleTerritoire = this.props.parentApi.data.zone.maille;
                let isLaunchable = true;
                let messageIfNotLaunchable = "";

                let representation = analysis.representation;
                let graphs = []; // Liste destinée à contenir tous les graphiques
                let analyse = this.state.analyses.find(
                    (analyse) => analyse.id === id_analysis
                );
                let selectionCategorie = []; // Liste destinée à contenir les cases à cocher qui permettent de sélectionner les catégories pour lesquelles on veut afficher le graphiques
                if (representation === "map") {
                    if (
                        uniqueZoneEnabled &&
                        !uniqueZoneEnabled.split(",").includes(mailleTerritoire)
                    ) {
                        isLaunchable = false;
                        messageIfNotLaunchable =
                            "Cet indicateur n'est disponible qu'à la maille " +
                            uniqueZoneEnabled;
                    } else if (
                        disabledZones &&
                        disabledZones.split(",").includes(mailleTerritoire)
                    ) {
                        isLaunchable = false;
                        messageIfNotLaunchable =
                            "Cet indicateur n'est pas activé à la maille " +
                            mailleTerritoire;
                    }
                }
                if (
                    !["logo", "didactic-file-launcher", "link-launcher"].includes(
                        representation
                    ) &&
                    disabledMacroLevels &&
                    disabledMacroLevels.split(",").includes(typeTerritoire)
                ) {
                    isLaunchable = false;
                    messageIfNotLaunchable =
                        "Cet indicateur cartographique n'est pas disponible à l'échelle " +
                        typeTerritoire;
                }

                if (id_analysis && analyse && representation && !isLaunchable) {
                    graphs.push(
                        <div
                            key={id_analysis + "_" + representation + "_" + a}
                            className="graphs graphs-non-affiches"
                        >
                            <h4 className="nom-indicateur">{analyse.nom}</h4>
                            <div className="confid-chart">{messageIfNotLaunchable}</div>
                        </div>
                    );
                    nouvelleAnalyse = (
                        <div key={a} className="graphs choisir-methode">
                            {selectionCategorie}
                            {graphs}
                        </div>
                    );
                }

                if (id_analysis && representation && isLaunchable) {
                    let categories = analysis.categories;
                    let numeroIndicateur = analysis.numero_analyse;
                    let listeDeroulanteSVG = "";
                    let reduceSVGIconSize = "";
                    let increaseSVGIconSize = "";
                    let reduceDataSizeForSVG = "";
                    let increaseDataSizeForSVG = "";
                    let imageSVG;
                    let tailleSVG = 3.5;
                    let tailleData = 2.5;
                    let couleurAnalyseName = getComputedStyle(
                        document.body
                    ).getPropertyValue("--" + this.props.parentApi.data.settings.theme);
                    let nomLink = "";
                    if (!numeroIndicateur) {
                        numeroIndicateur = String(this.state.nbreAnalyses - 1);
                    }
                    // we handle SVG case when analysis type is only an icon
                    if (representation === "marqueur-svg") {
                        imageSVG = analysis.imageSVG;
                        tailleSVG = analysis.tailleSVG || tailleSVG;
                        tailleData = analysis.tailleData || tailleData;
                        // si provenance est la page de la modification
                        if (
                            this.props.parentApi.data.fromMenu &&
                            tailleSVG &&
                            tailleData &&
                            imageSVG &&
                            this.state.chargerMarqueurSVG &&
                            this.mounted
                        ) {
                            this.setState({
                                tailleSVG: tailleSVG,
                                tailleData: tailleData,
                                imageSVG: imageSVG,
                                chargerMarqueurSVG: false, // pour attribuer l'image et la taille qu'une seule fois
                            });
                        }
                        categories = {};
                        listeDeroulanteSVG = (
                            <div>
                                {" "}
                                <Select
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({
                                            ...base,
                                            zIndex: 9999,
                                        }),
                                    }}
                                    options={options}
                                    onChange={this.handleChangeSVGIcons.bind(
                                        this,
                                        thematique,
                                        representation,
                                        id_analysis,
                                        a,
                                        tailleSVG,
                                        tailleData
                                    )}
                                />
                            </div>
                        );
                        reduceSVGIconSize = (
                            <div
                                style={{ float: "left" }}
                                title="Réduire la taille de l'icône"
                            >
                                <i
                                    className="bi bi-dash-lg"
                                    onClick={() =>
                                        this.reduceSVGIconSize(
                                            tailleSVG,
                                            tailleData,
                                            imageSVG,
                                            thematique,
                                            representation,
                                            id_analysis,
                                            a
                                        )
                                    }
                                ></i>
                            </div>
                        );
                        increaseSVGIconSize = (
                            <div
                                style={{ float: "left" }}
                                title="Augmenter la taille de l'icône"
                            >
                                <i
                                    className="bi bi-plus-lg"
                                    onClick={() =>
                                        this.increaseSVGIconSize(
                                            tailleSVG,
                                            tailleData,
                                            imageSVG,
                                            thematique,
                                            representation,
                                            id_analysis,
                                            a
                                        )
                                    }
                                ></i>
                            </div>
                        );
                        reduceDataSizeForSVG = (
                            <div
                                style={{ float: "right" }}
                                title="Réduire la taille de la valeur"
                            >
                                <i
                                    className="bi bi-dash-lg"
                                    onClick={() =>
                                        this.reduceDataSizeForSVG(
                                            tailleSVG,
                                            tailleData,
                                            imageSVG,
                                            thematique,
                                            representation,
                                            id_analysis,
                                            a
                                        )
                                    }
                                ></i>
                            </div>
                        );
                        increaseDataSizeForSVG = (
                            <div
                                style={{ float: "right" }}
                                title="Augmenter la taille de la valeur"
                            >
                                <i
                                    className="bi bi-plus-lg"
                                    onClick={() =>
                                        this.increaseDataSizeForSVG(
                                            tailleSVG,
                                            tailleData,
                                            imageSVG,
                                            thematique,
                                            representation,
                                            id_analysis,
                                            a
                                        )
                                    }
                                ></i>
                            </div>
                        );
                    }
                    // we handle case when type is a launcher (either didactic file
                    // or analysis).
                    if (
                        [
                            "analysis-launcher",
                            "didactic-file-launcher",
                            "link-launcher",
                        ].includes(representation)
                    ) {
                        if (analysis.couleur) {
                            couleurAnalyseName = analysis.couleur;
                            this.setState({
                                couleurAnalyseName: couleurAnalyseName,
                            });
                        }

                        if (analysis.nom && representation === "link-launcher") {
                            // TODO : finir la génération automotique des noms des liens
                            nomLink = analysis.nom;
                        }
                        // si provenance est la page de la modification
                        if (
                            this.props.parentApi.data.fromMenu &&
                            couleurAnalyseName &&
                            this.state.chargerAnalysisLauncher &&
                            this.mounted
                        ) {
                            this.setState({
                                couleurAnalyseName: couleurAnalyseName,
                                chargerAnalysisLauncher: false,
                            });
                        }
                        categories = {};
                        this.props.parentApi.callbacks.updateDashboard(
                            thematique,
                            this.props.parentApi.data.tableauBordDonnees.donnees[
                                thematique
                            ].titre_thematique,
                            this.props.parentApi.data.tableauBordDonnees.donnees[
                                thematique
                            ].description_thematique,
                            a,
                            {
                                id_analysis: id_analysis,
                                representation: representation,
                                numero_analyse: a,
                                couleur: couleurAnalyseName,
                                nom: nomLink,
                            }
                        );
                        this.props.parentApi.controller.dashboardManager.setEditingModeDashboard(
                            true
                        );
                    }

                    let additionalOptions = this.getAdditionalOptions(
                        thematique,
                        numeroIndicateur,
                        id_analysis,
                        representation
                    );

                    let title = analyse ? analyse.nom : "";
                    if (representation === "pcaet-trajectory-line") {
                        title = "Suivi des trajectoires - " + analysis.trajectoryName;
                    }
                    if (representation === "pcaet-trajectory-table") {
                        title =
                            "Suivi de la trajectoire PCAET - " +
                            analysis.trajectoryName;
                    }

                    if (
                        categories &&
                        Object.keys(categories).length === 0 &&
                        categories.constructor === Object
                    ) {
                        graphs.push(
                            <div key={id_analysis + "_" + representation + "_" + a}>
                                <h4>{title}</h4>
                                {imageSVG && reduceSVGIconSize}
                                {imageSVG && reduceDataSizeForSVG}
                                <FabriqueRepresentation
                                    // FabriqueRepresentation instancie la représentation précisée dans la propriété representation
                                    // Par exemple, elle peut instancer DiagrammeCirculaire, CourbesEmpilees ou encore CourbesComparees
                                    parentApi={this.props.parentApi}
                                    id_analysis={id_analysis}
                                    representation={representation}
                                    provenance={"tableau_de_bord"}
                                    thematique={thematique}
                                    id={numeroIndicateur}
                                    type={representation}
                                    key={id_analysis + "_" + representation}
                                    tailleSVG={tailleSVG}
                                    tailleData={tailleData}
                                    image={imageSVG}
                                    couleur={couleurAnalyseName}
                                    filters={analysis.filters}
                                    options={
                                        this.state.listOptionsByAnalysis[
                                            numeroIndicateur
                                        ]
                                    }
                                />
                                {imageSVG && increaseSVGIconSize}
                                {imageSVG && increaseDataSizeForSVG}
                            </div>
                        );
                        const pdfName =
                            this.props.parentApi.controller.analysisManager.getAnalysisMethodoPdf(
                                parseInt(id_analysis, 10)
                            );
                        nouvelleAnalyse = (
                            <div key={a}>
                                {listeDeroulanteSVG}
                                {!["analysis-launcher", "logo"].includes(
                                    representation
                                ) && (
                                    <div className="selection-graph do-not-print">
                                        <button
                                            type="button"
                                            className="btn btn-info btn-save block-row bouton-flex "
                                            onClick={() =>
                                                saveAsPng(
                                                    ref,
                                                    analyse ? analyse.nom : ""
                                                )
                                            }
                                        >
                                            <span>PNG</span>
                                        </button>
                                        {pdfName && (
                                            <a
                                                href={createPdfMethodoLink(
                                                    config.methodo_url,
                                                    this.props.parentApi.data.region,
                                                    pdfName
                                                )}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <div className="pdf"></div>
                                            </a>
                                        )}
                                    </div>
                                )}
                                <div ref={ref} className="choisir-methode">
                                    {additionalOptions}
                                    {selectionCategorie}
                                    {graphs}
                                </div>
                            </div>
                        );
                    } else {
                        selectionCategorie.push(
                            <div key={id_analysis + "_" + representation + "_" + a}>
                                <h4>{title ?? "Indicateur désactivé"}</h4>
                            </div>
                        );
                        const representationDetails =
                            this.props.parentApi.controller.analysisManager.getAnalysisRepresentationDetails(
                                id_analysis
                            );
                        for (let categorie in categories) {
                            if (Object.keys(categories).length > 1) {
                                const disabledInDashboard =
                                    representationDetails?.[
                                        "additional_details_disable_in_dashboard_" +
                                            categorie
                                    ];
                                if (disabledInDashboard === "yes") {
                                    continue;
                                }
                                selectionCategorie.push(
                                    this.buttonCategoriesSelection(
                                        thematique,
                                        a,
                                        categorie
                                    )
                                );
                            }
                            if (
                                categories[categorie].visible ||
                                Object.keys(categories).length === 1
                            ) {
                                // Si le booléen visible est initialisé à true
                                // Pour rappel, le tableau est ainsi formé {categorie: secteur, titre: Par secteurs, visible: true}
                                graphs.push(
                                    <FabriqueRepresentation
                                        // FabriqueRepresentation instancie la représentation précisée dans la propriété representation
                                        // Par exemple, elle peut instancer DiagrammeCirculaire, CourbesEmpilees ou encore CourbesComparees
                                        parentApi={this.props.parentApi}
                                        id_analysis={id_analysis}
                                        representation={representation}
                                        thematique={thematique}
                                        provenance="tableau_de_bord"
                                        id={numeroIndicateur}
                                        type={categories[categorie]}
                                        key={categories[categorie].categorie + a}
                                        width={460}
                                        height={330}
                                        tailleSVG={tailleSVG}
                                        tailleData={tailleData}
                                        couleur={couleurAnalyseName}
                                        options={
                                            this.state.listOptionsByAnalysis[
                                                numeroIndicateur
                                            ]
                                        }
                                        image={imageSVG}
                                        filters={analysis.filters}
                                    />
                                );
                            }
                            nouvelleAnalyse = (
                                <div key={a}>
                                    {listeDeroulanteSVG}
                                    {representation !== "analysis-launcher" && (
                                        <div className="selection-graph do-not-print">
                                            <button
                                                type="button"
                                                className="btn btn-info btn-save block-row bouton-flex "
                                                onClick={() =>
                                                    saveAsPng(ref, analyse.nom)
                                                }
                                            >
                                                <span>PNG</span>
                                            </button>
                                            <a
                                                href={createPdfMethodoLink(
                                                    config.methodo_url,
                                                    this.props.parentApi.data.region,
                                                    this.props.parentApi.controller.analysisManager.getAnalysisMethodoPdf(
                                                        parseInt(id_analysis, 10)
                                                    )
                                                )}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <div className="pdf"></div>
                                            </a>
                                        </div>
                                    )}
                                    <div ref={ref} className="graphs choisir-methode">
                                        {additionalOptions}
                                        {selectionCategorie}
                                        {graphs}
                                    </div>
                                </div>
                            );
                        }
                    }
                }
                listeGraphiques[a] = nouvelleAnalyse;
            }
        }
        return listeGraphiques;
    }

    loadDashbordsList() {
        this.props.parentApi.callbacks.chargementListeTableauxDeBordNecessaire(true);
        this.props.parentApi.callbacks.updateAnalysis("");
    }

    /**
     * déplace une thématique vers le haut ou vers le bas
     * @param {integer} groupNumber le numéro de la thématique qui se déplace
     * @param {up|down} mouvement le mouvement (up|down)
     */
    moveGroup(groupNumber, mouvement) {
        let thematiques = this.props.parentApi.data.tableauBordDonnees.donnees;

        // si on n'a pas les données suffisantes
        if (!thematiques || !thematiques[groupNumber]) {
            return false;
        }

        // on calcule la différence qu'il doit y avoir entre les deux thématiques
        let diffOrdre = 0;
        if (mouvement === "up") {
            diffOrdre = -1;
        } else if (mouvement === "down") {
            diffOrdre = 1;
        } else {
            return false;
        }

        let ordreThem = thematiques[groupNumber].ordre;
        let otherThem = groupNumber;

        for (let idThematique in thematiques) {
            let ordreCurrThem = thematiques[idThematique].ordre;
            // si on a trouvé la thématique située en-dessous/au-dessus...
            if (ordreThem + diffOrdre === ordreCurrThem) {
                // on enregistre l'ID
                otherThem = idThematique;
            }
        }

        let res = this.props.parentApi.callbacks.updateGroupsOrderInDashboard(
            groupNumber,
            otherThem
        );
        this.props.parentApi.controller.dashboardManager.setEditingModeDashboard(res);
    }

    renderPOILayerSelection(groupNumber, id) {
        let themesOptions = [];
        if (this.state.poiRubriques) {
            themesOptions = this.state.poiRubriques.map((theme) => (
                <option value={theme} key={theme}>
                    Carte des {theme}
                </option>
            ));
        }
        let value = undefined;
        if (this.props.parentApi.data.tableauBordDonnees.donnees[groupNumber]) {
            if (
                this.props.parentApi.data.tableauBordDonnees.donnees[groupNumber]
                    .indicateurs[id]
            ) {
                value =
                    this.props.parentApi.data.tableauBordDonnees.donnees[groupNumber]
                        .indicateurs[id].id_analysis;
            }
        }
        return (
            <div
                className="alert filter filter-territoire filter-territoire-short relative"
                id={id}
            >
                <label className="label-selecteur-objet">
                    <strong>Equipements</strong>
                </label>
                <select
                    id={id}
                    key={"selectionneur_" + groupNumber}
                    className={"short"}
                    onChange={(event) => {
                        this.props.parentApi.callbacks.updateDashboard(
                            groupNumber,
                            this.props.parentApi.data.tableauBordDonnees.donnees[
                                groupNumber
                            ].titre_thematique,
                            this.props.parentApi.data.tableauBordDonnees.donnees[
                                groupNumber
                            ].description_thematique,
                            id,
                            {
                                id_analysis: event.target.value,
                                representation: "poi_map",
                                categories: {},
                                numero_analyse: id,
                            }
                        );
                        this.setState({ reloadinChartsRequired: true });
                    }}
                    value={value}
                >
                    <option value="selectionnez">
                        Sélectionnez un type d'équipements
                    </option>
                    {themesOptions}
                </select>
            </div>
        );
    }

    render() {
        // on kick les personnes non connectées
        if (!this.props.connected) {
            return (
                <div className="plan-actions widgets full-screen-widget">
                    <Link
                        className="back-to-map"
                        to={"/" + this.props.parentApi.data.urlPartageable}
                    >
                        <button
                            type="button"
                            className="close close-big"
                            data-dismiss="alert"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </Link>{" "}
                    {/* Retour à la carte */}
                    <div className="pdf-pages">
                        <div className="creation-tableaux-bord">
                            <div className="title centered-row">
                                <p className="action-title">Action impossible</p>
                            </div>
                            <p>
                                Vous devez vous connecter pour pouvoir effectuer cette
                                action.
                            </p>
                        </div>
                    </div>
                </div>
            );
        }

        let formulaireMetadonnees = this.formMetadata(); // Ajout du formulaire des métadonnées
        let filtreTerritoires = (
            <MainZoneSelect
                parentApi={this.props.parentApi}
                containerClassName=""
                className=""
                callback={(zone) => {
                    let typeTerritoire = "?zone=" + zone.zoneType;
                    let maille = "&maille=" + zone.zoneMaille;
                    let codeInseeTerritoire = "&zone_id=" + zone.zoneId;
                    let url = typeTerritoire + maille + codeInseeTerritoire;
                    this.props.parentApi.callbacks.mettreAJourParametresUrls(url);
                }}
            />
        );

        // Formulaire qui permet d'ajouter le titre de la thématique
        // structureThematiques est un Objet clé => valeur dont la clé est le nom de la thématique et la valeur
        // un nouvel objet qui décrit la structure de la thématique.
        let structureThematiques = {};
        let indicateur = this.state.chartIndicateur;
        for (let groupNumber in this.props.parentApi.data.tableauBordDonnees.donnees) {
            let thematique =
                this.props.parentApi.data.tableauBordDonnees.donnees[groupNumber]
                    .titre_thematique;
            let ordre =
                this.props.parentApi.data.tableauBordDonnees.donnees[groupNumber].ordre;
            let descriptionThematique =
                this.props.parentApi.data.tableauBordDonnees.donnees[groupNumber]
                    .description_thematique;

            let boutonsMouvement = [];
            if (ordre > 0) {
                boutonsMouvement.push(
                    <button
                        className="btn btn-primary btn-mouvement"
                        key="up"
                        onClick={() => this.moveGroup(groupNumber, "up")}
                    >
                        Monter
                    </button>
                );
            }
            if (
                ordre <
                Object.keys(this.props.parentApi.data.tableauBordDonnees.donnees)
                    .length -
                    1
            ) {
                boutonsMouvement.push(
                    <button
                        className="btn btn-primary btn-mouvement"
                        key="down"
                        onClick={() => this.moveGroup(groupNumber, "down")}
                    >
                        Descendre
                    </button>
                );
            }

            let structure = (
                <div
                    key={"en-tete " + groupNumber}
                    className="en-tete-thematique"
                    id={groupNumber}
                >
                    <div className="titre-thematique">
                        <div className="en-tete-thematique">
                            <p>{boutonsMouvement}</p>
                            <div>
                                <label htmlFor={"titre_thematique_" + groupNumber}>
                                    <b>Titre : </b>
                                </label>
                                <input
                                    name={"titre_thematique_" + groupNumber}
                                    id={"titre_thematique_" + groupNumber}
                                    type="text"
                                    placeholder="Titre de la thématique"
                                    defaultValue={thematique}
                                    autoFocus={ordre === 0}
                                    onChange={(event) =>
                                        this.editGroupTitle(event, groupNumber)
                                    }
                                ></input>
                            </div>
                            <div>
                                <label
                                    htmlFor={"description_thematique_" + groupNumber}
                                >
                                    <b>{this.showMarkdownTooltip("Description")} : </b>
                                </label>
                                <textarea
                                    id={"description_thematique_" + groupNumber}
                                    name={"description_thematique_" + groupNumber}
                                    type="text"
                                    placeholder="Description de la thématique"
                                    defaultValue={descriptionThematique}
                                    onChange={(event) =>
                                        this.editGroupDescription(event, groupNumber)
                                    }
                                ></textarea>
                            </div>
                        </div>
                        {this.buttonDeleteGroup(groupNumber)}
                    </div>
                    <div className="buttons-add-elements-group">
                        {this.buttonAddNewIndicator(groupNumber)}{" "}
                        {this.buttonAddPOIMap(groupNumber)}{" "}
                        {this.buttonAddPCAETCurve(groupNumber)}{" "}
                        {this.buttonAddDataSourceLogo(groupNumber)}{" "}
                        {this.buttonAddDidactifFileLink(groupNumber)}{" "}
                        {this.buttonAddExternalLink(groupNumber)}{" "}
                        {this.buttonAddTextBlock(groupNumber)}{" "}
                    </div>
                </div>
            );
            structureThematiques[groupNumber] = structure;
        }

        // on crée le tableau permettant d'ordonner les thématiques
        let ordreThematiques = {};
        for (let groupNumber in this.props.parentApi.data.tableauBordDonnees.donnees) {
            // Pour chaque thématique
            let ordreThematique =
                this.props.parentApi.data.tableauBordDonnees.donnees[groupNumber].ordre;
            // il nous faut gérer le cas où l'ordre n'est pas disponible (pas normal)
            // ou bien s'il est déjà présent (pas normal non plus)
            if (
                ordreThematique === undefined ||
                ordreThematique === null ||
                ordreThematique === false ||
                isNaN(ordreThematique) ||
                Object.keys(ordreThematiques).includes(ordreThematique)
            ) {
                // s'il n'y a pas encore d'objet dans le tableau, on met 0
                if (Object.keys(ordreThematiques).length === 0) {
                    ordreThematique = 0;
                } else {
                    // on utilise l'ordre max + 1 (on met à la fin quoi)
                    // cette formule reste valide même si on continue de remplir le tableau
                    ordreThematique = Math.max(Object.keys(ordreThematiques)) + 1;
                }
            }
            // on sauvegarde l'ID au niveau de l'ordre
            ordreThematiques[ordreThematique] = groupNumber;
        }
        let ordresThematiquesOrdonnees = Object.keys(ordreThematiques)
            .map(Number)
            .sort(function (a, b) {
                return a - b;
            });

        let toutesThematiques = []; // Liste d'éléments html et composants.
        // Pour chacune des thématiques dans l'ordre
        ordresThematiquesOrdonnees.forEach((ordre) => {
            // on récupère le numéro de la thématique
            let groupNumber = ordreThematiques[ordre];
            toutesThematiques.push(structureThematiques[groupNumber]); // On en ajoute la structure (bouton ajouter analyses et suppression thématique)

            const currentGroupBlocks =
                this.props.parentApi.data.tableauBordDonnees.donnees[groupNumber]
                    .indicateurs;
            let toutesAnalyses = []; // Toutes les analyses contenues dans une thématique
            for (let a in currentGroupBlocks) {
                const block = currentGroupBlocks[a];
                let analyse = []; // Liste des éléments pour une seule analyse (sélecteur d'analyse, bouton de suppression, graphiques)
                let currentElementRender = undefined;
                if (block.representation === "logo") {
                    currentElementRender = this.renderLogoSelection(groupNumber, a);
                } else if (block.representation === "poi_map") {
                    currentElementRender = this.renderPOILayerSelection(groupNumber, a);
                } else if (block.representation === "didactic-file-launcher") {
                    currentElementRender = this.renderDidacticFileSelection(
                        groupNumber,
                        a
                    );
                } else if (block.representation?.startsWith("pcaet-trajectory")) {
                    currentElementRender = this.renderPCAETTrajectoriesSelection(
                        groupNumber,
                        a
                    );
                } else if (block.representation === "link-launcher") {
                    currentElementRender = this.renderLinksSelection(groupNumber, a);
                } else if (block.representation === "text-block") {
                    currentElementRender = this.renderTextBlockForm(groupNumber, a);
                } else {
                    currentElementRender = this.renderIndicatorSelection(
                        block,
                        groupNumber,
                        a
                    );
                }
                analyse.push(
                    <div
                        className="selecteur choisir-methode"
                        key={"selecteur" + groupNumber + a}
                    >
                        {currentElementRender}
                        {this.insertIndicatorDeleteButton(groupNumber, a)}
                    </div>
                );
                if (indicateur) {
                    // si indicateur est défini et si les clés correspondent (association sélecteur d'analyse et graphique)
                    if (
                        indicateur[a] &&
                        String(indicateur[a].key) ===
                            String(currentElementRender.props.id)
                    ) {
                        analyse.push(indicateur[a]);
                    }
                }
                toutesAnalyses.push(
                    <div
                        className="selecteur-analyse choisir-methode"
                        key={groupNumber + "_selecteur-analyse_" + a}
                    >
                        {analyse}
                    </div>
                );
                if (indicateur) {
                    if (indicateur[a]) {
                        toutesAnalyses.push(
                            <span
                                className="separateur-vertical"
                                key={"span_" + groupNumber + a}
                            ></span>
                        ); // Séparateur vertical
                    }
                }
            }
            let thematiqueFinale = (
                <div
                    className="structure-thematique structure-thematique-creation"
                    key={groupNumber + "_structure-thematique-tableau"}
                >
                    {toutesAnalyses}
                </div>
            );
            toutesThematiques.push(thematiqueFinale);
        });
        let pasDeTerritoireSelectionne = "";
        let boutonNouvelleThematique = this.buttonNewGroup();
        let boutonEnregistrerModifications = this.buttonUpdateDashboard(
            this.state.dashboardId
        );
        let boutonPublication = "";
        if (this.props.parentApi.data.profil === "admin") {
            boutonPublication = this.buttonPublishDashboard(
                this.props.parentApi.data.zone.zone,
                this.props.parentApi.data.currentZone,
                this.state.dashboardId
            );
        }
        let formulaireDePublication = this.publicationForm();
        if (!this.props.territoireSelectionne) {
            // Si la territoire est la région alors currentZone n'est pas déini mais on a bien sélectionné un teritoire
            pasDeTerritoireSelectionne = (
                <label className="alert alert-warning">
                    Pour suivre l'avancement du tableau de bord, vous devez d'abord
                    sélectionner un territoire.
                </label>
            );
            toutesThematiques = "";
            boutonNouvelleThematique = "";
            boutonEnregistrerModifications = "";
            boutonPublication = "";
            formulaireDePublication = "";
        }
        if (this.state.listeAffectations.length === 0) {
            formulaireDePublication = "";
        }

        let message = "";

        if (this.state.erreur) {
            message = <div className="alert alert-warning">{this.state.erreur}</div>;
        }
        if (this.state.status) {
            message = <div className="alert alert-success">{this.state.status}</div>;
        }
        let pdfUrl = createPdfMethodoLink(
            config.methodo_url,
            this.props.parentApi.data.region,
            configData.methodoCreationTableauBord
        );
        let res = (
            <div className="plan-actions widgets full-screen-widget">
                <SEO
                    settings={this.props.parentApi.data.settings["seo"]}
                    page="dashboard"
                    seoDetails="Tableau de bord - Création"
                />
                <Link
                    className="back-to-map"
                    to={"/" + this.props.parentApi.data.urlPartageable}
                >
                    <button
                        type="button"
                        className="close close-big"
                        data-dismiss="alert"
                        aria-label="Close"
                        onClick={() => this.loadDashbordsList(true)}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Link>{" "}
                {/* Retour à la carte */}
                <a href={pdfUrl} target="_blank" rel="noreferrer">
                    <div className="help"></div>
                </a>
                <div className="creation-tableaux-bord">
                    <div className="title centered-row">
                        <label className="action-title">Tableaux de bord</label>
                        <label className="action-territoire">
                            Sélectionnez un territoire pour suivre l'évolution du
                            tableau de bord{" "}
                        </label>
                    </div>
                    <div className="text-a-droite">{filtreTerritoires}</div>
                    {pasDeTerritoireSelectionne}
                    <div className="thematique-finale">
                        {toutesThematiques}
                        <div className="elements-alignes-a-gauche">
                            <div className="flex-ligne">
                                <div className="nouvelle-ligne">
                                    {boutonNouvelleThematique}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        key={"validation-metadata-form"}
                        className="centered-row panel-ajout-indicateur formulaire-metadonnees"
                    >
                        {formulaireMetadonnees}
                        {boutonEnregistrerModifications}
                        {boutonPublication}
                    </div>
                    {message}
                    {formulaireDePublication}
                </div>
                <div className="footer-charts"></div>
                {this.state.confirmJSX}
            </div>
        );

        if (this.state.reinitialisationTableauDeBord) {
            res = <div id="tdb-reinitialise"></div>;
        }

        return res;
    }
}
export default DashboardEdition;
