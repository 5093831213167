/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import ReactTable from "react-table-6";

import Api from "../../Controllers/Api";
import config from "../../settings.js";
import { buildRegionUrl, filterCaseInsensitive, exportToCSV } from "../../utils.js";

/**
 * This component allows to edit the template Excel file used to export the results
 * and parameters of territorial strategies.
 */
class StrategyErrors extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
        };
    }

    componentDidMount() {
        this.getInformation();
    }

    /**
     * Get current template file
     * @returns the file from the API
     */
    getInformation() {
        const url = buildRegionUrl(
            config.api_strategy_errors,
            this.props.parentApi.data.region
        );
        Api.callApi(url, null, "GET").then((response) => {
            this.setState({
                data: response,
            });
        });
    }

    exportTable() {
        exportToCSV(this.state.data, "csv");
    }

    render() {
        const columns = [
            {
                Header: "Numéro de l'action",
                accessor: "action_number",
            },
            {
                Header: "Clé",
                accessor: "action_key",
            },
            {
                Header: "Type de territoire",
                accessor: "zone_type",
            },
            {
                Header: "Territoire",
                accessor: "zone_id",
            },
            {
                Header: "Message d'erreur",
                accessor: "error_message",
            },
            {
                Header: "Date de l'erreur",
                accessor: "error_date",
            },
        ];

        return (
            <div className="panel-body user-scenarii">
                <h3>
                    Erreurs répertoriées des stratégies lancées par les utilisateurs
                </h3>
                <p>
                    <button className="btn btn-info" onClick={() => this.exportTable()}>
                        Exporter les données en csv
                    </button>{" "}
                </p>
                {this.state.data && (
                    <ReactTable
                        data={this.state.data}
                        columns={columns}
                        filterable={true}
                        defaultFilterMethod={(filter, row) =>
                            filterCaseInsensitive(filter, row)
                        }
                        className="-striped"
                        defaultPageSize={100}
                    />
                )}
            </div>
        );
    }
}

export default StrategyErrors;
