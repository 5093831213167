/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React, { useEffect, useState } from "react";
import Api from "../../Controllers/Api.js";
import { buildRegionUrl } from "../../utils.js";
import config from "../../settings.js";
import "../../style/national/login.css";
import { useHistory } from "react-router-dom";

import AuthManager from "../../Controllers/AuthManager.js";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min.js";

function ForgotPassword({ onSubmit, onForgotPasswordClick }) {
    const [msgStatus, setMsgStatus] = useState("");

    const handleSubmit = async (event) => {
        event.preventDefault();
        const login = event.target.elements["login"].value;
        try {
            const message = await onSubmit(login);
            setMsgStatus(message);
            event.target.reset();
        } catch (error) {
            setMsgStatus(error.message);
        }
    };

    return (
        <form id="form" className="tsform" onSubmit={handleSubmit}>
            <div className="input">
                <label htmlFor="login">E-mail</label>
                <input type="text" id="login" name="login" required />
            </div>
            <div className="error-status">{msgStatus}</div>
            <div>
                <input
                    type="button"
                    className="tsbtn"
                    onClick={onForgotPasswordClick}
                    value="Annuler"
                />{" "}
                <input
                    type="submit"
                    className="tsbtn danger"
                    value="Réinitialiser le mot de passe"
                />
            </div>
        </form>
    );
}

function Login(props) {
    const [forgotPassword, setForgotPassword] = useState(false);
    const [title, setTitle] = useState("Se connecter");
    const [associatedRegions, setAssociatedRegions] = useState([]);
    const [nationalRegion, setNationalRegion] = useState(undefined);
    const [errorStatus, setErrorStatus] = useState("");
    const { connected } = props.parentApi.data;
    const history = useHistory();
    const location = useLocation();
    const previousPage = location.state?.previousPage;

    useEffect(() => {
        // Redirect to the portal if the user is logged in
        if (connected) {
            history.push("/national/" + props.parentApi.data.region + "/portail");
        }
    }, [connected, history, props.parentApi.data.region]);

    const fullAuthentication = async (login, password, nationalRegion) => {
        const body = JSON.stringify({
            login,
            password,
            region: "national",
            nationalRegion,
        });
        try {
            const json = await Api.callApi(
                buildRegionUrl(config.auth_url, "national"),
                body,
                "POST"
            );
            const accessToken = json["access_token"];
            const refreshToken = json["refresh_token"];
            localStorage.setItem("access_token", accessToken);
            localStorage.setItem("refresh_token", refreshToken);
            const authManager = new AuthManager(null, "national");
            props.parentApi.callbacks.updateAuthManager(authManager);
            const connected = await authManager.getMe(props.parentApi.callbacks);
            if (connected) {
                // update the region used as central region to custom region instead of national
                props.parentApi.callbacks.updateNationalTerritory(true, nationalRegion);
                // update zone
                getCodeRegion(nationalRegion);
                history.push("/national/" + nationalRegion + "/portail");
            }
        } catch (error) {
            setErrorStatus("Authentification échouée.");
            console.error("Error during login:", error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const form = e.target.elements;
        const login = form["login"].value;
        const password = form["password"].value;

        if (nationalRegion) {
            await fullAuthentication(login, password, nationalRegion);
        } else {
            const region = "national";
            const body = JSON.stringify({ login, password, region });
            try {
                const json = await Api.callApi(
                    buildRegionUrl(config.national_auth_url, region),
                    body,
                    "POST"
                );
                if (!json?.length) {
                    throw Error("Aucun schema associé à cet utilisateur");
                }
                if (json.length === 1) {
                    await fullAuthentication(login, password, json[0].id);
                }
                setAssociatedRegions(json);
            } catch (error) {
                setErrorStatus("Authentification échouée.");
                console.error("Error during login:", error);
            }
        }
    };

    const handleForgotPassword = () => {
        setTitle(forgotPassword ? "Se connecter" : "Réinitialiser le mot de passe");
        setForgotPassword(!forgotPassword);
    };

    const handleForgotPasswordSubmit = async (login) => {
        try {
            let url = buildRegionUrl(config.user_request_new_password, "national");
            const body = JSON.stringify({ mail: login });
            await Api.callApi(url, body, "POST");
            return "Demande prise en compte. Un nouveau mot de passe vous sera envoyé par email.";
        } catch (error) {
            throw new Error(
                "Une erreur est survenue lors de la réinitialisation du mot de passe."
            );
        }
    };

    const getCodeRegion = (region) => {
        let url = buildRegionUrl(config.api_code_region_national, "national").replace(
            "#territory#",
            region
        );
        Api.callApi(url, null, "GET")
            .then((code) => {
                props.parentApi.callbacks.updateZone("region", "commune", code);
            })
            .catch((e) => console.error(e));
    };

    return (
        <div className="submission widgets full-screen-widget">
            <div>
                {previousPage && (
                    <div>
                        <Link to={previousPage} className="btn btn-light return-button">
                            <i className="bi bi-arrow-left"></i>
                        </Link>
                    </div>
                )}
                <h3 className="tstitle">{title}</h3>
                {!forgotPassword && (
                    <form className="tsform" onSubmit={handleSubmit}>
                        <div className="input">
                            <label htmlFor="login">E-mail</label>
                            <input type="text" id="login" name="login" required />
                        </div>
                        <div className="input">
                            <label htmlFor="password">Mot de passe</label>
                            <input
                                type="password"
                                id="password"
                                name="password"
                                required
                            />
                        </div>
                        {associatedRegions.length > 0 && (
                            <div className="input">
                                <label htmlFor="region">Territoire de travail</label>
                                <select
                                    id="region"
                                    name="region"
                                    defaultValue={nationalRegion}
                                    onChange={(e) => setNationalRegion(e.target.value)}
                                >
                                    <option selected disabled>
                                        Choisissez le territoire à utiliser
                                    </option>
                                    {associatedRegions.map((region) => {
                                        return (
                                            <option value={region.id} key={region.id}>
                                                {region.label}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        )}
                        <input
                            type="button"
                            onClick={handleForgotPassword}
                            className="tsbtn"
                            style={{ display: "block", marginLeft: "auto" }}
                            value="Mot de passe oublié ?"
                        />
                        <div className="error-status">{errorStatus}</div>
                        <input
                            type="submit"
                            className="tsbtn info big"
                            value="Se connecter"
                        />
                    </form>
                )}
                {forgotPassword && (
                    <ForgotPassword
                        onSubmit={handleForgotPasswordSubmit}
                        onForgotPasswordClick={handleForgotPassword}
                    />
                )}
            </div>
        </div>
    );
}

export default Login;
