/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */
import React, { useEffect } from "react";

import MDEditor from "@uiw/react-md-editor";
import rehypeSanitize from "rehype-sanitize";
import rehypeParse from "rehype-parse";
import rehypeStringify from "rehype-stringify";

import { unified } from "unified";

export default function TextBlock({ content }) {
    const [sanitizedContent, setSanitizedContent] = React.useState(content);

    useEffect(() => {
        unified()
            .use(rehypeParse, { fragment: true })
            .use(rehypeSanitize)
            .use(rehypeStringify)
            .process(content)
            .then((result) => {
                setSanitizedContent(result.value);
            });
    }, [content]);

    return (
        <MDEditor.Markdown
            disallowedElements={["img"]}
            source={String(sanitizedContent)}
        />
    );
}
