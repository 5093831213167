/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import { Line, Pie, Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import configData from "../../settings_data.js";
import FabriqueRepresentation from "../FabriqueRepresentation.js";
import { isLightColor, splitCategoryFilters } from "../../utils.js";

/**
 * This component is used to create a chart (several types possible)
 */
class Charts extends React.Component {
    constructor(props) {
        super(props);

        const legendChartOptions = {
            display: true,
            position: "left",
            fullWidth: false,
            reverse: false,
            labels: {
                fontColor: configData.chartFontColor,
                fontSize: configData.chartFontSize,
                padding: configData.chartPadding,
                boxWidth: 10,
            },
        };
        this.divChartSizes = [];
        this.state = {
            legend: legendChartOptions,
            data: {},
            analysis: props.parentApi.data.analysis,
            nbCharts: props.nbCharts,
            id: props.id,
            chartsIds: props.chartsIds,
            widthDOM: window.innerWidth,
        };

        if (
            props.parentApi.data.analysis !== undefined &&
            props.parentApi.data.analysis !== ""
        ) {
            let data = this.props.parentApi.controller.analysisManager.getDataChart(
                props.parentApi.data.analysis,
                props.id,
                this.props.parentApi.data.analysisSelectedUnit
            );
            this.state["data"] = data.data;
            this.state["meta"] = data.meta;
            if (data.data.confid) {
                this.props.parentApi.callbacks.typeConfid(data.data.confid.charts);
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        let reload = false;
        let analysis = this.props.parentApi.data.analysis;
        let id = this.props.id;
        let currentZone = this.props.parentApi.data.currentZone;
        let confidActuelle = this.props.parentApi.data.confidActuelle; // Reload render if the confid type is different to fix a display bug
        let filtreCourant = this.props.parentApi.data.fluxThreshold;
        let filtreCategorieCourant = this.props.parentApi.data.filtreCategorieCourant;
        let display = this.props.parentApi.data.displayChart;
        let zone = this.props.parentApi.data.zone;
        let conditionChargementIndicateursClimat =
            !display || this.props.parentApi.data.analysisMeta.data_type !== "climat";
        if (!this.state.data.confid && conditionChargementIndicateursClimat) {
            let data = this.props.parentApi.controller.analysisManager.getDataChart(
                this.props.parentApi.data.analysis,
                this.props.id,
                this.props.parentApi.data.analysisSelectedUnit
            );
            let dataFinale = this.state.data;
            dataFinale["data"] = data.data;
            dataFinale["meta"] = data.meta;
            this.props.parentApi.callbacks.displayChart(false);
            if (JSON.stringify(dataFinale) !== JSON.stringify(this.state.data)) {
                this.setState({
                    data: dataFinale,
                });
            }
        }

        if (
            confidActuelle !== prevProps.parentApi.data.confidActuelle &&
            conditionChargementIndicateursClimat
        ) {
            reload = true;
        }

        if (
            filtreCourant !== prevProps.parentApi.data.fluxThreshold &&
            conditionChargementIndicateursClimat
        ) {
            reload = true;
        }

        if (
            filtreCategorieCourant !==
                prevProps.parentApi.data.filtreCategorieCourant &&
            conditionChargementIndicateursClimat
        ) {
            reload = true;
        }
        if (this.state.filtreCategorieCourant !== prevState.filtreCategorieCourant) {
            reload = true;
            this.setState({
                filtresLocauxCourants:
                    this.props.parentApi.controller.analysisManager.filtre_initial,
            });
        }
        if (
            prevProps.parentApi.data.analysis !== analysis &&
            conditionChargementIndicateursClimat
        ) {
            reload = true;
        }
        if (
            prevProps.parentApi.data.currentZone !== currentZone &&
            conditionChargementIndicateursClimat
        ) {
            reload = true;
            if (analysis) {
                let filtreDefaut =
                    this.props.parentApi.controller.analysisManager.getFilterDefaultValue(
                        analysis
                    );
                this.props.parentApi.callbacks.reinitialiserFiltreValeur(filtreDefaut);
            }
        }

        if (prevProps.parentApi.data.zone.maille !== zone.maille) {
            // reload the climate data, if the maille has been changed
            reload = true;
        }

        if (
            prevProps.parentApi.data.analysisSelectedYear !==
            this.props.parentApi.data.analysisSelectedYear
        ) {
            // when selected year changed for current analysis => reload charts
            reload = true;
        }

        if (
            prevProps.parentApi.data.analysisSelectedUnit !==
            this.props.parentApi.data.analysisSelectedUnit
        ) {
            // when selected unit changed for current analysis => reload charts
            reload = true;
        }

        if (reload) {
            this.loadData(analysis, id, this.props.parentApi.data.confidActuelle);
        }
    }

    launchAnalysis = (e, id) => {
        e.stopPropagation();

        const { parentApi } = this.props;
        const { controller, callbacks } = parentApi;
        const { analysisManager } = controller;

        const parsedId = parseInt(id, 10);
        const currentAnalysisId = parseInt(parentApi.data.analysis, 10);

        if (parsedId === currentAnalysisId) {
            callbacks.reset(true);
        } else {
            const analysisName = analysisManager.getAnalysisName(parsedId);
            const analysisTheme = analysisManager.getAnalysisNameTheme(parsedId);

            callbacks.updateAnalysis(id, false, "carto");
            callbacks.miseAJourUiTheme(analysisTheme);
            callbacks.miseAjourNomIndicateurCourant(analysisName);
            callbacks.updateProvenance("sub_indicator");

            this.setState({
                analysis: parsedId,
            });
        }

        callbacks.displayChart(false);
    };

    /**
     * Builds the legend with which we can select or delete modalities
     * in the data to be displayed using checkboxes, from modalities by categories.
     * @param {objet clé valeur} chart : data for a chart constructed as follows:
     * {
     *     data : {
     *         confid : Type of confidentiality,
     *         id : indicator identifier,
     *         labels : list of modalities,
     *         datasets : [{
     *             data : List of values in the correct order to match them
     *             to the order of the modalities,
     *             backgroundColor : List of colors in the correct order to match them
     *             to the order of the modalities
     *         }]
     *     }
     * }
     * This array contains other keys that we don't use in this method.
     */
    buildLegend(data, isConfid) {
        let type = "";
        if (!isConfid) {
            type = "liste-modalites-camembert";
        }
        if (data.type === "histogramme") {
            type = "liste-modalites-histogramme";
        }

        let id = this.state.id;
        let legende = [];
        let identifiant_indicateur = this.props.parentApi.data.analysis;
        let filtreInitial = data.filtre_initial;
        let colorRegion = getComputedStyle(document.body).getPropertyValue(
            "--" + this.props.parentApi.data.settings.theme
        );
        if (!filtreInitial) {
            filtreInitial =
                this.props.parentApi.controller.analysisManager.initFiltersByCategory(
                    parseInt(identifiant_indicateur, 10)
                );
        }

        let listeModaliteFiltre = [];

        if (filtreInitial[data.name]) {
            if (filtreInitial)
                for (let elem of filtreInitial[data.name]) {
                    listeModaliteFiltre.push(
                        splitCategoryFilters(elem["filtre_categorie"])[1]
                    );
                }
        }

        for (let i in data.datasets[0].data) {
            let checked = false;
            if (listeModaliteFiltre.indexOf(data.labels[i]) !== -1) {
                checked = true;
            }

            let classDiagramCarto = "";
            if (!isConfid) {
                classDiagramCarto = " diagramme-carto";
            }
            if (data.labels[i] !== "indisponible") {
                let subIndicatorsButtons = [];
                if (data.sub_indicator) {
                    subIndicatorsButtons = data.sub_indicator.map((obj) => {
                        const isSameAnalysis =
                            parseInt(obj.parent, 10) === parseInt(data.indicateur, 10);
                        const isSameCategory = obj.category === data.categorie;
                        const isSameModality = obj.modality_id === data.modality_id[i];

                        if (!isSameAnalysis || !isSameCategory || !isSameModality) {
                            return null;
                        }
                        return (
                            <button
                                key={obj.indicator}
                                style={{
                                    fontSize: "x-small",
                                    backgroundColor: colorRegion,
                                    color: "#ffffff",
                                    marginLeft: "3px",
                                    border: "none",
                                }}
                                onClick={(e) =>
                                    this.launchAnalysis(e, parseInt(obj.indicator, 10))
                                }
                            >
                                Détails
                            </button>
                        );
                    });
                }
                legende.push(
                    <div
                        className={"legende-modalite" + classDiagramCarto}
                        key={"legende-" + i + "-" + id}
                    >
                        <span
                            className="element-legende-modalites"
                            style={{
                                backgroundColor: data.datasets[0].backgroundColor[i],
                                display: "block",
                                height: "12px",
                                width: "12px",
                            }}
                        ></span>
                        <input
                            type="checkbox"
                            defaultChecked={checked}
                            id={"legende-" + i + "-" + data.labels[i]}
                            className="element-legende-modalites"
                            onChange={(e) =>
                                this.filtrerSurLegende(data.labels[i], e.target.checked)
                            }
                        ></input>
                        <label
                            htmlFor={"legende-" + i + "-" + data.labels[i]}
                            className="element-legende-modalites"
                        >
                            {" "}
                            {data.labels[i]}
                            {subIndicatorsButtons}
                        </label>
                    </div>
                );
            }
        }

        return (
            <ul className={"liste-modalites " + type} key={"legende-finale-" + id}>
                {legende}
            </ul>
        );
    }

    loadData(analysis, id) {
        if (analysis !== undefined && analysis !== "") {
            let data = this.props.parentApi.controller.analysisManager.getDataChart(
                analysis,
                id,
                this.props.parentApi.data.analysisSelectedUnit
            );
            if (data.data && data.meta) {
                // we test we are not entering an infinite loop
                if (
                    JSON.stringify(this.state.data) !== JSON.stringify(data.data) ||
                    JSON.stringify(this.state.meta) !== JSON.stringify(data.meta)
                ) {
                    this.setState({
                        data: JSON.parse(JSON.stringify(data.data)),
                        meta: JSON.parse(JSON.stringify(data.meta)),
                    });
                }
            }
            if (data.data.confid) {
                this.props.parentApi.callbacks.typeConfid(data.data.confid.charts);
            }
        }
    }

    /**
     * Launches a main component method to add/remove a modality to the filter.
     * This method is triggered when you check a box associated with a modality
     * @param {chaine de caractère} modalite : modality checked / unchecked
     */
    filtrerSurLegende(modalite, checked) {
        let filterWithTableName = this.state.data.filter_table + "." + modalite;
        this.props.parentApi.callbacks.updateMapFilter(
            filterWithTableName,
            this.props.parentApi.data.fluxThreshold,
            this.props.categorie,
            checked
        );
        this.setState({
            filtreCategorieCourant: filterWithTableName,
        });
    }

    /**
     * calculate the average value of a season for climate data
     */
    calculer_avg(arr) {
        let avg,
            sum = 0;
        if (arr.length === 0) {
            return;
        }
        for (let i in arr) {
            sum += parseFloat(arr[i]);
        }
        avg = sum / arr.length;
        return avg;
    }

    /**
     * Disable tooltip for graph
     */
    desactiverInfoBullesGraphiques() {
        this.setState({
            libelle: false,
        });
    }

    render() {
        let label = "";
        let donneesIndisponibles = "";
        let legendeFinale = "";
        let chartObj = this;
        let data = this.state.data;

        if (["switch-button", "selection"].includes(data.type)) {
            return null;
        }
        if (data.messageNePasAfficherDonneesMailles) {
            return (
                <div className="confid-chart">
                    {data.messageNePasAfficherDonneesMailles}
                </div>
            );
        }
        if (!data.labels) {
            return null;
        }

        if (this.props.representation) {
            chartObj.state.data.type = this.props.representation;
        }
        // Reminder of the zone selected
        let zoneInfos = "";
        let zoneName = this.props.parentApi.controller.zonesManager.getZoneName(
            this.props.parentApi.data.currentZone,
            this.props.parentApi.data.zone
        );
        if (this.props.parentApi.data.zone.zone === "region") {
            zoneInfos = " (Région)";
        } else {
            zoneInfos = " (" + (zoneName ? zoneName : "EPCI") + ")";
        }

        let chart = "";
        let chartId = "chart" + this.state.id;

        // Pie Chart Width Estimation
        let sizeMenu = 300;
        let maxLabelsInColumn = 10;
        let sizeChar = 6;
        let sizeChart = 180;
        let width = sizeChart;

        // Retrieve the number of characters in the legends to determine the approximate length
        // By column
        let maxLabelLength = 0;
        let indexLabel = 0;
        for (let label of data.labels) {
            let labelLength = label.length;
            if (maxLabelLength < labelLength) {
                maxLabelLength = labelLength;
            }
            indexLabel += 1;
            if (indexLabel > maxLabelsInColumn) {
                width += maxLabelLength * sizeChar;
                indexLabel = 0;
                maxLabelLength = 0;
            }
        }
        // Last value
        width += maxLabelLength * sizeChar;

        // If there is space left, we add a few pixels for the last of them
        if (this.props.last) {
            // Retrieve the size of the previous diagrams
            let widthPrecedent = 0;
            for (let i = 0; i < this.state.chartsIds.length - 1; i++) {
                let idPrec = this.state.chartsIds[i];
                if (document.getElementsByClassName("charts-" + idPrec).length > 0) {
                    let chartWidth = document.getElementsByClassName(
                        "charts-" + idPrec
                    )[0].offsetWidth;
                    widthPrecedent += chartWidth;
                }
            }
            let newWidth = this.state.widthDOM - widthPrecedent - sizeMenu - 10; // 10 est une marge
            if (newWidth > width) {
                width += 20;
            }
        }

        let classeRadar = "";
        let maxLargeurDiagramme = "";
        if (data.type === "radar") {
            for (let a in data.datasets) {
                data.datasets[a].backgroundColor = "#007F7B66";
                data.datasets[a].pointBackgroundColor =
                    data.datasets[a].origBackgroundColor;
            }
            classeRadar = "charts-legende-radars";
            chart = (
                <FabriqueRepresentation
                    parentApi={this.props.parentApi}
                    id_analysis={this.props.parentApi.data.analysis}
                    representation={"radar"}
                    provenance="carto"
                    type={{ categorie: data.categorie, titre: data.titre }}
                    inverserAxeVertical={true}
                    key={this.props.parentApi.data.analysis + "_radar"}
                    mapCallback={true}
                    data={data}
                />
            );
        }
        if (data.type === "pie" || data.type === "histogramme") {
            let { unit } =
                this.props.parentApi.controller.analysisManager.getUnitParamsForIndicator(
                    this.props.parentApi.data?.analysisSelectedUnit,
                    this.props.parentApi.data.analysisMeta,
                    this.props.parentApi.data.zone.maille
                );
            let isConfid = false;
            if (this.state.libelle) {
                label = <div style={this.state.style}>{this.state.libelle}</div>;
            }
            maxLargeurDiagramme = " diagramme-carto";
            let titre = data.titre + zoneInfos;
            if (titre.length > configData.chartMaxTitleLength && zoneInfos) {
                titre = data.titre + "\n" + zoneInfos;
            }

            let valeurDistinctesDonnees = Array.from(new Set(data.datasets[0].data));

            if (
                valeurDistinctesDonnees.length === 1 &&
                valeurDistinctesDonnees[0] === undefined
            ) {
                titre = "";
                donneesIndisponibles = <h4>Pas de données disponibles</h4>;
            }

            // Tooltip according to https://github.com/chartjs/Chart.js/blob/master/docs/samples/tooltip/html.md
            // Function to create the main div receiving the tooltip if it doesn't exist
            const getOrCreateTooltip = (chart) => {
                let tooltipEl = chart.canvas.parentNode.querySelector("div");

                if (!tooltipEl) {
                    // We indicate all the style elements for the tooltip div (including transparency)
                    tooltipEl = document.createElement("div");
                    tooltipEl.style.background = "rgba(0, 0, 0, 0.8)";
                    tooltipEl.style.borderRadius = "5px";
                    tooltipEl.style.color = "white";
                    tooltipEl.style.opacity = 1;
                    tooltipEl.style.pointerEvents = "none";
                    tooltipEl.style.display = "flex";
                    tooltipEl.style.width = "max-content";
                    tooltipEl.style.zIndex = 999999;
                    tooltipEl.style.position = "absolute";
                    tooltipEl.style.alignItems = "center";
                    tooltipEl.style.justifyContent = "center";
                    tooltipEl.style.transform = "translate(-50%, 0)";
                    tooltipEl.style.transition = "all .4s ease";

                    const div = document.createElement("div");
                    div.style.margin = "0px";

                    tooltipEl.appendChild(div);
                    chart.canvas.parentNode.appendChild(tooltipEl);
                }

                return tooltipEl;
            };

            /**
             * Create the tooltip and fill it with relevant information.
             */
            const externalTooltipHandler = (context) => {
                // Tooltip Element
                const { chart, tooltip } = context;
                const tooltipEl = getOrCreateTooltip(chart);

                // Hide if no tooltip
                if (tooltip.opacity === 0) {
                    tooltipEl.style.opacity = 0;
                    return;
                }

                // Set Text
                if (tooltip.body) {
                    const bodyLines = tooltip.body.map((b) => b.lines);
                    const tooltipBody = document.createElement("div");
                    bodyLines.forEach((body, i) => {
                        // if we have more than one element to display, we need to skip a new line
                        if (i > 0)
                            tooltipBody.appendChild(document.createElement("br"));

                        const colors = tooltip.labelColors[i];

                        // label from the label of the target object
                        let libelleBrut = tooltip.dataPoints[i].label;
                        let libelle =
                            libelleBrut +
                            "\u00a0: " +
                            new Intl.NumberFormat("fr-FR").format(
                                tooltip.dataPoints[i].raw
                            ) +
                            "\u00a0" +
                            unit;

                        // square containing the color of the dotted diagram piece
                        const span = document.createElement("span");
                        span.style.background = colors.backgroundColor;
                        span.style.borderColor = colors.borderColor;
                        span.style.borderWidth = "2px";
                        span.style.marginRight = "10px";
                        span.style.height = "10px";
                        span.style.width = "10px";
                        span.style.display = "inline-block";

                        const text = document.createTextNode(libelle);

                        tooltipBody.appendChild(span);
                        tooltipBody.appendChild(text);

                        // we add the percentage in the tooltip
                        let sum = 0;
                        let dataArr = context.chart.data.datasets[0].data;
                        dataArr.forEach((data) => {
                            sum += data;
                        });
                        let percentage = (tooltip.dataPoints[i].raw * 100) / sum;

                        const percentageText = document.createElement("span");
                        percentageText.appendChild(
                            document.createTextNode(percentage.toFixed(1) + "%")
                        );
                        percentageText.style.marginLeft = "20px";

                        tooltipBody.appendChild(document.createElement("br"));
                        tooltipBody.appendChild(percentageText);
                    });

                    // Retrieve the tooltip to put our object in it
                    const tableRoot = tooltipEl.querySelector("div");

                    // Remove all other information that may be present
                    while (tableRoot.firstChild) {
                        tableRoot.firstChild.remove();
                    }

                    // Add new children
                    tableRoot.appendChild(tooltipBody);
                }

                const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

                // Display, position, and set styles for font
                tooltipEl.style.opacity = 1;
                tooltipEl.style.left = positionX + tooltip.caretX + "px";
                tooltipEl.style.top = positionY + tooltip.caretY + "px";
                tooltipEl.style.font = tooltip.options.bodyFont.string;
                tooltipEl.style.padding =
                    tooltip.options.padding + "px " + tooltip.options.padding + "px";
            };

            const options = {
                responsive: false,
                elements: {
                    arc: {
                        borderWidth: 0, // No space beetween pie parts
                    },
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                    title: {
                        display: true,
                        text: titre.split("\n")[0],
                        padding: 2,
                    },
                    subtitle: {
                        display: titre.includes("\n"),
                        text: titre.split("\n")[1],
                        font: { weight: "bold" },
                        padding: 2,
                    },
                    tooltip: {
                        enabled: false,
                        external: externalTooltipHandler,
                    },
                    datalabels: {
                        align: "end",
                        offset: -5,
                        formatter: (value, ctx) => {
                            let sum = 0;
                            let dataArr = ctx.chart.data.datasets[0].data;
                            dataArr.forEach((data) => {
                                sum += data;
                            });
                            let percentage = (value * 100) / sum;
                            if (percentage < configData.thresholdCircularDiagram) {
                                return "";
                            }
                            return percentage.toFixed(1) + "%";
                        },
                        color: function (context) {
                            var index = context.dataIndex;
                            return isLightColor(data.datasets[0].backgroundColor[index])
                                ? "#222"
                                : "#fff";
                        },
                        font: {
                            weight: "bold",
                            size: 11,
                        },
                    },
                },
            };

            if (data.type === "pie") {
                chart = (
                    <div>
                        <Pie
                            plugins={[ChartDataLabels]}
                            id={chartId}
                            width={configData.height_chart}
                            height={configData.height_chart}
                            options={options}
                            data={data}
                            legend={this.state.legend}
                        />
                    </div>
                );
            } else {
                function _filterOnlyAvailable(objects, labels) {
                    let datasetWithOnlyAvailable = [];
                    for (let i in objects) {
                        if (labels[i] !== "indisponible") {
                            datasetWithOnlyAvailable.push(objects[i]);
                        }
                    }
                    return datasetWithOnlyAvailable;
                }

                let dataWithOnlyAvailable = JSON.parse(JSON.stringify(data));
                dataWithOnlyAvailable.datasets[0].data = _filterOnlyAvailable(
                    data.datasets[0].data,
                    data.labels
                );
                dataWithOnlyAvailable.datasets[0].backgroundColor =
                    _filterOnlyAvailable(data.datasets[0].backgroundColor, data.labels);
                dataWithOnlyAvailable.datasets[0].hoverBackgroundColor =
                    _filterOnlyAvailable(
                        data.datasets[0].hoverBackgroundColor,
                        data.labels
                    );
                dataWithOnlyAvailable.datasets[0].origBackgroundColor =
                    _filterOnlyAvailable(
                        data.datasets[0].origBackgroundColor,
                        data.labels
                    );
                dataWithOnlyAvailable.labels = _filterOnlyAvailable(
                    data.labels,
                    data.labels
                );

                let titresLegende =
                    this.props.parentApi.controller.analysisManager.getLegendTitles(
                        this.state.analysis
                    );

                let axes = {
                    "x-axis-0": {
                        display: false,
                    },
                    y: {
                        title: {
                            display: true,
                            text: titresLegende["titre_legende"],
                            font: {
                                size: 10,
                            },
                        },
                    },
                };
                options.scales = axes;
                chart = (
                    <div onMouseLeave={() => this.desactiverInfoBullesGraphiques()}>
                        <Bar
                            id={chartId}
                            options={options}
                            data={dataWithOnlyAvailable}
                            legend={this.state.legend}
                            whidh={200}
                            height={200}
                        />
                    </div>
                );
            }
            /**
             * management of the confidentiality of circular diagrams:
             *  Case A: we remove the interactivity between pie charts, but we still update the map
             *  Case B: the energy and usage pie charts are deleted
             *  Case C: the sector and usage pie charts are deleted
             *  Case D: we delete all the pie charts
             */
            // TODO: passage table here?
            if (
                data.confid.charts === "D" ||
                (data.confid.charts === "B" &&
                    ["energie", "facture_energie"].includes(data.name)) ||
                (data.confid.charts === "B" &&
                    ["usage", "facture_usage"].includes(data.name)) ||
                (data.confid.charts === "C" &&
                    ["secteur", "facture_secteur"].includes(data.name)) ||
                (data.confid.charts === "C" &&
                    ["usage", "facture_usage"].includes(data.name)) ||
                (this.props.parentApi.data.confidFilteredPieId !== undefined &&
                    this.props.parentApi.data.confidFilteredPieId !== this.state.id) // In case of A confidentiality, if the user has clicked on a chart, we must hide the others
            ) {
                let titleChart = "";
                if (["energie", "facture_energie"].includes(data.name)) {
                    titleChart = (
                        <label className="title-chart">Par type d'énergie</label>
                    );
                }
                if (["usage", "facture_usage"].includes(data.name)) {
                    titleChart = <label className="title-chart">Par usage</label>;
                }
                // TODO: passage table here?
                if (["secteur", "facture_secteur"].includes(data.name)) {
                    titleChart = <label className="title-chart">Par secteur</label>;
                }
                chart = (
                    <div className="confid-chart">
                        {titleChart}Données confidentielles
                    </div>
                );
                isConfid = true;
            }
            if (!isConfid) {
                maxLargeurDiagramme = " diagramme-carto";
            }
            legendeFinale = this.buildLegend(data, isConfid);
        }

        if (chartObj.state.data.type === "hbar") {
            const labelEnergieTotale = "Consommation d'énergie finale totale";
            width = 700;
            // Get max value from data
            let maxValue = 0;

            // We have to rebuild the dataset (stacked + one more bar for total)
            let datasets = [];
            for (let d in data.datasets[0].data) {
                let dataset = {};
                if (this.state.data.labels[d] !== "indisponible") {
                    dataset.label = this.state.data.labels[d];
                    dataset.data = [];
                    dataset.data.push(this.state.data.datasets[0].data[d]);
                    dataset.data.push(0); // Because we need to separate the bar
                    maxValue += this.state.data.datasets[0].data[d];
                    dataset.backgroundColor = data.datasets[0].backgroundColor[d];
                    dataset.xAxisID = "x-axis-2";
                    datasets.push(dataset);
                }
            }
            // We add the dividor
            let datasetDividor = {};
            datasetDividor.label = labelEnergieTotale;
            datasetDividor.data = [];
            datasetDividor.data.push(0); // Because we need to separate the bar
            datasetDividor.data.push(chartObj.state.meta.divider);
            if (maxValue < chartObj.state.meta.divider) {
                maxValue = chartObj.state.meta.divider;
            }
            datasetDividor.backgroundColor = "#c00000";
            datasetDividor.xAxisID = "x-axis-2";
            datasets.push(datasetDividor);
            let hbarChartData = {
                labels: ["Production EnR (GWh)", "Consommation d'énergie finale (GWh)"],
                datasets: datasets,
            };

            let formatValueToolip = function (tooltipItems, data) {
                return tooltipItems.dataset.label + " : " + tooltipItems.raw;
            };

            const options = {
                tooltips: {
                    mode: "index",
                    intersect: false,
                    callbacks: {
                        title: formatValueToolip,
                    },
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                    title: {
                        display: true,
                        text: this.state.data.titre + zoneInfos,
                    },
                },
                responsive: false,
                indexAxis: "y",
                scales: {
                    "x-axis-2": {
                        type: "linear",
                        stacked: true,
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                        },
                        afterBuildTicks: function (chartTicks) {
                            chartTicks.ticks.pop();
                        },
                    },
                    "x-axis-1": {
                        type: "linear",
                        stacked: true,
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                            max: maxValue,
                        },
                        display: false,
                    },
                    y: {
                        stacked: true,
                    },
                },
            };
            chart = (
                <Bar
                    id={chartId}
                    ref={chartId}
                    width={width}
                    height={configData.height_chart}
                    options={options}
                    data={hbarChartData}
                    legend={this.state.legend}
                />
            );
            legendeFinale = this.buildLegend(data, false);
        }

        if (data.type === "line") {
            if (
                data.data_type === "climat_gel" ||
                data.data_type === "climat_temperature" ||
                data.data_type === "climat_precipitation"
            ) {
                let datasets = {};
                let nomStation = this.props.parentApi.data.stationMeteoName;
                let altitudeStation = this.props.parentApi.data.stationAltitude;
                let data_name = this.state.meta.data_name;
                if (data.titre === "par_mois") {
                    /**
                     * This function is used to browse the list of stations and retrieve the data of the selected station
                     * Each station is an Object which contains other object of type (Key,value) => "nomStion": { annee : [{val, libelle_mois},{val, libelle_mois}], annee : [{val, libelle_mois},{val, libelle_mois}], ....}
                     * Example of data by station from 1962 until 2020 :
                     * "Ambérieu-en-Bugey": { 1962: [ { valeur: 0, libelle_mois: "août" },{ valeur: 8, libelle_mois: "avril" }, ..., { valeur: 25, libelle_mois: "décembre" }],
                     *                        1963: [ { valeur: 0, libelle_mois: "août" },{ valeur: 8, libelle_mois: "avril" }, ..., { valeur: 25, libelle_mois: "décembre" }],
                     *                            .......
                     *                        2020: [ { valeur: 0, libelle_mois: "août" },{ valeur: 8, libelle_mois: "avril" }, ..., { valeur: 25, libelle_mois: "décembre" }]}
                     * Then, loop through the data for that station year by year and group values by season for each year
                     * and finally restructure this data into a new datasets list:
                     * datasets = { annee : {ete:[5,3,4], hiver: [2,0,1], automne : [12,3,8], printemps : [2,0,0]},
                     *              annee2 :{ete:[5,3,4], hiver: [2,0,1], automne : [12,3,8], printemps : [2,0,0]}
                     *             }
                     */
                    for (let d in Object.keys(data.datasets[0].data[0])) {
                        if (Object.keys(data.datasets[0].data[0])[d] === nomStation) {
                            for (
                                let i = 1;
                                i <
                                Object.keys(data.datasets[0].data[0][nomStation])
                                    .length;
                                i++
                            ) {
                                let ete = [];
                                let automne = [];
                                let hiver = [];
                                let printems = [];
                                if (data.data_type === "climat_gel") {
                                    ete = 0;
                                    automne = 0;
                                    hiver = 0;
                                    printems = 0;
                                }

                                let annee = Object.keys(
                                    data.datasets[0].data[0][nomStation]
                                )[i];

                                datasets[annee] = [];

                                let annee_precedente = Object.keys(
                                    data.datasets[0].data[0][nomStation]
                                )[i - 1];

                                for (let j in data.datasets[0].data[0][nomStation][
                                    annee
                                ]) {
                                    let mois =
                                        data.datasets[0].data[0][nomStation][annee][j]
                                            .libelle_mois;
                                    let valeur =
                                        data.datasets[0].data[0][nomStation][annee][j]
                                            .valeur;
                                    let mois_decembre, valeur_mois_decembre;
                                    if (
                                        data.datasets[0].data[0][nomStation][
                                            annee_precedente
                                        ][j]
                                    ) {
                                        mois_decembre =
                                            data.datasets[0].data[0][nomStation][
                                                annee - 1
                                            ][j].libelle_mois;
                                        valeur_mois_decembre =
                                            data.datasets[0].data[0][nomStation][
                                                annee - 1
                                            ][j].valeur;
                                    }

                                    if (data.data_type === "climat_gel") {
                                        if (["mars", "avril", "mai"].includes(mois)) {
                                            printems += valeur;
                                        } else if (
                                            ["juin", "juillet", "août"].includes(mois)
                                        ) {
                                            ete += valeur;
                                        } else if (
                                            [
                                                "septembre",
                                                "octobre",
                                                "novembre",
                                            ].includes(mois)
                                        ) {
                                            automne += valeur;
                                        } else if (
                                            ["janvier", "février"].includes(mois)
                                        ) {
                                            hiver += valeur;
                                        } else if (mois_decembre === "décembre") {
                                            hiver += valeur_mois_decembre;
                                        }
                                    } else {
                                        if (["mars", "avril", "mai"].includes(mois)) {
                                            printems.push(valeur);
                                        } else if (
                                            ["juin", "juillet", "août"].includes(mois)
                                        ) {
                                            ete.push(valeur);
                                        } else if (
                                            [
                                                "septembre",
                                                "octobre",
                                                "novembre",
                                            ].includes(mois)
                                        ) {
                                            automne.push(valeur);
                                        } else if (
                                            ["janvier", "février"].includes(mois)
                                        ) {
                                            hiver.push(valeur);
                                        } else if (mois_decembre === "décembre") {
                                            hiver.push(valeur_mois_decembre);
                                        }
                                    }
                                }
                                if (data.data_type === "climat_gel") {
                                    if (
                                        data.datasets[0].data[0][nomStation][annee]
                                            .length !== 0
                                    ) {
                                        datasets[annee].push({
                                            ete: [ete],
                                            automne: [automne],
                                            hiver: [hiver],
                                            printemps: [printems],
                                        });
                                    } else {
                                        datasets[annee].push({
                                            ete: [],
                                            automne: [],
                                            hiver: [],
                                            printemps: [],
                                        });
                                    }
                                } else {
                                    if (
                                        i <
                                        Object.keys(
                                            data.datasets[0].data[0][nomStation]
                                        ).length -
                                            1
                                    ) {
                                        if (hiver.length < 3) {
                                            hiver = [];
                                        }
                                        if (automne.length < 3) {
                                            automne = [];
                                        }
                                        if (ete.length < 3) {
                                            ete = [];
                                        }
                                        if (ete.length < 3) {
                                            ete = [];
                                        }
                                        datasets[annee].push({
                                            ete: ete,
                                            automne: automne,
                                            hiver: hiver,
                                            printemps: printems,
                                        });
                                    }
                                }
                            }
                            break;
                        }
                    }
                } else if (data.titre === "par_saison") {
                    for (let d in Object.keys(data.datasets[0].data[0])) {
                        if (Object.keys(data.datasets[0].data[0])[d] === nomStation) {
                            for (
                                let i = 1;
                                i <
                                Object.keys(data.datasets[0].data[0][nomStation])
                                    .length;
                                i++
                            ) {
                                let ete = [];
                                let automne = [];
                                let hiver = [];
                                let printems = [];
                                if (data.data_type === "climat_gel") {
                                    ete = 0;
                                    automne = 0;
                                    hiver = 0;
                                    printems = 0;
                                }

                                let annee = Object.keys(
                                    data.datasets[0].data[0][nomStation]
                                )[i];

                                datasets[annee] = [];

                                for (let j in data.datasets[0].data[0][nomStation][
                                    annee
                                ]) {
                                    let saison =
                                        data.datasets[0].data[0][nomStation][annee][j]
                                            .saison;
                                    let valeur =
                                        data.datasets[0].data[0][nomStation][annee][j]
                                            .valeur;

                                    if (data.data_type === "climat_gel") {
                                        if (["printemps"].includes(saison)) {
                                            printems += valeur;
                                        } else if (["ete"].includes(saison)) {
                                            ete += valeur;
                                        } else if (["automne"].includes(saison)) {
                                            automne += valeur;
                                        } else if (["hiver"].includes(saison)) {
                                            hiver += valeur;
                                        }
                                    } else {
                                        if (["printemps"].includes(saison)) {
                                            printems.push(valeur);
                                        } else if (["ete"].includes(saison)) {
                                            ete.push(valeur);
                                        } else if (["automne"].includes(saison)) {
                                            automne.push(valeur);
                                        } else if (["hiver"].includes(saison)) {
                                            hiver.push(valeur);
                                        }
                                    }
                                }
                                if (data.data_type === "climat_gel") {
                                    if (
                                        data.datasets[0].data[0][nomStation][annee]
                                            .length !== 0
                                    ) {
                                        datasets[annee].push({
                                            ete: [ete],
                                            automne: [automne],
                                            hiver: [hiver],
                                            printemps: [printems],
                                        });
                                    } else {
                                        datasets[annee].push({
                                            ete: [],
                                            automne: [],
                                            hiver: [],
                                            printemps: [],
                                        });
                                    }
                                } else {
                                    datasets[annee].push({
                                        ete: ete,
                                        automne: automne,
                                        hiver: hiver,
                                        printemps: printems,
                                    });
                                }
                            }
                            break;
                        }
                    }
                }

                let dataSetLineChart = {};
                dataSetLineChart["ete"] = [];
                dataSetLineChart["hiver"] = [];
                dataSetLineChart["automne"] = [];
                dataSetLineChart["printemps"] = [];
                /**
                 * This function is used to browse datasets and calculate the annual average per season
                 * dataSetLineChart is a list that contains all the annual averages by seasons:
                 * dataSetLineChart = {ete : [0,1,5,2,0,3,.........],
                 *                     hiver : [9,20,5,6,7,3,.........],
                 *                     printemps : [4,2,5,6,7,3,.........],
                 *                     automne : [6,2,5,6,7,3,.........]}
                 */
                for (let i in Object.keys(datasets)) {
                    for (let j in Object.values(datasets)[i]) {
                        let saisons = Object.values(datasets)[i][j];
                        for (let k in Object.keys(saisons)) {
                            let saison = Object.keys(saisons)[k];
                            let valueSaison = Object.values(saisons)[k];
                            if (saison === "ete") {
                                dataSetLineChart["ete"].push(
                                    this.calculer_avg(valueSaison)
                                );
                            } else if (saison === "printemps") {
                                dataSetLineChart["printemps"].push(
                                    this.calculer_avg(valueSaison)
                                );
                            } else if (saison === "hiver") {
                                dataSetLineChart["hiver"].push(
                                    this.calculer_avg(valueSaison)
                                );
                            } else if (saison === "automne") {
                                dataSetLineChart["automne"].push(
                                    this.calculer_avg(valueSaison)
                                );
                            }
                        }
                    }
                }

                // This function identifies the first year of data availability
                // initiate the first year by the year of the beginning of graph
                let first_year = Object.keys(datasets)[0];
                // go through the list of years and values by seasons
                for (let i in Object.keys(datasets)) {
                    first_year = Object.keys(datasets)[i];
                    let cpt = 0;
                    for (let j in Object.values(datasets)[i]) {
                        let saisons = Object.values(datasets)[i][j];
                        for (let k in Object.keys(saisons)) {
                            let valueSaison = Object.values(saisons)[k];
                            if (valueSaison.length !== 0) cpt++;
                        }
                    }
                    // we only need one value to define the first year of data availability
                    if (cpt > 0) break;
                }

                // This function identifies the last year of data availability
                // initiate the last year by the last year of the graph
                let last_year = Object.keys(datasets)[-1];
                for (let i = Object.keys(datasets).length - 1; i >= 0; i--) {
                    last_year = Object.keys(datasets)[i];
                    let cpt = 0;
                    for (let j in Object.values(datasets)[i]) {
                        let saisons = Object.values(datasets)[i][j];
                        for (let k in Object.keys(saisons)) {
                            let valueSaison = Object.values(saisons)[k];
                            if (valueSaison.length !== 0) cpt++;
                        }
                    }
                    // we only need one value to define the latest year of data availability
                    if (cpt > 0) break;
                }

                const options = {
                    whidh: 350,
                    maintainAspectRatio: false,
                    responsive: true,
                    plugins: {
                        tooltip: {
                            mode: "point",
                            intersect: false,
                            enabled: false,
                        },
                        legend: {
                            position: "bottom",
                            labels: {
                                boxWidth: 10,
                            },
                        },
                        title: {
                            display: true,
                            text:
                                this.props.parentApi.data.analysisMeta
                                    .titreGraphiqueIndicateursClimat +
                                " entre " +
                                first_year +
                                " et " +
                                last_year +
                                " à " +
                                nomStation +
                                " (altitude " +
                                altitudeStation +
                                " m) ",
                            padding: 4,
                            fontColor: "black",
                            fontSize: 12,
                        },
                    },
                    scales: {
                        xAxes: {
                            gridLines: {
                                display: false,
                            },
                        },
                        yAxes: {
                            gridLines: {
                                display: true,
                                drawBorder: false,
                            },
                            scaleLabel: {
                                display: true,
                                labelString: data_name + " en " + data.unit,
                                fontSize: 10,
                            },
                        },
                    },
                };

                //This function allows you to browse dataSetLineChart and create the data to display in the chart with the right parameters
                // It assigns the right colors and labels according to the seasons
                let dataFinal = [];
                for (let i in Object.keys(dataSetLineChart)) {
                    let saison = Object.keys(dataSetLineChart)[i];
                    let dataParSaison = Object.values(dataSetLineChart)[i];
                    dataParSaison.unshift(undefined); // the data is calculated from n+1, so we insert an empty box to display the start date and shift the data by 1 year
                    let label, colorHex;
                    switch (saison) {
                        case "ete":
                            label = "Moyennes estivales";
                            colorHex = "#E73836";
                            break;
                        case "hiver":
                            label = "Moyennes hivernales";
                            colorHex = "#009CDD";
                            break;
                        case "automne":
                            label = "Moyennes automnales";
                            colorHex = "#F4B025";
                            break;
                        case "printemps":
                            label = "Moyennes printannières";
                            colorHex = "#7ba428";
                            break;
                        default:
                            break;
                    }

                    dataFinal.push({
                        label: label,
                        data: dataParSaison,
                        fill: false,
                        borderColor: colorHex,
                        tension: 0.1,
                    });
                }

                const dataLineChart = {
                    labels: data.labels,
                    datasets: dataFinal,
                };
                chart = (
                    <Line
                        data={dataLineChart}
                        width={600}
                        height={configData.height_chart}
                        options={options}
                    />
                );
            } else if (data.data_type === "climat_enneigement") {
                let datasets = {};
                let debut_saison = [];
                let mi_saison = [];
                let fin_saison = [];
                let nomStation = this.props.parentApi.data.stationMeteoName;
                let altitudeStation = this.props.parentApi.data.stationAltitude;

                /**
                 * This function is used to browse the list of stations and retrieve the data of the selected station
                 * Each station is an object which contains other objects of type (Key,value) => "nomStion": { annee : [{val, libelle_mois},{val, libelle_mois}], annee : [{val, libelle_mois},{val, libelle_mois}], ....}
                 * Example of data by station from 1962 until 2020:
                 * "Autrans: { 1962: [ { valeur: 0, libelle_mois: "janvier", jour_mois:1 },{ valeur: 8, libelle_mois: "janvier", jour_mois:2 }, ..., { valeur: 25, libelle_mois: "janvier", jour_mois:10 }],
                 *                        1963: [ { valeur: 0, libelle_mois: "mars", jour_mois:2 },{ valeur: 8, libelle_mois: "mars", jour_mois:4 }, ..., { valeur: 25, libelle_mois: "mars", jour_mois:11 }],
                 *                            ......}]}
                 * Then iterate over the data for that station year by year and aggregate values by season for each year
                 * And finally restructure this data into a new datasets list:
                 * datasets = { annee : {debut_saison: [2,0,1], mi_saison : [12,3,8], fin_saison : [2,0,0]},
                 *              annee2 :{debut_saison: [2,0,1], mi_saison : [12,3,8], fin_sison : [2,0,0]}
                 *             }
                 */
                for (let d in Object.keys(data.datasets[0].data[0])) {
                    if (Object.keys(data.datasets[0].data[0])[d] === nomStation) {
                        for (
                            let i = 1;
                            i <
                            Object.keys(data.datasets[0].data[0][nomStation]).length;
                            i++
                        ) {
                            let annee = Object.keys(
                                data.datasets[0].data[0][nomStation]
                            )[i];
                            let annee_precedente = Object.keys(
                                data.datasets[0].data[0][nomStation]
                            )[i - 1];
                            datasets[annee] = [];
                            for (let j in data.datasets[0].data[0][nomStation][annee]) {
                                let mois =
                                    data.datasets[0].data[0][nomStation][annee][j]
                                        .libelle_mois;
                                let jour_mois =
                                    data.datasets[0].data[0][nomStation][annee][j]
                                        .jour_mois;
                                let valeur =
                                    data.datasets[0].data[0][nomStation][annee][j]
                                        .valeur;
                                let mois_decembre,
                                    valeur_mois_decembre,
                                    jour_mois_decembre;
                                if (
                                    data.datasets[0].data[0][nomStation][
                                        annee_precedente
                                    ][j]
                                ) {
                                    mois_decembre =
                                        data.datasets[0].data[0][nomStation][annee - 1][
                                            j
                                        ].libelle_mois;
                                    jour_mois_decembre =
                                        data.datasets[0].data[0][nomStation][annee - 1][
                                            j
                                        ].valeur;
                                    valeur_mois_decembre =
                                        data.datasets[0].data[0][nomStation][annee - 1][
                                            j
                                        ].valeur;
                                }
                                if (["janvier"].includes(mois) && jour_mois <= 10) {
                                    debut_saison.push(valeur);
                                } else if (
                                    mois_decembre === "décembre" &&
                                    jour_mois_decembre >= 20
                                ) {
                                    debut_saison.push(valeur_mois_decembre);
                                } else if (
                                    (["janvier"].includes(mois) && jour_mois >= 11) ||
                                    (["février"].includes(mois) && jour_mois <= 10)
                                ) {
                                    mi_saison.push(valeur);
                                } else if (
                                    (["février"].includes(mois) && jour_mois >= 11) ||
                                    (["mars"].includes(mois) && jour_mois <= 20)
                                ) {
                                    fin_saison.push(valeur);
                                }
                            }
                            datasets[annee].push({
                                debut_saison: debut_saison,
                                mi_saison: mi_saison,
                                fin_saison: fin_saison,
                            });
                            debut_saison = [];
                            mi_saison = [];
                            fin_saison = [];
                        }
                        break;
                    }
                }
                let dataSetLineChart = {};
                dataSetLineChart["debut_saison"] = [];
                dataSetLineChart["mi_saison"] = [];
                dataSetLineChart["fin_saison"] = [];
                /**
                 * This function is used to browse datasets and calculate the annual average by season
                 * dataSetLineChart is a list that contains all the annual averages per season:
                 * dataSetLineChart = {debut_saison : [0,1,5,2,0,3,.........],
                 *                     mi_saison : [9,20,5,6,7,3,.........],
                 *                     fin_saison : [4,2,5,6,7,3,.........]}
                 **/
                for (let i in Object.keys(datasets)) {
                    for (let j in Object.values(datasets)[i]) {
                        let saisons = Object.values(datasets)[i][j];
                        for (let k in Object.keys(saisons)) {
                            let saison = Object.keys(saisons)[k];
                            let valueSaison = Object.values(saisons)[k];
                            if (saison === "debut_saison") {
                                dataSetLineChart["debut_saison"].push(
                                    this.calculer_avg(valueSaison)
                                );
                            } else if (saison === "mi_saison") {
                                dataSetLineChart["mi_saison"].push(
                                    this.calculer_avg(valueSaison)
                                );
                            } else if (saison === "fin_saison") {
                                dataSetLineChart["fin_saison"].push(
                                    this.calculer_avg(valueSaison)
                                );
                            }
                        }
                    }
                }

                // This function identifies the first year of data availability
                // Initiate the first year by the year of the beginning of graph
                let first_year = Object.keys(datasets)[0];
                // Go through the list of years and values by season
                for (let i in Object.keys(datasets)) {
                    first_year = Object.keys(datasets)[i];
                    let cpt = 0;
                    for (let j in Object.values(datasets)[i]) {
                        let saisons = Object.values(datasets)[i][j];
                        for (let k in Object.keys(saisons)) {
                            let valueSaison = Object.values(saisons)[k];
                            if (valueSaison.length !== 0) cpt++;
                        }
                    }
                    // We only need one value to define the first year of data availability
                    if (cpt > 0) break;
                }

                // This function identifies the last year of data availability
                // Initiate the last year with the last year of the graph
                let last_year = Object.keys(datasets)[-1];
                for (let i = Object.keys(datasets).length - 1; i >= 0; i--) {
                    last_year = Object.keys(datasets)[i];
                    let cpt = 0;
                    for (let j in Object.values(datasets)[i]) {
                        let saisons = Object.values(datasets)[i][j];
                        for (let k in Object.keys(saisons)) {
                            let valueSaison = Object.values(saisons)[k];
                            if (valueSaison.length !== 0) cpt++;
                        }
                    }
                    // we only need one value to define the last year of data availability
                    if (cpt > 0) break;
                }

                const options = {
                    whidh: 350,
                    maintainAspectRatio: false,
                    responsive: true,
                    plugins: {
                        title: {
                            display: true,
                            text:
                                this.props.parentApi.data.analysisMeta
                                    .titreGraphiqueIndicateursClimat +
                                " entre " +
                                first_year +
                                " et " +
                                last_year +
                                " à " +
                                nomStation +
                                " (altitude " +
                                altitudeStation +
                                " m) ",
                            padding: 4,
                            fontColor: "black",
                            fontSize: 12,
                        },
                        legend: {
                            position: "bottom",
                            labels: {
                                boxWidth: 10,
                            },
                        },
                        tooltip: {
                            enabled: false,
                        },
                    },
                    scales: {
                        xAxes: {
                            gridLines: {
                                display: false,
                            },
                        },
                        yAxes: {
                            gridLines: {
                                display: true,
                                drawBorder: false,
                            },
                            scaleLabel: {
                                display: true,
                                labelString: "hauteur de neige en " + data.unit,
                                fontSize: 10,
                            },
                        },
                    },
                };

                // This function browse dataSetLineChart and create the data to display in the chart with the right parameters
                // It assigns the right colors and labels according to the season
                let dataFinal = [];
                for (let i in Object.keys(dataSetLineChart)) {
                    let saison = Object.keys(dataSetLineChart)[i];
                    let dataParSaison = Object.values(dataSetLineChart)[i];
                    dataParSaison.unshift(undefined); // Data is calculated from n+1, so we insert an empty box to display the start date and shift the data by 1 year
                    let label, colorHex;
                    switch (saison) {
                        case "debut_saison":
                            label = "Début saison (20déc-10jan)";
                            colorHex = "#009CDD";
                            break;
                        case "mi_saison":
                            label = "Mi-saison (11jan-10fév)";
                            colorHex = "#575756";
                            break;
                        case "fin_saison":
                            label = "Fin saison (11fév-20mars)";
                            colorHex = "#F4B025";
                            break;
                        default:
                            break;
                    }

                    dataFinal.push({
                        label: label,
                        data: dataParSaison,
                        fill: false,
                        borderColor: colorHex,
                        tension: 0.1,
                    });
                }
                const dataLineChart = {
                    labels: data.labels,
                    datasets: dataFinal,
                };
                chart = (
                    <Line
                        data={dataLineChart}
                        width={600}
                        height={configData.height_chart}
                        options={options}
                    />
                );
            }
        }
        if (data.titre === "par_ann") {
            // For bar and line chart for heat data
            let datasets = [];
            let nomStation = this.props.parentApi.data.stationMeteoName;
            let altitudeStation = this.props.parentApi.data.stationAltitude;
            for (let d in Object.keys(data.datasets[0].data[0])) {
                if (Object.keys(data.datasets[0].data[0])[d] === nomStation) {
                    for (let i in Object.keys(data.datasets[0].data[0][nomStation])) {
                        let valeur;
                        for (let j in Object.values(
                            data.datasets[0].data[0][nomStation]
                        )[i]) {
                            valeur = Object.values(
                                data.datasets[0].data[0][nomStation]
                            )[i][j].valeur;
                        }
                        datasets.push(valeur);
                    }
                    break;
                }
            }
            // This function identifies the first year of data availability
            // initiate the first year by the start year of graph
            let first_year = data.labels[0];
            for (let i in Object.values(datasets)) {
                first_year = data.labels[i];
                if (Object.values(datasets)[i] !== undefined) break;
            }

            // This function identifies the latest year of data availability
            // initiate the last year with the last year of the graph
            let last_year = data.labels[data.labels.length - 1];
            for (let i = Object.values(datasets).length - 1; i >= 0; i--) {
                last_year = data.labels[i];
                if (Object.values(datasets)[i] !== undefined) break;
            }

            const options = {
                whidh: 350,
                maintainAspectRatio: false,
                responsive: true,
                plugins: {
                    title: {
                        display: true,
                        text:
                            this.props.parentApi.data.analysisMeta
                                .titreGraphiqueIndicateursClimat +
                            " entre " +
                            first_year +
                            " et " +
                            last_year +
                            " à " +
                            nomStation +
                            " (altitude " +
                            altitudeStation +
                            " m) ",
                        padding: 4,
                        fontColor: "black",
                        fontSize: 12,
                    },
                    legend: {
                        position: "bottom",
                        labels: {
                            boxWidth: 10,
                        },
                    },
                    tooltip: {
                        enabled: false,
                    },
                },
                scales: {
                    xAxes: {
                        gridLines: {
                            display: false,
                        },
                    },
                    yAxes: {
                        gridLines: {
                            display: true,
                            drawBorder: false,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: "Nombre de jour",
                            fontSize: 10,
                        },
                    },
                },
            };
            let dd = [];

            if (data.data_type === "climat_chaleur") {
                /**
                 * calculate the true size of the values for the selected station
                 * to know if we can calculate the moving average (moyenne mobile)
                 */
                let length = 0;
                let moyenneMobile = [];
                for (let i in Object.values(datasets)) {
                    if (Object.values(datasets)[i] === undefined) {
                        continue;
                    } else {
                        length += 1;
                    }
                }

                /**
                 * Calculate the 10-year moving average
                 */
                let tab = Object.values(datasets);
                if (length >= 10) {
                    for (let j = 0; j < Object.values(datasets).length; j++) {
                        moyenneMobile.push(
                            this.calculer_avg([
                                tab[j - 4],
                                tab[j - 3],
                                tab[j - 2],
                                tab[j - 1],
                                tab[j],
                                tab[j + 1],
                                tab[j + 2],
                                tab[j + 3],
                                tab[j + 4],
                                tab[j + 5],
                            ])
                        );
                    }
                }

                dd = [
                    {
                        label: "Nb journées estivales",
                        data: Object.values(datasets),
                        borderColor: "red",
                        backgroundColor: "red",
                        order: 1,
                    },
                    {
                        label: "Moyenne mobile sur 10 ans",
                        data: moyenneMobile,
                        borderColor: "orange",
                        type: "line",
                        fill: false,
                        tension: 0.1,
                        order: 0,
                    },
                ];
            } else if (data.data_type === "climat_gel") {
                dd = [
                    {
                        label: "Nb journées annuelles",
                        data: Object.values(datasets),
                        borderColor: "#009CDD",
                        backgroundColor: "#009CDD",
                        order: 1,
                    },
                ];
            }

            const dataBarLineChart = {
                labels: data.labels,
                datasets: dd,
            };
            chart = (
                <Bar
                    data={dataBarLineChart}
                    width={600}
                    height={configData.height_chart}
                    options={options}
                />
            );
        }
        return (
            <div className={"charts charts-" + this.state.id + maxLargeurDiagramme}>
                <div className={"charts-legende " + classeRadar}>
                    {donneesIndisponibles}
                    {chart}
                    <div className="choisir-methode">{legendeFinale}</div>
                    {label}
                </div>
            </div>
        );
    }
}

export default Charts;
