/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import { Link } from "react-router-dom";

import config from "../../settings.js";
import { consulteAutrePage } from "../../utils.js";
import SEO from "../SEO.js";
import Loading from "../../wrappers/Loading.js";

/**
 * This component is used to to display the legal notices relating to the selected region
 */
class LegalInformation extends React.Component {
    componentDidMount() {
        let region = this.props.parentApi.data.region;
        if (this.props.parentApi?.controller?.gestionSuiviConsultations) {
            let idUtilisateur =
                this.props.parentApi.controller.gestionSuiviConsultations.idUtilisateur;
            let url = config.api_consultation_autre_page_url;
            consulteAutrePage(url, region, idUtilisateur, "Mentions légales", "");
        }
    }

    render() {
        if (!this.props.parentApi.data.settings) {
            return (
                <Loading
                    isLoaded={
                        this.props.parentApi.data.settings &&
                        Object.values(this.props.parentApi.data.settings).length > 0
                    }
                />
            );
        }

        let mentionsLegales = "";
        let retourCarte = !this.props.hideBackToMap && (
            <div>
                <Link
                    className="back-to-map"
                    to={"/" + this.props.parentApi.data?.urlPartageable}
                >
                    <button
                        type="button"
                        className="close close-big"
                        data-dismiss="alert"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Link>
            </div>
        );

        const namesByRegion = {
            occitanie: {
                publication: {
                    director: (
                        <>
                            <pre>
                                SPL Agence Régionale Énergie Climat Occitanie
                                <br />
                                55 avenue Louis Bréguet
                                <br />
                                31400 Toulouse
                            </pre>
                            Le Directeur de la Publication est le Directeur Général de
                            l’AREC Occitanie
                        </>
                    ),
                    structure: "l’AREC Occitanie",
                },
            },
            national: {
                publication: {
                    director: (
                        <>
                            <pre>
                                Auvergne-Rhône-Alpes Énergie Environnement <br />
                                Le Stratège-Péri <br />
                                18, rue Gabriel Péri <br />
                                69100 Villeurbanne
                            </pre>
                            Le Directeur de la Publication est Monsieur Didier CHATEAU,
                            Directeur d'Auvergne-Rhône-Alpes Énergie Environnement
                        </>
                    ),
                    structure: "Auvergne-Rhône-Alpes Énergie Environnement",
                },
                redaction: {
                    director:
                        "Monsieur Pierrick YALAMAS, Directeur Intelligence territoriale et Observatoires au sein d’Auvergne-Rhône-Alpes Énergie Environnement",
                    structure: "Auvergne-Rhône-Alpes Énergie Environnement.",
                },
            },
            "auvergne-rhone-alpes": {
                publication: {
                    director: (
                        <>
                            <pre>
                                Auvergne-Rhône-Alpes Énergie Environnement <br />
                                Le Stratège-Péri <br />
                                18, rue Gabriel Péri <br />
                                69100 Villeurbanne
                            </pre>
                            Le Directeur de la Publication est Monsieur Didier CHATEAU,
                            Directeur d'Auvergne-Rhône-Alpes Énergie Environnement
                        </>
                    ),
                    structure: "Auvergne-Rhône-Alpes Énergie Environnement",
                },
                redaction: {
                    director:
                        "Monsieur Pierrick YALAMAS, Directeur Intelligence territoriale et Observatoires au sein d’Auvergne-Rhône-Alpes Énergie Environnement",
                    structure: "Auvergne-Rhône-Alpes Énergie Environnement.",
                },
            },
            "nouvelle-aquitaine": {
                publication: {
                    director: (
                        <>
                            <pre>
                                AREC Nouvelle-Aquitaine
                                <br />
                                Direction : Amandine LOEB
                                <br />
                                Association loi 1901
                                <br />
                                Siège : 60, rue Jean Jaurès CS 90452 – 86011 POITIERS
                                Cedex
                            </pre>
                            Le Directeur de la Publication est Madame Amandine LOËB,
                            Directrice de l’AREC Nouvelle-Aquitaine
                        </>
                    ),
                    structure: "l’AREC Nouvelle-Aquitaine",
                },
                redaction: {
                    director:
                        "Madame Camille ROMARY, Chargée d'études, et Monsieur Rafaël BUNALES, Directeur adjoint à l’AREC Nouvelle-Aquitaine",
                    structure: "l’AREC Nouvelle-Aquitaine",
                },
            },
            bretagne: {
                publication: {
                    director: (
                        <>
                            <pre>
                                Observatoire de l'environnement en Bretagne
                                <br />
                                47 avenue des Pays-Bas
                                <br />
                                35000 Rennes
                            </pre>
                            Le Directeur de la Publication est Monsieur Ronan LUCAS,
                            Directeur de l’Observatoire de l’Environnement en Bretagne
                        </>
                    ),
                    structure: "l’Observatoire de l’Environnement en Bretagne",
                },
                redaction: {
                    director:
                        "Madame Aliette LACROIX, Cheffe du Pôle Énergie-Climat, et Madame Alice LE FLAHEC, Chargée de mission data-analyst à l’Observatoire de l’Environnement en Bretagne",
                    structure: "l’Observatoire de l’Environnement en Bretagne",
                },
            },
            paysdelaloire: {
                publication: {
                    director: (
                        <>
                            <pre>
                                Association TEO <br /> Observatoire de la Transition
                                Ecologique <br /> 5 rue Edouard Nignon 44307 Nantes
                            </pre>
                            Le Directeur de la Publication est Monsieur Philippe HENRY,
                            Président de l’Observatoire de la Transition Écologique des
                            Pays de la Loire
                        </>
                    ),
                    structure: "TEO Pays de la Loire",
                },
                redaction: {
                    director:
                        "Madame Emilie GAUTHIER, Directrice de l’Observatoire de la Transition Écologique des Pays de la Loire",
                    structure: "TEO Pays de la Loire",
                },
            },
            corse: {
                publication: {
                    director: (
                        <>
                            <pre>
                                Agence d’Aménagement durable d’Urbanisme de la Corse
                                (AAUC)
                                <br />
                                5, rue Prosper Mérimée <br /> CS 40001 <br /> 20181
                                Ajaccio Cedex 1
                            </pre>
                            Le Directeur de la Publication est Monsieur Julien PAOLINI,
                            Président de l’Agence d’Aménagement durable, d’Urbanisme et
                            d’Energie de la Corse
                        </>
                    ),
                    structure: "AUE Corse",
                },
                redaction: {
                    director:
                        "Monsieur Judicaël AMBACH-ALBERTINI, Chef du Département Climat Air Energie de l’Agence d’Aménagement durable, d’Urbanisme et d’Energie de la Corse",
                    structure: "AUE Corse",
                },
            },
        };

        const regionName = this.props.parentApi.data.regionLabel;
        const contactMail = this.props.parentApi.data.settings.contact_email;
        const names = namesByRegion[this.props.parentApi.data.region] ?? null;

        mentionsLegales = (
            <div className="container-fluid main-container mentions-legales">
                <div>
                    <h1 className="title-referencement-mentions-legales">
                        Transition écologique, outil de stratégie territoriale
                        TerriSTORY {regionName}
                    </h1>
                </div>
                <div className="fluid-row" id="header">
                    <h1 className="title toc-ignore">
                        Mentions Légales et conditions générales d'utilisation
                    </h1>
                </div>
                <p>
                    <strong>
                        Conformément aux dispositions de l’article 6 III-1 de la loi
                        n°2004-575 du 21 juin 2004 pour la Confiance dans l’économie
                        numérique, nous vous informons des points suivants concernant ce
                        site :
                    </strong>
                </p>
                <h1>Propriété</h1>
                <p>
                    La totalité de ce site est soumis à la loi française, notamment en
                    ce qui concerne les droits d’auteur et de la propriété
                    intellectuelle.
                </p>
                <p>
                    Il est mis à la disposition des utilisateurs sous réserve de
                    l’acceptation des conditions et des avertissements rappelés
                    ci-dessous.
                </p>
                <p>
                    Le présent site est la propriété d'
                    <em>Auvergne-Rhône-Alpes Energie Environnement</em>, association loi
                    1901, déclarée en 1978 auprès de la Préfecture du Rhône sous le
                    numéro W691056636, dont le siège social est situé au 18 rue Gabriel
                    Péri - 69100 Villeurbanne.
                </p>
                {names?.publication ? (
                    <div>
                        Le site TerriSTORY {regionName} que vous consultez actuellement
                        est édité par : {names.publication.director}, représentant{" "}
                        {names.publication.structure}, Membre du Consortium
                        TerriSTORY-DEV, en charge du déploiement de TerriSTORY sur la
                        région {regionName}.
                    </div>
                ) : (
                    <p>
                        La personne dirigeant la publication du site n'est pas connue.
                    </p>
                )}
                {names?.redaction && (
                    <>
                        <p>
                            La personne dirigeant la rédaction du site est{" "}
                            {names.redaction.director}, représentant{" "}
                            {names.redaction.structure}, Membre du Consortium
                            TerriSTORY-DEV, en charge du déploiement de TerriSTORY sur
                            la région {regionName}.
                        </p>
                    </>
                )}
                <h1>Réalisation et hébergement du site</h1>
                <p>
                    La réalisation technique et fonctionnelle du site a été assurée par
                    le Consortium TerriSTORY-DEV.
                </p>
                <p>
                    La maintenance du site est assurée par le Coordinateur du
                    Consortium, ci-après nommé « le Coordinateur », actuellement :
                </p>
                <pre>
                    Auvergne-Rhône-Alpes Énergie Environnement <br />
                    Le Stratège-Péri <br />
                    18, rue Gabriel Péri <br />
                    69100 Villeurbanne
                </pre>
                <p>
                    L’hébergement des données est réalisé par un prestataire, ci-après
                    nommé « l’hébergeur » dans les présentes mentions légales.
                </p>
                <p>L’hébergeur est OVH sur son infrastructure “Cloud”.</p>
                <pre>
                    OVH <br />
                    2 rue Kellermann - 59100 Roubaix - France <br />
                    Numéro de téléphone : 1007
                </pre>
                <p>
                    Pour plus d’informations, reportez-vous sur le site{" "}
                    <a href="https://www.ovh.com/fr/">https://www.ovh.com/fr/</a>.
                </p>
                <h1>Conditions d’utilisation du site</h1>
                <p>
                    L’utilisateur du site reconnaît avoir pris connaissance des
                    présentes conditions d’utilisation et s’engage à les respecter. Il
                    s’engage à faire un usage strictement personnel du site et à ne pas
                    porter atteinte aux droits de propriété intellectuelle du contenu.
                </p>
                <p>
                    L’utilisateur du site reconnaît disposer de la compétence et des
                    moyens nécessaires pour accéder et utiliser ce site et reconnaît
                    avoir vérifié que la configuration informatique qu’il utilise ne
                    contient aucun virus et est en parfait état de fonctionnement.
                </p>
                <p>
                    L’utilisateur du site est tenu de respecter les dispositions de la
                    loi relative à l’informatique, aux fichiers et aux libertés, dont la
                    violation est passible de sanctions pénales. Il doit notamment
                    s’abstenir, s’agissant des informations nominatives auxquelles il
                    accède, de toute collecte, de toute utilisation détournée et, d’une
                    manière générale, de tout acte susceptible de porter atteinte à la
                    vie privée ou à la réputation des personnes.
                </p>
                <h1>Traitement des données à caractère personnel</h1>
                <h2>Utilisation des informations</h2>
                <p>
                    L’utilisateur est informé, conformément à l’article 32 de la loi
                    Informatique et libertés du 6 janvier 1978 modifiée, que :
                </p>
                <ul>
                    <li>
                        les informations communiquées par l’intermédiaire des
                        formulaires présents sur le site (nom, prénom, courriel) sont
                        destinées au Directeur de la Publication, responsable du
                        traitement, à des fins de gestion administrative et de
                        communication (emailings pour des événements et des informations
                        ciblées essentiellement), et pourront être communiquées à ses
                        partenaires, dans le cadre de son activité de centre de
                        ressources ;
                    </li>
                    <li>
                        ces informations, à l’exception toutefois de celles qui ne sont
                        pas identifiées par un astérisque, sont nécessaires et
                        obligatoires pour répondre aux demandes de l’utilisateur ;
                    </li>
                    <li>
                        à défaut de renseigner les informations obligatoires, les
                        demandes de l’utilisateur ne pourront pas être traitées ;
                    </li>
                    <li>
                        les informations personnelles collectées au travers du
                        formulaire de contact du site et des correspondances associées
                        (nom, prénom, courriel, société, fonction, numéro de téléphone)
                        sont conservées par le Directeur de la Publication pour un
                        maximum de 36 mois ;
                    </li>
                    <li>
                        les informations personnelles collectées au travers du
                        formulaire de demande d'inscription, (nom, prénom, courriel,
                        société, fonction) sont conservées par le Directeur de la
                        Publication pour la durée strictement nécessaire à la
                        réalisation du projet TerriSTORY et pour un maximum de 36 mois ;
                    </li>
                    <li>
                        elles sont également stockées dans les Serveurs de
                        « l’hébergeur » pour une durée de 36 mois et structurées,
                        consultées par le Coordinateur du consortium TerriSTORY-DEV
                        uniquement lorsque ces traitements sont nécessaires dans le
                        cadre du fonctionnement, du développement et la maintenance du
                        logiciel.
                    </li>
                </ul>
                Les données de l'utilisateur ne sont pas :
                <ul>
                    <li>
                        vendues ou utilisées pour une finalité autre que celles évoquées
                        précédemment ;
                    </li>
                    <li>
                        transférées vers un pays tiers à l’Union européenne ou une
                        organisation internationale.
                    </li>
                </ul>
                <h2>Droits d’accès aux informations</h2>
                <p>
                    Dans le cadre du traitement de vos données à caractère personnel,
                    vous disposez de plusieurs droits issus du RGPD que vous pouvez
                    faire valoir auprès du responsable de traitement.
                </p>
                <ul>
                    <li>
                        Le droit d’accéder à vos données à caractère personnel faisant
                        l’objet d’un traitement (article 15 du RGPD),
                    </li>
                    <li>
                        Le droit de retirer votre consentement à tout moment, sans
                        remettre en cause le traitement mis en œuvre jusque-là (article
                        7 du RGPD),
                    </li>
                    <li>
                        Le droit de rectifier des données inexactes et de compléter des
                        données incomplètes (article 16 du RGPD),
                    </li>
                    <li>
                        Le droit d’obtenir l’effacement de vos données, dans les cas
                        prévus à l’article 17 du RGPD,
                    </li>
                    <li>
                        Le droit d’obtenir la limitation du traitement exercé sur vos
                        données (article 18 du RGPD) : en faisant valoir ce droit, vous
                        stopperez tout ou partie du traitement de vos données
                        personnelles, à l’exception de leur conservation,
                    </li>
                    <li>
                        Le droit à la portabilité de vos données (article 20 du RGPD),
                    </li>
                    <li>
                        Le droit de vous opposer à un traitement des données à caractère
                        personnel vous concernant à tout moment, pour des raisons tenant
                        à votre situation particulière, y compris un profilage (article
                        21 du RGPD),
                    </li>
                    <li>
                        Le droit d’introduire une réclamation auprès de la CNIL (article
                        13 du RGPD).
                    </li>
                </ul>
                <p>
                    Le modèle de lettre / courriel à transmettre à cette adresse et les
                    dispositions d’envoi sont renseignés sur le site Internet de la
                    CNIL : <a href="https://www.cnil.fr/">https://www.cnil.fr/</a>.
                </p>
                <h2>Prise de décision automatisée</h2>
                <p>
                    Il est indiqué qu’il n’est pas procédé, au moyen des données à
                    caractère personnel collectées, à une prise de décision automatisée
                    au sens de la Réglementation en vigueur. Une prise de décision
                    automatisée est une décision prise à l’égard d’une personne, par le
                    biais d’algorithmes appliqués à ses données personnelles, sans
                    qu’aucun être humain n’intervienne dans le processus.
                </p>
                <h2>Protection de vos données</h2>
                <p>
                    Le propriétaire du site met en place des mesures de sécurité
                    techniques et organisationnelles en vue de garantir la
                    disponibilité, l’intégrité et la confidentialité des données à
                    caractère personnel afin d’empêcher que celles-ci soient déformées,
                    endommagées ou que des tiers non autorisés y aient accès. Elle
                    assure un niveau de sécurité adapté aux risques liés au traitement
                    et à la nature des données à protéger.
                </p>
                <h2>Divers</h2>
                <p>
                    <em>Données Post Mortem :</em> Conformément et dans les conditions
                    de l’article 85 de la loi n° 78-17 du 6 janvier 1978 modifiée
                    relative à l’informatique, aux fichiers et aux libertés, toute
                    personne peut définir des directives relatives à la conservation, à
                    l’effacement et à la communication de ses données à caractère
                    personnel après son décès.
                </p>
                <p>
                    <em>Droit applicable :</em> Tout litige en relation avec
                    l’utilisation de vos données personnelles est soumis au droit
                    Français. Toute réclamation ou litige découlant de l’interprétation
                    ou de l’exécution des présentes de préservation des données
                    personnelles sera soumis aux tribunaux compétents dans les
                    conditions de droit commun.
                </p>
                <h1>Informations contenues dans le site</h1>
                <p>
                    Ce site met tout en œuvre pour offrir aux utilisateurs des
                    informations et/ou outils disponibles et vérifiés mais ne saurait
                    être tenu pour responsable des erreurs, d’une absence de
                    disponibilité des informations et/ou de la présence de virus sur son
                    site.
                </p>
                <p>
                    Le Directeur de la Publication ne saurait garantir l’exactitude, la
                    complétude, l’actualité des informations diffusées sur son site. En
                    conséquence, l’utilisateur reconnaît utiliser ces informations sous
                    sa responsabilité exclusive.
                </p>
                <h1>Cookies de navigation</h1>
                <p>
                    L’utilisateur est informé que, lors de ses visites sur le site, un
                    cookie peut s’installer automatiquement sur son logiciel de
                    navigation. Le cookie est un bloc de données qui ne permet pas
                    d’identifier l’utilisateur mais sert à enregistrer des informations
                    relatives à la navigation de celui-ci sur le site.
                </p>
                <p>
                    Le paramétrage du logiciel de navigation permet d’informer de la
                    présence de cookies et éventuellement de la refuser de la manière
                    décrite à l’adresse suivante :{" "}
                    <a href="https://www.cnil.fr/">https://www.cnil.fr/</a>.
                </p>
                <p>
                    L’utilisateur dispose d’un droit d’accès, de retrait et de
                    modification des données personnelles communiquées par le biais des
                    cookies dans les conditions indiquées ci-dessus dans la section
                    « Droit d’accès aux informations ».
                </p>
                <h1>Droits de Propriété Intellectuelle</h1>
                <p>
                    L’exploitant du site détient des droits d’exploitation exclusifs sur
                    la structure générale ainsi que les textes, images animées ou non,
                    sons, savoir-faire et tous les autres éléments composant le site.
                </p>
                <p>
                    Les éléments qui apparaissent sur le site sont disponibles dans les
                    conditions{" "}
                    <strong>
                        Creative Commons 4.0 : Attribution - Pas de Modification 4.0
                        International (CC BY-ND 4.0)
                    </strong>
                    , selon les conditions suivantes :
                    <br />
                    <em>Attribution</em> — Vous devez créditer l'Œuvre, intégrer un lien
                    vers la licence et indiquer si des modifications ont été effectuées
                    à l'Oeuvre. Vous devez indiquer ces informations par tous les moyens
                    raisonnables, sans toutefois suggérer que l'Offrant vous soutient ou
                    soutient la façon dont vous avez utilisé son Oeuvre. Pour cela, il
                    doit être mentionné TerriSTORY® {regionName} et le logo doit être
                    apposé (en téléchargement ici)
                    <br />
                    <em>Pas de modifications</em> — Dans le cas où vous effectuez un
                    remix, que vous transformez, ou créez à partir du matériel composant
                    l'Oeuvre originale, vous n'êtes pas autorisé à distribuer ou mettre
                    à disposition l'Oeuvre modifiée.
                    <br />
                    <em>Pas de restrictions complémentaires</em> — Vous n'êtes pas
                    autorisé à appliquer des conditions légales ou des mesures
                    techniques qui restreindraient légalement autrui à utiliser l'Oeuvre
                    dans les conditions décrites par la licence.
                </p>
                <h1>Liens hypertextes</h1>
                <p>
                    Le site TerriSTORY se réserve le droit de renvoyer ou non vers des
                    sites tiers. Le Directeur de la Publication ne saurait être
                    responsable de l’accès par les utilisateurs via les liens hypertexte
                    mis en place dans le cadre du site web en direction d’autres
                    ressources présentes sur le réseau internet.
                </p>
                <p>
                    La mise en place de liens hypertextes vers des pages ou des
                    documents diffusés sur le site TerriSTORY est autorisée si les liens
                    garantissent la possibilité d’identifier l’origine du document.
                </p>
                <p>
                    Le Directeur de la Publication n’approuve et ne garantit en aucun
                    cas le contenu, les informations et / ou les idées disponibles sur
                    les sites extérieurs accessibles par liens hypertextes présents sur
                    le site TerriSTORY.
                </p>
                <p>
                    La responsabilité du Directeur de la Publication ne peut être
                    engagée eu égard à ces contenus, informations et / ou idées,
                    diffusées par des tiers, sur des sites tiers accessibles par liens
                    hypertextes sur le site TerriSTORY.
                </p>
                <h1>Responsabilité</h1>
                <p>
                    Le Directeur de la Publication et le Coordinateur ne peuvent en
                    aucun cas être tenus responsables pour tout préjudice direct ou
                    indirect résultant de l’utilisation du site.
                </p>
                <p>
                    Notamment, le Directeur de la Publication et le Coordinateur ne
                    seront pas responsables des actes et faits des tiers ayant une
                    conséquence sur le site TerriSTORY, ni de l’impossibilité d’accès et
                    / ou d’usage du site, ni des utilisations frauduleuses du site
                    TerriSTORY par des tiers causant un préjudice à un utilisateur.
                </p>
                <h1>Disponibilité du site</h1>
                <p>
                    Pour des raisons de maintenance, le Coordinateur pourra être amené,
                    de manière occasionnelle, à interrompre l’accès au site pendant
                    quelques heures. En cas de problème persistant, contactez-nous à
                    l’adresse <a href={"mailto:" + contactMail}>{contactMail}</a>.
                </p>
            </div>
        );

        return (
            <div
                className="widgets full-screen-widget"
                style={{ display: "flex", flexDirection: "column" }}
            >
                <SEO
                    settings={this.props.parentApi.data.settings["seo"]}
                    page="legal-notice"
                />
                {retourCarte}
                {mentionsLegales}
            </div>
        );
    }
}

export default LegalInformation;
