/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";

import "ol/ol.css";
import { transform } from "ol/proj";
import configData from "../../settings_data.js";
import { defaults as defaultControls, Control, Zoom } from "ol/control.js";
import Overlay from "ol/Overlay.js";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import Feature from "ol/Feature.js";
import Point from "ol/geom/Point.js";
import { Draw, Select, Translate } from "ol/interaction";

import OlMap from "./OlMap";
import {
    convertRegionToUrl,
    buildRegionUrl,
    removeRegionFromLayer,
    addPlusSign,
    getDateFromStr,
} from "../../utils.js";
import StylesApi from "../../Controllers/style.js";
import Api from "../../Controllers/Api.js";
import config from "../../settings.js";

/**
 * The main map displayed at the root of the application.
 * Here are defined the interactions between the map and the user.
 * The representation of the map is managed in OlMap.js
 *
 * ### Popup workflow:
 * * Outside edit mode, render depends on state.hoverdeFeatures and state.shouldDisplayEditButton
 * * Edit mode is activated by state.editOn and parametrized by state.editedFeature
 * * mouseClick / mouseMove => updatePopup (state.hoveredFeatures,
 * popup.position) => updateEditStatus (state.shouldDisplayEditButton)
 * * componentDidUpdate => updatePoiFeatureTemplate (poiFeatureTemplate)
 * * onClick => startEdit (state.editOn, state.editedFeature) => updatePopup...
 * * index.js => newFeature (state.editOn, state.editedFeature)
 * * onSubmit => submitFeature ()
 */
class MainMap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hoveredFeatures: [], // [{layerName: "...", feature: {...}}, ...]
            /** true if one of the hoveredFeatures is an editable POI */
            shouldDisplayEditButton: false,
            editOn: false, // false, "modify" or "new"
            editedFeature: undefined, // {layerName: "...", feature: {...}}
            refreshPoi: false,
            hasBeenMoved: false,
        };

        this.map = undefined;
        this.popup = undefined;
        /** Disables the update of the popup (position and hoveredFeatures) */
        this.lockPopup = false;
        /** Dict storing some features properties, to build the POI adding form */
        this.poiFeatureTemplate = {};
        this.editLayer = undefined;
        this.select = undefined;
        this.translate = undefined;
        this.draw = undefined;

        this.didMountCallback = this.didMountCallback.bind(this);
        this.closePopup = this.closePopup.bind(this);
        this.delFeature = this.delFeature.bind(this);
        this.submitFeature = this.submitFeature.bind(this);
        this.startEdit = this.startEdit.bind(this);
    }

    componentDidMount() {
        if (this.props.onRef) this.props.onRef(this);
        this.updatePoiFeatureTemplate();
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.parentApi.data.poiLayers !== prevProps.parentApi.data.poiLayers
        ) {
            this.updatePoiFeatureTemplate();
        }

        const current = this.props.parentApi.data;
        const previous = prevProps.parentApi.data;
        const popupDataUpdated =
            current.currentZone !== previous.currentZone ||
            current.zone.zone !== previous.zone.zone ||
            current.zone.maille !== previous.zone.maille ||
            current.analysis !== previous.analysis ||
            current.filtreCategorieCourant !== previous.filtreCategorieCourant ||
            (isNaN(current.fluxThreshold) !== isNaN(previous.fluxThreshold) &&
                current.fluxThreshold !== previous.fluxThreshold) ||
            current.analysisSelectedYear !== previous.analysisSelectedYear ||
            current.analysisSelectedUnit !== previous.analysisSelectedUnit ||
            current.poiLayers !== previous.poiLayers;
        if (popupDataUpdated) {
            this.closePopup();
            this.setState({ hoveredFeatures: [] });
        }
    }

    updatePoiFeatureTemplate() {
        const layerName = convertRegionToUrl(this.props.parentApi.data.region) + "_poi";
        const poiLayers = this.map
            .getLayers()
            .array_.filter(
                (layer) => layer.get("name") && layer.get("name").includes(layerName)
            );
        for (const poiLayer of poiLayers) {
            const poiLayerName = poiLayer.get("name").split("_poi.")[1];
            if (this.poiFeatureTemplate[poiLayerName]) {
                // We already have a template for this layer
                continue;
            }
            let extent = [-Infinity, -Infinity, Infinity, Infinity];
            let features = poiLayer.getSource().getFeaturesInExtent(extent);
            if (features.length === 0) {
                // One of the layers is not fully charged yet. Set a timeout to retry later
                console.log("timeout updatePoiFeatureTemplate");
                setTimeout(this.updatePoiFeatureTemplate.bind(this), 1000);
                return;
            }
            this.poiFeatureTemplate[poiLayerName] = this.getFeatureProperties(
                features[0]
            );
        }
    }

    /**
     * Get the parsed properties of a POI feature, or the value_ attribute if
     * not found
     */
    getFeatureProperties(feature) {
        if (!feature) return {};
        let properties = feature.get("properties");
        if (!properties) {
            return feature.values_;
        }
        return JSON.parse(properties);
    }

    /**
     * Called after the child component's Map was created
     *
     * @param{OlMap} olMap the child map component
     */
    didMountCallback(olMap) {
        this.map = olMap.map;
        this.props.parentApi.callbacks.updateMapPointer(olMap.map);

        let popupContainer = document.getElementById("popup");
        this.popup = new Overlay({
            element: popupContainer,
            autoPan: false,
            autoPanAnimation: {
                duration: 250,
            },
        });
        this.map.addOverlay(this.popup);

        this.editLayer = new VectorLayer({
            source: new VectorSource(),
            style: StylesApi.styleEdit(),
            name: "edit",
        });
        this.editLayer.setZIndex(configData.edit_layer_index);
        this.map.addLayer(this.editLayer);

        // Mouse move on map
        this.map.on("pointermove", (e) => {
            this.manageMouseMoveOnMap(e);
        });

        // Mouse clic on map
        this.map.on("click", (e) => {
            this.manageMouseClicOnMap(e);
        });
    }

    manageMouseMoveOnMap(e) {
        if (this.state.editOn) return;
        if (this.lockPopup) return;
        if (e.dragging) {
            this.closePopup();
        } else {
            this.updatePopup(e.coordinate, e.pixel);
        }
    }

    manageMouseClicOnMap(e) {
        if (this.state.editOn) {
            return;
        }
        // Always update the popup, but lock it if a feature is clicked
        let hoveredFeatures = this.updatePopup(e.coordinate, e.pixel);
        this.lockPopup = hoveredFeatures.length > 0;

        var _this = this;
        this.map.forEachFeatureAtPixel(e.pixel, function (feature, layer) {
            // If the clicked feature is a station, display its history in a chart
            if (layer.get("name") === "stations") {
                _this.props.parentApi.callbacks.displayChart(
                    true,
                    feature.getProperties()["station-name"],
                    feature.getProperties()["station-altitude"]
                );
                return true; // break the loop
            }
        });
    }

    /**
     * Updates the position of the popup and the state hoveredFeatures.
     * @param {boolean} dontClose don't close the popup, even in no features are hovered
     * @returns The new hoveredFeatures state
     */
    updatePopup(coordinate, pixel, dontClose = false) {
        let hoveredFeatures = [];
        this.map.forEachFeatureAtPixel(
            pixel,
            function (feature, layer) {
                if (!layer) return;
                hoveredFeatures.push({
                    feature: feature,
                    hasBeenMoved: false,
                    layerName: layer.get("name"),
                    id:
                        (feature.get("code") ?? feature.get("id")) +
                        "@" +
                        layer.get("name"),
                });
            },
            {
                layerFilter: function (layer) {
                    return layer.get("name") && layer.get("name") !== "highlight";
                },
            }
        );

        // Remove duplicated features
        hoveredFeatures = hoveredFeatures.filter(
            (feature, index, self) =>
                self.findIndex((f) => feature.id === f.id) === index
        );

        this.setState({ hoveredFeatures: hoveredFeatures });
        this.updateEditStatus(hoveredFeatures);

        if (hoveredFeatures.length === 0 && !dontClose) {
            this.closePopup();
        } else {
            this.popup.setPosition(coordinate);
        }

        return hoveredFeatures;
    }

    closePopup() {
        this.popup.setPosition(undefined);
        this.lockPopup = false;
        if (this.state.editOn) {
            this.setState({
                editOn: false,
                editedFeature: undefined,
                hasBeenMoved: false,
            });
        }
    }

    updateEditStatus(hoveredFeatures) {
        if (hoveredFeatures.length === 0) return;

        for (const { layerName } of hoveredFeatures) {
            if (this.isEditableLayer(layerName)) {
                this.setState({ shouldDisplayEditButton: true });
                return;
            }
        }

        this.setState({ shouldDisplayEditButton: false });
    }

    getEditableProperties() {
        const feature = this.state.editedFeature.feature;
        if (!feature) return [];
        const properties = this.getFeatureProperties(feature);

        let editProperties = [];
        if (feature.get("id")) {
            editProperties.push({
                key: "id",
                val: feature.get("id"),
                hidden: true,
            });
        }
        for (const property in properties) {
            if (property !== "geometry" && property !== "type") {
                editProperties.push({
                    key: property,
                    val: properties[property],
                });
            }
        }
        return editProperties;
    }

    editExistingFeature(feature) {
        this.setState({
            editOn: "modify",
            editedFeature: feature,
            hasBeenMoved: false,
        });
        this.startEdit(feature);
    }

    startEdit(forcedFeature = false) {
        let editedFeature;
        let coordinates;
        if (!forcedFeature) {
            editedFeature = this.state.hoveredFeatures.find(
                (feature) => feature.feature.getGeometry().getType() === "Point"
            );

            let schema = convertRegionToUrl(this.props.parentApi.data.region) + "_poi.";
            let poiLayer = this.props.parentApi.data.poiLayers.find(
                (poiLayer) =>
                    schema + poiLayer.nom === editedFeature.layerName &&
                    poiLayer.modifiable
            );
            if (!poiLayer) {
                alert("Vous ne pouvez pas modifier cette couche de données");
                return;
            }
        } else {
            editedFeature = forcedFeature;
        }
        const geometryObj = editedFeature.feature.getGeometry();
        coordinates = geometryObj.getCoordinates
            ? geometryObj.getCoordinates()
            : geometryObj.flatCoordinates_;

        if (!coordinates) {
            alert("Vous ne pouvez pas modifier cette couche de données");
            return;
        }
        let pixel = this.map.getPixelFromCoordinate(coordinates);

        // Copy feature to editLayer
        let feature = new Feature({
            geometry: new Point(coordinates),
        });
        this.editLayer.getSource().addFeature(feature);

        this.select = new Select({ layers: [this.editLayer] });
        this.translate = new Translate({
            features: this.select.getFeatures(),
        });
        this.translate.on("translateend", (event) => {
            this.popup.setPosition(event.coordinate);
            this.setState({
                hasBeenMoved: true,
            });
        });
        this.map.addInteraction(this.select);
        this.map.addInteraction(this.translate);

        // Select the feature
        this.select.getFeatures().clear();
        this.select.getFeatures().push(feature);
        this.updatePopup(coordinates, pixel, true);
    }

    newFeature(layer) {
        // Add interactions to the map
        this.select = new Select({ layers: [this.editLayer] });
        this.translate = new Translate({
            features: this.select.getFeatures(),
        });
        this.draw = new Draw({
            type: "Point",
            source: this.editLayer.getSource(),
        });
        this.map.addInteraction(this.select);
        this.map.addInteraction(this.translate);
        this.map.addInteraction(this.draw);

        this.draw.on("drawend", (event) => {
            var feature = event.feature;
            this.map.removeInteraction(this.draw);

            // Get a template of the POI porperties depending on the layer
            let featureTemplate = this.poiFeatureTemplate[layer];
            // Empty the template
            for (const property in featureTemplate) {
                if (Array.isArray(featureTemplate[property])) {
                    for (const subProperty of featureTemplate[property]) {
                        for (const key in subProperty) {
                            subProperty[key] = "";
                        }
                    }
                } else {
                    featureTemplate[property] = "";
                }
            }
            feature.set("properties", JSON.stringify(featureTemplate));
            this.setState({
                editOn: "new",
                editedFeature: {
                    layerName: layer,
                    hasBeenMoved: true,
                    feature,
                },
            });

            // Select the feature
            this.select.getFeatures().clear();
            this.select.getFeatures().push(feature);
            this.startEdit({
                layerName: layer,
                feature,
            });
        });
    }

    /**
     * Enregistrement d'une feature (attributs) nouvelle ou modifiée
     */
    submitFeature(event) {
        event.preventDefault();
        const form = event.target.elements;

        // Retrieve data from form
        let params = { properties: {} };
        const editableProperties = this.getEditableProperties();
        for (const prop of editableProperties) {
            if (Array.isArray(prop.val)) {
                params.properties[prop.key] = [];
                for (const i in prop.val) {
                    let item = {};
                    const subProperty = prop.val[i];
                    for (const key in subProperty) {
                        let nameKey = i + key;
                        let value = form[nameKey].value;
                        item[key] = value;
                    }
                    params.properties[prop.key].push(item);
                }
            }
            if (form[prop.key]) {
                let value = form[prop.key].value;
                if (prop.key === "id") {
                    params[prop.key] = parseInt(value, 10);
                } else {
                    params.properties[prop.key] = value;
                }
            }
        }

        if (this.state.editOn !== "modify" || this.state.hasBeenMoved) {
            // Add geometry
            let editedFeature = this.editLayer.getSource().getFeatures();
            let coordinates = editedFeature[0].getGeometry().getCoordinates();
            params["x"] = coordinates[0];
            params["y"] = coordinates[1];
        }

        let method = "put";
        if (this.state.editOn === "new") {
            method = "post";
        }

        // Appeler API
        let url = config.api_poi_object_url.replace(
            "#layer#",
            removeRegionFromLayer(
                this.state.editedFeature.layerName,
                this.props.parentApi.data.region + "_poi"
            )
        );

        const body = JSON.stringify(params);
        Api.callApi(buildRegionUrl(url, this.props.parentApi.data.region), body, method)
            .then((response) => {
                if (response.status !== "updated" && response.status !== "created")
                    this.props.parentApi.callbacks.updateMessages(
                        "Un problème est survenu lors de l'enregistrement. " +
                            response.status
                    );
                else {
                    let status =
                        response.status === "updated"
                            ? "Modifications proposées, elles attendent la validation d'un administrateur !"
                            : "Equipement ajouté, l'ajout attend la validation d'un administrateur !";
                    this.props.parentApi.callbacks.updateMessages(status);
                    // Reload POI layers in olMap component
                    this.setState({ refreshPoi: !this.state.refreshPoi });
                    this.clearInteractions();
                    this.closePopup();
                }
            })
            .catch((e) => {
                this.props.parentApi.callbacks.updateMessages(
                    "Un problème est survenu lors de l'enregistrement. " + e.message
                );
            });
    }

    delFeature() {
        // Appeler API
        const url = config.api_poi_object_url.replace(
            "#layer#",
            removeRegionFromLayer(
                this.state.editedFeature.layerName,
                this.props.parentApi.data.region + "_poi"
            )
        );
        const body = JSON.stringify({
            id: this.state.editedFeature.feature.get("id"),
        });
        Api.callApi(
            buildRegionUrl(url, this.props.parentApi.data.region),
            body,
            "delete"
        )
            .then((response) => {
                if (response.status !== "deleted")
                    this.props.parentApi.callbacks.updateMessages(
                        "Un problème est survenu lors de la suppression. Merci de contacter un administrateur."
                    );

                this.props.parentApi.callbacks.updateMessages(
                    "Suppression proposée, elle attend la validation d'un administrateur !"
                );
                // Reload POI layers in olMap component
                this.setState({ refreshPoi: !this.state.refreshPoi });
                this.clearInteractions();
                this.closePopup();
            })
            .catch((e) => {
                this.props.parentApi.callbacks.updateMessages(
                    "Un problème est survenu lors de la suppression. Merci de contacter un administrateur."
                );
            });
    }

    /**
     * Supprime les interactions de modifications des features et nettoie les layers
     */
    clearInteractions() {
        // Supression des interactions
        this.map.removeInteraction(this.select);
        this.map.removeInteraction(this.translate);

        // Nettoyage layer d'édition
        this.editLayer.getSource().clear(true);
    }

    /** Check if the layer name is from a POI one */
    isPoiLayer(layerName) {
        for (const layer of this.props.parentApi.data.poiLayers) {
            if (
                convertRegionToUrl(this.props.parentApi.data.region) +
                    "_poi." +
                    layer.nom ===
                layerName
            )
                return true;
        }
        return false;
    }

    /** Check if the layer name is from an editable POI layer */
    isEditableLayer(layerName) {
        for (const layer of this.props.parentApi.data.poiLayers) {
            if (
                convertRegionToUrl(this.props.parentApi.data.region) +
                    "_poi." +
                    layer.nom ===
                    layerName &&
                layer.modifiable
            ) {
                return true;
            }
        }
        return false;
    }

    /** Compose un JSX pour l'affichage d'une valeur dans la popup */
    renderValue(val) {
        if (val === null || val === undefined) val = "";
        if (val.toString(10).toLowerCase().startsWith("http"))
            return (
                <a href={val} target="_blank" rel="noreferrer">
                    Lien
                </a>
            );
        return <span className="val val-edit">{val}</span>;
    }

    renderZoneInPopup(feature, key = "territory-name") {
        const availableZoneScales = this.props.parentApi.controller.zonesManager.zones;
        // If the territory is already focused or the type-mesh pair is non-existant in the region
        if (
            this.props.parentApi.data.zone.zone ===
                this.props.parentApi.data.zone.maille ||
            availableZoneScales.find(
                ({ nom, maille }) =>
                    nom === maille && maille === this.props.parentApi.data.zone.maille
            ) === undefined
        ) {
            return (
                <div className="val popup-zone" key={key}>
                    <span className="val">{feature.get("nom")}</span>
                </div>
            );
        }
        return (
            <button
                className="val popup-zone"
                key={key}
                onClick={() =>
                    this.props.parentApi.callbacks.focusTerritory(feature.get("code"))
                }
                title="Zoomer sur ce territoire"
            >
                <span className="val">{feature.get("nom")}</span>{" "}
                <i className="bi bi-search" />
            </button>
        );
    }

    /** Render a POI Layer inside the popup */
    renderPoiInPopup(feature) {
        let poiInPopup = [];

        let zoneFeature = undefined;
        if (feature.getGeometry().getType() === "Point") {
            this.map.forEachFeatureAtPixel(
                this.map.getPixelFromCoordinate(feature.getFlatCoordinates()),
                (feature, layer) => {
                    zoneFeature = feature;
                    return true;
                },
                { layerFilter: (layer) => layer.get("name") === "zone" }
            );
        }
        if (zoneFeature) {
            poiInPopup.push(this.renderZoneInPopup(zoneFeature, feature.get("id")));
        }

        let properties = this.getFeatureProperties(feature);
        for (const property in properties) {
            if (property === "geometry") {
                continue;
            }
            if (Array.isArray(properties[property])) {
                let subItems = [];
                for (const i in properties[property]) {
                    const subProperty = properties[property][i];
                    let subItemTitle = <h3>{+i + 1}</h3>;
                    let subItemBody = [];
                    for (const key in subProperty) {
                        let valueField = this.renderValue(subProperty[key]);
                        subItemBody.push(
                            <div className="val" key={key}>
                                <label className="strong">{key} :</label> {valueField}
                            </div>
                        );
                    }
                    subItems.push(
                        <div key={i} className="row w-100">
                            <div className="col-1">{subItemTitle}</div>
                            <div className="col mb-2">{subItemBody}</div>
                        </div>
                    );
                }
                poiInPopup.push(
                    <details key={property}>
                        <summary className="strong">{property}</summary>
                        {subItems}
                    </details>
                );
            } else {
                let valueField = this.renderValue(properties[property]);
                if (property !== "type") {
                    poiInPopup.push(
                        <div className="val" key={property}>
                            <label className="strong">{property} :</label> {valueField}
                        </div>
                    );
                }
            }
        }
        return poiInPopup;
    }

    /**
     * Formats a number with spaces between thousands
     *
     * @param {?number} value Value
     * @returns {?string} Formatted value as string
     */
    thousandsWithSpace(value) {
        if (value === null) {
            return null;
        }
        // Add spaces between thousands
        let parts = value.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        return parts.join(".");
    }

    /**
     * Create JSX for a number of stars.
     * @param {float} value The nb of stars
     * @returns the JSX containing the stars
     */
    getStarsFromValue(value, minStar, maxStar, fullStarStyle = {}) {
        let currentStars = [];
        for (let currentStarNb = 0; currentStarNb < maxStar; currentStarNb++) {
            if (currentStarNb >= value) {
                currentStars.push(
                    <span
                        key={currentStarNb}
                        className="analysis-popup-star analysis-empty-legend-star"
                    ></span>
                );
            } else {
                currentStars.push(
                    <span
                        key={currentStarNb}
                        className="analysis-popup-star analysis-full-legend-star"
                        style={fullStarStyle}
                    ></span>
                );
            }
        }
        return currentStars;
    }

    /**
     * Render an analysis value inside the popup
     * @returns {JSX.Element} The JSX representing the value
     */
    renderPopupAnalysisValue(feature, layerName, value) {
        if (!this.props.parentApi.data.analysisMeta) return null;
        const { analysisMeta } = this.props.parentApi.data;
        let dataType = analysisMeta.data_type;

        let { unit } =
            this.props.parentApi.controller.analysisManager.getUnitParamsForIndicator(
                this.props.parentApi.data?.analysisSelectedUnit,
                analysisMeta,
                this.props.parentApi.data.zone.maille
            );

        let processedVal = this.thousandsWithSpace(value);

        if (value === null) {
            processedVal = "Données indisponibles";
            unit = "";
        }

        if (dataType === "climat" && value > 0) {
            processedVal = "+" + processedVal;
        }

        if (feature.get("confidentiel")) {
            processedVal = "Confidentiel";
            unit = "";
        }
        if (feature.get("indisponible")) {
            processedVal = "Indisponible";
            unit = "";
            if (dataType === "climat") {
                processedVal =
                    "Indicateur non disponible pour cette zone climatique. La zone climatique n'est pas couverte par une station de mesure météorologique disposant de données homogénéisées sur une période longue.";
            }
        }
        if (analysisMeta.type === "choropleth_cat") {
            const modalite = analysisMeta.carto_category.find(
                ({ modalite_id }) => modalite_id === value
            );
            if (modalite === undefined) {
                processedVal = `Indisponible (id ${value})`;
            }
            processedVal = modalite.modalite;
        }

        if (analysisMeta.type === "stars") {
            let minStar = 0,
                maxStar = 5;
            if (analysisMeta.representationDetails) {
                // we parse as integers
                const _minStar = parseInt(
                    analysisMeta.representationDetails.minValue ?? 0,
                    10
                );
                const _maxStar = parseInt(
                    analysisMeta.representationDetails.maxValue ?? 5,
                    10
                );
                // just in case we don't have correct order here
                minStar = Math.min(_minStar, _maxStar);
                maxStar = Math.max(_minStar, _maxStar);
            }

            let fullStarStyle = { backgroundColor: "darkblue" };
            if (
                analysisMeta.representationDetails &&
                analysisMeta.representationDetails.color
            ) {
                fullStarStyle.backgroundColor =
                    analysisMeta.representationDetails.color;
            }
            return (
                <div>
                    {this.getStarsFromValue(value, minStar, maxStar, fullStarStyle)}
                </div>
            );
        }
        if (dataType === "accessibilite_emploi") {
            return (
                <div>
                    <label className="popup-label">
                        Nombre d’emplois accessibles :
                    </label>{" "}
                    <span className="val">
                        {processedVal} {unit}
                    </span>
                    <br />
                    <label className="popup-label">Accessibilité relative :</label>{" "}
                    <span className="val">
                        {feature.getProperties()["classesLegende"]}
                    </span>
                </div>
            );
        }

        const additionnalInfo = [];
        if (analysisMeta.representationDetails?.popup_columns) {
            const columns = JSON.parse(
                analysisMeta.representationDetails.popup_columns
            );
            if (Object.keys(columns).length) {
                additionnalInfo.push(<br key="top-br" />);
            }
            for (let column in columns) {
                let displayedVal = feature.get(column);
                if (displayedVal == null) continue;
                if (displayedVal === true) displayedVal = "oui";
                else if (displayedVal === false) displayedVal = "non";
                additionnalInfo.push(
                    <span key={column}>
                        <label className="popup-label">{columns[column]}&nbsp;:</label>{" "}
                        <span className="val">{displayedVal}</span>
                        <br />
                    </span>
                );
            }
        }

        let indicatorTitle =
            analysisMeta.titre_dans_infobulle || analysisMeta.data_name;
        let val_applats = "";
        if (feature.getProperties()["val_applats"]) {
            if (dataType === "infrastructures_cyclables") {
                val_applats =
                    "Pourcentage par rapport au total du réseau routier, : " +
                    feature.getProperties()["val_applats"] +
                    " %";
            } else {
                val_applats =
                    "Pourcentage du total : " +
                    feature.getProperties()["val_applats"] +
                    " %";
            }
        } else {
            if (analysisMeta.dataDeuxiemeRepresentation) {
                val_applats = "Pourcentage du total : 0 %";
            }
        }
        if (analysisMeta.intervalle_temps && !feature.get("indisponible")) {
            let old_interval =
                feature.getProperties()["min_periode_ancienne"] +
                " - " +
                feature.getProperties()["max_periode_ancienne"];
            let new_interval =
                feature.getProperties()["min_periode_recente"] +
                " - " +
                feature.getProperties()["max_periode_recente"];

            if (
                analysisMeta.data === "data_temperature" &&
                this.props.parentApi.data.region !== "occitanie"
            ) {
                old_interval =
                    analysisMeta.intervalles_temps_recent_ancien.periode_ancienne[0];
                new_interval =
                    analysisMeta.intervalles_temps_recent_ancien.periode_recente[1];
            }

            indicatorTitle = `${indicatorTitle} entre ${old_interval} et ${new_interval}`;
        }
        return (
            <div>
                <label className="popup-label">{indicatorTitle}&nbsp;:</label>{" "}
                <span className="val">
                    {processedVal} {unit}
                </span>
                {val_applats && (
                    <span>
                        <br />
                        {val_applats}
                    </span>
                )}
                {additionnalInfo}
            </div>
        );
    }

    renderStationInPopup(feature) {
        const meta = this.props.parentApi.data.analysisMeta;
        let nomIndicateur = "";
        if (meta) {
            nomIndicateur = meta.data_name;
        }
        let old_interval =
            feature.get("min_periode_ancienne") +
            " - " +
            feature.get("max_periode_ancienne");
        let new_interval =
            feature.get("min_periode_recente") +
            " - " +
            feature.get("max_periode_recente");
        if (
            meta &&
            meta.data === "data_temperature" &&
            this.props.parentApi.data.region !== "occitanie"
        ) {
            old_interval = meta.intervalles_temps_recent_ancien.periode_ancienne[0];
            new_interval = meta.intervalles_temps_recent_ancien.periode_recente[1];
        }
        return (
            <div>
                <span className="station">
                    <b>Station :</b> {feature.get("station-name")}
                    <br />
                </span>
                <b>
                    {nomIndicateur} : {meta.titre_dans_infobulle} entre {old_interval}{" "}
                    et {new_interval} :
                </b>{" "}
                {addPlusSign(feature.get("station_val"))} {meta.unit}
                <br />
                Cliquer sur la station pour visualiser les courbes d'évolution
            </div>
        );
    }

    renderFlowAnalysisInPopup(feature) {
        return (
            <span className="val">
                <span className="flux flux-o">{feature.get("origin")}</span>
                <span> &#8594; </span>
                <span className="flux flux-d">{feature.get("destination")}</span> :{" "}
                <b>{feature.get("val")} trajets</b>
                <br />
            </span>
        );
    }

    /**
     * Render the content of the popup (exculding buttons at the top and bottom)
     * @param {{feature:Feature, layerName:string}[]} hoveredFeatures The list of features hovered or selected for the popup
     * @returns {JSX.Element} The JSX for the content of the popup
     */
    renderPopupContent(hoveredFeatures) {
        // Separate features in types: zone, station, flow analysis, other analysis and POI
        let zoneFeature = undefined;
        let stationFeature = undefined;
        let analysisFeature = undefined;
        let flowFeatures = [];
        let poiFeatures = [];

        // The chloropleth analysis is drawn directly in the "zone" layer.
        for (const f of hoveredFeatures) {
            if (f.layerName === "zone" && f.feature.get("nom")) {
                if (
                    (f.feature.get("confidentiel") ||
                        f.feature.get("val") !== undefined ||
                        f.feature.get("indisponible")) &&
                    analysisFeature === undefined
                ) {
                    analysisFeature = f;
                } else if (zoneFeature === undefined) {
                    zoneFeature = f;
                }
            } else if (f.layerName === "stations" && stationFeature === undefined) {
                stationFeature = f;
            } else if (f.layerName === "analysis") {
                if (f.feature.get("origin") && f.feature.get("destination")) {
                    flowFeatures.push(f);
                } else if (analysisFeature === undefined) {
                    analysisFeature = f;
                }
            } else if (this.isPoiLayer(f.layerName)) {
                poiFeatures.push(f);
            }
        }

        // The important part : decide what features to keep
        if (analysisFeature !== undefined && analysisFeature.feature.get("nom")) {
            zoneFeature = analysisFeature;
        }
        if (poiFeatures.length && analysisFeature === undefined) {
            zoneFeature = undefined;
        }
        if (flowFeatures.length) {
            zoneFeature = undefined;
        }
        if (stationFeature !== undefined) {
            zoneFeature = undefined;
            analysisFeature = undefined;
            poiFeatures = [];
        }

        // Compose popup jsx
        let contents = [];
        if (zoneFeature !== undefined) {
            // TODO: handle confidentiality for maille = commune
            contents.push(this.renderZoneInPopup(zoneFeature.feature));
        }
        if (stationFeature !== undefined) {
            contents.push(
                <div className="val mb-2" key="station">
                    <span className="val">
                        {this.renderStationInPopup(stationFeature.feature)}
                    </span>
                </div>
            );
        }
        if (analysisFeature !== undefined) {
            const { feature, layerName } = analysisFeature;
            contents.push(
                <div className="val mb-2" key="analysis-value">
                    {this.renderPopupAnalysisValue(
                        feature,
                        layerName,
                        feature.get("val")
                    )}
                </div>
            );
        }
        for (const index in flowFeatures) {
            const { feature } = flowFeatures[index];
            contents.push(
                <div className="val mb-2" key={"flow-" + index}>
                    {this.renderFlowAnalysisInPopup(feature)}
                </div>
            );
        }
        const canEdit =
            this.props.parentApi.data.connected &&
            this.state.shouldDisplayEditButton &&
            !this.state.editOn;
        for (const index in poiFeatures) {
            const { feature, layerName } = poiFeatures[index];
            contents.push(
                <div className="val mb-2" key={"poi-" + index}>
                    {this.renderPoiInPopup(feature)}
                </div>
            );
            if (canEdit) {
                // Le bouton est affiché si la couche est éditable et que l'utilisateur est connecté
                contents.push(
                    <div className="val mb-2">
                        <button
                            type="button"
                            className="btn btn-outline-primary btn-sm"
                            onClick={(e) =>
                                this.editExistingFeature({
                                    layerName,
                                    feature,
                                    hasBeenMoved: false,
                                })
                            }
                        >
                            Modifier cet objet
                        </button>
                    </div>
                );
            }
        }

        return <div id="popup-content">{contents}</div>;
    }

    renderEditFormInput(key, val, constraint) {
        if (!constraint) {
            return (
                <input
                    type="text"
                    id={key}
                    className="val"
                    defaultValue={val}
                    name={key}
                />
            );
        } else if (constraint?.field_type === "date") {
            let currentDate = getDateFromStr(val);
            if (currentDate && !isNaN(currentDate)) {
                currentDate = currentDate.toISOString().substring(0, 10);
            }
            return (
                <input
                    type="date"
                    id={key}
                    className="val"
                    defaultValue={currentDate}
                    name={key}
                />
            );
        } else if (constraint?.field_type === "integer") {
            return (
                <input
                    type="number"
                    id={key}
                    step="1"
                    className="val"
                    defaultValue={val}
                    name={key}
                />
            );
        } else if (constraint?.field_type === "float") {
            return (
                <input
                    type="number"
                    id={key}
                    step="0.01"
                    className="val"
                    defaultValue={val}
                    name={key}
                />
            );
        } else if (constraint?.field_type === "select") {
            return (
                <select id={key} className="val" defaultValue={val} name={key}>
                    {(constraint?.details ?? []).map((option) => {
                        return <option value={option}>{option}</option>;
                    })}
                </select>
            );
        }
        return (
            <input type="text" id={key} className="val" defaultValue={val} name={key} />
        );
    }

    renderEditForm() {
        const editableProperties = this.getEditableProperties();

        // we retrieve potential constraints applying to current layer
        let poiLayer = this.props.parentApi.data.poiLayers.find(
            (poiLayer) =>
                poiLayer.nom ===
                    removeRegionFromLayer(
                        this.state.editedFeature.layerName,
                        this.props.parentApi.data.region + "_poi"
                    ) && poiLayer.modifiable
        );
        let constraints = poiLayer?.structure_constraints ?? {};
        // we generate form
        let form = [];
        for (const prop of editableProperties) {
            let valueField = undefined;
            let classItem = "";
            if (Array.isArray(prop.val)) {
                let supPropList = [];
                for (const i in prop.val) {
                    const subProperty = prop.val[i];
                    let subItemBody = [];
                    for (const key in subProperty) {
                        let nameKey = i + key;
                        subItemBody.push(
                            <div className="val" key={nameKey}>
                                <label className="label-edit" htmlFor={nameKey}>
                                    {key} :{" "}
                                </label>
                                {this.renderEditFormInput(
                                    nameKey,
                                    subProperty[key],
                                    constraints?.[key]
                                )}
                            </div>
                        );
                    }
                    let key = i + prop.key;
                    supPropList.push(
                        <div key={key}>
                            <h3>{prop.key}</h3>
                            {subItemBody}
                        </div>
                    );
                }
                valueField = <div>{supPropList}</div>;
            } else {
                if (prop.hidden) {
                    classItem = "hidden";
                }
                // HACK
                valueField = this.renderEditFormInput(
                    prop.key,
                    prop.val,
                    constraints?.[prop.key]
                );
            }
            form.push(
                <div className={classItem} key={prop.key}>
                    <label className="label-edit" htmlFor={prop.key}>
                        {prop.key} :{" "}
                    </label>
                    {valueField}
                </div>
            );
        }
        return form;
    }

    /** Renders the popup as a JSX with the control buttons and the content */
    renderPopup() {
        if (this.state.editOn) {
            let saveLabel = this.state.editOn === "new" ? "Ajouter" : "Enregistrer";
            const saveButton = (
                <button type="submit" id="popup-save" className="btn btn-success">
                    {saveLabel}
                </button>
            );

            const cancelButton = (
                <button
                    type="button"
                    id="popup-cancel"
                    className="btn btn-secondary"
                    onClick={() => {
                        this.clearInteractions();
                        this.closePopup();
                    }}
                >
                    Annuler
                </button>
            );

            let delButton = "";
            if (
                this.props.parentApi.data.profil === "admin" &&
                this.state.editOn === "modify"
            ) {
                delButton = (
                    <>
                        <br />
                        <button
                            type="button"
                            id="popup-del"
                            className="btn btn-danger"
                            onClick={this.delFeature}
                        >
                            Supprimer
                        </button>
                    </>
                );
            }

            let form = this.renderEditForm();

            return (
                <div id="popup" className="ol-popup ol-popup-edit-mode">
                    <form onSubmit={this.submitFeature}>
                        <div id="popup-content">{form}</div>
                        {cancelButton}
                        {saveButton}
                        {delButton}
                    </form>
                </div>
            );
        }
        const closeButton = (
            <button
                type="button"
                id="popup-closer"
                className="ol-popup-closer ol-popup-button"
                onClick={this.closePopup}
            >
                ✖
            </button>
        );
        const popupContent = this.renderPopupContent(this.state.hoveredFeatures);
        return (
            <div id="popup" className="ol-popup">
                {closeButton}
                {popupContent}
            </div>
        );
    }

    render() {
        const attribution = (
            <div className="custom-ol-attribution">
                <a
                    href="http://www.openstreetmap.org/about/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    © OpenStreetMap contributors
                </a>
            </div>
        );
        const classTheme = this.props.parentApi.data.theme;
        const viewOptions = {
            center: transform(
                this.props.initialMapCenter,
                configData.data_epsg,
                configData.map_epsg
            ),
            zoom: this.props.initialMapZoom,
            extent: this.props.parentApi.data.settings.map_extent,
            minZoom: this.props.parentApi.data.settings.map_min_zoom,
            constrainResolution: true,
        };
        const mapOptions = {
            controls: defaultControls({
                zoom: false,
                attribution: false,
            }).extend([
                new Zoom(),
                new PrintControl({
                    printCallback: this.props.parentApi.callbacks.print,
                    className: "print print-" + classTheme,
                }),
            ]),
        };
        const checkedPoiLayers = this.props.parentApi.data.poiLayers
            .filter((p) => p.checked)
            .map((p) => p.nom);

        // Update the map if one of these values are updated
        if (this.state.refreshPoi) checkedPoiLayers.push(undefined);
        let filterString =
            "" +
            this.props.parentApi.data.filtreCategorieCourant +
            this.props.parentApi.data.fluxThreshold +
            this.props.parentApi.data.analysisSelectedYear +
            this.props.parentApi.data.analysisSelectedUnit;

        return (
            <div className="mapContainerContent">
                {this.renderPopup()}
                <OlMap
                    id="mainmap"
                    parentApi={this.props.parentApi}
                    mapOptions={mapOptions}
                    viewOptions={viewOptions}
                    // Data
                    zone={this.props.parentApi.data.zone}
                    currentZone={this.props.parentApi.data.currentZone}
                    analysisId={
                        this.props.parentApi.data.displayAnalysis
                            ? this.props.parentApi.data.analysis
                            : undefined
                    }
                    filterString={filterString}
                    classMethod={this.props.parentApi.data.classMethod}
                    metaStyle={this.props.parentApi.data.metaStyle}
                    poiLayers={checkedPoiLayers}
                    // Callbacks
                    updateAnalysisMeta={
                        this.props.parentApi.callbacks.updateAnalysisMeta
                    }
                    didMountCallback={this.didMountCallback}
                />
                {attribution}
            </div>
        );
    }
}

class PrintControl extends Control {
    constructor(opt_options) {
        let options = opt_options || {};

        let button = document.createElement("button");
        const className = options.className ?? "";
        button.innerHTML = `<div class="${className}"><div class="menu-icon" /></div>`;

        let element = document.createElement("div");
        element.className = "print-control ol-unselectable ol-control";
        element.title = "Imprimer";
        element.appendChild(button);

        super({
            element: element,
            target: options.target,
        });

        if (options.printCallback) {
            button.addEventListener("click", options.printCallback, false);
        }
    }
}

export default MainMap;
