/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import { Link } from "react-router-dom";

import Api from "../Controllers/Api";
import { buildRegionUrl, saveAsPng, createPdfMethodoLink } from "../utils.js";
import FabriqueRepresentation from "./FabriqueRepresentation.js";
import { ExportIndicatorButton } from "./ExportButton.js";

import config from "../settings.js";

/**
 * This function is used to display GES emissions tracking graphs
 */
class SuiviEmissionGes extends React.Component {
    constructor(props) {
        super(props);

        // Get data from API
        this.state = {
            parameters: {},
            identifiantIndicateurEmiGes: false,
            passageTable: false,
        };
    }

    componentDidMount() {
        // Get the data for the graph
        this.getParametersFromApi();
    }

    getParametersFromApi() {
        const url = buildRegionUrl(
            config.api_analysis_page_parameters,
            this.props.parentApi.data.region
        ).replace("#page#", "suivi_emission_ges");
        Api.callApi(url).then((json) => {
            if (!json || !json.parameters || !json.passage_table) {
                return;
            }

            let pZone = "?zone=" + this.props.parentApi.data.zone.zone;
            let pMaille = "&maille=" + this.props.parentApi.data.zone.maille;
            let pZoneId = "&zone_id=" + this.props.parentApi.data.currentZone;
            let nomTerritoire =
                "&nom_territoire=" +
                encodeURI(
                    this.props.parentApi.controller.zonesManager.getZoneName(
                        this.props.parentApi.data.currentZone,
                        this.props.parentApi.data.zone
                    )
                );
            this.props.parentApi.callbacks.mettreAJourParametresUrls(
                pZone + pMaille + pZoneId + nomTerritoire
            );

            this.setState({
                parameters: json.parameters,
                passageTable: json.passage_table,
                identifiantIndicateurEmiGes:
                    this.props.parentApi.controller.analysisManager.obtenirIdentifiantAnalyse(
                        json.passage_table["DataSet.EMISSIONS"].name
                    ),
            });
        });
    }

    render() {
        if (
            this.state.passageTable === false ||
            this.state.identifiantIndicateurEmiGes === false
        ) {
            return <></>;
        }

        let refEvolEmiSecteur = React.createRef(),
            refEvolEmiEnergie = React.createRef(),
            refEvolEmiUsage = React.createRef();
        let classTheme = this.props.parentApi.data.theme;
        let source_suivi_traj = JSON.parse(
            this.props.parentApi.data.settings["source_suivi_traj"]
        );
        let zoneName = this.props.parentApi.controller.zonesManager.getZoneName(
            this.props.parentApi.data.currentZone,
            this.props.parentApi.data.zone
        );
        // territory name
        let title = zoneName;
        if (!title) {
            title = this.props.parentApi.data.nomTerritoire;
        }

        // chart parameters
        let width = 620;
        let height = 450;

        const urlPartageable = "";
        let chartEvoEmiSecteur =
            this.state.parameters && this.state.parameters["chartEvoEmiSecteur"] ? (
                <FabriqueRepresentation
                    // FabriqueRepresentation instantiates the representation specified in the representation property
                    // Here, we instantiate a CourbesEmpilees component
                    parentApi={this.props.parentApi}
                    id_analysis={this.state.identifiantIndicateurEmiGes}
                    representation={"line"}
                    type={{
                        categorie:
                            this.state.passageTable["DataSet.EMISSIONS"].cols["SECTOR"],
                        titre: "Par secteur",
                    }}
                    provenance="suivi_energetique"
                    width={width}
                    height={height}
                    id={1}
                />
            ) : (
                false
            );

        let chartEvoEmiEnergie =
            this.state.parameters && this.state.parameters["chartEvoEmiEnergie"] ? (
                <FabriqueRepresentation
                    parentApi={this.props.parentApi}
                    id_analysis={this.state.identifiantIndicateurEmiGes}
                    representation={"line"}
                    type={{
                        categorie:
                            this.state.passageTable["DataSet.EMISSIONS"].cols[
                                "COMMODITY"
                            ],
                        titre: "Par type d'énergie",
                    }}
                    provenance="suivi_energetique"
                    width={width}
                    height={height}
                    id={2}
                />
            ) : (
                false
            );

        let chartEvoEmiUsage =
            this.state.parameters && this.state.parameters["chartEvoEmiUsage"] ? (
                <FabriqueRepresentation
                    parentApi={this.props.parentApi}
                    id_analysis={this.state.identifiantIndicateurEmiGes}
                    representation={"line"}
                    type={{
                        categorie:
                            this.state.passageTable["DataSet.EMISSIONS"].cols["USAGE"],
                        titre: "Par usage",
                    }}
                    provenance="suivi_energetique"
                    width={width}
                    height={height}
                    id={3}
                />
            ) : (
                false
            );

        const getPdfSaveButtons = (id_analysis, ref, nom) => {
            return (
                <div className="selection-graph bouton-methodo do-not-print">
                    <button
                        id="buttons"
                        type="button"
                        className="btn btn-info btn-save block-row bouton-flex liste-modalites-diagramme"
                        onClick={() => {
                            saveAsPng(ref, nom);
                        }}
                    >
                        <span>PNG</span>
                    </button>
                    <ExportIndicatorButton
                        parentApi={this.props.parentApi}
                        analysisId={id_analysis}
                    />
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href={createPdfMethodoLink(
                            config.methodo_url,
                            this.props.parentApi.data.region,
                            this.props.parentApi.controller.analysisManager.getAnalysisMethodoPdf(
                                parseInt(id_analysis, 10)
                            )
                        )}
                    >
                        <div className="pdf"></div>
                    </a>
                </div>
            );
        };

        return (
            <div
                className="suivi-energetique widgets full-screen-widget"
                id="suivi-energetique"
            >
                <Link
                    className="back-to-map"
                    to={"/" + this.props.parentApi.data.urlPartageable}
                >
                    <button
                        type="button"
                        className="close close-big"
                        data-dismiss="alert"
                        aria-label="Close"
                        onClick={() => {
                            this.props.parentApi.callbacks.mettreAJourParametresUrls(
                                urlPartageable
                            );
                            this.props.parentApi.callbacks.updateCurrentMenu("analyse");
                        }}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Link>
                <div
                    className={"page-content " + classTheme}
                    id="suivi-energetique-content"
                >
                    <div className="title centered-row">
                        <label className="suivi-energetique-title">
                            Suivi de la trajectoire carbone
                        </label>
                        <label className="suivi-energetique-territoire">
                            Territoire : {title}
                        </label>
                    </div>

                    <h3>Évolution des émissions de GES</h3>
                    <div align="center"></div>
                    {chartEvoEmiSecteur && (
                        <div ref={refEvolEmiSecteur}>
                            <h4>Par secteur :</h4>
                            {getPdfSaveButtons(
                                this.state.identifiantIndicateurEmiGes,
                                refEvolEmiSecteur,
                                "Évolution des émissions par secteur"
                            )}
                            {chartEvoEmiSecteur}
                            <div className="chart-legend">
                                {source_suivi_traj["source"]}
                            </div>
                        </div>
                    )}
                    {chartEvoEmiEnergie && (
                        <div ref={refEvolEmiEnergie}>
                            <h4>Par énergie :</h4>
                            {getPdfSaveButtons(
                                this.state.identifiantIndicateurEmiGes,
                                refEvolEmiEnergie,
                                "Évolution des émissions par énergie"
                            )}
                            {chartEvoEmiEnergie}
                            <div className="chart-legend">
                                {source_suivi_traj["source"]}
                            </div>
                        </div>
                    )}
                    {chartEvoEmiUsage && (
                        <div ref={refEvolEmiUsage}>
                            <h4>Par usage :</h4>
                            {getPdfSaveButtons(
                                this.state.identifiantIndicateurEmiGes,
                                refEvolEmiUsage,
                                "Évolution des émissions par usage"
                            )}
                            {chartEvoEmiUsage}
                            <div className="chart-legend">
                                {source_suivi_traj["source"]}
                            </div>
                        </div>
                    )}
                    {this.props.parentApi.data.settings &&
                    this.props.parentApi.data.settings.ui_show_plan_actions ? (
                        <div className="lien-strategies-territoire">
                            <Link to="/strategies_territoriales">
                                {" "}
                                <button className="btn btn-success">
                                    {" "}
                                    Mesurer l'impact de votre plan d'actions sur la
                                    trajectoire émissions de GES{" "}
                                </button>
                            </Link>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            </div>
        );
    }
}

export default SuiviEmissionGes;
