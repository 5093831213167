/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import ReactTable from "react-table-6";

import Api from "../../Controllers/Api";
import { buildRegionUrl, exportToCSV } from "../../utils.js";
import Select from "react-select";

import config from "../../settings.js";

import "bootstrap/dist/css/bootstrap.min.css";

/**
 * Ce composant permet de présenter et de récupérer les statistiques de consultation.
 */
class Statistics extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status: false,
            message: "",
            statsData: undefined,
            statsHeaders: [],
            fromDate: "2022-01-01",
            toDate: "",
            statsTypes: undefined,
            statsTypeSelected: { value: "territoires", label: "territoires" },
        };
    }

    componentDidMount() {
        if (
            this.props.connected &&
            this.props.userInfos &&
            this.props.userInfos.profil === "admin" &&
            this.state.statsTypes === undefined
        ) {
            this.getStatsTypes();
        }
    }

    /**
     * Retrieve existing statistics types from API to fill select input.
     */
    getStatsTypes() {
        // Call api to get history
        Api.callApi(
            buildRegionUrl(config.api_stats_types, this.props.parentApi.data.region),
            undefined,
            "GET"
        )
            .then((response) => {
                this.setState({
                    statsTypes: response.stats_types,
                    status: false,
                    message: "",
                });
            })
            .catch((e) => this.setState({ status: "warning", message: e.message }));
    }

    /**
     * Extract stats corresponding to time interval specified and statistics
     * type selected.
     * Will return if beginning date or type have not been specified.
     */
    extractStats() {
        if (
            this.state.fromDate === undefined ||
            this.state.fromDate === "" ||
            this.state.statsTypeSelected === "" ||
            this.state.statsTypeSelected === undefined
        ) {
            this.setState({
                status: "warning",
                message:
                    "La date de début ou le type de statistiques n'ont pas été renseignés.",
            });
            return;
        }
        this.setState({
            statsData: undefined,
            statsHeaders: [],
        });

        let url = buildRegionUrl(
            config.api_stats_query,
            this.props.parentApi.data.region
        )
            .replace("#stat_type#", this.state.statsTypeSelected.value)
            .replace("#from_date#", this.state.fromDate);

        if (this.state.toDate !== "" && this.state.toDate !== undefined) {
            url += "?to_date=" + this.state.toDate;
        }
        // Call api to get history
        Api.callApi(url, undefined, "GET")
            .then((response) => {
                this.setState({
                    status: false,
                    message: "",
                    statsData: response.data,
                    statsHeaders: response.headers,
                });
            })
            .catch((e) => this.setState({ status: "danger", message: e.message }));
    }

    /**
     * Update internal state for statsTypeSelected parameter.
     * @param {str} newVal the new value
     */
    selectStatsType(newVal) {
        this.setState({ statsTypeSelected: newVal });
    }

    /**
     * Update internal state for fromDate parameter.
     * @param {str} newVal the new value
     */
    changeFromDate(newVal) {
        this.setState({ fromDate: newVal ?? "" });
    }

    /**
     * Update internal state for toDate parameter.
     * @param {str} newVal the new value
     */
    changeToDate(newVal) {
        // cf. https://react.dev/reference/react-dom/components/input#im-getting-an-error-a-component-is-changing-an-uncontrolled-input-to-be-controlled
        this.setState({ toDate: newVal ?? "" });
    }

    /**
     * Export table data to CSV file using exportToCSV function (utils).
     */
    exportStats() {
        exportToCSV(this.state.statsData, "csv");
    }

    render() {
        if (
            !this.props.connected ||
            !this.props.userInfos ||
            this.props.userInfos.profil !== "admin"
        ) {
            return <div>Non accessible.</div>;
        }

        // Prepare data for statistics type selection
        let typesAvailable = [];
        if (this.state.statsTypes && Array.isArray(this.state.statsTypes)) {
            for (let a of this.state.statsTypes) {
                typesAvailable.push({ value: a, label: a });
            }
        }

        // Fill columns of ReactTable
        let columns = [];
        this.state.statsHeaders.forEach((header) => {
            columns.push({
                Header: header,
                accessor: header,
            });
        });

        let stats = "";
        if (this.state.statsData !== undefined) {
            stats = (
                <div>
                    <h3>Statistiques</h3>
                    <p>
                        <button
                            className="btn btn-info"
                            onClick={() => this.exportStats()}
                        >
                            Exporter les résultats en csv
                        </button>
                    </p>
                    <ReactTable
                        data={this.state.statsData}
                        columns={columns}
                        className="-striped"
                        defaultPageSize={100}
                    />
                </div>
            );
        }

        return (
            <div>
                <div className="panel-body panel-ajout-indicateur">
                    <h3 className="panel-title pull-left">
                        Statistiques de consultation
                    </h3>
                    {this.state.status && (
                        <p className={"alert alert-" + this.state.status}>
                            {this.state.message}
                        </p>
                    )}
                    <form aria-label="stats-selection">
                        <div className="form-horizontal">
                            <label
                                htmlFor="stat_type"
                                className="col-sm-2 control-label"
                            >
                                Type de statistiques à extraire :
                            </label>
                            <Select
                                defaultValue={this.state.statsTypeSelected}
                                value={this.state.statsTypeSelected}
                                name="stat_type"
                                inputId="stat_type"
                                role="listbox"
                                options={typesAvailable}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(e) => this.selectStatsType(e)}
                            />
                        </div>
                        <div className="form-horizontal">
                            <label
                                htmlFor="from-date"
                                className="col-sm-2 control-label"
                            >
                                Date de début (format <strong>AAAA-MM-DD</strong>) :
                            </label>
                            <input
                                className="form-control form-inline col-sm-4 taille-formulaire-admin"
                                type="date"
                                name="from-date"
                                id="from-date"
                                role="dialog"
                                value={this.state.fromDate}
                                onChange={(e) => this.changeFromDate(e.target.value)}
                            />
                        </div>
                        <div className="form-horizontal">
                            <label htmlFor="to-date" className="col-sm-2 control-label">
                                Date de fin (<em>optionnelle</em>) :
                            </label>
                            <input
                                className="form-control form-inline col-sm-4 taille-formulaire-admin"
                                type="date"
                                name="to-date"
                                id="to-date"
                                role="dialog"
                                value={this.state.toDate}
                                onChange={(e) => this.changeToDate(e.target.value)}
                            />
                        </div>
                        <div>
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => this.extractStats()}
                            >
                                Extraire les statistiques
                            </button>
                        </div>
                    </form>
                    {stats}
                </div>
            </div>
        );
    }
}

export default Statistics;
