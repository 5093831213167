/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import { useEffect } from "react";

function _convertErrorToCleanMsg(error) {
    let correspondingMsg = {
        "Internal Server Error":
            "Le serveur a rencontré une erreur et ne peut gérer votre requête.",
        "Bad Request": "Le serveur ne peut gérer votre requête car elle est erronée.",
    };
    return Object.keys(correspondingMsg).includes(error)
        ? correspondingMsg[error]
        : error;
}

/**
 * Function to make the link with the API of Terristory
 */
const Api = {
    // Request specific route from API
    callApi(
        url,
        body,
        method = "GET",
        contentType = "application/json",
        abortController = undefined
    ) {
        if (abortController === undefined) abortController = new AbortController();
        const headers = new Headers();
        let params = {
            body,
            method: method,
            credentials: "include",
            signal: abortController.signal,
        };
        // Fetch is able to determine the correct header in the case of form (FormData) and file sending (even causes a bug if we try to force it)
        if (contentType !== "default") {
            headers.append("Content-Type", contentType);
            params["headers"] = headers;
        }
        const promise = fetch(`${url}`, params).then((response) => {
            if (response.ok) {
                return response.json();
            }
            let errorMessage;
            return response
                .json()
                .then((json) => {
                    // if we received a real json, we show the error message
                    errorMessage = json.message;
                })
                .finally(() => {
                    // we convert the server msg error into something understandable
                    const err = new Error(
                        errorMessage ?? _convertErrorToCleanMsg(response.statusText)
                    );
                    err.status = response.status;
                    throw err;
                });
        });
        promise.abort = () => {
            abortController.abort();
        };
        return promise;
    },
    useLoadData(
        url,
        onSuccess,
        onError = undefined,
        updateDataLoaded = undefined,
        forceUpdate = undefined
    ) {
        useEffect(() => {
            if (!url || !onSuccess) return;
            if (updateDataLoaded) updateDataLoaded(false);
            let promise = this.callApi(url);
            promise
                .then((data) => {
                    if (updateDataLoaded) updateDataLoaded(true);
                    onSuccess(data);
                })
                .catch((error) => {
                    if (error.name === "AbortError") return;
                    if (updateDataLoaded) updateDataLoaded(true);
                    if (onError) onError(error);
                });
            return () => promise.abort();
        }, [url, updateDataLoaded, onSuccess, onError, forceUpdate]);
    },
};

export default Api;
