/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import { Redirect, useLocation } from "react-router-dom";

/**
 * We test whether a region has been loaded, by having its name as the first parameter of the url.
 * Then weredirect it to the root.
 * @param {str} param0
 * @returns
 */
export default function RegionalHandler({ region, regions, children }) {
    const location = useLocation();

    if (
        region &&
        region !== "national" &&
        regions &&
        regions.indexOf(location.pathname.replace("/", "")) !== -1
    ) {
        return <Redirect to="/" />;
    } else {
        return <>{children}</>;
    }
}
