/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import ReactTooltip from "react-tooltip";
import "rc-slider/assets/index.css";
import Slider, { Range } from "rc-slider";
import { transform } from "ol/proj";

import NumberFormat from "react-number-format";
import config from "../../settings.js";
import configData from "../../settings_data.js";
import StylesApi from "../../Controllers/style.js";
import { DataSource } from "./Infos.js";
import {
    consulteAutrePage,
    createPdfMethodoLink,
    exportToCSV,
    slugify,
} from "../../utils.js";

import "bootstrap-icons/font/bootstrap-icons.css";
/**
 * This component is used to create a legend for indicators and equipment.
 */
class Legend extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fluxThreshold: configData.fluxThreshold,
            display: true,
            modalIsOpen: false,
            poiToExport: undefined,
            selectedOption: "",
        };
        this.toggleDisplay = this.toggleDisplay.bind(this);
        this.toggleDisplayAnalysis = this.toggleDisplayAnalysis.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleOptionChange = this.handleOptionChange.bind(this);
        this.exportPoiToCsv = this.exportPoiToCsv.bind(this);
    }

    componentDidMount() {
        let idIndicateur = this.props.parentApi.data.analysis;
        if (this.props.parentApi.controller.analysisManager) {
            let filtre =
                this.props.parentApi.controller.analysisManager.getFilterDefaultValue(
                    idIndicateur
                );
            if (
                this.props.analysisMeta &&
                this.props.analysisMeta.dataDeuxiemeRepresentation
            ) {
                this.setState({
                    fluxThreshold: filtre,
                });
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        let fluxThreshold = this.props.parentApi.data.fluxThreshold;
        let analysis = this.props.parentApi.data.analysis;
        let idIndicateur = this.props.parentApi.data.analysis;
        if (idIndicateur) {
            let filtre =
                this.props.parentApi.controller.analysisManager.getFilterDefaultValue(
                    idIndicateur
                );
            if (
                this.props.parentApi.data.currentZone !==
                prevProps.parentApi.data.currentZone
            ) {
                this.setState({
                    fluxThreshold: filtre,
                });
            }

            if (
                this.props.parentApi.data.analysisMeta !==
                prevProps.parentApi.data.analysisMeta
            ) {
                if (
                    !prevProps.parentApi.data.analysisMeta ||
                    !this.props.parentApi.data.analysisMeta ||
                    this.props.parentApi.data.analysisMeta.type !==
                        prevProps.parentApi.data.analysisMeta.type
                ) {
                    if (this.props.parentApi.data.analysisMeta?.type === "flow") {
                        // Particular case for flow indicators
                        // The size assigned to circles is not suitable for flow representations
                        this.props.parentApi.callbacks.updateMetaStyle({
                            maxRadius: configData.minRadiusCircle,
                        });
                    } else {
                        this.props.parentApi.callbacks.updateMetaStyle({
                            maxRadius: configData.maxRadiusCircle,
                        });
                    }
                }
            }

            if (idIndicateur !== prevProps.parentApi.data.analysis) {
                this.setState({
                    fluxThreshold: filtre,
                });
            }
        }
        if (
            prevProps.parentApi.data.fluxThreshold !== fluxThreshold &&
            prevProps.parentApi.data.analysis === analysis
        ) {
            // we test we are not entering an infinite loop
            if (
                this.state.fluxThreshold !== fluxThreshold &&
                !(isNaN(fluxThreshold) && isNaN(this.state.fluxThreshold))
            ) {
                this.setState({
                    fluxThreshold: fluxThreshold,
                });
            }
        }
    }

    toggleDisplay() {
        this.setState({
            display: !this.state.display,
        });
    }

    toggleDisplayAnalysis() {
        let displ = this.props.parentApi.data.displayAnalysis;
        this.props.parentApi.callbacks.displayAnalysis(!displ);
    }

    openModal(poiToExport) {
        this.setState({
            modalIsOpen: true,
            poiToExport,
        });
    }

    closeModal() {
        this.setState({
            modalIsOpen: false,
        });
    }

    handleOptionChange(changeEvent) {
        this.setState({
            selectedOption: changeEvent.target.value,
        });
    }

    suiviConsultation(categorie, text) {
        let region = this.props.parentApi.data.region;
        let idUtilisateur =
            this.props.parentApi.controller.gestionSuiviConsultations.idUtilisateur;
        let url = config.api_consultation_autre_page_url;
        let nomPdf = text;
        consulteAutrePage(
            url,
            region,
            idUtilisateur,
            categorie + " - PDF méthodologique",
            nomPdf
        );
        return false;
    }

    /**
     * Structure les données d'équipements et les exporte en CSV
     * @param {*} poiLayer : la couche d'équipements à exporter
     * @param {*} pdfUrl : l'url du PDF méthodologie
     */
    exportPoiToCsv(poiLayer, pdfUrl, pdfName) {
        if (!poiLayer.exportable) {
            console.error("Cette couche d'équipement n'est pas exportable.");
        } else {
            this.props.parentApi.controller.equipementsManager.getEquipementsProperties(
                poiLayer.nom,
                this.props.parentApi.data.zone.zone,
                this.props.parentApi.data.currentZone,
                (data) => {
                    if (poiLayer.typeGeom === "Point") {
                        // extraire les coordonnées x, y de la géométrie
                        for (let i in data) {
                            let coords = data[i]["geom"]
                                .replace("POINT(", "")
                                .replace(")", "")
                                .split(" ")
                                .map(parseFloat);
                            coords = transform(coords, "EPSG:3857", "EPSG:4326");
                            data[i]["x(EPGS:4326)"] = coords[0];
                            data[i]["y(EPGS:4326)"] = coords[1];
                            data[i]["geom(EPSG:3857)"] = data[i]["geom"];
                            delete data[i]["geom"];
                        }
                    }
                    for (let i in data) {
                        data[i] = {
                            ...data[i],
                            ...JSON.parse(data[i]["proprietes"]),
                        };
                        delete data[i]["proprietes"];
                    }
                    exportToCSV(
                        data,
                        this.state.selectedOption,
                        poiLayer.label,
                        pdfUrl,
                        pdfName
                    );
                }
            );
        }
        this.closeModal();
    }

    /*
     * Build html legend for current analysis
     */
    buildLegend() {
        let legend = "";
        let legends = [];
        let meta = this.props.parentApi.data.analysisMeta;
        let id_indicateur = this.props.parentApi.data.analysis;
        let titresLegende = "";
        let titreLegende = "";
        let titreLegendeAplats = "";
        let titre = "";
        let affichageProportion = false;

        if (id_indicateur && this.props.parentApi.controller.analysisManager) {
            let isActive =
                this.props.parentApi.controller.analysisManager.isActive(id_indicateur);
            if (isActive) {
                // Get values from the database
                titresLegende =
                    this.props.parentApi.controller.analysisManager.getLegendTitles(
                        id_indicateur
                    );
                titre =
                    this.props.parentApi.controller.analysisManager.getTitle(
                        id_indicateur
                    ).titre;
                affichageProportion =
                    this.props.parentApi.controller.analysisManager.getProportionForCircleDisplay(
                        id_indicateur
                    ).afficherProportion;
            }
        }

        let filtre = 0;
        if (id_indicateur) {
            filtre =
                this.props.parentApi.controller.analysisManager.getFilterDefaultValue(
                    id_indicateur
                );
        }
        if (titresLegende !== "") {
            titreLegende = titresLegende["titre_legende"];
            titreLegendeAplats = titresLegende["titre_legende_deuxieme_representation"];
        }
        let noLegendForPoi = true;
        for (let poiLayer of this.props.parentApi.data.poiLayers) {
            if (poiLayer.checked) noLegendForPoi = false;
        }
        if (!meta && noLegendForPoi) {
            return legend;
        }
        let filtreGammeDistance = "";
        if (meta && this.props.parentApi.data.analysis) {
            const handleChangeMaxRadius = (e) => {
                this.props.parentApi.callbacks.updateMetaStyle({
                    maxRadius: e,
                });
            };

            const handleAfterChangeFluxThreshold = (e) => {
                this.props.parentApi.callbacks.updateMapFilter(undefined, e, undefined);
            };

            const handleChangeFluxThreshold = (e) => {
                this.setState({ fluxThreshold: e });
            };

            const handleMethodClassChange = (e) => {
                this.props.parentApi.callbacks.updateClassMethod(e.target.value);
            };

            let maxRadius = this.props.parentApi.data.metaStyle.maxRadius; // TODO maxRadius should be renamed, because it is used also for maxWidth

            let { unit } =
                this.props.parentApi.controller.analysisManager.getUnitParamsForIndicator(
                    this.props.parentApi.data?.analysisSelectedUnit,
                    meta,
                    this.props.parentApi.data.zone.maille
                );

            if (meta.dataDeuxiemeRepresentation && !affichageProportion) {
                let min = parseInt(meta.bornesFiltre.min, 10);
                let max = parseInt(meta.bornesFiltre.max, 10) + 1;
                let valeurMax = max;
                let intervalleParDefaut;
                let slider;
                let indicationSeuil = "";
                let step = 1;
                if (meta.data_type === "accessibilite_emploi") {
                    // for "accessiblité emploi" indicator
                    step = 5; // distance threshold: from 5km to 50km every 5km
                    max = parseInt(meta.bornesFiltre.max, 10);
                    valeurMax = parseInt(meta.bornesFiltre.max, 10);
                    indicationSeuil = "Tous les distances (km)";
                    if (this.state.fluxThreshold) {
                        valeurMax = this.state.fluxThreshold;
                        indicationSeuil = "Seuil de distance (" + valeurMax + " km)";
                    }
                    intervalleParDefaut = valeurMax;
                    // the distance bar is unidirectional (threshold = 5, threshold = 10, etc ...)
                    slider = (
                        <Slider
                            className="threshold-slider-range"
                            defaultValue={intervalleParDefaut}
                            min={min}
                            max={max}
                            step={step}
                            onChange={handleChangeFluxThreshold}
                            onAfterChange={handleAfterChangeFluxThreshold}
                            value={intervalleParDefaut}
                        />
                    );
                } else {
                    if (
                        this.state.fluxThreshold &&
                        this.state.fluxThreshold.length === 2
                    ) {
                        filtre = this.state.fluxThreshold[0].toString();
                        valeurMax = this.state.fluxThreshold[1].toString();
                    }
                    indicationSeuil = "Entre " + filtre + " et " + valeurMax + " km";
                    if (parseInt(filtre, 10) !== 0 && parseInt(valeurMax, 10) >= 100) {
                        indicationSeuil = "Plus de " + filtre + " km";
                    } else if (
                        parseInt(valeurMax, 10) >= 100 &&
                        parseInt(filtre, 10) === 0
                    ) {
                        indicationSeuil = "Toutes les distances";
                    } else if (
                        parseInt(filtre, 10) === 0 &&
                        parseInt(valeurMax, 10) < 100
                    ) {
                        indicationSeuil = "Moins de " + valeurMax + " km";
                    }
                    intervalleParDefaut = [filtre, valeurMax];
                    slider = (
                        <Range
                            className="threshold-slider-range"
                            defaultValue={intervalleParDefaut}
                            min={min}
                            max={100}
                            step={step}
                            onChange={handleChangeFluxThreshold}
                            onAfterChange={handleAfterChangeFluxThreshold}
                            value={intervalleParDefaut}
                        />
                    );
                }
                filtreGammeDistance = (
                    <div
                        key="legend-control-deuxieme-filtre"
                        className="legend-control"
                    >
                        {indicationSeuil}
                        {slider}
                    </div>
                );
            }
            // Build the legend for the active indicator.
            const indicatorLegends = [];
            const indicatorLegendsSecondColumn = [];

            if (meta.type === "circle") {
                let color = meta.color_end;

                if (meta.min === 0) {
                    color = configData.circleZeroColor;
                }

                const forcedMinScale = meta?.representationDetails?.forcedMinScale;
                let max = meta.max;
                let min = forcedMinScale ?? meta.min;

                // Set the default value to 0 to get the circle radius value except for forced and negative values (to have a more realistic visual for legend according to the map)
                let defaultMinValue = 0;
                let val = min;
                if (min < 0) {
                    defaultMinValue = min;
                    val = max;
                }

                const minRadiusCircle = StylesApi.getPropotionalRadius(
                    configData.minRadiusCircle,
                    maxRadius,
                    forcedMinScale ? forcedMinScale : defaultMinValue,
                    max,
                    val
                );

                const styles = {
                    circleMin: {
                        width: 2 * minRadiusCircle + "px",
                        height: 2 * minRadiusCircle + "px",
                        borderRadius: minRadiusCircle + "px",
                        background: color,
                        marginLeft: maxRadius - minRadiusCircle + "px",
                        marginRight:
                            minRadiusCircle <= 10
                                ? maxRadius + "px"
                                : maxRadius - minRadiusCircle + "px",
                        marginBottom: minRadiusCircle <= 10 ? "0px" : "1px",
                    },
                    circleMax: {
                        width: 2 * maxRadius + "px",
                        height: 2 * maxRadius + "px",
                        borderRadius: maxRadius + "px",
                        background: meta.color_end,
                    },
                    circleWhenOnlyOneCircle: {
                        width: 2 * 0.75 * (minRadiusCircle + maxRadius) + "px",
                        height: 2 * 0.75 * (minRadiusCircle + maxRadius) + "px",
                        borderRadius: 0.75 * (minRadiusCircle + maxRadius) + "px",
                        background: meta.color_end,
                    },
                    labelMax: {
                        marginTop: maxRadius + "px",
                    },
                };

                if (min < 0) {
                    let minmax = max;
                    let maxmin = min;
                    min = minmax;
                    max = maxmin;
                }

                if (min === max) {
                    legend = (
                        <div className="legend-container legend-circle" key="0">
                            <p> {titreLegende} </p>
                            <div key="legend-min" className="legend-item legend-min">
                                <span
                                    className="circle-min legend-symbol"
                                    style={styles.circleWhenOnlyOneCircle}
                                ></span>
                                <label>
                                    <NumberFormat
                                        value={min}
                                        displayType={"text"}
                                        thousandSeparator={" "}
                                    />{" "}
                                    {unit}
                                </label>
                            </div>
                        </div>
                    );
                } else {
                    legend = (
                        <div className="legend-container legend-circle" key="0">
                            <p> {titreLegende} </p>
                            <div key="legend-min" className="legend-item legend-min">
                                <span
                                    className="circle-min legend-symbol"
                                    style={styles.circleMin}
                                ></span>
                                <label>
                                    <NumberFormat
                                        value={min}
                                        displayType={"text"}
                                        thousandSeparator={" "}
                                    />{" "}
                                    {unit}
                                </label>
                            </div>
                            <div key="legend-max" className="legend-item legend-max">
                                <span
                                    className="circle-max legend-symbol"
                                    style={styles.circleMax}
                                ></span>
                                <label style={styles.labelMax}>
                                    <NumberFormat
                                        value={max}
                                        displayType={"text"}
                                        thousandSeparator={" "}
                                    />{" "}
                                    {unit}
                                </label>
                            </div>
                            <div key="legend-control" className="legend-control">
                                <Slider
                                    defaultValue={configData.maxRadiusCircle}
                                    min={configData.minRadiusCircle}
                                    max={configData.maxRadiusCircle}
                                    onAfterChange={handleChangeMaxRadius}
                                />
                                Taille
                            </div>
                        </div>
                    );
                }

                indicatorLegends.push(legend);
            }

            if (meta.type === "flow") {
                legend = (
                    <div className="legend-container" key="1">
                        <div className="legend-control">
                            Épaisseur
                            <Slider
                                defaultValue={configData.minRadiusCircle}
                                min={configData.minRadiusCircle - 3}
                                max={configData.maxRadiusCircle - 30}
                                onAfterChange={handleChangeMaxRadius}
                            />
                            Flux (nombre de trajets)
                            <Slider
                                className="threshold-slider"
                                defaultValue={this.state.fluxThreshold}
                                min={configData.minFluxThreshold}
                                max={configData.maxFluxThreshold}
                                onChange={handleChangeFluxThreshold}
                                onAfterChange={handleAfterChangeFluxThreshold}
                                value={this.state.fluxThreshold}
                            />
                            Seuil : affichage des flux &gt; {this.state.fluxThreshold}{" "}
                            {/* &gt; corresponds to ">". ">" is a reserved character for html tags */}
                        </div>
                    </div>
                );
                indicatorLegends.push(legend);
            }
            if (meta.type === "pixels_cat") {
                let choroLegend = [];
                // we get colors code from meta data corresponding to current analysis
                let colorsCode = meta.colorsCode;
                // if it is not already computed by OlMap => compute it again...
                // TOFIX
                if (!colorsCode) {
                    colorsCode = {};
                    let data =
                        this.props.parentApi.controller.analysisManager.getDataMap(
                            id_indicateur
                        );
                    if (data.distinctValues) {
                        data.distinctValues.forEach((cat) => {
                            colorsCode[cat.valeur] = cat;
                        });
                    }
                }

                if (colorsCode) {
                    for (const c in colorsCode) {
                        if (Object.hasOwnProperty.call(colorsCode, c)) {
                            // the "color code" gives both value and color used
                            // corresponding to this class
                            const catCode = colorsCode[c];
                            let style = { background: catCode.couleur };
                            let key = "legend-item-" + catCode.valeur + c;
                            let label = "";

                            label = <label>{catCode.modalite}</label>;

                            choroLegend.push(
                                <div key={key} className="legend-item">
                                    <span
                                        className="legend-square legend-symbol"
                                        style={style}
                                    ></span>
                                    {label}
                                </div>
                            );
                        }
                    }
                    // if we have no result, it means that no value is available here
                    if (Object.keys(colorsCode).length === 0) {
                        choroLegend = (
                            <div>Pas de données disponibles pour ce territoire.</div>
                        );
                    }
                } else {
                    choroLegend = (
                        <div>Aucune légende n'a été définie pour cet indicateur.</div>
                    );
                }

                legend = (
                    <div className="legend-container" key="2">
                        <p> {titreLegendeAplats} </p>
                        {choroLegend}
                    </div>
                );
                indicatorLegends.push(legend);
            } else if (meta.type === "stars") {
                // we retrieve min and max star from details if available
                let minStar = 0,
                    maxStar = 5;
                if (meta.representationDetails) {
                    // we parse as integers
                    const _minStar = parseInt(
                        meta.representationDetails.minValue ?? 0,
                        10
                    );
                    const _maxStar = parseInt(
                        meta.representationDetails.maxValue ?? 5,
                        10
                    );
                    // just in case we don't have correct order here
                    minStar = Math.min(_minStar, _maxStar);
                    maxStar = Math.max(_minStar, _maxStar);
                }

                const data_map = Array(maxStar - minStar + 1)
                    .fill()
                    .map((x, i) => ({
                        val: i + minStar,
                    }));
                // we rebuild color scale
                const colorScale = StylesApi.getColorScale(
                    data_map,
                    data_map.length,
                    meta.color_start,
                    meta.color_end,
                    "equidistant",
                    meta.dataDeuxiemeRepresentation,
                    meta.type === "flow",
                    meta.data_type,
                    maxStar - minStar + 1
                );

                let choroLegend = [];
                // we then build the legend, at the same time stars and choropleth
                // legend alongside
                for (let nbStars = minStar; nbStars < maxStar + 1; nbStars++) {
                    // color used to fill shapes on map (choropleth)
                    let color = colorScale.scale(nbStars);
                    let style = { background: color };
                    // if we have a specific color chose to fill stars, we use it
                    let fullStarStyle = { backgroundColor: "darkblue" };
                    if (meta.representationDetails) {
                        if (meta.representationDetails.color) {
                            fullStarStyle.backgroundColor =
                                meta.representationDetails.color;
                        }
                    }

                    // then we display the right number of stars for this class
                    let currentStars = [];
                    for (
                        let currentStarNb = 0;
                        currentStarNb < maxStar;
                        currentStarNb++
                    ) {
                        currentStars.push(
                            currentStarNb >= nbStars ? (
                                <span className="analysis-legend-star analysis-empty-legend-star"></span>
                            ) : (
                                <span
                                    className="analysis-legend-star analysis-full-legend-star"
                                    style={fullStarStyle}
                                ></span>
                            )
                        );
                    }
                    choroLegend.push(
                        <p className="legend-item">
                            <span
                                className="legend-square legend-symbol"
                                style={style}
                            ></span>
                            {currentStars}
                        </p>
                    );
                }

                legend = (
                    <div className="legend-container" key="2">
                        <p> {titreLegendeAplats} </p>
                        {choroLegend}
                    </div>
                );

                if (meta.min === 0 && meta.max === 0) {
                    legend = (
                        <div className="legend-container" key="2">
                            <p>Pas de données</p>
                        </div>
                    );
                }

                indicatorLegends.push(legend);
            } else if (meta.type === "choropleth_cat") {
                let choroLegend = [];
                for (const modalite of meta.carto_category) {
                    choroLegend.push(
                        <label className="legend-item" key={modalite.modalite_id}>
                            <span
                                className="legend-square legend-symbol"
                                style={{ backgroundColor: modalite.couleur }}
                            ></span>
                            {modalite.modalite}
                        </label>
                    );
                }
                legend = (
                    <div className="legend-container" key="2">
                        <p>{titreLegende}</p>
                        {choroLegend}
                    </div>
                );
                indicatorLegends.push(legend);
            } else if (
                ["choropleth", "pixels", "flow"].includes(meta.type) ||
                meta.dataDeuxiemeRepresentation ||
                meta.afficherVersionSimple
            ) {
                let choroLegend = [];
                let nbClasses =
                    meta.nb_classes_color_representation &&
                    Number.isInteger(meta.nb_classes_color_representation)
                        ? meta.nb_classes_color_representation
                        : configData.nbClassChoropleth;
                if (meta.nbValeurs < nbClasses) {
                    nbClasses = meta.nbValeurs;
                }

                // we rebuild the color scale for choropleth as changes made
                // on classification method are not (yet?) propagated here
                let colorScale = meta.colorScale;
                if (
                    this.props.parentApi.data &&
                    this.props.parentApi.controller.analysisManager
                ) {
                    let data =
                        this.props.parentApi.controller.analysisManager.getDataMap(
                            id_indicateur
                        );
                    if (data.data) {
                        const deuxiemeRepresentation =
                            meta.dataDeuxiemeRepresentation ||
                            meta.afficherVersionSimple;

                        colorScale = StylesApi.getColorScale(
                            data.data,
                            data.data.length,
                            meta.color_start,
                            meta.color_end,
                            this.props.parentApi.data.classMethod,
                            deuxiemeRepresentation,
                            meta.type === "flow",
                            meta.data_type,
                            nbClasses
                        );
                    }
                }

                for (let c = 0; c < nbClasses; c++) {
                    let legendVal = colorScale.breaks[c];
                    let color = colorScale.scale(legendVal);
                    let style = { background: color };
                    let key = "legend-item-" + legendVal + c;
                    let label = "";
                    let lastIncluder = "[";

                    if (c === nbClasses - 1) {
                        lastIncluder = "]";
                    }

                    if (meta.dataDeuxiemeRepresentation || meta.afficherVersionSimple) {
                        unit = "%";
                    }
                    if (meta.data_type === "accessibilite_emploi") {
                        // the accessibility index has no direct meaning, replaced by : Très mauvaise, Assez mauvaise, Plutôt mauvaise, Plutôt bonne, Assez bonne, Très bonne
                        label = (
                            <label>
                                <span>{colorScale.classesLegende[c]}</span>
                            </label>
                        );
                    }
                    if (meta.data_type !== "accessibilite_emploi") {
                        label = (
                            <label>
                                <span>[</span>
                                <NumberFormat
                                    value={Number(colorScale.breaks[c].toFixed(2))}
                                    displayType={"text"}
                                    thousandSeparator={" "}
                                />
                                <span>&nbsp;à&nbsp;</span>
                                <NumberFormat
                                    value={Number(colorScale.breaks[c + 1].toFixed(2))}
                                    displayType={"text"}
                                    thousandSeparator={" "}
                                />{" "}
                                <span>
                                    {lastIncluder} {unit}
                                </span>
                            </label>
                        );
                    }
                    if (meta.type === "flow") {
                        style = { background: color, height: c + 2 + "px" };
                    }

                    choroLegend.push(
                        <div key={key} className="legend-item">
                            <span
                                className="legend-square legend-symbol"
                                style={style}
                            ></span>
                            {label}
                        </div>
                    );
                }

                legend = (
                    <div className="legend-container" key="2">
                        <p> {titreLegendeAplats} </p>
                        {choroLegend}
                    </div>
                );
                if (meta.type === "flow") {
                    legend = (
                        <div className="legend-container" key="2">
                            <p> {titreLegende} </p>
                            {choroLegend}
                        </div>
                    );

                    if (meta.min === 0 && meta.max === 0) {
                        legend = (
                            <div className="legend-container" key="2">
                                <p>
                                    {" "}
                                    {"Aucun flux de plus de " +
                                        this.state.fluxThreshold +
                                        " personnes"}{" "}
                                </p>
                            </div>
                        );
                    }
                }

                if (meta.min === 0 && meta.max === 0) {
                    legend = (
                        <div className="legend-container" key="2">
                            <p> {"Pas de données"} </p>
                        </div>
                    );
                }
                let pdfUrl = createPdfMethodoLink(
                    config.methodo_url,
                    this.props.parentApi.data.region,
                    config.classification_methodo
                );
                let classMethod = (
                    <div className="gamme" key="class-methodo">
                        <div key="classMethod" className="choisir-methode">
                            <label>Méthode de classification : </label>
                            <select
                                data-tip="Choisissez la méthode de classification"
                                value={this.props.parentApi.data.classMethod}
                                onChange={handleMethodClassChange}
                                key="classMethod"
                            >
                                <option key="equidistant" value="equidistant">
                                    Equidistant
                                </option>
                                <option key="quantile" value="quantile">
                                    Quantile
                                </option>
                                <option key="logarithmic" value="logarithmic">
                                    Logarithmique
                                </option>
                            </select>
                            <ReactTooltip place="top" type="error" effect="float" />
                        </div>
                        <a
                            data-tip="Note : la classification logarithmique n'autorise pas de valeurs inférieures ou égales à 0. Dans ce cas, la classification quantile sera utilisée"
                            href={pdfUrl}
                            onClick={() =>
                                this.suiviConsultation(
                                    "Classification",
                                    config.classification_methodo
                                )
                            }
                            target="_blank"
                            rel="noreferrer"
                        >
                            <div className="pdf"></div>
                        </a>
                    </div>
                );

                // If there is no second representation, simply add to the main list of legend elements.
                if (!meta.dataDeuxiemeRepresentation && !meta.afficherVersionSimple) {
                    indicatorLegends.push(legend);
                    indicatorLegends.push(classMethod);
                } else {
                    // In case of a double representation, legend and classMethod are displayed in a separate column.
                    indicatorLegendsSecondColumn.push(
                        <div className="aplats-couleurs" key="indicator-legend-row">
                            {legend}
                            {classMethod}
                        </div>
                    );
                }
            } else if (meta.type === "wms_feed") {
                legends.push(
                    <div className="legend-wms-parent">
                        <div className="legend-wms">
                            {meta.unit && <p>Valeurs en {meta.unit}</p>}
                            <img
                                crossorigin="Anonymous"
                                className={
                                    "legend-wms-" + this.props.parentApi.data.analysis
                                }
                                alt="Légende du flux WMS"
                            />
                        </div>
                    </div>
                );
            }

            let confidentialityLegend = (
                <div key="confidential-legend" className="confidential-legend">
                    Données confidentielles
                </div>
            );
            let unavailableLegend = (
                <div className="unavailable-legend" key="unavailable-legend">
                    {/* Name of the className determined by a ternary. The name refers to the appropriate CSS class (circle or shaded square).*/}
                    <div
                        className={
                            meta.type === "circle"
                                ? "fond-gris-for-circle"
                                : "fond-gris"
                        }
                    ></div>
                    <div className="unavailable-data">Données indisponibles</div>
                </div>
            );

            if (meta.confidentiel) {
                indicatorLegends.push(confidentialityLegend);
            }
            if (meta.indisponible) {
                indicatorLegends.push(unavailableLegend);
            }

            // add the indicator legends to the list of legends in one or two columns.
            if (indicatorLegendsSecondColumn.length === 0) {
                legends.push(...indicatorLegends);
            } else {
                legends.push(
                    <div
                        className="d-flex flex-row justify-content-between"
                        key="two-col-indicator-legend"
                    >
                        <div className="">{indicatorLegendsSecondColumn}</div>
                        <div className="">{indicatorLegends}</div>
                    </div>
                );
            }
        }

        if (this.props.parentApi.data.poiLayers) {
            const poiLegends = [];
            // Generate a legend for each POI layer that is checked
            this.props.parentApi.data.poiLayers
                .filter((poiLayer) => poiLayer.checked)
                .forEach((poiLayer, index) => {
                    let listeTypesInstallation = poiLayer.type_installation
                        ? poiLayer.type_installation.split(",")
                        : undefined;

                    // PDF Download button
                    let pdfUrl = createPdfMethodoLink(
                        config.methodo_url,
                        this.props.parentApi.data.region,
                        poiLayer.nom + ".pdf"
                    );
                    let typePOI;
                    if (poiLayer.typeGeom === "Point") {
                        typePOI = listeTypesInstallation
                            ? "POI multiple"
                            : "POI ponctuel";
                    } else {
                        typePOI = "POI non ponctuel";
                    }
                    let accesMethodo = (
                        <a
                            href={pdfUrl}
                            onClick={() =>
                                this.suiviConsultation(typePOI, poiLayer.nom + ".pdf")
                            }
                            className="pdf-href"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <div className="pdf"></div>
                        </a>
                    );

                    // Data download button
                    let exportCSV = (
                        <button
                            aria-hidden="true"
                            style={{ border: "none", padding: "1px" }}
                            title={"Exporter " + poiLayer.label}
                            className="csv poi-csv-widget bi-download"
                            onClick={() => this.openModal(poiLayer)}
                        />
                    );

                    // Generate the list of legend items to display for this POI layer.
                    // Only a POI layer with multiple types of installations will have more than one legend item.
                    let poiLayerLegendItems = [];

                    if (listeTypesInstallation) {
                        poiLayerLegendItems = listeTypesInstallation.map(
                            (typeInstall) => {
                                return {
                                    imgSource:
                                        "svg/" +
                                        this.props.parentApi.data.region +
                                        "/" +
                                        poiLayer.nom +
                                        "_" +
                                        slugify(typeInstall) +
                                        ".svg",
                                    label: typeInstall.replace("--", "/"),
                                };
                            }
                        );
                    } else if (poiLayer.typeGeom === "Point") {
                        poiLayerLegendItems = [
                            {
                                imgSource:
                                    "svg/" +
                                    this.props.parentApi.data.region +
                                    "/" +
                                    poiLayer.nom +
                                    "_legende.svg",
                                label: poiLayer.label,
                            },
                        ];
                    } else {
                        // for geometries other thant Point (i.e. lines and polygons), there is no image source, the icon displayed will be a line of the color of the layer.
                        poiLayerLegendItems = [
                            { imgSource: null, label: poiLayer.label },
                        ];
                    }

                    // add the legend items of the layer to the legend
                    poiLayerLegendItems.forEach((legendItem, index) => {
                        poiLegends.push(
                            <div key={legendItem.label} className="legend-item">
                                <span className="poi-legend legend-symbol">
                                    {
                                        // if the legend item has an image source, display it, otherwise display a colored line
                                        legendItem.imgSource ? (
                                            <img
                                                src={legendItem.imgSource}
                                                alt={
                                                    poiLayer.nom +
                                                    " " +
                                                    legendItem.label
                                                }
                                            />
                                        ) : (
                                            <span
                                                className="legend-line-symbol"
                                                style={{
                                                    backgroundColor: poiLayer.couleur,
                                                }}
                                            ></span>
                                        )
                                    }
                                </span>
                                <label>{legendItem.label}</label>
                                <div className="ms-auto d-flex gap-1 flex-row align-items-center">
                                    {index === 0 && poiLayer.exportable && exportCSV}
                                    {index === 0 && accesMethodo}
                                </div>
                            </div>
                        );
                    });

                    // Add sources and providers to the legend
                    poiLegends.push(
                        <div key={poiLayer.nom + "-sources"}>
                            <div className="content sources-analysis">
                                <DataSource
                                    label={
                                        poiLayer.creditsDataSources?.length > 1
                                            ? "Sources des données"
                                            : "Source des données"
                                    }
                                    tableSources={poiLayer.creditsDataSources}
                                />
                                <DataSource
                                    label={
                                        poiLayer.creditsDataProducers?.length > 1
                                            ? "Producteurs de l'indicateur"
                                            : "Producteur de l'indicateur"
                                    }
                                    tableSources={poiLayer.creditsDataProducers}
                                />
                            </div>
                        </div>
                    );

                    // Add a separator between each POI layer
                    if (
                        index <
                        this.props.parentApi.data.poiLayers.filter(
                            (poiLayer) => poiLayer.checked
                        ).length -
                            1
                    ) {
                        poiLegends.push(<hr key={"sep_poi_" + poiLayer.nom} />);
                    }
                });

            const isAnyLayerModifiable = this.props.parentApi.data.poiLayers.some(
                (poiLayer) => poiLayer.checked && poiLayer.modifiable
            );
            if (isAnyLayerModifiable && this.props.parentApi.data.connected) {
                poiLegends.push(<hr key="separator-before-poi-edit-help" />);
                poiLegends.push(
                    <p className="alert alert-info" key="poi-help">
                        Pour modifier ou supprimer un équipement, cliquez sur celui-ci
                        puis sur le crayon dans l'infobulle. Pour ajouter un équipement,
                        cliquez sur l'icône{" "}
                        <span
                            className="poi-add rounded"
                            style={{ display: "inline-block", verticalAlign: "middle" }}
                        ></span>{" "}
                        dans la liste de gauche.
                    </p>
                );
            }

            // Display the status In project / In service (En projet / En service) in the legend only for certain installations
            const displayStatus = this.props.parentApi.data.poiLayers.some(
                (poiLayer) => poiLayer.checked && poiLayer.afficherStatut
            );
            if (displayStatus) {
                poiLegends.push(<hr key="separator-before-status-legend" />);
                for (const statusSettings of configData.poi_layer_color) {
                    const iconClassName = statusSettings.enProjet
                        ? "cercle-projet"
                        : "cercle-service";
                    poiLegends.push(
                        <div
                            key={statusSettings.statut}
                            className="legend-item legend-min legend-installation-colors"
                        >
                            <span className={iconClassName + " legend-symbol"}></span>
                            <label>{statusSettings.statut}</label>
                        </div>
                    );
                }
            }

            // if there is a legend for the POI layers and the legend is not empty, add a separator
            if (poiLegends.length > 0 && legends.length > 0) {
                legends.push(<hr key="separator-before-poi-legend" />);
            }
            // add the poi legends
            legends.push(...poiLegends);
        }

        return {
            legends: legends,
            filtreGammeDistance: filtreGammeDistance,
            titre: titre,
        };
    }

    render() {
        let res = "";
        let noLegendForPoi = true;
        let titreLegende = "";
        if (this.props.parentApi.data.poiLayers) {
            for (let poiLayer of this.props.parentApi.data.poiLayers) {
                if (poiLayer.checked) {
                    noLegendForPoi = false;
                }
            }
        }
        if (this.props.parentApi.data.analysis !== undefined || !noLegendForPoi) {
            let legend = this.buildLegend();
            let classe = "legend float-widget";
            titreLegende = (
                <span>{legend.titre}</span>
                // <div className="titre-final">
                //     <p>{legend.titre}</p>
                // </div>
            );

            let modal = "";
            if (this.state.modalIsOpen) {
                let pdfName = this.state.poiToExport.nom + ".pdf";
                let pdfUrl = createPdfMethodoLink(
                    config.methodo_url,
                    this.props.parentApi.data.region,
                    pdfName
                );
                modal = (
                    <div className="modal">
                        <div className="modal-content">
                            <span className="close" onClick={this.closeModal}>
                                &times;
                            </span>
                            <p style={{ clear: "both" }}>
                                Sélectionner le format à exporter :{" "}
                            </p>
                            <br />
                            <form>
                                <div>
                                    <label>
                                        <input
                                            type="radio"
                                            value="csv"
                                            checked={
                                                this.state.selectedOption === "csv"
                                            }
                                            onChange={this.handleOptionChange}
                                        ></input>
                                        Fichier CSV
                                    </label>
                                </div>
                                {pdfUrl.indexOf("http") === 0 ? (
                                    ""
                                ) : (
                                    <div>
                                        <label>
                                            <input
                                                type="radio"
                                                value="zip"
                                                checked={
                                                    this.state.selectedOption === "zip"
                                                }
                                                onChange={this.handleOptionChange}
                                            ></input>
                                            Fichier CSV + PDF méthodo
                                        </label>
                                    </div>
                                )}
                                <br />
                                <div>
                                    <center>
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={() => {
                                                this.exportPoiToCsv(
                                                    this.state.poiToExport,
                                                    pdfUrl,
                                                    pdfName
                                                );
                                            }}
                                        >
                                            Exporter
                                        </button>
                                    </center>
                                </div>
                            </form>
                        </div>
                    </div>
                );
            }

            if (this.props.parentApi.data.analysisMeta) {
                if (
                    (this.props.parentApi.data.analysisMeta &&
                        this.props.parentApi.data.analysisMeta
                            .dataDeuxiemeRepresentation) ||
                    this.props.parentApi.data.analysisMeta.afficherVersionSimple
                ) {
                    classe = "legend legend-double-width float-widget";
                }
            }
            let classEye = "bi bi-eye-slash";
            if (this.props.parentApi.data.displayAnalysis) {
                classEye = "bi bi-eye";
            }
            res = (
                <div
                    className={
                        classe +
                        (this.state.display ? " legend-open" : " legend-closed")
                    }
                >
                    <button
                        type="button"
                        className={
                            this.state.display
                                ? "legend-close-button"
                                : "legend-open-button"
                        }
                        aria-label="Masquer/Afficher"
                        title={
                            (this.state.display ? "Masquer" : "Afficher") + " legende"
                        }
                        onClick={this.toggleDisplay}
                    >
                        {this.state.display && (
                            <div className="panel-close-icon" aria-hidden="true"></div>
                        )}
                    </button>
                    {this.state.display && (
                        <div className="legend-scroll-container">
                            {this.props.parentApi.data.analysis && (
                                <>
                                    <div className="d-flex flex-row align-items-center justify-content-between">
                                        <div className="titre-legende-conteneur">
                                            {titreLegende}
                                            {legend.filtreGammeDistance}
                                        </div>
                                        <button
                                            type="button"
                                            className={"legend-eye-button"}
                                            aria-label="Masquer/Afficher"
                                            title={
                                                (this.props.parentApi.data
                                                    .displayAnalysis
                                                    ? "Masquer"
                                                    : "Afficher") + " indicateur"
                                            }
                                            onClick={this.toggleDisplayAnalysis}
                                        >
                                            {this.state.display && (
                                                <i className={classEye}></i>
                                            )}
                                        </button>
                                    </div>
                                    {!this.props.parentApi.data.displayAnalysis &&
                                        legend.legends.length > 0 && <hr></hr>}
                                </>
                            )}
                            <div className="legend-content">{legend.legends}</div>
                        </div>
                    )}
                    {this.state.modalIsOpen && modal}
                </div>
            );
        }
        return res;
    }
}

export default Legend;
