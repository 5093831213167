/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import ReactTable from "react-table-6";

import Api from "../../Controllers/Api";
import { TablePreview } from "./DataManage";
import DetailsPopup from "../DetailsPopup";

import config from "../../settings.js";
import { buildRegionUrl, filterCaseInsensitive, exportToCSV } from "../../utils.js";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-table-6/react-table.css";

/**
 * This component is used to manage indicator categories
 */
class CategoriesManage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showDetailsTablesElement: false,
            detailsTable: "",
            detailsTableTitle: "",
            categoriesFullData: undefined,
            isLoadingData: false,
        };
        this.region = this.props.parentApi.data.region;
        this.getListeCategories();
    }

    componentDidMount() {
        if (!this.state.isLoadingData) {
            this.getCategoriesDataTables();
        }
    }

    /**
     * Retrieve the list of available categories
     * @return {JSON} the list of categories available for this region
     */
    getListeCategories() {
        Api.callApi(
            buildRegionUrl(
                config.api_categories_list_url,
                this.props.parentApi.data.region
            )
        )
            .then((response) => {
                let categories = [];
                for (let cat in response) {
                    categories.push({
                        nom: cat,
                        miseAJourEnabled: false,
                    });
                }
                this.setState({ categories: categories });
            })
            .catch((e) => this.setState({ status: e.message }));
    }

    /**
     * Retrieve list of categories tables contents
     * @return {json} the list of tables
     */
    getCategoriesDataTables() {
        this.setState({ isLoadingData: true });
        // Call API to retrieve analysis
        let url = buildRegionUrl(config.api_categories_data_list_url, this.region);
        Api.callApi(url, null, "GET")
            .then((response) => {
                this.setState({
                    categoriesFullData: response,
                    isLoadingData: false,
                });
            })
            .catch((e) => this.setState({ categoriesFullData: undefined }));
    }

    /**
     * update the component state with the uploaded category file
     */
    onFichierCategorieSelection = (event, row) => {
        let categories = JSON.parse(JSON.stringify(this.state.categories));
        for (let cat of categories) {
            if (row.props.row.nom === cat.nom) {
                cat.miseAJourEnabled = true;
                cat.fichier = event.target.files[0];
            } else {
                cat.miseAJourEnabled = false;
            }
        }
        this.setState({
            categories: categories,
        });
    };

    /**
     *
     * @param {obj} details the objects
     */
    toggleDetailsCell(details, title) {
        let toggle = this.state.showDetailsTablesElement;
        // we toggle between displaying details or not
        if (!toggle) {
            this.setState({
                showDetailsTablesElement: true,
                detailsTable: details,
                detailsTableTitle: title,
            });
        } else {
            this.setState({
                showDetailsTablesElement: false,
                detailsTable: "",
                detailsTableTitle: "",
            });
        }
    }

    /**
     * Export table data to CSV file using exportToCSV function (utils).
     */
    exportCategoryContent(categoryName) {
        if (
            this.state.categoriesFullData &&
            this.state.categoriesFullData[categoryName]
        ) {
            exportToCSV(this.state.categoriesFullData[categoryName], "csv");
        }
    }

    /**
     * @return {dom} the rendering of the component
     */
    render() {
        if (
            !this.props.connected ||
            !this.props.userInfos ||
            this.props.userInfos?.profil !== "admin"
        ) {
            return <div>Non accessible.</div>;
        }

        /**
         * Callback to update a category
         * @param  {json} analysis : current row
         */
        const updateCategory = (categorie) => {
            // Object of type FormData
            let formData = new FormData();

            for (let cat of this.state.categories) {
                if (cat.nom === categorie.props.original.nom) {
                    formData.append("fichierCategorie", cat.fichier);
                    formData.append("nomFichierfichierCategorie", cat.fichier.name);
                    break;
                }
            }

            formData.append("categorie", categorie.props.original.nom);

            // Call API with the file and the name of the category to update
            Api.callApi(
                buildRegionUrl(
                    config.api_categorie_url,
                    this.props.parentApi.data.region
                ),
                formData,
                "PUT",
                "default"
            )
                .then((response) => {
                    this.setState({ status: response.message });
                })
                .catch((e) => {
                    this.setState({ status: e.message });
                });
        };

        /**
         * Callback to delete a category
         * @param  {json} analysis : current row
         */
        const deleteCategory = (categorie) => {
            let url = buildRegionUrl(
                config.api_categorie_edit_url,
                this.props.parentApi.data.region
            ).replace("#categorie#", categorie.props.original.nom);
            // Call API with the name of the category to delete
            Api.callApi(url, null, "DELETE", "default")
                .then((response) => {
                    const newLine = "\r\n";
                    let r = window.confirm(
                        "Attention, cette opération n'est pas réversible. Voulez-vous supprimer cette catégorie ?" +
                            newLine +
                            newLine +
                            "Il y a : " +
                            newLine +
                            " - " +
                            response["related_charts"] +
                            " graphique(s) d'indicateurs liés (automatiquement désactivés après suppression)" +
                            newLine +
                            " - " +
                            response["related_datatables"] +
                            " table(s) de données ayant cette catégorie (inchangé(e)s après suppression)." +
                            newLine +
                            " - " +
                            response["related_dashboards"] +
                            " tableau(x) de bords ayant cette catégorie (graphiques retirés après suppression)."
                    );
                    if (r === true) {
                        url += "?confirm=true";
                        Api.callApi(url, null, "DELETE", "default")
                            .then((second_response) => {
                                this.setState({ status: second_response.message });
                                this.getListeCategories();
                            })
                            .catch((e) => {
                                this.setState({ status: e.message });
                            });
                    }
                })
                .catch((e) => {
                    this.setState({ status: e.message });
                });
        };

        // Show errors
        let erreurs = "";
        if (this.state.status) {
            erreurs = <div className="alert alert-warning">{this.state.status}</div>;
        }

        const columns = [
            {
                Header: "Nom",
                accessor: "nom",
            },
            {
                Header: "Aperçu de la table",
                accessor: "table_preview",
                Cell: (props) => {
                    // we get the data from full categories query results
                    // (if available), else we send empty object.
                    const data =
                        this.state.categoriesFullData &&
                        this.state.categoriesFullData[props.original.nom]
                            ? this.state.categoriesFullData[props.original.nom]
                            : {};

                    const downloadButton = (
                        <p className="export-data-button-in-popup">
                            <button
                                className="btn btn-info"
                                onClick={() =>
                                    this.exportCategoryContent(props.original.nom)
                                }
                            >
                                Exporter le contenu de la table en csv
                            </button>
                        </p>
                    );
                    return (
                        <div>
                            <img
                                className="table_preview_icon"
                                src="img/table_preview.svg"
                                alt="Visualiser une partie des données"
                                title="Visualiser une partie des données"
                                onClick={() =>
                                    this.toggleDetailsCell(
                                        <TablePreview
                                            data={data}
                                            additionalElements={downloadButton}
                                        />,
                                        props.original.nom
                                    )
                                }
                            />
                        </div>
                    );
                },
                filterable: false,
            },
            {
                id: "activate",
                Header: "Action",
                accessor: "actif",
                Cell: (props) => (
                    <div className="actions">
                        <input
                            type="file"
                            onChange={(e) => {
                                this.onFichierCategorieSelection(e, {
                                    props,
                                });
                            }}
                        />
                        <button
                            className={
                                "btn btn-warning " +
                                (props.original.miseAJourEnabled ? "" : "hidden")
                            }
                            onClick={() => updateCategory({ props })}
                        >
                            Mettre à jour
                        </button>
                        <button
                            className="btn btn-danger"
                            onClick={() => deleteCategory({ props })}
                        >
                            Supprimer
                        </button>
                    </div>
                ),
            },
        ];
        return (
            <div>
                {erreurs}
                {this.state.showDetailsTablesElement ? (
                    <DetailsPopup
                        title={
                            <>
                                Contenu de la table{" "}
                                <em>{this.state.detailsTableTitle}</em>{" "}
                            </>
                        }
                        content={this.state.detailsTable}
                        show={this.state.showDetailsTablesElement}
                        emptyMsg="Nothing to show"
                        callbackAfterClosing={() => this.toggleDetailsCell()}
                    />
                ) : null}
                <div className="panel-body user-scenarii">
                    <h3 className="panel-title pull-left">Gestion des catégories</h3>
                    <ReactTable
                        data={this.state.categories}
                        columns={columns}
                        className="-striped"
                        defaultPageSize={30}
                        filterable={true}
                        defaultFilterMethod={(filter, row) =>
                            filterCaseInsensitive(filter, row)
                        }
                    />
                </div>
            </div>
        );
    }
}

export default CategoriesManage;
