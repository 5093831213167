/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React, { useState, useEffect } from "react";
import ReactTable from "react-table-6";
import { Link, useParams } from "react-router-dom";

import Api from "../../Controllers/Api.js";
import config from "../../settings.js";
import { buildRegionUrl, filterCaseInsensitive } from "../../utils.js";
import DetailsPopup from "../DetailsPopup.js";
import TablePreview from "./TablePreview.js";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-table-6/react-table.css";
import "../../style/national/dataimport.css";

const DataManageDashboard = (props) => {
    const [tables, setTables] = useState([]);
    const [showDetailsTablesElement, setShowDetailsTablesElement] = useState(false);
    const [detailsTable, setDetailsTable] = useState("");
    const { region } = useParams();

    useEffect(() => {
        const url = buildRegionUrl(config.api_national_import_metadata, region);
        Api.callApi(url, null, "GET")
            .then((response) => {
                console.log("response metadata", response);
                setTables(response);
            })
            .catch((e) => console.error(e));
    }, [region]);

    const toggleDetailsCell = (details) => {
        setShowDetailsTablesElement(!showDetailsTablesElement);
        setDetailsTable(details);
    };

    const downloadButton = (tableName) => {
        let url = buildRegionUrl(
            config.api_analysis_data_national_export,
            region
        ).replace("#table_name#", tableName);
        return (
            <p className="export-data-button-in-popup">
                <a
                    className="btn btn-info normal-size"
                    target="_blank"
                    rel="noreferrer"
                    href={url}
                >
                    Exporter la table en csv
                </a>
            </p>
        );
    };

    const columns = [
        {
            Header: "Etat",
            accessor: "etat",
            Cell: (props) => (
                <span className="etat-import">
                    {props.original.data_exist ? (
                        <span className="bi bi-check exist"> Table valide</span>
                    ) : (
                        <span className="bi bi-x not-exist"> Table non importée</span>
                    )}
                </span>
            ),
            filterable: false,
        },
        {
            Header: "Nom de la table",
            accessor: "table_name",
        },
        {
            Header: "Date de mise à jour",
            accessor: "date_maj",
        },
        {
            Header: "Aperçu de la table",
            accessor: "table_preview",
            Cell: (props) => (
                <button
                    className="unstyled"
                    onClick={() =>
                        toggleDetailsCell(
                            <TablePreview
                                data={props.original.header}
                                additionalElements={
                                    props.original.data_exist
                                        ? downloadButton(props.original.nom)
                                        : "Aucune donnée à afficher"
                                }
                            />
                        )
                    }
                >
                    <img
                        className="table_preview_icon"
                        src="/img/table_preview.svg"
                        alt="Visualiser une partie des données"
                        title="Visualiser une partie des données"
                    />
                </button>
            ),
            filterable: false,
        },
        {
            id: "action",
            accessor: "action",
            Header: "Actions",
            Cell: (row) => {
                const params = {
                    table: row.original.table_key,
                    tables: tables,
                };
                const label = row.original.data_exist ? "Modifier" : "Importer";
                const className = row.original.data_exist ? "info" : "success";
                return (
                    <Link
                        to={{
                            pathname:
                                "/national/" +
                                props.parentApi.data.region +
                                "/import/formulaire",
                            state: { params: params },
                        }}
                        className={`tsbtn ${className}`}
                    >
                        {label}
                    </Link>
                );
            },
            filterable: false,
        },
    ];

    return (
        <div className="panel panel-default centered-row data-manage-dashboard">
            <div>
                <Link
                    className="btn btn-light return-button"
                    to={"/national/" + props.parentApi.data.region + "/portail"}
                >
                    <i className="bi bi-arrow-left"></i>
                </Link>
            </div>
            {showDetailsTablesElement && (
                <DetailsPopup
                    title="Aperçu de la table"
                    content={detailsTable}
                    show={showDetailsTablesElement}
                    emptyMsg="Nothing to show"
                    callbackAfterClosing={() => toggleDetailsCell()}
                />
            )}
            <div className="panel-body user-scenarii">
                <h3 className="tstitle">Import des données</h3>
                <ReactTable
                    data={tables}
                    columns={columns}
                    className="-striped"
                    defaultPageSize={30}
                    filterable={true}
                    defaultFilterMethod={(filter, row) =>
                        filterCaseInsensitive(filter, row)
                    }
                />
            </div>
        </div>
    );
};

export default DataManageDashboard;
