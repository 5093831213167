/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { saveAs } from "file-saver";

import Api from "../../Controllers/Api";
import Actions from "./Actions";
import Trajectories from "./Trajectories";
import Impacts from "./Impacts";
import { NewDetailsPopup as DetailsPopup } from "../DetailsPopup";
import { buildRegionUrl, createPdfMethodoLink } from "../../utils";
import config from "../../settings";
import configData from "../../settings_data";

import "../../style/strategies.css";

/**
 * Page for strategy editing. It gets the current strategy id from URL.
 * This page loads data for the whole page in several requests.
 */
export default function StrategyEdition({ parentApi, location }) {
    // Data independent of the current status
    const [lastPossibleReferenceYear, setLastPossibleReferenceYear] = useState(0);
    const [trajectoriesMeta, setTrajectoriesMeta] = useState([]);

    // Data dependent on the current zone
    const [actionsMeta, setActionsMeta] = useState([]);
    const [refTrajectories, setRefTrajectories] = useState({});

    // Data dependant on the current strategy
    const [strategy, setStrategy] = useState(undefined);
    const [trajectories, setTrajectories] = useState({});
    const [actionsParams, setActionsParams] = useState([]);
    const [advancedParams, setAdvancedParams] = useState([]);
    const [activeActions, setActiveActions] = useState({});

    // Data created by the component
    const [actionErrors, setActionErrors] = useState({});
    const [impacts, setImpacts] = useState(undefined);
    const [status, setStatus] = useState({});
    const [isLoadingImpacts, setIsLoadingImpacts] = useState(true);
    const [isLoadingSave, setIsLoadingSave] = useState(false);
    const [paramsBackup, setParamsBackup] = useState("");

    const [showHelp, setShowHelp] = useState(false);
    const [showExport, setShowExport] = useState(false);

    /*console.log({ lastPossibleReferenceYear, trajectoriesMeta, actionsMeta, refTrajectories,
    strategy, trajectories, actionsParams, advancedParams, activeActions, }); // prettier-ignore*/

    // HACK needed because index.js doesn't use hook useCallback
    const setMainZone = useRef(parentApi.callbacks.updateZone).current;
    const updateProvenance = useRef(parentApi.callbacks.updateProvenance).current;

    useEffect(() => {
        // If we come from strategy creation, show the help popup if the local config enables it
        if (parentApi.data.provenance !== "strategy_creation") return;
        setShowHelp(localStorage.getItem("strategy-hide-help") == null);
        updateProvenance("");
    }, [parentApi.data.provenance, updateProvenance]);

    const { region } = parentApi.data;
    // Data independent of the current status
    useEffect(() => {
        const tablesProjection = [
            "DataSet.CONSUMPTION",
            "DataSet.EMISSIONS",
            "DataSet.PRODUCTION",
        ];
        const url = buildRegionUrl(config.api_liste_annees_url, region);
        const promises = tablesProjection.map((table) =>
            Api.callApi(url.replace("#table#", table))
        );
        Promise.all(promises)
            .then((responses) => {
                const minLastDataYear = Math.min(
                    ...responses.map((years) => years[years.length - 1].value)
                );
                setLastPossibleReferenceYear(minLastDataYear);
            })
            .catch((error) => {
                if (error.name !== "AbortError") throw error;
            });
        return () => promises.forEach((promise) => promise.abort());
    }, [region]);

    const trajMetaUrl = buildRegionUrl(config.api_pcaet_trajectories_list, region);
    Api.useLoadData(trajMetaUrl, setTrajectoriesMeta, console.error);

    useEffect(() => {
        if (!trajectoriesMeta.length) return;
        setTrajectories((trajs) => {
            for (const { id } of trajectoriesMeta) {
                if (trajs[id] === undefined) {
                    trajs = { ...trajs, [id]: {} };
                }
            }
            return trajs;
        });
    }, [trajectoriesMeta]);

    Api.useLoadData(
        buildRegionUrl(config.api_plan_actions_coeffs_url, region),
        useCallback((defaultParams) => {
            setActionsParams((actionsParams) =>
                updateIndexedArray(defaultParams, actionsParams, "id")
            );
        }, []),
        console.error
    );

    // Data dependant on the current zone
    const zoneType = parentApi.data.zone?.zone;
    const zoneId = parentApi.data.currentZone;
    useEffect(() => {
        if (!zoneType || !zoneId) return;

        let loadActionsMetadataUrl =
            buildRegionUrl(config.api_plan_actions_actions_url, region) +
            `?zone=${zoneType}&zone_id=${zoneId}`;
        const promise = Api.callApi(loadActionsMetadataUrl);
        promise
            .then(({ actions }) => {
                setActionsMeta(actions);
            })
            .catch((e) => {
                if (e.name === "AbortError") return;
                console.error(e);
            });
        return () => promise.abort();
    }, [region, zoneType, zoneId]);

    const defaultAvancedUrl =
        buildRegionUrl(config.api_plan_actions_advanced_params_url, region) +
        `?zone=${zoneType}&zone_id=${zoneId}`;
    Api.useLoadData(
        defaultAvancedUrl,
        useCallback(({ actions }) => {
            setAdvancedParams((advancedParams) =>
                updateIndexedArray(actions, advancedParams, "action")
            );
        }, []),
        console.error
    );

    const baseRefTrajectoriesUrl =
        zoneType && zoneId
            ? buildRegionUrl(config.api_pcaet_trajectory_details, region) +
              `?zone=${zoneType}&zone_id=${zoneId}`
            : undefined;
    useEffect(() => {
        if (!baseRefTrajectoriesUrl) return;
        const promises = trajectoriesMeta.map((meta) =>
            Api.callApi(baseRefTrajectoriesUrl.replace("#trajectory_id#", meta.id))
        );
        Promise.all(promises)
            .then((responses) => {
                const refTrajs = Object.fromEntries(
                    responses.map((traj, i) => [trajectoriesMeta[i].id, traj])
                    /*traj.filter(({ affectation }) => {
                        // Is it needed to check for affectation in front ?
                        const affectation = JSON.parse(affectation);
                        const isAffectationOK =
                            affectation[zoneType + "s"] === "" ||
                            affectation[zoneType] === zoneId;
                        return isAffectationOK;
                    }),*/
                );
                setRefTrajectories(refTrajs);
            })
            .catch((error) => {
                if (error.name !== "AbortError") throw error;
            });
        return () => promises.forEach((promise) => promise.abort());
    }, [trajectoriesMeta, baseRefTrajectoriesUrl]);

    // Data dependant on the current strategy
    useEffect(() => {
        // If the URL ends by a number, use it as the strategy ID
        const strategyId = location.pathname.match(/(?<=\/)[0-9]+$/)?.[0];
        if (strategyId == null) return;

        let url = buildRegionUrl(config.user_scenario_url, region);
        url += "/" + strategyId;
        const promise = Api.callApi(url);
        promise
            .then((value) => {
                const { actions, params, advanced, trajectoires, ...strategy } = value;
                setStrategy(strategy);

                const [zoneType, zoneMaille] = strategy.zone_type.split("#");
                setMainZone(zoneType, zoneMaille ?? zoneType, strategy.zone_id);

                const { energie_economisee, energie_produite, ...otherTrajs } =
                    trajectoires;
                setTrajectories({
                    consommation_ener: energie_economisee,
                    enr_production: energie_produite,
                    ...otherTrajs,
                });
                convertParamsToNumber(params);
                setActionsParams((actionsParams) =>
                    updateIndexedArray(actionsParams, params, "id")
                );
                setAdvancedParams((advancedParams) =>
                    updateIndexedArray(advancedParams, advanced, "action")
                );
                const filledActions = {};
                for (const param of params) {
                    filledActions[param.action] |=
                        param.valeur && Object.keys(param.valeur).length > 0;
                }
                const activeActions = Object.fromEntries(
                    Object.keys(filledActions)
                        .filter((a) => filledActions[a])
                        .map((a) => [a, actions.includes(a)])
                );
                setActiveActions(activeActions);
            })
            .catch((error) => {
                if (error.name === "AbortError") return;
                console.error(error);
                setStatus({ type: "error", message: error.message });
            });
        return () => promise.abort();
    }, [location, region, setMainZone]);

    useEffect(() => {
        // If the strategy is loaded but doesn't have a reference year
        // Set it to the last year where all indicators are available
        if (lastPossibleReferenceYear && strategy?.referenceYear === undefined) {
            setStrategy({
                ...(strategy ?? {}),
                referenceYear: lastPossibleReferenceYear,
            });
        }
    }, [lastPossibleReferenceYear, strategy]);

    useEffect(() => {
        if (!actionsParams?.length || !actionsMeta?.length) return;
        const filledActions = {};
        for (const action of actionsMeta) {
            filledActions[action.numero] = actionsParams.some(
                (param) =>
                    param.action === action.numero &&
                    param.valeur &&
                    Object.keys(param.valeur).length > 0
            );
        }
        setActiveActions((oldActions) => {
            const newActions = { ...oldActions };
            let hasChanged = false;
            for (const actionNb in filledActions) {
                if (filledActions[actionNb] && newActions[actionNb] === undefined) {
                    newActions[actionNb] = hasChanged = true;
                }
                if (!filledActions[actionNb] && newActions[actionNb] !== undefined) {
                    delete newActions[actionNb];
                    hasChanged = true;
                }
            }
            return hasChanged ? newActions : oldActions;
        });
    }, [actionsParams, actionsMeta]);

    function saveStrategy() {
        if (!strategy?.id) {
            console.error("Il n'y a pas de stratégie territoriale active");
            return;
        }

        setIsLoadingSave(true);

        const { consommation_ener, enr_production, ...otherTrajs } = trajectories;

        const strategyToUpload = {
            titre: strategy.titre,
            description: strategy.description,
            referenceYear: strategy.referenceYear,
            trajectoires: {
                energie_economisee: consommation_ener,
                energie_produite: enr_production,
                ...otherTrajs,
            },
            actions: Object.keys(activeActions).filter((nb) => activeActions[nb]),
            advanced: advancedParams,
        };
        actionsParams.forEach((action) => {
            if (action.nom && action.valeur && Object.keys(action.valeur).length > 0) {
                strategyToUpload[action.nom] = action.valeur;
            }
        });

        let url = buildRegionUrl(config.user_scenario_url, region) + "/" + strategy.id;
        Api.callApi(url, JSON.stringify(strategyToUpload), "PUT")
            .then((response) => {
                setIsLoadingSave(false);
                setStatus({
                    type: "success",
                    message: "Stratégie territoriale mise à jour !",
                });
                setTimeout(() => setStatus({}), 5000);
            })
            .catch((error) => {
                setIsLoadingSave(false);
                console.error(error);
            });
    }

    useEffect(() => setIsLoadingImpacts(actionsMeta.length === 0), [actionsMeta]);
    function computeImpacts() {
        setIsLoadingImpacts(true);
        setParamsBackup(
            JSON.stringify({ activeActions, actionsParams, advancedParams })
        );
        const url =
            buildRegionUrl(
                config.api_plan_actions_running_plan,
                parentApi.data.region
            ) +
            `?zone=${parentApi.data.zone.zone}` +
            `&zone_id=${parentApi.data.currentZone}` +
            `&id_utilisateur=${parentApi.controller.gestionSuiviConsultations.idUtilisateur}`;
        const processedActionParams = processStrategyForImpactCalculation(
            actionsMeta,
            activeActions,
            actionsParams,
            advancedParams
        );
        const strategyToUpload = {
            actions: Object.keys(activeActions).filter((nb) => activeActions[nb]),
            advanced: advancedParams,
            ...processedActionParams,
        };
        Api.callApi(url, JSON.stringify(strategyToUpload), "POST")
            .then((response) => {
                setIsLoadingImpacts(false);
                if (response.failed_actions) {
                    // TODO TOFIX
                    let disabledActions = response.failed_actions.map((a) => {
                        return a.action_key;
                    });
                    let actions = [...this.state.actions];
                    for (const i in actions) {
                        if (disabledActions.includes(actions[i].numero)) {
                            actions[i].enabled = false;
                        }
                    }
                    const warningDisabledActions = (
                        <DetailsPopup
                            title="Actions désactivées au cours de l'exécution"
                            isOpen
                            onClose={() => setStatus({})}
                        >
                            <p>
                                Les actions suivantes ont été automatiquement
                                désactivées à cause d'une erreur survenue au cours de
                                l'exécution. Si l'erreur se reproduit, merci de
                                transmettre les informations et les détails de la
                                situation à un administrateur.
                            </p>
                            <ul>
                                {response.failed_actions.map((a) => (
                                    <li key={a.action_key}>{a.name}</li>
                                ))}
                            </ul>
                        </DetailsPopup>
                    );
                    setStatus({ type: "raw", message: warningDisabledActions });
                } else {
                    // Store results
                    const { energie_economisee, energie_produite, ...otherImpacts } =
                        response;
                    setImpacts({
                        consommation_ener: energie_economisee,
                        enr_production: energie_produite,
                        ...otherImpacts,
                    });
                }
            })
            .catch((error) => {
                if (error.name === "AbortError") return;
                setIsLoadingImpacts(false);
                setStatus({ type: "error", message: error.message });
                console.error(error);
            });
    }

    async function exportStrategy(format) {
        let url, fileName;
        if (format === "pcaet") {
            url = config.api_export_strategy_ademe;
            fileName = "export-pcaet-ademe.xlsx";
        } else if (format === "impacts") {
            url = config.api_export_strategy_results;
            fileName = "export-impacts.xlsx";
        }
        if (url === undefined) throw Error("Unknown export format");
        url =
            buildRegionUrl(url, parentApi.data.region) +
            `?zone=${parentApi.data.zone.zone}` +
            `&zone_id=${parentApi.data.currentZone}` +
            `&id_utilisateur=${parentApi.controller.gestionSuiviConsultations.idUtilisateur}`;
        let strategyToUpload;
        if (format === "impacts") {
            let customTraj = Object.fromEntries(
                Object.entries(trajectories).map(
                    ([key, { annees_valeurs, annees_modifiees }]) => {
                        if (key === "consommation_ener") key = "energie_economisee";
                        if (key === "enr_production") key = "energie_produite";
                        return [
                            key,
                            {
                                annees: Object.keys(annees_valeurs),
                                valeurs: Object.values(annees_valeurs),
                                annees_modifiees: Object.values(annees_modifiees),
                            },
                        ];
                    }
                )
            );
            let target = {};
            for (const id in refTrajectories) {
                if (refTrajectories[id].pcaet_trajectory?.length) {
                    const traj = refTrajectories[id].pcaet_trajectory;
                    const yearsList = traj[0].data.map(({ annee }) => annee);
                    for (const year of yearsList) {
                        // Sum all categories corresponding to current year to get total value
                        const value = traj
                            .map(
                                ({ data }) =>
                                    data.find(({ annee }) => annee === year)?.valeur ??
                                    0
                            )
                            .reduce((a, b) => a + b, 0);
                        target[id + "_annee_" + year] = value;
                    }
                }
            }
            strategyToUpload = {
                parameters: {
                    actions: actionsMeta.map((meta) => ({
                        ...meta,
                        enabled: activeActions[meta.numero],
                    })),
                    coeffs: actionsParams.map(({ valeur, ...param }) => ({
                        ...param,
                        valeur_annee: valeur ?? {},
                    })),
                    coeffsAdvanced: advancedParams,
                },
                results: (() => {
                    const { consommation_ener, enr_production, ...otherImpacts } =
                        impacts;
                    return {
                        energie_economisee: consommation_ener,
                        energie_produite: enr_production,
                        ...otherImpacts,
                    };
                })(),
                trajectories: {
                    custom: customTraj,
                    pcaet: Object.keys(target).length > 0,
                    target,
                    target_ref: (() => {
                        const { consommation_ener, enr_production, ...otherTrajs } =
                            refTrajectories;
                        return Object.fromEntries(
                            Object.entries({
                                energie_economisee: consommation_ener,
                                energie_produite: enr_production,
                                ...otherTrajs,
                            }).map(([key, { supra_goals }]) => [key, supra_goals])
                        );
                    })(),
                },
                title: strategy.titre,
            };
        } else {
            const processedActionParams = processStrategyForImpactCalculation(
                actionsMeta,
                activeActions,
                actionsParams,
                advancedParams
            );
            strategyToUpload = {
                actions: Object.keys(activeActions).filter((nb) => activeActions[nb]),
                advanced: advancedParams,
                title: strategy.titre,
                ...processedActionParams,
            };
        }
        try {
            const response = await fetch(url, {
                method: "POST",
                body: JSON.stringify(strategyToUpload),
                credentials: "include",
            });
            console.log(response);
            if (!response.ok) {
                throw Error(await response.text());
            }
            saveAs(await response.blob(), fileName);
        } catch (error) {
            setStatus({ type: "error", message: error.message });
            console.error(error);
        }
    }

    const impactsSettings = parentApi.data.settings["impacts"];
    const actionsHaveChanged =
        JSON.stringify({ activeActions, actionsParams, advancedParams }) !==
        paramsBackup;

    const zoneName = parentApi.controller.zonesManager
        ? parentApi.controller.zonesManager.getZoneName(
              parentApi.data.currentZone,
              parentApi.data.zone
          )
        : "(inconnu)";

    let returnButton = (
        <Link className="back-to-map" to={"/" + parentApi.data.urlPartageable}>
            <span className="big">&times;</span> <span>Retour à la carte</span>
        </Link>
    );
    if (parentApi.data.settings?.is_national) {
        returnButton = (
            <div>
                <Link
                    className="btn btn-light return-button"
                    to={"/national/" + parentApi.data.region + "/portail"}
                >
                    <i className="bi bi-arrow-left"></i>
                </Link>
            </div>
        );
    }
    return (
        <div className="widgets full-screen-widget">
            {returnButton}
            <div className="ui-main">
                <button
                    className="help strategy-help-button"
                    onClick={() => setShowHelp(true)}
                >
                    Aide
                </button>
                <h1 className="tstitle left">
                    Élaborer une stratégie{strategy?.titre && " : " + strategy.titre}
                </h1>
                <p>
                    <b>Territoire</b> : {zoneName}
                </p>
                <div className="strategy-edit-buttons">
                    {strategy?.id && (
                        <>
                            <button
                                className="tsbtn info"
                                onClick={saveStrategy}
                                disabled={isLoadingSave}
                            >
                                {isLoadingSave && <span className="spinner-border" />}
                                Sauvegarder
                            </button>
                            {/*<button className="tsbtn">Renommer</button>*/}
                        </>
                    )}
                    <button className="tsbtn" onClick={() => setShowExport(true)}>
                        Exporter...
                    </button>
                    {/*!strategy?.id && (
                        <span>
                            <i className="bi bi-info-circle" /> Connectez-vous afin de
                            sauvegarder votre stratégie
                        </span>
                    )*/}
                </div>
                {status?.type === "raw" ? (
                    status.message
                ) : status?.type === "error" ? (
                    <div className="alert alert-warning">{status.message}</div>
                ) : status?.type === "success" ? (
                    <div className="alert alert-success">{status.message}</div>
                ) : null}
                <Tabs>
                    <TabList className="tabs-primary">
                        <Tab title="Définir les trajectoires cibles">
                            <span>Trajectoires</span>
                        </Tab>
                        <Tab title="Paramétrer les actions mises en œuvre">
                            <span>Actions</span>
                        </Tab>
                        <Tab title="Visualiser les impacts des actions">
                            <span>Impacts</span>
                        </Tab>
                    </TabList>
                    <TabPanel>
                        <Trajectories
                            strategy={strategy}
                            trajectoriesMeta={trajectoriesMeta}
                            refTrajectories={refTrajectories}
                            trajectories={trajectories}
                            setTrajectories={setTrajectories}
                        />
                    </TabPanel>
                    <TabPanel>
                        <Actions
                            strategy={strategy}
                            actionsMeta={actionsMeta}
                            params={actionsParams}
                            setParams={setActionsParams}
                            advancedParams={advancedParams}
                            setAdvancedParams={setAdvancedParams}
                            activeActions={activeActions}
                            setActionErrors={setActionErrors}
                        />
                    </TabPanel>
                    <TabPanel>
                        <Impacts
                            strategy={strategy}
                            actionsMeta={actionsMeta}
                            activeActions={activeActions}
                            setActiveActions={setActiveActions}
                            computeImpacts={computeImpacts}
                            impacts={impacts}
                            trajectoriesMeta={trajectoriesMeta}
                            refTrajectories={refTrajectories}
                            trajectories={trajectories}
                            isLoadingImpacts={isLoadingImpacts}
                            impactsSettings={impactsSettings}
                            zoneName={zoneName}
                            actionsHaveChanged={actionsHaveChanged}
                            actionErrors={actionErrors}
                        />
                    </TabPanel>
                </Tabs>
            </div>
            <HelpPopup
                parentApi={parentApi}
                isOpen={showHelp}
                onClose={() => setShowHelp(false)}
            />
            <ExportPopup
                isOpen={showExport}
                onClose={() => setShowExport(false)}
                onValidate={exportStrategy}
                isImpactAllowed={impacts !== undefined}
            />
        </div>
    );
}

function HelpPopup({ parentApi, isOpen, onClose }) {
    const pdfUrl = createPdfMethodoLink(
        config.methodo_url,
        parentApi.data.region,
        configData.methodoPlanActionsPdf
    );
    return (
        <DetailsPopup
            title="Élaborer une stratégie"
            className="large strategy-help"
            {...{ isOpen, onClose }}
        >
            <p>
                Pour élaborer votre stratégie, nous vous proposons une démarche en trois
                temps :
            </p>
            <ol className="steps">
                <li>Définissez vos TRAJECTOIRES cibles (objectifs PCAET)</li>
                <li>
                    Choisissez et paramétrez les ACTIONS que vous souhaitez mettre en
                    œuvre
                </li>
                <li>
                    Visualisez les IMPACTS de vos actions relativement à vos
                    trajectoires cibles
                </li>
            </ol>
            <div className="center-block">
                <h3>Avant de commencer</h3>
                <p>Nous vous invitons à préparer les éléments suivants :</p>
                <ul>
                    <li>
                        Les ambitions chiffrées de votre collectivité à horizon 2050 en
                        matière d'énergie consommée, d'EnR produite et d'émission de
                        GES.
                    </li>
                    <li>
                        Toutes les données chiffrées en votre possession concernant les
                        plans d'actions envisagés par votre collectivité sur les
                        thématiques de bâtiment, mobilité.
                    </li>
                </ul>
                <br />
                <p>Des données manquantes ?</p>
                <p>
                    Pas de panique ! Vous n'êtes pas obligé de tout compléter et vous
                    pourrez revenir sur votre stratégie ultérieurement pour la
                    compléter.
                </p>
            </div>
            <br />
            <p style={{ textAlign: "center" }}>
                <a href={pdfUrl}>Fiche méthodologique Stratégies Territoriales</a>
            </p>
            <div className="check-input">
                <input
                    type="checkbox"
                    defaultChecked={localStorage.getItem("strategy-hide-help") == null}
                    onChange={(event) =>
                        event.target.checked
                            ? localStorage.removeItem("strategy-hide-help")
                            : localStorage.setItem("strategy-hide-help", "")
                    }
                    id="show-help-by-default"
                />
                <label htmlFor="show-help-by-default">
                    Afficher à la création d’une stratégie territoriale
                </label>
            </div>
            <br />
            <button
                className="tsbtn info big"
                style={{ display: "block", margin: "auto" }}
                onClick={onClose}
            >
                Ok
            </button>
        </DetailsPopup>
    );
}

function ExportPopup({ isOpen, onClose, onValidate, isImpactAllowed }) {
    const [isLoading, setLoading] = useState(false);
    return (
        <DetailsPopup title="Exporter une stratégie" {...{ isOpen, onClose }}>
            <form
                onSubmit={async (event) => {
                    event.preventDefault();
                    setLoading(true);
                    await onValidate(event.target.elements["format"].value);
                    setLoading(false);
                    onClose();
                }}
                className="full-screen-form"
                style={{ fontSize: "var(--font-size-text)" }}
            >
                <div />
                <div>
                    <div className="check-input">
                        <input
                            type="radio"
                            name="format"
                            value="pcaet"
                            id="format-pcaet"
                            required
                        />
                        <label htmlFor="format-pcaet">
                            Export Excel PCAET pour le dépôt ADEME
                        </label>
                    </div>
                    <div
                        className={"check-input" + (isImpactAllowed ? "" : " disabled")}
                    >
                        <input
                            type="radio"
                            name="format"
                            value="impacts"
                            id="format-impacts"
                            required
                            disabled={!isImpactAllowed}
                        />
                        <label
                            htmlFor="format-impacts"
                            title={
                                isImpactAllowed
                                    ? undefined
                                    : "Veuillez calculer les impacts afin de les exporter"
                            }
                        >
                            Export Excel des impacts
                        </label>
                    </div>
                    {/* TODO: Export PDF de la stratégie, Export PDF des impacts */}
                </div>
                <input
                    type="submit"
                    className="tsbtn info big full-width loading"
                    value="Exporter"
                    disabled={isLoading}
                />
            </form>
        </DetailsPopup>
    );
}

function processStrategyForImpactCalculation(
    actionsMeta,
    activeActions,
    actionsParams,
    advancedParams
) {
    const params = { economique: {} };
    const actions = {};
    const advanced = {};
    const yearAdvancedParams = {};

    for (let actionNb in activeActions) {
        if (!activeActions[actionNb]) continue;
        const action = actionsMeta.find(({ numero }) => numero === actionNb);
        if (!action) {
            throw Error(`Action nb ${actionNb} was not found in actionsMeta`);
        }
        for (const param of actionsParams) {
            // we skip the parameters of other actions
            if (param.action !== action.numero) continue;
            // Fill the actions activated years
            for (const [year, value] of Object.entries(param.valeur ?? {})) {
                if (value == null || value === "") continue;
                if (!actions[year]) actions[year] = [];
                if (!actions[year].includes(action.numero)) {
                    actions[year].push(action.numero);
                }
            }
            params[param.nom] = param.valeur;
        }

        const advancedParam = advancedParams.find(
            (param) => param.action === action.numero
        )?.params_avances;
        if (advancedParam?.economique) {
            // Economic
            for (let phase of advancedParam.economique) {
                // get the value (proportion)
                let value = phase.part;
                if (value == null || value === "") {
                    throw Error(
                        "Merci de renseigner une valeur dans tous les paramètres économiques de l'action : " +
                            action.name
                    );
                }
                params.economique[phase.id] = Number(value);
            }
        }
        if (advancedParam?.autres) {
            // Others
            advanced[action.numero] = {};
            for (let subkey in advancedParam.autres) {
                let paramsAutres = {};
                for (let i in advancedParam.autres[subkey].index) {
                    let idxValeur =
                        advancedParam.autres[subkey].columns.indexOf("valeur");
                    let idxName = advancedParam.autres[subkey].columns.indexOf("nom");
                    let value = advancedParam.autres[subkey].data[i][idxValeur];
                    if (value == null || value === "") {
                        throw Error(
                            "Merci de renseigner une valeur dans tous les paramètres avancés de l'action : " +
                                action.name
                        );
                    }
                    if (idxName !== -1) {
                        paramsAutres[advancedParam.autres[subkey].data[i][idxName]] =
                            Number(value);
                    } else {
                        paramsAutres[advancedParam.autres[subkey].index[i]] =
                            Number(value);
                    }
                }
                advanced[action.numero][subkey] = paramsAutres;
            }
        }
        if (advancedParam?.others_years) {
            // Yearly params
            yearAdvancedParams[action.numero] = {};
            for (let subkey in advancedParam.others_years) {
                const param = advancedParam.others_years[subkey];
                let otherYearParams = {};
                // We have to get all fields (years)
                let advancedValues = {};
                for (let year in param.valeur_annee) {
                    let value = String(param.valeur_annee[year]);

                    if (value.trim() !== "") {
                        advancedValues[year] = value;
                    }
                }
                // add the reference year if not exists
                if (!Object.keys(advancedValues).includes(action.annee_ref)) {
                    advancedValues[action.annee_ref] = String(0);
                }
                otherYearParams[param.index[0]] = advancedValues;
                yearAdvancedParams[action.numero][subkey] = otherYearParams;
            }
        }
    }

    params.actions = actions;
    params.advanced = advanced;
    params.yearAdvancedParams = yearAdvancedParams;
    params.trajectoires_cibles = []; // TODO trajectoires activées ou non (info pour la mesure d'audience)

    return params;
}

function updateIndexedArray(original, newValues, key) {
    // Instead of updating the original array, we put all new values, then put missing values from the original array
    const newArray = [...newValues];
    for (const value of original) {
        if (!newArray.find((val) => val[key] === value[key])) {
            newArray.push(value);
        }
    }
    return newArray;
}

function convertParamsToNumber(params) {
    for (const param of params) {
        if (!param.valeur) continue;
        const siblings = params.filter(({ action }) => action === param.action);
        for (const [year, value] of Object.entries(param.valeur)) {
            param.valeur[year] = parseFloat(value);
            if (
                siblings.every(({ valeur }) => !valeur?.[year] || valeur[year] === "0")
            ) {
                delete param.valeur[year];
            }
        }
    }
    return params;
}
