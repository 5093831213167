/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

function Navbar({ parentApi }) {
    const history = useHistory();

    const handleDisconnect = async () => {
        try {
            const connected = await parentApi.controller.authManager.disconnect({
                ...parentApi.callbacks,
            });
            if (!connected) {
                parentApi.callbacks.updateRegionName("national", () => {
                    history.push("/national");
                });
            }
        } catch (error) {
            console.error("Error disconnecting:", error);
        }
    };

    const handleScenarii = (e) => {
        parentApi.callbacks.goToURL(
            `/national/${parentApi.data.region}/strategies_territoriales`
        );
    };

    return parentApi.data.connected ? (
        <div className="auth-widget auth-widget-collapsed">
            <div className="user-menu">
                <ul>
                    <li>
                        <Link
                            to={
                                "/national/" +
                                parentApi.data.region +
                                "/strategies_territoriales"
                            }
                            onClick={handleScenarii}
                            className="btn-login disconnect-button"
                        >
                            <i className="bi bi-graph-down"></i> Stratégies
                            territoriales
                        </Link>
                    </li>
                    <li>
                        <Link
                            to={"/national/" + parentApi.data.region + "/portail"}
                            className="btn-login disconnect-button"
                        >
                            <i className="bi bi-house"></i> Portail
                        </Link>
                    </li>
                    <li>
                        <button
                            type="button"
                            className="btn-login disconnect-button"
                            onClick={handleDisconnect}
                            title="Se déconnecter"
                        >
                            <span className="bi bi-power"></span>
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    ) : null;
}

export default Navbar;
