/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import ReactTable from "react-table-6";

import Api from "../../Controllers/Api";
import DetailsPopup from "../DetailsPopup";
import { buildRegionUrl, filterCaseInsensitive } from "../../utils.js";

import config from "../../settings.js";

import "bootstrap/dist/css/bootstrap.min.css";

/**
 * Ce composant permet de gérer l'historique des modifications sur les couches de
 * données
 */
class DataHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            history: undefined,
            showDetailsHistoryElement: false,
            detailsHistory: "",
        };
        this.getHistory();
    }

    // Get history of modifications on layers
    getHistory() {
        // Call api to get history
        Api.callApi(
            buildRegionUrl(
                config.api_analysis_data_history_url,
                this.props.parentApi.data.region
            ),
            undefined,
            "GET"
        )
            .then((response) => {
                response = response.map((r) => ({
                    ...r,
                    date_maj: new Date(r.date_maj),
                }));
                this.setState({
                    history: response,
                });
            })
            .catch((e) => this.setState({ status: e.message }));
    }

    toggleDetailsCell(details) {
        let toggle = this.state.showDetailsHistoryElement;
        // we toggle between displaying details or not
        if (!toggle) {
            this.setState({
                showDetailsHistoryElement: true,
                detailsHistory: details,
            });
        } else {
            this.setState({
                showDetailsHistoryElement: false,
                detailsHistory: "",
            });
        }
    }

    render() {
        if (
            !this.props.connected ||
            !this.props.userInfos ||
            this.props.userInfos?.profil !== "admin"
        ) {
            return <div>Non accessible.</div>;
        }

        const columns = [
            {
                Header: "Id",
                accessor: "id",
            },
            {
                Header: "Donnée / indicateur",
                accessor: "nom_donnee",
            },
            {
                Header: "Action",
                accessor: "action",
            },
            {
                Header: "Utilisateur",
                accessor: "utilisateur",
            },
            {
                Header: "Mise à jour",
                accessor: "date_maj",
                Cell: (props) => {
                    return props.value.toLocaleString();
                },
            },
            {
                Header: "Détails",
                accessor: "autres_informations",
                Cell: (props) => {
                    // computing the details to show
                    let details = props.original.autres_informations;
                    // converts JSON to pretty output
                    if (typeof details !== "string" && !(details instanceof String)) {
                        let listDetails = [];
                        for (const key in details) {
                            if (Object.hasOwnProperty.call(details, key)) {
                                const element = details[key];
                                listDetails.push(
                                    <div>
                                        <p>
                                            <strong>{key} :</strong>
                                        </p>
                                        <pre>{JSON.stringify(element, null, 2)}</pre>
                                    </div>
                                );
                            }
                        }
                        details = listDetails;
                    }
                    // prepares the button to toggle the popup
                    let toggle = details ? (
                        <button
                            className="btn btn-secondary"
                            onClick={() => this.toggleDetailsCell(details)}
                        >
                            Afficher les détails
                        </button>
                    ) : (
                        <em>Pas de détails</em>
                    );
                    return <div>{toggle}</div>;
                },
            },
        ];

        return (
            <div>
                <div className="panel-body">
                    <h3 className="panel-title pull-left">
                        Dernières modifications d'indicateurs
                    </h3>
                    <ReactTable
                        data={this.state.history}
                        columns={columns}
                        className="-striped"
                        defaultPageSize={10}
                        filterable={true}
                        defaultFilterMethod={(filter, row) =>
                            filterCaseInsensitive(filter, row)
                        }
                    />
                </div>
                <DetailsPopup
                    title="Détails sur l'opération"
                    content={this.state.detailsHistory}
                    show={this.state.showDetailsHistoryElement}
                    emptyMsg="Pas de détails"
                    callbackAfterClosing={() => this.toggleDetailsCell()}
                />
            </div>
        );
    }
}

export default DataHistory;
