/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import Api from "../Controllers/Api";
import config from "../settings.js";
import { buildRegionUrl } from "../utils.js";

/**
 * This component is used to manage th selection of a geographical zone
 */
class Zones {
    constructor(callback, region) {
        this.region = region;
        this.zones = undefined;
        this.zoneLists = undefined;
        this.maillesList = [];
        this.zonesInNewFormat = {};

        this.fetchConfiguration()
            .then(() => this.fetchZones())
            // Tell the main app that the configuration has been loaded
            .then(callback);
    }

    /**
     * Retreive zones configuration from API and store available zone types into
     * this.zones.
     */
    async fetchConfiguration() {
        this.zones = await Api.callApi(
            buildRegionUrl(config.api_area_url, this.region)
        );
        // Create a list of all the different "maille"
        this.maillesList = Array.from(new Set(this.zones.map((z) => z.maille))).map(
            (m) => ({ value: m, label: m })
        );

        /** Ex: {
         *      region: { label: "Région", mailles: {
         *          departement: {label: "département"},
         *          epci: {label: "EPCI"}
         *      }
         * }*/
        this.zonesInNewFormat = {};
        for (const zone of this.zones) {
            if (!this.zonesInNewFormat[zone.nom]) {
                const label = zone.libelle.split(" - ")[0];
                this.zonesInNewFormat[zone.nom] = { label, mailles: {} };
            }
            const separator = zone.libelle.includes(" - maille ")
                ? " - maille "
                : " - ";
            let label = zone.libelle.split(separator);
            label = label[label.length - 1];
            this.zonesInNewFormat[zone.nom].mailles[zone.maille] = { label };
        }
    }

    /**
     * Fetches the data (code, geometry...) for the all the zones defined in this.zones except for zone "commune"
     */
    async fetchZones() {
        this.zoneLists = {};
        if (!this.zones) return;
        const zonesToFetch = new Set(this.zones.map((z) => [z.nom, z.maille]).flat());
        await Promise.all(
            Array.from(zonesToFetch).map(async (zone) => {
                try {
                    const response = await Api.callApi(
                        buildRegionUrl(config.api_area_url, this.region) + zone,
                        null,
                        "GET"
                    );
                    this.zoneLists[zone] = response.map((f) => ({
                        code: f.code,
                        label: f.nom,
                        communes: zone === "commune" ? [f.code] : f.communes,
                    }));
                } catch (error) {
                    console.error(`Error fetching data for zone ${zone}: ${error}`);
                }
            })
        );
    }

    /**
     * Return the list of zones for a specified scale
     *
     * @param {string | {zone: string}} zone
     * @returns {{code: string, label: string, communes: any[], geometry: any}[] | undefined}
     */
    getZoneList(zone) {
        if (!this.zoneLists) return undefined;
        return this.zoneLists[zone.zone ?? zone];
    }

    /*
     * Return mailles list
     */
    getMaillesList() {
        return this.maillesList;
    }

    /*
     * Return zone's name
     */
    getZoneName(code, zone) {
        if (!zone) return undefined;
        let idZone = zone.zone;
        if (this.zoneLists && this.zoneLists[idZone]) {
            for (let a of this.zoneLists[idZone]) {
                if (a.code === code) {
                    return a.label;
                }
            }
        }
        return undefined;
    }

    /*
     * Return zone's name
     */
    getZoneLevelName(zone) {
        if (!zone) return undefined;
        if (this.zones) {
            for (let a of this.zones) {
                if (a.nom === zone.zone && a.maille === zone.maille) {
                    return a.libelle;
                }
            }
        }
        return undefined;
    }

    /*
     * Retreive communes from specific zone
     */
    getCommunesZone(code, zone) {
        let idZone = zone.zone;
        let communes = [];
        if (this.zoneLists && this.zoneLists[idZone]) {
            for (let a of this.zoneLists[idZone]) {
                if (a.code === code) {
                    communes = a.communes;
                    break;
                }
            }
        }
        return communes;
    }

    /*
     * Retreive feature of a specific zone
     */
    getFeature(zoneType, zoneId) {
        let url = buildRegionUrl(config.api_area_geometry_url, this.region)
            .replace("#zone_type#", zoneType)
            .replace("#zone_id#", zoneId);
        return Api.callApi(url);
    }

    obtenirLibelleZone(typeTerritoire) {
        for (let zone of this.zones) {
            if (zone.nom === typeTerritoire) {
                return zone.libelle.split("-")[0];
            }
        }
    }
}

export default Zones;
