/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import ReactTable from "react-table-6";

import Api from "../../Controllers/Api";

import config from "../../settings.js";

import { buildRegionUrl, filterCaseInsensitive } from "../../utils.js";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";

/**
 * Indicator data management (Registry data)
 */
function DonneesRegistre({ connected, userInfos, parentApi, callbackConfiguration }) {
    const [tablesList, setTablesList] = useState([]);
    const [error, setError] = useState("");
    const [dataList, setDataList] = useState([]);
    const [isLoadingMainData, setLoadingMainData] = useState(false);

    if (tablesList.length === 0 && dataList.length === 0) {
        retrieveMainData();
    }

    /**
     * Updates the component state with the list of registry data tables.
     * @param {chaine de caractères} region : Name of the selected region
     */
    function retrieveMainData() {
        // is not already loading data
        if (!isLoadingMainData) {
            setLoadingMainData(true);
            let url = config.api_liste_donnees_registre;
            Api.callApi(buildRegionUrl(url, parentApi.data.region))
                .then((response) => {
                    setTablesList(response.liste_tables_rte);
                    setDataList(response.liste_tables_configuree);
                    setLoadingMainData(false);
                })
                .catch((e) => {
                    setError(e.message);
                });
        }
    }

    function goToConfiguration(analysis) {
        // We switch to the configuration tab of a registry data set
        callbackConfiguration(analysis); // 8 represents the registry dataset configuration tab
    }

    function parseTablesList() {
        let couleurDeFond = { background: "rgba(220, 220, 220)" };
        let tableauFinal = [];
        let cpt = 0;

        for (let a of dataList) {
            if (cpt % 2 !== 0) {
                couleurDeFond = { background: "rgba(220, 220, 220)" };
            } else {
                couleurDeFond = { background: "rgba(240, 240, 240)" };
            }
            let ligne = (
                <tr key={a}>
                    <th style={couleurDeFond}>{a}</th>
                </tr>
            );
            tableauFinal.push(ligne);
            cpt += 1;
        }
        return tableauFinal;
    }

    let tablesDashboard = "";

    if (connected && userInfos && tablesList.length !== 0) {
        if (userInfos.profil === "admin") {
            const columns = [
                {
                    Header: "Jeu de données",
                    accessor: "nom",
                },
                {
                    id: "action",
                    Header: "Action",
                    /**
                     * @param {objet clé => valeur} props : table row properties
                     * Here, only the "original" key is useful to us. Here is the structure
                     * { nom : Name of the dataset to display }
                     */
                    Cell: (props) => {
                        let caracteristiquesLigne = props.original;
                        let nom = caracteristiquesLigne.nom;
                        let bouton = (
                            <button
                                className="btn btn-info"
                                onClick={() => goToConfiguration(caracteristiquesLigne)}
                            >
                                Configurer
                            </button>
                        );
                        return (
                            <div className="actions-territoire" key={nom}>
                                {bouton}
                            </div>
                        );
                    },
                    filterable: false,
                },
            ];

            tablesDashboard = (
                <div className="panel-body user-scenarii">
                    <div className="block-etape">
                        <h4>Gestion des données du registre</h4>
                    </div>
                    <ReactTable
                        data={tablesList}
                        columns={columns}
                        className="-striped donnees-territoires"
                        defaultPageSize={30}
                        filterable={true}
                        defaultFilterMethod={(filter, row) =>
                            filterCaseInsensitive(filter, row)
                        }
                    />
                </div>
            );
        }
    }

    let finalTable = parseTablesList();
    return (
        <div>
            {tablesDashboard}
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "50px",
                    marginLeft: "100px",
                }}
            >
                <b style={{ display: "flex", marginBottom: "10px" }}>
                    Liste des tables déjà configurées :{" "}
                </b>
                <b>{finalTable}</b>
            </div>
            {error}
        </div>
    );
}

export default DonneesRegistre;
