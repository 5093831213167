/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React, { useState, useEffect, useRef } from "react";
import Select, { createFilter } from "react-select";

import "../style/doubleSelect.css";

/* If you need a two-step selector that is not linked to zones, you should create a new
component based on the code of ZoneSelect. */

/**
 * Select a zone from the list given by parentApi.controllers.zonesManager, for any
 * usage. The onSelect callback is called each time a valid zone (type+maille+id) is
 * selected by the user.
 *
 * This component uses the triplet {zoneType: str, zoneMaille: str, zoneId: str}, not
 * the old format {zone: {zone: str, maille: str}, zoneId: str}.
 *
 * @param {Object} props
 * @param {string} props.zoneType Type of default selected zone
 * @param {string} props.zoneMaille Type of default selected maille
 * @param {string} props.zoneId Id of default selected zone
 * @param {({zoneType: string, zoneMaille: string, zoneId: string}) => void} props.onSelect Callback called when selecting a valid territory
 * @param {string} props.title Title of the selector. Default "Territoire". Set to "" to disable
 * @param {string} props.className Additional classNames for styling
 * @param {boolean} props.disableMaille Disable maille selection (set to zoneType)
 * @param {Object} props.parentApi
 *
 * @returns {JSX.Element}
 */
export function ZoneSelect(props) {
    const { title, disableMaille, parentApi } = props;
    const zonesManager = parentApi.controller.zonesManager;

    const [zoneType, setZoneType] = useState(props.zoneType ?? "");
    const [zoneMaille, setZoneMaille] = useState(props.zoneMaille ?? "");
    const [zoneId, setZoneId] = useState(props.zoneId ?? "");

    const [allZoneTypes, setAllZoneTypes] = useState({});
    const [zonesForCurrentType, setZonesForCurrentType] = useState([]);

    // Initialize from props
    useEffect(() => setAllZoneTypes(zonesManager.zonesInNewFormat), [zonesManager]);
    useEffect(() => setZoneType(props.zoneType ?? ""), [props.zoneType]);
    useEffect(() => setZoneMaille(props.zoneMaille ?? ""), [props.zoneMaille]);
    useEffect(() => setZoneId(props.zoneId ?? ""), [props.zoneId]);

    // Updates
    useEffect(() => {
        const getZoneList = (type, includeType = false) =>
            zonesManager.getZoneList({ zone: type })?.map(({ code, label }) => ({
                value: code,
                label,
                acronym: [...label.matchAll(/[A-Z]/g)].join(""),
                ...(includeType ? { type } : {}),
            })) ?? [];

        let zones = [];
        if (zoneType) {
            zones = getZoneList(zoneType);
        } else {
            zones = Object.entries(allZoneTypes).map(([type, { label }]) => ({
                label,
                options: getZoneList(type, true),
            }));
        }
        setZonesForCurrentType(zones);
    }, [zoneType, zonesManager, allZoneTypes]);

    useEffect(() => {
        if (
            allZoneTypes[zoneType] &&
            allZoneTypes[zoneType].mailles[zoneMaille] === undefined
        ) {
            // Use current scale as the maille if available
            if (allZoneTypes[zoneType].mailles[zoneType] || disableMaille) {
                setZoneMaille(zoneType);
                return;
            }
            if (parentApi.data.settings.auto_select_maille) {
                // Get the first element of the dict
                for (const maille in allZoneTypes[zoneType].mailles) {
                    setZoneMaille(maille);
                    return;
                }
            }
        }
    }, [zoneType, zoneMaille, allZoneTypes, parentApi.data.settings, disableMaille]);

    useEffect(() => {
        if (zonesForCurrentType.length === 1) {
            setZoneId(zonesForCurrentType[0].value);
            document.getElementById("select-maille")?.focus();
        }
    }, [zonesForCurrentType]);

    // Parent callbacks
    const onSelect = useRef(props.onSelect).current; // HACK? Directly using props.onSelect causes an infinite loop.
    useEffect(() => {
        if (zoneType && zoneMaille && zoneId && onSelect) {
            onSelect({ zoneType, zoneMaille, zoneId });
        }
    }, [zoneType, zoneId, zoneMaille, onSelect]);

    // Children callbacks
    const onTypeSelect = (event) => {
        const newZoneType = event.target.value;
        // Reset zoneId before changing zoneType to prevent calling onSelect callback with wrong zone
        if (zoneType !== newZoneType) {
            setZoneId("");
            setZoneMaille("");
        }
        document.querySelector(".zone-react-select .react-select__input").focus();
        setZoneType(newZoneType);
    };
    const onZoneSelect = (item) => {
        setZoneType(item?.type ?? zoneType);
        setZoneId(item?.value ?? "");
        document.getElementById("select-maille")?.focus();
    };
    const onMailleSelect = (event) => {
        setZoneMaille(event.target.value);
    };

    // Render
    const typeOptions = Object.entries(allZoneTypes).map(([id, { label }]) => (
        <option value={id} key={id}>
            {label}
        </option>
    ));
    let mailleOptions = [];
    if (zoneType && allZoneTypes[zoneType]) {
        mailleOptions = Object.entries(allZoneTypes[zoneType].mailles).map(
            ([id, { label }]) => (
                <option value={id} key={id}>
                    {label}
                </option>
            )
        );
    }

    const tooMuchResults = zonesForCurrentType.length > 300 || !zoneType;
    const _filterOption = createFilter({
        stringify: (item) => `${item.label} ${item.value} ${item.data?.acronym ?? ""}`,
    });
    let filterOption = _filterOption;
    if (tooMuchResults) {
        filterOption = (option, input) =>
            input.trim().length >= 2 && _filterOption(option, input);
    }

    return (
        <div className={"zone-select " + (props.className ?? "")}>
            {title !== "" && (
                <label
                    className="label-selecteur-objet"
                    htmlFor={props.forIdOnSelect ?? "select-type"}
                >
                    <strong>{title ?? "Territoire"}</strong>
                </label>
            )}
            <select
                id={props.forIdOnSelect ?? "select-type"}
                value={zoneType}
                onChange={onTypeSelect}
                role="combobox"
            >
                <option value="">Échelle</option>
                {typeOptions}
            </select>
            <Select
                className="zone-react-select"
                classNamePrefix="react-select"
                options={zonesForCurrentType}
                value={
                    zonesForCurrentType.find(({ value }) => value === zoneId) || null
                }
                onChange={onZoneSelect}
                placeholder={
                    zoneType && allZoneTypes[zoneType]
                        ? `Rechercher un(e) ${allZoneTypes[zoneType].label}`
                        : "Rechercher un territoire"
                }
                filterOption={filterOption}
                noOptionsMessage={({ inputValue }) =>
                    tooMuchResults && inputValue.trim().length < 2
                        ? "Saisissez deux caractères ou plus"
                        : "Aucun résultat"
                }
            />
            {!disableMaille && (
                <select
                    id="select-maille"
                    value={zoneMaille}
                    onChange={onMailleSelect}
                    role="combobox"
                    disabled={mailleOptions.length <= 0}
                >
                    <option value="" disabled>
                        Maille
                    </option>
                    {mailleOptions}
                </select>
            )}
        </div>
    );
}

/**
 * Selection component for the main zone: parentApi.data.zone/parentApi.data.currentZone.
 * Override className and containerClassName to change the style; else the main map
 * style will be used (absolute position at top center).
 */
export function MainZoneSelect({
    parentApi,
    className,
    containerClassName,
    callback,
    ...otherProps
}) {
    const onSelect = ({ zoneType, zoneMaille, zoneId }) => {
        parentApi.callbacks.updateZone(zoneType, zoneMaille, zoneId);
        if (callback) callback({ zoneType, zoneMaille, zoneId });
    };

    return (
        <div className={containerClassName ?? "main-zone-select-container"}>
            <ZoneSelect
                parentApi={parentApi}
                onSelect={onSelect}
                className={className ?? "main-zone-select"}
                zoneType={parentApi.data.zone.zone}
                zoneMaille={parentApi.data.zone.maille}
                zoneId={parentApi.data.currentZone}
                {...otherProps}
            />
        </div>
    );
}

/**
 * This component is used to select an indicator and its display in dashboards.
 */
export class VisualizationSelect extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            premiereSelection: props.premiereSelection,
            selectionFinale: props.valeurDefaut,
            tableauBordReinitialise: false,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        const parentData = this.props.parentApi.data;
        if (
            parentData.analysis === undefined &&
            prevProps.parentApi.data.analysis &&
            parentData.currentZone
        ) {
            this.props.parentApi.callbacks.updateAnalysis(
                prevProps.parentApi.data.analysis
            );
        }

        if (parentData.currentZone !== prevProps.parentApi.data.currentZone) {
            if (prevProps.parentApi.data.analysis) {
                this.props.parentApi.callbacks.updateAnalysis(
                    prevProps.parentApi.data.analysis
                );
            }
        }
        if (
            parentData.tableauBordDonnees !==
            prevProps.parentApi.data.tableauBordDonnees
        ) {
            this.setState({
                selectionFinale: undefined,
                premiereSelection: undefined,
                tableauBordReinitialise: true,
            });
        }
    }

    /**
     * Cette fonction retourne la liste des balises html <option> à ajouter au premier
     * menu déroulant de sélection d'objet
     * @param  {Liste} listeObjetsPremiereSelection : liste des objets à ajouter au menu déroulant et à intégrer dans des balises <option>
     * exemple : {
     *   0: Object { id: 1, nom: "Consommation d'énergie", data: "conso_energetique", … }
     * }
     */
    selectionTypeObjet(listeObjetsPremiereSelection) {
        let sel = {},
            themes = [];
        for (let analysis of listeObjetsPremiereSelection) {
            if (analysis.disabled_in_dashboard) {
                continue;
            }
            if (!sel[analysis.ui_theme]) {
                sel[analysis.ui_theme] = [];
                themes.push({
                    theme: analysis.ui_theme,
                    order: analysis.ordre_ui_theme,
                });
            }

            if (!analysis.is_sub_indicator) {
                sel[analysis.ui_theme].push(
                    <option key={analysis.id} value={analysis.id}>
                        {analysis.nom}
                    </option>
                );
            }

            if (analysis.sub_indicator) {
                const subIndicators = JSON.parse(analysis.sub_indicator);
                const renderedSubIndicators =
                    this.renderSubIndicatorOption(subIndicators);
                sel[analysis.ui_theme].push(...renderedSubIndicators);
            }
        }
        themes.sort((a, b) => a.order - b.order);
        let output = themes.map(({ theme }) => (
            <optgroup key={theme} label={theme}>
                {sel[theme]}
            </optgroup>
        ));
        return output;
    }

    renderSubIndicatorOption(subIndicators) {
        return subIndicators.map((subIndic) => {
            const { indicator } = subIndic;
            const name =
                this.props.parentApi.controller.analysisManager.getAnalysisName(
                    indicator
                );
            const indentation = "\u00A0\u00A0\u00A0";

            return (
                <option key={indicator} value={indicator}>
                    {`${indentation}\u2192 ${name}`}
                </option>
            );
        });
    }

    miseAJourAnalysesTableauBord(id) {
        if (
            this.props.parentApi.data.tableauBordDonnees.donnees[
                this.props.numeroThematique
            ]
        ) {
            this.props.parentApi.callbacks.updateDashboard(
                this.props.numeroThematique,
                this.props.parentApi.data.tableauBordDonnees.donnees[
                    this.props.numeroThematique
                ].titre_thematique,
                this.props.parentApi.data.tableauBordDonnees.donnees[
                    this.props.numeroThematique
                ].description_thematique,
                this.props.id,
                {
                    id_analysis: id,
                    representation: undefined,
                    categories: undefined,
                }
            );
            this.props.parentApi.callbacks.representationCourante(undefined);
        }
    }

    /**
     * Met à jour l'état du composant à partir de l'objet sélectionné
     *
     * @param {Object} e : objet grâce auquel on peut obtenir la valeur sélectionnée (e.target.value)
     */
    obtenirTypeObjetApresChangement = (e) => {
        let territoireMailleSelectionne = e.target.value;

        // Il est nécessaire de récupérer les metadonnées de l'analyse (appel à l'api)
        this.miseAJourAnalysesTableauBord(territoireMailleSelectionne);
        this.setState({
            // Mise à jour du composant
            premiereSelection: territoireMailleSelectionne,
            selectionFinale: "",
        });
    };

    /**
     * Retourne un composant select qui permet de sélectionner un objet et intègre
     * une fonctionnalité de filtre
     * @param  {Liste} options : liste des choix possibles pour le deuxième menu déroulant
     * Exemple :
     * {
     *   0: Object { code: 1, nom: "Diagramme circulaire" }
     *   1: Object { code: 2, nom: "Courbes empilées" }
     * }
     */
    outilDeSelection(options) {
        /**
         * Met à jour la liste des objets sélectionnables à partir des mots clés qu'une personnes a saisis
         * et qui servent de filtre
         * @param  {e} objet : objet grâce auquel on peut obtenir la valeur sélectionnée (e.target.value)
         */
        const onSelect = (e) => {
            this.setState({
                selectionFinale: e.target.value, // Mise à jour de l'état du composant à partir de la valeur sélectionnée
            });
            // Sélecteur d'analyses pour les tableaux de bord
            let id_analysis = parseInt(this.state.premiereSelection, 10);
            let listeCategories =
                this.props.parentApi.controller.analysisManager.getCategories(
                    id_analysis
                );
            let categories = {}; // Liste des catégories disponibles en base de données ET qui n'ont pas été désactivées par l'utilisateur.trice
            for (let a in listeCategories) {
                if (listeCategories[a] && listeCategories[a].visible) {
                    categories[listeCategories[a].categorie] = {
                        categorie: listeCategories[a].categorie,
                        titre: listeCategories[a].titre,
                        visible: false,
                    };
                }
            }
            if (
                this.props.parentApi.data.tableauBordDonnees.donnees[
                    this.props.numeroThematique
                ]
            ) {
                this.props.parentApi.callbacks.updateDashboard(
                    this.props.numeroThematique,
                    this.props.parentApi.data.tableauBordDonnees.donnees[
                        this.props.numeroThematique
                    ].titre_thematique,
                    this.props.parentApi.data.tableauBordDonnees.donnees[
                        this.props.numeroThematique
                    ].description_thematique,
                    this.props.id,
                    {
                        id_analysis: id_analysis,
                        representation: e.target.value,
                        categories: categories,
                        numero_analyse: this.props.id,
                    }
                );
                this.props.parentApi.callbacks.representationCourante(e.target.value);
            }
        };

        if (this.state.premiereSelection === "" || !options?.length) {
            return (
                <div className="auto-complete auto-complete-zone">
                    <p className="alert alert-warning">
                        Pas de représentation disponible
                    </p>
                </div>
            );
        }
        return (
            <div className="auto-complete auto-complete-zone">
                <div className="texte-a-droite">
                    <select value={this.state.selectionFinale} onChange={onSelect}>
                        <option value="" disabled>
                            Représentation...
                        </option>
                        {options.map(({ nom, arg }) => (
                            <option value={arg} key={arg}>
                                {nom}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        );
    }

    render() {
        let short = this.state.premiereSelection ? "" : "filter-territoire-short";
        let classe_filtre_territoires =
            "alert filter filter-territoire relative " + short;
        let filtre = "";
        let selection = "";
        let listeSecondaire = this.props.liste;
        if (this.props.listeObjetsPremiereSelection) {
            filtre = this.selectionTypeObjet(this.props.listeObjetsPremiereSelection);
            if (this.state.premiereSelection !== undefined) {
                let representationsPossibles =
                    this.props.parentApi.controller.analysisManager.obtenirRepresentationsPossibles(
                        parseInt(this.state.premiereSelection, 10)
                    );
                if (representationsPossibles) {
                    listeSecondaire = representationsPossibles;
                }
                selection = this.outilDeSelection(listeSecondaire);
            }
        }
        let option = "Sélectionnez un indicateur";
        let selectionne = "";
        if (this.state.tableauBordReinitialise) {
            selectionne = "Sélectionnez un indicateur";
        }

        return (
            <div className={classe_filtre_territoires}>
                <label className="label-selecteur-objet">
                    <strong>{this.props.titreForm ?? "Indicateur"}</strong>
                </label>
                <select
                    value={this.state.premiereSelection}
                    className="short"
                    onChange={this.obtenirTypeObjetApresChangement}
                    role="combobox"
                >
                    <option value={selectionne}>{option}</option>
                    {filtre}
                </select>
                {selection}
            </div>
        );
    }
}

/**
 * This component is used to select an indicator and its display in dashboards.
 */
export class MultiLevelSelect extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selections:
                props.defaultSelections ??
                props.availableSelections.map(() => undefined),
        };
    }

    handleSelection(e, index) {
        let newSelections = [...this.state.selections];
        newSelections[index] = e.target.value;
        newSelections = newSelections.fill(undefined, index + 1);
        this.setState({
            selections: newSelections,
        });

        if (
            index === this.props.availableSelections.length - 1 &&
            this.props.callback
        ) {
            this.props.callback(newSelections);
        }
    }
    render() {
        let selectionTools = [];

        for (let index = 0; index < this.props.availableSelections.length; index++) {
            const availableSelection = this.props.availableSelections[index];
            let options = availableSelection.values.map((v) => (
                <option value={v.value} key={v.value}>
                    {v.label}
                </option>
            ));
            selectionTools.push(
                <div key={index}>
                    <label className="label-selecteur-objet">
                        <strong>{availableSelection.label}</strong>
                    </label>
                    <select
                        value={this.state.selections[index]}
                        className="short"
                        onChange={(e) => this.handleSelection(e, index)}
                        role="combobox"
                    >
                        <option value={undefined}>{availableSelection.default}</option>
                        {options}
                    </select>
                </div>
            );

            // we hide any field that has not already a value except for first one
            if (!this.state.selections[index]) {
                break;
            }
        }

        return <div>{selectionTools}</div>;
    }
}
