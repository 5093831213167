/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import SimpleImageSlider from "react-simple-image-slider";

/**
 * function used to create a carousel (Image slider)
 */
function Carousel({ listImg, height, width }) {
    return (
        <div>
            <SimpleImageSlider
                width={width}
                height={height}
                images={listImg}
                slideDuration={3}
                autoPlay={true}
                autoPlayDelay={10}
                showBullets={true}
                showNavs={true}
            />
        </div>
    );
}

export default Carousel;
