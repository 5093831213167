/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import { SketchPicker } from "react-color";
import reactCSS from "reactcss";
import { Link } from "react-router-dom";

import Api from "../../Controllers/Api";
import { buildRegionUrl } from "../../utils.js";
import config from "../../settings.js";

import "bootstrap-icons/font/bootstrap-icons.css";

/**
 * This component is used to create an Analysis Launcher
 */
class DidacticFileLauncher extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id_analyse: String(props.id_analysis),
            representation: "didactic-file-launcher",
            widthDOM: window.innerWidth,
            color: props.color,
            displayColorStartPicker: false,
            displayColorEndPicker: false,
            disabled: false,
            id_dashboard: props.id_tableau_bord,
            urlPartageable: this.props.parentApi.data.urlPartageable,
        };
        //  // Get data from API
        //  this.fetchConfiguration();
    }

    componentDidMount() {
        // we parse ID to remove any eventual prefix due to territorial choice
        let id = this.props.id;
        if (["-"].includes(id)) {
            id = id.split("-")[-1];
        }
        this.props.parentApi.callbacks.updateDidacticFileNameColor({
            id: parseInt(id, 10),
            color: this.props.color,
            id_analysis: this.props.id_analysis,
        });
        let chartsLegendsDiv = document.getElementsByClassName("charts-legende");
        if (chartsLegendsDiv.length > 0) {
            let widthDiv =
                document.getElementsByClassName("charts-legende")[0].offsetWidth;
            if (
                this.props.parentApi.data.tailleDiv !== widthDiv &&
                this.props.provenance === "tableau_de_bord_restitue"
            ) {
                this.props.parentApi.callbacks.tailleDiv(widthDiv);
            }
        }

        if (this.props.id_analysis) {
            // Get data from API
            this.getDidacticFileData(this.props.id_analysis);
        }
    }

    componentWillUnmount() {
        if (this.dataPromise) this.dataPromise.abort();
    }

    updateID(id) {
        this.props.parentApi.callbacks.updateCurrentDidacticFile(id);
        let typeTerritoire =
            "?zone=" + (this.props.zoneType ?? this.props.parentApi.data.zone.zone);
        let maille =
            "&maille=" +
            (this.props.zoneMaille ?? this.props.parentApi.data.zone.maille);
        let analysis = "&didactic_file=" + id;
        let codeInseeTerritoire =
            "&zone_id=" + (this.props.zoneId ?? this.props.parentApi.data.currentZone);
        let zoneObject =
            this.props.zoneType && this.props.zoneMaille
                ? {
                      zone: this.props.zoneType,
                      maille: this.props.zoneMaille,
                  }
                : this.props.parentApi.data.zone;
        let nomTerritoire =
            "&nom_territoire=" +
            encodeURI(
                this.props.parentApi.controller.zonesManager.getZoneName(
                    this.props.zoneId ?? this.props.parentApi.data.currentZone,
                    zoneObject
                )
            );
        if (
            !this.props.parentApi.controller.zonesManager.getZoneName(
                this.props.zoneId ?? this.props.parentApi.data.currentZone,
                zoneObject
            )
        ) {
            nomTerritoire =
                "&nom_territoire=" + encodeURI(this.props.parentApi.data.nomTerritoire);
        }
        if ((this.props.zoneType ?? this.props.parentApi.data.zone.zone) === "region") {
            codeInseeTerritoire = "&zone_id=" + this.props.parentApi.data.regionCode;
            nomTerritoire =
                "&nom_territoire=" + this.props.parentApi.data.settings.label;
        }
        let id_dashboard = "&id_tableau=" + this.state.id_dashboard;
        let url =
            typeTerritoire +
            maille +
            codeInseeTerritoire +
            analysis +
            nomTerritoire +
            id_dashboard;
        this.props.parentApi.callbacks.mettreAJourParametresUrls(url);
    }
    /**
     * Get the data for a specific didactic file identified by its ID.
     * @param {int} id the didactic file ID
     */
    getDidacticFileData(id) {
        let url = buildRegionUrl(
            config.api_didactic_file,
            this.props.parentApi.data.region
        ).replace("#id#", id);
        if (this.dataPromise) this.dataPromise.abort();
        this.dataPromise = Api.callApi(url, null, "GET");
        this.dataPromise
            .then((response) => {
                this.setState({
                    didacticFile: response.metadata,
                });
            })
            .catch((error) => {
                if (error.name === "AbortError") return;
                console.error(error);
            });
    }

    /**
     * Update component state with the color selected by user
     * @param {chaine de caractères} : color encoded in hexadecimal string
     */
    updateColor = (newColor) => {
        let color = newColor.hex + Math.round(newColor.rgb.a * 255).toString(16);
        this.setState({
            color: color,
        });
        // we parse ID to remove any eventual prefix due to territorial choice
        let id = this.props.id;
        if (id.includes("-")) {
            id = id.split("-")[-1];
        }
        let id_analyse = parseInt(this.state.id_analyse, 10);
        this.props.parentApi.callbacks.updateDidacticFileNameColor({
            id: parseInt(id, 10),
            color,
            id_analyse,
        });
    };

    /**
     * Updates this.state.displayColorStartPicker
     * As long as this.state.displayColorStartPicker is equal to false, the color form is not displayed
     * As soon as it is true, the form appears and the administrator can select the color of his choice
     */
    handleColorStartClick = () => {
        this.setState({
            displayColorStartPicker: !this.state.displayColorStartPicker,
        });
    };

    /**
     * Set this.state.displayColorStartPicker to false to close the form when the color has been selected
     */
    handleColorStartCose = () => {
        this.setState({ displayColorStartPicker: false });
    };

    /**
     * The color of the target trajectory
     */
    colorChoice() {
        const stylesColorDebut = reactCSS({
            default: {
                color: {
                    background: this.state.color,
                },
            },
        });

        return (
            <div className="formulaire-horizontal-alignement-vertical-centre">
                <label className="">Couleur de l'étiquette</label>
                <div className="custom-block">
                    <div
                        className="color-picker-swatch"
                        onClick={this.handleColorStartClick}
                    >
                        <div
                            className="color-picker-color"
                            style={stylesColorDebut.color}
                        />
                    </div>
                    {this.state.displayColorStartPicker ? (
                        <div className="color-picker-popover">
                            <div
                                className="color-picker-cover"
                                onClick={this.handleColorStartCose}
                            />
                            <SketchPicker
                                color={this.state.color}
                                onChange={this.updateColor}
                            />
                        </div>
                    ) : null}
                </div>
            </div>
        );
    }

    getUrl(didactifFileId) {
        let territoryType = "?zone=" + this.props.parentApi.data.zone.zone;
        let territory = "&maille=" + this.props.parentApi.data.zone.maille;
        let codeInseeTerritory = "&zone_id=" + this.props.parentApi.data.currentZone;
        let url =
            "/fiche_didactique" +
            territoryType +
            territory +
            codeInseeTerritory +
            "&didactic_file=" +
            didactifFileId;
        return url;
    }

    render() {
        if (this.state.disabled === true) {
            return (
                <div className="charts-legende">
                    <div className={"confid-chart"}>
                        Cet indicateur n'est pas activé actuellement, veuillez contacter
                        l'administrateur régional.
                    </div>
                </div>
            );
        }

        let nom = "";
        let id_analysis;
        let colorChoice = this.colorChoice();
        let colorRegion = this.state.color.toUpperCase();
        if (this.state.didacticFile) {
            id_analysis = this.state.didacticFile.id;
            nom = this.state.didacticFile.fileTitle;
        }

        const getItemStyle = {
            background: colorRegion,
            borderRadius: "5px",
            padding: "10px",
        };

        const url = this.getUrl(id_analysis);
        return (
            <div>
                <div className="charts-legende">
                    <Link to={url} className="no-link-description">
                        <div
                            className="btn"
                            onClick={this.updateID.bind(this, id_analysis)}
                            onContextMenu={this.updateID.bind(this, id_analysis)}
                        >
                            <div
                                style={getItemStyle}
                                className="analysis-launcher-button"
                            >
                                Fiche « {nom} »
                            </div>
                        </div>
                    </Link>
                    <span className="only-print">
                        Contient un <a href={window.location.origin + url}>lien</a>
                    </span>
                </div>
                {this.props.provenance !== "tableau_de_bord_restitue" && colorChoice}
            </div>
        );
    }
}

export default DidacticFileLauncher;
