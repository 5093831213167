/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";

import Api from "../../Controllers/Api";
import config from "../../settings.js";
import { buildRegionUrl } from "../../utils.js";
import "bootstrap-icons/font/bootstrap-icons.css";

/**
 * This component used to add data source logo
 *
 */
class AddDataSourceLogo extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            file: undefined,
            sources: [{ id: undefined, name: "", file: undefined, url: "" }],
            status: false,
            statusMessage: false,
        };
    }

    componentDidMount() {
        if (this.props.mode === "update") {
            // There are two possible modes: "update" and "add"
            this.getDataSource();
        }
    }

    /**
     * Get the characteristics of the data source logo (url, logo)
     */
    getDataSource() {
        let id = this.props.dataSourceLogoCourant.id;
        let url = buildRegionUrl(
            config.api_logo_sources,
            this.props.parentApi.data.region
        ).replace("#id#", id);
        Api.callApi(url, null, "GET").then((response) => {
            this.setState({
                sources: [response],
            });
        });
    }

    addDataSourceLogo() {
        let formData = new FormData();

        // Updates the formdata before sending it to the API
        formData.append("sources", JSON.stringify(this.state.sources));

        for (let item of this.state.sources) {
            formData.append("file" + item.id, item.file);
        }

        // Calls the API with files and metadata
        Api.callApi(
            buildRegionUrl(
                config.api_integration_logo_sources,
                this.props.parentApi.data.region
            ),
            formData,
            "PUT",
            "default"
        )
            .then((response) => {
                this.setState({
                    status: "success",
                    statusMessage: response.message,
                    updateDate: response.date,
                });
            })
            .catch((e) => {
                this.setState({ status: "warning", statusMessage: e.message });
            });
    }

    updateDataSourceLogo() {
        let formData = new FormData();
        let id = this.props.dataSourceLogoCourant.id;
        formData.append("sources", JSON.stringify(this.state.sources));
        formData.append(
            "file" + this.props.dataSourceLogoCourant.id,
            this.state.sources[0].file
        );
        Api.callApi(
            buildRegionUrl(
                config.api_update_logo_sources,
                this.props.parentApi.data.region
            ).replace("#id#", id),
            formData,
            "PUT",
            "default"
        )
            .then((response) => {
                this.setState({
                    status: "success",
                    statusMessage: response.message,
                });
            })
            .catch((e) => {
                this.setState({ status: "error", statusMessage: e.message });
            });
    }

    addInput() {
        this.setState({
            sources: [
                ...this.state.sources,
                { id: undefined, name: "", file: undefined, url: "" },
            ],
        });
    }

    removeInput(i) {
        this.setState({
            sources: this.state.sources.filter((_, index) => index !== i),
        });
    }

    handleChange(i, element, val, mode) {
        if (this.state.status && this.state.statusMessage) {
            this.setState({
                status: false,
                statusMessage: false,
            });
        }
        let elem = this.state.sources;
        elem[i] = {
            id: mode === "update" ? this.state.sources[i].id : i,
            file: element === "file" ? val : this.state.sources[i]["file"],
            url: element === "url" ? val : this.state.sources[i]["url"],
            name: element === "name" ? val : this.state.sources[i]["name"],
            extension: mode === "update" ? this.state.sources[i].extension : "",
        };
    }

    displayLogo(id, extension) {
        return (
            <span style={{ height: "100px", maxWidth: "200px" }}>
                <img
                    id={id}
                    src={
                        "img/logo_source_fiches/" +
                        this.props.parentApi.data.region +
                        "_logo" +
                        id +
                        "." +
                        extension
                    }
                    style={{ height: "150px", maxWidth: "200px" }}
                    alt=""
                />
                <br />
                <em>Logo actuel</em>
            </span>
        );
    }

    render() {
        let errors = "";
        let extension = "";
        let id = undefined;
        if (this.state.status && this.state.statusMessage) {
            errors = (
                <div className={"alert alert-" + this.state.status}>
                    {this.state.statusMessage}
                </div>
            );
        }
        let title = "Ajouter les logos des sources de données";
        let actionButton = (
            <button
                className="btn btn-success"
                onClick={() => this.addDataSourceLogo()}
            >
                Ajouter les logos
            </button>
        );
        if (this.props.mode === "update") {
            actionButton = (
                <button
                    className="btn btn-success"
                    onClick={() => this.updateDataSourceLogo()}
                >
                    Modifier le logo
                </button>
            );
            title = "Mettre à jour un logo de sources de données";
        }
        return (
            <div>
                <h3>{title}</h3>
                <br />
                <br />
                <div
                    className="analysis-sources-form"
                    style={{ display: "inline-block" }}
                >
                    <div>
                        {this.state.sources.map((item, i) => {
                            extension = item ? item.extension : "";
                            id = item ? item.id : undefined;
                            let url = item ? item.url : "";
                            let name = item ? item.name : "";
                            return (
                                <div
                                    className="analysis-sources-data-form"
                                    key={"logo_form_" + item.id}
                                >
                                    <p>Nom :</p>
                                    <p>
                                        <input
                                            type="text"
                                            defaultValue={name}
                                            onChange={(e) =>
                                                this.handleChange(
                                                    i,
                                                    "name",
                                                    e.target.value,
                                                    this.props.mode
                                                )
                                            }
                                        />
                                    </p>
                                    <p>URL :</p>
                                    <p>
                                        <input
                                            type="text"
                                            defaultValue={url}
                                            onChange={(e) =>
                                                this.handleChange(
                                                    i,
                                                    "url",
                                                    e.target.value,
                                                    this.props.mode
                                                )
                                            }
                                        />
                                    </p>

                                    <p>File :</p>
                                    <p>
                                        <input
                                            type="file"
                                            value={item.value}
                                            onChange={(e) =>
                                                this.handleChange(
                                                    i,
                                                    "file",
                                                    e.target.files[0],
                                                    this.props.mode
                                                )
                                            }
                                        />
                                    </p>
                                    <p>
                                        {this.props.mode !== "update" && (
                                            <button
                                                className="btn btn-danger"
                                                onClick={() => this.removeInput(i)}
                                            >
                                                <i className="bi bi-dash-circle-fill"></i>
                                            </button>
                                        )}
                                    </p>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div>
                    {this.props.mode !== "update" && (
                        <p>
                            <button
                                className="btn btn-success"
                                onClick={() => this.addInput()}
                            >
                                <i className="bi bi-plus-circle-fill"></i>
                            </button>
                        </p>
                    )}
                    <p>
                        {this.props.mode === "update" &&
                            this.displayLogo(id, extension)}
                    </p>
                    <hr />
                    <p>{actionButton}</p>
                </div>
                {errors}
            </div>
        );
    }
}

export default AddDataSourceLogo;
