/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import html2canvas from "html2canvas";
import { saveAs } from "file-saver";
import { SankeyDraw, convert_data, updateLayout } from "open-sankey";
import React from "react";
import { Link } from "react-router-dom";
import SEO from "./SEO.js";

import Api from "../Controllers/Api.js";
import config from "../settings.js";
import { buildRegionUrl } from "../utils.js";
import "../style/sankey.css";

var _interopRequireWildcard =
    require("@babel/runtime/helpers/interopRequireWildcard").default;
var _SankeyUtils = _interopRequireWildcard(require("open-sankey/dist/SankeyUtils"));
// var _SankeyTooltip = _interopRequireWildcard(require("open-sankey/dist/SankeyTooltip"));

/**
 * This component is used to display the energy flow according to the energy consumption and prod
 */
class Sankey extends React.Component {
    constructor(props) {
        super(props);

        // Get data from API
        this.state = {
            diagram: {
                data: {
                    version: "0.4",
                    nodes: [
                        {
                            id: 0,
                            name: "n0",
                            type: "sector",
                            visible: true,
                            label_visible: true,
                            color: "darkgrey",
                            x: 429.7218632698059,
                            y: 1.2613182067871094,
                            input_links: [],
                            output_links: [],
                            tags: {},
                        },
                    ],
                    links: [],
                    user_scale: 100,
                    height: 1500,
                    width: 2150,
                    node_width: 10,
                    h_space: 200,
                    v_space: 100,
                    left_shift: 0.3333333333333333,
                    right_shift: 0.6666666666666666,
                    max_shift: 0.2,
                    display_style: {
                        font_size: 11,
                        sector_uppercase: true,
                        sector_bold: true,
                        sector_italic: false,
                        product_uppercase: false,
                        product_bold: false,
                        product_italic: true,
                        unit: false,
                        filter: 0,
                        filter_label: 0,
                        global_curvature: 0.5,
                    },
                    tags_catalog: [],
                },
            },
            regionEnabled:
                this.props.parentApi.data.zone.zone &&
                this.props.parentApi.data.settings &&
                this.props.parentApi.data.settings.ui_show_sankey,
            urlPartageable: "",
            metaDonnees: {},
            isFullscreen: false,
            isPrinting: false,
        };

        this.escFunction = this.escFunction.bind(this);
        this.handlePrint = this.handlePrint.bind(this);
    }

    componentDidMount() {
        document.addEventListener("webkitfullscreenchange", this.escFunction, false);
        document.addEventListener("mozfullscreenchange", this.escFunction, false);
        document.addEventListener("fullscreenchange", this.escFunction, false);
        document.addEventListener("MSFullscreenChange", this.escFunction, false);

        if (this.props.parentApi.data.zone.zone) {
            this.obtenirDonnees();
        }
    }

    componentWillUnmount() {
        document.removeEventListener("webkitfullscreenchange", this.escFunction, false);
        document.removeEventListener("mozfullscreenchange", this.escFunction, false);
        document.removeEventListener("fullscreenchange", this.escFunction, false);
        document.removeEventListener("MSFullscreenChange", this.escFunction, false);
    }

    handlePrint() {
        let name = "sankey";
        if (!this.state.isPrinting) {
            this.setState({ isPrinting: true });
            const stopPrinting = () => {
                this.setState({ isPrinting: false });
            };

            html2canvas(document.querySelector("#sankey-content"), {
                onclone: (document) => {
                    Array.from(document.querySelectorAll(".not-printable")).forEach(
                        (e) => {
                            e.style.visibility = "hidden";
                        }
                    );
                    Array.from(document.querySelectorAll(".printable")).forEach((e) => {
                        e.style.visibility = "visible";
                    });
                },
            }).then((canvas) => {
                // IMAGE
                if (navigator.msSaveBlob) {
                    navigator.msSaveBlob(canvas.msToBlob(), name + ".png");
                    stopPrinting();
                } else {
                    canvas.toBlob(function (blob) {
                        saveAs(blob, name + ".png");
                        stopPrinting();
                    });
                }
            });
        }
    }

    escFunction(event) {
        // on gère le cas où la personne a quitté le plein écran sans appuyer sur le bouton
        if (
            !document.webkitIsFullScreen &&
            !document.mozFullScreen &&
            !document.msFullscreenElement
        ) {
            if (this.state.isFullscreen) {
                this.setState({ isFullscreen: false });
            }
        }
    }

    obtenirDonnees() {
        let pZone = "?zone=" + this.props.parentApi.data.zone.zone;
        let pMaille = "&maille=" + this.props.parentApi.data.zone.maille;
        let pZoneId = "&zone_id=" + this.props.parentApi.data.currentZone;
        let nomTerritoire =
            "&nom_territoire=" +
            encodeURI(
                this.props.parentApi.controller.zonesManager.getZoneName(
                    this.props.parentApi.data.currentZone,
                    this.props.parentApi.data.zone
                )
            );
        let sankeySelected = this.props.parentApi.data.sankeySelected
            ? "&sankey=" + this.props.parentApi.data.sankeySelected
            : "";
        if (
            !this.props.parentApi.controller.zonesManager.getZoneName(
                this.props.parentApi.data.currentZone,
                this.props.parentApi.data.zone
            )
        ) {
            nomTerritoire =
                "&nom_territoire=" + encodeURI(this.props.parentApi.data.nomTerritoire);
        }

        Api.callApi(
            buildRegionUrl(
                config.api_meta_donnees_sankey +
                    "?sankey=" +
                    this.props.parentApi.data.sankeySelected,
                this.props.parentApi.data.region
            ),
            null
        ).then((json) => {
            if (!json) {
                return;
            }

            if (
                json.geographical_levels_enabled &&
                !json.geographical_levels_enabled.includes(
                    this.props.parentApi.data.zone.zone
                )
            ) {
                this.setState({
                    metaDonnees: json,
                    regionEnabled: false,
                });
                return;
            }
            this.setState({
                metaDonnees: json,
            });

            if (sankeySelected === "") {
                sankeySelected = "&sankey=" + json.data_table;
            }

            let urlIdUtilisateur = "";
            if (this.props.parentApi.controller.gestionSuiviConsultations) {
                urlIdUtilisateur =
                    "&id_utilisateur=" +
                    this.props.parentApi.controller.gestionSuiviConsultations
                        .idUtilisateur;
                if (this.props.parentApi.data.zone.zone === "region") {
                    pZoneId = "&zone_id=" + this.props.parentApi.data.regionCode;
                }
                this.props.parentApi.callbacks.mettreAJourParametresUrls(
                    pZone + pMaille + pZoneId + nomTerritoire + sankeySelected
                );
                let dataSource =
                    config.api_suivi_trajectoire_energetique_url +
                    pZone +
                    pMaille +
                    pZoneId +
                    sankeySelected +
                    urlIdUtilisateur;
                Api.callApi(
                    buildRegionUrl(dataSource, this.props.parentApi.data.region),
                    null
                ).then((json) => {
                    if (!json || !json.sankey) {
                        this.setState({
                            unavailable: true,
                        });
                        return;
                    }
                    if (!json.sankey.statut) {
                        convert_data(json.sankey);
                        // compute_auto_sankey(json.sankey, 300)
                        updateLayout(json.sankey, json.sankey.layout);
                    }
                    this.setState({
                        data: json,
                        diagram: { data: json.sankey },
                    });
                });
            }
        });
        this.sankey_visu = React.createRef();
        this.generate_sankey = this.generate_sankey.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (
            !prevProps.parentApi.controller.gestionSuiviConsultations &&
            this.props.parentApi.controller.gestionSuiviConsultations &&
            this.props.parentApi.data.zone.zone &&
            this.state.metaDonnees &&
            this.state.metaDonnees.geographical_levels_enabled &&
            this.state.metaDonnees.geographical_levels_enabled.includes(
                this.props.parentApi.data.zone.zone
            )
        ) {
            this.obtenirDonnees();
        }
    }

    generate_sankey() {
        this.sankey_visu.current.modal_ref.current.state.data = this.state.diagram.data;
        this.sankey_visu.current.modal_ref.current.state.units = ["GWh"];
        this.sankey_visu.current.modal_ref.current.state.selection =
            this.state.diagram.selection;
        // this.sankey_visu.current.compute_auto_sankey()
        const { nodes, links, static_sankey } = this.state.diagram.data;
        const { units, units_name_display } = this.state.diagram;
        window.manual_sankey.add_nodes_auto(
            nodes,
            links,
            units,
            units_name_display,
            static_sankey,
            true
        );
        window.manual_sankey.add_links(
            nodes,
            links,
            units,
            units_name_display,
            static_sankey,
            true
        );
    }

    fullscreenSankey(e) {
        if (this.state.isFullscreen) {
            document.exitFullscreen();
            this.setState({ isFullscreen: false });
        } else {
            var elem = document.getElementById("sankey_with_buttons");

            /* When the openFullscreen() function is executed, open the video in fullscreen.
            Note that we must include prefixes for different browsers, as they don't support the requestFullscreen method yet */
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.webkitRequestFullscreen) {
                /* Safari */
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) {
                /* IE11 */
                elem.msRequestFullscreen();
            }

            this.setState({ isFullscreen: true });
        }
    }

    render() {
        let zoneName = this.props.parentApi.controller.zonesManager.getZoneName(
            this.props.parentApi.data.currentZone,
            this.props.parentApi.data.zone
        );
        let title = zoneName;
        if (zoneName === "") {
            title = this.props.parentApi.data.nomTerritoire;
        }
        let sankeyTitle = this.state.metaDonnees.sankey_name
            ? this.state.metaDonnees.sankey_name
            : "Flux";
        // update SEO meta data
        let dataReferencement = sankeyTitle + " - " + title;
        if (!this.state.regionEnabled || this.state.unavailable) {
            let message = "";
            if (!this.state.regionEnabled) {
                message = (
                    <>
                        Désactivé pour cette échelle géographique
                        {this.state.metaDonnees &&
                        this.state.metaDonnees.geographical_levels_enabled &&
                        this.state.metaDonnees.geographical_levels_enabled.length > 0 &&
                        this.state.metaDonnees.geographical_levels_enabled[0].length > 0
                            ? " (disponibles aux échelles suivantes : " +
                              this.state.metaDonnees.geographical_levels_enabled.join(
                                  ", "
                              ) +
                              ")"
                            : ""}
                        .
                    </>
                );
            } else {
                message = (
                    <>
                        Diagramme non accessible. Veuillez contacter les administrateurs
                        ou signaler le bug (voir page{" "}
                        <a href="/open_source">Open-Source</a> ci-dessous) !
                    </>
                );
            }
            return (
                <div className="widgets full-screen-widget">
                    <SEO
                        settings={this.props.parentApi.data.settings["seo"]}
                        page="sankey"
                        seoDetails={dataReferencement}
                    />
                    <Link
                        className="back-to-map"
                        to={"/" + this.props.parentApi.data.urlPartageable}
                    >
                        <button
                            type="button"
                            className="front-of-sankey close close-big"
                            data-dismiss="alert"
                            aria-label="Close"
                            onClick={() => {
                                this.props.parentApi.callbacks.mettreAJourParametresUrls(
                                    this.state.urlPartageable
                                );
                                this.props.parentApi.callbacks.updateCurrentMenu(
                                    "analyse"
                                );
                            }}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </Link>
                    <div className="page-content sankey" id="sankey-content">
                        <div className="title centered-row">
                            <label className="suivi-energetique-title">
                                {sankeyTitle}
                            </label>
                            <label className="suivi-energetique-territoire">
                                Territoire : {title}
                            </label>
                        </div>
                        <p className="sankey_intro">{message}</p>
                    </div>
                </div>
            );
        }

        let classTheme = this.props.parentApi.data.theme;
        if (!this.state.data) {
            return (
                <div className="widgets full-screen-widget">
                    <div className={"page-content " + classTheme} id="sankey-content">
                        <div className="loader centered-widget"></div>
                    </div>
                </div>
            );
        }

        if (!title) {
            title = this.props.parentApi.data.nomTerritoire;
        }

        if (this.props.parentApi.data.zone.zone === "region") {
            title = this.props.parentApi.data.regionLabel;
        }
        let diagram = this.state.diagram;

        window.SankeyToolStatic = false;
        diagram.data.static_sankey = true;

        let sankey = (
            <div className="sankey-confidential-data">
                Diagramme non disponible pour l'instant. Merci de contacter
                l'administrateur ou de signaler ce bug (voir page <em>Open-Source</em>{" "}
                pour plus d'informations).
            </div>
        );
        if (this.state.data.sankey.statut) {
            if (this.state.data.sankey.statut === "layout_unavailable") {
                sankey = (
                    <div className="sankey-confidential-data">
                        Le modèle de Sankey n'est pas disponible pour ce diagramme.
                        Merci de contacter l'administrateur ou de signaler ce bug (voir
                        page <em>Open-Source</em> pour plus d'informations).
                    </div>
                );
            } else if (this.state.data.sankey.statut === "confidentiel") {
                sankey = (
                    <div className="sankey-confidential-data">
                        Données confidentielles
                    </div>
                );
            } else if (this.state.data.sankey.statut === "no_data") {
                sankey = (
                    <div className="sankey-confidential-data">
                        Aucune donnée disponible pour ce diagramme.
                    </div>
                );
            }
        } else {
            sankey = (
                <SankeyDraw
                    data={diagram.data}
                    // nodeTooltipsContent={_SankeyTooltip.nodeTooltipsContent}
                    // linkTooltipsContent={_SankeyTooltip.linkTooltipsContent}
                    select_node={() => {}}
                    nodeContextMenu={() => {}}
                    node_visible={(n) => (n.visible ? "visible" : "hidden")}
                    node_label_visible={(n) => (n.label_visible ? "visible" : "hidden")}
                    node_arrow_visible={(n) =>
                        n.inputLinksId.length === 0 ||
                        !diagram.data.links[n.inputLinksId[0]].arrow
                            ? false
                            : true
                    }
                    select_link={() => {}}
                    linkContextMenu={() => {}}
                    link_color={(l) => l.color}
                    node_color={(n) => n.color}
                    link_text={_SankeyUtils.link_text}
                    link_visible={(l) => {
                        return l.visible &&
                            l.value[0] >=
                                Math.max(
                                    diagram.data.display_style.filter,
                                    diagram.data.display_style.filter_label
                                )
                            ? "visible"
                            : "hidden";
                    }}
                    test_link_value={_SankeyUtils.test_link_value}
                    more_processing={() => {}}
                    getValueIndex={() => 0}
                />
            );
        }

        let year = this.state.metaDonnees.year ? this.state.metaDonnees.year : "";
        let unit = this.state.metaDonnees.unit ? this.state.metaDonnees.unit : "";
        if (
            this.state.metaDonnees.division_units[this.props.parentApi.data.zone.zone]
        ) {
            unit =
                this.state.metaDonnees.division_units[
                    this.props.parentApi.data.zone.zone
                ];
        }
        let intro = this.state.metaDonnees.introduction_text
            ? this.state.metaDonnees.introduction_text
            : "";
        let copyright = this.state.metaDonnees.copyright ? (
            <p className="sankey_copyright">
                Basé sur une librairie de <a href="https://terriflux.com/">TerriFlux</a>
            </p>
        ) : (
            ""
        );

        const currentDate = new Date().toLocaleDateString("fr-FR");

        return (
            <div className="widgets full-screen-widget">
                <SEO
                    settings={this.props.parentApi.data.settings["seo"]}
                    page="sankey"
                    seoDetails={dataReferencement}
                />
                <Link
                    className="back-to-map"
                    to={"/" + this.props.parentApi.data.urlPartageable}
                >
                    <button
                        type="button"
                        className="close close-big close-big-sankey"
                        data-dismiss="alert"
                        aria-label="Close"
                        onClick={() => {
                            this.props.parentApi.callbacks.mettreAJourParametresUrls(
                                this.state.urlPartageable
                            );
                            this.props.parentApi.callbacks.updateCurrentMenu("analyse");
                        }}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Link>
                <div className={"page-content " + classTheme} id="sankey-content">
                    <div className="title centered-row">
                        <label className="suivi-energetique-title">
                            {sankeyTitle} {year} (en {unit})
                            <a
                                href={
                                    "/pdf/" +
                                    this.props.parentApi.data.region +
                                    "/methodologie_sankey_" +
                                    (this.props.parentApi.data.sankeySelected ??
                                        "default") +
                                    ".pdf"
                                }
                                title="Note méthodologique des diagrammes de flux"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <span className="pdf-admin not-printable"></span>
                            </a>
                        </label>
                        <label className="suivi-energetique-territoire">
                            Territoire : {title}
                        </label>
                        <div
                            className={
                                "logo-sankey-print printable logo-sankey-" +
                                this.props.parentApi.data?.theme
                            }
                        ></div>
                    </div>
                    <p className="sankey_intro">{intro}</p>
                    {copyright}
                    <div id="sankey_with_buttons">
                        <p className="sankey_fullscreen not-printable">
                            <span
                                className={
                                    this.state.isPrinting
                                        ? "loader sankey-printing-button"
                                        : "sankey-print-button sankey-print-button" +
                                          classTheme
                                }
                                title="Print"
                                alt="Print"
                                onClick={() => {
                                    this.handlePrint();
                                }}
                            ></span>
                            <span
                                className={
                                    "sankey-fullscreen-button sankey-" +
                                    (this.state.isFullscreen ? "exit-" : "") +
                                    "fullscreen-button-" +
                                    classTheme
                                }
                                title="Fullscreen"
                                alt="Toggle fullscreen"
                                onClick={(e) => {
                                    this.fullscreenSankey(e);
                                }}
                            ></span>
                        </p>
                        {sankey}
                    </div>
                    <div className="export-date printable">
                        Données exportées le {currentDate}.
                    </div>
                </div>
            </div>
        );
    }
}

export default Sankey;
