/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */
import React from "react";
import { useState } from "react";
import ReactTable from "react-table-6";

import DetailsPopup from "../DetailsPopup.js";
import Api from "../../Controllers/Api.js";
import config from "../../settings.js";
import { buildRegionUrl, filterCaseInsensitive } from "../../utils.js";

function APIEdition({ parentApi, onSuccess, apiContent, isEditing }) {
    const [errorMessage, setErrorMessage] = useState(false);
    const [editionIsLoading, setEditionIsLoading] = useState(false);

    const [paramName, setParamName] = useState(apiContent?.name ?? "");
    const [paramActor, setParamActor] = useState(apiContent?.actor ?? "");
    const [paramURL, setParamURL] = useState(apiContent?.url ?? "");
    const [paramKeyParameter, setParamKeyParameter] = useState(
        apiContent?.api_key_parameter ?? undefined
    );
    const [paramKey, setParamKey] = useState(undefined);
    const [paramMethod, setParamMethod] = useState(apiContent?.method ?? "GET");
    const [paramFormat, setParamFormat] = useState(apiContent?.format ?? "json");
    const [paramColumns, setParamColumns] = useState(apiContent?.columns ?? "");
    const [paramFilters, setParamFilters] = useState(apiContent?.filters ?? "");
    const [paramPOSTData, setParamPOSTData] = useState(apiContent?.post_data ?? "");
    const [paramTableName, setParamTableName] = useState(apiContent?.table_name ?? "");
    const [paramSubKeys, setParamSubKeys] = useState(apiContent?.subkeys ?? "");
    const [paramDetails, setParamDetails] = useState(apiContent?.details_column ?? "");
    const [paramFixedYear, setParamFixedYear] = useState(apiContent?.fixed_year ?? "");
    const [paramPerimeterYear, setParamPerimeterYear] = useState(
        apiContent?.perimeter_year ?? ""
    );
    const [paramGeographicalColumn, setParamGeographicalColumn] = useState(
        apiContent?.geographical_column ?? ""
    );

    const helpMessages = {
        table_name:
            "Nom de la table de données dans laquelle seront stockées les données récupérées par l'API",
        name: "Nom de la connexion à l'API",
        actor: "Acteur possédant l'API",
        url: "URL d'appel à l'API",
        param_key:
            "Contenu de la clé d'authentification. Si la valeur n'est pas modifiée ici, elle ne le sera pas non plus en base de données.",
        api_key_parameter:
            "Nom du paramètre envoyé avec les requêtes vers l'API pour authentification",
        method: "Méthode employée pour la requête (GET ou POST)",
        format: "Format de la réponse (json ou csv)",
        geographical_column: "Type de maille géographique (epci ou commune)",
        details_column:
            "Colonne contenant des détails à afficher lors de la requête faite par cette page (uniquement pour l'affichage).",
        columns:
            "Associations entre les colonnes fournies par l'API et les colonnes telles que considérées dans TerriSTORY®. Par exemple, il faut renseigner le nom de la colonne qui contient l'année et inscrire year: annee dans le champ ci-dessous.",
        filters:
            "Filtres appliqués aux données récupérées par l'API. Par exemple, en écrivant label: cae, le table renverra les lignes dont la valeur de la clé label est 'cae'.",
        post_data: "Données envoyées lors d'une requête POST.",
        perimeter_year: "Année du périmètre géographique des données récupérées.",
        fixed_year:
            "Année statique non dépendante des résultats de la requête et forcée pour toutes les entrées.",
        subkeys:
            "Sous forme de liste des clés à aller chercher. Quand la donnée n'est pas directement fournie dans une liste mais comme un dictionnaire contenant plusieurs sous-niveaux, contient la liste des clés à aller chercher. Par exemple, si la liste des données se trouve dans d[results][data], alors ce champ devra contenir results,data.",
    };

    /**
     * Calls the API to modify the perimeter year of the table
     * @param {*} e
     */
    const handleEdition = (e) => {
        e.preventDefault();
        setEditionIsLoading(true);
        setErrorMessage("");

        let url, method;
        if (isEditing) {
            url = buildRegionUrl(
                config.api_update_external_api_metadata,
                parentApi.data.region
            ).replace("#slug#", apiContent?.slug);
            method = "PUT";
        } else {
            url = buildRegionUrl(
                config.api_update_external_api_new,
                parentApi.data.region
            );
            method = "POST";
        }
        Api.callApi(
            url,
            JSON.stringify({
                name: paramName,
                actor: paramActor,
                url: paramURL,
                key: paramKey,
                api_key_parameter: paramKeyParameter,
                method: paramMethod,
                format: paramFormat,
                table_name: paramTableName,
                columns: paramColumns,
                filters: paramFilters,
                post_data: paramPOSTData,
                geographical_column: paramGeographicalColumn,
                subkeys: paramSubKeys,
                details_column: paramDetails,
                fixed_year: paramFixedYear,
                perimeter_year: paramPerimeterYear,
            }),
            method
        )
            .then((response) => {
                setEditionIsLoading(false);
                setErrorMessage("Modifications enregistrées !");
                // close modal and refetch tables
                onSuccess();
            })
            .catch((e) => {
                setEditionIsLoading(false);
                setErrorMessage(e.message);
            });
    };

    const parseJson = (json) => {
        let output = [];
        for (let key in json) {
            output.push(key + ": " + json[key]);
        }
        return output.join("\n");
    };

    const saveJsonEdit = (input, editionCallback) => {
        let entries = input.split("\n");
        let finalEntries = {};
        entries.forEach((line) => {
            if (line.includes(":")) {
                let objects = line.split(":", 2);
                finalEntries[objects[0].trim()] = objects[1].trim();
            }
        });
        editionCallback(finalEntries);
    };

    return (
        <div className="mt-3">
            {!isEditing ? (
                <p>
                    Le formulaire suivant permet d'éditer les caractéristiques d'un
                    nouveau lien vers une API externe.
                </p>
            ) : (
                <p>
                    Le formulaire suivant permet d'éditer les caractéristiques du lien
                    vers l'API externe <strong>{apiContent?.name}</strong>.
                </p>
            )}
            <form className="mb-3" onSubmit={handleEdition}>
                {!isEditing && (
                    <div className="mb-3">
                        <label
                            className="col-sm-6 control-label"
                            title={helpMessages["table_name"]}
                            htmlFor="table_name"
                        >
                            Nom de la table de données :{" "}
                        </label>
                        <input
                            className="col-sm-6"
                            type="text"
                            id="table_name"
                            value={paramTableName}
                            onChange={(e) => setParamTableName(e.target.value)}
                            required
                        />{" "}
                    </div>
                )}
                <div className="mb-3">
                    <label
                        className="col-sm-6 control-label"
                        title={helpMessages["name"]}
                        htmlFor="name"
                    >
                        Nom :{" "}
                    </label>
                    <input
                        className="col-sm-6"
                        type="text"
                        id="name"
                        value={paramName}
                        onChange={(e) => setParamName(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label
                        className="col-sm-6 control-label"
                        title={helpMessages["actor"]}
                        htmlFor="actor"
                    >
                        Acteur :{" "}
                    </label>
                    <input
                        className="col-sm-6"
                        type="text"
                        id="actor"
                        value={paramActor}
                        onChange={(e) => setParamActor(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label
                        className="col-sm-6 control-label"
                        title={helpMessages["url"]}
                        htmlFor="url"
                    >
                        URL principale :{" "}
                    </label>
                    <input
                        className="col-sm-6"
                        type="text"
                        id="url"
                        value={paramURL}
                        onChange={(e) => setParamURL(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label
                        className="col-sm-6 control-label"
                        title={helpMessages["param_key"]}
                        htmlFor="param_key"
                    >
                        Clé :{" "}
                    </label>
                    <input
                        className="col-sm-6"
                        type="text"
                        id="param_key"
                        placeholder="*****************"
                        value={paramKey}
                        onChange={(e) => setParamKey(e.target.value)}
                    />
                </div>
                <div className="mb-3">
                    <label
                        className="col-sm-6 control-label"
                        title={helpMessages["api_key_parameter"]}
                        htmlFor="api_key_parameter"
                    >
                        Nom du paramètre contenant la clé :{" "}
                    </label>
                    <input
                        className="col-sm-6"
                        type="text"
                        id="api_key_parameter"
                        value={paramKeyParameter}
                        onChange={(e) => setParamKeyParameter(e.target.value)}
                    />
                </div>
                <div className="mb-3">
                    <label
                        className="col-sm-6 control-label"
                        title={helpMessages["method"]}
                        htmlFor="method"
                    >
                        Méthode :{" "}
                    </label>
                    <select
                        className="col-sm-6"
                        id="method"
                        value={paramMethod}
                        onChange={(e) => setParamMethod(e.target.value)}
                        required
                    >
                        <option value="GET">GET</option>
                        <option value="POST">POST</option>
                    </select>
                </div>
                <div className="mb-3">
                    <label
                        className="col-sm-6 control-label"
                        title={helpMessages["format"]}
                        htmlFor="format"
                    >
                        Format :{" "}
                    </label>
                    <select
                        className="col-sm-6"
                        id="format"
                        value={paramFormat}
                        onChange={(e) => setParamFormat(e.target.value)}
                        required
                    >
                        <option value="json">JSON</option>
                        <option value="csv">CSV</option>
                    </select>
                </div>
                <div className="mb-3">
                    <label
                        className="col-sm-6 control-label"
                        title={helpMessages["subkeys"]}
                        htmlFor="subkeys"
                    >
                        Sous-clés :{" "}
                    </label>
                    <input
                        className="col-sm-6"
                        type="text"
                        id="subkeys"
                        value={paramSubKeys}
                        onChange={(e) => setParamSubKeys(e.target.value)}
                    />
                </div>
                <div className="mb-3">
                    <label
                        className="col-sm-6 control-label"
                        title={helpMessages["perimeter_year"]}
                        htmlFor="perimeter_year"
                    >
                        Millésime (année) du périmètre géographique :{" "}
                    </label>
                    <input
                        className="col-sm-6"
                        type="number"
                        id="perimeter_year"
                        value={paramPerimeterYear}
                        onChange={(e) => setParamPerimeterYear(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label
                        className="col-sm-6 control-label"
                        title={helpMessages["fixed_year"]}
                        htmlFor="fixed_year"
                    >
                        Année statique :{" "}
                    </label>
                    <input
                        className="col-sm-6"
                        type="number"
                        id="fixed_year"
                        value={paramFixedYear}
                        onChange={(e) => setParamFixedYear(e.target.value)}
                    />
                </div>
                <div className="mb-3">
                    <label
                        className="col-sm-6 control-label"
                        title={helpMessages["details_column"]}
                        htmlFor="details_column"
                    >
                        Colonne de détails pour l'affichage :{" "}
                    </label>
                    <input
                        className="col-sm-6"
                        type="text"
                        id="details_column"
                        value={paramDetails}
                        onChange={(e) => setParamDetails(e.target.value)}
                    />
                </div>
                <div className="mb-3">
                    <label
                        className="col-sm-6 control-label"
                        title={helpMessages["geographical_column"]}
                        htmlFor="geographical_column"
                    >
                        Maille géographique :{" "}
                    </label>
                    <input
                        className="col-sm-6"
                        type="text"
                        id="geographical_column"
                        value={paramGeographicalColumn}
                        onChange={(e) => setParamGeographicalColumn(e.target.value)}
                    />
                </div>
                <div className="mb-3">
                    <label
                        className="col-sm-6 control-label"
                        title={helpMessages["columns"]}
                        htmlFor="columns"
                    >
                        Colonnes* :{" "}
                    </label>
                    <textarea
                        className="col-sm-6"
                        type="text"
                        id="columns"
                        defaultValue={parseJson(paramColumns)}
                        onChange={(e) => {
                            saveJsonEdit(e.target.value, setParamColumns);
                        }}
                    ></textarea>
                </div>
                <div className="mb-3">
                    <label
                        className="col-sm-6 control-label"
                        title={helpMessages["filters"]}
                        htmlFor="filters"
                    >
                        Filtres* :{" "}
                    </label>
                    <textarea
                        className="col-sm-6"
                        type="text"
                        id="filters"
                        defaultValue={parseJson(paramFilters)}
                        onChange={(e) => {
                            saveJsonEdit(e.target.value, setParamFilters);
                        }}
                    ></textarea>
                </div>
                <div className="mb-3">
                    <label
                        className="col-sm-6 control-label"
                        title={helpMessages["post_data"]}
                        htmlFor="post_data"
                    >
                        POST data* :{" "}
                    </label>
                    <textarea
                        className="col-sm-6"
                        type="text"
                        id="post_data"
                        defaultValue={parseJson(paramPOSTData)}
                        onChange={(e) => {
                            saveJsonEdit(e.target.value, setParamPOSTData);
                        }}
                    ></textarea>
                </div>
                <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={editionIsLoading}
                >
                    {editionIsLoading && (
                        <>
                            <span
                                className="spinner-border spinner-border-sm me-1"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            <span className="visually-hidden">Loading...</span>
                        </>
                    )}
                    Enregistrer les modifications
                </button>
            </form>
            <p>
                *Les champs marqués par l'astérisque doivent être remplis avec un couple{" "}
                <em>clé</em> et <em>valeur</em> par ligne, par exemple de la façon
                suivante :
            </p>
            <pre>
                code_siren: epci
                <br />
                etoiles: valeur
            </pre>
            {errorMessage && (
                <div className="alert alert-danger" role="alert">
                    {errorMessage}
                </div>
            )}
        </div>
    );
}

class ExternalAPI extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            apiList: [],
            fullResponses: {},
            shownFullResponse: false,

            showAPIEdition: false,
        };
    }

    componentDidMount() {
        if (
            this.props.connected &&
            this.props.userInfos &&
            this.props.userInfos.profil &&
            this.props.userInfos?.profil === "admin" &&
            this.state.apiList.length === 0
        ) {
            this.getAPIsList();
        }
    }

    getAPIsList() {
        Api.callApi(
            buildRegionUrl(
                config.api_get_external_api_list,
                this.props.parentApi.data.region
            ),
            null,
            "GET"
        )
            .then((response) => {
                this.setState({ apiList: response });
            })
            .catch((e) => {
                alert("Erreur lors de la récupération des données sur l'API.");
            });
    }

    editAPI(props) {
        let modalContent = (
            <APIEdition
                parentApi={this.props.parentApi}
                onSuccess={() => {
                    this.closeAPIEditionModal();
                    this.getAPIsList();
                }}
                apiContent={props}
                isEditing={props !== undefined}
            />
        );
        this.setState({
            showAPIEdition: true,
            editionModal: modalContent,
        });
    }
    closeAPIEditionModal() {
        this.setState({ showAPIEdition: false });
    }

    addNewAPI() {
        this.editAPI();
    }

    deleteAPI(props) {
        let r = window.confirm(
            "Souhaitez vous vraiment supprimer ce lien vers l'API '" +
                props.name +
                "' ?"
        );
        if (r !== true) {
            return;
        }
        Api.callApi(
            buildRegionUrl(
                config.api_update_external_api_delete,
                this.props.parentApi.data.region
            ).replace("#slug#", props.slug),
            null,
            "DELETE"
        )
            .then((response) => {
                this.getAPIsList();
                this.props.parentApi.callbacks.updateMessages(
                    "La suppression a bien été effectuée !",
                    "success"
                );
            })
            .catch((e) => {
                this.props.parentApi.callbacks.updateMessages(
                    "Un problème est survenu lors de la suppression du lien (" +
                        e +
                        ").",
                    "danger"
                );
            });
    }

    callAPI(slug) {
        const url = buildRegionUrl(
            config.api_call_external_api,
            this.props.parentApi.data.region
        ).replace("#api_key#", slug);

        Api.callApi(url, null, "GET")
            .then((response) => {
                if (response.status === "success") {
                    this.props.parentApi.callbacks.updateMessages(
                        response.message,
                        "success"
                    );
                    // if we did receive full response, we add it to current
                    // state attribute that stores all full responses (to be able
                    // to display them later)
                    if (response.full_response) {
                        let fullResponses = { ...this.state.fullResponses };
                        fullResponses[slug] = response.full_response;
                        this.setState({
                            fullResponses,
                        });
                    }
                } else {
                    this.props.parentApi.callbacks.updateMessages(
                        response.message,
                        "danger"
                    );
                }
            })
            .catch((error) => {
                this.props.parentApi.callbacks.updateMessages(error.message, "danger");
            });
    }

    toggleFullResponse(slug) {
        if (this.state.shownFullResponse !== slug) {
            this.setState({
                shownFullResponse: slug,
            });
        }
    }

    displayFullResponse() {
        // we haven't shown anything yet
        if (!this.state.shownFullResponse) {
            return;
        }

        // we get config to use right column to display details
        let apiConfig = this.state.apiList.find(
            (api) => api.slug === this.state.shownFullResponse
        );

        // we get data to be shown
        let data = this.state.fullResponses[this.state.shownFullResponse];

        const showElement = (elementData) => {
            let output = [];
            for (const key in elementData) {
                if (Object.hasOwnProperty.call(elementData, key)) {
                    const element = elementData[key];
                    output.push(
                        <li key={key}>
                            {key}: {element}
                        </li>
                    );
                }
            }
            return <ul>{output}</ul>;
        };

        if (apiConfig.subkeys) {
            for (const subkey of apiConfig.subkeys.split(",")) {
                data = data[subkey];
            }
        }
        let content = data.map((element, i) => {
            return (
                <div key={i}>
                    <strong>{element[apiConfig?.details_column ?? "nom"]}</strong>
                    {}
                    <div className="wrap-pre">{showElement(element)}</div>
                </div>
            );
        });

        return (
            <DetailsPopup
                title="Réponse complète de l'API externe"
                content={content}
                show={true}
                emptyMsg="Aucune donnée disponible."
                callbackAfterClosing={() => this.toggleFullResponse(false)}
            />
        );
    }

    render() {
        if (
            !this.props.connected ||
            !this.props.userInfos ||
            this.props.userInfos?.profil !== "admin"
        ) {
            return <div>Non accessible.</div>;
        }

        const columns = [
            {
                Header: "Nom",
                accessor: "name",
                style: { whiteSpace: "unset" },
            },
            {
                Header: "Acteur",
                accessor: "actor",
                width: 120,
            },
            {
                Header: "URL principale",
                accessor: "url",
                width: 300,
            },
            {
                Header: "Clé",
                accessor: "key",
                filterable: false,
                width: 120,
            },
            {
                Header: "Méthode",
                accessor: "method",
                width: 75,
            },
            {
                Header: "Format",
                accessor: "format",
                width: 120,
            },
            {
                Header: "Table locale",
                accessor: "table_name",
                Cell: (row) => {
                    return (
                        <span>
                            {row.original.table_name}
                            <br />
                            Périmètre : <em>{row.original.perimeter_year}</em>
                        </span>
                    );
                },
            },
            {
                Header: "Autres détails",
                accessor: "details",
                Cell: (row) => {
                    return (
                        <span>
                            Maille géographique :{" "}
                            <em>{row.original.geographical_column}</em>
                            <br />
                            Colonne de détails : <em>{row.original.details_column}</em>
                            {row.original.subkeys && (
                                <>
                                    <br />
                                    Sous-clés : <em>{row.original.subkeys}</em>
                                </>
                            )}
                            {row.original.fixed_year && (
                                <>
                                    <br />
                                    Année statique : <em>{row.original.fixed_year}</em>
                                </>
                            )}
                        </span>
                    );
                },
            },
            {
                Header: "Actions",
                accessor: "action",
                filterable: false,
                Cell: (row) => {
                    const slug = row.original.slug;
                    return (
                        <div>
                            <p>
                                <button
                                    className="btn btn-warning pull-button"
                                    onClick={() => this.callAPI(slug)}
                                >
                                    Importer
                                </button>
                                {this.state.fullResponses[slug] && (
                                    <button
                                        className="btn btn-warning pull-button"
                                        onClick={() => this.toggleFullResponse(slug)}
                                    >
                                        Afficher
                                    </button>
                                )}
                            </p>
                            <p>
                                <button
                                    className="btn btn-primary pull-button"
                                    onClick={() => this.editAPI(row.original)}
                                >
                                    Modifier
                                </button>
                                <button
                                    className="btn btn-danger pull-button"
                                    onClick={() => this.deleteAPI(row.original)}
                                >
                                    Supprimer
                                </button>
                            </p>
                        </div>
                    );
                },
                width: 100,
                style: { whiteSpace: "unset" },
            },
            {
                Header: "Mise à jour",
                accessor: "date_maj",
                width: 120,
            },
        ];

        return (
            <div>
                <div className="panel-body panel-ajout-indicateur">
                    <h3 className="panel-title pull-left">
                        Connexion à des API externes
                    </h3>
                    <p>
                        <button
                            className="btn btn-success pull-button"
                            onClick={() => this.addNewAPI()}
                        >
                            Ajouter un lien vers une API externe
                        </button>
                    </p>
                </div>
                <ReactTable
                    data={this.state.apiList}
                    columns={columns}
                    className="-striped"
                    defaultPageSize={10}
                    filterable={true}
                    defaultFilterMethod={(filter, row) =>
                        filterCaseInsensitive(filter, row)
                    }
                />
                {this.displayFullResponse()}
                {this.state.showAPIEdition && (
                    <DetailsPopup
                        title="Modification d'un lien vers une API externe"
                        content={this.state.editionModal}
                        show={this.state.showAPIEdition}
                        emptyMsg="Nothing to show"
                        callbackAfterClosing={() => this.closeAPIEditionModal()}
                    />
                )}
            </div>
        );
    }
}

export default ExternalAPI;
