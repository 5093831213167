/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React, { useState, useEffect } from "react";
import Api from "../../Controllers/Api";

import config from "../../settings.js";
import { buildRegionUrl, filterCaseInsensitive, exportToCSV } from "../../utils.js";
import ReactTable from "react-table-6";

/**
 * This admin page manages PCAET data and updates them from ADEME's files.
 */
function Pcaet({ connected, userInfos, parentApi }) {
    const [updateSwitch, forceUpdate] = useState(false);
    const onUpdatePcaet = () => forceUpdate(!updateSwitch);

    if (!connected || !userInfos || userInfos.profil !== "admin") {
        return <div>Non accessible.</div>;
    }

    return (
        <div>
            <h3>Gestion des PCAET</h3>
            <div className="block-etape" style={{ marginBottom: "32px" }}>
                <h4>Récupération des PCAET</h4>
                <UpdatePcaetFromAdeme
                    region={parentApi.data.region}
                    onSuccess={onUpdatePcaet}
                />
            </div>
            <UpdateSirenAssignment
                region={parentApi.data.region}
                onSuccess={onUpdatePcaet}
            />
            <div className="block-etape">
                <h4>Liste des PCAET</h4>
                <PcaetList region={parentApi.data.region} forceUpdate={updateSwitch} />
            </div>
        </div>
    );
}

function UpdatePcaetFromAdeme({ region, onSuccess }) {
    const [pcaetUpdateMessage, setPcaetUpdateMessage] = useState("");

    const loadingMessage = "Mise à jour en cours... ceci peut prendre quelques minutes";
    const errorMessage =
        "Erreur lors du chargement de données. Réessayez ultérieurement.\nSi l'erreur persiste, contactez AURA-EE.";

    /** Call the API to update PCAET data from ADEME */
    function run_update_pcaet() {
        setPcaetUpdateMessage(loadingMessage);
        let url = buildRegionUrl(config.mise_a_jour_des_pcaet_ademe, region);
        Api.callApi(url)
            .then(() => {
                setPcaetUpdateMessage("Mise à jour réussie !");
                if (onSuccess) onSuccess();
            })
            .catch((e) => {
                console.error(e);
                setPcaetUpdateMessage(errorMessage);
                alert(errorMessage);
            });
    }

    return (
        <>
            <button
                className="btn btn-primary"
                disabled={pcaetUpdateMessage === loadingMessage}
                onClick={() => run_update_pcaet()}
            >
                Mettre à jour les PCAET
            </button>
            {/* display or not the loading/success/error message */}
            {pcaetUpdateMessage && <p>{pcaetUpdateMessage}</p>}
        </>
    );
}

function UpdateSirenAssignment({ region, onSuccess }) {
    const [sirenData, setSirenData] = useState([]);
    const [updateSwitch, forceUpdate] = useState(false);

    useEffect(() => {
        const url = buildRegionUrl(config.api_siren_assignment, region);
        Api.callApi(url).then(setSirenData);
    }, [region, updateSwitch]);

    const updateSirenAssignment = (e) => {
        e.preventDefault();

        const file = e.target.elements.file.files[0];
        let formData = new FormData();
        formData.append("fichier", file);

        const url = buildRegionUrl(config.api_siren_assignment, region);
        Api.callApi(url, formData, "PUT", "default").then(() => {
            forceUpdate(!updateSwitch);
            if (onSuccess) onSuccess();
        });
    };

    return (
        <div className="block-etape">
            <h4>Voir ou modifier les numéro de SIREN</h4>
            <table className="table_preview_header" style={{ margin: "auto" }}>
                <tbody>
                    <tr>
                        <th>
                            zone_type
                            <br />
                            <i>(Échelle territoriale)</i>
                        </th>
                        <th>
                            zone_id
                            <br />
                            <i>(Numéro du territoire)</i>
                        </th>
                        <th>
                            siren
                            <br />
                            <i>(Numéro de SIREN)</i>
                        </th>
                        <th>
                            epcis
                            <br />
                            <i>(Liste des numéros d'EPCI composant le territoire)</i>
                        </th>
                    </tr>
                    {sirenData.length ? (
                        sirenData.map((row) => (
                            <tr key={row.zone_id}>
                                <td>{row.zone_type}</td>
                                <td>{row.zone_id}</td>
                                <td>{row.siren}</td>
                                <td>{JSON.stringify(row.epcis)}</td>
                            </tr>
                        ))
                    ) : (
                        <tr style={{ color: "grey" }}>
                            <td colSpan={4}>[Table vide]</td>
                        </tr>
                    )}
                </tbody>
            </table>
            <button
                className="btn btn-info"
                onClick={() => exportToCSV(sirenData, "csv")}
                style={{ margin: "4px" }}
            >
                Exporter en CSV
            </button>
            <form onSubmit={updateSirenAssignment}>
                <input type="file" name="file" required />
                <input
                    className="btn btn-success"
                    type="submit"
                    value="Mettre à jour"
                />
            </form>
            <br />
        </div>
    );
}

function PcaetList({ region, forceUpdate }) {
    const [pcaetList, setPcaetList] = useState([]);
    const [methodIsRelative, setMethodRelative] = useState(false);
    const [years, setYears] = useState({
        2021: false,
        2026: false,
        2030: false,
        2050: false,
    });
    const [trajectories, setTrajectories] = useState({});
    const [demarche, setDemarche] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    function setYearActivation(year, active) {
        setYears({ ...years, [year]: active });
    }
    function setTrajectoryActivation(traj, active) {
        setTrajectories({ ...trajectories, [traj]: { ...trajectories[traj], active } });
    }

    function exportTableToCSV(data) {
        exportToCSV(data, "csv");
    }

    useEffect(
        function loadTrajectories() {
            Api.callApi(
                buildRegionUrl(config.api_pcaet_trajectories_list, region)
            ).then((trajs) =>
                setTrajectories(
                    Object.fromEntries(
                        trajs.map(({ id, name, unit }) => [
                            id,
                            { name, unit, active: false },
                        ])
                    )
                )
            );
        },
        [region]
    );
    useEffect(
        function loadData() {
            setIsLoading(true);
            let promise = Api.callApi(
                buildRegionUrl(config.api_all_pcaet, region) +
                    `?relative_pcaet=${methodIsRelative}`
            );
            promise
                .then((pcaets) => {
                    setPcaetList(pcaets);
                    setIsLoading(false);
                })
                .catch((error) => {
                    if (error.name === "AbortError") return;
                    setIsLoading(false);
                    alert(error.message);
                });
            return () => promise.abort();
        },
        [region, methodIsRelative, forceUpdate, setIsLoading]
    );

    let columns = [
        { Header: "Nom du territoire", accessor: "nom", maxWidth: 500 },
        { Header: "SIREN", accessor: "siren", width: 100 },
        { Header: "Nombre d’EPCI", accessor: "epci_nb", width: 100 },
        { Header: "Démarche", accessor: "demarche", width: 100 },
    ];
    const activeTrajectories = Object.entries(trajectories)
        .filter(([_, { active }]) => active)
        .map(([traj, _]) => traj);
    const activeYears = Object.entries(years)
        .filter(([_, active]) => active)
        .map(([year, _]) => year);

    for (const trajectory of activeTrajectories) {
        const { unit, name } = trajectories[trajectory];
        columns.push({
            Header: `${name} observée (${unit})`,
            accessor: `${trajectory}_obs`,
        });
        for (const year of activeYears) {
            columns.push({
                Header: `${name} ${year} (${unit})`,
                accessor: `${trajectory}_${year}`,
            });
        }
    }

    let displayedPcaetList = pcaetList.filter((pcaet) => {
        if (demarche !== "" && pcaet.demarche !== demarche) {
            return false;
        }
        for (const trajectory of activeTrajectories) {
            if (pcaet[`${trajectory}_obs`] === undefined) {
                return false;
            }
            for (const year of activeYears) {
                if (pcaet[`${trajectory}_${year}`] === undefined) {
                    return false;
                }
            }
        }
        return true;
    });

    const total = { nom: "Total" };
    for (const col of columns) {
        if (["siren", "nom", "demarche"].includes(col.accessor)) continue;
        total[col.accessor] = displayedPcaetList
            .map((row) => row[col.accessor])
            .reduce((val1, val2) => val1 + val2, 0);
    }
    displayedPcaetList = [total, ...displayedPcaetList];

    return (
        <>
            <div className="my-1">
                <label className="switch">
                    <input
                        type="checkbox"
                        id="method-switch"
                        defaultChecked={methodIsRelative}
                        disabled
                        onChange={(e) => setMethodRelative(e.target.checked)}
                    />
                    <span className="slider round" />
                </label>{" "}
                <label htmlFor="method-switch" title="Non disponible pour le moment">
                    Méthode de calcul en pourcentage par rapport à l'annnée de référence
                </label>{" "}
            </div>
            <h5>Filtrer par…</h5>
            <form className="pcaet-aggregation-filter">
                <fieldset>
                    Type de trajectoire :
                    {Object.entries(trajectories).map(([id, { active, name }]) => (
                        <label key={id}>
                            <input
                                type="checkbox"
                                defaultChecked={active}
                                onChange={(e) =>
                                    setTrajectoryActivation(id, e.target.checked)
                                }
                            />{" "}
                            {name}
                            <br />
                        </label>
                    ))}
                </fieldset>
                <fieldset>
                    Années d'objectif :
                    {["2021", "2026", "2030", "2050"].map((year) => (
                        <label key={year}>
                            <input
                                type="checkbox"
                                defaultChecked={years[year]}
                                onChange={(e) =>
                                    setYearActivation(year, e.target.checked)
                                }
                            />{" "}
                            {year}
                            <br />
                        </label>
                    ))}
                </fieldset>
                <fieldset>
                    Démarche :
                    <label>
                        <input
                            type="checkbox"
                            checked={demarche === "Obligée"}
                            onChange={(e) =>
                                setDemarche(e.target.checked ? "Obligée" : "")
                            }
                        />{" "}
                        Obligée
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            checked={demarche === "Volontaire"}
                            onChange={(e) =>
                                setDemarche(e.target.checked ? "Volontaire" : "")
                            }
                        />{" "}
                        Volontaire
                    </label>
                </fieldset>
            </form>
            {isLoading ? (
                <span className="spinner-border" role="status" />
            ) : (
                <>
                    <p className="export-data-button-in-popup">
                        <button
                            className="btn btn-info"
                            onClick={() => exportTableToCSV(displayedPcaetList)}
                        >
                            Exporter le contenu de la table en csv
                        </button>
                    </p>
                    <ReactTable
                        data={displayedPcaetList}
                        columns={columns}
                        className="-striped"
                        defaultFilterMethod={(filter, row) =>
                            filterCaseInsensitive(filter, row)
                        }
                        getTrProps={(_, row) => {
                            return row?.row?.nom === "Total"
                                ? { style: { background: "#c9f9f8" } }
                                : {};
                        }}
                    />
                </>
            )}
        </>
    );
}

export default Pcaet;
