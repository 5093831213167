/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React, { useRef, useState, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";

import Api from "../../Controllers/Api.js";
import config from "../../settings.js";
import { buildRegionUrl } from "../../utils.js";

import "../../style/national/dataimport.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-table-6/react-table.css";

const DataImport = (props) => {
    const inputFileRef = useRef(null);
    const [fichierDonnees, setFichierDonnees] = useState(undefined);
    const [metadata, setMetadata] = useState({});
    const [status, setStatus] = useState(undefined);
    const [responseError, setResponseError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [categories, setCategories] = useState(undefined);
    const [unit, setUnit] = useState(undefined);
    const [enabledModules, setEnabledModules] = useState({});

    const location = useLocation();
    const { region } = useParams();
    const params = location.state?.params;

    useEffect(() => {
        // Build URL
        let url = buildRegionUrl(
            config.api_analysis_data_categories_national_url,
            "national"
        )
            .replace("#territory#", region)
            .replace("#table_name#", params.table);

        Api.callApi(url, null, "GET")
            .then((response) => {
                setCategories(response.categories);
                setUnit(response.data_unit);
            })
            .catch((e) => console.error(e));

        url =
            buildRegionUrl(config.api_national_import_metadata, region) +
            "/" +
            params.table;
        Api.callApi(url, null, "GET")
            .then((response) => {
                setMetadata(response);
            })
            .catch((e) => console.error(e));
    }, [region, params.table]);

    const renderTips = () => {
        return (
            <div className="import-tips">
                <h3>Consignes générales</h3>
                <p>
                    Le fichier de données doit être au <strong>format CSV</strong>,
                    séparé par des <strong>points-virgules</strong> avec le séparateur
                    décimal étant le point. La <strong>première ligne</strong> doit
                    contenir les <strong>entêtes</strong> avec les noms de colonnes
                    spécifiés ci-dessous (l'ordre n'est pas important).
                </p>
                <h3>Colonnes attendues</h3>
                <div class="cards">
                    <div className="card">
                        <strong>
                            <code>annee</code>
                        </strong>
                        le millésime des données
                    </div>
                    <div className="card">
                        <strong>
                            <code>valeur</code>
                        </strong>
                        {unit && `en (${unit})`}
                    </div>
                    {categories &&
                        Object.keys(categories).map((category) => {
                            if (
                                metadata.categories &&
                                !metadata.categories.includes(category)
                            ) {
                                return "";
                            }
                            return (
                                <div className="card" key={category}>
                                    <strong>
                                        <code>{category}</code>
                                    </strong>
                                    <details className="tsdetails">
                                        <summary>détail des modalités</summary>
                                        <div>
                                            <ul>
                                                {categories[category].map((item) => (
                                                    <li key={item.modalite_id}>
                                                        <code>{item.modalite}</code>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </details>
                                </div>
                            );
                        })}
                </div>

                {metadata.template_file && (
                    <div>
                        <h3>Exemple de table attendue</h3>
                        <p>
                            Vous trouverez à l'adresse{" "}
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={"/uploads/" + metadata.template_file}
                            >
                                suivante
                            </a>{" "}
                            un <strong>document Excel</strong> vous permettant de
                            remplir et de générer automatiquement le fichier csv attendu
                            dans le bon format. Vous n'avez plus qu'à remplir la feuille
                            appelée «&nbsp;<em>Onglet à remplir</em>&nbsp;» et la
                            feuille «&nbsp;<em>CSV</em>&nbsp;» pourra être enregistrée
                            comme fichier CSV puis importée ici. Plus d'informations
                            sont disponibles dans l'onglet «&nbsp;Mode d'emploi&nbsp;».
                        </p>
                    </div>
                )}
            </div>
        );
    };

    const renderEnabledModules = () => {
        if (!enabledModules) return "";

        const nbEnabledModules = Object.values(enabledModules).filter(Boolean).length;
        if (nbEnabledModules === 0) {
            return (
                <p>
                    Aucun module activé pour l'instant, certaines données manquent
                    encore.
                </p>
            );
        }
        return (
            <p>
                <strong>
                    {nbEnabledModules} module{nbEnabledModules > 1 && "s"} activé
                    {nbEnabledModules > 1 && "s"}
                </strong>{" "}
                : {enabledModules.simulator && "le simulateur"}
                {nbEnabledModules > 1 && ", "}
                {enabledModules.territorial_strategy && "le module de stratégie"}
            </p>
        );
    };

    const renderActionButtons = () => {
        let submitted = "";
        let loadingMsg = "";

        if (status) {
            loadingMsg = "";
            submitted = (
                <div className="form-group centered-row">
                    <label
                        className={`alert alert-${
                            responseError ? "warning" : "success"
                        } col-sm-4`}
                    >
                        {status}
                        {renderEnabledModules()}
                    </label>
                </div>
            );
        }
        if (isLoading) {
            submitted = "";
            loadingMsg = (
                <div className="form-group centered-row">
                    <label className={`col-sm-4`}>
                        <div className="loader-data"></div>
                    </label>
                </div>
            );
        }
        return (
            <div className="actions">
                <input
                    type="file"
                    ref={inputFileRef}
                    onChange={(e) => onFichierDataSelection(e)}
                />
                <button
                    className="tsbtn warning"
                    onClick={() => updateTable(params.table)}
                    disabled={isLoading}
                >
                    Mettre à jour
                </button>
                <br />
                {loadingMsg}
                <br />
                {submitted}
            </div>
        );
    };

    const onFichierDataSelection = (event) => {
        setFichierDonnees(event.target.files[0]);
    };

    const resetInputFile = () => {
        inputFileRef.current.value = null;
    };

    const updateTable = (table) => {
        // Check if a data file is selected
        if (!fichierDonnees) {
            setResponseError(true);
            setStatus("Vous devez choisir un fichier de données");
            return;
        }

        const userConfirmed = window.confirm(
            "Attention, cette opération n'est pas réversible. Voulez-vous continuer ?"
        );
        if (!userConfirmed) {
            return;
        }

        // Prepare the form data to be sent
        const formData = new FormData();
        formData.append("file", fichierDonnees);

        // Build the API URL
        const baseUrl = buildRegionUrl(
            config.api_analysis_data_national_url,
            "national"
        ).replace("#territory#", props.parentApi.data.region);
        const url = `${baseUrl}/${table}`;

        // Set loading state
        setIsLoading(true);

        // Make the API call
        Api.callApi(url, formData, "PUT", "default")
            .then((response) => {
                // Handle successful response
                setResponseError(false);
                setStatus(response.message);
                resetInputFile();
                update_data();
            })
            .catch((error) => {
                // Handle error response
                setResponseError(true);
                setStatus(error.message);
            })
            .finally(() => {
                // Reset loading state
                setIsLoading(false);
            });
    };

    const update_data = () => {
        // Build the API URL
        const url = buildRegionUrl(
            config.api_activate_territorial_strategy_simulator,
            "national"
        ).replace("#territory#", region);

        // Make the API call
        Api.callApi(url, null, "GET", "default")
            .then((response) => {
                // Handle successful response
                setEnabledModules(response);
            })
            .catch((error) => {
                // Handle error response
                console.error("Error updating data:", error);
            });
    };

    return (
        <>
            <div>
                <Link
                    className="btn btn-light return-button"
                    to={"/national/" + region + "/import"}
                >
                    <i className="bi bi-arrow-left"></i>
                </Link>
            </div>
            <div className="container-import">
                <h3 className="tstitle">
                    Import des données - {metadata?.table_name ?? params.table}
                </h3>
                {metadata?.description && <p>{metadata?.description}</p>}
                {renderActionButtons()}
            </div>
            <div className="container-import">
                <h3 className="tstitle">Aide</h3>
                {renderTips()}
            </div>
        </>
    );
};

export default DataImport;
