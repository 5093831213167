/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import config from "../settings.js";
import { buildRegionUrl } from "../utils.js";
import Api from "./Api";

/**
 * This component is used to manage poi layers
 */
class Equipements {
    constructor(callback, region) {
        this.region = region;
        this.fetchConfiguration(callback);
        this.poiList = [];
        this.poiLayers = [];
        this.themes = [];
    }

    fetchConfiguration(callback) {
        // Retreive poi list from DB
        // List all zones available
        return Api.callApi(buildRegionUrl(config.api_poi_layers_url, this.region))
            .then((json) => {
                this.poiList = json;
                // Tell the main app that the configuration has been loaded
                callback(this.getEquipementsLayers());
            })
            .catch((e) => {
                console.log("Problème de téléchargement des données d'équipements");
                callback([]);
            });
    }

    /*
     * Get poi list
     */
    getEquipementsLayers(refresh, callback) {
        if (refresh) {
            this.fetchConfiguration(callback);
        }
        this.poiLayers = [];
        this.themes = [];

        for (let poi of this.poiList) {
            this.poiLayers.push({
                id: poi.id,
                nom: poi.nom,
                label: poi.label,
                couleur: poi.couleur,
                modifiable: poi.modifiable,
                checked: false,
                afficherStatut: poi.statut,
                exportable: poi.donnees_exportables,
                theme: poi.theme,
                type_installation: poi.type_installation,
                structure_constraints: poi?.structure_constraints || {},
                typeGeom: poi.type_geom,
                ancrageIcone: poi.ancrage_icone,
                creditsDataSources: poi.credits_data_sources?.length
                    ? JSON.parse(poi.credits_data_sources)
                    : [],
                creditsDataProducers: poi.credits_data_producers?.length
                    ? JSON.parse(poi.credits_data_producers)
                    : [],
            });

            if (this.themes.indexOf(poi.theme) === -1) {
                this.themes.push(poi.theme);
            }
        }

        return this.poiLayers;
    }

    /*
     * Refresh poi list
     */
    refreshPoiLayers(callback) {
        this.fetchConfiguration(callback);
    }

    getEquipementsProperties(
        nom_equipememt,
        zone = null,
        currentZone = null,
        callback = null
    ) {
        let url = buildRegionUrl(config.api_poi_export_layer_url, this.region).replace(
            "#layer#",
            nom_equipememt
        );
        if (zone !== null && currentZone !== null) {
            url += "?zone=" + zone + "&zone_id=" + currentZone;
        }
        Api.callApi(url, null, "GET").then((response) => {
            callback(response);
        });
    }
}

export default Equipements;
