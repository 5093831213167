/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";

import { buildRegionUrl, createPdfMethodoLink, slugify } from "../utils.js";
import jszip from "jszip";
import saveAs from "file-saver";
import config from "../settings.js";

/**
 * Allow to export general data using an external callback when send button is clicked.
 *
 * Use ExportForm so the user can select options (include or not PDF is any PDF is
 * linked, select format between CSV or Excel readable - ie. with right encoding).
 *
 * @prop parentApi
 * @prop {callable} exportFunction The function called when pushing export button
 * @prop {boolean} methodoPdf whether a PDF file is associated with current data
 */
export class GenericExportButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
        };
    }

    openModal = () => {
        this.setState({ isModalOpen: true });
    };
    closeModal = () => {
        this.setState({ isModalOpen: false });
    };

    onExport = (format, exportYears, includePDF, resolve) => {
        this.props.exportFunction(format, includePDF, () => {
            this.closeModal();
            resolve();
        });
    };

    render() {
        return (
            <>
                <button
                    type="button"
                    className="territorialsynthesis-methodo btn btn-primary"
                    data-dismiss="alert"
                    onClick={this.openModal}
                >
                    Exporter les données
                </button>
                {this.state.isModalOpen && (
                    <ExportForm
                        parentApi={this.props.parentApi}
                        closeModal={this.closeModal}
                        methodoPdf={this.props.methodoPdf}
                        analysisId={false}
                        analysisSelectedYear={false}
                        exportYears={false}
                        onExport={this.onExport}
                        disableGIS
                    />
                )}
            </>
        );
    }
}

/**
 * Allow to export an indicator data using API link.
 *
 * Use ExportForm so the user can select options (include current year if provided or all years,
 * include or not PDF is any PDF is linked, select format between CSV or Excel readable - ie. with right encoding).
 *
 * @prop parentApi
 * @prop {number} analysisId
 * @prop {number?} analysisSelectedYear L'année actuelle séléctionnée
 * @prop {string?} exportYears Si spécifié choisi les années à exporter à la place de l'utilisateur
 */
export class ExportIndicatorButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
        };
    }

    openModal = () => {
        this.setState({ isModalOpen: true });
    };
    closeModal = () => {
        this.setState({ isModalOpen: false });
    };

    onExport = async (format, exportYears, includePDF, resolve) => {
        const analysisId = this.props.analysisId ?? this.props.parentApi.data.analysis;
        const analysisManager = this.props.parentApi.controller.analysisManager;

        const zoneType = this.props?.zoneType ?? this.props.parentApi.data.zone.zone;
        const zoneId = this.props?.zoneId ?? this.props.parentApi.data.currentZone;
        const zoneMaille =
            this.props?.zoneMaille ?? this.props.parentApi.data.zone.maille;

        let exportUrl =
            buildRegionUrl(
                config.api_analysis_export,
                this.props.parentApi.data.region
            ).replace("#id#", analysisId) +
            `?zone=${zoneType}` +
            `&zone_id=${zoneId}` +
            `&format=${format}`;
        const pdfName = analysisManager.getAnalysisMethodoPdf(analysisId);
        let pdfUrl;
        if (includePDF)
            pdfUrl = createPdfMethodoLink(
                config.methodo_url,
                this.props.parentApi.data.region,
                pdfName
            );
        let exportedFilename = analysisManager.getAnalysisName(analysisId);
        exportedFilename = slugify(exportedFilename);
        exportedFilename +=
            format === "excel" ? ".xlsx" : format === "gpkg" ? ".gpkg" : ".csv";

        let mailles = [zoneType, zoneMaille];
        if (zoneType === zoneMaille) {
            mailles = [zoneType];
        }
        let years = [
            exportYears === "all"
                ? ""
                : this.props.analysisSelectedYear ??
                  analysisManager.getAnalysisLastYear(analysisId),
        ];
        if (exportYears === "all" && ["gpkg"].includes(format)) {
            years = analysisManager.getAnalysisYears(analysisId);
        }
        const exportAttr = mailles
            .map((maille) => years.map((year) => ({ maille, year })))
            .flat();

        try {
            const buffers = await Promise.all(
                exportAttr
                    .map(({ maille, year }) => ({
                        maille: `&maille=${maille}`,
                        year: year ? `&annee=${year}` : "",
                    }))
                    .map(async ({ maille, year }) => {
                        const response = await fetch(exportUrl + maille + year);
                        if (!response.ok) {
                            const e = await response.json();
                            console.error("Error during export: ", e["message"]);
                            return null;
                        }
                        return response.arrayBuffer();
                    })
            );
            const zip = new jszip();
            for (let i in buffers) {
                if (buffers[i] != null) {
                    zip.file(
                        (mailles.length > 1 ? `${exportAttr[i].maille}_` : "") +
                            (years.length > 1 ? `${exportAttr[i].year}_` : "") +
                            exportedFilename,
                        buffers[i]
                    );
                }
            }
            if (pdfUrl) {
                const PdfMethodo = fetch(pdfUrl).then((t) => t.blob());
                zip.file(pdfName, PdfMethodo);
            }
            const blob = await zip.generateAsync({ type: "blob" });
            exportedFilename = exportedFilename.split(".")[0] + ".zip";
            saveAs(blob, exportedFilename);
        } catch (e) {
            alert(e);
        } finally {
            this.closeModal();
            resolve();
        }
    };

    render() {
        let analysisId = this.props.analysisId ?? this.props.parentApi.data.analysis;
        return (
            <div>
                <button
                    aria-hidden="true"
                    style={{ border: "none", padding: "1px" }}
                    title={
                        "Exporter " +
                        this.props.parentApi.controller.analysisManager.getAnalysisName(
                            analysisId
                        )
                    }
                    className={"bi-download " + this.props.className}
                    onClick={this.openModal}
                />
                {this.state.isModalOpen && (
                    <ExportForm
                        parentApi={this.props.parentApi}
                        analysisSelectedYear={this.props.analysisSelectedYear}
                        closeModal={this.closeModal}
                        analysisId={analysisId}
                        exportYears={this.props.exportYears}
                        onExport={this.onExport}
                    />
                )}
            </div>
        );
    }
}

/**
 * Show a modal so the user can select options related to an export.
 *
 * Options provided go as follow:
 *   - include current year if provided or all years,
 *   - include or not PDF is any PDF is linked (thus creating a zip),
 *   - select format between CSV or Excel readable - ie. with right encoding.
 *
 * Require a callback function that is called when button is pressed.
 *
 * @prop parentApi
 * @prop {callable} onExport Called when submit button pressed
 * @prop {number} analysisId
 * @prop {number?} analysisSelectedYear Current selected year
 * @prop {string?} exportYears If specified, user won't have the choice on years
 * @prop {boolean} methodoPdf whether a PDF file is associated with current data
 */
class ExportForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disableExport: false,
            includePDF: false,
            exportYears: props.exportYears ?? "current",
            format: "excel",
        };
    }

    onIncludePDFChange = (event) => {
        this.setState({
            includePDF: event.target.checked,
        });
    };
    onYearsChange = (event) => {
        this.setState({
            exportYears: event.target.value,
        });
    };
    onFormatChange = (event) => {
        this.setState({
            format: event.target.value,
        });
    };

    onExport = () => {
        this.setState({ disableExport: true });

        this.props.onExport(
            this.state.format,
            this.state.exportYears,
            this.state.includePDF,
            () => {
                this.setState({ disableExport: false });
            }
        );
    };

    handleClick(e) {
        // do not handle click when clicking on inner div and not on main div
        if (e.target !== e.currentTarget) {
            return;
        }
        this.props.closeModal();
    }

    render() {
        const analysisId = this.props.analysisId ?? this.props.parentApi.data.analysis;
        let year =
            this.props.analysisSelectedYear ??
            this.props.parentApi.controller.analysisManager.getAnalysisLastYear(
                analysisId
            );

        const methodoPdf =
            this.props.methodoPdf ??
            this.props.parentApi.controller.analysisManager.getAnalysisMethodoPdf(
                analysisId
            );
        const hasMethodoPdf =
            methodoPdf && methodoPdf.endsWith(".pdf") && !methodoPdf.startsWith("http");

        const years =
            this.props.parentApi.controller.analysisManager.getAnalysisYears(
                analysisId
            );
        const hasSeveralYears = years && Array.isArray(years) && years.length > 1;

        return (
            <div className="modal" onClick={(e) => this.handleClick(e)}>
                <div className="modal-content">
                    <span className="close" onClick={(e) => this.handleClick(e)}>
                        &times;
                    </span>
                    <div>
                        {hasSeveralYears && !this.props.exportYears && (
                            <>
                                <p style={{ clear: "both" }}>
                                    Sélectionner les années à exporter :
                                </p>
                                <div>
                                    <input
                                        type="radio"
                                        name="years"
                                        value="current"
                                        id="export-years-current"
                                        checked={this.state.exportYears === "current"}
                                        onChange={this.onYearsChange}
                                    />{" "}
                                    <label htmlFor="export-years-current">{year}</label>
                                </div>
                                <div>
                                    <input
                                        type="radio"
                                        name="years"
                                        value="all"
                                        id="export-years-all"
                                        checked={this.state.exportYears === "all"}
                                        onChange={this.onYearsChange}
                                    />{" "}
                                    <label htmlFor="export-years-all">Toutes</label>
                                </div>
                                <br />
                            </>
                        )}
                        <p>Sélectionner le format du fichier de données :</p>
                        <div>
                            <input
                                type="radio"
                                name="format"
                                value="excel"
                                id="export-format-excel"
                                checked={this.state.format === "excel"}
                                onChange={this.onFormatChange}
                            />{" "}
                            <label htmlFor="export-format-excel">Excel</label>
                        </div>
                        <div>
                            <input
                                type="radio"
                                name="format"
                                value="csv"
                                id="export-format-csv"
                                checked={this.state.format === "csv"}
                                onChange={this.onFormatChange}
                            />{" "}
                            <label htmlFor="export-format-csv">CSV</label>
                        </div>
                        {!this.props.disableGIS && (
                            <div>
                                <input
                                    type="radio"
                                    name="format"
                                    value="gpkg"
                                    id="export-format-gpkg"
                                    checked={this.state.format === "gpkg"}
                                    onChange={this.onFormatChange}
                                />{" "}
                                <label htmlFor="export-format-gpkg">GeoPackage</label>
                            </div>
                        )}
                        <br />
                        {hasMethodoPdf && (
                            <>
                                <div>
                                    <input
                                        type="checkbox"
                                        id="export-include-pdf"
                                        checked={this.state.includePDF}
                                        onChange={this.onIncludePDFChange}
                                    />{" "}
                                    <label htmlFor="export-include-pdf">
                                        Inclure le PDF méthodologique
                                    </label>
                                </div>
                                <br />
                            </>
                        )}
                        {this.state.format === "gpkg" &&
                            this.state.exportYears === "all" && (
                                <p>Attention : le temps d’export peut être long</p>
                            )}
                        <center>
                            <button
                                className="btn btn-primary"
                                onClick={this.onExport}
                                disabled={this.state.disableExport}
                            >
                                {this.state.disableExport && (
                                    <span
                                        className="spinner-border spinner-border-sm me-1"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                )}
                                Exporter
                            </button>
                        </center>
                    </div>
                </div>
            </div>
        );
    }
}
