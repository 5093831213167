/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import ReactTable from "react-table-6";

import Api from "../../Controllers/Api";

import config from "../../settings.js";
import { buildRegionUrl, filterCaseInsensitive } from "../../utils.js";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-table-6/react-table.css";

/**
 * This component is used to manage existing analysis
 */
class AnalysisManage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            analysis: undefined,
            statusError: false,
            status: "",
        };
        this.region = this.props.parentApi.data.region;
        this.getAnalysis();
    }

    // Retrieve analysis
    getAnalysis() {
        // Call API to Retrieve analysis
        let url = buildRegionUrl(config.api_analysis_meta_url, this.region);
        Api.callApi(url, null, "GET")
            .then((response) => {
                this.setState({
                    analysis: response,
                });
            })
            .catch((e) => this.setState({ analysis: undefined }));
    }

    /**
     * getTrProps is used to color the rows of the table according to criteria
     * @param  {json} state : current row state
     * @param  {json} rowInfo : current row info (columns, values)
     * @param  {json} instance : array instance
     * @return {json} the style for the current row
     */
    getTrProps(state, rowInfo, instance) {
        if (rowInfo) {
            return {
                style: {
                    background: rowInfo.row.active ? "#f3f9e6" : "#fdf1da",
                },
            };
        }
        return {};
    }

    /**
     * @return {dom} the rendering of the component
     */
    render() {
        if (
            !this.props.connected ||
            !this.props.userInfos ||
            this.props.userInfos?.profil !== "admin"
        ) {
            return <div>Non accessible.</div>;
        }

        /**
         * Callback for the activation / deactivation of the current analysis
         * @param  {json} analysis : current row
         */
        const activateAnalysis = (analysis) => {
            const baseUrl = analysis.props.row.active
                ? config.api_analysis_deactivate_url
                : config.api_analysis_activate_url;
            let url = buildRegionUrl(baseUrl, this.region).replace(
                "#id#",
                analysis.props.row.id
            );

            Api.callApi(url, null, "GET")
                .then((response) => {
                    // Reload analysis list
                    this.setState({
                        statusError: false,
                        status: analysis.props.row.active
                            ? "Désactivation réussie !"
                            : "Activation réussie !",
                    });
                    if (this.props.userInfos.profil === "admin") {
                        this.getAnalysis();
                    }
                })
                .catch((e) => this.setState({ status: e.message, statusError: true }));
        };

        /**
         * Callback for the deletion of the current analysis
         * @param  {json} analysis : current row
         */
        const deleteAnalysis = (analysis) => {
            const newLine = "\r\n";
            let url = buildRegionUrl(
                config.api_analysis_url + analysis.props.row.id,
                this.region
            );

            Api.callApi(url, null, "DELETE")
                .then((response) => {
                    // Reload analysis list
                    const dashboardsLinked =
                        response["related_dashboards"] === 0
                            ? ""
                            : newLine +
                              " - " +
                              response["related_dashboards"] +
                              " tableau(x) de bord lié(s).";
                    let r = window.confirm(
                        "Confirmez-vous la suppression de l'indicateur " +
                            analysis.props.row.nom +
                            " ?" +
                            dashboardsLinked
                    );
                    if (r === true) {
                        url += "?confirm=true";
                        Api.callApi(url, null, "DELETE").then((response) => {
                            this.setState({
                                statusError: false,
                                status: "Suppression réussie !",
                            });
                            this.getAnalysis();
                        });
                    }
                })
                .catch((e) => {
                    this.setState({ status: e.message, statusError: true });
                });
        };

        /**
         * Callback to update an indicator
         * @param  {json} analysis : current row
         */
        const updateAnalysis = (analysis) => {
            // We switch to the creation tab, but in edit mode
            this.props.updateTabIndicateur(analysis.props.original);
        };

        const columns = [
            {
                Header: "Id",
                accessor: "id",
                filterable: false,
            },
            {
                Header: "Thème",
                accessor: "ui_theme",
            },
            {
                Header: "Nom",
                accessor: "nom",
            },
            {
                Header: "Active",
                accessor: "active",
                filterable: false,
            },
            {
                id: "activate",
                Header: "Action",
                accessor: "actif",
                Cell: (props) => (
                    <div className="actions">
                        <button
                            className={
                                props.row.active ? "btn btn-warning" : "btn btn-success"
                            }
                            onClick={() => activateAnalysis({ props })}
                        >
                            {props.row.active ? "Désactiver" : "Activer"}
                        </button>
                        <button
                            className="btn btn-warning"
                            onClick={() => updateAnalysis({ props })}
                        >
                            Mettre à jour
                        </button>
                        <button
                            className={"btn btn-danger"}
                            onClick={() => deleteAnalysis({ props }, false)}
                        >
                            Supprimer
                        </button>
                    </div>
                ),
            },
        ];

        const alertBox = this.state.statusError
            ? "alert alert-danger"
            : "alert alert-success";
        return (
            <div>
                <div className="panel-body user-scenarii">
                    <h3 className="panel-title pull-left">Gestion des indicateurs</h3>

                    {this.state.status && (
                        <div className={alertBox}>{this.state.status}</div>
                    )}
                    <ReactTable
                        data={this.state.analysis}
                        columns={columns}
                        className="-striped"
                        getTrProps={this.getTrProps}
                        defaultPageSize={30}
                        filterable={true}
                        defaultFilterMethod={(filter, row) =>
                            filterCaseInsensitive(filter, row)
                        }
                    />
                </div>
            </div>
        );
    }
}

export default AnalysisManage;
