/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import ReactTable from "react-table-6";

import Api from "../../Controllers/Api";
import FormGenerator from "../FormGenerator";
import UserEdition from "./UserEdition";

import config from "../../settings.js";

import "bootstrap/dist/css/bootstrap.min.css";
import { buildRegionUrl, filterCaseInsensitive } from "../../utils.js";

/**
 * This component manages the different users with access to the application
 */
class UsersManage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            users: undefined,
            formNewUser: false,
            showUserEdit: false,
            editedUser: undefined, // object representing a user : { mail, nom, ... }
        };
        this.getUsers();
    }

    // Get all users list
    getUsers() {
        // Call api to get users list
        Api.callApi(
            buildRegionUrl(config.users_url, this.props.parentApi.data.region),
            undefined,
            "GET"
        )
            .then((response) => {
                response = response.map((r) => ({
                    ...r,
                    derniere_connexion: new Date(r.derniere_connexion),
                    date_creation: new Date(r.date_creation),
                }));
                // Store user list
                this.setState({
                    users: response,
                });
            })
            .catch((e) => this.setState({ status: e.message }));
    }

    /**
     * getTrProps is used to color the rows of the table according to criteria
     * @param  {json} state state of the current row
     * @param  {json} rowInfo current row info (columns, values)
     * @param  {json} instance array instance
     * @return {json} the style definition for the current row
     */
    getTrProps(state, rowInfo, instance) {
        if (rowInfo) {
            return {
                style: {
                    background: rowInfo.original.actif ? "#f3f9e6" : "#fdf1da",
                },
            };
        }
        return {};
    }

    activateUser(user) {
        let url = buildRegionUrl(
            config.user_activate_url,
            this.props.parentApi.data.region
        );
        if (user.props.value) {
            // Deactivate
            url = buildRegionUrl(
                config.user_deactivate_url,
                this.props.parentApi.data.region
            );
        }
        const body = JSON.stringify({ login: user.props.row.mail });
        Api.callApi(url, body, "POST")
            .then((response) => {
                // Reload user list
                if (this.props.userInfos.profil === "admin") {
                    this.getUsers();
                }
            })
            .catch((e) => this.setState({ status: e.message }));
    }

    deleteUser(user) {
        let r = window.confirm("Attention, cette action est irréversible");
        if (r !== true) {
            return;
        }
        let url = buildRegionUrl(
            config.user_delete_url,
            this.props.parentApi.data.region
        );
        const body = JSON.stringify({ login: user.props.row.mail });
        Api.callApi(url, body, "DELETE")
            .then((response) => {
                // Reload user list
                if (this.props.userInfos.profil === "admin") {
                    this.getUsers();
                }
            })
            .catch((e) => this.setState({ status: e.message }));
    }

    addUser() {
        this.setState({ formNewUser: true });
    }
    callbackCreateNewUserForm() {
        this.setState({ formNewUser: false });
        // Reload user list
        if (this.props.userInfos.profil === "admin") {
            this.getUsers();
        }
    }

    editUser(props) {
        this.setState({ showUserEdit: true, editedUser: props.original });
    }
    editUserCallback() {
        this.setState({ showUserEdit: false });
        // Reload user list
        if (this.props.userInfos.profil === "admin") {
            this.getUsers();
        }
    }

    /**
     * @return {dom} the rendering of the component
     */
    render() {
        if (
            !this.props.connected ||
            !this.props.userInfos ||
            this.props.userInfos.profil !== "admin"
        ) {
            return null;
        }

        // Admin dashboard --------------------------------------------
        let formNewUser = "";
        if (this.state.formNewUser) {
            let submissionFormFields = [
                { id: "prenom", label: "Prénom", type: "text", required: true },
                { id: "nom", label: "Nom", type: "text", required: true },
                { id: "mail", label: "Mail", type: "text", required: true },
                {
                    id: "organisation",
                    label: "Organisation",
                    type: "text",
                    required: true,
                },
                {
                    id: "fonction",
                    label: "Fonction",
                    type: "text",
                    required: true,
                },
                {
                    id: "territoire_predilection",
                    label: "Territoire",
                    type: "territory",
                    required: true,
                },
                {
                    id: "utiliser_territoire_predilection",
                    label: "Sélectionner ce territoire automatiquement lors de la connexion",
                    type: "checkbox",
                },
                {
                    id: "code_postal",
                    label: "Code postal",
                    type: "text",
                    required: true,
                    regex: /^[0-9]{5}$/,
                },
                {
                    id: "profil",
                    label: "Profil",
                    type: "select",
                    options: ["admin", "utilisateur"],
                    required: true,
                    multiple: false,
                },
                {
                    id: "actif",
                    label: "Rendre ce compte actif",
                    type: "checkbox",
                    required: false,
                },
            ];
            formNewUser = (
                <FormGenerator
                    parentApi={this.props.parentApi}
                    title="Nouvel utilisateur"
                    fields={submissionFormFields}
                    url={config.create_user_admin_url}
                    rgpd={false}
                    callback={() => this.callbackCreateNewUserForm()}
                    region={this.props.parentApi.data.region}
                />
            );
        }

        const columns = [
            {
                Header: "Mail",
                accessor: "mail",
            },
            {
                Header: "Prénom",
                accessor: "prenom",
            },
            {
                Header: "Nom",
                accessor: "nom",
            },
            {
                Header: "Organisation",
                accessor: "organisation",
            },
            {
                Header: "Fonction",
                accessor: "fonction",
            },
            {
                Header: "Territoire",
                accessor: "territoire",
            },
            {
                Header: "Territoire de prédilection",
                accessor: "territoire_predilection",
                Cell: (props) => {
                    const value = JSON.parse(props.value);
                    return (
                        <>
                            {value.zoneType} - maille {value.zoneMaille}
                            {value.zoneId ? " :" : ""} {value.zoneId}
                        </>
                    );
                },
            },
            {
                Header: "Profil",
                accessor: "profil",
                Cell: (props) => (
                    <>
                        {props.value}{" "}
                        {props.original.publication && "ayant le droit de publication"}
                    </>
                ),
            },
            {
                Header: "Nb connexion",
                accessor: "connexion",
                filterable: false,
            },
            {
                Header: "Dernière connexion",
                accessor: "derniere_connexion",
                Cell: (props) => {
                    // timestamp is 0 = null date
                    if (props.value.getTime() === 0) {
                        return "Aucune connexion";
                    }
                    return props.value.toLocaleString();
                },
            },
            {
                Header: "Date de création",
                accessor: "date_creation",
                Cell: (props) => {
                    // timestamp is 0 = null date
                    if (props.value.getTime() === 0) {
                        return "Aucune information";
                    }
                    return props.value.toLocaleString();
                },
            },
            {
                id: "activate",
                Header: "Action",
                accessor: "actif",
                filterable: false,
                Cell: (props) => (
                    <div className="actions flex-wrap">
                        <button
                            className={
                                props.value ? "btn btn-warning" : "btn btn-success"
                            }
                            onClick={() => this.activateUser({ props })}
                        >
                            {props.value ? "Désactiver" : "Activer"}
                        </button>
                        <button
                            className="btn btn-danger"
                            onClick={() => this.deleteUser({ props })}
                        >
                            Supprimer
                        </button>
                        <br />
                        <button
                            className="btn btn-primary"
                            onClick={() => this.editUser(props)}
                        >
                            Modifier
                        </button>
                    </div>
                ),
            },
        ];
        let listeUtilisateurs = (
            <div>
                {"Aucun compte utilisateur n'a été détecté pour la région " +
                    this.props.parentApi.data.settings.label}
            </div>
        );
        if (this.state.users) {
            listeUtilisateurs = (
                <ReactTable
                    data={this.state.users}
                    columns={columns}
                    className="-striped"
                    getTrProps={this.getTrProps}
                    defaultPageSize={10}
                    filterable={true}
                    defaultFilterMethod={(filter, row) =>
                        filterCaseInsensitive(filter, row)
                    }
                />
            );
        }
        return (
            <div>
                <div className="panel-body user-scenarii">
                    <h3 className="panel-title pull-left">Gestion des utilisateurs</h3>
                    <button
                        className="btn btn-success bottom-spacer"
                        onClick={() => this.addUser()}
                    >
                        Ajouter un utilisateur
                    </button>
                    {formNewUser}
                    {listeUtilisateurs}
                </div>
                {this.state.showUserEdit && (
                    <UserEdition
                        parentApi={this.props.parentApi}
                        mode="admin"
                        user={this.state.editedUser}
                        onClose={() => this.editUserCallback()}
                        data-testid="user-edition"
                    />
                )}
            </div>
        );
    }
}

export default UsersManage;
