/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import React from "react";
import { Link } from "react-router-dom";

import configData from "../../settings_data.js";

/**
 * This component manages the display of the application footer
 */
class NationalFooter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            classFooter: "footer",
        };

        // Initial one shot timer for hiding footer
        this.footerTimerId = setInterval(() => {
            this.setState({ classFooter: "footer footer-small" });
            clearInterval(this.footerTimerId);
        }, configData.timeHideFooter);
    }

    render() {
        // Mouse over on footer
        const handleMouseEnterOnFooter = (e) => {
            this.setState({ classFooter: "footer" });
        };
        const handleMouseLeaveFooter = (e) => {
            this.setState({ classFooter: "footer footer-small" });
        };

        let classFooter = this.state.classFooter;
        return (
            <div
                className={classFooter}
                onMouseEnter={handleMouseEnterOnFooter}
                onMouseLeave={handleMouseLeaveFooter}
            >
                <ul className="menu-h">
                    <li>
                        <Link to="/national/a_propos">
                            <button>À propos</button>
                        </Link>
                    </li>
                    <li>
                        <Link to="/national/modules">
                            <button>L'outil</button>
                        </Link>
                    </li>
                    <li>
                        <Link to="/national/statistiques">
                            <button>Statistiques</button>
                        </Link>
                    </li>
                    <li>
                        <Link to="/national/open_source">
                            <button>Open-Source</button>
                        </Link>
                    </li>
                    <li>
                        <Link to="/national/contact">
                            <button>Contact</button>
                        </Link>
                    </li>
                    <li>
                        <Link to="/national/mentions_legales_cgu">
                            <button>Mentions légales</button>
                        </Link>
                    </li>
                </ul>
            </div>
        );
    }
}

export default NationalFooter;
